import React, { useEffect, useState, useRef, useContext } from "react";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";

import { dbTables, typeOfTask } from "../../api/types/dbTables";
import IntlMessages from "../../util/IntlMessages";
import WithConfirmation from "../ConfirmationDialog/WithConfirmation";
import TemplatesPopover from "./TemplatesPopover";
import LoadingBackdrop from "../WholeScreenFocusBackdrop/LoadingBackdrop";
import ReadOnlyModal from "../Modal/ReadOnlyModal";
import { useCompany } from "../../hooks/company";
import { firestore } from "../../firebase";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
} from "@mui/material";

import {
  fetchVendorTasks,
  fetchSalesOrderTasks,
  fetchSalesOrderTemplate,
} from "./FactoryTemplateHelper";
import {
  changeTaskDuration,
  getStarFinishCalendarDate,
  getTemplateTaskWithDate,
  sortTaskList,
  outdatedTaskSorting,
  taskListByStage,
  getChangesFromBeforeAndCurrentTasks,
} from "../SalesOrders/soTemplateHelper";
import { GanttChartTemplateStyled } from "../SalesOrders/GanttChartTemplate/styles";
import TableContentSetup from "../SalesOrders/GanttChartTemplate/TableContentSetup";
import GanttChartHeader from "../SalesOrders/GanttChartTemplate/GanttChartHeader";
import {
  GANTT_CHART_ACTION,
  GanttChartTemplateContext,
} from "../CompanyTabs/SalesOrderTemplateContainer";
import TaskTemplateContent from "../SalesOrders/GanttChartTemplate/TaskTemplateContent";
import PurchaseOrderTask from "../../api/model/purchaseOrderTask";
import { TASK_TEMPLATE_OPTIONS } from "../../helpers/salesOrderHelpers";
import GanttChart from "../SalesOrders/GanttChartTemplate/GanttChart";
import { colors } from "../../assets/jss/variables";
import CustomButton from "../Buttons/CustomButton";
import {
  getRandomId,
  validateDataTaskTemplate,
  verifyTaskTemplateErrors,
} from "../../helpers/helpers";
import { useCompanyUsers, useUser } from "../../hooks/user";
import { TASK_TYPE } from "../../helpers/constants";
import PurchaseOrderTaskTemplate from "../../api/model/purchaseOrderTemplate";
import CollapseExpandButtons from "../SalesOrders/CollapseExpandButtons";
import CustomSnackbar from "../../routes/components/snackbar/component/CustomSnackbar";
import { filterTaskByPhases } from "../Timeline/timelineHelper";
import ChangeLog from "../../api/model/ChangeLog";

import VendorTemplateVersion from "../../api/model/VendorTemplateVersion";
import AttentionModal from "../Modal/AttentionModal";

function FactoryTemplateModal({
  open,
  onClose,
  companyId,
  factory,
  isReadOnly = false,
  summaryFactories,
}) {
  const { ganttChartTemplateState, dispatchGanttChartTemplate } = useContext(
    GanttChartTemplateContext
  );
  const {
    salesOrderTemplate,
    startDate,
    endDate,
    loading,
    tableSize,
    tasks,
    initializedTasks,
    salesOrderTasks,
    templateChanges,
    vendorClone,
    deletedTasks,
    taskPhases,
  } = ganttChartTemplateState;
  const company = useCompany() || {};
  const user = useUser();
  const buttonRef = useRef(null);
  const companyRef = `${dbTables.COMPANIES}/${companyId}`;
  const companyUsers = useCompanyUsers({
    showInactiveUsers: false,
    showBotUser: false,
  });
  const [errorMessage, setErrorMessage] = useState({
    open: false,
    message: "type",
  });
  const [openReadOnlyModal, setReadOnlyModal] = useState(false);
  const [openCloneTemplate, setOpenCloneTemplate] = useState(false);
  const [validateTaskTemplate, setValidateTaskTemplate] = useState(false);
  const [calendarRange, setCalendarRange] = useState({
    headers: [],
    subheaders: [],
  });
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");

  useEffect(() => {
    const days = getStarFinishCalendarDate({ endDate, startDate });
    setCalendarRange({
      headers: days,
    });
  }, [startDate, endDate]);

  useEffect(() => {
    if (tasks.length > 0) {
      const isTemplateValidate = validateDataTaskTemplate({
        poTasks: tasks.filter(
          (taskData) => taskData.type !== TASK_TYPE.SALES_ORDER
        ),
        salesOrderTasks,
        users: companyUsers,
      });
      setValidateTaskTemplate(isTemplateValidate);
    }
  }, [tasks]);

  useEffect(() => {
    const fetchSOData = async () => {
      try {
        const templateData = await fetchSalesOrderTemplate({ companyRef });
        if (templateData) {
          dispatchGanttChartTemplate({
            type: GANTT_CHART_ACTION.COMMON,
            payload: {
              salesOrderTemplate: templateData,
            },
          });
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchSOData();
  }, []);

  useEffect(() => {
    const fetchSOTaskData = async () => {
      try {
        if (salesOrderTemplate?.id && factory?.id && open) {
          const currentSalesOrderTasks = await fetchSalesOrderTasks({
            companyRef,
            salesOrderTemplateId: salesOrderTemplate.id,
            factory,
          });
          const { vendorTasks } = await fetchVendorTasks({
            companyRef,
            factory,
          });
          let sortingTask;
          if (factory.isOutdated) {
            sortingTask = outdatedTaskSorting({
              salesOrderTasks: currentSalesOrderTasks,
              vendorTasks,
            });
          } else {
            sortingTask = sortTaskList(
              [...currentSalesOrderTasks, ...vendorTasks],
              factory.vendorTemplateIndexes
            );
          }
          const initializedTasks = sortingTask.map((task, index) => ({
            ...task,
            index,
          }));
          dispatchGanttChartTemplate({
            type: GANTT_CHART_ACTION.COMMON,
            payload: {
              salesOrderTasks: sortTaskList(currentSalesOrderTasks),
              tasks: initializedTasks,
              initializedTasks: initializedTasks,
              loading: false,
            },
          });
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchSOTaskData();
  }, [salesOrderTemplate.id, factory.id, open]);

  const addStageTask = (currentStage) => {
    const newTaskList = taskListByStage(tasks);
    const currentTaskList = newTaskList[currentStage];
    const previousTaskIndex =
      currentTaskList.length > 0
        ? currentTaskList[currentTaskList.length - 1].listIndex
        : 0;
    newTaskList[currentStage].push({
      ...new PurchaseOrderTask({
        description: "New Task",
        salesOrderId: salesOrderTemplate.id,
        listIndex: previousTaskIndex + 1,
        stage: currentStage,
      }),
      duration: 1,
      offset: 0,
      color: colors.purchaseOrderBorderColor,
    });
    const updatedTaskList = sortTaskList(Object.values(newTaskList).flat());
    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        tasks: updatedTaskList.map((taskData, index) => ({
          ...taskData,
          dependencyType: index === 0 ? "" : taskData.dependencyType,
          dependency: index === 0 ? "" : taskData.dependency,
          index,
          listIndex: index + 1,
        })),
      },
      typeChange: TASK_TEMPLATE_OPTIONS.ADD_TASK,
    });
  };

  function getFactoryVersion(factory) {
    return factory.version ? factory.version + 1 : 1;
  }

  async function saveMiniProjectTemplate() {
    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        loading: true,
      },
    });
    const { status, type } = verifyTaskTemplateErrors({
      isPOVerifier: true,
      POTaskTemplate: tasks
        .filter((taskData) => taskData.type !== TASK_TYPE.SALES_ORDER)
        .map((taskData) => ({ ...taskData })),
      SOTaskTemplate: salesOrderTasks,
    });
    if (status === 400) {
      setErrorMessage({ open: true, message: type });
      dispatchGanttChartTemplate({
        type: GANTT_CHART_ACTION.COMMON,
        payload: {
          loading: false,
        },
      });
      return false;
    }

    const currentFactoryRef = doc(
      firestore,
      `${companyRef}/${dbTables.FACTORIES}/${factory.id}`
    );
    const batch = writeBatch(firestore);
    summaryFactories.forEach((summary) => {
      const factories = summary.factories.map((summaryFactory) => {
        if (summaryFactory.id !== factory.id) {
          return {
            ...summaryFactory,
          };
        } else {
          const factoryData = { ...summaryFactory };
          delete factoryData.isOutdated;
          return factoryData;
        }
      });

      updateDoc(summary.ref, { factories });
      updateDoc(currentFactoryRef, { isOutdated: false });
    });
    if (vendorClone.id) {
      const originalTask = await getDocs(
        collection(
          firestore,
          `${companyRef}/${dbTables.FACTORIES}/${factory.id}/${dbTables.MINI_PROJECT_TEMPLATE}`
        )
      );
      originalTask.docs.forEach((doc) => deleteDoc(doc.ref));
    } else {
      deletedTasks.forEach((task) => deleteDoc(task.ref));
    }
    const taskListStages = taskListByStage(tasks);
    const updatedTaskList = sortTaskList(Object.values(taskListStages).flat());

    updatedTaskList.forEach((task) => {
      const newDependency =
        tasks.find((taskData) => taskData.id === task.dependency) || {};

      if (task.type !== typeOfTask.SALES_ORDER) {
        batch.set(
          doc(
            firestore,
            `${companyRef}/${dbTables.FACTORIES}/${factory.id}/${dbTables.MINI_PROJECT_TEMPLATE}/${task.id}`
          ),
          {
            ...new PurchaseOrderTaskTemplate({
              ...task,
              dependsOnSOT: newDependency.type === TASK_TYPE.SALES_ORDER,
            }),
          }
        );
      }
    });
    let vendorTemplateIndexes = {};
    tasks.forEach((task) => (vendorTemplateIndexes[task.id] = task.listIndex));
    updateDoc(
      doc(firestore, `${companyRef}/${dbTables.FACTORIES}/${factory.id}`),
      {
        vendorTemplateIndexes,
        version: getFactoryVersion(factory),
      }
    );

    const {
      addedTasks,
      deletedTasks: deletedTasksTemplate,
      updatedTasks,
    } = getChangesFromBeforeAndCurrentTasks({
      tasks: updatedTaskList,
      initializedTasks,
      type: dbTables.FACTORY_TEMPLATE_CHANGE_LOG,
    });
    const changeLogId = getRandomId();
    const templateViewId = getRandomId();
    const version = getFactoryVersion(factory);
    batch.set(
      doc(
        firestore,
        `${dbTables.COMPANIES}/${companyId}/${dbTables.FACTORIES}/${factory.id}/${dbTables.FACTORY_TEMPLATE_CHANGE_LOG}/${changeLogId}`
      ),
      {
        ...new ChangeLog({
          addedTasks,
          deletedTasks: deletedTasksTemplate,
          updatedTasks,
          version,
          user: user.id,
          description: confirmationText,
          id: changeLogId,
          templateViewId,
          cloneFrom: vendorClone.id,
        }),
      }
    );

    batch.set(
      doc(
        firestore,
        `${dbTables.COMPANIES}/${companyId}/${dbTables.FACTORIES}/${factory.id}/${dbTables.FACTORY_TASK_TEMPLATE_VIEW}/${templateViewId}`
      ),
      {
        ...new VendorTemplateVersion({
          version,
          user: user.id,
          description: "",
          id: templateViewId,
          changeLogId,
          salesOrderTemplateTasks: salesOrderTasks,
          vendorTemplateTasks: updatedTaskList.filter(
            (task) => task.type !== TASK_TYPE.SALES_ORDER
          ),
          salesOrderTemplateId: salesOrderTemplate.id,
          vendorTemplateIndexes,
        }),
      }
    );
    await batch.commit();
    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        loading: false,
        vendorClone: {},
      },
      typeChange: {},
    });
    closeTemplate();
  }

  function closeTemplate() {
    onClose();
  }

  function openClone() {
    if (isReadOnly) {
      setReadOnlyModal(true);
    } else {
      setOpenCloneTemplate(true);
    }
  }

  async function cloneTemplate(newVendorClone) {
    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        loading: true,
        tasks: sortTaskList([...salesOrderTasks]),
        deletedTasks: [],
      },
    });
    const { vendorTasks } = await fetchVendorTasks({
      companyRef,
      factory: { id: newVendorClone.id },
    });
    let sortingTask;
    if (newVendorClone.isOutdated) {
      sortingTask = outdatedTaskSorting({
        salesOrderTasks: salesOrderTasks,
        vendorTasks,
      });
    } else {
      const snapVendor = await getDoc(
        doc(
          firestore,
          `${companyRef}/${dbTables.FACTORIES}/${newVendorClone.id}`
        )
      );
      const vendorCloneDB = snapVendor.data();
      sortingTask = sortTaskList(
        [...salesOrderTasks, ...vendorTasks],
        vendorCloneDB.vendorTemplateIndexes
      );
    }

    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        tasks: sortingTask.map((task, index) => ({ ...task, index })),
        loading: false,
        vendorClone: newVendorClone,
      },
      typeChange: TASK_TEMPLATE_OPTIONS.ADD_TASK,
    });
  }
  function handleChangeTaskDuration(taskId, offset, direction) {
    const newTasks = changeTaskDuration({ direction, offset, taskId, tasks });
    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        tasks: newTasks,
      },
      typeChange: TASK_TEMPLATE_OPTIONS.CHANGED_DATA,
    });
  }

  function onDropTask(task, offset) {
    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.UPDATE_TASK,
      payload: {
        id: task.id,
        offset: task.offset + offset,
      },
      typeChange: TASK_TEMPLATE_OPTIONS.ADD_TASK,
    });
  }

  return (
    <Dialog open={open} fullScreen className="dialog-mini-template">
      <CustomSnackbar
        handleCloseSnackbar={() => {
          setErrorMessage({ open: false, message: "message" });
        }}
        variant="error"
        open={errorMessage.open}
        message={errorMessage.message}
      />
      {loading && <LoadingBackdrop withLogo={true} />}

      {openReadOnlyModal && (
        <ReadOnlyModal
          isOpen={openReadOnlyModal}
          onClick={() => setReadOnlyModal(false)}
          onClose={() => setReadOnlyModal(false)}
        />
      )}
      {confirmationModal && (
        <AttentionModal
          title={
            <div
              style={{
                textAlign: "center",
                color: colors.primaryDark,
              }}
            >
              Save changes?
            </div>
          }
          blockKeydown
          isOpen={confirmationModal}
          onClose={() => {
            setConfirmationText("");
            setConfirmationModal(false);
          }}
          onClick={() => {
            setConfirmationModal(false);
            saveMiniProjectTemplate();
          }}
          cancelText="Cancel"
          confirmationText="Save"
          cancellable={true}
          description={
            <div>
              <TextareaAutosize
                id="template-changes-description-text-area"
                placeholder="Optional notes (but highly recommended)"
                style={{
                  width: "100%",
                  padding: 10,
                  fontSize: 14,
                  border: "1px solid #d1d1d1",
                  borderRadius: 5,
                  marginBottom: 10,
                }}
                minRows={12}
                maxRows={15}
                value={confirmationText}
                onChange={(ev) => setConfirmationText(ev.target.value)}
              />
            </div>
          }
          bodyStyles={{
            height: "auto",
          }}
          styleModal={{
            height: "auto",
          }}
        />
      )}

      <TemplatesPopover
        onItemClick={cloneTemplate}
        onClose={() => setOpenCloneTemplate(false)}
        open={openCloneTemplate}
        anchorEl={buttonRef.current}
        companyId={companyId}
      />
      <DialogTitle className="sales-order-modal-title">
        <WithConfirmation
          text={"Discard changes and go Back?"}
          disabled={Object.keys(templateChanges).length === 0}
        >
          <div
            onClick={closeTemplate}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              gap: 15,
              width: "fit-content",
            }}
          >
            <ArrowBackIcon id="icon-to-call" />

            <IntlMessages id="generic.text.back" />
          </div>
        </WithConfirmation>

        <span
          style={{
            top: 16,
            margin: "auto",
            fontSize: 24,
          }}
        >
          {company.name} - {factory && factory.name}
        </span>
        {Object.keys(templateChanges).length > 0 && (
          <CustomButton
            onClick={closeTemplate}
            style={{
              fontWeight: "bold",
            }}
          >
            {"components-salesOrders-salesOrderTemplate-DiscardChanges"}
          </CustomButton>
        )}

        {validateTaskTemplate && (
          <CustomButton
            onClick={() => setConfirmationModal(true)}
            disabled={loading}
            type="success"
            style={{
              fontWeight: "bold",
            }}
          >
            {
              "components-salesOrders-salesOrderTemplate-SavenewVersionofTemplate"
            }
          </CustomButton>
        )}
      </DialogTitle>
      <DialogContent
        style={{
          margin: "0px 10px",
          padding: 0,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {salesOrderTemplate.version && (
          <div className="header-container">
            <label>{"Sales Order  V" + salesOrderTemplate.version} </label>
            <button ref={buttonRef} onClick={openClone}>
              {
                <IntlMessages id="components.factories.factorytemplatemodal.clonetemplate" />
              }
            </button>
          </div>
        )}

        <GanttChartTemplateStyled className="ganttChartContainer">
          <div
            className="task-content-ganttchart-header-container"
            style={{ height: 80 }}
          >
            <TableContentSetup soTemplate poTemplate />
            <GanttChartHeader calendarRange={calendarRange} />
          </div>
          <div className="task-content-and-ganttchart-container">
            <TaskTemplateContent
              tasks={tasks}
              onAddTask={addStageTask}
              vendorTemplate
            />
            <CollapseExpandButtons
              dispatchGanttChartTemplate={dispatchGanttChartTemplate}
              tableSize={tableSize}
            />
            <GanttChart
              tasks={getTemplateTaskWithDate(
                filterTaskByPhases({ tasks, taskPhases })
              )}
              calendarRange={calendarRange}
              onChangeTaskDuration={handleChangeTaskDuration}
              onDropTask={onDropTask}
              vendorTemplate
            />
          </div>
        </GanttChartTemplateStyled>
      </DialogContent>
    </Dialog>
  );
}

export default FactoryTemplateModal;
