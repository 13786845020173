import { now } from "moment";
import { getRandomId } from "../../helpers/helpers";

export default class VendorTemplateVersion {
  constructor({
    id = getRandomId(),
    creationDate = now(),
    description = "",
    user = "",
    version = "",
    vendorTemplateTasks = [],
    salesOrderTemplateTasks = [],
    salesOrderTemplateId = "",
    changeLogId = "",
    vendorTemplateIndexes = {},
  }) {
    this.id = id;
    this.creationDate = creationDate;
    this.description = description;
    this.user = user;
    this.version = version;
    this.vendorTemplateTasks = vendorTemplateTasks;
    this.salesOrderTemplateTasks = salesOrderTemplateTasks;
    this.salesOrderTemplateId = salesOrderTemplateId;
    this.changeLogId = changeLogId;
    this.vendorTemplateIndexes = vendorTemplateIndexes;
  }
}
