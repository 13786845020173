import { colors } from "../../assets/jss/variables";
import { styled } from "@mui/material";

export const CompanyPermissionsStyled = styled("div")(() => ({
  "&.companyPermissionsContainer": {
    boxShadow: "0 2px 4px 2px #ccc",
    overflowY: "scroll",
    background: "white",
    padding: "0px 15px 15px 15px",
    width: "100%",
    height: "100%",
    "& .permissions-header-tab": {
      paddingTop: 16,
      justifyContent: "flex-start",
      display: "inline-flex",
      width: "100%",
      alignItems: "baseline",
      marginBottom: 10,
      position: "sticky",
      top: 0,
      zIndex: 1,
      minHeight: 64,
      background: "white",
      "& .permission-header-text": {
        fontWeight: "bold",
        fontSize: 17,
        color: colors.primaryDark,
      },
    },
    "& .permission-list": {
      padding: "0px 16px 16px 16px",
      "& h6": {
        fontWeight: "bold",
        fontSize: 17,
        color: colors.primaryDark,
      },
      "& .permission-list-item": {
        display: "flex",
        alignItems: "center",
        "& .permission-label": {
          marginRight: 8,
          color: colors.primaryDark,
          fontSize: 15,
          fontWeight: 400,
        },
        "& .permission-label-prefix": {
          marginRight: 2,
          color: colors.primaryDark,
          fontSize: 15,
          fontWeight: 500,
        },
      },
    },
  },
}));
