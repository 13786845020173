import { Button, styled } from "@mui/material";
import { boxShadowColor, colors } from "../../assets/jss/variables";
import { BUTTON_FILTER_BOX_SHADOW } from "../../components/PurchaseOrderList/ButtonFilter";

export const buttonFilterClasses = {
  buttonFilter: "buttonFilter",
  buttonStar: "buttonStar",
  buttonsStatus: "buttonStatus",
};

const buttonFiltersStyles = {
  fontWeight: 400,
  display: "flex",
  alignItems: "center",
  fontSize: "0.85em",
  whiteSpace: "nowrap",
  borderRadius: "50%",
};

export const ButtonFilterComponent = styled(Button)(({ theme }) => ({
  [`&.${buttonFilterClasses.buttonFilter}`]: {
    userSelect: "none",
    height: 40,
    gap: 5,
    color: colors.primaryDark,
    width: 100,
    minWidth: 55,
    borderRadius: 5,
    border: "1px solid #d2dfec ",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    background: "transparent",
    transform: "unset !important",
    padding: "8px 16px",
    [theme.breakpoints.down("xl")]: {
      width: 80,
    },
    [theme.breakpoints.down("lg")]: {
      width: 60,
    },
    "&:hover": {
      background: "#F5F5F5",
      [`& .${BUTTON_FILTER_BOX_SHADOW.FAVORITE}`]: {
        backgroundColor: boxShadowColor.yellowActive,
      },
      [`& .${BUTTON_FILTER_BOX_SHADOW.RED_DIAMOND}`]: {
        backgroundColor: boxShadowColor.redActive,
      },
      [`& .${BUTTON_FILTER_BOX_SHADOW.ORANGE_DIAMOND}`]: {
        backgroundColor: boxShadowColor.orangeActive,
      },
      [`& .${BUTTON_FILTER_BOX_SHADOW.GREEN_DIAMOND}`]: {
        backgroundColor: boxShadowColor.greenActive,
      },
      [`& .${BUTTON_FILTER_BOX_SHADOW.WHITE_DIAMOND}`]: {
        backgroundColor: boxShadowColor.whiteActive,
      },
      [`& .${BUTTON_FILTER_BOX_SHADOW.GRAY_DIAMOND}`]: {
        backgroundColor: boxShadowColor.grayActive,
      },
    },
    "& .childText": {
      fontWeight: 400,
      display: "flex",
      alignItems: "center",
      fontSize: "0.85em",
      whiteSpace: "nowrap",
    },
    [`& .${BUTTON_FILTER_BOX_SHADOW.FAVORITE}`]: {
      ...buttonFiltersStyles,
      backgroundColor: boxShadowColor.yellow,
      padding: 5,
    },
    [`& .${BUTTON_FILTER_BOX_SHADOW.RED_DIAMOND}`]: {
      ...buttonFiltersStyles,
      backgroundColor: boxShadowColor.red,
      padding: "5px 8px",
    },
    [`& .${BUTTON_FILTER_BOX_SHADOW.ORANGE_DIAMOND}`]: {
      ...buttonFiltersStyles,
      backgroundColor: boxShadowColor.orange,
      padding: "5px 8px",
    },
    [`& .${BUTTON_FILTER_BOX_SHADOW.GREEN_DIAMOND}`]: {
      ...buttonFiltersStyles,
      backgroundColor: boxShadowColor.green,
      padding: "5px 8px",
    },
    [`& .${BUTTON_FILTER_BOX_SHADOW.WHITE_DIAMOND}`]: {
      ...buttonFiltersStyles,
      backgroundColor: boxShadowColor.white,
      padding: "5px 8px",
    },
    [`& .${BUTTON_FILTER_BOX_SHADOW.GRAY_DIAMOND}`]: {
      ...buttonFiltersStyles,
      backgroundColor: boxShadowColor.gray,
      padding: "1px 1px",
    },

    "&.active": {
      backgroundColor: "#EFF7FF",
      border: "1px solid #017FFF !important",
      zIndex: 10,
      "&:hover": {
        background: "#E0EFFF",
      },
      [`& .${BUTTON_FILTER_BOX_SHADOW.FAVORITE}`]: {
        backgroundColor: boxShadowColor.yellowActive,
      },
      [`& .${BUTTON_FILTER_BOX_SHADOW.RED_DIAMOND}`]: {
        backgroundColor: boxShadowColor.redActive,
      },
      [`& .${BUTTON_FILTER_BOX_SHADOW.ORANGE_DIAMOND}`]: {
        backgroundColor: boxShadowColor.orangeActive,
      },
      [`& .${BUTTON_FILTER_BOX_SHADOW.GREEN_DIAMOND}`]: {
        backgroundColor: boxShadowColor.greenActive,
      },
      [`& .${BUTTON_FILTER_BOX_SHADOW.WHITE_DIAMOND}`]: {
        backgroundColor: boxShadowColor.whiteActive,
      },
      [`& .${BUTTON_FILTER_BOX_SHADOW.GRAY_DIAMOND}`]: {
        backgroundColor: boxShadowColor.grayActive,
      },
    },
  },
  [`&.${buttonFilterClasses.buttonStar}`]: {
    width: 55,
    "&:hover": {
      "& .childText": {
        background: "rgba(255, 220, 181, 0.7)",
        borderRadius: 50,
        padding: 4,
      },
      "&.active": {
        backgroundColor: "#E0EFFF",
      },
    },
  },
  [`&.${buttonFilterClasses.buttonsStatus}`]: {
    [theme.breakpoints.down("lg")]: {
      width: 80,
    },
  },
}));
