import React from "react";
import { CheckBoxIcon, UncheckBoxIcon } from "../../helpers/iconSvgPath";
import { CheckBoxStyled } from "./styles";
import { cx } from "@emotion/css";

function CustomCheckbox({
  checked,
  onChange = () => {},
  onClick = () => {},
  disabled = false,
  id = "custom-checkbox",
  className = "",
  color,
  iconSize,
  styles = {},
  disableTouchRipple = false,
}) {
  return (
    <CheckBoxStyled
      id={id}
      className={cx("checkboxEffect", className)}
      checked={!!checked}
      checkedIcon={
        <CheckBoxIcon
          height={iconSize}
          width={iconSize}
          svgClass="checkIcon"
          color={color}
        />
      }
      icon={
        <UncheckBoxIcon
          height={iconSize}
          width={iconSize}
          svgClass="uncheckIcon"
        />
      }
      size="small"
      onChange={onChange}
      onClick={onClick}
      disabled={disabled}
      style={styles}
      disableTouchRipple={disableTouchRipple}
    />
  );
}

export default CustomCheckbox;
