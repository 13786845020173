import { darkBlue } from "../../helpers/variableColors";
import { Popover, styled } from "@mui/material";
import { colors } from "../../assets/jss/variables";

export const SettingsPopover = styled(Popover)(() => ({
  "& .menuItemOption": {
    height: 46,
    padding: "0px 8px",
    gap: 5,
  },
  "& .listItemTextLabel": {
    paddingRight: 8,
    fontSize: "1.1em",
  },
  "& .checkBoxSelect": {
    "& svg": {
      fontSize: 24,
    },
  },

  "& .imgListItemIconOrderPlaceholder": {
    cursor: "grab !Important",
  },
  "& .paperPopover": {
    maxHeight: "calc(100vh - 300px)",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent",
      width: 16,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "transparent",
      borderRadius: 7,
    },
    "&::-webkit-scrollbar-track": {
      border: " solid 1px transparent",
      marginBottom: 45,
    },
    "&:hover": {
      "&::-webkit-scrollbar-thumb": {
        boxShadow: `inset 0 0 10px 10px ${colors.thumbScrollColor}`,
        borderBlock: "1px solid transparent",
        borderInline: "5px solid transparent",
      },
    },
  },
  "& .defaultColumnsContainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "16px 8px",
    position: "sticky",
    bottom: 0,
    zIndex: 1,
    background: "white",
    "& span": {
      fontSize: 11,
      fontWeight: 700,
      color: "rgb(37, 72, 109)",
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  "& .dragContainer": {
    cursor: "grab",
    minWidth: 10,
    display: "flex",
    alignItems: "center",
    height: "100%",
    "&:hover, &:active": {
      "& .dragClass": {
        "& circle": {
          fill: "black",
          r: 1.5,
        },
      },
    },
  },
}));

export const SettingScreenBox = styled("div")(() => ({
  "&.multiSelectContainer": {
    height: 35,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 36,
    minWidth: 36,
    cursor: "pointer",
    borderRadius: 5,
    "& .paperPopover": {
      maxHeight: " calc(100vh - 300px)",
    },
    "& .gearOpen": {
      width: 22,
      height: 22,
      transform: "rotate(45deg)",
      transition: "transform .5s ease-in-out",
      fill: darkBlue,
    },
    "& .gearClose": {
      width: 22,
      height: 22,
      transition: "transform .5s ease-in-out",
    },
    "&:hover": {
      background: colors.backgroundWhite,
      "& .gearClose": {
        fill: darkBlue,
      },
    },
    "&:active": {
      background: colors.backgroundWhiteAction,
      "& .gearClose": {
        transform: "rotate(45deg)",
        transition: "transform .5s ease-in-out",
        fill: darkBlue,
      },
    },
  },
}));
