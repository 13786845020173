import React from "react";
import Dropzone from "react-dropzone";
import IntlMessages from "../../util/IntlMessages";
import { colors } from "../../assets/jss/variables";
import { storage } from "../../firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { dbTables } from "../../api/types/dbTables";

function AdminAvatar({
  name,
  domain,
  avatarUrl,
  onChange,
  id,
  onUpload,
  errorMessage,
  disabled,
  onChangeCompany,
  isCompanyActive,
}) {
  function uploadFile(filename) {
    const storageRef = ref(
      storage,
      `${dbTables.COMPANIES}/${id}/${filename.name}`
    );
    const uploadFile = uploadBytesResumable(storageRef, filename);
    uploadFile.on(
      "state_changed",
      () => {},
      () => {},
      () => {
        getDownloadURL(uploadFile.snapshot.ref).then((downloadURL) => {
          onUpload(downloadURL);
        });
      }
    );
  }

  return (
    <div className="container-company-avatar">
      <div className="avatar-container">
        <Dropzone
          onDrop={(accept) => {
            const [fileAccept] = accept;
            if (fileAccept) uploadFile(fileAccept);
          }}
          accept={{
            "image/png": [".png"],
            "image/jpeg": [".jpeg"],
          }}
          maxFiles={1}
          multiple={false}
          disabled={disabled}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {avatarUrl ? (
                <img
                  style={{
                    height: "auto",
                    objectFit: "contain",
                    maxWidth: 120,
                    maxHeight: 80,
                    marginBottom: 10,
                  }}
                  src={avatarUrl}
                  alt={"avatarUrl"}
                />
              ) : (
                <div className="avatar-peding">Avatar pending</div>
              )}
            </div>
          )}
        </Dropzone>
      </div>

      <div className="company-name-container">
        <input
          className="title-company-avatar"
          value={name}
          onChange={(ev) => onChange(ev, "name")}
          disabled={disabled}
          style={{
            marginRight: 5,
            border:
              errorMessage.name || !name ? `1px solid ${colors.dangerRed}` : "",
          }}
        />
        <label style={{ color: "red", marginBottom: "0px", height: 20 }}>
          {errorMessage.name && <IntlMessages id={errorMessage.name || ""} />}
        </label>
      </div>
      <div className="company-domain-container">
        <label style={{ width: 70 }}>Company domain:</label>
        <div className="domain-title">
          <input
            className="title-company-avatar"
            value={domain}
            onChange={(ev) => onChange(ev, "domain")}
            disabled={disabled}
            style={{
              border:
                errorMessage.domain || !domain
                  ? `1px solid ${colors.dangerRed}`
                  : "",
              textTransform: "lowercase",
            }}
          />
          <label style={{ color: "red", marginBottom: "0px", height: 20 }}>
            {errorMessage.domain && (
              <IntlMessages id={errorMessage.domain || ""} />
            )}
          </label>
        </div>
      </div>
      <div className="switch-container">
        <label className="switch">
          <input
            onChange={onChangeCompany}
            checked={isCompanyActive}
            value={isCompanyActive}
            type="checkbox"
          />
          <span
            data-positive="Enabled"
            data-negative="Disabled"
            className="slider round"
          />
        </label>
      </div>
    </div>
  );
}

export default AdminAvatar;
