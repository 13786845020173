import { getRandomId } from "../../helpers/helpers";

export default class ShipmentItem {
  constructor({
    id = getRandomId(),
    number = 0,
    finalDestination = "",
    allocation = 0,
    status = "",
  }) {
    this.id = id;
    this.number = number;
    this.finalDestination = finalDestination;
    this.allocation = parseInt(allocation);
    this.status = status;
  }
}
