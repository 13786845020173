import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getShipmentBadge } from "../../helpers/shipments";
import SearchBox from "../SearchBox/SearchBox";
import { useCustomers } from "../../hooks/customers";
import TooltipTD from "../Tooltip/TooltipTD";
import { ArrowsToSortIconStyled } from "../PurchaseOrderDashboard/styles";
import { pathToString, sortObjectsBy } from "../../helpers/helpers";
import { usePurchaseOrders } from "../../hooks/salesOrders";
import { dbTables } from "../../api/types/dbTables";
import { useCompanyId } from "../../hooks";
import { getShipmentsByPOs } from "../../helpers/salesOrderHelpers";
import LoadingBackdrop from "../WholeScreenFocusBackdrop/LoadingBackdrop";
import { SHIPMENT_STATUS } from "../../helpers/constants";
import { ArrowsToSortIcon } from "../../helpers/iconSvgPath";
import { colors } from "../../assets/jss/variables";
import {
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { shipmentBadgeClass } from "../../container/StyledComponent/BadgeStyled";
import { useDispatch } from "react-redux";
import { getStringDate } from "../PurchaseOrderDashboard/sections/helpers";

const headCells = [
  {
    id: "number",
    label: "Number",
    align: "left",
    headAlign: "center",
    maxLength: 6,
    style: {
      width: 100,
      maxWidth: 100,
      minWidth: 120,
      paddingRight: 8,
    },
    cellStyle: {
      padding: "5px 5px",
      textAlign: "center",
    },
    showBadge: true,
    reference: "number",
    canBeSort: true,
    arrowStyle: {
      top: 10,
      left: 86,
    },
  },
  {
    id: "finalDestination",
    label: "Final Destination",
    headAlign: "center",
    maxLength: 12,
    style: {
      width: 270,
      maxWidth: 270,
      minWidth: 270,
      textAlign: "left",
    },
    cellStyle: {
      padding: "7px 3px 5px 0px",
      textAlign: "left",
      width: 60,
      maxWidth: 60,
    },
    reference: "finalDestination",
    canBeSort: true,
    arrowStyle: {
      top: 10,
      left: 76,
    },
  },
  {
    id: "shipDate",
    label: "Shipment",
    style: {
      width: 105,
      maxWidth: 105,
      minWidth: 105,
      paddingRight: 20,
    },
    cellStyle: {
      padding: "5px 20px 5px 5px",
      textAlign: "right",
      width: 60,
      maxWidth: 60,
    },
    align: "right",
    format: (value) => getStringDate(value),
    reference: "shipDate",
    canBeSort: true,
    arrowStyle: {
      top: 10,
      left: 91,
    },
  },
  {
    id: "deliveryDate",
    label: "Arrival",
    style: {
      width: 105,
      maxWidth: 105,
      minWidth: 105,
      padding: "5px 14px 6px 5px",
      paddingRight: 20,
    },
    cellStyle: {
      padding: "5px 20px 5px 5px",
      textAlign: "right",
      width: 60,
      maxWidth: 60,
    },
    align: "right",
    format: (value) => getStringDate(value),
    reference: "deliveryDate",
    canBeSort: true,
    arrowStyle: {
      top: 10,
      left: 90,
    },
  },
  {
    id: "extra",
    label: "",
    style: {
      width: "100%",
      maxWidth: "100%",
      minWidth: "100%",
      padding: "5px 14px 6px 5px",
      paddingRight: 14,
    },
  },
];

function ListPicker({
  onChange,
  anchorEl,
  open = false,
  onClose = () => {},
  onOpenModal = () => {},
  showNewShipment,
  filterBy = [],
  customerId,
}) {
  const [query, setQuery] = useState("");
  const [listToSearch, setListToSearch] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const dispatch = useDispatch();

  const companyId = useCompanyId();
  const [filters, setFilters] = useState({
    sortedColumn: "number",
    orderBy: "ASC",
  });
  const setShipments = async () => {
    const newShipments = await getShipmentsByPOs({
      purchaseOrders,
      path: pathToString([dbTables.COMPANIES, companyId, dbTables.SHIPMENTS]),
      dispatch: dispatch,
    });
    if (newShipments?.length > 0) {
      setListToSearch(newShipments);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (open) {
      setShipments();
    }
  }, [open]);

  const purchaseOrders = usePurchaseOrders();
  const customers = useCustomers();
  const filterList = listToSearch.filter((itemList) => {
    const parseQuery = query.toLowerCase();
    const number = `${itemList.number || ""}`.toLowerCase();
    const destination = (itemList.finalDestination || "").toLowerCase();
    const customer = (itemList.customer || "").toLowerCase();

    const filter =
      !filterBy.includes(itemList.id) &&
      itemList.customerId === customerId &&
      itemList.status !== SHIPMENT_STATUS.VOIDED;
    const filterByQuery = query
      ? number.includes(parseQuery) ||
        destination.includes(parseQuery) ||
        customer.includes(parseQuery)
      : true;
    return filterByQuery && filter;
  });

  function renderArrow(headerLabel, arrowStyles = {}) {
    if (filters.sortedColumn === headerLabel) {
      if (filters.orderBy === "ASC") {
        return (
          <ArrowsToSortIconStyled
            svgClass="headerArrowButton"
            style={arrowStyles}
            leftColor={colors.primaryDark}
            rightColor="#EEE"
          />
        );
      }
      return (
        <ArrowsToSortIconStyled
          svgClass={"headerArrowButton"}
          style={arrowStyles}
          leftColor="#EEE"
          rightColor={colors.primaryDark}
        />
      );
    }
    return (
      <ArrowsToSortIcon svgClass={"headerArrowButton"} style={arrowStyles} />
    );
  }

  function sortActualColumn(actualColumn) {
    if (filters.sortedColumn === actualColumn) {
      if (filters.orderBy === "ASC") {
        setFilters({ ...filters, orderBy: "DESC" });
      } else {
        setFilters({ ...filters, orderBy: "ASC" });
      }
    } else {
      setFilters({
        ...filters,
        sortedColumn: actualColumn,
        orderBy: "ASC",
      });
    }
  }
  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={open}
      className="list-picker"
      onClose={() => {
        if (!isLoading) {
          onClose();
        }
      }}
    >
      {showNewShipment && (
        <div className={"new-item-container"}>
          <span style={{ cursor: "pointer" }} onClick={onOpenModal}>
            + NEW SHIPMENT
          </span>
        </div>
      )}

      <div className={"searchbox-container"}>
        <SearchBox
          placeholder="Search by number, customer or destination"
          style={{}}
          onChange={(ev) => {
            setQuery(ev.target.value);
          }}
          value={query}
          customStyle={"search-list-picker"}
          hasBeenReseted={() => {
            setQuery("");
          }}
        />
      </div>
      <TableContainer className={"table-container"}>
        {isLoading && <LoadingBackdrop size={40} />}
        <Table aria-labelledby="tableTitle" size={"small"} stickyHeader>
          <TableHead>
            <TableRow key={"head_row_popover"}>
              {headCells.map((headCell, index) => (
                <TableCell
                  key={`header_popover_${headCell.id}_${index}`}
                  className={
                    filters.sortedColumn === headCell.reference
                      ? "cellHead-list-picker-sorted"
                      : "cellHead-list-picker"
                  }
                  align={headCell.headAlign || "right"}
                  padding={"none"}
                  style={{ ...headCell.style }}
                  onClick={() => sortActualColumn(headCell.reference)}
                >
                  {headCell.label}
                  {headCell.canBeSort &&
                    renderArrow(headCell.reference, headCell.arrowStyle)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filterList
              .sort(
                sortObjectsBy(
                  filters.sortedColumn,
                  filters.orderBy === "ASC" ? true : false
                )
              )
              .map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    key={`row${row.id}_${index}`}
                    hover
                    onClick={(ev) => {
                      onChange(row);
                      ev.stopPropagation();
                    }}
                    tabIndex={-1}
                    className={"row-list-picker"}
                    classes={{ selected: "row-list-selected" }}
                  >
                    {headCells.map((column, indexColumn) => {
                      const value = column.format
                        ? column.format(row[column.id], customers)
                        : row[column.id] || "";
                      const maxLengthString = column.maxLength || 999;
                      return (
                        <TableCell
                          key={`${row.id}_${column.id}_${indexColumn}`}
                          align={column.align || "left"}
                          id={labelId}
                          scope="row"
                          style={{
                            ...column.style,
                            ...column.cellStyle,
                            borderBottom: "1px solid #D9E2EC",
                          }}
                          className={"cell-list-picker"}
                        >
                          <TooltipTD
                            style={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                            label={value}
                            showToolTip={value.length > maxLengthString}
                          >
                            {column.id === "number"
                              ? getShipmentBadge({
                                  shipment: row,
                                  styles: { margin: "0px auto 0px 28px" },
                                  classes: shipmentBadgeClass.medium,
                                  onChangeShipment: () => {},
                                  showToolTip: false,
                                })
                              : value}
                          </TooltipTD>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Popover>
  );
}

ListPicker.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ListPicker;
