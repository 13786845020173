import { getRandomId } from "../../helpers/helpers";

export default class Task {
  constructor({
    id = getRandomId(),
    description = "",
    assignedTo = "",
    assignedBy = "",
    stage = "",
    dependency = "",
    SONumber = "",
    PONumber = "",
    customerName = "",
    factoryName = "",
    assignedToName = "",
    type = "",
  }) {
    this.id = id;
    this.description = description;
    this.assignedBy = assignedBy;
    this.assignedTo = assignedTo;
    this.stage = stage;
    this.dependency = dependency;
    this.SONumber = SONumber;
    this.PONumber = PONumber;
    this.customerName = customerName;
    this.factoryName = factoryName;
    this.assignedToName = assignedToName;
    this.type = type;
  }
}
