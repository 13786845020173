import { styled } from "@mui/material";
import { colors } from "../../assets/jss/variables";

export const SearchBoxStyled = styled("div")(() => ({
  "&.searchContainer": {
    display: "flex",
    alignItems: "center",
    justifySelf: "flex-end",
    backgroundColor: "#fff",
    height: 40,
    border: "1px solid #D2DFEC",
    borderRadius: 4,
    padding: "0 10px",
    "&:focus-within": {
      boxShadow: "rgb(93 146 244 / 25%) 0 0 0 0.2rem",
      borderColor: colors.radioCheck,
    },
  },
  "& .inputSearch": {
    flex: 1,
    height: 40,
  },
  "& .buttonX": {
    background: "#FFF",
    color: "darkGray",
    width: 15,
    height: 15,
    borderRadius: "50%",
    margin: "0px 8px",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
