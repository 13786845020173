import { now } from "moment";
import { getRandomId } from "../../helpers/helpers";

export default class Favorite {
  constructor({
    id = getRandomId(),
    creationDate = now(),
    size = 1,
    list = {},
  }) {
    this.id = id;
    this.creationDate = creationDate;
    this.size = size;
    this.list = list;
  }
}
