import React from "react";
import { dbTables } from "../../api/types/dbTables";
import { useData } from "../../hooks";
import FeatureFlag from "../FeatureFlags/FeatureFlag";
import { useParams } from "react-router-dom";

function FeatureFlags() {
  const { companyId } = useParams();
  const featureFlags = useData(
    [dbTables.COMPANIES, companyId, dbTables.FEATURE_FLAGS],
    [companyId]
  );
  return <FeatureFlag companyID={companyId} featureFlags={featureFlags} />;
}

export default FeatureFlags;
