import { getRandomId } from "../../helpers/helpers";
import { now } from "moment";

export default class RolesPermissionsModel {
  constructor({ id = getRandomId(), creationDate = now(), permissions = {} }) {
    this.id = id;
    this.creationDate = creationDate;
    this.permissions = permissions;
  }
}
