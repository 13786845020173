import React, { useEffect, useState } from "react";
import { AttentionModalStyled } from "./style";
import { CSVLink } from "react-csv";
import { Fade } from "@mui/material";

function CSVModalDownload({
  isOpen,
  onClose,
  title,
  description,
  onClick,
  confirmationText = "Ok",
  cancelText = "No",
  csvBody = [],
  csvHeader = [],
  filename,
  filters,
  currentExpenses,
}) {
  const [wait, setWait] = useState(true);
  const runData = () => {
    return setTimeout(() => {
      const el = document.getElementById("csvDownloadData");
      if (el) {
        el.click();
      }
      onClick();
    }, 300);
  };
  useEffect(() => {
    if (isOpen && filters.exported) {
      const expensesNotExported = currentExpenses.filter(
        (expense) => !expense.isExported
      );
      if (expensesNotExported.length === 0) {
        runData();
        return;
      }
    }
    setWait(false);
  }, [isOpen]);

  const isEmpty = currentExpenses.length === 0;

  return (
    <AttentionModalStyled
      id="attentionModal"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={"rootModal"}
      style={{ opacity: wait ? 0 : 1 }}
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      onKeyDown={(ev) => {
        if (ev.key === "Enter") {
          onClick();
        }
      }}
    >
      <Fade in={isOpen}>
        <div className={"attentionModalPaper"}>
          <div className={"attentionModalHeader"}>{title}</div>
          <div className={"attentionModalBody"}>
            {isEmpty ? "There are no expenses to download" : description}
          </div>
          <div className={"attentionModalFooter"}>
            {!isEmpty && (
              <CSVLink
                id="csvDownloadData"
                data={csvBody}
                headers={csvHeader}
                filename={filename}
                onClick={() => {
                  onClick();
                  return true;
                }}
              >
                <button className={"buttonConfirmationBlue"}>
                  {confirmationText}
                </button>
              </CSVLink>
            )}
            <button
              className={
                isEmpty ? "buttonConfirmationBlue" : "buttonCancelWhite"
              }
              onClick={onClose}
            >
              {isEmpty ? "OK" : cancelText}
            </button>
          </div>
        </div>
      </Fade>
    </AttentionModalStyled>
  );
}

export default CSVModalDownload;
