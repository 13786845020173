import React from "react";
import { ThreePanelButtonStyled } from "./styles";
import { ThreePanelIcon } from "../../helpers/iconSvgPath";
import { useNavigate } from "react-router-dom";
import { getDashboardSearchPath } from "../../helpers/helpers";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "../PurchaseOrderDashboard/sections/helpers";
import { useStorage } from "../../hooks";

function ThreePanelButton({
  soId,
  poId,
  shipmentId = "",
  tab = 0,
  style = {},
  alternativePath = "",
  clickAction = () => {},
}) {
  const navigate = useNavigate();
  const [storage = {}, setStorage] = useStorage("projectTasks");

  function changeDocumentTab() {
    let documentTab = storage.documentTab || {};
    documentTab = {
      ...documentTab,
      [poId]: tab === 0 ? undefined : tab,
    };
    setStorage("documentTab", documentTab);
  }

  return (
    <ThreePanelButtonStyled
      className="buttonContainerThreePanel"
      style={style}
      onClick={() => {
        changeDocumentTab();
        navigate(
          alternativePath
            ? alternativePath
            : getDashboardSearchPath({
                salesOrderId: soId,
                purchaseOrderId: poId,
                shipmentId: shipmentId,
                section: ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION,
              })
        );
        clickAction();
      }}
    >
      <ThreePanelIcon />
    </ThreePanelButtonStyled>
  );
}

export default ThreePanelButton;
