import { Chip, TextField } from "@mui/material";
import React from "react";
import { AutoCompleteWithChipsStyled } from "./styles";
import { sortObjectsBy } from "../../helpers/helpers";

function AutoCompleteWithChips({
  options = [],
  selectedOptions = [],
  label = "name",
  onChange = () => {},
  disableClearable = true,
  onBlur = () => {},
  id = "autocomplete-chips-id",
  canAssign,
  canUnassign,
}) {
  return (
    <AutoCompleteWithChipsStyled
      multiple
      id={id}
      options={options.sort(sortObjectsBy("name", false)).filter((option) => {
        return !selectedOptions.find((selectedOption) => {
          return selectedOption.id === option.id;
        });
      })}
      getOptionLabel={(option) => option[label]}
      value={selectedOptions}
      onChange={onChange}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            classes={{
              root: "autocomplete-chip",
              deleteIcon: !canUnassign
                ? "deleteIcon-non-displaying"
                : "autocomplete-chip-deleteIcon",
            }}
            variant="outlined"
            label={option[label]}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField {...params} variant="filled" hiddenLabel={true} />
      )}
      onBlur={onBlur}
      disableClearable={disableClearable}
      classes={{
        root: "autocomplete-chips",
        endAdornment: "autocomplete-chips-endAdornment",
        option: "autocomplete-chips-option",
        paper: "autocomplete-chips-paper",
        popper: "autocomplete-chips-popper",
        input: "autocomplete-chips-input",
        inputRoot: "autocomplete-chips-inputRoot",
        listbox: "autocomplete-chips-listbox",
        tag: "autocomplete-chips-tag",
      }}
      ListboxProps={{
        style: {
          display: "flex",
          flexWrap: "wrap",
        },
      }}
      disableCloseOnSelect={true}
      getOptionDisabled={() => !canAssign}
    />
  );
}

export default AutoCompleteWithChips;
