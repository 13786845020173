import React, { useState, useEffect } from "react";
import { dbTables } from "../../api/types/dbTables";

import AttentionModal from "../Modal/AttentionModal";
import { useUser, useCompanyUsers } from "../../hooks/user";
import ReadOnlyModal from "../Modal/ReadOnlyModal";
import { isTradeDashEmployee } from "../../helpers/helpers";
import { firestore } from "../../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { SettingsStyled } from "./styles";

function Settings() {
  const { companyId } = useParams();
  const user = useUser();
  const [apiKey, setApiKey] = useState({});
  const [company, setCompany] = useState({});
  const [openModalAttention, setOpenModalAttention] = useState(false);
  const [openReadOnlyModal, setReadOnlyModal] = useState(false);

  const [descriptionModalAttention, setDescriptionModalAttention] =
    useState("");

  const users = useCompanyUsers({ id: companyId, showBotUser: true });

  useEffect(() => {
    getCompany(companyId);
  }, []);

  async function getCompany() {
    const companySnap = await getDoc(
      doc(firestore, `${dbTables.COMPANIES}/${companyId}`)
    );
    const company = { ...companySnap.data(), ref: companySnap.ref };
    if (company.apiKey) {
      setApiKey({
        ...apiKey,
        apiKey: company.apiKey,
        networkKey: company.networkKey,
      });
    }
    setCompany(company);
  }

  function generateApiKey(field) {
    if (isTradeDashEmployee(user)) {
      setReadOnlyModal(true);
      return;
    }
    const size = 64;
    const keyWords =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let apiKey = "";
    for (let i = 0, n = keyWords.length; i < size; ++i) {
      apiKey += keyWords.charAt(Math.floor(Math.random() * n));
    }
    setApiKey({ ...apiKey, [field]: apiKey });
    updateDoc(company.ref, {
      [field]: apiKey,
    });
  }

  function onCopy(elementId) {
    const copyLink = document.getElementById(elementId);
    copyLink.select();
    document.execCommand("copy");
  }

  function handleAssignUser(value) {
    updateDoc(company.ref, {
      userAPI: value,
    });
  }

  return (
    <SettingsStyled className="settingsContainer">
      {openModalAttention && (
        <AttentionModal
          title="Attention"
          description={descriptionModalAttention}
          isOpen={openModalAttention}
          onClick={() => {
            generateApiKey("networkKey");
            setOpenModalAttention(false);
          }}
          cancellable={true}
          confirmationText="Yes"
          onClose={() => setOpenModalAttention(false)}
        />
      )}
      {openReadOnlyModal && (
        <ReadOnlyModal
          isOpen={openReadOnlyModal}
          onClick={() => setReadOnlyModal(false)}
          onClose={() => setReadOnlyModal(false)}
        />
      )}
      <div style={{ display: "flex", padding: 8 }}>
        <div>
          <div>Generate API Key</div>
          <div>
            <button onClick={() => generateApiKey("apiKey")}>
              Generate API key
            </button>
            <input
              id="inputApiKey"
              value={apiKey["apiKey"]}
              type="text"
              style={{ width: 650 }}
              readOnly
            />
            <button onClick={() => onCopy("inputApiKey")}>Copy</button>
          </div>
        </div>
        <div>
          <div>Username for the Activity Stream</div>
          <div>
            <select
              onChange={(event) => handleAssignUser(event.target.value)}
              style={{ height: "32px", width: "150px", margin: "0px 8px" }}
              disabled={isTradeDashEmployee(user)}
            >
              {!company.userAPI && <option>Select user</option>}
              {users.map((user) => (
                <option value={user.id} selected={user.id === company.userAPI}>
                  @{user.displayName}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div style={{ padding: 8 }}>
        <div>Generate Network Key</div>
        <div>
          <button
            onClick={() => {
              if (isTradeDashEmployee(user)) {
                setReadOnlyModal(true);
              } else {
                setOpenModalAttention(true);
                setDescriptionModalAttention(
                  "Do you really want to replace this key? Export to TradeDash will STOP!"
                );
              }
            }}
          >
            Generate Network key
          </button>
          <input
            id="networkKey"
            value={apiKey["networkKey"]}
            type="text"
            style={{ width: 650 }}
            readOnly
          />
          <button onClick={() => onCopy("networkKey")}>Copy</button>
        </div>
      </div>
    </SettingsStyled>
  );
}

export default Settings;
