import React, { useContext, useState } from "react";
import AssignedToIcon from "../../../assets/flag-icons/assignedToIcon.svg";
import DiamondLegend from "../../../components/LegendStatus/DiamondLegend";
import TaskStatusFilters from "../../../components/ListViews/TaskStatusFilters";
import ButtonFilter from "../../../components/PurchaseOrderList/ButtonFilter";
import {
  ArrowLeftIcon,
  HiddenDataIcon,
  SalesOrderIcon,
} from "../../../helpers/iconSvgPath";
import MultiSelectFilter from "../../../components/FilterTable/MultiSelectFilter";
import { getListOptions } from "../../../components/PurchaseOrderDashboard/HelperProjectTasks";
import { useCompanyUsers, useUser } from "../../../hooks/user";
import FilterDisplay from "../../../components/PurchaseOrderList/FilterDisplay";
import { getToolTipArrayValue } from "../../../helpers/purchaseOrderList";
import { TaskGroupedByProjectContext } from "./TaskByProjectContext";
import { useMediaQuery } from "@mui/material";
import {
  entitiesFilteredByCustomer,
  getTotalTasksByStatus,
  purchaseOrdersFilteredByVendor,
} from "../../../helpers/tasks";
import TooltipTD from "../../../components/Tooltip/TooltipTD";

function TasksByProjectFilters({
  onGroupBySalesOrder,
  onChangeFilters = () => {},
  filters,
  onOpenAll = () => {},
  openAll = false,
}) {
  const [currentList, setCurrentList] = useState("");
  const screenMidSize = useMediaQuery("(max-width:1624.95px)");
  const companyUsers = useCompanyUsers({
    showBotUser: false,
    showInactiveUsers: false,
  });
  const user = useUser();

  function changeButtonFilter(filter) {
    onChangeFilters({ ...filters, [filter]: !filters[filter] });
  }

  const { taskByProjectContext } = useContext(TaskGroupedByProjectContext);
  let {
    tasks = {},
    purchaseOrders = [],
    shipments = [],
    currentSalesOrder = {},
  } = taskByProjectContext;
  purchaseOrders = purchaseOrdersFilteredByVendor({ purchaseOrders, user });
  shipments = entitiesFilteredByCustomer({ entities: shipments, user });

  const filteredTasks = {};
  Object.keys(tasks).forEach((key) => {
    if (
      currentSalesOrder.id === key ||
      purchaseOrders.find((po) => po.id === key) ||
      shipments.find((shipment) => shipment.id === key)
    ) {
      filteredTasks[key] = tasks[key];
    }
  });

  const {
    totalLateTasks,
    totalNearDueTasks,
    totalInProgressTasks,
    totalNotStartedTasks,
    totalCompletedTasks,
    totalTasks,
  } = getTotalTasksByStatus({
    tasksObj: filteredTasks,
    filters,
    companyUsers,
  });

  return (
    <div className="contentOptions">
      <DiamondLegend
        lateText={`Late (${totalLateTasks})`}
        nearDueText={`Due Today or Tomorrow (${totalNearDueTasks})`}
        inProgressText={`In Progress (${totalInProgressTasks})`}
        notStartedText={`Not Started (${totalNotStartedTasks})`}
        completedText={`Completed (${totalCompletedTasks})`}
        totalTasks={totalTasks}
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "0px 16px 16px 0px",
        }}
      />
      <div className="buttonsFilter">
        <ArrowLeftIcon onClick={onGroupBySalesOrder} className={"arrow"} />
        <TaskStatusFilters
          filters={filters}
          changeButtonFilter={changeButtonFilter}
          changeFilters={changeButtonFilter}
          taskByProject
        />
        <ButtonFilter
          buttonId="list-view-button-filter-groupbypo"
          value
          onClick={onGroupBySalesOrder}
          style={{ width: "auto" }}
          toolTip={screenMidSize && "Group by Sales Order"}
        >
          <SalesOrderIcon width={24} height={24} style={{ marginRight: 10 }} />
          {!screenMidSize && "Group by Sales Order"}
        </ButtonFilter>
        <MultiSelectFilter
          buttonId="list-view-select-filter-assignedto"
          value={getListOptions({
            filterList: filters.assignedTo,
            allList: companyUsers,
            key: {
              value: "id",
              label: "displayName",
              avatar: true,
            },
            prefix: "@",
          })}
          options={getListOptions({
            filterList: filters.assignedTo,
            allList: companyUsers,
            key: {
              value: "id",
              label: "displayName",
              avatar: true,
            },
            prefix: "@",
            showAll: true,
          })}
          label="Assigned To"
          icon={AssignedToIcon}
          onChange={(field) => {
            onChangeFilters({ ...filters, assignedTo: field });
          }}
          scope="users"
          placeholder="Search for person"
          currentList={currentList}
          setCurrentList={setCurrentList}
          canAssing
        />
        {filters.assignedTo.length > 0 && (
          <FilterDisplay
            clearIconId="list-view-filter-badge-clear-assignedto"
            onClear={() => onChangeFilters({ ...filters, assignedTo: [] })}
            label="Assigned To"
            tooltip={
              getToolTipArrayValue(
                filters.assignedTo,
                companyUsers,
                "displayName"
              ).tooltip
            }
            value={
              getToolTipArrayValue(
                filters.assignedTo,
                companyUsers,
                "displayName"
              ).value
            }
            filters={filters}
          />
        )}
      </div>
      <div className="toolsContainer">
        <div className="buttonTool" onClick={onOpenAll}>
          <TooltipTD label={openAll ? "Collapse All" : "Expand All"}>
            <HiddenDataIcon svgClass="expandRow" />
          </TooltipTD>
        </div>
      </div>
    </div>
  );
}

export default TasksByProjectFilters;
