import React from "react";

import IntlMessages from "../../util/IntlMessages";
import newFile from "../../assets/flag-icons/new-file-placeholder.svg";
import { dbTables } from "../../api/types/dbTables";
import { FolderIconv2 } from "../../helpers/iconSvgPath";
import { ManagementPlaceholderDocumentHeaderStyled } from "./styles";

function ManagementDocumentsHeader({
  title,
  handleAddNewFolder,
  handleAddNewFile,
  dbTable,
}) {
  const isShipmentDocuments = dbTable === dbTables.SHIPMENT_DOCUMENTS_TEMPLATE;
  return (
    <ManagementPlaceholderDocumentHeaderStyled className="documentHeader">
      <div className="documents-header-title-document">
        <IntlMessages id={title} />
      </div>
      <div className="documents-header-button-add-document">
        {!isShipmentDocuments && (
          <div className="add-new-folder" onClick={handleAddNewFolder}>
            <FolderIconv2 width={16} height={16} />
            <span>
              <IntlMessages id="documents.button.create.new.folder" />
            </span>
          </div>
        )}
        {!isShipmentDocuments && (
          <div className="add-new-folder" onClick={handleAddNewFile}>
            <img
              className={"img-new-folder-placeholder"}
              src={newFile}
              alt="file"
            />
            <span>
              <IntlMessages id="documents.button.create.new.file" />
            </span>
          </div>
        )}
      </div>
    </ManagementPlaceholderDocumentHeaderStyled>
  );
}

export default ManagementDocumentsHeader;
