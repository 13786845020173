import React from "react";
import { ViewOptionsPopoverStyled } from "./styles";
import TooltipTD from "../Tooltip/TooltipTD";
import { Button } from "@mui/material";
import CustomCheckbox from "../Inputs/CustomCheckbox";
import { ChevronIcon, SwitchIcon } from "../../helpers/iconSvgPath";
import { cx } from "@emotion/css";

function ViewOptions({
  viewOptionsArray,
  onChangeViewOptions,
  collapseListTask = false,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const viewOptionItem = ({ option }) => {
    return (
      <div
        key={option.label}
        className="view-option-item-container"
        onClick={() =>
          onChangeViewOptions({ ...option, enabled: !option.enabled })
        }
      >
        <CustomCheckbox
          checked={option.enabled}
          onChange={() =>
            onChangeViewOptions({ ...option, enabled: !option.enabled })
          }
          iconSize={21}
          styles={{ padding: 0, marginBottom: -2 }}
        />
        <span>{option.label}</span>
      </div>
    );
  };

  return (
    <>
      <Button
        className={cx("buttonViewOptions", {
          openButton: open,
          smallButton: collapseListTask,
        })}
        aria-describedby="simple-popover"
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        <TooltipTD
          showToolTip={collapseListTask}
          className={"tooltipContainer"}
          label={"View options"}
        >
          <SwitchIcon />
          <span className="labelOption">View Options</span>
          <ChevronIcon svgClass={"chrevronClass"} />
        </TooltipTD>
      </Button>
      {open && (
        <ViewOptionsPopoverStyled
          id="simple-popover"
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          classes={{ paper: "viewOptionsPopover" }}
          className="viewOptionsPopoverContainer"
        >
          <div className="view-options-left-container">
            {viewOptionsArray
              .filter((option) => option.position === "left")
              .map((option) => viewOptionItem({ option }))}
          </div>
          <div className="view-options-right-container">
            {viewOptionsArray
              .filter((option) => option.position === "right")
              .map((option) => viewOptionItem({ option }))}
          </div>
        </ViewOptionsPopoverStyled>
      )}
    </>
  );
}

export default ViewOptions;
