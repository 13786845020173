import moment from "moment";
import { getRandomId } from "../../helpers/helpers";

export default class DocumentToTrain {
  constructor({
    id = getRandomId(),
    creationDate = moment.now(),
    pdfURL = "",
    companyId = "",
    scope = "",
    parentId = "",
    confidenceAverage = "",
  }) {
    this.id = id;
    this.creationDate = creationDate;
    this.pdfURL = pdfURL;
    this.companyId = companyId;
    this.scope = scope;
    this.parentId = parentId;
    this.confidenceAverage = confidenceAverage;
  }
}
