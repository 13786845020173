import React from "react";
import Autocomplete from "../UserManagement/AutocompleteField";
import PropTypes from "prop-types";
import { useCompanyUsers } from "../../hooks/user";

function AutocompleteUsers({
  classes,
  value = [],
  options = [],
  onChange = () => {},
  isMulti,
  focused,
  id = "auto-complete-users-id",
  selectClassname,
  disabled = false,
}) {
  const companyUsers = useCompanyUsers({});

  function handleChange(value, lastValue) {
    const valueIds = value.map((item) => item.value);
    const lastValueIds = lastValue.map((item) => item.value);
    const users = companyUsers.filter((user) => valueIds.includes(user.id));
    const lastUsers = companyUsers.filter((user) =>
      lastValueIds.includes(user.id)
    );
    onChange(users, lastUsers);
  }

  return (
    <div className="autocomplete">
      <Autocomplete
        id={id}
        focused={focused}
        selectClassname={selectClassname}
        classes={{
          chip: "chip",
          root: "autocomplete-root",
          deleteIcon: "delete",
          valueContainer: "value-container",
          indicatorsContainer: "indicators",
          inputContainer: "input-container",
          input: "input",
          underline: "underline",
          inputFocused: "input-focused",
          paper: "paper-autocomplete-users",
          placeholder: "placeholder",
          menuItem: "menu-item",
          ...classes,
        }}
        onChange={handleChange}
        value={value}
        options={options}
        isMulti={isMulti}
        disabled={disabled}
      />
    </div>
  );
}

AutocompleteUsers.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    input: PropTypes.string,
    valueContainer: PropTypes.string,
    chip: PropTypes.string,
    chipFocused: PropTypes.string,
    noOptionsMessage: PropTypes.string,
    singleValue: PropTypes.string,
    placeholder: PropTypes.string,
    paper: PropTypes.string,
    divider: PropTypes.string,
    deleteIcon: PropTypes.string,
    inputFocused: PropTypes.string,
    indicatorsContainer: PropTypes.string,
    inputContainer: PropTypes.string,
    underline: PropTypes.string,
    menuItem: PropTypes.string,
  }),
};

export default AutocompleteUsers;
