module.exports = {
  "generic.text.create": "Crear",
  "generic.text.cancel": "Cancelar",
  "generic.text.delete": "Eliminar",
  "generic.text.edit": "Editar",
  "generic.text.yes": "Si",
  "generic.text.no": "No",
  "generic.text.back": "Atras",
  "generic.text.submit": "De acuerdo",
  "generic.text.accept": "Aceptar",
  "generic.text.confirm": "Confirmar",
  "generic.text.update": "Actualizar",
  "generic.text.save": "Guardar",
  "generic.text.void": "Void",
  "generic.text.reassign": "Reasignar",
  "generic.text.description": "Description",
  "generic.text.active": "Activo",
  "generic.text.active/inactive": "Activo/Desactivo",
  "generic.text.name": "Nombre",
  "generic.text.category": "Category",
  "generic.text.createdBy": "Creado por",
  "generic.text.dateCreated": "Fecha de creación",
  "generic.text.updatedBy": "Modificado por",
  "generic.text.dateUpdated": "Fecha de modificación",
  "generic.text.blank": " ",
  "generic.textForm.name": "Nombre",
  "generic.textForm.email": "Correo electronico",
  "generic.textForm.cellPhone": "Celular",
  "generic.textForm.firstName": "Nombre",
  "generic.textForm.lastName": "Apellido",
  "generic.textForm.displayName": "Nombre para mostrar",
  "generic.textForm.role": "Rol",
  "generic.textForm.permissionGroup": "Grupo de permisos",
  "generic.textForm.typeOfUser": "Tipo de usuario",

  "sidebar.feedback": "Realimentación",
  "sidebar.alerts": "Alertas",
  "sidebar.appBar": "Barra de aplicaciones",
  "sidebar.avatars": "Avatares",
  "sidebar.buttons": "Botones",
  "sidebar.bottomNavigations": "Navegaciones inferiores",
  "sidebar.badges": "Insignias",
  "sidebar.cards": "Tarjetas",
  "sidebar.cardsMasonry": "Tarjetas Masonary",
  "sidebar.chip": "Chip",
  "sidebar.dialog": "Diálogo",
  "sidebar.dividers": "Divisores",
  "sidebar.drawers": "Cajones",
  "sidebar.popover": "Popover",
  "sidebar.expansionPanel": "Panel de Expansión",
  "sidebar.gridList": "Lista de cuadrícula",
  "sidebar.list": "Lista",
  "sidebar.menu": "Menú",
  "sidebar.progress": "Progreso",
  "sidebar.snackbar": "Snackbar",
  "sidebar.selectionControls": "Controles de selección",
  "sidebar.tabs": "Pestañas",
  "sidebar.permissionManagement": "Gestión de usuarios",
  invoice: "Invoice",
  "title.text.GLAccount": "G/L Account",
  "title.text.GLAccount.update": "Actualizar G/L Account",
  "title.text.GLAccount.create": "Crear G/L Account",
  "title.text.expenses": "Expenses",
  "widgets.ratings": "Calificaciones",
  "widgets.currentDate": "Fecha actual",
  "widgets.todayOrders": "Pedidos de hoy",
  "widgets.toDoList": "Para hacer listas",
  "widgets.discoverPeople": "Descubre personas",
  "widgets.commments": "Comentarios",
  "widgets.newCustomers": "nuevos clientes",
  "widgets.recentNotifications": "Notificaciones recientes",
  "widgets.appNotifications": "Notificaciones de aplicación",
  "widgets.newEmails": "Nuevos correos electrónicos",
  "widgets.siteVisitors": "Visitantes del sitio",
  "widgets.recentActivities": "Actividades recientes",
  "widgets.gallery": "Galería",
  "widgets.pricing": "Precios",
  "widgets.enterpriseEdition": "Edición de Empresa",
  "widgets.personalEdition": "Edición personal",
  "widgets.socialCompanines": "Empresas sociales",
  "widgets.teamEdition": "Edición de equipo",
  "widgets.standard": "Estándar",
  "widgets.advanced": "Avanzado",
  "widgets.master": "MasterDominar",
  "widgets.Mega": "MegaMega",
  "widgets.logIn": "Iniciar sesión",
  "widgets.signUp": "Regístrate",
  "widgets.lockScreen": "Bloquear pantalla",
  "widgets.alertsWithLink": "Alertas con enlace",
  "widgets.additionalContent": "Contenido adicional",
  "widgets.alertDismiss": "Alerta de descartar",
  "widgets.uncontrolledDisableAlerts": "Alertas de Deshabilitar descontrolado",
  "widgets.contexualAlerts": "Alertas Contexuales",
  "widgets.alertsWithIcons": "Alertas con iconos",
  "widgets.Simple App Bars": "Barras de aplicación simples",
  "widgets.appBarsWithButtons": "Barras de aplicación con botones",
  "widgets.imageAvatars": "Avatares de imagen",
  "widgets.lettersAvatars": "Cartas Avatares",
  "widgets.iconsAvatars": "Iconos Avatares",
  "widgets.flatButtons": "Botones planos",
  "widgets.raisedButton": "Botón elevado",
  "widgets.buttonWithIconAndLabel": "Botón con icono y etiqueta",
  "widgets.floatingActionButtons": "Botones de acción flotante",
  "widgets.iconButton": "Botón de icono",
  "widgets.socialMediaButton": "Botón de redes sociales",
  "widgets.reactButton": "React Button",
  "widgets.buttonOutline": "Contorno del botón",
  "widgets.buttonSize": "Tamaño del botón",
  "widgets.buttonState": "Estado del botón",
  "widgets.buttonNavigationWithNoLabel": "botón Navegación sin etiqueta",
  "widgets.buttonNavigation": "Navegación de botón",
  "widgets.iconNavigation": "Navegación de iconos",
  "widgets.badgeWithHeadings": "Insignia con títulos",
  "widgets.contexualVariations": "Variaciones Contexuales",
  "widgets.badgeLinks": "Enlaces de placa",
  "widgets.materialBadge": "Insignia de material",
  "widgets.simpleCards": "Tarjetas simples",
  "widgets.backgroundVarient": "Variente de fondo",
  "widgets.cardOutline": "Esquema de la tarjeta",
  "widgets.overlayCard": "Tarjeta de superposición",
  "widgets.cardGroup": "Grupo de tarjetas",
  "widgets.cardTitle": "Título de la tarjeta",
  "widgets.speacialTitleTreatment": "Tratamiento de título especial",
  "widgets.chipWithClickEvent": "Chip con evento Click",
  "widgets.chipArray": "Chip Array",
  "widgets.dialogs": "Diálogos",
  "widgets.listDividers": "List Dividers",
  "widgets.insetDividers": "Divisores de inserción",
  "widgets.temporaryDrawers": "Cajones temporales",
  "widgets.permanentDrawers": "Cajones permanentes",
  "widgets.simpleExpansionPanel": "Panel de expansión simple",
  "widgets.controlledAccordion": "Acordeón controlado",
  "widgets.secondaryHeadingAndColumns": "Encabezado secundario y columnas",
  "widgets.imageOnlyGridLists": "Las listas de cuadrícula solo con imágenes",
  "widgets.advancedGridLists": "Listas de grillas avanzadas",
  "widgets.singleLineGridLists": "Listas de cuadrículas unifilares",
  "widgets.simpleLists": "Listas simples",
  "widgets.folderLists": "Listas de carpetas",
  "widgets.listItemWithImage": "Artículo de lista con imagen",
  "widgets.switchLists": "Cambiar listas",
  "widgets.insetLists": "Listas de inserción",
  "widgets.nestedLists": "Listas anidadas",
  "widgets.checkboxListControl": "Control de lista de casillas de verificación",
  "widgets.pinedSubHeader": "Sub encabezado pined",
  "widgets.InteractiveLists": "Listas interactivas",
  "widgets.simpleMenus": "Menús simples",
  "widgets.selectedMenu": "Menú Seleccionado",
  "widgets.maxHeightMenu": "Menú de altura máxima",
  "widgets.changeTransition": "Cambiar la transición",
  "widgets.paper": "Papel",
  "widgets.anchorPlayGround": "ancla Patio de recreo",
  "widgets.tooltip": "Información sobre herramientas",
  "widgets.positionedToolTips": "Barra de Snack Posicionado",
  "widgets.circularProgressBottomStart": "Inicio inferior de progreso circular",
  "widgets.interactiveIntegration": "Integración Interactiva",
  "widgets.determinate": "Determinado",
  "widgets.linearProgressLineBar": "Lineal Progreso Línea Bar",
  "widgets.indeterminate": "Indeterminado",
  "widgets.buffer": "Buffer",
  "widgets.query": "Consulta",
  "widgets.transitionControlDirection": "Dirección de control de transición",
  "widgets.simpleSnackbar": "Snackbar simple",
  "widgets.positionedSnackbar": "Barra de Snack Posicionado",
  "widgets.contexualColoredSnackbars": "Snackbars de colores contextuales",
  "widgets.simpleCheckbox": "Casilla de verificación simple",
  "widgets.interminateSelection": "Selección Interminada",
  "widgets.disabledCheckbox": "Casilla de verificación desactivada",
  "widgets.customColorCheckbox":
    "Casilla de verificación de color personalizado",
  "widgets.VerticalStyleCheckbox": "Casilla de verificación de estilo vertical",
  "widgets.horizontalStyleCheckbox":
    "Casilla de verificación de estilo horizontal",
  "widgets.radioButtons": "Botones de radio",
  "widgets.disabledRadio": "Radio inhabilitada",
  "widgets.withError": "Con error",
  "widgets.switches": "Swiches",
  "widgets.dateAndTimePicker": "Selector de fecha y hora",
  "widgets.defaultPicker": "Selector predeterminado",
  "widgets.timePicker": "Selector de tiempo",
  "widgets.weekPicker": "Selector de semana",
  "widgets.defaultDatePicker": "Selector de fecha predeterminado",
  "widgets.customPicker": "Selector personalizado",
  "widgets.tabs": "Pestañas",
  "widgets.fixedTabs": "Pestañas fijas",
  "widgets.basicTab": "Pestaña básica",
  "widgets.wrappedLabels": "Etiquetas envueltas",
  "widgets.centeredLabels": "Etiquetas centradas",
  "widgets.forcedScrolledButtons": "Botones desplazados forzados",
  "widgets.iconsTabs": "Pestañas de iconos",
  "widgets.withDisableTabs": "Con Inhabilitar Pestañas",
  "widgets.iconWithLabel": "Icono con etiqueta",
  "widgets.stepper": "Paso a paso",
  "widgets.horizontalLinear": "Horizontal lineal",
  "widgets.horizontalNonLinear": "Horizontal no lineal",
  "widgets.horizontalLinerAlternativeLabel":
    "Etiqueta alternativa horizontal del trazador de líneas",
  "widgets.horizontalNonLinerAlternativeLabel":
    "Etiqueta alternativa horizontal sin revestimiento",
  "widgets.verticalStepper": "Stepper vertical",
  "widgets.descriptionAlert": "Descripción Alerta",
  "widgets.customIconAlert": "Alerta de icono personalizado",
  "widgets.withHtmlAlert": "Con Html Alert",
  "widgets.promptAlert": "Aviso rápido",
  "widgets.passwordPromptAlert": "Alerta de solicitud de contraseña",
  "widgets.customStyleAlert": "Alerta de estilo personalizado",
  "widgets.autoComplete": "Auto completo",
  "widgets.reactSelect": "React Select",
  "widgets.downshiftAutoComplete": "Cambio descendente Auto Completar",
  "widgets.reactAutoSuggests": "Reaccionar Auto Sugiere",
  "widgets.aboutUs": "Sobre nosotros",
  "widgets.ourVission": "Nuestra Vision",
  "widgets.ourMissions": "Nuestras Misiones",
  "widgets.ourMotivation": "Nuestra motivación",
  "widgets.defualtReactForm": "Formulario de declaración de defualt",
  "widgets.url": "Url",
  "widgets.textArea": "Área de texto",
  "widgets.file": "Archivo",
  "widgets.formGrid": "Formulario Cuadrícula",
  "widgets.inlineForm": "Formulario en línea",
  "widgets.inputSizing": "Tamaño de entrada",
  "widgets.inputGridSizing": "Tamaño de cuadrícula de entrada",
  "widgets.hiddenLabels": "Etiquetas escondidas",
  "widgets.formValidation": "Formar Validación",
  "widgets.number": "Número",
  "widgets.date": "Fecha",
  "widgets.time": "Hora",
  "widgets.color": "Color",
  "widgets.search": "Buscar",
  "widgets.selectMultiple": "Seleccionar múltiples",
  "widgets.inputWithSuccess": "Entrada con éxito",
  "widgets.inputWithDanger": "Entrada con peligro",
  "widgets.simpleTextField": "Campo de texto simple",
  "widgets.componet": "Componentes",
  "widgets.layouts": "Diseños",
  "widgets.inputAdorements": "Adoración de entrada",
  "widgets.formattedInputs": "Entradas formateadas",
  "widgets.simpleSelect": "Simple Select",
  "widgets.nativeSelect": "Selección nativa",
  "widgets.MutltiSelectList": "Lista de selección Mutlti",
  "widgets.lineChart": "Gráfico de linea",
  "widgets.barChart": "Gráfico de barras",
  "widgets.stackedBarChart": "Gráfico de barras apiladas",
  "widgets.lineBarAreaChart": "Gráfico de área de la barra de líneas",
  "widgets.areaChart": "Carta de área",
  "widgets.stackedAreaChart": "Tabla de áreas apiladas",
  "widgets.verticalChart": "Gráfico vertical",
  "widgets.radarChart": "Gráfico de radar",
  "widgets.doughnut": "Rosquilla",
  "widgets.polarChart": "Gráfico polar",
  "widgets.pieChart": "Gráfico circular",
  "widgets.bubbleChart": "Bubble Gráfico",
  "widgets.horizontalBar": "Barra Horizonatl",
  "widgets.basicTable": "Tabla básica",
  "widgets.contexualColoredTable": "Tabla de colores Contexual",
  "widgets.dataTable": "Tabla de datos",
  "widgets.employeeList": "Lista de empleados",
  "widgets.responsiveTable": "Tabla receptiva",
  "widgets.responsiveFlipTable": "Mesa giratoria receptiva",
  "widgets.reactGridControlledStateMode":
    "Reaccionar Cuadrícula Revisado Estado Modo",
  "widgets.googleMaps": "mapas de Google",
  "widgets.productsReports": "Informes de productos",
  "widgets.taskList": "Lista de tareas",
  "widgets.basicCalender": "Calendario básico",
  "widgets.culturesCalender": "Calendario de las culturas",
  "widgets.dragAndDropCalender": "Calendario de arrastrar y soltar",
  "widgets.selectableCalender": "Calendario seleccionable",
  "widgets.customRendering": "Representación personalizada",
  "widgets.customCalender": "Calendario personalizado",
  "widgets.searchMailList": "Buscar lista de correo",
  "widgets.forgetPassword": "Contraseña olvidada",

  "components.userManagement.userRolesModal.createRoles": "Crear un rol",
  "components.userManagement.userPermissionsModal.addUserRoles":
    "Agregar usuarios a este rol",
  "components.userManagement.userCompany.createUser": "Crear Usuario",
  "components.saleOrders.ActionsModal.voidPO": "Void PO",
  "components.saleOrders.ActionsModal.voidPOPQ": "Void PO/PQ",
  "components.buyNow": "Compra ahora",
  "compenets.dontHaveAccountSignUp": "No tiene registro de cuenta",
  "compenets.enterUserName": "Introduzca su nombre de usuario",
  "compenets.enterEmailAddress":
    "Introducir la dirección de correo electrónico",
  "compenets.confirmPasswords": "Confirmar contraseñas",
  "components.alreadyHavingAccountSignIn": "Ya tienes cuenta Inicia sesión",
  "components.enterYourPassword": "Ingresa tu contraseña",
  "components.unlock": "desbloquear",
  "components.enterPasswords": "Ingrese contraseñas",
  "components.resetPassword": "Rescatar contraseña",
  "components.pageNotfound": "Página no encontrada",
  "components.goToHomePage": "Ir a la página de inicio",
  "components.sorryServerGoesWrong": "Lo sentimos Servidor va mal",
  "components.persistentDrawer": "Cajón persistente",
  "components.back": "Espalda",
  "components.next": "Siguiente",
  "components.completeStep": "Paso completo",
  "components.withHtml": "Con Html",
  "components.prompt": "Rápido",
  "components.withDescription": "Con descripción",
  "components.success": "Éxito",
  "components.passwordPrompt": "Indicación de contraseña",
  "components.warning": "Advertencia",
  "components.customIcon": "Icono personalizado",
  "components.customStyle": "Estilo personalizado",
  "components.basic": "BASIC",
  "components.submit": "Enviar",
  "components.compose": "Componer",
  "components.sendMessage": "Enviar mensaje",
  "components.addNewTasks": "Agregar nuevas tareas",
  "components.addToCart": "Añadir a la cesta",
  "components.payNow": "Pague ahora",
  "components.print": "Impresión",
  "components.cart": "Carro",
  "components.viewCart": "Ver carro",
  "components.checkout": "Revisa",
  "widgets.QuickLinks": "enlaces rápidos",
  "widgets.upgrade": "mejorar",
  "widgets.app": "Aplicación",
  "widgets.addNew": "Agregar nuevo",
  "widgets.orderDate": "Fecha de orden",
  "widgets.status": "Estado",
  "widgets.trackingNumber": "El número de rastreo",
  "widgets.action": "Acción",
  "widgets.designation": "Designacion",
  "widgets.subject": "tema",
  "widgets.send": "Enviar",
  "widgets.saveAsDrafts": "Guardar como borradores",
  "widgets.onlineSources": "Fuentes en línea",
  "widgets.lastMonth": "El mes pasado",
  "widgets.widgets": "Widgets",
  "widgets.listing": "Listado",
  "widgets.paid": "Pagado",
  "widgets.refunded": "Reintegrado",
  "widgets.done": "Hecho",
  "widgets.pending": "Pendiente",
  "widgets.cancelled": "Cancelado",
  "widgets.approve": "Aprobar",
  "widgets.following": "Siguiendo",
  "widgets.follow": "seguir",
  "widgets.graphs&Charts": "Gráficos y gráficos",
  "widgets.open": "Abierto",
  "widgets.bounced": "Rebotado",
  "widgets.spam": "Correo no deseado",
  "widgets.unset": "Desarmado",
  "widgets.bandwidthUse": "Uso del ancho de banda",
  "widgets.dataUse": "Uso de datos",
  "widgets.unsubscribe": "Darse de baja",
  "widgets.profile": "Perfil",
  "widgets.messages": "Mensajes",
  "widgets.support": "Apoyo",
  "widgets.faq(s)": "Faq (s)",
  "widgets.upgradePlains": "Actualizar llanuras",
  "widgets.logOut": "Cerrar sesión",
  "widgets.mail": "Correo",
  "widgets.adminTheme": "Tema de Admin",
  "widgets.wordpressTheme": "Tema de Wordpress",
  "widgets.addToCart": "Añadir a la cesta",
  "widgets.plan": "Plan",
  "widgets.basic": "BASIC",
  "widgets.pro": "Pro",
  "widgets.startToBasic": "Comience a básico",
  "widgets.upgradeToPro": "Actualizar a Pro",
  "widgets.upgradeToAdvance": "Mejorar A Avanzar",
  "widgets.comparePlans": "Comparar Planes",
  "widgets.free": "Gratis",
  "widgets.frequentlyAskedQuestions": "Frecuentemente Preguntó Preguntas",
  "widgets.searchIdeas": "Buscar Ideas",
  "widgets.startDate": "comienzo Fecha",
  "widgets.endDate": "Fin Fecha",
  "widgets.category": "Categoría",
  "widgets.apply": "Apply",
  "widgets.downloadPdfReport": "Descargar Pdf Report",
  "widgets.yesterday": "Ayer",
  "widgets.totalOrders": "Total de pedidos",
  "widgets.totalVisitors": "Visitantes totales",
  "widgets.typeYourQuestions": "Escriba sus preguntas",
  "widgets.username": "Nombre de usuario",
  "widgets.password": "Contraseña",
  "widgets.signIn": "Registrarse",
  "widgets.enterYourPassword": "Ingresa tu contraseña",
  "widgets.alreadyHavingAccountLogin": "Ya tienes acceso a la cuenta",
  "widgets.composeMail": "Redactar correo",
  "widgets.issue": "Problema",
  "widgets.recentChat": "Charla reciente",
  "widgets.previousChat": "Charla anterior",
  "widgets.all": "Todas",
  "widgets.filters": "Filtros",
  "widgets.deleted": "Eliminado",
  "widgets.starred": "Sembrado de estrellas",
  "widgets.frontend": "Interfaz",
  "widgets.backend": "Backend",
  "widgets.api": "Api",
  "widgets.simpleAppBar": "Barra de aplicaciones simple",
  "widgets.recents": "Recientes",
  "widgets.cardLink": "Enlace de tarjeta",
  "widgets.anotherLink": "Otro enlace",
  "widgets.cardSubtitle": "tarjetaSubtittle",
  "widgets.confirmationDialogs": "Diálogos de confirmación",
  "widgets.deletableChip": "Chip eliminable",
  "widgets.customDeleteIconChip": "Chip de icono de eliminación personalizada",
  "widgets.openAlertDialog": "Diálogo de alerta abierta",
  "widgets.openResponsiveDialog": "Diálogo receptivo abierto",
  "widgets.openSimpleDialog": "Abrir un cuadro de diálogo simple",
  "widgets.openFormDialog": "Abrir el cuadro de diálogo de formulario",
  "widgets.follower": "Seguidor",
  "widgets.important": "Importante",
  "widgets.private": "Privado",
  "widgets.openLeft": "Abierto a la izquierda",
  "widgets.openRight": "Abierto a la derecha",
  "widgets.openTop": "Tapa abierta",
  "widgets.openBottom": "Parte inferior abierta",
  "widgets.selectTripDestination": "Seleccione el destino del viaje",
  "widgets.pinnedSubheaderList": "Lista de subcarpetas fijadas",
  "widgets.singleLineItem": "Artículo de línea única",
  "widgets.acceptTerms": "Aceptar los terminos",
  "widgets.optionA": "Opción A",
  "widgets.optionB": "Opción B",
  "widgets.optionC": "Opción C",
  "widgets.optionM": "Opción M",
  "widgets.optionN": "Opción N",
  "widgets.optionO": "Opción O",
  "widgets.customColor": "Color personalizado",
  "widgets.centeredTabs": "Fichas centradas",
  "widgets.multipleTabs": "Múltiples pestañas",
  "widgets.preventScrolledButtons": "Evitar botones desplazados",
  "widgets.browse": "Vistazo",
  "widgets.formValidate": "Formulario Validar",
  "widgets.code": "Código",
  "widgets.company": "Empresa",
  "widgets.price": "Precio",
  "widgets.change": "Cambio",
  "widgets.high": "Alto",
  "widgets.low": "Bajo",
  "widgets.volume": "Volumen",
  "widgets.personalDetails": "Detalles personales",
  "widgets.occupation": "Ocupación",
  "widgets.companyName": "nombre de empresa",
  "widgets.phoneNo": "Telefono no",
  "widgets.city": "Ciudad",
  "widgets.zipCode": "Código postal",
  "widgets.updateProfile": "Actualización del perfil",
  "widgets.reject": "Rechazar",
  "widgets.exportToExcel": "Exportar a Excel",
  "widgets.addNewUser": "Añadir nuevo usuario",
  "widgets.workWeek": "Semana de trabajo",
  "widgets.agenda": "Agenda",
  "widgets.conference": "Conferencia",
  "widgets.dailySales": "Ventas diarias",
  "widgets.today": "Hoy",
  "widgets.campaignPerformance": "Rendimiento de la campaña",
  "widgets.supportRequest": "Solicitud de soporte",
  "widgets.usersList": "Lista de usuarios",
  "widgets.lastWeek": "La semana pasada",
  "themeOptions.sidebarOverlay": "Superposición de barra lateral",
  "themeOptions.sidebarBackgroundImages":
    "Imágenes de fondo de la barra lateral",
  "themeOptions.sidebarImage": "Imagen de la barra lateral",
  "themeOptions.miniSidebar": "Mini barra lateral",
  "themeOptions.boxLayout": "Diseño de caja",
  "themeOptions.rtlLayout": "Rtl Layout",
  "themeOptions.darkMode": "Modo oscuro",
  "themeOptions.appSettings": "Ajustes de Aplicacion",
  "themeOptions.sidebarLight": "Ligero",
  "themeOptions.sidebarDark": "Oscuro",
  "sidebar.dateTimePicker": "Selector de fecha y hora",
  "components.summary": "Resumen",
  "hint.whatAreYouLookingFor": "Qué estás buscando",
  "components.yesterday": "Ayer",
  "components.last7Days": "últimos 7 días",
  "components.last1Month": "Último 1 mes",
  "components.last6Month": "Últimos 6 meses",
  "components.spaceUsed": "Espacio utilizado",
  "components.followers": "Seguidor",
  "components.trending": "Tendencia",
  "components.paid": "Pagado",
  "components.refunded": "Reembolsado",
  "components.done": "Hecho",
  "components.pending": "Pendiente",
  "components.cancelled": "Cancelado",
  "components.approve": "Aprobar",
  "components.week": "Semana",
  "components.month": "Mes",
  "components.year": "Año",
  "components.today": "Hoy",
  "componentes.popularity": "Popularidad",
  "components.drafts": "Borradores",
  "components.sent": "Enviado",
  "components.trash": "Papelera",
  "components.all": "Todos",
  "components.do": "Do",
  "components.title": "Título",
  "components.projectName": "Nombre del proyecto",
  "components.companyName": "Nombre de la compañía",
  "components.openAlert": "Alerta abierta",
  "components.slideInAlertDialog": "Dialogo de diapositivas en dialogo",
  "components.openFullScreenDialog":
    "Abrir cuadros de diálogo de pantalla completa",
  "components.basicChip": "Chip básico",
  "components.clickableChip": "Chip Clickable",
  "components.left": "Izquierda",
  "components.right": "Derecha",
  "components.expansionPanel1": "Panel de expansión 1",
  "components.expansionPanel2": "Panel de expansión 2",
  "components.generalSetting": "Configuración general",
  "components.advancedSettings": "Configuración avanzada",
  "components.occupation": "Ocupación",
  "components.phoneNo": "Teléfono No",
  "components.address": "Dirección",
  "components.city": "Ciudad",
  "components.state": "Estado",
  "components.zipCode": "código postal",
  "components.social Connection": "conexión social",
  "widgets.buyMore": "Comprar más",
  "widgets.trafficChannel": "Canal de tráfico",
  "widgets.stockExchange": "Bolsa de valores",
  "widgets.tweets": "Tweets",
  "widgets.ourLocations": "Nuestras ubicaciones",
  "widgets.sales": "Ventas",
  "widgets.to": "Para",
  "widgets.shipTo": "Enviar a",
  "widgets.unitPrice": "Precio unitario",
  "widgets.total": "Total",
  "widgets.note": "Nota",
  "widgets.chipWithAvatar": "Chip con Avatar",
  "widgets.chipWithTextAvatar": "Chip con texto Avatar",
  "widgets.chipWithIconAvatar": "Chip con el icono Avatar",
  "widgets.customClickableChip": "Chip Clickable personalizado",
  "widgets.outlineChip": "Chip de esquema",
  "widgets.disableChip": "Desactivar Chip",
  "widgets.alertDialog": "Diálogo de alerta",
  "widgets.animatedSlideDialogs": "Diálogos de diapositiva animados",
  "widgets.fullScreenDialogs": "Cuadros de diálogo de pantalla completa",
  "widgets.formDialogs": "Diálogos de formulario",
  "widgets.simpleDialogs": "Cuadros de diálogo simples",
  "widgets.responsiveFullScreen": "Pantalla completa receptiva",
  "widgets.primary": "Director de escuela",
  "widgets.social": "social",
  "widgets.user": "Usuario",
  "widgets.admin": "Administrador",
  "widgets.permanentdrawer": "Cajón permanente",
  "widgets.persistentdrawer": "Cajón persistente",
  "widgets.swiches": "Swiches",
  "widgets.horizontalLinearAlternativeLabel":
    "Etiqueta alternativa horizontal lineal",
  "widgets.horizontalNonLinearAlternativeLabel":
    "etiqueta alternativa no lineal horizontal",
  "widgets.notifications": "Notificaciones",
  "widgets.basicAlert": "Alerta básica",
  "widgets.successAlert": "Alerta de éxito",
  "widgets.warningAlert": "Alerta de advertencia",
  "widgets.reactAutoSuggest": "reaccionar automáticamente sugerir",
  "widgets.components": "Componentes",
  "widgets.inputAdornments": "Adorements de entrada",
  "widgets.multiSelectList": "Lista de selección múltiple",
  "widgets.contextualColoredTable": "Tabla de colores Contexuales",
  "widgets.updateYourEmailAddress":
    "Actualiza tu dirección de correo electrónico",
  "widgets.selectADefaultAddress": "Seleccione una dirección predeterminada",
  "widgets.activity": "Actividad",
  "widgets.basicCalendar": "Calendario básico",
  "widgets.culturesCalendar": "Calendario de Culturas",
  "widgets.dragAndDropCalendar": "arrastrar y soltar calendario",
  "widgets.quillEditor": "Pluma Editor",
  "widgets.reactDND": "Reaccionar DND",
  "widgets.dragula": "Dragula",
  "hint.searchMailList": "Buscar lista dpe correo",
  "widgets.AcceptorrRejectWithin": "Aceptar o rechazar dentro",
  "widgets.quoteOfTheDay": "Cita del día",
  "widgets.updated10Minago": "Actualizado hace 10 min",
  "widgets.personalSchedule": "Horario personal",
  "widgets.activeUsers": "Usuarios activos",
  "widgets.totalRequest": "Solicitud total",
  "widgets.new": "Nuevo",
  "widgets.ShareWithFriends": "¡Compartir con amigos!",
  "widgets.helpToShareText":
    "¡Ayúdenos a difundir el mundo compartiendo nuestro sitio web con sus amigos y seguidores en las redes sociales!",
  "widgets.thisWeek": "Esta semana",
  "widgets.howWouldYouRateUs": "¿Cómo nos calificarías?",
  "widgets.booking": "Reserva",
  "widgets.confirmed": "Confirmado",
  "widgets.monthly": "mensual",
  "widgets.weekly": "semanal",
  "widgets.target": "Objetivo",
  "widgets.totalActiveUsers": "Total de usuarios activos",
  "sidebar.user": "Usuario",
  "sidebar.miscellaneous": "Diverso",
  "sidebar.promo": "Promoción",
  "themeOptions.themeColor": "Color del tema",
  "module.inbox": "Bandeja de entrada",
  "module.drafts": "Borradores",
  "module.sent": "Enviado",
  "module.trash": "Papelera",
  "module.spam": "Correo no deseado",
  "module.frontend": "Interfaz",
  "module.backend": "Backend",
  "module.api": "Api",
  "module.issue": "Problema",
  "components.emailPrefrences": "Preferencias de correo electrónico",
  "components.myProfile": "Mi perfil",
  "sidebar.gettingStarted": "Empezando",
  "widgets.deadline": "Fecha tope",
  "widgets.team": "Equipo",
  "widgets.projectManagement": "Gestión de proyectos",
  "widgets.latestPost": "Última publicación",
  "widgets.projectTaskManagement": "Project Task Management",
  "widgets.selectProject": "Seleccionar proyecto",
  "widgets.activityBoard": "Tablero de actividad",
  "widgets.checklist": "Checklist",
  "sidebar.shop": "tienda",
  "sidebar.cart": "Carro",
  "sidebar.checkout": "Revisa",
  "components.product": "Producto",
  "components.quantity": "Cantidad",
  "components.totalPrice": "Precio total",
  "components.removeProduct": "Eliminar producto",
  "components.mobileNumber": "Número de teléfono móvil",
  "components.address2Optional": "Dirección 2 (Opcional)",
  "components.country": "País",
  "components.zip": "Cremallera",
  "components.saveContinue": "Guardar Continuar",
  "components.placeOrder": "Realizar pedido",
  "components.payment": "Pago",
  "components.billingAddress": "Dirección de Envio",
  "components.ShippingAddressText":
    "La dirección de envío es la misma que la dirección de facturación.",
  "components.CartEmptyText": "¡Su cesta está vacía!",
  "components.NoItemFound": "No se encontró ningún artículo",
  "components.goToShop": "Ir a la tienda",
  "components.cardNumber": "Número de tarjeta",
  "components.expiryDate": "Fecha de caducidad",
  "components.cvv": "CVV",
  "components.nameOnCard": "Nombre en la tarjeta",
  "components.confirmPayment": "Confirmar pago",
  "sidebar.saas": "SAAS",
  "sidebar.level1": "Nivel 1",
  "sidebar.level2": "Nivel 2",
  "sidebar.boxed": "En caja",
  "sidebar.extensions": "Extensiones",
  "sidebar.imageCropper": "Imagen Cropper",
  "sidebar.videoPlayer": "Reproductor de video",
  "sidebar.dropzone": "Zona de descenso",
  "widgets.baseConfig": "Configuración de base",
  "widgets.customControlBar": "Barra de control personalizado",
  "widgets.withDownloadButton": "Con el botón Descargar",
  "widgets.httpLiveStreaming": "HTTP Live Streaming",
  "widgets.keyboardShortcuts": "Atajos de teclado",
  "button.cropImage": "Delimitar imagen",
  "widgets.preview": "Avance",
  "widgets.croppedImage": "Imagen recortada",
  "widgets.transactionList": "lista de transacciones",
  "widgets.transferReport": "informe de transferencia",
  "widgets.expenseCategory": "categoría de gastos",
  "widgets.upcomingEvents": "Próximos Eventos",
  "widgets.OngoingProjects": "Proyectos en marcha",
  "widgets.ProjectStatus": "estado del proyecto",
  "widgets.LiveChatSupport": "soporte de chat en vivo",
  "sidebar.projects": "proyectos",
  "sidebar.projectDetail": "detalle del proyecto",
  "sidebar.clients": "clientela",
  "sidebar.reports": "informes",
  "companytabs.user.button.adduser": "AÑADIR USUARIO",
  "companytabs.user.button.addReport": "AÑADIR REPORTE",
  "companytabs.user.button.addGLAccount": "AÑADIR G/L Account",
  "companytabs.user.button.addCategory": "NUEVA CATEGORÍA",
  "userscompany.createuserform.active": "Activo",
  "userscompany.createuserform.firstname.required": "Nombre(Required)",
  "userscompany.createuserform.lastname.required": "Apellido(Required)",
  "userscompany.createuserform.displayname.required":
    "Nombre para mostrar(Required)",
  "userscompany.createuserform.cellphone.required": "Celular(Required)",
  "userscompany.createuserform.email.required": "Correo electronico(Required)",
  "userscompany.createuserform.analyticsPersona.label": "App Analytics Persona",
  "userscompany.createuserform.role.required": "Rol(Required)",
  "userscompany.createuserform.analyticsPersona.required":
    "App Analytics Persona(Optional)",
  "userscompany.tableheader.analyticsPerson": "App Analytics Persona",
  "userscompany.tableheader.automaticSystemUser":
    "Usuario automática del sistema",
  "userscompany.tableheader.active": "Activo",
  "customers.title": "Lista de clientes",
  "customers.sidebar.title": "Clientes",
  "customers.customermodal.latepenaltyterms.label":
    "términos de penalización tardía",
  "customers.customermodal.contactname.label": "Nombre contacto",
  "customers.customermodal.notes.label": "Notas",
  "companytabs.customers.button.addcustomer": "AÑADIR CLIENTE",
  "salesordertemplate.title": "Plantilla de Orden de Venta",
  "salesordertemplate.add": "Añadir tarea",
  "adminsettings.secondarytitle": "Ajustes",
  "expenses.attention.export.message.one":
    "¿Está seguro de que desea exportar este gasto? Se marcara como exportado con la fecha de hoy para evitar futuras duplicaciones.",

  "expenses.attention.export.message":
    "¿Está seguro de que desea exportar estos gastos? Se marcarán como exportados con la fecha de hoy para evitar futuras duplicaciones.",

  "companymodal.custommodal.createcompany": "Crear compañia",
  "factorymodal.custommodal.editfactory": "Editar fabrica",
  "factorymodal.custommodal.createfactory": "Crear fabrica",
  "documents.button.create.new.folder": "Nueva Carpeta",
  "documents.button.create.new.file": "Nuevo Archivo",
  "companytabs.documents.salesorders.title":
    "SO Archivos & Titular de carpetas",
  "companytabs.documents.purchasesorders.title":
    "PO Archivos & Titular de carpetas",
  "companytabs.documents.shipments.title":
    "Shipment File & Folder Place holders",
  "documents.createnewfolder.save": "GUARDAR",
  "documents.createnewfolder.cancel": "CANCELAR",
  "salesordersmanager.validation.salesorder.amount":
    "El campo cantidad en SO es requerido",
  "salesordersmanager.validation.salesorder.customer":
    "El campo cliente en SO es requerido",
  "salesordersmanager.validation.salesorder.finaldestination":
    "El campo destino final en SO es requerido",
  "salesordersmanager.validation.salesorder.loadingport":
    "El campo puerto de carga en SO es requerido",
  "salesordersmanager.validation.salesorder.person":
    "El campo vendedor en SO es requerido",
  "salesordersmanager.validation.salesorder.potentiallatefee":
    "El campo posible cargo por atraso en SO es requerido",
  "salesordersmanager.validation.salesorder.shippingterms":
    "El campo condiciones de envio en SO es requerido",
  "salesordersmanager.validation.salesorder.unloadingport":
    "El campo puerto de descarga cantidad en SO es requerido",
  "salesordersmanager.validation.salesorder.number":
    "El campo numero es SO es requerido",
  "salesordersmanager.validation.salesorder.numberexists":
    "El numero de SO ya existe",
  "salesordersmanager.validation.purchaseorder.number":
    "El campo numero es requerido en PO",
  "salesordersmanager.validation.purchaseorder.numberexists":
    "El numer de PO ya existe",
  "salesordersmanager.validation.purchaseorder.duplicate":
    "PO Number duplicado",
  "salesordersmanager.validation.purchaseorder.amount":
    "El campo cantidad es requerido en PO",
  "salesordersmanager.validation.purchaseorder.customer":
    "El campo cliente es requerido en PO",
  "salesordersmanager.validation.purchaseorder.factory":
    "El campo fabrica es requerido en PO",
  "salesordersmanager.validation.purchaseorder.finaldestination":
    "El campo destino final es requerido en PO",
  "salesordersmanager.validation.purchaseorder.loadingport":
    "El campo puerto de carga es requerido en PO",
  "salesordersmanager.validation.purchaseorder.potentiallatefee":
    "El campo posible cargo por retraso es requerido en PO",
  "salesordersmanager.validation.purchaseorder.shippingterms":
    "El campo condiciones de envio es requerido en PO",
  "salesordersmanager.validation.purchaseorder.unloadingport":
    "El campo puerto de descarga es requerido en PO",
  "salesOrderGroup.taskcount": "Cantidad de Tareas",
  "errormessage.companynameissue": "El nombre de la compañia ya existe",
  "salesordersmanager.successfulupdated": "Actualizado satisfactoriamente",
  "salesordersmanager.successfulDeleted": "Eliminado satisfactoriamente",
  "salesordersmanager.successfulcreated": "Creado satisfactoriamente",
  "errormessage.companyexists": "El nombre de la compañia ya existe",
  "errormessage.nocompanydomain":
    "El dominio de la empresa no puede estar vacio",
  "errormessage.companydomaintoolong": "El dominio es muy largo",
  "errormessage.invaliddomain": "El dominio es invalido",
  "admincontent.successfullcompanynameupdated":
    "Los cambios de la compañia fueron cambiadas exitosamente",
  "admincontent.companynamenotsaved":
    "Los cambios de la compañia no han sido guardada",
  "documenttypes.create.custommodal": "Crear tipo de documento",
  "documenttypes.create.formtextfield": "Tipo de documento",
  "components.customers.customermodal.header.updatecustomer":
    "Actualizar cliente",
  "components.customers.customermodal.header.createcustomer":
    "Crear un cliente",
  "components.customers.customermodal.customsnackbar.alreadyexists":
    "Ya existe un cliente con el mismo nombre en la compañia",
  "components.adminsettings.adminsidebar.adminsettingsitem.logout": "Salir",
  "components.adminsettings.adminsidebar.adminsettingsitem.settings":
    "Configuraciones",
  "components.companytabs.permissionGroups.title": "Grupo de permisos",
  "components.companytabs.permissionGroups.custombutton.addGroup":
    "Nuevo Grupo",
  "components.companytabs.factories.button.addfactory": "Agregar fabrica",
  "components.companytabs.factorymodal.customsnackbar.message":
    "El nombre de la fábrica ya esta en uso en la compañía",
  "components.companytabs.factorymodal.customsnackbar.message.toomuchcharacters":
    "El nombre de la fábrica es muy largo, 55 caracteres como máximo",
  "components.companytabs.factorymodal.customsnackbar.message.invalidcharacters":
    "Solo (- , .) son caracteres especiales permitidos",
  "components.companytabs.factorymodal.namelabel.terms":
    "Terminos de penalización tardía",
  "components.companytabs.factorymodal.namelabel.contact": "Contacto",
  "components.companytabs.factorymodal.namelabel.notes": "Notas",
  "components.confirmationdialog.withconfirmation.custombutton.continue":
    "Continuar",
  "components.documents.draganddropuploadfile.modalpermission.selectdocumenttypeandviewpermissions":
    "Permisos de acceso",
  "components.documents.draganddropuploadfile.p.draganddrop":
    "Arrastra y Suelta Archivos",
  "components.documents.menuoptions.uploadfile": "Subir Archivo",
  "components.documents.menuoptions.newfolder": "Nueva Carpeta",
  "components.documents.menuoptions.newfolderTemplate": "Nuevo Sub-folder",
  "components.documents.menuoptions.multiselect": "Multi-select",
  "components.documents.menuoptions.cancelmultiselect": "Cancel Multi-select",
  "components.documents.menuoptions.uploadnewversion": "Cargar Versión Nueva",
  "components.documents.menuoptions.preview": "Vista previa",
  "components.documents.menuoptions.folderPreview":
    "Vistas previas de imágenes",
  "components.documents.menuoptions.copylink": "Copiar Enlace",
  "components.documents.menuoptions.rename": "Renombrar",
  "components.documents.menuoptions.permissions": "Permisos",
  "components.documents.menuoptions.download": "Descagar",
  "components.documents.menuoptions.moveToTopLevel": "Mover al nivel mas alto",
  "components.documents.menupermission.onlyonefilecanbeuploadedatatime":
    "Solo se pude subir un archivo a la vez",
  "components.documents.modalpermission.documenttype": "Grupos de Acceso",
  "components.documents.modalpermission.selectone": "Elegir Una",
  "components.documents.modalpermission.viewpermission":
    "Usuarios selecionados",
  "components.documents.modalpermission.cancel": "CANCELAR",
  "components.documents.modalpermission.done": "HECHO",
  "components.documenttemplate.managmentdocuments.order": "Pedido",
  "components.documenttemplate.managmentdocuments.documenttype":
    "Tipo de Documento",
  "components.errorcomponents.notobserving.youarenotobservinganycompany":
    "No se está observando ninguna compañía",
  "components.factories.categorysection.addtask": "Agregar tarea",
  "components.factories.categorysection.ordinal": "Orden",
  "components.factories.taskdescription": "Descripción de la tarea",
  "components.factories.categorysection.dependencytype": "Tipo de dependencia",
  "components.factories.categorysection.predecessor": "Predecesor",
  "components.factories.categorysection.duration": "Duración",
  "components.factories.categorysection.offset": "Compensación",
  "components.factories.categorysection.person": "Persona",
  "components.factories.categorysection.notificationEarly":
    "Notificacion completetado temprano",
  "components.factories.categorysection.notificationLate":
    "Notificacion completado tarde",
  "components.factories.categorysection.isShipment": "Cargamento",
  "components.factories.categorysection.action": "Acción",
  "components.factories.factorytable.miniprojecttemplate":
    "Plantilla de mini proyecto",
  "components.factories.factorytemplatemodal.clonetemplate": "clonar plantilla",
  "components.factories.salesordercontainer.salesordertasks":
    "Tarea de Pedido de Ventas",
  "components.factories.salesordercontainer.proposal": "Propuesta",
  "components.factories.salesordercontainer.preproduction": "Pre - Producción",
  "components.factories.salesordercontainer.production": "Producción",
  "components.factories.salesordercontainer.bookingtransit":
    "Reservas y Tránsito",
  "components.factories.salesordercontainer.paymentbilling":
    "Pago y Facturación",
  "components.purchaseorderdashboard.salesorder": "Pedidos de Venta",
  "components.purchaseorderdashboard.quote": "Cotizacion",
  "components.purchaseorderdashboard.purchaseorder": "Pedidos de Compra",
  "components.purchaseorderdashboard.purchasequote": "Cotizacion de Compra",
  "components.purchaseorderdashboard.shipment": "Nota de envio",
  "components.purchaseorderdashboard.notessection.noteiseditablefor1minuteaftersubmitting.":
    "Atención! La nota se puede cambiar hasta 8 segundos despues de ser enviada.",
  "components.purchaseorderdashboard.notessection.send": "Enviar",
  "components.purchaseorderdashboard.projecttasktabs.all": "TODOS",
  "components.purchaseorderdashboard.projecttasktabs.salesorder":
    "PEDIDOS DE VENTA",
  "components.purchaseorderdashboard.projecttasktabs.purchaseorder":
    "PEDIDO DE COMPRA",
  "components.purchaseorderdashboard.projectnotes.newsalesordernote":
    "Nueva Nota de Pedido de Venta",
  "components.purchaseorderdashboard.projectnotes.newpurchaseordernote":
    "Nueva Nota de Pedido de Compra",
  "components.purchaseorderdashboards.task.notassigned": "Sin Asignar",
  "components.purchaseorderlist.purchaseorderlistheader.sodeliverydate":
    "SO Fecha de Entrega",
  "components.purchaseorderlist.purchaseorderlistheader.soshipdate":
    "SO Fecha de Envío",
  "components.purchaseorderlist.purchaseorderlistheader.poshipdate":
    "PO Fecha de Envío",
  "components.purchaseorderlist.purchaseorderlistheader.customer": "Cliente",
  "components.purchaseorderlist.purchaseorderlistheader.podate": "PO Fecha",
  "components.purchaseorderlist.purchaseorderlistheader.required": "Requerido",
  "components.purchaseorderlist.purchaseorderlistheader.revised": "Revisado",
  "components.purchaseorderlist.purchaseorderlistheader.factory": "Fábrica",
  "components.purchaseorderlist.purchaseorderlistheader.povalue": "PO Valor",
  "components.purchaseorderlist.purchaseorderlistheader.deposit": "Depósito",
  "components.purchaseorderlist.purchaseorderlistheader.balance": "Balance",
  "components.purchaseorderlist.purchaseorderlistheader.potenciallatepenalty":
    "Posible Penalización Tardía",
  "components.purchaseorderlist.purchaseorderlistheader.shippingterms":
    "Términos de Entrega",
  "components.purchaseorderlist.purchaseorderlistheader.loadingport":
    "Puerto de Carga",
  "components.purchaseorderlist.purchaseorderlistheader.unloadingport":
    "Puerto de Descarga",
  "components.purchaseorderlist.purchaseorderlistheader.finaldestination":
    "Destino Final",
  "components.companytabs.factorymodal.customsnackbar.message.isblank":
    "El nombre no puede estar en blanco",
  "components-salesOrders-salesOrderTemplate-DiscardChanges":
    "Descartar Cambios",
  "components-salesOrders-salesOrderTemplate-SavenewVersionofTemplate":
    "Guardar Nueva Version de la Plantilla",
  "companyusers.validate.error.displayname":
    "Espacios en blanco o caracteres especiales no son validos",
  "companyusers.validate.error.nonvalidphone":
    "El numero de telefono es invalido",
  "components.companytabs.factorymodal.customsnackbar.message.tooLong":
    "El nombre es muy largo",
  "salesorderlist.title": "Orden de venta",
  "tags.title": "Etiquetas",
  "shipments.title": "Cargamentos",
  "PurchaseOrderInfo-Attention-Modal-Title": "Atención",
  "PurchaseOrderInfo-Attention-Modal-Description":
    "El número de PO ya existe, porfavor elija otro",
  "purchaseOrderInfo.Attention.Modal.Description.existsPONumber":
    "That PO number already exists, please pick another number",
  "purchaseOrderInfo.Attention.Modal.Description.emptyPONumber":
    "That PO number can not be empty",
  "salesOrderInfo.Attention.Modal.Description.existsSONumber":
    "That SO number already exists, please pick another number",
  "salesOrderInfo.Attention.Modal.Description.emptySONumber":
    "That SO number can not be empty",
  "salesordersmanager.successfulreassigned": "Reasignado Satisfactoriamente!",
  "salesordersmanager.successfuldeletedPO": "Eliminado Satisfactoriamente",
  "logistics.title": "Logistica",
  "blockedCompany.title": "Esta empresa fue inhabilitada",
  "adminsettings.sidebar.title": "Compañias",
  "adminsettings.sidebar.button": "Añadir nueva",
  "adminsettings.title": "Opciones administrativas",
  "notifications.filters.allNotifications": "Todos",
  "notifications.filters.directMessage": "DMs",
  "notifications.filters.adHocTask": "AD-HOC TASK",
  "notifications.filters.completeEarly": "COMP. TEMPRANO",
  "notifications.filters.completeLate": "COMP. TARDE",
  "general.filters.export.csv": "Exportar a CSV",
  "factories.screen.po.template": "Mini Project Template",
  "factories.title": "Vendors",
  Vendors: "Clientes",
  Tags: "Etiquetas",
  Roles: "Roles",
  "Sales Order": "Sales Order",
  "general.basicinfo": "Informacion General",
  "factory.name": "Nombre del Cliente",
  "factory.number": "Numero del Cliente",
  "factory.mainContact": "Contacto principal",
  "factory.terms": "Terminos (en dias)",
  "factory.notes": "Notas",
  "factory.email": "Correo electornico",
  "factory.inactive": "Desactivado",
  "factory.categories": "Categories",
  "customer.name": "Customer Name",
  "customer.number": "Customer Number",
  "customer.inactive": "Desactivado",
  "customer.email": "Customer Email",
  "customer.mainContact": "Main Contact",
  "customer.Terms": "Terminos (en dias)",
  "customer.cellPhone": "Phone",
  "customer.jobTitle": "Job Title",
  "customer.notes": "Notes",
  "customer.categories": "Categorias",
  "order.dashboard.adhoc.task.sales.order": "ORDER DE VENTA",
  "order.dashboard.adhoc.task.purchase.order": "ORDEN DE COMPRA",
  "order.dashboard.adhoc.task.shipment": "CARGAMENTO",
  "orderDashboard.salesOrder.info.date": "Fecha del documento:",
  "orderDashboard.salesOrder.info.create": "Fecha de Creacion:",
  "orderDashboard.salesOrder.info.cbm": "Total CBM:",
  "orderDashboard.salesOrder.info.totalPOs": "Total POs:",
  "orderDashboard.salesOrder.info.totalPQs": "Total PQs:",
  "orderDashboard.salesOrder.info.totalPOsAndPQs": "Total POs and PQs:",
  "orderDashboard.salesOrder.info.invoice": "Factura de venta:",
  "orderDashboard.salesOrder.info.amount": "Subtotal:",
  "orderDashboard.salesOrder.info.totalshipments": "Total Cargamentos:",
  "orderDashboard.emptyField": "",
  "orderDashboard.salesOrder.info.shipmentWindow": "Vista de Cargamento:",
  "orderDashboard.salesOrder.info.deliveryWindow": "Vista de Entrega:",
  "orderDashboard.salesOrder.info.customerPO": "Cliente PO:",
  "orderDashboard.salesOrder.info.deposit": "Deposito:",
  "orderDashboard.salesOrder.info.salesRepresentative": "Responsable:",
  "orderDashboard.salesOrder.info.referenceNumber": "Numero de referencia:",
  "orderDashboard.salesOrder.info.shipDate": "Fecha de envio:",
  "orderDashboard.salesOrder.info.uniqueItems": "Unicos Items:",
  "orderDashboard.salesOrder.info.deliveryDate": "Entrega requerida:",
  "orderDashboard.salesOrder.info.potentialLateFee":
    "Posible cargo por retraso:",
  "orderDashboard.salesOrder.info.balance": "Balance:",
  "orderDashboard.salesOrder.info.weight": "Peso Total bruto:",
  "orderDashboard.purchaseOrder.info.date": "Fecha del Documento:",
  "orderDashboard.purchaseOrder.info.create": "Fecha de Creación",
  "orderDashboard.purchaseOrder.info.orderReadyDate":
    "Fecha de preparación del pedido:",
  "orderDashboard.purchaseOrder.info.cbm": "Total CBM:",
  "orderDashboard.purchaseOrder.info.amount": "Total:",
  "orderDashboard.purchaseOrder.info.subTotal": "Subtotal:",
  "orderDashboard.purchaseOrder.info.shipmentWindow": "Vista de Cargamento:",

  "orderDashboard.purchaseOrder.info.invoice": "Factura de la fabrica:",
  "orderDashboard.purchaseOrder.info.discountPercentage":
    "Porcentaje de descuento:",
  "orderDashboard.purchaseOrder.info.discount": "Descuento:",
  "orderDashboard.purchaseOrder.info.shipDate": "Fecha de envio:",
  "orderDashboard.purchaseOrder.info.weight": "Peso Total bruto:",
  "orderDashboard.purchaseOrder.info.deposit": "Deposito:",
  "orderDashboard.purchaseOrder.info.totalShipments": "Total Cargamentos:",
  "orderDashboard.purchaseOrder.info.potentialLateFee":
    "Posible cargo por retraso:",
  "orderDashboard.purchaseOrder.info.uniqueItems": "Unicos Items:",
  "orderDashboard.purchaseOrder.info.balance": "Balance:",
  "orderDashboard.purchaseOrder.info.shippingTerms": "Terminos de envio:",
  "orderDashboard.shipment.info.date": "Creatión:",
  "orderDashboard.shipment.info.cbm": "Total CBM:",
  "orderDashboard.shipment.info.booking": "Reserva:",
  "orderDashboard.shipment.info.loadingPort": "Puerto de carga:",
  "orderDashboard.shipment.info.amount": "Precio:",
  "orderDashboard.shipment.info.invoice": "Factura de venta:",
  "orderDashboard.shipment.info.shipDate": "Fecha de envio:",
  "orderDashboard.shipment.info.weight": "Peso bruto:",
  "orderDashboard.shipment.info.billOfLading": "Conocimiento de carga:",
  "orderDashboard.shipment.info.unloadingPort": "Puerto de descarga:",
  "orderDashboard.shipment.info.potentialLateFee": "Posible cargo por retraso:",
  "orderDashboard.shipment.info.customerPO": "Cliente PO:",
  "orderDashboard.shipment.info.deliveryDate": "Fecha de entrega:",
  "orderDashboard.shipment.info.uniqueItems": "Unicos Items:",
  "orderDashboard.shipment.info.container": "Container:",
  "orderDashboard.shipment.info.shippingTerms": "Terminos de embarque:",
  "orderDashboard.shipment.info.finalDestination": "Destino Final:",
  "orderDashboard.shipment.info.freightForwarder": "Transitario:",
  "order.type.sales.order": "Orden de venta",
  "order.type.purchase.order": "Orden de compra",
  "order.type.purchase.po": "PO",
  "order.type.quote": "Cotizacion",
  "order.type.purchase.quote": "Cotizacion de compra",
  "user.profile.save": "Cargar diferente imagen",
  "update.column.headers.for.list.views":
    "Actualizar las cabezeras de las vistas",
  "column.settings.reportEmbed": "Report Embed",
  "modal.settings.updateReport": "Actualizar Reporte",
  "modal.settings.createReport": "Crear Reporte",
};
