import { getRandomId } from "../../helpers/helpers";

export default class SalesOrderItem {
  constructor({
    id = getRandomId(),
    number = 0,
    customerId = "",
    allocation = 0,
    shipments = [],
    status = ''
  }) {
    this.id = id;
    this.number = number;
    this.customerId = customerId;
    this.allocation = parseInt(allocation);
    this.shipments = shipments;
    this.status = status;

  }
}
