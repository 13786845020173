import React, { useEffect, useRef, useState } from "react";
import folder from "../../assets/flag-icons/folder-placeholder.svg";
import file from "../../assets/flag-icons/file-placeholder.svg";
import {
  CancelCreationIcon,
  DoneCreationIcon,
} from "../../helpers/iconSvgPath";
import ClearIcon from "@mui/icons-material/Cancel";
import { CreateNewFolderStyled } from "./styles";
import { InputBase, styled } from "@mui/material";
import moment from "moment";
import { colors } from "../../assets/jss/variables";
import { TYPE_OF_FILE } from "../../helpers/constants";

const InputField = styled(InputBase)(({ theme }) => ({
  "&.rootInput": {
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #D2DFEC",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "16px",
    padding: "8px 10px",
    borderRadius: 4,
    color: colors.primaryDark,
    height: 32,
    "& input": {
      padding: "4px 0 4px",
    },
  },
}));

function CreateNewFolder({
  folderName = "",
  handleSaveName = () => {},
  handleClose = () => {},
  type = TYPE_OF_FILE.FOLDER,
}) {
  const [name, setName] = useState(folderName || "New Folder");
  const [idKey] = useState(moment().valueOf());
  const inputRef = useRef(null);

  const onFocus = () => {
    inputRef.current?.focus();
    inputRef.current?.select();
  };

  useEffect(() => {
    setTimeout(() => {
      onFocus();
    }, 150);
  }, []);

  return (
    <CreateNewFolderStyled className="listem-item-document">
      <div className="listItemIcon">
        <img
          src={type === TYPE_OF_FILE.FOLDER ? folder : file}
          className="imgListItemIcon"
          alt="folder"
          height={30}
          width={30}
        />
      </div>

      <div className="itemDataContainer">
        <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
          <InputField
            autoFocus
            className="rootInput"
            id={`item_${idKey}`}
            key={`input_${idKey}`}
            style={{ width: "100%" }}
            value={name}
            inputRef={inputRef}
            onChange={(e) => {
              const { value } = e.target;
              const cleanedValue = value.replace(/\s+/g, " ");
              const validCharacters = /^[^\t\\/\\]+$/;
              if (cleanedValue === "" || validCharacters.test(cleanedValue)) {
                setName(value);
              }
            }}
            onKeyDown={(ev) => {
              if (ev.key === "Enter") {
                handleSaveName(name);
              }
              if (ev.key === "Escape") {
                handleClose();
                setName(folderName);
              }
            }}
          />
          <ClearIcon
            fontSize="small"
            style={{
              position: "relative",
              right: 25,
              width: 15,
              height: 15,
              color: "#CBCBCB",
            }}
            onClick={() => {
              setName("");
              onFocus();
            }}
          />
        </div>
        <div
          style={{
            flex: "0 0 auto",
            display: "flex",
            alignItems: "center",
            gap: 8,
          }}
        >
          <CancelCreationIcon
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              handleClose();
              setName(folderName);
            }}
          />
          <DoneCreationIcon
            style={{
              cursor: !name ? "" : "pointer",
              opacity: !name ? 0.6 : 1,
            }}
            onClick={(e) => {
              if (name) {
                handleSaveName(name);
              }
              e.stopPropagation();
            }}
          />
        </div>
      </div>
    </CreateNewFolderStyled>
  );
}

export default CreateNewFolder;
