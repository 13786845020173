import { useSelector } from "react-redux";
import { useCompanyId } from ".";
import Tag from "../api/model/tag";
import { dbTables } from "../api/types/dbTables";
import { sortObjectsBy } from "../helpers/helpers";
import { AUTOMATIC_SYSTEM_LABEL, TYPE_OF_TAGS } from "../helpers/constants";
import { firestore } from "../firebase";
import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import { groupBy } from "lodash";

export function useTags(showAll = false) {
  const companyId = useCompanyId() || "";
  let tags = useSelector((state) => state.data[dbTables.TAGS][companyId]);
  if (!companyId) return [];
  if (!tags) return [];
  if (!showAll) {
    tags = tags.filter((tag) => !tag.inactive);
  }
  const parseTags = tags.map((tag) => {
    const parseTag = { ...new Tag({ ...tag }), ref: tag.ref };
    return parseTag;
  });
  return parseTags;
}

export function tagsByType(tags, type) {
  const tagsByGroup = groupBy(tags, "type");
  const filterTags = tagsByGroup[type] || [];
  return filterTags.sort(sortObjectsBy("name", false));
}

export function tagsExcludes(tags, excludeTags = []) {
  const tagsByGroup = groupBy(tags, "type");
  let allTags = [];
  if (!excludeTags.includes(TYPE_OF_TAGS.REGULAR_TAG)) {
    const regularTag = tagsByGroup[TYPE_OF_TAGS.REGULAR_TAG] || [];
    allTags = [...allTags, ...regularTag.sort(sortObjectsBy("name", false))];
  }
  if (!excludeTags.includes(TYPE_OF_TAGS.MISMATCH_TAG)) {
    const mismatchTag = tagsByGroup[TYPE_OF_TAGS.MISMATCH_TAG] || [];
    allTags = [...allTags, ...mismatchTag.sort(sortObjectsBy("name", false))];
  }
  if (!excludeTags.includes(TYPE_OF_TAGS.FILE_TAG)) {
    const fileTag = tagsByGroup[TYPE_OF_TAGS.FILE_TAG] || [];
    allTags = [...allTags, ...fileTag.sort(sortObjectsBy("name", false))];
  }
  if (!excludeTags.includes(TYPE_OF_TAGS.DEADLINE_TAG)) {
    const deadlineTag = tagsByGroup[TYPE_OF_TAGS.DEADLINE_TAG] || [];
    allTags = [...allTags, ...deadlineTag.sort(sortObjectsBy("name", false))];
  }
  if (!excludeTags.includes(TYPE_OF_TAGS.EXPENSES_TAG)) {
    const deadlineTag = tagsByGroup[TYPE_OF_TAGS.EXPENSES_TAG] || [];
    allTags = [...allTags, ...deadlineTag.sort(sortObjectsBy("name", false))];
  }
  return allTags;
}

export function getPositionByType(tags, inputFilter = "") {
  const tagsFilter = tags.filter((tag) => {
    const name = tag.name || "";
    const parseInput = inputFilter.toString();
    return name.toUpperCase().includes(parseInput.toUpperCase());
  });
  const tagsByGroup = groupBy(tagsFilter, "type");
  const regularTags = tagsByGroup[TYPE_OF_TAGS.REGULAR_TAG] || [];
  const mismatchTags = tagsByGroup[TYPE_OF_TAGS.MISMATCH_TAG] || [];
  const fileTags = tagsByGroup[TYPE_OF_TAGS.FILE_TAG] || [];
  const deadlineTags = tagsByGroup[TYPE_OF_TAGS.DEADLINE_TAG] || [];
  const positionBySize = {
    [TYPE_OF_TAGS.MISMATCH_TAG]:
      regularTags.length === 0 || mismatchTags.length === 0
        ? -1
        : regularTags.length,
    [TYPE_OF_TAGS.FILE_TAG]:
      (regularTags.length === 0 && mismatchTags.length === 0) ||
      fileTags.length === 0
        ? -1
        : regularTags.length + mismatchTags.length,
    [TYPE_OF_TAGS.DEADLINE_TAG]:
      (regularTags.length === 0 &&
        mismatchTags.length === 0 &&
        fileTags.length === 0) ||
      deadlineTags.length === 0
        ? -1
        : regularTags.length + mismatchTags.length + fileTags.length,
  };

  return positionBySize || {};
}

export async function createMismatchTags(tags, companyId) {
  if (!tags || !companyId) {
    return;
  }

  const AUTOMATIC_SYSTEM_LABELS = [
    {
      name: AUTOMATIC_SYSTEM_LABEL.SALES_ORDER_MISMATCH,
      type: TYPE_OF_TAGS.MISMATCH_TAG,
    },
    {
      name: AUTOMATIC_SYSTEM_LABEL.SHIPMENT_MISMATCH,
      type: TYPE_OF_TAGS.MISMATCH_TAG,
    },
    {
      name: AUTOMATIC_SYSTEM_LABEL.SALES_ORDER_FILE,
      type: TYPE_OF_TAGS.FILE_TAG,
    },
    {
      name: AUTOMATIC_SYSTEM_LABEL.PURCHASE_ORDER_FILE,
      type: TYPE_OF_TAGS.FILE_TAG,
    },
    { name: AUTOMATIC_SYSTEM_LABEL.SHIPMENT_FILE, type: TYPE_OF_TAGS.FILE_TAG },
    {
      name: AUTOMATIC_SYSTEM_LABEL.TASK_DEADLINE,
      type: TYPE_OF_TAGS.DEADLINE_TAG,
    },
    {
      name: AUTOMATIC_SYSTEM_LABEL.NO_FORECASTED_EXPENSES_ASSIGNED,
      type: TYPE_OF_TAGS.EXPENSES_TAG,
    },
    {
      name: AUTOMATIC_SYSTEM_LABEL.NO_ACTUAL_EXPENSES_ASSIGNED,
      type: TYPE_OF_TAGS.EXPENSES_TAG,
    },
    {
      name: AUTOMATIC_SYSTEM_LABEL.FORECASTED_EXPENSES_ASIGNED,
      type: TYPE_OF_TAGS.EXPENSES_TAG,
    },
    {
      name: AUTOMATIC_SYSTEM_LABEL.ACTUAL_EXPENSES_ASIGNED,
      type: TYPE_OF_TAGS.EXPENSES_TAG,
    },
  ];

  const companyRef = `${dbTables.COMPANIES}/${companyId}`;
  let tagsToProcess = [];
  if (tags.length === 0) {
    const tagsSnapDB = await getDocs(
      collection(firestore, `${companyRef}/${dbTables.TAGS}`)
    );
    tagsToProcess = tagsSnapDB.docs.map((tag) => {
      return { ...tag.data(), id: tag.id };
    });
  } else {
    tagsToProcess = tags;
  }
  AUTOMATIC_SYSTEM_LABELS.forEach((systemLabel) => {
    const mismatchTag = tagsToProcess.find(
      (tag) => tag.name === systemLabel.name
    );
    if (!mismatchTag) {
      const tag = {
        ...new Tag({
          name: systemLabel.name,
          type: systemLabel.type,
        }),
      };
      setDoc(doc(firestore, `${companyRef}/${dbTables.TAGS}/${tag.id}`), tag);
    }
  });
}
