import React from "react";
import { BoldIcon, ItalicIcon, UnderlineIcon } from "../ToolbarButtons";
import { StyleButton } from "../ComponentHelper";
import { RICH_STYLE } from "../../../helpers/constants";
import { Divider } from "@mui/material";

const INLINE_STYLES = [
  { label: "bold", icon: <BoldIcon />, style: RICH_STYLE.BOLD },
  { label: "italic", icon: <ItalicIcon />, style: RICH_STYLE.ITALIC },
  { label: "underline", icon: <UnderlineIcon />, style: RICH_STYLE.UNDERLINE },
];

export const InlineStyleControls = (props) => {
  const currentStyle = props.editorState.getCurrentInlineStyle();

  return (
    <div className={"inlineGroup"}>
      {INLINE_STYLES.map((type, i) => (
        <StyleButton
          key={i}
          active={currentStyle.has(type.style)}
          label={type.label}
          icon={type.icon}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
      <Divider
        orientation="vertical"
        style={{ color: "#E0E0E0", width: 1, height: 18 }}
      />
    </div>
  );
};
