// sidebar nav links
import React from "react";
import WeekIcon from "../../assets/flag-icons/sidebar-week.svg";
import SalesIcon from "../../assets/flag-icons/sidebar-sales.svg";
import PurchaseIcon from "../../assets/flag-icons/sidebar-purchase.svg";
import TasksIcon from "../../assets/flag-icons/sidebar-tasks.svg";
import PhaseIcon from "../../assets/flag-icons/sidebar-phases.svg";
import FactoriesIcon from "../../assets/flag-icons/sidebar-factories.svg";
import CustomersIcon from "../../assets/flag-icons/sidebar-customers.svg";
import Admin from "../../assets/flag-icons/sidebar-admin.svg";
import ShipmentsIcon from "../../assets/flag-icons/sidebar-shipments.svg";
import ReportsIcon from "../../assets/flag-icons/report_icon.svg";
import { ExpensesIcon } from "../../helpers/iconSvgPath";
import {
  featureFlagNames,
  GENERAL_PERMISSION_VALUE,
} from "../../helpers/constants";

const navLinks = {
  menu_items: [
    // {
    //   menu_title: "Notifications",
    //   menu_icon: NotificationsIcon,
    //   path: "#",
    //   new_item: false,
    //   child_routes: null,
    //   permission_name: " ",
    // },
    {
      menu_title: "Reports",
      menu_icon: ReportsIcon,
      path: "/app/report",
      new_item: false,
      child_routes: null,
      permission_name: GENERAL_PERMISSION_VALUE.REPORTS_VIEW,
    },
    {
      menu_title: "Sales Orders",
      menu_icon: SalesIcon,
      path: "/app/sales_orders",
      new_item: false,
      child_routes: null,
      permission_name: GENERAL_PERMISSION_VALUE.SALES_ORDERS_VIEW,
    },
    {
      menu_title: "Purchase Orders",
      menu_icon: PurchaseIcon,
      path: "/app/purchase_orders",
      new_item: false,
      child_routes: null,
      permission_name: GENERAL_PERMISSION_VALUE.PURCHASE_ORDERS_VIEW,
    },
    {
      menu_title: "Tasks",
      menu_icon: TasksIcon,
      path: "/app/tasks",
      new_item: false,
      child_routes: null,
      permission_name: GENERAL_PERMISSION_VALUE.TASKS_VIEW,
    },
    {
      menu_title: "Shipments",
      menu_icon: ShipmentsIcon,
      path: "/app/shipments",
      new_item: false,
      child_routes: null,
      permission_name: GENERAL_PERMISSION_VALUE.SHIPMENTS_VIEW,
    },
    {
      menu_title: "Orders by Phase",
      menu_icon: PhaseIcon,
      path: "/app/orders_by_phase",
      new_item: false,
      child_routes: null,
      permission_name: GENERAL_PERMISSION_VALUE.ORDERS_BY_PHASE_VIEW,
    },
    // {
    //   menu_title: "Logistics",
    //   menu_icon: Logistics,
    //   path: "/app/logistics",
    //   new_item: false,
    //   child_routes: null,
    //   permission_name: "logistics_view",
    // },
    {
      menu_title: "Week",
      menu_icon: WeekIcon,
      path: "/app/week",
      new_item: false,
      child_routes: null,
      permission_name: GENERAL_PERMISSION_VALUE.WEEK_VIEW,
    },
    {
      menu_title: "Vendors",
      menu_icon: FactoriesIcon,
      path: "/app/factories",
      new_item: false,
      child_routes: null,
      permission_name: GENERAL_PERMISSION_VALUE.FACTORIES_VIEW,
    },
    {
      menu_title: "Customers",
      menu_icon: CustomersIcon,
      path: "/app/customers",
      new_item: false,
      child_routes: null,
      permission_name: GENERAL_PERMISSION_VALUE.CUSTOMERS_VIEW,
    },
    {
      menu_title: "Expenses",
      menu_icon: <ExpensesIcon width={"100%"} height={28} />,
      svgIcon: true,
      path: "/app/expenses",
      new_item: false,
      child_routes: null,
      permission_name: GENERAL_PERMISSION_VALUE.EXPENSES_VIEW,
      permissionFlag: featureFlagNames.EXPENSES_V1,
    },
    {
      menu_title: "Settings",
      menu_icon: Admin,
      path: "/app/admin",
      new_item: false,
      child_routes: null,
      permission_name: GENERAL_PERMISSION_VALUE.SETTINGS,
    },
  ],
};

export default navLinks;
