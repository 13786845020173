import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";

// Injected message
const InjectMassage = (props) => (
  <FormattedMessage {...props} defaultMessage="" />
);

export default injectIntl(InjectMassage, {
  withRef: false,
});
