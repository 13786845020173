import React, { useState } from "react";

import { useJSONLocalStorage } from "../../hooks";

import TaskList from "./TaskList";
import TasksByProjectContainer from "./TaskByProject/TasksByProjectContainer";
import { TaskByProjectProvider } from "./TaskByProject/TaskByProjectContext";

function TaskListScreen() {
  const { get, set } = useJSONLocalStorage("taskListScreen");
  const dataLocalStorage = get() || {};
  const [filters, setFilters] = useState({
    groupBySalesOrder: dataLocalStorage.groupBySalesOrder || "",
  });

  return (
    <React.Fragment>
      {filters.groupBySalesOrder ? (
        <TaskByProjectProvider className="tasksProjectContainer">
          <TasksByProjectContainer
            onGroupBySalesOrder={(filters) => {
              setFilters({ ...filters });
              set({ ...filters });
            }}
          />
        </TaskByProjectProvider>
      ) : (
        <TaskList
          onGroupBySalesOrder={(filters) => {
            setFilters({ ...filters });
            set({ ...filters });
          }}
        />
      )}
    </React.Fragment>
  );
}

export default TaskListScreen;
