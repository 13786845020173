import React, { useState } from "react";
import CustomButton from "../Buttons/CustomButton";
import { customerVendorFields } from "../../helpers/constants";
import { CustomerVendorFormStyled } from "./styles";
import { Grid } from "@mui/material";

function CreateVendorCustomer({ onSubmit = () => {}, handleClose, scope }) {
  const [currentItem, setCurrentItem] = useState({});
  const handleChange = ({ field, value }) => {
    setCurrentItem({ ...currentItem, [field]: value });
  };
  return (
    <CustomerVendorFormStyled
      onSubmit={(ev) => {
        onSubmit({
          ev,
          item: currentItem,
        });
        ev.preventDefault();
      }}
      onKeyDown={(ev) => {
        if (ev.key === "Escape") {
          handleClose();
        }
      }}
    >
      <Grid
        container
        spacing={5}
        alignItems="center"
        justifyContent="center"
        className={"gridFrom"}
      >
        <div className={"formContainer"}>
          <label>{`${scope} number`}</label>
          <input
            autoFocus={true}
            className="inputForm"
            required={true}
            value={currentItem[customerVendorFields.NUMBER] || ""}
            onChange={(ev) => {
              const value = ev.target.value || "";
              handleChange({
                value: value.replace(/\s+/g, ""),
                field: customerVendorFields.NUMBER,
              });
            }}
          />
        </div>

        <div className={"formContainer"}>
          <label>{`${scope} name`}</label>
          <input
            className="inputForm"
            required={true}
            value={currentItem[customerVendorFields.NAME] || ""}
            onChange={(ev) => {
              const value = ev.target.value || "";
              handleChange({
                value: value.replace(/\s\s+/g, " "),
                field: customerVendorFields.NAME,
              });
            }}
          />
        </div>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        style={{ marginTop: 24 }}
      >
        <Grid item xs={10}>
          <CustomButton
            styleContainer={{
              marginLeft: "81%",
            }}
            type="success"
          >
            {"generic.text.done"}
          </CustomButton>
        </Grid>
        <Grid item xs={2}>
          <CustomButton type="cancel" onClick={handleClose} noSubmit>
            {"generic.text.cancel"}
          </CustomButton>
        </Grid>
      </Grid>
    </CustomerVendorFormStyled>
  );
}

export default CreateVendorCustomer;
