import React, { useState, useEffect } from "react";
import EditIcon from "../../assets/flag-icons/edit-icon.svg";
import { updateDoc } from "firebase/firestore";
import { Popover, TextareaAutosize } from "@mui/material";
import { ReportInfoIcon } from "../../helpers/iconSvgPath";
import { colors } from "../../assets/jss/variables";

function ReportPopover({ report }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [editing, setEditing] = useState(false);
  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "simple-popover" : undefined;
  const [reportDescription, setReportDescription] = useState("");

  useEffect(() => {
    setReportDescription(report.description);
  }, [report.id, report.description]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = () => {
    updateDoc(report.ref, {
      description: reportDescription,
    });
    setAnchorEl(null);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <ReportInfoIcon
        height={18}
        width={18}
        color={colors.primaryDark}
        onMouseEnter={handleClick}
      />
      {openPopover && (
        <Popover
          onClick={(ev) => ev.stopPropagation()}
          id={id}
          open={openPopover}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          disablePortal
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          style={{ padding: "24px 24px 12px 24px" }}
          classes={{ paper: "reportPopoverContainer" }}
        >
          {editing ? (
            <TextareaAutosize
              style={{
                width: 350,
                fontSize: 13,
                color: "#25486D",
                lineHeight: 1.5,
              }}
              placeholder="empty"
              value={reportDescription}
              onBlur={() => {
                setEditing(false);
              }}
              onChange={(ev) => setReportDescription(ev.target.value)}
              autoFocus={true}
            />
          ) : (
            <div className="report-description">{reportDescription}</div>
          )}
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <img
              src={EditIcon}
              alt="edit-icon"
              onClick={() => setEditing(true)}
            />
          </div>
        </Popover>
      )}
    </div>
  );
}

export default ReportPopover;
