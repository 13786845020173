import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

function UploadFiles({ id, onRef, handleFiles, multipleUpload = false }) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      onRef.current.value = "";
      handleFiles(acceptedFiles);
    },
    [handleFiles, onRef]
  );

  const { getInputProps } = useDropzone({
    onDrop,
    multiple: multipleUpload,
    noClick: false,
  });
  return (
    <input
      id={id}
      {...getInputProps()}
      ref={onRef}
      type="file"
      style={{ display: "none" }} // Mantiene el input invisible
      multiple={multipleUpload}
    />
  );
}

export default UploadFiles;
