import { styled } from "@mui/material";

export const CategoryFormStyled = styled("form")(() => ({
  "&.categoryForm": {
    minWidth: 680,
  },
  "& .modalContainder": {
    background: "#fff",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: 32,
    borderRadius: 5,
    minWidth: 680,
    "& .modal-container-title": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "4px 0px 24px 0px",
      fontSize: 18,
      fontWeight: 500,
    },
  },
  "& .formContainer": {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    margin: "8px 0px",
    "& label": {
      margin: "0px 8px 0px 0px",
      width: 90,
    },
    "& textarea": {
      width: 350,
      maxHeight: 400,
      minHeight: 38,
      border: "1px solid #ccc",
      "&::placeholder": {
        color: "#adb5bd",
      },
    },
  },
  "& .permissionsGroupContainer": {
    display: "flex",
    alignItems: "flex-start",
  },
  "& .permissionsGroupContent": {
    margin: "8px",
  },
  "& .viewPermissionsLabelContainer": {
    display: "flex",
    width: "100%",
    fontSize: 14,
    marginLeft: 107,
    marginTop: 18,
  },
  "& .inactiveContainer": {
    padding: 12,
    width: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 80,
    "& label": {
      margin: 0,
    },
  },
}));
