import React from "react";

function CreateQuote({ item }) {
  const { data } = item;
  return (
    <div style={{ marginLeft: 32 }}>
      <div>
        <div>
          {Object.keys(data).map((key, index) => {
            if (key !== "items") {
              return (
                <div key={index}>
                  {key}: {data[key] || '"  "'}
                </div>
              );
            }
            return null;
          })}
        </div>
        <div>Items:</div>
        <div style={{ marginLeft: 24 }}>
          {data.items.map((item, index) => {
            return (
              <div
                key={index + item.price + Math.random() * 100}
                className="item"
                style={{ margin: "16px 0px" }}
              >
                {Object.keys(item).map((key, index) => (
                  <div key={index + "item"}>
                    {key}: {item[key]}
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default CreateQuote;
