import { Autocomplete, styled } from "@mui/material";
import AppConfig from "../../constants/AppConfig";
import { colors } from "../../assets/jss/variables";

export const AutoCompleteWithChipsStyled = styled(Autocomplete)(() => ({
  "&.autocomplete-chips": {
    "& .autocomplete-chips-inputRoot": {
      background: "#fff",
      border: "1px solid #D4DFED",
      padding: "8px",
      "&:before": {
        borderBottom: "none",
      },
      "& .autocomplete-chips-endAdornment": {
        display: "none",
      },
    },
    "& .autocomplete-chip": {
      background: AppConfig.themeColors.primaryLight,
      color: colors.primaryDark,
      borderRadius: 20,
      border: "none",
      "& .autocomplete-chip-deleteIcon": {
        color: "#CBCBCB",
        "&:hover": {
          color: "#9c9c9c",
        },
      },
      "& .deleteIcon-non-displaying": {
        display: "none",
      },
    },
  },
}));
