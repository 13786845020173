import { TASK_TYPE } from "../../helpers/constants";
import Task from "./task";

export default class PurchaseOrderTask extends Task {
  constructor({ listIndex = 0, ...rest }) {
    super(rest);
    this.listIndex = listIndex;
    this.type = TASK_TYPE.PURCHASE_ORDER;
  }
}
