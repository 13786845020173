import React from "react";
import { ACTIVITY_STREAM_ACTION } from "./ActivityStreamContext";
import { getUserByField } from "../../../helpers/helpers";
import { useCompanyUsers } from "../../../hooks/user";
import { cx } from "@emotion/css";
import { ExpandActivityIcon } from "../../../helpers/iconSvgPath";
import { activityType } from "../../../helpers/activitiesStream";

function ActivityFooter({
  activity,
  activityRef,
  readMoreStatus,
  handleReadMoreStatus,
  onReadActivity = () => {},
  dispatchActivityStream,
}) {
  const companyUsers = useCompanyUsers({
    showBotUser: true,
    showInactiveUsers: true,
  });

  return (
    <div className="activity-footer">
      {activity.user && (
        <span className="username">
          @
          {getUserByField({
            field: "displayName",
            userId: activity.user,
            users: companyUsers,
          })}
        </span>
      )}
      {readMoreStatus.showReadMore && (
        <span
          className="readMore"
          onClick={() => {
            onReadActivity();
            handleReadMoreStatus((oldState) => ({
              ...oldState,
              showAll: !oldState.showAll,
            }));
            if (readMoreStatus.showAll) {
              setTimeout(() => {
                activityRef.current?.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }, 150);
            }
          }}
        >
          {readMoreStatus.showAll ? ` Show Less ` : ` Read more `}
        </span>
      )}
      {activity.type === activityType.NOTE && (
        <ExpandActivityIcon
          onClick={() => {
            onReadActivity();
            dispatchActivityStream({
              type: ACTIVITY_STREAM_ACTION.COMMON,
              payload: {
                activityModal: activity,
              },
            });
          }}
          svgClass={cx("expandIcon", "iconVisibility")}
        />
      )}
    </div>
  );
}

export default ActivityFooter;
