import Airplane from "../assets/emojis/Airplane.png";
import Articulated_Lorry from "../assets/emojis/Articulated_Lorry.png";
import Automobile from "../assets/emojis/Automobile.png";
import Auto_Rickshaw from "../assets/emojis/Auto_Rickshaw.png";
import Avocado from "../assets/emojis/Avocado.png";
import Baby_Chick from "../assets/emojis/Baby_Chick.png";
import Bacon from "../assets/emojis/Bacon.png";
import Banana from "../assets/emojis/Banana.png";
import Bell_Pepper from "../assets/emojis/Bell_Pepper.png";
import Bicycle from "../assets/emojis/Bicycle.png";
import Bison from "../assets/emojis/Bison.png";
import Black_Cat from "../assets/emojis/Black_Cat.png";
import Blossom from "../assets/emojis/Blossom.png";
import Blueberries from "../assets/emojis/Blueberries.png";
import Bouquet from "../assets/emojis/Bouquet.png";
import Broccoli from "../assets/emojis/Broccoli.png";
import Bus from "../assets/emojis/Bus.png";
import Cactus from "../assets/emojis/Cactus.png";
import Candy from "../assets/emojis/Candy.png";
import Carousel_Horse from "../assets/emojis/Carousel_Horse.png";
import Cherries from "../assets/emojis/Cherries.png";
import Cherry_Blossom from "../assets/emojis/Cherry_Blossom.png";
import Coconut from "../assets/emojis/Coconut.png";
import Cookie from "../assets/emojis/Cookie.png";
import Cooking from "../assets/emojis/Cooking.png";
import Cow from "../assets/emojis/Cow.png";
import Crab from "../assets/emojis/Crab.png";
import Crocodile from "../assets/emojis/Crocodile.png";
import Croissant from "../assets/emojis/Croissant.png";
import Cucumber from "../assets/emojis/Cucumber.png";
import Cupcake from "../assets/emojis/Cupcake.png";
import Custard from "../assets/emojis/Custard.png";
import Deciduous_Tree from "../assets/emojis/Deciduous_Tree.png";
import Deer from "../assets/emojis/Deer.png";
import Delivery_Truck from "../assets/emojis/Delivery_Truck.png";
import Dodo from "../assets/emojis/Dodo.png";
import Dog_Face from "../assets/emojis/Dog_Face.png";
import Dog from "../assets/emojis/Dog.png";
import Dolphin from "../assets/emojis/Dolphin.png";
import Doughnut from "../assets/emojis/Doughnut.png";
import Dove from "../assets/emojis/Dove.png";
import Dragon from "../assets/emojis/Dragon.png";
import Duck from "../assets/emojis/Duck.png";
import Eagle from "../assets/emojis/Eagle.png";
import Elephant from "../assets/emojis/Elephant.png";
import Ewe from "../assets/emojis/Ewe.png";
import Feather from "../assets/emojis/Feather.png";
import Fish from "../assets/emojis/Fish.png";
import Flamingo from "../assets/emojis/Flamingo.png";
import Flying_Saucer from "../assets/emojis/Flying_Saucer.png";
import Fox from "../assets/emojis/Fox.png";
import French_Fries from "../assets/emojis/French_Fries.png";
import Fried_Shrimp from "../assets/emojis/Fried_Shrimp.png";
import Frog from "../assets/emojis/Frog.png";
import Front_Facing_Baby_Chick from "../assets/emojis/Front_Facing_Baby_Chick.png";
import Giraffe from "../assets/emojis/Giraffe.png";
import Goat from "../assets/emojis/Goat.png";
import Gorilla from "../assets/emojis/Gorilla.png";
import Grapes from "../assets/emojis/Grapes.png";
import Hamburger from "../assets/emojis/Hamburger.png";
import Hamster from "../assets/emojis/Hamster.png";
import Hatching_Chick from "../assets/emojis/Hatching_Chick.png";
import Hear_No_Evil_Monkey from "../assets/emojis/Hear_No_Evil_Monkey.png";
import Hedgehog from "../assets/emojis/Hedgehog.png";
import Helicopter from "../assets/emojis/Helicopter.png";
import Hibiscus from "../assets/emojis/Hibiscus.png";
import Hippopotamus from "../assets/emojis/Hippopotamus.png";
import Honeybee from "../assets/emojis/Honeybee.png";
import Horse from "../assets/emojis/Horse.png";
import Horse_Face from "../assets/emojis/Horse_Face.png";
import Hot_Dog from "../assets/emojis/Hot_Dog.png";
import Hot_Pepper from "../assets/emojis/Hot_Pepper.png";
import Kangaroo from "../assets/emojis/Kangaroo.png";
import Kiwi_Fruit from "../assets/emojis/Kiwi_Fruit.png";
import Koala from "../assets/emojis/Koala.png";
import Lady_Beetle from "../assets/emojis/Lady_Beetle.png";
import Lemon from "../assets/emojis/Lemon.png";
import Leopard from "../assets/emojis/Leopard.png";
import Lion from "../assets/emojis/Lion.png";
import Llama from "../assets/emojis/Llama.png";
import Lobster from "../assets/emojis/Lobster.png";
import Locomotive from "../assets/emojis/Locomotive.png";
import Mammoth from "../assets/emojis/Mammoth.png";
import Monkey from "../assets/emojis/Monkey.png";
import Monkey_Face from "../assets/emojis/Monkey_Face.png";
import Motorcycle from "../assets/emojis/Motorcycle.png";
import Motor_Boat from "../assets/emojis/Motor_Boat.png";
import Motor_Scooter from "../assets/emojis/Motor_Scooter.png";
import Mountain_Cableway from "../assets/emojis/Mountain_Cableway.png";
import Mushroom from "../assets/emojis/Mushroom.png";
import Octopus from "../assets/emojis/Octopus.png";
import Olive from "../assets/emojis/Olive.png";
import Orangutan from "../assets/emojis/Orangutan.png";
import Otter from "../assets/emojis/Otter.png";
import Owl from "../assets/emojis/Owl.png";
import Ox from "../assets/emojis/Ox.png";
import Palm_Tree from "../assets/emojis/Palm_Tree.png";
import Pancakes from "../assets/emojis/Pancakes.png";
import Panda from "../assets/emojis/Panda.png";
import Parachute from "../assets/emojis/Parachute.png";
import Parrot from "../assets/emojis/Parrot.png";
import Peacock from "../assets/emojis/Peacock.png";
import Peanuts from "../assets/emojis/Peanuts.png";
import Penguin from "../assets/emojis/Penguin.png";
import Pickup_Truck from "../assets/emojis/Pickup_Truck.png";
import Pie from "../assets/emojis/Pie.png";
import Pig_Face from "../assets/emojis/Pig_Face.png";
import Pig from "../assets/emojis/Pig.png";
import Pineapple from "../assets/emojis/Pineapple.png";
import Pizza from "../assets/emojis/Pizza.png";
import Polar_Bear from "../assets/emojis/Polar_Bear.png";
import Poodle from "../assets/emojis/Poodle.png";
import Potted_Plant from "../assets/emojis/Potted_Plant.png";
import Rabbit from "../assets/emojis/Rabbit.png";
import Rabbit_Face from "../assets/emojis/Rabbit_Face.png";
import Raccoon from "../assets/emojis/Raccoon.png";
import Racing_Car from "../assets/emojis/Racing_Car.png";
import Ram from "../assets/emojis/Ram.png";
import Rhinoceros from "../assets/emojis/Rhinoceros.png";
import Rocket from "../assets/emojis/Rocket.png";
import Rooster from "../assets/emojis/Rooster.png";
import Rose from "../assets/emojis/Rose.png";
import Rosette from "../assets/emojis/Rosette.png";
import Sailboat from "../assets/emojis/Sailboat.png";
import Sauropod from "../assets/emojis/Sauropod.png";
import Seal from "../assets/emojis/Seal.png";
import See_No_Evil_Monkey from "../assets/emojis/See_No_Evil_Monkey.png";
import Shark from "../assets/emojis/Shark.png";
import Sheaf_Of_Rice from "../assets/emojis/Sheaf_Of_Rice.png";
import Ship from "../assets/emojis/Ship.png";
import Shrimp from "../assets/emojis/Shrimp.png";
import Sloth from "../assets/emojis/Sloth.png";
import Small_Airplane from "../assets/emojis/Small_Airplane.png";
import Snail from "../assets/emojis/Snail.png";
import Speak_No_Evil_Monkey from "../assets/emojis/Speak_No_Evil_Monkey.png";
import Speedboat from "../assets/emojis/Speedboat.png";
import Spiral_Shell from "../assets/emojis/Spiral_Shell.png";
import Spouting_Whale from "../assets/emojis/Spouting_Whale.png";
import Squid from "../assets/emojis/Squid.png";
import Strawberry from "../assets/emojis/Strawberry.png";
import Sunflower from "../assets/emojis/Sunflower.png";
import Swan from "../assets/emojis/Swan.png";
import T_Rex from "../assets/emojis/T_Rex.png";
import Tangerine from "../assets/emojis/Tangerine.png";
import Tiger from "../assets/emojis/Tiger.png";
import Tiger_Face from "../assets/emojis/Tiger_Face.png";
import Tractor from "../assets/emojis/Tractor.png";
import Tropical_Fish from "../assets/emojis/Tropical_Fish.png";
import Tulip from "../assets/emojis/Tulip.png";
import Turkey from "../assets/emojis/Turkey.png";
import Turtle from "../assets/emojis/Turtle.png";
import Two_Hump_Camel from "../assets/emojis/Two_Hump_Camel.png";
import Unicorn from "../assets/emojis/Unicorn.png";
import Waffle from "../assets/emojis/Waffle.png";
import Water_Buffalo from "../assets/emojis/Water_Buffalo.png";
import Watermelon from "../assets/emojis/Watermelon.png";
import Whale from "../assets/emojis/Whale.png";
import Wilted_Flower from "../assets/emojis/Wilted_Flower.png";
import Wolf from "../assets/emojis/Wolf.png";
import Zebra from "../assets/emojis/Zebra.png";

export const emojisIcon = [
  { src: Airplane, alt: "Airplane" },
  { src: Articulated_Lorry, alt: "Articulated_Lorry" },
  { src: Automobile, alt: "Automobile" },
  { src: Auto_Rickshaw, alt: "Auto_Rickshaw" },
  { src: Avocado, alt: "Avocado" },
  { src: Baby_Chick, alt: "Baby_Chick" },
  { src: Bacon, alt: "Bacon" },
  { src: Banana, atl: "Banana" },
  { src: Bell_Pepper, alt: "Bell_Pepper" },
  { src: Bicycle, alt: "Bicycle" },
  { src: Bison, alt: "Bison" },
  { src: Black_Cat, alt: "Black_Cat" },
  { src: Blossom, alt: "Blossom" },
  { src: Blueberries, alt: "Blueberries" },
  { src: Bouquet, alt: "Bouquet" },
  { src: Broccoli, alt: "Broccoli" },
  { src: Bus, alt: "Bus" },
  { src: Cactus, alt: "Cactus" },
  { src: Candy, alt: "Candy" },
  { src: Carousel_Horse, alt: "Carousel_Horse" },
  { src: Cherries, alt: "Cherries" },
  { src: Cherry_Blossom, alt: "Cherry_Blossom" },
  { src: Coconut, alt: "Coconut" },
  { src: Cookie, alt: "Cookie" },
  { src: Cooking, alt: "Cooking" },
  { src: Cow, alt: "Cow" },
  { src: Crab, alt: "Crab" },
  { src: Crocodile, alt: "Crocodile" },
  { src: Croissant, alt: "Croissant" },
  { src: Cucumber, alt: "Cucumber" },
  { src: Cupcake, alt: "Cupcake" },
  { src: Custard, alt: "Custard" },
  { src: Deciduous_Tree, alt: "Deciduous_Tree" },
  { src: Deer, alt: "Deer" },
  { src: Delivery_Truck, alt: "Delivery_Truck" },
  { src: Dodo, alt: "Dodo" },
  { src: Dog, alt: "Dog" },
  { src: Dog_Face, alt: "Dog_Face" },
  { src: Dolphin, alt: "Dolphin" },
  { src: Doughnut, alt: "Doughnut" },
  { src: Dove, alt: "Dove" },
  { src: Dragon, alt: "Dragon" },
  { src: Duck, alt: "Duck" },
  { src: Eagle, alt: "Eagle" },
  { src: Elephant, alt: "Elephant" },
  { src: Ewe, alt: "Ewe" },
  { src: Feather, alt: "Feather" },
  { src: Fish, alt: "Fish" },
  { src: Flamingo, alt: "Flamingo" },
  { src: Flying_Saucer, alt: "Flying_Saucer" },
  { src: Fox, alt: "Fox" },
  { src: French_Fries, alt: "French_Fries" },
  { src: Fried_Shrimp, alt: "Fried_Shrimp" },
  { src: Frog, alt: "Frog" },
  { src: Front_Facing_Baby_Chick, alt: "Front_Facing_Baby_Chick" },
  { src: Giraffe, alt: "Giraffe" },
  { src: Goat, alt: "Goat" },
  { src: Gorilla, alt: "Gorilla" },
  { src: Grapes, alt: "Grapes" },
  { src: Hamburger, alt: "Hamburger" },
  { src: Hamster, alt: "Hamster" },
  { src: Hatching_Chick, alt: "Hatching_Chick" },
  { src: Hear_No_Evil_Monkey, alt: "Hear_No_Evil_Monkey" },
  { src: Hedgehog, alt: "Hedgehog" },
  { src: Helicopter, alt: "Helicopter" },
  { src: Hibiscus, alt: "Hibiscus" },
  { src: Hippopotamus, alt: "Hippopotamus" },
  { src: Honeybee, alt: "Honeybee" },
  { src: Horse, alt: "Horse" },
  { src: Horse_Face, alt: "Horse_Face" },
  { src: Hot_Dog, alt: "Hot_Dog" },
  { src: Hot_Pepper, alt: "Hot_Pepper" },
  { src: Kangaroo, alt: "Kangaroo" },
  { src: Kiwi_Fruit, alt: "Kiwi_Fruit" },
  { src: Koala, alt: "Koala" },
  { src: Lady_Beetle, alt: "Lady_Beetle" },
  { src: Lemon, alt: "Lemon" },
  { src: Leopard, alt: "Leopard" },
  { src: Lion, alt: "Lion" },
  { src: Llama, alt: "Llama" },
  { src: Lobster, alt: "Lobster" },
  { src: Locomotive, alt: "Locomotive" },
  { src: Mammoth, alt: "Mammoth" },
  { src: Monkey, alt: "Monkey" },
  { src: Monkey_Face, alt: "Monkey_Face" },
  { src: Motorcycle, alt: "Motorcycle" },
  { src: Motor_Boat, alt: "Motor_Boat" },
  { src: Motor_Scooter, alt: "Motor_Scooter" },
  { src: Mountain_Cableway, alt: "Mountain_Cableway" },
  { src: Mushroom, alt: "Mushroom" },
  { src: Octopus, alt: "Octopus" },
  { src: Olive, alt: "Olive" },
  { src: Orangutan, alt: "Orangutan" },
  { src: Otter, alt: "Otter" },
  { src: Owl, alt: "Owl" },
  { src: Ox, alt: "Ox" },
  { src: Palm_Tree, alt: "Palm_Tree" },
  { src: Pancakes, alt: "Pancakes" },
  { src: Panda, alt: "Panda" },
  { src: Parachute, alt: "Parachute" },
  { src: Parrot, alt: "Parrot" },
  { src: Peacock, alt: "Peacock" },
  { src: Peanuts, alt: "Peanuts" },
  { src: Penguin, alt: "Penguin" },
  { src: Pickup_Truck, alt: "Pickup_Truck" },
  { src: Pie, alt: "Pie" },
  { src: Pig, alt: "Pig" },
  { src: Pig_Face, alt: "Pig_Face" },
  { src: Pineapple, alt: "Pineapple" },
  { src: Pizza, alt: "Pizza" },
  { src: Polar_Bear, alt: "Polar_Bear" },
  { src: Poodle, alt: "Poodle" },
  { src: Potted_Plant, alt: "Potted_Plant" },
  { src: Rabbit, alt: "Rabbit" },
  { src: Rabbit_Face, alt: "Rabbit_Face" },
  { src: Raccoon, alt: "Raccoon" },
  { src: Racing_Car, alt: "Racing_Car" },
  { src: Ram, alt: "Ram" },
  { src: Rhinoceros, alt: "Rhinoceros" },
  { src: Rocket, alt: "Rocket" },
  { src: Rooster, alt: "Rooster" },
  { src: Rose, alt: "Rose" },
  { src: Rosette, alt: "Rosette" },
  { src: Sailboat, alt: "Sailboat" },
  { src: Sauropod, alt: "Sauropod" },
  { src: Seal, alt: "Seal" },
  { src: See_No_Evil_Monkey, alt: "See_No_Evil_Monkey" },
  { src: Shark, alt: "Shark" },
  { src: Sheaf_Of_Rice, alt: "Sheaf_Of_Rice" },
  { src: Ship, alt: "Ship" },
  { src: Shrimp, alt: "Shrimp" },
  { src: Sloth, alt: "Sloth" },
  { src: Small_Airplane, alt: "Small_Airplane" },
  { src: Snail, alt: "Snail" },
  { src: Speak_No_Evil_Monkey, alt: "Speak_No_Evil_Monkey" },
  { src: Speedboat, alt: "Speedboat" },
  { src: Spiral_Shell, alt: "Spiral_Shell" },
  { src: Spouting_Whale, alt: "Spouting_Whale" },
  { src: Squid, alt: "Squid" },
  { src: Strawberry, alt: "Strawberry" },
  { src: Sunflower, alt: "Sunflower" },
  { src: Swan, alt: "Swan" },
  { src: Tangerine, alt: "Tangerine" },
  { src: Tiger, alt: "Tiger" },
  { src: Tiger_Face, alt: "Tiger_Face" },
  { src: Tractor, alt: "Tractor" },
  { src: Tropical_Fish, alt: "Tropical_Fish" },
  { src: Tulip, alt: "Tulip" },
  { src: Turkey, alt: "Turkey" },
  { src: Turtle, alt: "Turtle" },
  { src: Two_Hump_Camel, alt: "Two_Hump_Camel" },
  { src: T_Rex, alt: "T_Rex" },
  { src: Unicorn, alt: "Unicorn" },
  { src: Waffle, alt: "Waffle" },
  { src: Watermelon, alt: "Watermelon" },
  { src: Water_Buffalo, alt: "Water_Buffalo" },
  { src: Whale, alt: "Whale" },
  { src: Wilted_Flower, alt: "Wilted_Flower" },
  { src: Wolf, alt: "Wolf" },
  { src: Zebra, alt: "Zebra" },
];
