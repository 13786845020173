import { styled } from "@mui/material";

export const LegendContainer = styled("div")(() => ({
  "&.root": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    position: "relative",
  },
  "& .legendContainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    fontSize: 12,
    right: "4px",
    "& span": {
      marginLeft: 10,
      marginRight: 10,
      fontSize: 12,
      color: "#828282",
      fontWeight: 500,
      fontFamily: "Roboto",
    },
  },
  "& .total-tasks-text": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    fontSize: 12,
    fontWeight: 500,
    color: "#828282",
    fontFamily: "Roboto",
    marginRight: 10,
    position: "absolute",
    right: 0,
    top: 32,
  },
}));
