import React from "react";
import { Switch } from "@mui/material";
import { ReportTreeItemStyled } from "./styles";
import TooltipTD from "../Tooltip/TooltipTD";

function InactiveReportsList({ document, onActivate = () => {} }) {
  return (
    <ReportTreeItemStyled className="reportTreeItemContainer">
      <div
        style={{
          width: 500,
          minWidth: 500,
          display: "flex",
          alignItems: "center",
          gap: 8,
        }}
      >
        {document.name}
      </div>
      <div className="descriptionToolBox">
        <span className="reportDescription">{document.description}</span>
        <div className="reportItemOptions">
          {document.type !== "folder" && (
            <TooltipTD label="Activate report" className="hiddenIcon">
              <Switch
                onChange={() => {}}
                onClick={(ev) => {
                  onActivate(document);
                  ev.stopPropagation();
                }}
                checked={false}
                size="small"
              />
            </TooltipTD>
          )}
        </div>
      </div>
    </ReportTreeItemStyled>
  );
}

export default InactiveReportsList;
