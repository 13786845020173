import React from "react";
import userTypes from "../../api/types/userTypes";

function RoleScreenPermission({
  list,
  permissions = [],
  isDisabled,
  role,
  onChangePermission,
}) {
  const isSuperAdmin = role && role.name === userTypes.SUPER_ADMIN;
  return (
    <div className="user-manager-permissions">
      <ul
        className="permissions-container"
        style={{ padding: "0px 16px 48px 16px" }}
      >
        {list.map((permission, index) => {
          const currentPermission =
            permissions.find((item) => item.id === permission.value) || {};
          const tabs = permission.tabs;
          const tabsStlye = tabs
            ? {
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }
            : { marginBottom: 10 };
          const classLabel = tabs
            ? "permission-label-tabs"
            : "permission-label";
          return (
            <li
              key={permission.value + index}
              style={{ padding: 3, width: "100%", ...tabsStlye }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ width: 35, marginRight: 10 }}>
                  {permission.icon || ""}
                </div>
                <div style={{ display: "flex" }}>
                  <label className="switch" style={{ margin: 0, minWidth: 77 }}>
                    <input
                      disabled={isDisabled}
                      onChange={(ev) =>
                        onChangePermission({
                          value: ev.target.value,
                          permission,
                          document: currentPermission,
                        })
                      }
                      checked={
                        isSuperAdmin ? true : !!currentPermission.enabled
                      }
                      value={permission.value}
                      type="checkbox"
                    />
                    <span
                      data-positive="YES"
                      data-negative="NO"
                      className="slider round"
                    />
                  </label>

                  <div
                    className="permission-label-container"
                    style={{ marginLeft: 8 }}
                  >
                    <span className={classLabel}>
                      {`${permission.label}`}
                      <span className="permission-label">
                        {tabs ? " - access per tab" : ""}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              {tabs && (
                <ul
                  className="permissions-container"
                  style={{
                    marginTop: 7,
                    marginLeft: 85,
                    borderLeft: "3px solid #87A3C2",
                    paddingBottom: 0,
                    paddingLeft: 45,
                  }}
                >
                  {tabs.map((tab) => {
                    const currentTabPermission =
                      permissions.find((item) => item.id === tab.value) || {};
                    return (
                      <li
                        key={tab.value + index}
                        style={{ padding: 3, width: "100%" }}
                      >
                        <label
                          className="switch"
                          style={{ margin: 0, minWidth: 77 }}
                        >
                          <input
                            disabled={isDisabled}
                            onChange={(ev) =>
                              onChangePermission({
                                value: ev.target.value,
                                permission,
                                tab,
                                document: currentPermission,
                              })
                            }
                            checked={
                              isSuperAdmin
                                ? true
                                : !!currentTabPermission.enabled
                            }
                            value={tab.value}
                            type="checkbox"
                          />
                          <span
                            data-positive="YES"
                            data-negative="NO"
                            className="slider round"
                          />
                        </label>
                        <div
                          className="permission-label-container"
                          style={{ marginLeft: 8 }}
                        >
                          <span className="permission-label">{tab.label}</span>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default RoleScreenPermission;
