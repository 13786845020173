import { now } from "moment/moment";
import { getRandomId } from "../../helpers/helpers";

export default class Category {
  constructor({
    id = getRandomId(),
    size = 1,
    categories = [],
    creationDate = now(),
  }) {
    this.id = id;
    this.size = size;
    this.categories = categories;
    this.creationDate = creationDate;
  }
}
