import { useSelector } from "react-redux";
import Item from "../api/model/Item";
import { dbTables } from "../api/types/dbTables";
import itemStatus from "../api/types/itemStatus";
import userTypes from "../api/types/userTypes";
import { GENERAL_PERMISSION_VALUE } from "../helpers/constants";

export function useItemTable() {
  const itemTables = useSelector(
    (state) =>
      (state.data[dbTables.ITEM_TABLE] && state.data[dbTables.ITEM_TABLE]) || {}
  );
  return itemTables;
}

export const useSOItemList = ({
  purchaseOrders = [],
  salesOrder = {},
  user = {},
}) => {
  const itemsDB = useSelector(
    (state) => (state.data[dbTables.ITEMS] && state.data[dbTables.ITEMS]) || {}
  );
  let allItems = [];
  let allItemsToDiscard = [];
  const { purchaseOrderIds = [], items = [] } = salesOrder;
  if (
    purchaseOrderIds &&
    purchaseOrderIds.length > 0 &&
    purchaseOrders.length === 0
  ) {
    return [];
  }
  if (
    purchaseOrderIds &&
    purchaseOrderIds.length === 0 &&
    !user.permissions[GENERAL_PERMISSION_VALUE.ALL_VENDORS] &&
    user.role !== userTypes.SUPER_ADMIN
  ) {
    return [];
  }

  let verifyBeforeRendering = true;
  purchaseOrders.forEach((po) => {
    const exist = itemsDB[po.itemTableId];
    if (!exist) {
      verifyBeforeRendering = false;
    }
  });

  if (!verifyBeforeRendering) {
    return [];
  }

  for (let i = 0; i < purchaseOrders.length; i++) {
    const purchaseOrder = purchaseOrders[i];
    const itemTableId = purchaseOrder.itemTableId;
    const poItems = itemsDB[itemTableId] || [];
    if (
      user.permissions[GENERAL_PERMISSION_VALUE.ALL_VENDORS] ||
      user.permissions[purchaseOrder.factoryId] ||
      user.role === userTypes.SUPER_ADMIN
    ) {
      allItems = [...allItems, ...poItems];
    } else {
      allItemsToDiscard = [
        ...allItemsToDiscard,
        ...poItems.map((item) => ({ ...item, notAllowedToSee: true })),
      ];
    }
  }
  if (salesOrder && salesOrder.items) {
    items.forEach((salesOrderItem) => {
      const isSalesOrderItem = allItems.find(
        (item) => String(item.itemNumber) === String(salesOrderItem.itemNumber)
      );
      if (
        !isSalesOrderItem &&
        (user.role === userTypes.SUPER_ADMIN ||
          user.permissions[GENERAL_PERMISSION_VALUE.ALL_VENDORS])
      ) {
        const {
          cbmMaster,
          description,
          itemNumber,
          price,
          piecesPerMaster,
          orderQuantity,
          image,
        } = salesOrderItem;
        allItems.push({
          ...new Item({
            cbmMaster,
            description,
            itemNumber,
            salesOrderPrice: { [salesOrder.id]: price },
            piecesPerMaster,
            salesOrderQuantity: {
              [salesOrder.id]: orderQuantity,
            },
            mismatchStatus: {
              purchaseOrder: true,
              salesOrder: true,
            },
            salesOrderIds: [salesOrder.id],
            image,
          }),
          status: itemStatus.READ_ONLY,
        });
      }
    });
    allItems = allItems.filter((item) =>
      item.salesOrderIds.includes(salesOrder.id)
    );
  }
  const itemsToDiscardNumber = allItemsToDiscard.map((item) =>
    String(item.itemNumber)
  );

  allItems = allItems.filter(
    (item) => !itemsToDiscardNumber.includes(String(item.itemNumber))
  );

  return [...allItems, ...allItemsToDiscard];
};

export const useAllPOItems = () =>
  useSelector(
    (state) => (state.data[dbTables.ITEMS] && state.data[dbTables.ITEMS]) || {}
  );

export function usePOItemsList({ currentPurchaseOrder }) {
  const itemsDB = useSelector(
    (state) => (state.data[dbTables.ITEMS] && state.data[dbTables.ITEMS]) || {}
  );
  let poItems = [];
  if (currentPurchaseOrder.itemTableId) {
    poItems = itemsDB[currentPurchaseOrder.itemTableId] || [];
  }
  return poItems;
}

export function useItemsList({
  purchaseOrders = [],
  currentPurchaseOrder,
  salesOrder = {},
  user = {},
}) {
  const itemsDB = useSelector(
    (state) => (state.data[dbTables.ITEMS] && state.data[dbTables.ITEMS]) || {}
  );
  if (currentPurchaseOrder) {
    let poItems = [];
    if (currentPurchaseOrder.itemTableId) {
      poItems = itemsDB[currentPurchaseOrder.itemTableId] || [];
    }
    return poItems;
  }

  let allItems = [];
  let allItemsToDiscard = [];

  let verifyBeforeRendering = true;
  if (
    salesOrder.purchaseOrderIds &&
    salesOrder.purchaseOrderIds.length > 0 &&
    purchaseOrders.length === 0
  ) {
    return [];
  }

  if (
    salesOrder.purchaseOrderIds &&
    salesOrder.purchaseOrderIds.length === 0 &&
    !user.permissions[GENERAL_PERMISSION_VALUE.ALL_VENDORS] &&
    user.role !== userTypes.SUPER_ADMIN
  ) {
    return [];
  }

  purchaseOrders.forEach((po) => {
    const exist = itemsDB[po.itemTableId];
    if (!exist) {
      verifyBeforeRendering = false;
    }
  });
  if (!verifyBeforeRendering) {
    return [];
  }
  for (let i = 0; i < purchaseOrders.length; i++) {
    const purchaseOrder = purchaseOrders[i];
    const itemTableId = purchaseOrder.itemTableId;
    const items = itemsDB[itemTableId] || [];
    if (
      user.permissions[GENERAL_PERMISSION_VALUE.ALL_VENDORS] ||
      user.permissions[purchaseOrder.factoryId] ||
      user.role === userTypes.SUPER_ADMIN
    ) {
      allItems = [...allItems, ...items];
    } else {
      allItemsToDiscard = [...allItemsToDiscard, ...items];
    }
  }

  if (salesOrder && salesOrder.items) {
    salesOrder.items.forEach((salesOrderItem) => {
      const isSalesOrderItem = allItems.find(
        (item) => String(item.itemNumber) === String(salesOrderItem.itemNumber)
      );
      if (
        !isSalesOrderItem &&
        (user.role === userTypes.SUPER_ADMIN ||
          user.permissions[GENERAL_PERMISSION_VALUE.ALL_VENDORS])
      ) {
        const {
          cbmMaster,
          description,
          itemNumber,
          price,
          piecesPerMaster,
          orderQuantity,
          image,
        } = salesOrderItem;
        allItems.push({
          ...new Item({
            cbmMaster,
            description,
            itemNumber,
            salesOrderPrice: { [salesOrder.id]: price },
            piecesPerMaster,
            salesOrderQuantity: {
              [salesOrder.id]: orderQuantity,
            },
            mismatchStatus: {
              purchaseOrder: true,
              salesOrder: true,
            },
            salesOrderIds: [salesOrder.id],
            image,
          }),
          status: itemStatus.READ_ONLY,
        });
      }
    });
    allItems = allItems.filter((item) =>
      item.salesOrderIds.includes(salesOrder.id)
    );
  }
  const itemsToDiscardNumber = allItemsToDiscard.map((item) =>
    String(item.itemNumber)
  );
  allItems = allItems.filter(
    (item) => !itemsToDiscardNumber.includes(String(item.itemNumber))
  );

  return allItems;
}

export const shipmentItemList = ({
  poItemsList = [],
  currentShipment = {},
  soItemsList = [],
  purchaseOrders = [],
  user = {},
}) => {
  let filterItems = poItemsList.filter(
    (item) =>
      item.shipmentIds &&
      currentShipment &&
      item.shipmentIds.includes(currentShipment.id)
  );
  let currentsShipmentItem = currentShipment.items || [];
  currentsShipmentItem.forEach((currentItem) => {
    const item = filterItems.find(
      (shipmentItem) => shipmentItem.id === currentItem.id
    );
    if (!item) {
      const poItem =
        soItemsList.find((item) => item.id === currentItem.id) || {};
      filterItems = [
        ...filterItems,
        {
          ...new Item({
            ...currentItem,
            ...poItem,
            purchaseOrderId: currentItem.currentPurchaseOrderId,
          }),
          status: poItem.id ? "" : itemStatus.READ_ONLY,
          ref: poItem.ref,
        },
      ];
    }
  });
  filterItems = filterItems.filter((item) => {
    const shipmentQuantity = item.shipmentQuantity || {};
    const shipmentId = currentShipment ? currentShipment.id : "-";
    return shipmentQuantity[shipmentId]
      ? parseInt(shipmentQuantity[shipmentId]) > 0
      : false;
  });
  filterItems = filterItems.map((item) => {
    const po = purchaseOrders.find((po) => item.purchaseOrderId === po.id);
    if (
      user.role === userTypes.SUPER_ADMIN ||
      user.permissions[GENERAL_PERMISSION_VALUE.ALL_VENDORS] ||
      (po && user.permissions[po.factoryId])
    ) {
      return item;
    } else {
      return { ...item, notAllowedToSee: true };
    }
  });
  return filterItems;
};

export function useItems() {
  const itemsDB = useSelector(
    (state) => (state.data[dbTables.ITEMS] && state.data[dbTables.ITEMS]) || []
  );
  return itemsDB;
}
