import { getRandomId } from "../../helpers/helpers";
import moment from "moment";

export default class Company {
  constructor({
    id = "",
    name = "",
    url_logo = "",
    enabled = true,
    templateId = getRandomId(),
    created = moment.now(),
  }) {
    this.id = id;
    this.name = name;
    this.url_logo = url_logo;
    this.enabled = enabled;
    this.templateId = templateId;
    this.created = created;
  }
}
