import { styled } from "@mui/material";

export const CustomerVendorFormStyled = styled("form")(() => ({
  "& .formContainer": {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    margin: "8px 0px",
    "& label": {
      margin: "0px 8px 0px 0px",
      width: 130,
    },
  },
  "& .gridFrom": {
    margin: "auto",
    width: "auto",
    "& .inputForm": {
      width: 350,
      height: 36,
      fontSize: 12,
      fontWeight: 400,
    },
  },
}));
