import React, { useCallback, useState } from "react";
import { dbTables } from "../../api/types/dbTables";
import {
  isTradeDashEmployee,

} from "../../helpers/helpers";
import ManagementDocumentsHeader from "./ManagementDocumentsHeader";
import CreateFileFolderTemplate from "./CreateFileFolderTemplate";
import FolderDocument from "../../api/model/FolderDocument";
import FileDocument from "../../api/model/FileDocument";
import { addGenericDataToFirestore } from "../../actions/DataActions";
import { useDispatch } from "react-redux";
import TemplateTreeDocuments from "./TemplateTreeDocuments";
import { verifyFolderDuplicateNames } from "../Documents/DocumentHelper";

function DocumentTemplate({
  style,
  permissionGroups = [],
  currentUser = {},
  documents = [],
  title,
  companyId,
  dbTable,
}) {
  const [newItem, setNewItem] = useState({});
  const dispatch = useDispatch();
  const addDocument = useCallback((document) =>
    addGenericDataToFirestore(
      [dbTables.COMPANIES, companyId, dbTable, document.id],
      document
    )(dispatch)
  );
  return (
    <div style={{ ...style }}>
      <ManagementDocumentsHeader
        title={title}
        handleAddNewFile={() =>
          setNewItem({
            ...new FileDocument({
              placeholder: true,
              user: currentUser.id,
            }),
          })
        }
        handleAddNewFolder={() =>
          setNewItem({
            ...new FolderDocument({
              placeholder: true,
              user: currentUser.id,
            }),
          })
        }
      />
      {newItem.id && (
        <CreateFileFolderTemplate
          handleCancel={() => setNewItem({})}
          handleSaveFolder={(name) => {
            addDocument({
              ...newItem,
              name: verifyFolderDuplicateNames(
                name.trim() || "New Folder",
                documents.filter((doc) => doc.parentId === "")
              ),
            });
            setNewItem({});
          }}
          itemDocument={newItem}
        />
      )}
      <TemplateTreeDocuments
        permissionGroupsDB={permissionGroups}
        selectedDocumentTab={0}
        documents={documents}
        dbCollection={dbTable}
        isReadOnly={isTradeDashEmployee(currentUser)}
      />
    </div>
  );
}

export default DocumentTemplate;
