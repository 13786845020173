import React, { useState } from "react";
import { RadioButtonChecked as RadioButtonCheckedIcon } from "@mui/icons-material";
import { MultiSelectButton, PopoverMultiSelect } from "./styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TooltipTD from "../Tooltip/TooltipTD";
import { colors } from "../../assets/jss/variables";
import {
  Button,
  ClickAwayListener,
  MenuItem,
  Radio,
  useMediaQuery,
} from "@mui/material";
import { cx } from "@emotion/css";
// import { trackEvent } from "../../helpers/analytics";

function SelectFilter({
  buttonId = "",
  label,
  icon,
  options = [],
  value = [],
  onChange = () => {},
  notificationStyles = false,
  tooltip,
  onOpenList = () => {},
  currentList,
  setCurrentList = () => {},
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event, anchorEl) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      onOpenList(true);
      setCurrentList(label);
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    onOpenList(false);
    setCurrentList("");
    setAnchorEl(null);
  };

  // function trackUse() {
  // if (multiple && value) {
  // trackEvent("UsedDropdownFilters", {
  //   selectedNumber: value.length,
  //   filter: label
  // });
  // }
  // }
  const matches = useMediaQuery("(max-width:1424px)");
  const open = !!anchorEl && currentList === label;
  return (
    <MultiSelectButton>
      <Button
        id={buttonId}
        className={
          notificationStyles ? "notificationContainer" : "regularContainer"
        }
        style={{ backgroundColor: open ? "#e0e3e7" : "" }}
        onClick={(event) => handleClick(event, anchorEl)}
        color="inherit"
      >
        <TooltipTD label={tooltip} style={{ height: 22, display: "flex" }}>
          <img
            alt=""
            height={19}
            width={19}
            className="location-icon"
            src={icon}
          />
        </TooltipTD>

        {!matches && !notificationStyles && (
          <label className="textFilter">{label}</label>
        )}
        <ExpandMoreIcon
          className={cx("arrowDropDownIcon", { arrowActive: open })}
          style={{ color: colors.primaryDark }}
        />
      </Button>
      {open && (
        <PopoverMultiSelect
          id='customized-select-options"'
          open={open}
          anchorEl={anchorEl}
          placement="bottom-start"
          className="popperSelect"
          style={{ zIndex: 103, maxWidth: 180 }}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <div>
              {options.length > 0 &&
                options.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    className={"menuItem"}
                    onClick={(ev) => {
                      onChange(option.value);
                      setAnchorEl(false);
                      ev.stopPropagation();
                    }}
                  >
                    <Radio
                      id={option.value}
                      color="primary"
                      size="small"
                      classes={{ root: "radioClass", checked: "checkedClass" }}
                      checked={value.includes(option.value)}
                      checkedIcon={<RadioButtonCheckedIcon className="check" />}
                    />
                    <label>{option.label}</label>
                  </MenuItem>
                ))}
              {options.length === 0 && (
                <MenuItem
                  key={"EMPTY"}
                  value={"EMPTY"}
                  className={"menuItem"}
                  classes={{ selected: "select-item-selected" }}
                >
                  <label>{"No options"}</label>
                </MenuItem>
              )}
            </div>
          </ClickAwayListener>
        </PopoverMultiSelect>
      )}
    </MultiSelectButton>
  );
}

export default SelectFilter;
