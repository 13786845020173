/**
 * App Reducers
 */
import { combineReducers } from 'redux';
import sidebarReducer from './SidebarReducer';
import authUserReducer from './AuthUserReducer';
import DataReducer from './DataReducer';
import settings from "./settings";

const reducers = combineReducers({
   settings,
   sidebar: sidebarReducer,
   authUser: authUserReducer,
   data: DataReducer,
});

export default reducers;
