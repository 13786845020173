import React from "react";

import {
  ColorButton,
  ColorStyleButton,
  colorPickerData,
} from "../ComponentHelper";
import ArrowDown from "../../../assets/toolbarIcon/ArrowDown.svg";
import AIcon from "../../../assets/toolbarIcon/A.svg";
import { ClickAwayListener } from "@mui/material";

const HEAD_COLOR = [
  {
    label: colorPickerData.primaryDark.label,
    style: colorPickerData.primaryDark.name,
    color: colorPickerData.primaryDark.color,
    icon: (props) => <ColorButton active={props.active} color={props.color} />,
  },
  {
    label: colorPickerData.dBlue.label,
    style: colorPickerData.dBlue.name,
    color: colorPickerData.dBlue.color,
    icon: (props) => <ColorButton active={props.active} color={props.color} />,
  },
  {
    label: colorPickerData.dTeal.label,
    style: colorPickerData.dTeal.name,
    color: colorPickerData.dTeal.color,
    icon: (props) => <ColorButton active={props.active} color={props.color} />,
  },
  {
    label: colorPickerData.dGreen.label,
    style: colorPickerData.dGreen.name,
    color: colorPickerData.dGreen.color,
    icon: (props) => <ColorButton active={props.active} color={props.color} />,
  },
  {
    label: colorPickerData.orange.label,
    style: colorPickerData.orange.name,
    color: colorPickerData.orange.color,
    icon: (props) => <ColorButton active={props.active} color={props.color} />,
  },
  {
    label: colorPickerData.dRed.label,
    style: colorPickerData.dRed.name,
    color: colorPickerData.dRed.color,
    icon: (props) => <ColorButton active={props.active} color={props.color} />,
  },
  {
    label: colorPickerData.dPurple.label,
    style: colorPickerData.dPurple.name,
    color: colorPickerData.dPurple.color,
    icon: (props) => <ColorButton active={props.active} color={props.color} />,
  },
  {
    label: colorPickerData.lGray.label,
    style: colorPickerData.lGray.name,
    color: colorPickerData.lGray.color,
    icon: (props) => <ColorButton active={props.active} color={props.color} />,
  },
  {
    label: colorPickerData.blue.label,
    style: colorPickerData.blue.name,
    color: colorPickerData.blue.color,
    icon: (props) => <ColorButton active={props.active} color={props.color} />,
  },
  {
    label: colorPickerData.teal.label,
    style: colorPickerData.teal.name,
    color: colorPickerData.teal.color,
    icon: (props) => <ColorButton active={props.active} color={props.color} />,
  },
  {
    label: colorPickerData.green.label,
    style: colorPickerData.green.name,
    color: colorPickerData.green.color,
    icon: (props) => <ColorButton active={props.active} color={props.color} />,
  },
  {
    label: colorPickerData.yellow.label,
    style: colorPickerData.yellow.name,
    color: colorPickerData.yellow.color,
    icon: (props) => <ColorButton active={props.active} color={props.color} />,
  },
  {
    label: colorPickerData.red.label,
    style: colorPickerData.red.name,
    color: colorPickerData.red.color,
    icon: (props) => <ColorButton active={props.active} color={props.color} />,
  },
  {
    label: colorPickerData.purple.label,
    style: colorPickerData.purple.name,
    color: colorPickerData.purple.color,
    icon: (props) => <ColorButton active={props.active} color={props.color} />,
  },
];

export const ColorStyleControls = (props) => {
  const { openList, handleOpenList, classes, editorRef } = props;
  const currentStyle = props.editorState.getCurrentInlineStyle();

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (openList) {
          handleOpenList(false);
        }
      }}
    >
      <div
        className={classes}
        onClick={(ev) => {
          editorRef.current.focus();
          handleOpenList(!openList);
          ev.stopPropagation();
        }}
        style={{ background: openList ? "#efefef" : "" }}
      >
        <div className="colorContainer">
          <img src={AIcon} alt="AIcon" height={10} />
          <div className="colorGradient" />
        </div>
        <img src={ArrowDown} alt="ad" />
        {openList && (
          <div className={"containerColorHeadLine"}>
            {HEAD_COLOR.map((type, i) => (
              <div
                onClick={(ev) => {
                  ev.preventDefault();
                  handleOpenList(false);
                  setTimeout(() => {
                    props.onToggle(type.style);
                  }, 50);
                  ev.stopPropagation();
                }}
                key={type.label}
              >
                <ColorStyleButton
                  key={i}
                  active={currentStyle.has(type.style)}
                  color={type.color}
                  label={type.label}
                  icon={type.icon({
                    active: currentStyle.has(type.style),
                  })}
                  onToggle={() => {}}
                  style={type.style}
                />
                {/* {type.icon({ active: currentStyle.has(type.style) })} */}
              </div>
            ))}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};
