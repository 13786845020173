import React, { useEffect, useState } from "react";
import { cx } from "@emotion/css";
import Mark from "mark.js";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { NavPickerPopoverStyled } from "../../../components/DatePicker/styles";
import { Button, Divider } from "@mui/material";
import TooltipTD from "../../../components/Tooltip/TooltipTD";
import { SalesOrderIcon } from "../../../helpers/iconSvgPath";
import { sortObjectsBy, stringLowerCase } from "../../../helpers/helpers";
import SearchBox from "../../../components/SearchBox/SearchBox";
import { DueLateDiamond } from "../TaskScreenHelper";
import SalesOrderBadge from "../../../components/Badge/SalesOrderBadge";

function SalesOrdersAutomplete({
  currentSalesOrder = {},
  salesOrders = [],
  onSelectItem = () => {},
}) {
  const [anchorEl, setAnchorEl] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    performMark(search);
  }, [search]);

  const onClose = () => {
    setAnchorEl(false);
    setSearch("");
  };

  const salesOrderFilter = () => {
    return salesOrders
      .sort(sortObjectsBy("creationDate", true))
      .filter((so) => {
        const parseSearch = search.toLowerCase();
        return (
          stringLowerCase(so.number).includes(parseSearch) ||
          stringLowerCase(so.customerName).includes(parseSearch) ||
          stringLowerCase(so.referenceNumber).includes(parseSearch) ||
          stringLowerCase(so.customerPO).includes(parseSearch)
        );
      });
  };

  const markInstance = new Mark(document.getElementById("orderList"));

  function performMark(keyword = "") {
    let options = {
      separateWordSearch: false,
      diacritics: false,
      debug: false,
      acrossElements: false,
      accuracy: "partially",
    };
    markInstance.unmark({
      done: () => {
        markInstance.mark(keyword, options);
      },
    });
  }

  return (
    <>
      <Button
        className="SOButtonSelect"
        onClick={async (ev) => {
          setAnchorEl(ev.currentTarget);
          ev.persist();
        }}
      >
        <SalesOrderIcon width={15} height={15} />
        {currentSalesOrder.id
          ? "View another Sales Order"
          : "View a Sales Order"}
        <ExpandMoreIcon
          className={cx("arrowDropDownIcon", { arrowActive: !!anchorEl })}
        />
      </Button>
      {!!anchorEl && (
        <NavPickerPopoverStyled
          open={!!anchorEl}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          classes={{ paper: cx("paper", "paperRound") }}
          className="navPickerPopoverContainer"
          onClose={onClose}
        >
          <div className="textFieldContainer">
            <SearchBox
              placeholder="Search for Sales Order, Customer, Ref # or Customer PO #"
              onDebounceValue={(filter, value) => {
                setSearch(value);
              }}
              value={search}
              hasBeenReseted={() => {
                setSearch("");
              }}
              style={{ width: "100%", minWidth: 450 }}
              autoFocus
            />
          </div>
          <div className="scrollList" id="orderList">
            {salesOrderFilter().map((item) => {
              const selectedStyle =
                item.id === currentSalesOrder.id ? "selectedStyle" : "";
              return (
                <div
                  key={item.id + "navTitle"}
                  className={cx(
                    "listContainer",
                    selectedStyle,
                    "salesOrderAutocomplete"
                  )}
                  onClick={() => {
                    if (currentSalesOrder !== item.id) {
                      onSelectItem(item);
                    }
                    onClose();
                  }}
                >
                  <DueLateDiamond item={item} />

                  <span className="itemListNumber">
                    <SalesOrderBadge number={item.number} />
                  </span>
                  <Divider className="divider" />
                  <TooltipTD label={item.customerName} className="toolTipText">
                    <span>{item.customerName}</span>
                  </TooltipTD>
                  <Divider className="divider" />
                  <TooltipTD
                    label={item.referenceNumber}
                    className="toolTipText"
                  >
                    <span> {item.referenceNumber}</span>
                  </TooltipTD>
                  <Divider className="divider" />

                  <TooltipTD label={item.customerPO} className="toolTipText">
                    <span>{item.customerPO || ""}</span>
                  </TooltipTD>
                </div>
              );
            })}
          </div>
        </NavPickerPopoverStyled>
      )}
    </>
  );
}

export default SalesOrdersAutomplete;
