import React from "react";
import { globalScopes } from "../../../api/types/dbTables";
import moment from "moment";

export const getStringDate = (date = "") => {
  const parseDate = moment(date);
  return parseDate.isValid() ? parseDate.format("M/D/YY") : "";
};

export const mismatchLabel = {
  SO_MISMATCH: {
    key: "SO_MISMATCH",
    content: (
      <span style={{ lineHeight: 1.5 }}>
        Red numbers in the Order Quantity column indicate
        <br />
        that the Sales Order quantity does not match the PO quantity
      </span>
    ),
  },
  SO_WHOLE_NUMBER: {
    key: "SO_WHOLE_NUMBER",
    content: (
      <span style={{ lineHeight: 1.5 }}>
        Red numbers in the Cartons column indicate
        <br />
        that it is not a whole number of cartons
      </span>
    ),
  },
  SO_MISMATCH_AND_NUMBER: {
    key: "SO_MISMATCH_AND_NUMBER",
    content: (
      <span style={{ lineHeight: 1.5 }}>
        Red numbers in the Order Quantity column indicate that the Sales Order
        quantity does not match the PO quantity
        <br />
        and
        <br />
        Red numbers in the Cartons column indicate that it is not a whole number
        of cartons.
      </span>
    ),
    tooltip: (
      <span>
        Red numbers in the Order Quantity column indicate that the Sales Order
        quantity does not match the PO quantity and <br />
        Red numbers in the Cartons column indicate that it is not a whole number
        of cartons.
      </span>
    ),
  },

  [globalScopes.PURCHASE_ORDER]: {
    key: globalScopes.PURCHASE_ORDER,
    content: (
      <span>
        The quantity of one or more items is not 100% <br /> allocated to
        shipments.
      </span>
    ),
  },
  [globalScopes.SHIPMENT]: {
    key: globalScopes.SHIPMENT,
    content: (
      <span>
        There are one or more items in this shipment <br /> that are not a whole
        number of cartons.
      </span>
    ),
  },
};

export const ORDER_DASHBOARD_ACTUAL_VIEWS = {
  SALES_ORDER: "sales-order-info-to-scroll",
  PURCHASE_ORDER: "purchase-order-info-to-scroll",
  SHIPMENT: "shipment-info-to-scroll",
  THREE_PANEL_SECTION: "three-panel-section-to-scroll",
};
