import React from "react";

import Shipment from "../../../assets/toolbarIcon/Shipment.svg";
import AddImage from "../../../assets/toolbarIcon/AddImage.svg";
import AT from "../../../assets/toolbarIcon/AT.svg";
import { EditorState, Modifier } from "draft-js";
import Dropzone from "react-dropzone";
import { DocumentToolBar } from "../../../helpers/iconSvgPath";

export const MentionControl = ({
  editorState,
  onChange,
  editorRef,
  classes,
  uploadImage,
  EmojiSelect,
  isDocumentOpen,
  handleDocumentComponent,
  hasFlagPermission,
}) => {
  function addCharacter(character) {
    const currentContent = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();
    const newContent = Modifier.insertText(
      currentContent,
      currentSelection,
      character
    );
    const newEditorState = EditorState.push(
      editorState,
      newContent,
      "insert-characters"
    );
    onChange(newEditorState);
    setTimeout(() => {
      const newCursorPosition = currentSelection.getStartOffset() + 1;
      const newSelection = currentSelection.merge({
        anchorOffset: newCursorPosition,
        focusOffset: newCursorPosition,
      });
      const finalEditorState = EditorState.forceSelection(
        newEditorState,
        newSelection
      );
      onChange(finalEditorState);
    }, 50);
  }
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  return (
    <div className="inlineGroup">
      <div
        className={classes}
        style={{ margin: 0 }}
        onClick={(ev) => {
          if (
            blockType === "ordered-list-item" ||
            blockType === "unordered-list-item"
          ) {
            addCharacter("@");
          } else {
            addCharacter(" @");
          }
          const element = document.getElementById("DraftEditor-root");
          setTimeout(() => {
            editorRef.current.focus();
            element.click()
          }, 50);
          ev.stopPropagation();
        }}
      >
        <img src={AT} alt={"@"} />
      </div>
      <div
        style={{ margin: 0 }}
        className={classes}
        onClick={(ev) => {
          if (
            blockType === "ordered-list-item" ||
            blockType === "unordered-list-item"
          ) {
            addCharacter("/");
          } else {
            addCharacter(" /");
          }
          const element = document.getElementById("DraftEditor-root");
          setTimeout(() => {
            editorRef.current.focus();
            element.click()
          }, 50);
          ev.stopPropagation();
        }}
      >
        <img src={Shipment} alt={"/"} />
      </div>
      <Dropzone
        onDrop={(accept) => {
          const [fileAccept] = accept;
          if (fileAccept) {
            uploadImage(fileAccept);
          }
        }}
        accept={{
          "image/png": [".png"],
          "image/jpeg": [".jpeg"],
        }}
        maxFiles={1}
        multiple={false}
        noKeyboard
        noDrag
      >
        {({ getRootProps, getInputProps }) => (
          <div
            style={{ margin: 0 }}
            {...getRootProps({
              className: classes,
            })}
          >
            <img src={AddImage} alt={"img"} />
            <input {...getInputProps()} />
          </div>
        )}
      </Dropzone>
      <div className={classes} style={{ margin: 0 }}>
        <EmojiSelect />
      </div>
      {hasFlagPermission && (
        <div
          style={{ margin: 0 }}
          className={classes}
          onClick={handleDocumentComponent}
        >
          <DocumentToolBar active={isDocumentOpen} />
        </div>
      )}
    </div>
  );
};
