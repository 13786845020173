import { now } from "moment";
import { TYPE_OF_FILE } from "../../helpers/constants";
import { parseNumberValue } from "../../helpers/orderDashboardRefactored";
import { getRandomId } from "../../helpers/helpers";

export default class FileDocument {
  constructor({
    id = getRandomId(),
    name = "New file",
    type = TYPE_OF_FILE.FILE,
    parentId = "",
    currentPath = "",
    oldPath = "",
    url = "",
    version = 1,
    mainFileId = "",
    listIndex = "",
    placeholder = false,
    lastModifiedDate = now(),
    size = 0,
    editedBy = "",
    created = now(),
    createdBy = "",
    storageRef = "",
    parentDocumentId = "",
    scope = "",
    originalName = name,
    versions = [],
    user = "",
    triggerType = "",
  }) {
    this.id = id;
    this.name = name;
    this.originalName = originalName;
    this.type = type;
    this.parentId = parentId;
    this.currentPath = currentPath;
    this.oldPath = oldPath;
    this.url = url;
    this.version = version;
    this.mainFileId = mainFileId;
    this.listIndex = listIndex;
    this.placeholder = placeholder;
    this.lastModifiedDate = lastModifiedDate;
    this.size = size !== "" ? parseNumberValue(size).toFixed(0) : 0;
    this.editedBy = editedBy;
    this.created = created;
    this.createdBy = createdBy;
    this.storageRef = storageRef;
    this.parentDocumentId = parentDocumentId;
    this.scope = scope;
    this.version = version;
    this.versions = versions;
    this.user = user;
    this.triggerType = triggerType;
  }
}
