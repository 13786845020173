import React, { useContext } from "react";
import { ActivityStreamFiltersStyled } from "./styles";
import { PO_STATUS } from "../../../helpers/constants";
import ExpandPanelButton from "../../Buttons/ExpandPanelButton";
import { emojisIcon } from "../../../helpers/emojisHelper";
import FiltersTabNote from "../FiltersTabNote";
import TooltipTD from "../../Tooltip/TooltipTD";
import { ReplyIcon, VoidLabel } from "../../../helpers/iconSvgPath";
import SearchLabel from "../../Inputs/SearchLabel";
import MultiUserPicker from "../../DatePicker/MultiUserPicker";
import { SEARCH_BOX_TYPES } from "../../../helpers/orderDashboard";
import {
  ACTIVITY_STREAM_ACTION,
  ActivityStreamContext,
} from "./ActivityStreamContext";
import { handleReplyActivity } from "./ActivityHelpers";
import { ACTIVITY_LOAD_FROM } from "../../../actions/types";
import { useDispatch } from "react-redux";

function ActivityStreamFiltersv2({
  salesOrder = {},
  purchaseOrder = {},
  currentShipment = {},
  companyUsers = [],
  panelWidth,
  onActivePanel = () => {},
  activities = [],
  resetLoading = () => {},
}) {
  const { activityStreamState, dispatchActivityStream } = useContext(
    ActivityStreamContext
  );
  const dispatch = useDispatch();
  const { noteThread, filterByUsers, cleanSearchBox } = activityStreamState;

  return (
    <ActivityStreamFiltersStyled className="activityStream-header-container">
      <div className="activity-stream-filters-container">
        <div style={{ display: "flex", alignItems: "center" }}>
          {salesOrder.status === PO_STATUS.VOIDED && <VoidLabel />}
          <div
            className="title"
            style={{ alignItems: !noteThread ? "" : "center" }}
          >
            <ExpandPanelButton
              activePanel={panelWidth === "50%"}
              handleActivePanel={onActivePanel}
            />

            {noteThread && emojisIcon[noteThread.iconIndex] && (
              <img
                width={25}
                style={{ marginRight: 6 }}
                src={emojisIcon[noteThread.iconIndex].src}
                alt={emojisIcon[noteThread.iconIndex].alt}
              />
            )}
            {!noteThread ? (
              <span>Activity Stream</span>
            ) : (
              <span>
                Reply Thread (
                {
                  activities.filter(
                    (activity) =>
                      activity.threadId === noteThread.id ||
                      activity.id === noteThread.id
                  ).length
                }
                )
              </span>
            )}
          </div>
        </div>
        <FiltersTabNote
          salesOrder={salesOrder}
          purchaseOrder={purchaseOrder}
          currentShipment={currentShipment}
          isSmallPanel={panelWidth === "25%"}
          resetLoading={resetLoading}
        />
      </div>

      <div className="activityTool">
        {noteThread && (
          <div className="replyToolContainer">
            <TooltipTD
              className="replyContainer"
              label="Add a note to this thread"
              tooltipClick={() => {
                handleReplyActivity({
                  activity: noteThread,
                  currentShipment,
                  dispatchActivityStream,
                  purchaseOrder,
                  salesOrder,
                });
              }}
            >
              <ReplyIcon height={20} />
            </TooltipTD>
            <div className="divider" />
          </div>
        )}
        <SearchLabel
          cookie={purchaseOrder.id}
          returnQuery={(query) => {
            const startDate =
              salesOrder.quoteCreationDate || salesOrder.creationDate;
            dispatchActivityStream({
              type: ACTIVITY_STREAM_ACTION.BRING_ACTIVITIES_FROM_THE_BEGINING,
              payload: {
                querySearch: query,
              },
              startDate,
            });
            dispatch({
              type: ACTIVITY_LOAD_FROM,
              payload: {
                creationDate: startDate,
              },
            });
          }}
          placeholderOnExpand={
            !noteThread
              ? "Search for anything in the Activity Stream"
              : "Search for anything in this thread"
          }
          styles={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
          searchLabelId="project-notes-search-box"
          type={SEARCH_BOX_TYPES.NOTES}
          cleanSearchBox={cleanSearchBox}
          onDisableCleanSearchBox={() => {
            dispatchActivityStream({
              type: ACTIVITY_STREAM_ACTION.COMMON,
              payload: {
                cleanSearchBox: false,
              },
            });
          }}
        />
        <MultiUserPicker
          list={companyUsers.filter((user) => user.active)}
          selectedOptions={(value) => {
            const startDate =
              salesOrder.quoteCreationDate || salesOrder.creationDate;
            dispatchActivityStream({
              type: ACTIVITY_STREAM_ACTION.BRING_ACTIVITIES_FROM_THE_BEGINING,
              payload: {
                filterByUsers: value.map((item) => item.id),
              },
              startDate,
            });
            dispatch({
              type: ACTIVITY_LOAD_FROM,
              payload: {
                creationDate: startDate,
              },
            });
          }}
          filterByUsers={filterByUsers}
        />
      </div>
    </ActivityStreamFiltersStyled>
  );
}

export default ActivityStreamFiltersv2;
