import React, { useState } from "react";
import AutocompleteUsers from "../TextFields/AutocompleteUsers";
import { sortObjectsBy } from "../../helpers/helpers";
import { FeatureFlagItemStyled } from "./styles";
import { dbTables } from "../../api/types/dbTables";
import FeatureFlag from "../../api/model/featureFlag";
import { firestore } from "../../firebase";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { colors } from "../../assets/jss/variables";
import { CircularProgress, FormControlLabel, Switch } from "@mui/material";
import AutoCompleteUser from "../../api/model/AutoCompleteUser.model";

function FeatureFlagItem({
  name,
  companyUsers = [],
  companyFeatureFlags = [],
  companyID,
  creationUserOption,
  onChangeValuePermission = () => {},
  loading,
  setLoading,
}) {
  const [isLoading, setIsLoading] = useState(false);
  function onChange(userArray, lastUserArray) {
    const companyFeatureFlag = companyFeatureFlags.find(
      (feature) => feature.name === name
    );
    if (!userArray) {
      if (!companyFeatureFlag) {
        const featureFlag = { ...new FeatureFlag({ name }) };
        setDoc(
          doc(
            firestore,
            `${dbTables.COMPANIES}/${companyID}/${dbTables.FEATURE_FLAGS}/${featureFlag.id}`
          ),
          { ...featureFlag, users: [] }
        );
      } else {
        updateDoc(companyFeatureFlag.ref, {
          users: {},
        });
      }
      return;
    }
    if (userArray === "all") {
      const allUsers = {};
      companyUsers.forEach((user) => (allUsers[user.id] = true));
      if (!companyFeatureFlag) {
        const featureFlag = { ...new FeatureFlag({ name }) };
        setDoc(
          doc(
            firestore,
            `${dbTables.COMPANIES}/${companyID}/${dbTables.FEATURE_FLAGS}/${featureFlag.id}`
          ),
          { ...featureFlag, users: allUsers }
        );
      } else {
        updateDoc(companyFeatureFlag.ref, {
          users: allUsers,
        });
      }
      return;
    }
    if (userArray.length > lastUserArray.length) {
      let addedUser;
      userArray.forEach((user) => {
        if (!lastUserArray.find((lastUser) => lastUser.id === user.id)) {
          addedUser = user;
        }
      });
      if (!companyFeatureFlag) {
        const featureFlag = { ...new FeatureFlag({ name }) };
        setDoc(
          doc(
            firestore,
            `${dbTables.COMPANIES}/${companyID}/${dbTables.FEATURE_FLAGS}/${featureFlag.id}`
          ),
          {
            ...featureFlag,
            users: { [addedUser.id]: true },
          }
        );
      } else {
        const usersCpy = { ...companyFeatureFlag.users };
        usersCpy[addedUser.id] = true;
        updateDoc(companyFeatureFlag.ref, {
          users: usersCpy,
        });
      }
      return;
    } else {
      let removedUser;
      lastUserArray.forEach((lastUser) => {
        if (!userArray.find((user) => user.id === lastUser.id)) {
          removedUser = lastUser;
        }
      });
      if (!companyFeatureFlag) {
      } else {
        const usersCpy = { ...companyFeatureFlag.users };
        delete usersCpy[removedUser.id];
        updateDoc(companyFeatureFlag.ref, {
          users: usersCpy,
        });
      }
    }
  }

  function getAllFeatureFlagUsers(name, companyFeatureFlags, companyUsers) {
    const currentFeature = companyFeatureFlags.find(
      (feature) => feature.name === name
    );

    if (!currentFeature || !currentFeature.users) {
      return [];
    }
    const usersSelected = [];
    Object.keys(currentFeature.users).forEach((key) => {
      const realUser = companyUsers.find((item) => item.id === key);
      if (realUser) {
        usersSelected.push(realUser);
      }
    });
    return usersSelected.sort(sortObjectsBy("displayName", false));
  }

  return (
    <FeatureFlagItemStyled className="permissionGroup">
      <div className="type-label">{name}</div>

      <div className="permission-group-content">
        <div
          style={{
            position: "relative",
            marginLeft: 10,
            width: 105,
          }}
        >
          <FormControlLabel
            style={{ margin: 0, cursor: "pointer" }}
            onClick={async () => {
              if (isLoading || loading) {
                return;
              }
              setLoading(true);
              setIsLoading(true);
              await onChangeValuePermission();
              setIsLoading(false);
              setLoading(false);
            }}
            control={
              <Switch
                checked={creationUserOption}
                classes={{
                  root: creationUserOption
                    ? "switchOnStyles"
                    : "switchOffStyles",
                }}
                disabled={isLoading}
                disableRipple
                disableFocusRipple
                disableTouchRipple
                onChange={() => {}}
                size="small"
              />
            }
            label={
              <span
                style={{
                  fontWeight: 700,
                  fontSize: 12,
                  color: creationUserOption
                    ? colors.diamondGreen
                    : colors.diamondRed,
                }}
              >
                {creationUserOption ? "Auto Add All Users" : "Off"}
              </span>
            }
          />
          {isLoading && (
            <CircularProgress
              size={30}
              onClick={(ev) => ev.stopPropagation()}
              style={{
                position: "absolute",
                left: 20,
              }}
            />
          )}
        </div>
        <div className="add-remove-all-options">
          <div onClick={() => onChange("all")} className="option-button">
            Add all
          </div>
          <div className="option-button" onClick={() => onChange(null)}>
            Remove all
          </div>
        </div>
        <div className="autocomplete-users-contenainer-options">
          <AutocompleteUsers
            options={companyUsers
              .sort(sortObjectsBy("displayName", false))
              .map((user) => ({
                ...new AutoCompleteUser({
                  value: user.id,
                  label: user.displayName,
                  avatar: user.avatar,
                  firstName: user.firstName,
                  lastName: user.lastName,
                }),
              }))}
            onChange={onChange}
            value={getAllFeatureFlagUsers(
              name,
              companyFeatureFlags,
              companyUsers
            ).map((user) => ({
              ...new AutoCompleteUser({
                value: user.id,
                label: user.displayName,
                avatar: user.avatar,
                firstName: user.firstName,
                lastName: user.lastName,
              }),
            }))}
            focused={true}
            classes={{
              input: "input",
              inputFocused: "input-focused",
              paper: "paper",
              chip: "chip",
              deleteIcon: "delete",
            }}
          />
        </div>
      </div>
    </FeatureFlagItemStyled>
  );
}

export default FeatureFlagItem;
