import { colors } from "../../assets/jss/variables";
import { Table, styled } from "@mui/material";

export const MainTableStyled = styled(Table)(() => ({
  "&.mainTable": {
    tableLayout: "fixed",
    borderCollapse: "collapse !Important",
    maxHeight: "95%",
    height: "auto",
    "& .MuiTableHead-root": {
      "&:hover": {
        "& .resize-icon": {
          cursor: "col-resize",
          "&:after": {
            background: colors.primaryDark,
            top: "50%",
            transform: "translateY(-50%)",
            width: 2,
            height: "75%",
          },
        },
      },
    },
    "& .sortedHeaderCell": {
      "& svg": {
        color: "#39f",
      },
    },
    "& .headerCell": {
      color: colors.primaryDark,
      background: colors.tableHeaderColor,
      zIndex: 100,
      minHeight: 45,
      top: 0,
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "16.41px",
      height: 51,
      "& svg": {
        opacity: 0,
      },
      "&:hover": {
        backgroundColor: colors.primary,
        "& svg": {
          opacity: 1,
        },
      },

      "&:active": {
        "& .resize-icon": {
          cursor: "col-resize",
          "&:after": {
            background: colors.primaryDark,
            top: "50%",
            transform: "translateY(-50%)",
            width: 2,
            height: "100%",
          },
          "&:before": {
            background: colors.primaryDark,
            top: "50%",
            transform: "translateY(-50%)",
            width: 2,
            height: "100%",
          },
        },
      },
      "& .resize-icon": {
        position: "absolute",
        right: 0,
        width: 5,
        height: "80%",
        "&:after": {
          content: '""',
          position: "absolute",
          right: 0,
          width: 2,
          height: "75%",
          background: colors.primaryLight,
          top: "50%",
          transform: "translateY(-50%)",
        },
        "&:before": {
          content: '""',
          position: "absolute",
          left: 0,
          width: 0,
          height: "75%",
          background: colors.primaryLight,
          top: "50%",
          transform: "translateY(-50%)",
        },
      },
    },
    "& .headerLabel": {
      display: "inline-block",
      verticalAlign: "middle",
    },
    "& .headerArrow": {
      verticalAlign: "middle",
      top: "40%",
      marginLeft: 8,
    },
    "& .columnSettings": {
      padding: "0px 4px !Important",
      height: 45,
      width: 35,
      backgroundColor: colors.skyBlue,
      color: colors.primaryDark,
    },
  },
}));
