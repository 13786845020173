/**
 * App Light Theme
 */
import { createTheme } from "@mui/material";
import AppConfig from "../../constants/AppConfig";

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: "#3C4043",
          position: "relative",
          fontSize: 14,
          fontWeight: 400,
          lineHeight: 1.4,
          maxWidth: 450,
        },
        arrow: {
          color: "#3C4043",
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 600,
      sm: 1025,
      md: 1225,
      lg: 1425,
      xl: 1625,
    },
  },
  palette: {
    primary: {
      main: AppConfig.themeColors.primary,
    },
    secondary: {
      main: AppConfig.themeColors.warning,
    },
  },
  typography: {
    useNextVariants: true,
    // Use the system font instead of the default Roboto font.
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
    htmlFontSize: 14,
    fontSize: 14,
    lineHeight: "14px",
    h2: {
      fontSize: 21,
      fontWeight: 400,
    },
    body1: {
      fontWeight: 400,
      fontSize: 14,
    },
    label: {
      margin: 0,
    },
  },
});

export default theme;
