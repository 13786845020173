import React, { useState } from "react";
import { useCompanyId } from "../../../hooks";
import { dbTables } from "../../../api/types/dbTables";
import { TYPE_OF_FILE } from "../../../helpers/constants";
import ModalCreateFolderDocument from "../../Documents/ModalCreateFolderDocument";
import { cx } from "@emotion/css";
import { ListItemDocumentStyled } from "../../Documentsv2/styles";
import DocumentNoteInfo from "./DocumentNoteInfo";

function DocumentNoteItem({
  document,
  documents,
  onOpenFolder = () => {},
  dbCollection = "",
  parentCollection = "",
  parentDocumentId = "",
  isVoided = false,
  onUpdatePermissionState = () => {},
  handleRichDocument,
}) {
  const companyId = useCompanyId();
  const [openAccessGroup, setOpenAccesGroup] = useState(false);

  const documentPath = `${dbTables.COMPANIES}/${companyId}/${parentCollection}/${parentDocumentId}/${dbCollection}`;
  const onDragEnd = () => setTimeout(() => handleRichDocument({}), 100);

  return (
    <>
      {openAccessGroup && (
        <ModalCreateFolderDocument
          open={openAccessGroup}
          title="Access Group"
          orderPath={documentPath}
          isNew={false}
          documentTree={documents.filter(
            (doc) => doc.type === TYPE_OF_FILE.FOLDER
          )}
          handleClose={() => {
            setOpenAccesGroup(false);
          }}
          folderData={document}
          parentDocument={documents.find((doc) => doc.id === document.parentId)}
          onUpdatePermissionState={onUpdatePermissionState}
        />
      )}
      <ListItemDocumentStyled
        draggable
        stlye={{ paddingInline: 5 }}
        className={cx("listItemDocumentContainer", "noteButtonEffect")}
        onDragStart={() => handleRichDocument(document)}
        onDragEnd={onDragEnd}
        onClick={(ev) => {
          onOpenFolder(document.id);
          ev.stopPropagation();
        }}
      >
        <div
          className={cx(
            "listemItemDocument",
            document.type === TYPE_OF_FILE.FOLDER
              ? "folderDocument_" + document.scope
              : document.scope
          )}
          style={{ width: "calc(100% - 12px)" }}
          onClick={(ev) => {
            onOpenFolder(document.id);
            ev.stopPropagation();
          }}
        >
          <DocumentNoteInfo
            currentDocument={document}
            isVoided={isVoided}
            onDragEnd={onDragEnd}
            onRichDocument={handleRichDocument}
            onEditPermission={(ev) => {
              setOpenAccesGroup(true);
              ev.stopPropagation();
            }}
            key={document.id + "noteInfo"}
          />
        </div>
      </ListItemDocumentStyled>
    </>
  );
}

export default DocumentNoteItem;
