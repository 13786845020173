import React, { useEffect, useRef, useState } from "react";
import { Edit as EditIcon } from "@mui/icons-material";

import { InputBase, styled } from "@mui/material";
import { colors } from "../../../assets/jss/variables";
import { regexDecimal, regexInteger } from "../../../helpers/constants";
import { EditableSKULabel } from "./styles";
import { cx } from "@emotion/css";

const type = {
  CASH: "cash",
  NUMBER: "number",
};

const InputField = styled(InputBase)(() => ({
  fontSize: 14,
  border: "1px solid #ccc",
  color: colors.primaryDark,
  height: 25,
  padding: "0px 0px 0px 3px",
  marginRight: 14,
  fontWeight: 700,
  background: "transparent",
  "& .inputField": {
    textAlign: "right",
    paddingRight: 5,
  },
}));

const SKUEditableLabelV2 = ({
  value,
  disabled,
  inputType = type.NUMBER,
  hiddenValue,
  tooltipLabel,
  showToolTip,
  onChangeLabel,
  preventDelete,
  labelFormat = (value) => value,
  previewColor,
  className = "",
}) => {
  const [temporalLabel, setTemporalLabel] = useState(value);
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (isEditing && inputRef && inputRef.current) {
      inputRef.current.select();
    }
  }, [isEditing]);

  useEffect(() => {
    if (temporalLabel !== value) {
      setTemporalLabel(value);
    }
  }, [value]);

  const handleValidData = async () => {
    let newValue = parseFloat(temporalLabel);
    if (isNaN(newValue)) {
      setIsEditing(false);
      setTemporalLabel(value);
      return;
    }
    if (parseFloat(newValue) === parseFloat(value) && !previewColor) {
      setTemporalLabel(value);
    } else if (newValue === 0 && preventDelete) {
      await onChangeLabel("1");
    } else {
      await onChangeLabel(temporalLabel);
      if (newValue === 0) {
        setTemporalLabel(value);
      }
    }
    setIsEditing(false);
  };

  return (
    <EditableSKULabel
      className={cx("editableLabelSKU", className)}
      tooltipClick={(ev) => {
        if (isEditing || disabled) {
          return;
        }
        setIsEditing(true);
        ev.stopPropagation();
      }}
      label={tooltipLabel}
      showToolTip={showToolTip && !isEditing}
    >
      {!isEditing && (
        <div className="labelText">
          <span
            className={cx("valueText", {
              disabledText: disabled,
              previewColor: previewColor,
            })}
          >
            {`${hiddenValue ? "" : labelFormat(temporalLabel)}`}
          </span>
          <div style={{ width: 14, height: 16 }}>
            {!disabled && (
              <EditIcon
                className={"edit-icon"}
                style={{
                  fontSize: 14,
                  color: `${colors.primaryDark}`,
                }}
              />
            )}
          </div>
        </div>
      )}
      {isEditing && (
        <InputField
          autoFocus
          value={temporalLabel}
          inputRef={inputRef}
          inputProps={{
            pattern: "[0-9]*",
          }}
          classes={{ input: "inputField" }}
          onBlur={handleValidData}
          onChange={(e) => {
            const currentValue = e.target.value;
            const numStr = currentValue.replace(/[^0-9.,]/g, "");
            if (
              inputType === type.NUMBER &&
              (numStr === "" || regexInteger.test(numStr))
            ) {
              setTemporalLabel(numStr);
            } else if (
              inputType === type.CASH &&
              (numStr === "" || regexDecimal.test(numStr))
            ) {
              setTemporalLabel(numStr);
            }
          }}
          onKeyDown={(ev) => {
            if (ev.key === "Escape") {
              setIsEditing(false);
              setTemporalLabel(value);
            }
            if (ev.key === "Enter") {
              handleValidData();
            }
          }}
        />
      )}
    </EditableSKULabel>
  );
};

export default SKUEditableLabelV2;
