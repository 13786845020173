import { colors } from "../../assets/jss/variables";
import { styled, Drawer } from "@mui/material";

export const PrincipalDrawerStyled = styled(Drawer)(() => ({
  "&.userDrawerRoot": {
    position: "absolute",
    zIndex: 2,
  },

  "& .notification-scroll-container": {
    height: "100%",
    marginRight: 5,
    "& .ReactVirtualized__Grid": {
      "&::-webkit-scrollbar": {
        backgroundColor: "transparent",
        width: 16,
      },
      "&::-webkit-scrollbar-thumb": {
        background: "transparent",
        borderRadius: 7,
      },
      "&::-webkit-scrollbar-track": {
        border: " solid 1px transparent",
      },
      "&:hover": {
        "&::-webkit-scrollbar-thumb": {
          boxShadow: `inset 0 0 10px 10px ${colors.thumbScrollColor}`,
          borderBlock: "1px solid transparent",
          borderInline: "5px solid transparent",
        },
      },
    },
  },
  "& .MuiDrawer-paper": {
    width: 492,
    boxShadow: "-4px 0px 4px 0px rgba(0, 0, 0, 0.25)",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  "& .header": {
    padding: "7px 27px 0px 24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 19,
    height: 57,
    "& .buttonLogout": {
      border: "1.5px solid #4F4F4F;",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: "8px 10px",
      background: "white",
      color: colors.borderGray,
      height: 36,
      width: 88,
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 700,
      justifyContent: "space-between",
      marginTop: 7,
    },
  },
  "& .groupButton": {
    height: 40,
    borderRadius: " 5px 5px 0px 0px",
    right: 23,
    display: "flex",
    justifyContent: "flex-end",
    gap: 17,
    zIndex: 6,
    top: 3,
    position: "relative",
    "& .MuiButtonBase-root": {
      fontSize: 12,
      color: colors.borderGray,
      padding: "10px 18px",
      background: "#D7E3F0",
      borderColor: "transparent",
      zIndex: 4,
      transform: "scale(1)",
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      border: 'unset'
    },
    "& .selected": {
      background: "white",
      boxShadow: "0 -6px 6px -3px rgb(0 0 0 / 25%),-2px 0 3px -3px rgb(0 0 0 / 25%),2px 0 3px -3px rgb(0 0 0 / 25%)",
      zIndex: 6,
    },
  },

  "& .content": {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    position: "relative",
  },
  "& .paperContent": {
    width: "100%",
    marginLeft: 12,
    boxShadow: "-4px -4px 5px 0px rgb(0 0 0 / 15%)",
    paddingTop: 10,
    overflow: "auto",
    zIndex: 5,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    paddingBottom: 15,
    paddingRight: 10,
  },
}));
