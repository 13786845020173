import React from "react";
import { Fade } from "@mui/material";
import { AttentionModalStyled } from "./style";

function AttentionModal({
  isOpen,
  onClose = () => {},
  title,
  description,
  onClick,
  cancellable,
  confirmationText = "Ok",
  cancelText = "No",
  acceptBlue = true,
  actionModal = true,
  styleModal = {},
  classNameModal,
  bodyClassname = "attentionModalBody",
  bodyStyles = {},
  blockKeydown,
}) {
  return (
    <AttentionModalStyled
      id="attentionModal"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classNameModal || "rootModal"}
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      onKeyDown={(ev) => {
        if (blockKeydown) {
          return;
        }
        if (ev.key === "Enter") {
          if (acceptBlue) {
            onClick(ev);
            ev.preventDefault();
            ev.stopPropagation();
          } else {
            onClose();
            ev.preventDefault();
            ev.stopPropagation();
          }
        }
        if (ev.key === "Escape") {
          onClose();
          ev.preventDefault();
          ev.stopPropagation();
        }
      }}
      onClick={(ev) => ev.stopPropagation()}
    >
      <Fade in={isOpen}>
        <div className={"attentionModalPaper"} style={styleModal}>
          <div className={"attentionModalHeader"}>{title}</div>
          <div className={bodyClassname} style={bodyStyles}>
            {description}
          </div>
          {actionModal && (
            <div className={"attentionModalFooter"}>
              <button
                className={
                  !cancellable
                    ? "buttonExpanded"
                    : acceptBlue
                    ? "buttonConfirmationBlue"
                    : "buttonConfirmationWhite"
                }
                onClick={onClick}
              >
                {confirmationText}
              </button>
              {cancellable && (
                <button
                  className={
                    acceptBlue ? "buttonCancelWhite" : "buttonCancelBlue"
                  }
                  onClick={onClose}
                >
                  {cancelText}
                </button>
              )}
            </div>
          )}
        </div>
      </Fade>
    </AttentionModalStyled>
  );
}

export default AttentionModal;
