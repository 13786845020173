import { colors } from "../../assets/jss/variables";
import { TableCell, TableRow, styled } from "@mui/material";

export const CellSKUHeader = styled(TableCell)(() => ({
  "&.headerRoot": {
    paddingBlock: "0px !important",
    fontSize: "0.85em",
    fontWeight: 500,
    lineHeight: "1.5rem",
    backgroundColor: colors.skyBlue,
    color: colors.primaryDark,
    border: 0,
  },
  "&.sorting": {
    "& .headerArrowButton": {
      position: "absolute",
      opacity: 0,
      top: 20,
    },
    "&:hover": {
      "& .headerArrowButton": { opacity: "1 !important" },
    },
  },
  "& .typeImage": {
    cursor: "pointer",
  },
}));

export const TableRowStyled = styled(TableRow)(() => ({
  "&.body": {
    "& .emptyCellStyle": {
      fontSize: 14,
      lineHeight: "16px",
      letterSpacing: "0.295em",
      textTransform: "uppercase",
      height: 40,
      backgroundColor: "white",
      color: colors.primaryDark,
      borderColor: colors.dividerColor,
    },

    "&:hover": {
      "& .styleCellShipment": {
        backgroundColor: "#fefce7 !important",
      },
    },
  },
}));

export const TableCellsStyled = styled("div")(() => ({
  root: {
    display: "block",
    flex: 1,
  },
  table: {
    height: "100%",
    width: "100%",
  },

  thead: {},
  tbody: {
    width: "100%",
  },

  headerRow: {},

  column: {},
}));
export const PDFPortalStyled = styled("div")(() => ({
  "&.portalPDF": {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    padding: "1%",
    display: "flex",
    background: "rgba(0, 0, 0, 0.3)",
    justifyContent: "center",
    "& .pg-viewer-wrapper": {
      width: "100%",
      "& .react-grid-Container": {
        width: "100%",
      },
    },
    "& .document-container": {
      width: "98%",
      border: `2px ${colors.primaryDark} solid`,
    },
    "& iframe": {
      width: "100%",
      height: "100%",
      zIndex: 2,
    },
    "& .cancel-icon-pdf": {
      width: 24,
      height: 24,
      padding: 4,
      border: "2px solid",
      borderRadius: "50%",
      position: "absolute",
      right: 22,
      cursor: "pointer",
      color: "white",
      background: "white",
      zIndex: 10,
      marginTop: 5,
      "&:hover": {
        background: "gray",
        color: "gray",
      },
    },
  },
}));

