import React from "react";

function RowEmptySimple({ searchText, style = {} }) {
  return (
    <div className="emptySKURow" style={style}>
      <span
        className="emptyCellStyle"
        key="SKU_empty"
        id="footerCell"
        align={"center"}
      >
        {!searchText
          ? "This table is empty"
          : "The current filter selection does not have any results"}
      </span>
    </div>
  );
}

export default RowEmptySimple;
