import moment from "moment";
import React from "react";
import { typeOfTask } from "../../api/types/dbTables";
import { getCompanyUserDisplayName } from "../../helpers/helpers";
import {
  fullDependencyType,
  getReminderDays,
  getTaskDiamond,
  getPredecesorTask,
  getTaskReference,
} from "../../helpers/timelineCalendar";
import { getUserAvatar } from "../../helpers/users";
import { TaskPopoverStyled } from "./styles";

function TaskPopover({
  task,
  companyUsers,
  predecesorTask,
  shipments = [],
  purchaseOrders,
  vendorIndex = false,
}) {
  function getPOsFromShipment({ purchaseOrders = [], shipment }) {
    if (!shipment || !shipment.purchaseOrderIds) return "";
    let linkedPOs = [];
    shipment.purchaseOrderIds.forEach((poId) => {
      const purchaseOrder = purchaseOrders.find((po) => po.id === poId);
      if (purchaseOrder) {
        linkedPOs.push(purchaseOrder.number);
      }
    });
    return linkedPOs.join(", ");
  }
  const getParseDate = (date, isEndDate) => {
    if (!moment(date).isValid()) {
      return "Pending...";
    } else {
      return (
        <span>
          {moment(date).format("M/D/YY")}{" "}
          {getReminderDays({
            date,
            isEndDate: isEndDate && task.status !== task.COMPLETE,
          })}
        </span>
      );
    }
  };

  return (
    <TaskPopoverStyled className="taskPopoverContainer" elevation={0}>
      <div
        className={"taskDescriptionContainer"}
        style={{ alignItems: "flex-start" }}
      >
        {getTaskDiamond({
          status: task.status,
          styles: { height: 15, width: 13, margin: "0px 4px" },
        })}
        <span className={"taskDescriptionBold"}>{task.description}</span>
      </div>
      <div className={"taskInfoContainer"}>
        <span className={"taskInfo"}>Starts:</span>
        <span className={"taskDescriptionNormal"}>
          {getParseDate(task.startDate, false)}
        </span>
      </div>
      <div className={"taskInfoContainer"}>
        <span className={"taskInfo"}>Ends:</span>
        <span className={"taskDescriptionNormal"}>
          {getParseDate(task.finishDate, true)}
        </span>
      </div>
      <div className={"taskInfoContainer"}>
        <span className={"taskInfo"}>Duration:</span>
        <span className={"taskDescriptionNormal"}>
          {task.duration} {task.duration === 1 ? "day" : "days"}
        </span>
      </div>
      <div className={"taskInfoContainer"}>
        <span className={"taskInfo"}> Reference:</span>
        <span className={"taskDescriptionNormal"}>
          {getTaskReference({ task, vendorIndex })}
        </span>
      </div>
      <div className={"taskInfoContainer"}>
        <span className={"taskInfo"}>Predecesor:</span>
        <span className={"taskDescriptionNormal"}>
          {getPredecesorTask({ task, predecesorTask })}
        </span>
      </div>
      <div className={"taskInfoContainer"}>
        <span className={"taskInfo"}>Dependency:</span>
        <span className={"taskDescriptionNormal"}>
          {fullDependencyType[task.dependencyType]}
        </span>
      </div>
      <div className={"taskInfoContainer"}>
        <span className={"taskInfo"}>Owner:</span>
        <span className={"taskDescriptionNormal"} style={{ height: 28 }}>
          {getUserAvatar({
            user: companyUsers.find((user) => user.id === task.assignedTo),
            styles: {
              width: 21,
              height: 21,
              marginRight: 3,
              fontSize: 11,
              outline: "1px solid #000",
              marginLeft: 2,
            },
          })}
          {getCompanyUserDisplayName(companyUsers, task.assignedTo)}
        </span>
      </div>
      {task.type === typeOfTask.SHIPMENT && !vendorIndex && (
        <div className={"linkedPOsContainer"}>
          <span className={"taskInfo"}>POs:</span>
          <span className={"linkedPOsDescription"}>
            {getPOsFromShipment({
              purchaseOrders,
              shipment: shipments.find(
                (shipment) => shipment.id === task.shipmentId
              ),
            })}
          </span>
        </div>
      )}
    </TaskPopoverStyled>
  );
}

export default TaskPopover;
