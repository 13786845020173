import { now } from "moment";

export default class FileDocumentVersion {
  constructor({
    url = "",
    version = 1,
    type = "",
    created = now(),
    createdBy = "",
    size = 0,
    storageRef = "",
  }) {
    this.url = url;
    this.version = version;
    this.type = type;
    this.created = created;
    this.createdBy = createdBy;
    this.size = size;
    this.storageRef = storageRef;
  }
}
