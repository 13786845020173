import React, { useState } from "react";
import CustomButton from "../Buttons/CustomButton";
import { Dialog, DialogActions, DialogContent } from "@mui/material";

function WithConfirmation({
  children,
  disabled,
  text = "Estas Seguro que deseas continuar?",
}) {
  const [isOpen, setIsOpen] = useState(false);
  const newChildren = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      onClick: () => {
        disabled
          ? children.props.onClick
            ? children.props.onClick()
            : children.props.onSubmit()
          : setIsOpen(true);
      },
    })
  );
  return (
    <React.Fragment>
      {newChildren}
      <Dialog onClose={() => setIsOpen(false)} open={isOpen}>
        <DialogContent>
          <div>{text}</div>
        </DialogContent>
        <DialogActions>
          <CustomButton type="cancel" onClick={() => setIsOpen(false)}>
            {"generic.text.cancel"}
          </CustomButton>
          <CustomButton
            type="success"
            onClick={() => {
              children.props.onClick
                ? children.props.onClick()
                : children.props.onSubmit();
              setIsOpen(false);
            }}
          >
            {
              "components.confirmationdialog.withconfirmation.custombutton.continue"
            }
          </CustomButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default WithConfirmation;
