import { useState, useEffect, useCallback, useLayoutEffect } from "react";
import {
  clearPurchaseOrders,
  clearShipmentOrders,
  clearSKUItems,
  debounce,
  listenRolePermissions,
  listenSKUData,
  listenToData,
  listenToDataMentions,
  listenToDataMiscellaneous,
  listenToDataPOs,
  listenToDataRefactored,
  listenToDataShipments,
  listenToDataSO,
  listenToDocument,
  listenToNotifications,
  replacePathReference,
} from "../helpers/helpers";
import { useSelector, useDispatch } from "react-redux";
import { dbTables } from "../api/types/dbTables";
import { useUser, useUserDraft } from "./user";
import userTypes from "../api/types/userTypes";
import { firestore } from "../firebase";
import { QUERY_PARAMS } from "../actions/types";
import { TYPE_OF_AUTOCOMPLETE_LISTS } from "../helpers/constants";
import moment from "moment";
import { useRolesPermisions } from "./permissions";
import { isEqual } from "lodash";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  increment,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { usePurchaseOrders } from "./salesOrders";
import { updateUserMentionDoc } from "../components/PurchaseOrderDashboard/HelperProjectNotes";

export const setDraftActivity = async ({
  pathToSet,
  activity,
  notification,
  currentDraft,
}) => {
  const { id, userMentions = {}, userId } = notification;
  const parts = pathToSet.split("/");
  const orderPath = parts.slice(0, 3).join("/");
  const snapCurrentOrder = await getDoc(
    doc(firestore, `${dbTables.COMPANIES}/${orderPath}`)
  );
  const currentOrder = {
    ...snapCurrentOrder.data(),
    ref: snapCurrentOrder.ref,
  };
  const hasMentions = Object.keys(userMentions).length > 0;

  setDoc(doc(firestore, `${dbTables.COMPANIES}/${pathToSet}`), {
    ...activity,
    notificationId: hasMentions ? activity.notificationId : "",
    enableToEdit: false,
    ref: "",
  });

  if (activity.threadId) {
    const newPath = replacePathReference({
      oldPath: pathToSet,
      newId: activity.threadId,
    });
    updateDoc(doc(firestore, `${dbTables.COMPANIES}/${newPath}`), {
      numberOfReplies: increment(1, 0),
    });
  }
  await deleteDoc(currentDraft.ref);
  for (const user in userMentions) {
    if (user !== userId) {
      setDoc(
        doc(
          firestore,
          `${dbTables.USERS}/${user}/${dbTables.NOTIFICATIONS}/${id}`
        ),
        {
          ...notification,
        }
      );
    }
  }
  updateUserMentionDoc({
    newMentions: userMentions,
    currentOrder,
  });
};

export function useDraftNote(currentTabId) {
  const user = useUser();
  const drafts = useUserDraft();
  const [sign, setSign] = useState(false);
  const onDebounce = useCallback(
    debounce(() => {
      setSign(true);
    }, 6100),
    []
  );

  useEffect(() => {
    if (drafts.length > 0) {
      onDebounce();
    }
  }, [drafts]);

  useEffect(() => {
    if (sign) {
      if (user && user.ref && user.checkDraft) {
        updateDoc(user.ref, {
          checkDraft: false,
        });
      }
      const [currentDraft] = drafts;
      if (currentDraft && !currentDraft.editing) {
        const { tabId, pathToSet, notification, ...activity } = currentDraft;
        if (tabId === currentTabId) {
          setDraftActivity({
            pathToSet,
            activity,
            notification,
            currentDraft,
          });
        }
      }
      setSign(false);
    }
  }, [sign]);
}

export function useQueryParams() {
  const query = useSelector((state) => state.data.queryParams) || {};
  return query;
}

export function useQueryHook(location) {
  const dispatch = useDispatch();
  useEffect(() => {
    const pathname = location.pathname.split("/");
    let query = {};
    if (pathname.length >= 2) {
      pathname.forEach((param, index) => {
        if (index > 1 && index % 2 === 0 && param) {
          query = { ...query, [param]: pathname[index + 1] || "" };
        }
      });
    }
    const queryString = location.search.slice(1);

    const queryArray = queryString.split("&&");
    queryArray.forEach((param) => {
      const splittedParam = param.split("=");
      const key = splittedParam[0];
      const value = splittedParam[1];
      if (value) {
        query[key] = value;
      }
    });

    dispatch({
      type: QUERY_PARAMS,
      table: "QUERY_PARAMS",
      payload: {
        ...query,
      },
    });
    return;
  }, [location]);
}

export function useFirestoreData(path) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getDocs(collection(firestore, path))
      .then((snapshot) => {
        setData(snapshot.docs.map((doc) => doc.data()));
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  return [data, loading];
}

export function useJSONLocalStorage(key) {
  function get() {
    if (!localStorage.getItem(key)) {
      return;
    }
    return JSON.parse(localStorage.getItem(key));
  }

  function set(item) {
    return localStorage.setItem(key, JSON.stringify(item));
  }

  function remove() {
    return localStorage.removeItem(key);
  }

  return { get, set, remove };
}

export function useFavoritesList({ dbTable = "" }) {
  const user = useUser();
  const favorites = useSelector((state) => state.data[dbTable][user.id]) || [];
  let favoriteList = [];
  favorites.forEach((favorite) => {
    favoriteList = [...favoriteList, ...favorite.list];
  });
  return favoriteList;
}

export function useFavorites({ dbTable }) {
  const user = useUser();
  const favorites = useSelector((state) => state.data[dbTable][user.id]) || [];
  return favorites;
}

export function useData(path = [], changingParams = [], query = null) {
  const dispatch = useDispatch();
  const data = useSelector(
    (state) => state.data[path[path.length - 1]][path[path.length - 2]] || []
  );

  const listen = useCallback(() => listenToData({ query, path })(dispatch));

  useEffect(() => {
    if (path[path.length - 2]) {
      listen();
    }
  }, [...changingParams] || []);
  return data;
}

export function useDataRefactored({
  path = [],
  changingParams = [],
  query = null,
  limit,
  orderBy,
  stateReference,
}) {
  const dispatch = useDispatch();
  const data = useSelector((state) => {
    if (state.data[stateReference]) {
      return state.data[stateReference][path[path.length - 2]] || [];
    }
    return [];
  });

  const listen = useCallback(() =>
    listenToDataRefactored({ query, path, limit, orderBy, stateReference })(
      dispatch
    )
  );

  const globalState = useSelector((state) => state.data);
  useEffect(() => {
    const existListener =
      globalState[`unsubscribe_${stateReference}`] &&
      globalState[`unsubscribe_${stateReference}`][path[path.length - 2]];
    if (path[path.length - 2] && !existListener) {
      listen();
    }
  }, [...changingParams] || []);
  return data;
}

export function useDataMiscellaneous({
  path = [],
  changingParams = [],
  stateReference,
  referenceId,
}) {
  const dispatch = useDispatch();
  const [documentRefId, setDocumentRefId] = useState(null);

  const listen = useCallback(() => {
    return listenToDataMiscellaneous({ path, stateReference, referenceId })(
      dispatch
    );
  });

  const unsubscribe = useSelector(
    (state) => state.data[`unsubscribe_${stateReference}`]
  );
  useEffect(() => {
    if (documentRefId !== referenceId) {
      if (unsubscribe && unsubscribe[path[path.length - 2]]) {
        unsubscribe[path[path.length - 2]]();
      }
      listen();
      setDocumentRefId(referenceId);
    }
  }, [...changingParams] || []);
  return;
}

export function useDataCustomers({ salesOrder = {}, companyId }) {
  const [currentCustomerId, setCurrentCustomerId] = useState(false);
  const dispatch = useDispatch();
  const listenCustomer = useCallback((customerId) =>
    listenToDocument({
      table: dbTables.CURRENT_CUSTOMER,
      path: [dbTables.COMPANIES, companyId, dbTables.CUSTOMERS, customerId],
      keyName: "customer",
    })(dispatch)
  );

  const unsubscribeCurrentCustomer = useSelector(
    (state) => state.data.unsubscribeCurrentCustomer
  );

  useEffect(() => {
    if (!salesOrder.customerId) {
      return;
    }
    if (currentCustomerId !== salesOrder.customerId) {
      if (unsubscribeCurrentCustomer) {
        unsubscribeCurrentCustomer();
      }
      listenCustomer(salesOrder.customerId);
      setCurrentCustomerId(salesOrder.customerId);
    }
  }, [salesOrder.customerId]);
}

export function useDataMentions({ userId }) {
  const dispatch = useDispatch();

  const listenUserMention = useCallback(() =>
    listenToDataMentions({ userId })(dispatch)
  );

  useEffect(() => {
    if (userId) {
      listenUserMention();
    }
  }, [userId]);
}

export function useDataSO({ salesOrderId, companyId }) {
  const [currentSOid, setCurrentSOid] = useState(salesOrderId);
  const dispatch = useDispatch();

  const unsubscribePO = useSelector(
    (state) => state.data.unsubscribePurchaseOrders
  );

  const listenSO = useCallback((soId) =>
    listenToDataSO({ salesOrderId: soId, companyId })(dispatch)
  );
  const listenPOs = useCallback((soId) =>
    listenToDataPOs({ salesOrderId: soId, companyId })(dispatch)
  );

  const clearPOs = useCallback(() =>
    clearPurchaseOrders({ companyId })(dispatch)
  );
  useEffect(() => {
    if (currentSOid !== salesOrderId) {
      if (unsubscribePO) {
        unsubscribePO();
      }
      if (!salesOrderId && currentSOid) {
        clearPOs();
      }
      if (salesOrderId) {
        listenSO(salesOrderId);
        listenPOs(salesOrderId);
      }
      setCurrentSOid(salesOrderId);
    }
  }, [salesOrderId]);
}

export function useDataSKU({ purchaseOrderId, companyId }) {
  const [itemTableIds, setItemTableIds] = useState([]);
  const purchaseOrders = usePurchaseOrders();
  const dispatch = useDispatch();
  const unsubscribeItems =
    useSelector((state) => state.data.unsubscribeItems) || {};
  const clearItems = useCallback(() => clearSKUItems({ companyId })(dispatch));

  const listenSKUItem = useCallback((poId, skuVersion) =>
    listenSKUData({ companyId, poId, skuVersion })(dispatch)
  );
  useEffect(() => {
    if (purchaseOrderId && purchaseOrders.length > 0) {
      for (let i = 0; i < purchaseOrders.length; i++) {
        const po = purchaseOrders[i];
        if (po.itemTableId && !itemTableIds.includes(po.itemTableId)) {
          listenSKUItem(po.id, po.itemTableId);
          setItemTableIds((oldIds) => [...oldIds, po.itemTableId]);
        }
      }
    }
    if (!purchaseOrderId && Object.keys(unsubscribeItems).length !== 0) {
      Object.keys(unsubscribeItems).forEach((unsubscribeItem) => {
        if (unsubscribeItems[unsubscribeItem]) {
          unsubscribeItems[unsubscribeItem]();
        }
      });
      console.log("clear");
      setItemTableIds([]);
      clearItems();
    }
  }, [purchaseOrders]);
}

export function useDataSOnShipment({ purchaseOrder = {}, companyId }) {
  const [currentSalesOrdersIds, setCurrentSalesOrdersIds] = useState([]);

  const [currentPurchaseOrderId, setCurrentPurchaseOrderId] = useState("");
  const dispatch = useDispatch();
  const listenSO = useCallback((soId) =>
    listenToDataSO({ salesOrderId: soId, companyId })(dispatch)
  );
  const listenShipments = useCallback((poId) =>
    listenToDataShipments({ purchaseOrderId: poId, companyId })(dispatch)
  );
  const unsubscribeShipments = useSelector(
    (state) => state.data.unsubscribeShipments
  );
  const clearShipments = useCallback(() =>
    clearShipmentOrders({ companyId })(dispatch)
  );
  useEffect(() => {
    const salesOrderIds = purchaseOrder.salesOrderIds || [];
    if (!isEqual(currentSalesOrdersIds, salesOrderIds)) {
      if (salesOrderIds.length > 0) {
        salesOrderIds.forEach((soIds) => {
          listenSO(soIds);
        });
      }
      setCurrentSalesOrdersIds(salesOrderIds);
    }
  }, [purchaseOrder.salesOrderIds]);

  useEffect(() => {
    const poId = purchaseOrder.id || "";
    if (poId !== currentPurchaseOrderId) {
      if (unsubscribeShipments) {
        unsubscribeShipments();
        clearShipments();
      }
      if (poId) {
        listenShipments(poId);
      }
      setCurrentPurchaseOrderId(poId);
    }
  }, [purchaseOrder.id]);
}

export function useCurrentRolePermissions({ role = {}, companyId, user = {} }) {
  const [currentRoleId, setCurrentRoleId] = useState(role.id);
  const dispatch = useDispatch();

  const unsubscribeRolePermissions = useSelector(
    (state) => state.data.unsubscribeRolePermissions
  );
  const currentPermissions = useRolesPermisions({ userRole: role });
  const listenRolePermission = useCallback((roleId) =>
    listenRolePermissions({ roleId, companyId })(dispatch)
  );

  useEffect(() => {
    if (role.name === user.role) {
      console.log("same", role.name);
      return;
    }
    if (role.id && role.id !== currentRoleId) {
      if (unsubscribeRolePermissions) {
        unsubscribeRolePermissions();
      }
      listenRolePermission(role.id);
      setCurrentRoleId(role.id);
    }
  }, [role.id]);
  return currentPermissions;
}

export function useDataShipments({
  shipmentsId,
  path = [],
  changingParams = [],
}) {
  const dispatch = useDispatch();
  const shipmentTasks = useSelector((state) => state.data.shipment_tasks) || {};
  const shipmentDocuments =
    useSelector((state) => state.data.shipment_documents) || {};
  const listen = useCallback((shipmentId) => {
    listenToData({ path: [...path, shipmentId, dbTables.SHIPMENT_TASKS] })(
      dispatch
    );
    listenToData({ path: [...path, shipmentId, dbTables.SHIPMENT_DOCUMENTS] })(
      dispatch
    );
  });

  useEffect(() => {
    if (shipmentsId) {
      Object.keys(shipmentsId).forEach((shipmentId) => listen(shipmentId));
    } else {
      return {
        shipmentTasks: [],
        shipmentDocuments: [],
      };
    }
  }, [...changingParams] || []);

  let shipmentTasksResult = [];
  let shipmentDocumentsResult = [];
  Object.keys(shipmentTasks).forEach((shipmentId) => {
    if (shipmentsId && shipmentsId[shipmentId]) {
      shipmentTasksResult = [
        ...shipmentTasksResult,
        ...shipmentTasks[shipmentId],
      ];
    }
  });
  Object.keys(shipmentDocuments).forEach((shipmentId) => {
    if (shipmentsId && shipmentsId[shipmentId]) {
      shipmentDocumentsResult = [
        ...shipmentDocumentsResult,
        ...shipmentDocuments[shipmentId],
      ];
    }
  });

  return {
    shipmentTasks: shipmentTasksResult,
    shipmentDocuments: shipmentDocumentsResult,
  };
}

export function useDataNotifications(path = [], changingParams = []) {
  const dispatch = useDispatch();
  const data = useSelector(
    (state) => state.data[path[path.length - 1]][path[path.length - 2]] || []
  );

  const listen = useCallback(() => listenToNotifications(...path)(dispatch));

  useEffect(() => {
    if (path[path.length - 2]) {
      listen();
    }
  }, [...changingParams] || []);
  return data;
}

export function useCompanies() {
  return useSelector((state) => state.data.companies) || [];
}

export function useCompanyId() {
  const user = useUser() || {};
  return (
    (user.role === userTypes.TRADEDASH_EMPLOYEE
      ? user.observedCompany
      : user.companyId) || ""
  );
}

export function usePermissionGroups({
  companyId,
  filteringByInactive = false,
}) {
  const permissionGroups = useSelector(
    (state) => state.data.permission_groups[companyId] || []
  );
  if (filteringByInactive) {
    return permissionGroups.filter(
      (permissionGroup) => !permissionGroup.isInactive
    );
  }
  return permissionGroups;
}

export function useModal() {
  const [open, setOpen] = useState(false);
  function openModal() {
    setOpen(true);
  }
  function closeModal() {
    setOpen(false);
  }
  return { open, openModal, closeModal };
}

export function useStorage(key) {
  const { get, set } = useJSONLocalStorage(key);

  function changeStorageProperty(prop, value, replace = false) {
    if (get) {
      if (replace) {
        set({ ...value, setDate: Date.now() });
      } else {
        set({ ...get(), [prop]: value, setDate: Date.now() });
      }
    } else {
      set({ [prop]: value, setDate: Date.now() });
    }
  }

  useLayoutEffect(() => {
    if (get() && get().setDate) {
      const oneDay = 86400000;
      if (get().setDate - Date.now() > oneDay) {
        localStorage.removeItem(key);
      }
    }
  }, []);

  return [get() || {}, changeStorageProperty];
}

export function useGLAccounts() {
  const companyId = useCompanyId();
  const GLAccounts =
    useSelector((state) => state.data[dbTables.GL_ACCOUNTS][companyId]) || [];
  return GLAccounts;
}

export function useGLAccountItems(filterDeactivate = false) {
  const companyId = useCompanyId();
  const GLAccounts =
    useSelector((state) => state.data[dbTables.GL_ACCOUNTS][companyId]) || [];
  const GLAccountItems = [];
  GLAccounts.forEach((account) => {
    account.accounts.forEach((item) => {
      if (filterDeactivate) {
        if (item.active) {
          GLAccountItems.push(item);
        }
      } else {
        GLAccountItems.push(item);
      }
    });
  });
  return GLAccountItems;
}

export function useAutoCompleteLists({
  id = TYPE_OF_AUTOCOMPLETE_LISTS.PAID_TO,
  canCreate = true,
}) {
  const companyId = useCompanyId();
  const autocompleteList =
    useSelector(
      (state) => state.data[dbTables.AUTOCOMPLETE_LISTS][companyId]
    ) || [];

  const autocompleteItem = autocompleteList.find((item) => item.id === id);
  if (autocompleteItem) {
    return autocompleteItem;
  } else {
    if (canCreate) {
      setDoc(
        doc(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${dbTables.AUTOCOMPLETE_LISTS}/${id}`
        ),
        {
          id,
          list: [],
          creationDate: moment.now(),
          companyId,
        }
      );
    }

    return {
      id,
      list: [],
      creationDate: moment.now(),
      companyId,
    };
  }
}

export function useCategories() {
  const companyId = useCompanyId();
  const categories =
    useSelector((state) => state.data[dbTables.CATEGORIES][companyId]) || [];
  return categories;
}

export function useCategoryItems({ filterDeactivate = false }) {
  const companyId = useCompanyId();
  const categories =
    useSelector((state) => state.data[dbTables.CATEGORIES][companyId]) || [];
  const categoryItems = [];
  categories.forEach((category) => {
    category.categories.forEach((item) => {
      if (filterDeactivate) {
        if (item.active) {
          categoryItems.push(item);
        }
      } else {
        categoryItems.push(item);
      }
    });
  });
  return categoryItems;
}
