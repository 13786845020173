import { now } from "moment/moment";
import { getRandomId } from "../../helpers/helpers";

export default class CategoryItem {
  constructor({
    id = getRandomId(),
    name = "",
    description = "",
    createdBy = "",
    createdDate = now(),
    updatedBy = "",
    updatedDate = "",
    active = true,
    summaryId = "",
  }) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.createdBy = createdBy;
    this.createdDate = createdDate;
    this.updatedBy = updatedBy;
    this.updatedDate = updatedDate;
    this.active = active;
    this.summaryId = summaryId;
  }
}
