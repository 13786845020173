import React from "react";
import { cx } from "@emotion/css";
import { colors } from "../../assets/jss/variables";
import { HiddenDataIcon } from "../iconSvgPath";

function HiddenDataIconAnimated({
  styles,
  color,
  onClick = () => {},
  size,
  className,
  showHighlight = false,
}) {
  return (
    <HiddenDataIcon
      svgClass={cx("expandRow", className)}
      color={color}
      style={{
        ...styles,
        borderRadius: 6,
        background: showHighlight ? colors.yellowHighlight : "",
      }}
      onClick={onClick}
      size={size}
    />
  );
}

export default HiddenDataIconAnimated;
