import React from "react";
import CustomCheckbox from "../Inputs/CustomCheckbox";
import { getUserAvatar } from "../../helpers/users";
import { InfoIcon } from "../../helpers/iconSvgPath";
import TooltipTD from "../Tooltip/TooltipTD";

export const OptionAutocomplete = ({ inputProps, option, state, showInfo }) => {
  const selected = state.selected;
  return (
    <div
      {...inputProps}
      className="poppoverOption"
      style={{ display: "flex", alignItems: "center" }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          height: "inherit",
          paddingInline: 10,
          gap: 5,
        }}
      >
        <CustomCheckbox
          checked={selected}
          iconSize={23}
          styles={{ padding: 0, marginBottom: -2 }}
        />
        <div
          className="selectedText"
          style={{
            ...option.styles,
          }}
        >
          {option.user &&
            getUserAvatar({
              user: option.user,
              styles: {
                width: 20,
                height: 20,
                margin: 3,
                fontSize: 10,
                outline: "1px solid #000",
              },
            })}
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
            }}
          >
            {option.label}
          </span>
          {showInfo && option.description && (
            <TooltipTD
              label={option.description}
              style={{ display: "flex", alignItems: "center" }}
            >
              <InfoIcon width={18} height={18} />
            </TooltipTD>
          )}
        </div>
      </div>
    </div>
  );
};
