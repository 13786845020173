import { REPORTS_TYPE } from "./constants";

export function filterReports({ reports = [], user = {}, accessGroups = [] }) {
  if (reports.length === 0 || !user.id) {
    return [];
  }
  let reportsCpy = [...reports];
  reportsCpy = reportsCpy.filter((report) => report.active);
  const originalReports = reportsCpy;
  reportsCpy = reportsCpy.filter((report) => {
    const parentReport =
      originalReports.find(
        (currentReport) => currentReport.id === report.parentId
      ) || {};
    const reportPermission = report.permissionUsers || {};
    if (reportPermission[user.id]) {
      return true;
    }
    if (report.type !== REPORTS_TYPE.FOLDER) {
      if (!report.parentId) {
        return true;
      }
      const parentPermission = parentReport.permissionUsers || {};
      if (parentPermission[user.id]) {
        return true;
      }
    }
    const reportPermissionGroups =
      parentReport.permissionGroups || report.permissionGroups || {};

    let isFilterReport = false;
    Object.keys(reportPermissionGroups).forEach((permissionId) => {
      const currentPermissionGroup =
        accessGroups.find((accessGroup) => accessGroup.id === permissionId) ||
        {};
      const userPermissionGroup = user.permissionGroups || {};
      if (
        userPermissionGroup[permissionId] &&
        currentPermissionGroup.id &&
        !currentPermissionGroup.isInactive
      ) {
        isFilterReport =  true;
      }
    });
    return isFilterReport;
  });
  return reportsCpy;
}

export function canSeeReport({ folder = {}, user = {}, accessGroups = [] }) {
  if (folder?.permissionUsers?.[user.id]) {
    return true;
  }
  const reportPermissionGroups = folder.permissionGroups || {};
  Object.keys(reportPermissionGroups).forEach((permission) => {
    const isPermissionEnabled = accessGroups.find(
      (accessGroup) => accessGroup.id === permission
    );
    if (
      user.permissionGroups &&
      user.permissionGroups[permission] &&
      isPermissionEnabled &&
      !isPermissionEnabled.isInactive
    ) {
      return true;
    }
  });

  return false;
}
