import styled from "@emotion/styled";
import { Autocomplete } from "@mui/material";

export const AutocompleteSelectStyled = styled(Autocomplete)({
  "&.autocomplete-select": {
    height: 26,
    width: "100% !Important",
    "& .adorment": {
      cursor: "pointer",
      width: 30,
      "& .iconButton": {
        padding: 0,
        minWidth: "unset",
        minHeight: "unset",
        display: "flex",
        width: "100%",
        height: "100%",
      },
    },
    "& .text-field": {
      padding: 0,
      height: "100%",
      "& div": {
        height: "100%",
        padding: 0,
        position: "absolute",
        right: 0,
      },
      "& .autocomplete-input": {
        padding: 5,
      },
      "& .adornedend": {
        borderRadius: "0px !important",
        border: "none !important",
        "&:hover": {
          borderRadius: "0px !important",
          border: "none !important",
          borderColor: "white !important",
        },
        padding: "0px !important",
        "& .endAdornment": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: 25,
          "& button": {
            width: 40,
            minWidth: 0,
          },
        },
      },
    },
  },
});

export const AutocompleteSimpleStyled = styled(Autocomplete)({
  "&.autocomplete-simple": {
    width: 450,
    "& .clearIndicator": {
      minWidth: 0,
      minHeight: 0,
      height: 30,
      width: 30,
    },
  },
});
