import { cx } from "@emotion/css";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import TooltipTD from "../../Tooltip/TooltipTD";
import defaultImage from "../../../assets/flag-icons/no_image_available.jpg";

import { getPOPathBySKU, getSKUProperties } from "./SKUHelper";
import {
  formatCash,
  formatWithComma,
  getFactoryName,
} from "../../../helpers/helpers";
import itemStatus from "../../../api/types/itemStatus";
import { useQueryParams } from "../../../hooks";
import {
  COMPANY_PERMISSION_VALUE,
  GENERAL_PERMISSION_VALUE,
  SALES_ORDER_STATUS,
  generalPermissions,
  notAllowedToSee,
} from "../../../helpers/constants";
import AttentionModal from "../../Modal/AttentionModal";
import SKUEditableLabelV2 from "./SKUEditableLabelV2";
import {
  useIsAllowedCompany,
  useIsAllowedFunction,
} from "../../../hooks/permissions";
import { useFactories } from "../../../hooks/factories";
import POBadge from "../../Badge/POBadge";

function SimpleShipmentRowSKU({
  itemSKU,
  showImage,
  handlePhotosViewer,
  entity,
  prefix,
  purchaseOrders,
  onChangeData = () => {},
}) {
  const {
    cartons = 0,
    cbmMaster,
    description,
    extendedCost,
    image,
    itemNumber,
    piecesPerMaster,
    quantity,
    totalCBM,
    purchaseOrder = {},
    status,
    unitCost,
    weight,
  } = getSKUProperties({ itemSKU, prefix, entity, purchaseOrders });
  const isCompanyAllowed = useIsAllowedCompany();
  const factories = useFactories();
  const isAllowed = useIsAllowedFunction();
  const queryParams = useQueryParams();
  const [modalAttention, setModalAttention] = useState(false);
  const isReadOnly =
    itemSKU.status === itemStatus.READ_ONLY ||
    entity.status === SALES_ORDER_STATUS.VOIDED;
  const blockRedirect = itemStatus.READ_ONLY === status && purchaseOrder.id;

  const isAllowedToShowPrice = isAllowed(generalPermissions.SHIPMENT_AMOUNT);

  return (
    <div
      className={cx("itemSKURow", { withImage: showImage })}
      key={itemSKU.id}
    >
      {modalAttention && (
        <AttentionModal
          title="Attention"
          description={"This item is not part of any PO in this project"}
          isOpen={modalAttention}
          onClick={() => setModalAttention(false)}
        />
      )}
      <div className={"cellRow"}>
        {itemSKU.notAllowedToSee ? notAllowedToSee : itemNumber}
      </div>
      <div className={cx("cellRow", "centerAlign")}>
        {showImage && !itemSKU.notAllowedToSee && (
          <img
            className="image"
            src={image}
            alt={`${itemNumber}Img`}
            onClick={() => {
              handlePhotosViewer({
                open: true,
                photos: [
                  ...itemSKU.image.map((el) => ({
                    src: el || defaultImage,
                    number: itemSKU.itemNumber,
                    description: itemSKU.description,
                  })),
                ],
              });
            }}
          />
        )}
      </div>
      <div className={cx("customized-tooltip")} id={description}>
        {itemSKU.notAllowedToSee ? (
          notAllowedToSee
        ) : (
          <TooltipTD
            label={getFactoryName(factories, purchaseOrder.factoryId)}
            className="tooltTipSpan"
          >
            <NavLink
              to={
                blockRedirect
                  ? null
                  : getPOPathBySKU({ purchaseOrder, queryParams })
              }
              className={cx("navLinkClass", { blockRedirect })}
              style={{
                textDecoration: "none",
              }}
            >
              <POBadge number={purchaseOrder.number} />
            </NavLink>
          </TooltipTD>
        )}
      </div>
      <div
        className={cx("customized-tooltip", {
          hiddenPrice: !isAllowedToShowPrice,
        })}
        id={description}
      >
        {itemSKU.notAllowedToSee ? (
          notAllowedToSee
        ) : (
          <TooltipTD label={description} className="tooltTipSpan">
            {description}
          </TooltipTD>
        )}
      </div>
      <div className={cx("cellRow", "alignRight", "shipmentPrice")}>
        {itemSKU.notAllowedToSee ? (
          notAllowedToSee
        ) : (
          <SKUEditableLabelV2
            value={quantity}
            disabled={
              !isAllowed(GENERAL_PERMISSION_VALUE.SHIPMENT_QUANTITY) ||
              !isCompanyAllowed(COMPANY_PERMISSION_VALUE.SHIPMENT_QUANTITY) ||
              isReadOnly
            }
            labelFormat={(value) => formatWithComma(value)}
            onChangeLabel={(temporalLabel) => {
              onChangeData({
                item: itemSKU,
                row: {
                  ...itemSKU,
                  shipmentQuantity: temporalLabel,
                  prevId: "",
                  erased: parseInt(temporalLabel, 10) === 0,
                },
              });
            }}
          />
        )}
      </div>

      {isAllowedToShowPrice && (
        <div className={cx("cellRow", "alignRight", "unitCost")}>
          {itemSKU.notAllowedToSee ? notAllowedToSee : formatCash(unitCost)}
        </div>
      )}

      {isAllowedToShowPrice && (
        <div className={cx("cellRow", "alignRight", "unitCost")}>
          {itemSKU.notAllowedToSee ? notAllowedToSee : formatCash(extendedCost)}
        </div>
      )}
      <div className={cx("cellRow", "alignRight", "cbmMaster")}>
        {itemSKU.notAllowedToSee ? notAllowedToSee : cbmMaster}
      </div>
      <div className={cx("cellRow", "alignRight", "piecesPerMaster")}>
        {itemSKU.notAllowedToSee ? notAllowedToSee : piecesPerMaster}
      </div>
      <div
        className={cx("cellRow", "alignRight", "cartons", {
          dangerStyle: itemSKU.notAllowedToSee ? false : cartons % 1 !== 0,
        })}
      >
        {itemSKU.notAllowedToSee ? (
          notAllowedToSee
        ) : (
          <TooltipTD
            label={"The order quantity is not a whole number of cartons"}
            showToolTip={cartons % 1 !== 0}
          >
            {cartons.toLocaleString("en-US", {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })}
          </TooltipTD>
        )}
      </div>

      <div className={cx("cellRow", "alignRight", "totalCBM")}>
        {itemSKU.notAllowedToSee ? notAllowedToSee : totalCBM}
      </div>
      <div className={cx("cellRow", "alignRight", "totalCBM")}>
        {itemSKU.notAllowedToSee ? notAllowedToSee : weight}
      </div>
    </div>
  );
}

export default SimpleShipmentRowSKU;
