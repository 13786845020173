import { styled } from "@mui/material";
import { colors } from "../../assets/jss/variables";

export const ReportsListViewTreeManagementStyled = styled("div")(() => ({
  "&.reportsListViewTreeContainer": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    background: "white",
    "& .headerOptionsContainer": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      width: "100%",
      padding: "8px",
      gap: 16,
      "& .newFolderOptionTreeview": {
        fontSize: "14px",
        fontWeight: "bold",
        color: colors.primaryDark,
        "&:hover": {
          cursor: "pointer",
        },
      },
      "& .iconContainer": {
        cursor: "pointer",
        height: 36,
        width: 36,
        borderRadius: 5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:hover": {
          background: colors.backgroundWhite,
          "& .expandRow": {
            "& path": {
              strokeWidth: 2.5,
            },
          },
        },
        "&:active": {
          background: colors.backgroundWhiteAction,
          "& .expandRow": {
            "& .item1": {
              transform: "translateY(1px)",
            },
            "& .item3": {
              transform: "translateY(-1px)",
            },
            "& path": {
              strokeWidth: 3,
            },
          },
        },
      },
    },
    "& .threeviewHeader": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-evenly",
      width: "100%",
      height: "40px",
      background: colors.tableHeaderColor,
      padding: 16,
    },
  },
  "& .InactiveContainer": {
    margin: "45px 0px 0px 0px",
    height: 31,
    background: "#7A7272",
    color: "white",
    fontSize: 20,
    fontWeight: 700,
    width: "100%",
    display: "flex",
    alignItems: "center",
    "& .inactiveText": {
      marginLeft: 18,
    },
  },
}));

export const ReportTreeItemStyled = styled("div")(() => ({
  "&.reportTreeItemContainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "6px 12px",
    background: "white",
    "& .hiddenIcon": { opacity: 0, cursor: "pointer" },
    "& .dragContainer": {
      cursor: "grab",
      minWidth: 10,
      display: "flex",
      alignItems: "center",
      height: "100%",
    },
    "& .descriptionToolBox": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      gap: 8,
    },
    "&:active": {
      "& .dragContainer": {
        cursor: "grabbing",
      },
    },

    "&:hover": {
      background: colors.lightYellow,
      "& .hiddenIcon": { opacity: 1 },
    },
    "& .dragClass": {
      "&:hover": {
        "& circle": {
          fill: "black",
          r: 1.5,
        },
      },
    },
    "& .disclouserArrowStyle": {
      borderRadius: 5,
      cursor: "pointer",
      fontSize: 20,
      width: 24,
      height: 24,
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      "&:hover": {
        background: colors.backgroundWhite,
      },
      "&:active": {
        background: colors.backgroundWhiteAction,
      },
      "& .arrow": {
        transition: "transform 0.5s ease-in-out",
      },
    },

    "& .reportItemOptions": {
      display: "flex",
      alignItems: "center",
      gap: 16,
      width: 120,
      minWidth: 120,
      justifyContent: "flex-end",
    },
    "& .reportDescription": {
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 4,
      width: "100%",
      textAlign: "justify",
    },
  },
  "&.hoverDrag": {
    outline: `2px solid ${colors.purchaseOrderBorderColor}`,
    outlineOffset: -5,
  },
  "&.selectedTree": {
    background: "#ADD8E6",
    "&:hover": {
      background: "#adcae6",
    },
  },
}));
