import { updateDoc } from "firebase/firestore";

export function removePermissionGroup(permissionGroup, companyUsers) {
  updateDoc(permissionGroup.ref, {
    users: {},
    ref: "",
  });

  companyUsers.forEach((user) => {
    const permissionGroupsCpy = user.permissionGroups
      ? { ...user.permissionGroups }
      : {};
    delete permissionGroupsCpy[permissionGroup.id];
    updateDoc(user.ref, {
      permissionGroups: permissionGroupsCpy,
      ref: "",
    });
  });
}

export function addAllUsersToPermissionGroup(permissionGroup, companyUsers) {
  const allUsers = {};
  companyUsers.forEach((user) => (allUsers[user.id] = true));
  updateDoc(permissionGroup.ref, {
    users: allUsers,
    ref: "",
  });

  companyUsers.forEach((user) => {
    const permissionGroupsCpy = user.permissionGroups
      ? { ...user.permissionGroups }
      : {};
    permissionGroupsCpy[permissionGroup.id] = true;
    updateDoc(user.ref, {
      permissionGroups: permissionGroupsCpy,
      ref: "",
    });
  });
}

export function addPermissionGroupToUser(
  userArray,
  lastUserArray,
  permissionGroup
) {
  let addedUser;
  userArray.forEach((user) => {
    if (!lastUserArray.find((lastUser) => lastUser.id === user.id)) {
      addedUser = user;
    }
  });
  const permissionGroupsCpy = addedUser.permissionGroups
    ? { ...addedUser.permissionGroups }
    : {};
  permissionGroupsCpy[permissionGroup.id] = true;
  updateDoc(addedUser.ref, {
    permissionGroups: permissionGroupsCpy,
    ref: "",
  });
}

export function removePermissionGroupFromUser(
  userArray,
  lastUserArray,
  permissionGroup
) {
  let removedUser;
  lastUserArray.forEach((lastUser) => {
    if (!userArray.find((user) => user.id === lastUser.id)) {
      removedUser = lastUser;
    }
  });
  const permissionGroupsCpy = removedUser.permissionGroups
    ? { ...removedUser.permissionGroups }
    : {};
  delete permissionGroupsCpy[permissionGroup.id];
  updateDoc(removedUser.ref, {
    permissionGroups: permissionGroupsCpy,
    ref: "",
  });
}

export function updatePermissionGroupFromFirestore(
  user,
  PermissionGroupsCollection
) {
  const newPermissionGroup = [];
  PermissionGroupsCollection.forEach((permissionGroup) => {
    const usersList = permissionGroup.users;
    if (user.permissionGroups[permissionGroup.id]) {
      newPermissionGroup.push({
        ...permissionGroup,
        users: {
          ...usersList,
          [user.id]: true,
        },
      });
    } else {
      delete usersList[user.id];
      newPermissionGroup.push({
        ...permissionGroup,
        users: {
          ...usersList,
        },
      });
    }
  });
  newPermissionGroup.forEach((permissionGroup) => {
    updateDoc(permissionGroup.ref, { users: permissionGroup.users, ref: "" });
  });
}
