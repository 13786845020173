import React, { useState } from "react";
import { dbTables } from "../../api/types/dbTables";
import UserPermissionsModal from "./UserPermissionsModal";
import AttentionModal from "../Modal/AttentionModal";
import { sortObjectsBy } from "../../helpers/helpers";
import { getUserAvatar } from "../../helpers/users";
import { firestore } from "../../firebase";
import { doc, writeBatch } from "firebase/firestore";
import userTypes from "../../api/types/userTypes";

function Users({
  role,
  changeActiveUser = () => {},
  activeUser,
  companyUsers,
  isReadOnly = false,
  handleReadOnlyModal = () => {},
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [attentionModalIsOpen, setAttentionModalIsOpen] = useState(false);

  function addUser(userItems) {
    if (
      role.name === userTypes.SUPER_ADMIN &&
      (!userItems || !userItems.length)
    ) {
      return setAttentionModalIsOpen(true);
    }
    const batch = writeBatch(firestore);
    const permissionUsersCpy = companyUsers.filter(
      (user) => user.role === role.name
    );
    permissionUsersCpy.forEach((item) => {
      if (item.role === role.name) {
        batch.update(doc(firestore, `${dbTables.USERS}/${item.id}`), {
          role: "COMPANY_USER",
        });
      }
    });
    if (!userItems) {
      batch.commit();
      return;
    }
    userItems.forEach((item) => {
      batch.update(doc(firestore, `${dbTables.USERS}/${item.id}`), {
        role: role.name,
      });
    });
    batch.commit();
  }
  if (!role) {
    return null;
  }

  return (
    <div className="user-manager-users">
      {attentionModalIsOpen && (
        <AttentionModal
          isOpen={attentionModalIsOpen}
          onClose={() => setAttentionModalIsOpen(false)}
          title="Attention"
          description={
            <span>The Group SuperAdmin must have at least one user</span>
          }
          onClick={() => setAttentionModalIsOpen(false)}
        />
      )}
      <header>
        Users
        <button
          disabled={!role}
          className="btn-create"
          onClick={function () {
            if (isReadOnly) {
              handleReadOnlyModal();
            } else {
              setModalIsOpen(true);
            }
          }}
        >
          Add/Remove Users
        </button>
      </header>
      <ul>
        {companyUsers
          .filter((user) => user.role === role.name)
          .filter((permissionUser) =>
            companyUsers.map((item) => item.id).includes(permissionUser.id)
          )
          .sort(sortObjectsBy("displayName", false))
          .map((permissionUser) => (
            <li
              onClick={() => changeActiveUser(permissionUser)}
              className={
                activeUser
                  ? activeUser.id === permissionUser.id
                    ? "active"
                    : ""
                  : ""
              }
              key={permissionUser.id}
              style={{ display: "flex", alignItems: "center" }}
            >
              {getUserAvatar({
                user: permissionUser,
                styles: {
                  width: 30,
                  height: 30,
                  margin: 4,
                  fontSize: 15,
                  outline: "2px solid #000",
                },
              })}
              <span> @{permissionUser.displayName}</span>
            </li>
          ))}
      </ul>
      <UserPermissionsModal
        onSave={addUser}
        users={companyUsers.filter(
          (item) => item.role !== userTypes.SUPER_ADMIN
        )}
        value={companyUsers.filter((user) => user.role === role.name)}
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
      />
    </div>
  );
}

export default Users;
