import { isEqual } from "lodash";
import { dbTables, typeOfTask } from "../../api/types/dbTables";
import taskStages from "../../api/types/taskStages";
import {
  STAGE_LIST,
  TASK_TYPE,
  dependencyTypes,
} from "../../helpers/constants";
import { CALENDAR_SETUP, getTasksWithDates } from "../../helpers/ganttChart";
import { sortObjectsBy } from "../../helpers/helpers";
import { builderTasksInPhases } from "../../helpers/timelineModal";
import { GANTTCHART_TABLE_SIZE } from "../Timeline/GanttChartContext";

export const getNextSize = ({ tableSize, reverse }) => {
  switch (tableSize) {
    case GANTTCHART_TABLE_SIZE.LARGE:
      return reverse
        ? GANTTCHART_TABLE_SIZE.SMALL
        : GANTTCHART_TABLE_SIZE.MEDIUM;
    case GANTTCHART_TABLE_SIZE.MEDIUM:
      return reverse
        ? GANTTCHART_TABLE_SIZE.LARGE
        : GANTTCHART_TABLE_SIZE.SMALL;
    default:
      return reverse
        ? GANTTCHART_TABLE_SIZE.MEDIUM
        : GANTTCHART_TABLE_SIZE.LARGE;
  }
};

export const getDependencies = (taskId, tasks, visited = new Set()) => {
  const dependencyTree = [];

  const findDependencies = (currentTaskId) => {
    if (visited.has(currentTaskId)) {
      return;
    }
    visited.add(currentTaskId);

    const currentTask = tasks.find((task) => task.id === currentTaskId);
    if (!currentTask || !currentTask.dependency) {
      return;
    }

    const dependencyId = currentTask.dependency;
    dependencyTree.push(dependencyId);

    findDependencies(dependencyId);
  };

  findDependencies(taskId);
  return dependencyTree;
};

export const sortTaskList = (list, vendorTemplateIndexes = {}) => {
  const listWithIndexes = list.map((taskData) => ({
    ...taskData,
    listIndex: vendorTemplateIndexes[taskData.id] || taskData.listIndex,
  }));
  const groupedTasks = {
    PROPOSAL: listWithIndexes
      .filter((item) => item.stage === taskStages.PROPOSAL)
      .sort(sortObjectsBy("listIndex"))
      .map((taskData, index) => ({ ...taskData, listIndex: index + 1 })),
    PRE_PRODUCTION: listWithIndexes
      .filter((item) => item.stage === taskStages.PRE_PRODUCTION)
      .sort(sortObjectsBy("listIndex"))
      .map((taskData, index) => ({ ...taskData, listIndex: index + 1 })),
    PRODUCTION: listWithIndexes
      .filter((item) => item.stage === taskStages.PRODUCTION)
      .sort(sortObjectsBy("listIndex"))
      .map((taskData, index) => ({ ...taskData, listIndex: index + 1 })),
    BOOKING_TRANSIT: listWithIndexes
      .filter((item) => item.stage === taskStages.BOOKING_TRANSIT)
      .sort(sortObjectsBy("listIndex"))
      .map((taskData, index) => ({ ...taskData, listIndex: index + 1 })),
    PAYMENT_BILLING: listWithIndexes
      .filter((item) => item.stage === taskStages.PAYMENT_BILLING)
      .sort(sortObjectsBy("listIndex"))
      .map((taskData, index) => ({ ...taskData, listIndex: index + 1 })),
  };
  return [
    ...groupedTasks.PROPOSAL,
    ...groupedTasks.PRE_PRODUCTION,
    ...groupedTasks.PRODUCTION,
    ...groupedTasks.BOOKING_TRANSIT,
    ...groupedTasks.PAYMENT_BILLING,
  ];
};

export const taskListByStage = (list) => {
  return {
    PROPOSAL: list
      .filter((item) => item.stage === taskStages.PROPOSAL)
      .map((taskData, index) => ({ ...taskData, listIndex: index + 1 })),
    PRE_PRODUCTION: list
      .filter((item) => item.stage === taskStages.PRE_PRODUCTION)
      .map((taskData, index) => ({ ...taskData, listIndex: index + 1 })),
    PRODUCTION: list
      .filter((item) => item.stage === taskStages.PRODUCTION)
      .map((taskData, index) => ({ ...taskData, listIndex: index + 1 })),
    BOOKING_TRANSIT: list
      .filter((item) => item.stage === taskStages.BOOKING_TRANSIT)
      .map((taskData, index) => ({ ...taskData, listIndex: index + 1 })),
    PAYMENT_BILLING: list
      .filter((item) => item.stage === taskStages.PAYMENT_BILLING)
      .map((taskData, index) => ({ ...taskData, listIndex: index + 1 })),
  };
};

export const getStarFinishCalendarDate = ({ startDate, endDate }) => {
  const days = [];
  const startDateCpy = startDate.clone();
  while (startDateCpy.isBefore(endDate)) {
    const startOfWeek = startDateCpy.clone().startOf("isoWeek");
    const endOfWeek = startDateCpy.clone().endOf("isoWeek");
    const day = startDateCpy.format("D");
    const dayCharacter = startDateCpy.format("dd")[0];
    const week = `${startOfWeek.format("MMM")} ${startOfWeek.format(
      "D"
    )} - ${endOfWeek.format("D")}`;
    const month = startDateCpy.format("MMM YYYY");
    const monthLabel = startDateCpy.format("MMMM");
    const quarter = `Q${startDateCpy.format("Q YYYY")}`;
    const isSaturday = startDateCpy.day() === 6;
    days.push({
      day,
      dayCharacter,
      [CALENDAR_SETUP.WEEKLY]: week,
      [CALENDAR_SETUP.MONTHLY]: month,
      monthLabel,
      [CALENDAR_SETUP.QUARTERLY]: quarter,
      fullDate: startDateCpy.format("YYYY-MM-DD"),
      isSaturday,
    });
    startDateCpy.add(1, "days");
  }
  return days;
};

export const getTemplateTaskWithDate = (tasks) => {
  const sortedTasksByNumberAndPhases = builderTasksInPhases({
    tasks: tasks.map((task) => ({
      ...task,
      sortedListIndex: task.listIndex,
    })),
  }).sortedTasksByNumberAndPhases;

  return getTasksWithDates({
    tasks: sortedTasksByNumberAndPhases,
  });
};

const dependencyTypesSS_FS = [
  dependencyTypes.START_START,
  dependencyTypes.FINISH_START,
];

export const changeTaskDuration = ({ tasks, direction, taskId, offset }) => {
  let tasksCpy = [...tasks];
  const movedTask = tasks.find((task) => task.id === taskId);
  const newDuration = parseInt(movedTask.duration) + parseInt(offset);
  if (direction === "left") {
    tasksCpy = tasksCpy.map((task) => {
      if (task.id === taskId) {
        if (dependencyTypesSS_FS.includes(task.dependencyType)) {
          return {
            ...task,
            offset: task.offset - offset,
            duration: newDuration,
          };
        } else {
          return {
            ...task,
            duration: newDuration,
          };
        }
      }
      return task;
    });
  } else {
    tasksCpy = tasksCpy.map((task) => {
      if (task.id === taskId) {
        if (
          dependencyTypesSS_FS.includes(task.dependencyType) ||
          !task.dependencyType ||
          !task.dependency
        ) {
          return {
            ...task,
            duration: newDuration,
          };
        } else {
          return {
            ...task,
            offset: task.offset + offset,
            duration: newDuration,
          };
        }
      }
      return task;
    });
  }
  return tasksCpy;
};

export const getVendorIndex = ({ currentTask, salesOrderTasks, tasks }) => {
  return currentTask.type === TASK_TYPE.SALES_ORDER
    ? salesOrderTasks.findIndex((soTask) => soTask.id === currentTask.id) + 1
    : tasks
        .filter((taskData) => taskData.type !== TASK_TYPE.SALES_ORDER)
        .findIndex((poTask) => poTask.id === currentTask.id) + 1;
};

export const combineAndSortTasksByStage = ({ soTasks, poTasks, stage }) => {
  const soTasksForStage = soTasks
    .filter((item) => item.stage === stage)
    .sort(sortObjectsBy("listIndex"));

  const poTasksForStage = poTasks
    .filter((item) => item.stage === stage)
    .sort(sortObjectsBy("listIndex"));
  const combinedTasksForStage = [...soTasksForStage, ...poTasksForStage];

  return combinedTasksForStage.map((task, index) => ({
    ...task,
    listIndex: index + 1,
  }));
};

export const outdatedTaskSorting = ({ salesOrderTasks, vendorTasks }) => {
  const newTasks = STAGE_LIST.map((stage) =>
    combineAndSortTasksByStage({
      soTasks: salesOrderTasks,
      poTasks: vendorTasks,
      stage,
    })
  );
  return newTasks.flat();
};

export function shouldUpdateVendorTemplate({
  addedTasks,
  deletedTasks,
  fieldChanged,
}) {
  const criticalFields = ["stage", "index"];
  if (addedTasks.length > 0 || deletedTasks.length > 0) {
    return true;
  }
  return fieldChanged.some((field) => criticalFields.includes(field));
}

export function getChangesFromBeforeAndCurrentTasks({
  tasks = [],
  initializedTasks = [],
  type = "",
}) {
  let tasksCpy = [...tasks];
  let initializedTasksCpy = [...initializedTasks];
  if (type === dbTables.FACTORY_TEMPLATE_CHANGE_LOG) {
    tasksCpy = tasksCpy.filter((task) => task.type !== typeOfTask.SALES_ORDER);
    initializedTasksCpy = initializedTasksCpy.filter(
      (task) => task.type !== typeOfTask.SALES_ORDER
    );
  }
  let addedTasks = tasksCpy.filter(
    (task) => !initializedTasksCpy.map((item) => item.id).includes(task.id)
  );
  let deletedTasks = initializedTasksCpy.filter(
    (task) => !tasksCpy.map((item) => item.id).includes(task.id)
  );
  let updatedTasks = tasksCpy.filter((task) =>
    initializedTasksCpy.map((item) => item.id).includes(task.id)
  );

  function getDependencyNameAndIndex({ task = {}, tasks }) {
    const predecesorTask = tasks.find((item) => item.id === task.dependency);
    const prefix = task.type === TASK_TYPE.SALES_ORDER ? "S." : "P.";
    if (!predecesorTask) {
      return {
        fullDescription: "",
        fullIndex: prefix + 1,
        fullpredecesorDescription: "",
        fullPredecesorIndex: "",
      };
    }
    const index = getVendorIndex({
      currentTask: task,
      salesOrderTasks: tasks.filter(
        (item) => item.type === TASK_TYPE.SALES_ORDER
      ),
      tasks,
    });
    console.log("INDEX::: ", index, "TASK::: ", task.description);
    const fullIndex = `${prefix}${index}`;
    const predecesorIndex = getVendorIndex({
      currentTask: predecesorTask,
      salesOrderTasks: tasks.filter(
        (item) => item.type === TASK_TYPE.SALES_ORDER
      ),
      tasks,
    });
    const predecesorPrefix =
      predecesorTask.type === TASK_TYPE.SALES_ORDER ? "S." : "P.";
    const predecesorFullIndex = `${predecesorPrefix}${predecesorIndex}`;

    return {
      fullDescription: fullIndex + " " + task?.description || "",
      fullIndex: fullIndex,
      fullpredecesorDescription:
        predecesorFullIndex + " " + predecesorTask?.description || "",
      fullPredecesorIndex: predecesorFullIndex,
    };
  }

  addedTasks = addedTasks.map((task) => {
    const { fullIndex, fullpredecesorDescription } = getDependencyNameAndIndex({
      task: task,
      tasks,
    });
    return {
      ...task,
      dependencyName: fullpredecesorDescription,
      fullIndex: fullIndex,
    };
  });

  deletedTasks = deletedTasks.map((task) => {
    const { fullpredecesorDescription, fullIndex } = getDependencyNameAndIndex({
      task,
      tasks: initializedTasks,
    });

    return {
      ...task,
      dependencyName: fullpredecesorDescription,
      fullIndex: fullIndex,
    };
  });

  updatedTasks = updatedTasks.map((task) => {
    const { fullIndex, fullpredecesorDescription } = getDependencyNameAndIndex({
      task,
      tasks,
    });
    const initializedTask = initializedTasks.find(
      (item) => item.id === task.id
    );
    const {
      fullIndex: prevFullIndex,
      fullpredecesorDescription: prevFullPredecesorDescription,
    } = getDependencyNameAndIndex({
      task: initializedTask,
      tasks: initializedTasks,
    });

    return {
      ...task,
      dependencyName: fullpredecesorDescription,
      fullIndex: fullIndex,
      prevTask: {
        ...initializedTask,
        dependencyName: prevFullPredecesorDescription,
        fullIndex: prevFullIndex,
      },
    };
  });

  const fieldChanged = [];
  const updatedTasksWithChanges = updatedTasks.filter((task) => {
    const initializedTask = initializedTasks.find(
      (item) => item.id === task.id
    );
    const modifiedFields = [
      "duration",
      "dependency",
      "dependencyType",
      "assignedTo",
      "description",
      "offset",
      "stage",
      "userNotificationEarly",
      "userNotificationLate",
      "index",
      "type",
      "listIndex",
      "number",
    ];
    let hasChanged = false;
    modifiedFields.forEach((field) => {
      let fieldHasChanged;
      if (
        field === "userNotificationEarly" ||
        field === "userNotificationLate"
      ) {
        fieldHasChanged = !isEqual(task[field], initializedTask[field]);
      } else {
        fieldHasChanged = task[field] !== initializedTask[field];
      }
      if (fieldHasChanged) {
        fieldChanged.push(field);
      }
      if (field !== "index") {
        hasChanged = hasChanged || fieldHasChanged;
      }
    });
    return hasChanged;
  });
  return {
    addedTasks,
    deletedTasks,
    updatedTasks: updatedTasksWithChanges,
    fieldChanged,
  };
}
