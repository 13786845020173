import React from "react";
import CustomButton from "../Buttons/CustomButton";
import { Grid, TextareaAutosize } from "@mui/material";
import { CategoryFormStyled } from "./styles";
import { categoryFields } from "../../helpers/constants";

function CreateCategoryForm({
  categoryItem = {},
  isUpdating,
  onChange,
  onSubmit = () => {},
  handleClose,
}) {
  return (
    <CategoryFormStyled
      onSubmit={(ev) => {
        onSubmit({
          ev,
          currentCategoryItem: { ...categoryItem },
        });
        ev.preventDefault();
      }}
      onKeyDown={(ev) => {
        if (ev.key === "Escape") {
          handleClose();
        }
      }}
      className="categoryForm"
    >
      <Grid container alignItems="center" justifyContent="center">
        <div className={"formContainer"}>
          <label>Category</label>
          <input
            id={"category-id-" + categoryItem.id}
            style={{
              width: 350,
              height: 36,
            }}
            required={true}
            value={categoryItem[categoryFields.NAME] || ""}
            onChange={(ev) =>
              onChange({
                value: ev.target.value,
                field: categoryFields.NAME,
              })
            }
            autoFocus
          />
        </div>
      </Grid>
      <Grid container alignItems="center" justifyContent="center">
        <div className={"formContainer"}>
          <label>Description</label>
          <TextareaAutosize
            value={categoryItem[categoryFields.DESCRIPTION]}
            onChange={(ev) =>
              onChange({
                value: ev.target.value,
                field: categoryFields.DESCRIPTION,
              })
            }
            placeholder="Category description"
            required={false}
            minRows={2}
            maxLength={150}
          />
        </div>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Grid item xs={10}>
          <CustomButton
            styleContainer={{
              marginLeft: "81%",
            }}
            type="success"
          >
            {isUpdating ? "generic.text.update" : "generic.text.create"}
          </CustomButton>
        </Grid>
        <Grid item xs={2}>
          <CustomButton type="cancel" onClick={handleClose} noSubmit>
            {"generic.text.cancel"}
          </CustomButton>
        </Grid>
      </Grid>
    </CategoryFormStyled>
  );
}

export default CreateCategoryForm;
