import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { userFields } from "../../helpers/constants";
import { validateEmail } from "../../helpers/helpers";
import { colors } from "../../assets/jss/variables";
import {
  Autocomplete,
  FormHelperText,
  InputAdornment,
  TextField,
} from "@mui/material";
import { cx } from "@emotion/css";

const TYPE_ERROR = {
  EMPTY: "empty",
  SAVE_ERROR: "saveError",
  EMAIL: "email",
  DOMAIN: "domain",
};

function TypeEditableLabel({
  item = {},
  onSave,
  userValue,
  currentUser,
  searchSameValue = () => {},
  dataList = [],
  company,
}) {
  const [error, setError] = useState({ type: "", hasError: false });
  const [value, setValue] = useState(userValue);
  const [isPopperOpen, setPopperOpen] = useState(false);
  const similarValue = searchSameValue(value);
  const hasError = error.hasError || similarValue || !value;

  const [editing, setEditing] = useState(false);

  const handleSave = async () => {
    if (hasError || value === userValue) {
      return setEditing(false);
    }
    if (editing) {
      console.log("send");
      const saved = await onSave(value, item);
      if (!saved) {
        setError({ hasError: true, type: TYPE_ERROR.SAVE_ERROR });
      }
      setEditing(false);
    }
  };

  const handleSaveSelect = async () => {
    if (value && userValue && value.name === userValue.name) {
      setPopperOpen(false);
      return;
    }
    if (value && value.utc) {
      await onSave(value, item);
      setPopperOpen(false);
    } else {
      setError({ hasError: true, type: TYPE_ERROR.EMPTY });
      setPopperOpen(false);
    }
  };

  const handleChangeSelect = (value) => {
    setValue(value);
  };

  const handleChange = (ev) => {
    const value = ev.target.value;
    setValue(value.replace(/\s\s+/g, " "));
    let typeError = false;
    if (!value || value === "+") {
      typeError = TYPE_ERROR.EMPTY;
    }
    if (item.key === userFields.EMAIL) {
      const domain = value.substring(value.lastIndexOf("@") + 1) || "";
      const companyDomain = company.domain || "";
      if (!validateEmail(value)) {
        typeError = TYPE_ERROR.EMAIL;
      } else if (domain.toLowerCase() !== companyDomain.toLowerCase()) {
        typeError = TYPE_ERROR.DOMAIN;
      }
    }
    if (typeError) {
      const { type } = error;
      if (type !== typeError) {
        setError({ hasError: true, type: typeError });
      }
    } else {
      setError({ hasError: false, type: "" });
    }
  };

  const handleChangePhone = (number, event) => {
    let parseNumber = number;
    let typeError = false;
    if (!parseNumber.includes("+")) {
      parseNumber = `+${parseNumber}`;
    }
    if (!parseNumber || parseNumber === "+") {
      typeError = TYPE_ERROR.EMPTY;
    }
    setValue(parseNumber);
    if (!event.nativeEvent && !typeError) {
      handleSave();
    }
    if (typeError) {
      const { hasError, type } = error;
      if (!hasError && type !== typeError) {
        setError({ hasError: true, type: typeError });
      }
    } else {
      setError({ hasError: false, type: "" });
    }
    return setEditing(true);
  };

  const getHelperError = () => {
    let errorMessage = item.errorMessage || "";
    if (item.specialError) {
      const firstName =
        item.key === userFields.FIRST_NAME
          ? value
          : currentUser.firstName || "";
      const lastName =
        item.key === userFields.LAST_NAME ? value : currentUser.lastName || "";
      const fullNameUser = `${firstName} ${lastName}`;
      errorMessage = errorMessage.replace("{FullName}", fullNameUser);
    }
    if (similarValue) {
      return `${errorMessage}`;
    }
    if (!error.hasError) {
      return " ";
    }
    switch (error.type) {
      case TYPE_ERROR.EMPTY:
        return "Value is required";
      case TYPE_ERROR.SAVE_ERROR:
        return errorMessage;
      case TYPE_ERROR.EMAIL:
        return errorMessage;
      case TYPE_ERROR.DOMAIN:
        return errorMessage;
      default:
        return "";
    }
  };
  if (item.type === "phone") {
    return (
      <React.Fragment>
        <PhoneInput
          onFocus={() => setEditing(true)}
          onBlur={handleSave}
          className={cx(
            {
              phoneInputError: hasError,
            },
            "phoneInput"
          )}
          size="small"
          dropdownStyle={{
            height: 200,
            top: 45,
            width: 170,
          }}
          onKeyDown={(ev) => {
            const { key } = ev;
            if (key === "Enter") {
              handleSave();
            }
          }}
          specialLabel={
            hasError ? (
              "ERROR"
            ) : (
              <React.Fragment>
                <span style={{ color: "red" }}>*</span>
                {item.label}
              </React.Fragment>
            )
          }
          value={userValue}
          onChange={(number, data, event) => {
            handleChangePhone(number, event);
          }}
          inputProps={{
            autoFocus: true,
            color: colors.primaryDark,
          }}
        />
        <FormHelperText
          style={{
            color: colors.diamondRed,
            fontSize: 9,
            position: "absolute",
            marginTop: 60,
            marginLeft: 5,
          }}
        >
          {getHelperError()}
        </FormHelperText>
      </React.Fragment>
    );
  }

  if (item.type === "select") {
    return (
      <React.Fragment>
        <Autocomplete
          size="small"
          value={value || null}
          open={isPopperOpen}
          onBlur={() => handleSaveSelect()}
          id={"timezoneAutocomplete"}
          className={cx(
            {
              timezoneAutocompleteError: hasError,
            },
            "timezoneAutocomplete"
          )}
          options={dataList}
          getOptionLabel={(option) => {
            return option.name || "";
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={
                hasError ? (
                  "ERROR"
                ) : (
                  <React.Fragment>{item.label}</React.Fragment>
                )
              }
              variant="outlined"
              onClick={() => setPopperOpen(!isPopperOpen)}
            />
          )}
          classes={{
            option: "option",
            listbox: "listbox",
            popper: "timezonePopper",
          }}
          onChange={(ev, value) => {
            handleChangeSelect(value);
            setPopperOpen(false);
          }}
        />
        <FormHelperText
          style={{
            color: colors.diamondRed,
            fontSize: 9,
            position: "absolute",
            marginTop: 55,
            marginLeft: 5,
          }}
        >
          {getHelperError()}
        </FormHelperText>
      </React.Fragment>
    );
  }

  return (
    <TextField
      variant="outlined"
      helperText={getHelperError()}
      className={cx(
        { errorInformationField: hasError },
        "userInformationField"
      )}
      label={
        hasError ? (
          "ERROR"
        ) : (
          <React.Fragment>
            <span style={{ color: "red" }}>* </span>
            {item.label}
          </React.Fragment>
        )
      }
      size="small"
      onFocus={() => setEditing(true)}
      onBlur={handleSave}
      value={value}
      InputProps={{
        style: {
          fontSize: 14,
          height: 35,
          color: colors.primaryDark,
          paddingLeft: 7,
          textTransform: userValue.EMAIL ? "lowercase" : "",
        },
        startAdornment: item.adornment ? (
          <InputAdornment position="start" style={{ margin: 0 }}>
            {item.adornment}
          </InputAdornment>
        ) : (
          <span />
        ),
      }}
      onChange={handleChange}
      onKeyDown={(ev) => {
        if (ev.key === "Enter") {
          handleSave();
        }
        if (ev.key === "Escape") {
          setValue(userValue);
          setError({ type: "", hasError: false });
        }
      }}
    />
  );
}

export default TypeEditableLabel;
