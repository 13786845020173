import React, { useCallback, useEffect, useState } from "react";
import {
  convertToTreeRefactored,
  getIdFromStructureFolders,
  getPathDocumentLocalStorage,
  sortObjectsBy,
  useLocationPath,
} from "../../helpers/helpers";
import DocumentItem from "./DocumentItem";
import { dbTables } from "../../api/types/dbTables";
import {
  useCompanyId,
  useJSONLocalStorage,
  usePermissionGroups,
} from "../../hooks";
import { useCompanyUsers, useUser } from "../../hooks/user";
import { getDocumentsDescendants } from "../ReportsTree/helpers";
import { ManagementDocumentsStyled } from "./styles";
import ManagementDocumentsHeader from "../Documents/ManagementDocumentsHeader";
import { addGenericDataToFirestore } from "../../actions/DataActions";
import { useDispatch } from "react-redux";
import DragAndDropUploadFileV2 from "../Documents/DragAndDropUploadFileV2";
import PendingDivider from "../Dividers/PendingDivider";
import { TYPE_OF_FILE } from "../../helpers/constants";
import AttentionModal from "../Modal/AttentionModal";
import { isOpenedFolder } from "../../helpers/documentsv2.helper";
import {
  filtersDocumentsPermission,
  getDocumentContainerClass,
} from "../../helpers/documents";
import { useIsAllowedFunction } from "../../hooks/permissions";
import Mark from "mark.js";
import { ClickAwayListener } from "@mui/material";

const IDENTATION = 60;

function ManagementDocumentsV2({
  documents = [],
  dbCollection,
  parentCollection,
  parentDocumentId,
  mainEntity = {},
  isVoided = false,
  isReadOnly = false,
  permissionScope,
  permissionGroupsDB,
}) {
  const dispatch = useDispatch();
  const companyId = useCompanyId();
  const allowed = useIsAllowedFunction();
  const documentPath = `${dbTables.COMPANIES}/${companyId}/${parentCollection}/${parentDocumentId}/${dbCollection}`;
  const path = useLocationPath();
  const pathLocalStorage = `${getPathDocumentLocalStorage(
    path
  )}DocumentStructure`;
  const { get, set } = useJSONLocalStorage(pathLocalStorage);
  const [selected, setSelected] = useState({
    parentId: null,
    documents: [],
    hiddenMenu: false,
  });
  const [draggingTo, setDraggingTo] = useState(null);
  const [sortingDocument, setSortingDocument] = useState({
    sortBy: "name",
    asc: false,
  });
  const [openedFolders, setOpenedFolders] = useState({});
  const [search, setSearch] = useState("");
  const [attentionModal, setAttentionModal] = useState({
    open: false,
    description: "",
    action: () => {},
    confirmationText: "",
    cancelText: "",
  });
  const user = useUser();
  const companyUsers = useCompanyUsers({
    id: companyId,
    showBotUser: false,
    showInactiveUsers: false,
  });
  const permissionGroups = usePermissionGroups({
    companyId,
    filteringByInactive: true,
  });
  const filteredDocuments = filtersDocumentsPermission({
    allowed,
    documents,
    permissionGroupsDB,
    permissionScope: permissionScope,
    user,
    search,
  });

  const documentsTree = convertToTreeRefactored({
    documents: filteredDocuments,
  });

  const addDocument = useCallback((document) =>
    addGenericDataToFirestore(
      [
        dbTables.COMPANIES,
        companyId,
        parentCollection,
        parentDocumentId,
        dbCollection,
        document.id,
      ],
      document
    )(dispatch)
  );

  const isAllFoldersOpen = () => {
    const nonPlaceholderDocuments = filteredDocuments.filter(
      (doc) => !doc.placeholder && doc.type === TYPE_OF_FILE.FOLDER
    );
    nonPlaceholderDocuments.every((doc) => openedFolders[doc.id]);
    return nonPlaceholderDocuments.every((doc) => openedFolders[doc.id]);
  };

  function handleAddNewFile() {
    if (isReadOnly || isVoided) {
      // handleReadOnlyModal();
      return;
    }
    const inputDrag = document.getElementById("principalDropOption");
    if (inputDrag) {
      inputDrag.click();
    }
  }

  const openCloseAllFolders = (value) => {
    let openFoldersIds = {};
    let localStorageData = get() || {};
    const nonPlaceholderDocuments = filteredDocuments.filter(
      (doc) => !doc.placeholder && doc.type === TYPE_OF_FILE.FOLDER
    );
    const placeholderDocuments = filteredDocuments.filter(
      (doc) => doc.placeholder && doc.type === TYPE_OF_FILE.FOLDER
    );
    placeholderDocuments.forEach(
      (doc) => (openFoldersIds[doc.id] = !!openedFolders[doc.id])
    );

    if (value) {
      const idsList = getIdFromStructureFolders(nonPlaceholderDocuments);
      idsList.forEach(
        (id) => (openFoldersIds = { ...openFoldersIds, [id]: true })
      );
    }
    set({
      ...localStorageData,
      [parentDocumentId]: openFoldersIds,
    });
    setOpenedFolders(openFoldersIds);
  };

  function recursiveDocuments({
    document = {},
    childNumber = 0,
    isRoot = false,
    isPlaceholder = false,
  }) {
    const { child = [], type } = document;
    let sortBy = sortingDocument.sortBy;
    let asc = sortingDocument.asc;
    if (type !== TYPE_OF_FILE.FOLDER) {
      sortBy = "version";
      asc = true;
    }
    const parentDocument = documents.find(
      (currentDocument) => currentDocument.id === document.id
    );

    const childrenSize =
      document.type === TYPE_OF_FILE.FOLDER
        ? getDocumentsDescendants({
            documents: filteredDocuments,
            originId: document.id,
          }).length
        : document.versions?.length;

    return (
      <div
        key={document.id + "active" + (document.version || 1)}
        style={{
          marginLeft: isRoot ? 0 : IDENTATION,
          width: "-webkit-fill-available",
        }}
        id={`documentItem_${document.id}`}
        className={getDocumentContainerClass({
          openedFolders,
          document,
          search,
          isMultiSelect: !!selected.parentId,
        })}
      >
        <DocumentItem
          currentDocument={document}
          childNumber={childNumber}
          selected={selected}
          setSelected={setSelected}
          draggingTo={draggingTo}
          setDraggingTo={setDraggingTo}
          documents={documents}
          onOpenFolder={(documentId, forceOpen = false) => {
            setOpenedFolders((oldValue) => ({
              ...oldValue,
              [documentId]: forceOpen || !openedFolders[documentId],
            }));
          }}
          isRoot={isRoot}
          isOpen={isOpenedFolder({ openedFolders, selected, document, search })}
          permissionGroupsDB={permissionGroups}
          currentUser={user}
          users={companyUsers}
          dbCollection={dbCollection}
          parentCollection={parentCollection}
          parentDocumentId={parentDocumentId}
          mainEntity={mainEntity}
          isVoided={isVoided}
          isReadOnly={isReadOnly}
          parentDocument={parentDocument}
          isPlaceholder={isPlaceholder}
          sortBy={sortingDocument.sortBy}
          onSelected={({ parentId, documents }) => {
            setSelected({ parentId, documents });
          }}
          onModalAttention={({
            open,
            description,
            action,
            confirmationText,
            cancelText,
          }) => {
            setAttentionModal({
              open,
              description,
              action,
              confirmationText,
              cancelText,
            });
          }}
        />
        <div
          className="children-container"
          style={{
            maxHeight: isOpenedFolder({
              openedFolders,
              selected,
              document,
              search,
            })
              ? childrenSize * 190
              : "0px",
            overflow: "hidden",
            transition: "max-height 1s ease-in-out",
          }}
        >
          {child.sort(sortObjectsBy(sortBy, asc)).map((cDocument) =>
            recursiveDocuments({
              document: cDocument,
              childNumber: childNumber + 1,
              isPlaceholder,
            })
          )}
        </div>
      </div>
    );
  }

  const markInstance = new Mark(
    document.getElementById("document-list-container")
  );
  function performMark(keyword = "") {
    var options = {
      separateWordSearch: false,
      diacritics: false,
      debug: false,
      acrossElements: true,
      accuracy: "partially",
      exclude: [
        ".document-creation-date-content > *",
        ".document-created-by-content > *",
        ".created-by-name",
        ".created-by-name > *",
        ".versionLabel",
      ],
    };
    markInstance.unmark({
      done: () => {
        setTimeout(() => {
          markInstance.mark(keyword, options);
        }, 300);
      },
    });
  }

  useEffect(() => {
    performMark(search);
  }, [search]);

  const placeholderTree = documentsTree.filter((doc) => doc.placeholder);
  const regularTree = documentsTree.filter((doc) => !doc.placeholder);

  return (
    <ManagementDocumentsStyled className="documents-panel-container">
      {attentionModal.open && (
        <AttentionModal
          title="Attention"
          isOpen={attentionModal.open}
          description={attentionModal.description}
          onClick={(ev) => {
            attentionModal.action();
            setAttentionModal({
              open: false,
              description: "",
              action: () => {},
              confirmationText: "",
              cancelText: "",
            });
            ev.stopPropagation();
          }}
          onClose={(ev) => {
            setAttentionModal({
              open: false,
              description: "",
              action: () => {},
              confirmationText: "",
              cancelText: "",
            });
            ev.stopPropagation();
          }}
          cancellable={!!attentionModal.cancelText}
          confirmationText={attentionModal.confirmationText}
          cancelText={attentionModal.cancelText}
          styleModal={{
            maxWidth: "calc(100vw - 50%)",
          }}
        />
      )}
      <div className="stickyHeader">
        <ManagementDocumentsHeader
          allowMultipleFiles
          handleExpandOrCollapse={(value) => {
            openCloseAllFolders(value);
          }}
          isExpand={isAllFoldersOpen()}
          handleAddNewFile={handleAddNewFile}
          documentPath={documentPath}
          onQuerySearch={(value) => setSearch(value)}
          disabled={isVoided}
          allDocuments={documents}
          parentCollection={parentCollection}
          handleSorting={setSortingDocument}
          sortBy={sortingDocument.sortBy}
        />
      </div>
      <DragAndDropUploadFileV2
        companyId={companyId}
        parentCollection={parentCollection}
        parentDocumentId={parentDocumentId}
        dbCollection={dbCollection}
        documents={documents}
        onAddNewFile={addDocument}
        isReadOnly={isReadOnly}
        orderIsVoided={isVoided}
        documentPath={documentPath}
        mainEntity={mainEntity}
      />
      <ClickAwayListener
        onClickAway={() => {
          if (selected.parentId) {
            setSelected({
              parentId: null,
              documents: [],
              hiddenMenu: false,
            });
          }
        }}
      >
        <div
          className="documentsList"
          style={{ height: "calc(100vh - 325px)" }}
          id="document-list-container"
        >
          {placeholderTree
            .sort(sortObjectsBy(sortingDocument.sortBy, sortingDocument.asc))
            .map((rootDocument) => {
              return recursiveDocuments({
                document: { ...rootDocument },
                childNumber: 0,
                isRoot: true,
                isPlaceholder: true,
              });
            })}

          {placeholderTree.length > 0 && (
            <div className="dividerPlaceholderDocuments">
              <PendingDivider
                text="Pending Placeholder Items"
                className={`divider_${parentCollection}`}
                infoLabel={
                  "Folders above are empty or have at least one placeholder file pending to upload"
                }
              />
            </div>
          )}
          {regularTree
            .sort(sortObjectsBy(sortingDocument.sortBy, sortingDocument.asc))
            .map((rootDocument) => {
              return recursiveDocuments({
                document: { ...rootDocument },
                childNumber: 0,
                isRoot: true,
              });
            })}
        </div>
      </ClickAwayListener>
    </ManagementDocumentsStyled>
  );
}

export default ManagementDocumentsV2;
