import React, { useState, useEffect } from "react";
import TooltipTD from "../Tooltip/TooltipTD";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Clear";
import BlockIcon from "@mui/icons-material/Block";
import AutoCompleteTag from "../AutoCompleteSelect/AutoCompleteTag";
import Tag from "../../api/model/tag";
import { dbTables } from "../../api/types/dbTables";
import { useCompanyId } from "../../hooks";
import { useIsAllowedFunction } from "../../hooks/permissions";
import AttentionModal from "./AttentionModal";
import { sortObjectsBy } from "../../helpers/helpers";
import ReadOnlyModal from "./ReadOnlyModal";
import { tagsByType, tagsExcludes } from "../../hooks/tags";
import {
  AUTOMATIC_SYSTEM_LABEL,
  PO_STATUS,
  TYPE_OF_TAGS,
} from "../../helpers/constants";
import { firestore } from "../../firebase";
import {
  salesOrderTriggerTypes,
  TAG_COLOR,
  TAG_STATUS,
} from "../../helpers/salesOrder";
import { LabelSVGIcon } from "../../helpers/iconSvgPath";
import { purchaseOrderTriggerTypes } from "../../helpers/purchaseOrder";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { isEqual } from "lodash";
import { cx } from "@emotion/css";
import { Button, Divider } from "@mui/material";
import { PopoverTagStyled, TagLabelStyled } from "./style";

function TagsModal({
  label,
  tags,
  allTags = [],
  item,
  currentUser,
  onUpdateItem,
  imageStyle = { height: 16, width: 14 },
  isThereItemRef = false,
  isReadOnly = false,
  tagReference = dbTables.SALES_ORDERS,
  containerStyle = {},
  setIsModalOpen = () => {},
  tooltipPlacement = "top-start",
  status = "",
  isWhiteIcon = false,
  classContainer = "",
  isDisabled = false,
}) {
  const isAllowed = useIsAllowedFunction();
  const [openReadOnlyModal, setReadOnlyModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentTags, setCurrentTags] = useState([...tags]);
  const [newTagName, setNewTagName] = useState("");
  const [openModalAttention, setOpenModalAttention] = useState(false);
  const [descriptionModalAttention, setDescriptionModalAttention] = useState(
    <React.Fragment></React.Fragment>
  );
  useEffect(() => {
    if (
      !isEqual(
        currentTags.map((cTag) => cTag.id),
        tags.map((tag) => tag.id)
      )
    ) {
      setCurrentTags([...tags]);
    }
  }, [tags]);

  useEffect(() => {
    setIsModalOpen(!!anchorEl);
  }, [anchorEl]);

  const companyId = useCompanyId();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    event.preventDefault();
    event.stopPropagation();
  };

  async function handleKeyDown(ev) {
    console.log("HANDLE KEY UPP...", ev.key);
    if (ev.key === "Enter") {
      onAddNewTag(ev);
    }
    if (ev.key === "Escape") {
      handleClose(ev);
    }
    ev.preventDefault();
    ev.stopPropagation();
  }

  async function onAddNewTag(ev) {
    if (isReadOnly) {
      setReadOnlyModal(true);
      return;
    }
    if (newTagName.trim() === "") {
      ev.preventDefault();
      ev.stopPropagation();
      return;
    }
    if (newTagName.trim().length > 40) {
      setDescriptionModalAttention("Label name cannot exceed 40 characters");
      setOpenModalAttention(true);
      setNewTagName(newTagName.slice(0, 40));
      return;
    }

    const parseNewTagName = newTagName.trim().toLowerCase();
    const hiddenTag = tagsExcludes(allTags, [TYPE_OF_TAGS.REGULAR_TAG]);

    const automaticSystemLabels = [
      AUTOMATIC_SYSTEM_LABEL.SALES_ORDER_MISMATCH.toLowerCase(),
      AUTOMATIC_SYSTEM_LABEL.SHIPMENT_MISMATCH.toLowerCase(),
      AUTOMATIC_SYSTEM_LABEL.SALES_ORDER_FILE.toLowerCase(),
      AUTOMATIC_SYSTEM_LABEL.PURCHASE_ORDER_FILE.toLowerCase(),
      AUTOMATIC_SYSTEM_LABEL.SHIPMENT_FILE.toLowerCase(),
      AUTOMATIC_SYSTEM_LABEL.TASK_DEADLINE.toLowerCase(),
    ];

    if (
      hiddenTag.length === 0 ||
      automaticSystemLabels.includes(parseNewTagName)
    ) {
      setDescriptionModalAttention("You can't create a label with this name");
      setOpenModalAttention(true);
      setNewTagName("");
      return;
    }

    const tag = tagsByType(allTags, TYPE_OF_TAGS.REGULAR_TAG).find(
      (tag) => tag.name.toLowerCase() === newTagName.trim().toLowerCase()
    );

    if (!tag) {
      if (!isAllowed("create_tags")) {
        onOpenModalAttention("create tags");
        return;
      }
      const newTag = new Tag({
        name: newTagName.trim(),
        createdBy: currentUser.id,
      });
      await setDoc(
        doc(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${dbTables.TAGS}/${newTag.id}`
        ),
        { ...newTag }
      );
      const currentTagsCpy = [...currentTags];
      currentTagsCpy.push({ ...newTag });
      setCurrentTags(currentTagsCpy);
      setNewTagName("");
      ev.preventDefault();
      ev.stopPropagation();
    } else if (tag && !currentTags.find((cTag) => cTag.id === tag.id)) {
      const currentTagsCpy = [...currentTags];
      currentTagsCpy.push(tag);
      setCurrentTags(currentTagsCpy);
      setNewTagName("");
      ev.preventDefault();
      ev.stopPropagation();
    } else {
      setNewTagName("");
      ev.preventDefault();
      ev.stopPropagation();
    }
  }

  function handleTextFieldChange(value) {
    setNewTagName(value);
  }

  function onSaveTags(currentTags, item) {
    if (isReadOnly) {
      setReadOnlyModal(true);
      return;
    }
    const companyRef = `${dbTables.COMPANIES}/${companyId}`;
    let newTags = [];
    currentTags.forEach((tag) => newTags.push(tag.id));
    if (!isThereItemRef) {
      if (tagReference === dbTables.SALES_ORDERS) {
        updateDoc(
          doc(firestore, `${companyRef}/${dbTables.SALES_ORDERS}/${item.id}`),
          {
            tags: newTags,
            updated_by: currentUser.id,
            triggerType: salesOrderTriggerTypes.TAGS,
          }
        );

        onUpdateItem(
          { ...item, tags: newTags, updated_by: currentUser.id },
          tagReference
        );
      } else if (tagReference === dbTables.PURCHASE_ORDERS) {
        updateDoc(
          doc(
            firestore,
            `${companyRef}/${dbTables.PURCHASE_ORDERS}/${item.id}`
          ),
          {
            tags: newTags,
            updated_by: currentUser.id,
            triggerType: purchaseOrderTriggerTypes.TAGS,
          }
        );
        onUpdateItem(
          { ...item, tags: newTags, updated_by: currentUser.id },
          tagReference
        );
      } else if (tagReference === dbTables.SHIPMENTS) {
        updateDoc(
          doc(firestore, `${companyRef}/${dbTables.SHIPMENTS}/${item.id}`),
          {
            tags: newTags,
            updated_by: currentUser.id,
            triggerType: salesOrderTriggerTypes.TAGS,
          }
        );
        onUpdateItem(
          { ...item, tags: newTags, updated_by: currentUser.id },
          tagReference
        );
      }
    } else {
      updateDoc(item.ref, {
        tags: newTags,
        updated_by: currentUser.id,
        triggerType: salesOrderTriggerTypes.TAGS,
      });
    }
    setAnchorEl(null);
  }

  function onDeleteTag(tagId) {
    if (isReadOnly) {
      setReadOnlyModal(true);
      return;
    }
    let currentTagsCpy = [...currentTags];
    currentTagsCpy = currentTagsCpy.filter((tag) => tag.id !== tagId);
    setCurrentTags(currentTagsCpy);
  }

  function onOpenModalAttention(value) {
    setDescriptionModalAttention(
      <React.Fragment>You do not have permission to {value}</React.Fragment>
    );
    setOpenModalAttention(true);
  }

  function handleClose(ev) {
    console.log("HANDLE CLOSE TAG...");
    setNewTagName("");
    if (!isReadOnly) {
      onSaveTags(currentTags, item);
    } else {
      setAnchorEl(null);
    }
    ev.preventDefault();
    ev.stopPropagation();
  }

  const popperCustomized = () => {
    const mismatchTags = currentTags.filter(
      (tag) => tag.type === TYPE_OF_TAGS.MISMATCH_TAG
    );
    const fileTags = currentTags.filter(
      (tag) => tag.type === TYPE_OF_TAGS.FILE_TAG
    );
    const deadLineTag = currentTags.filter(
      (tag) => tag.type === TYPE_OF_TAGS.DEADLINE_TAG
    );
    const expensesTag = currentTags.filter(
      (tag) => tag.type === TYPE_OF_TAGS.EXPENSES_TAG
    );
    const disabled = item.status === PO_STATUS.VOIDED || isDisabled;

    return (
      <PopoverTagStyled
        key={item.id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        style={{ zIndex: 1200 }}
        classes={{ paper: "rootPoppover" }}
        onClose={handleClose}
        onClick={(ev) => ev.stopPropagation()}
      >
        <div className="popperContainer">
          <div className="popperHeader">
            <span className="popperTitle">Labels</span>
            <DeleteIcon className="closePopperIcon" onClick={handleClose} />
          </div>
          {!disabled && <div className="divider" />}
          <div className="popperContent">
            {!disabled && (
              <div className="addNewTagContainer">
                <div
                  className="autocompleteTagWrapper"
                  onClick={(ev) => {
                    if (!isAllowed("assign_tags")) {
                      onOpenModalAttention("assign tags");
                    }
                    ev.preventDefault();
                    ev.stopPropagation();
                  }}
                >
                  <AutoCompleteTag
                    tags={tagsByType(allTags, TYPE_OF_TAGS.REGULAR_TAG)
                      .filter(
                        (tag) => !currentTags.find((cTag) => cTag.id === tag.id)
                      )
                      .sort(sortObjectsBy("name", false))}
                    onKeyDown={handleKeyDown}
                    onTextFieldChange={handleTextFieldChange}
                    textFieldValue={newTagName}
                    isAllowed={isAllowed}
                    onOpenModal={(value) => {
                      onOpenModalAttention(value);
                    }}
                  />
                </div>
                <TooltipTD
                  label="You do not have permission to create labels"
                  placement={tooltipPlacement}
                  showToolTip={!isAllowed("create_tags")}
                >
                  <Button
                    className={cx("addNewTagButton", {
                      addNewTagButtonDissabled: !isAllowed("create_tags"),
                    })}
                    onClick={(ev) => {
                      if (isAllowed) {
                        onAddNewTag(ev);
                      } else {
                        onOpenModalAttention("create tags");
                      }
                      ev.preventDefault();
                      ev.stopPropagation();
                    }}
                  >
                    +
                  </Button>
                </TooltipTD>
              </div>
            )}
            <div className="assignedTagsContainer">
              {tagsByType(currentTags, TYPE_OF_TAGS.REGULAR_TAG).map((tag) => {
                return (
                  <div className="tagContent" key={tag.id}>
                    <div className="tagName">{tag.name}</div>
                    <div className="deleteTagContainer">
                      {isAllowed("un-assign_tags") ? (
                        <DeleteIcon
                          className="deleteTagIcon"
                          onClick={(ev) => {
                            onDeleteTag(tag.id);
                            ev.preventDefault();
                            ev.stopPropagation();
                          }}
                        />
                      ) : (
                        <TooltipTD
                          label="You do not have permission to delete labels"
                          style={{ display: "flex" }}
                          placement={tooltipPlacement}
                        >
                          <BlockIcon
                            className="deleteTagIcon"
                            onClick={(ev) => {
                              onOpenModalAttention("delete tags");
                              ev.preventDefault();
                              ev.stopPropagation();
                            }}
                          />
                        </TooltipTD>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            {tagsExcludes(currentTags, [TYPE_OF_TAGS.REGULAR_TAG]).length >
              0 && (
              <React.Fragment>
                <Divider style={{ height: 0 }} className="divider" />
                <div className="infoText">
                  <TooltipTD
                    label={
                      "These labels are assigned automatically by the system when there is a mismatch in the item quantities"
                    }
                    style={{ display: "flex" }}
                    placement={tooltipPlacement}
                  >
                    <InfoIcon className={"infoIcon"} />
                  </TooltipTD>
                  Automatic System Labels
                </div>
                <div className="automaticTagsContainer">
                  {mismatchTags.map((tag) => {
                    return (
                      <div className="tagContent" key={tag.id}>
                        <div className="tagName">{tag.name}</div>
                      </div>
                    );
                  })}
                  {mismatchTags.length > 0 && fileTags.length > 0 && (
                    <Divider style={{ height: 0 }} className="divider" />
                  )}
                  {fileTags.map((tag) => {
                    return (
                      <div className="tagContent" key={tag.id}>
                        <div className="tagName">{tag.name}</div>
                      </div>
                    );
                  })}
                  {(mismatchTags.length > 0 || fileTags.length > 0) &&
                    deadLineTag.length > 0 && (
                      <Divider style={{ height: 0 }} className="divider" />
                    )}
                  {deadLineTag.map((tag) => {
                    return (
                      <div className="tagContent" key={tag.id}>
                        <div className="tagName">{tag.name}</div>
                      </div>
                    );
                  })}
                  {(mismatchTags.length > 0 ||
                    fileTags.length > 0 ||
                    deadLineTag.length > 0) &&
                    expensesTag.length > 0 && (
                      <Divider style={{ height: 0 }} className="divider" />
                    )}
                  {expensesTag.map((tag) => {
                    return (
                      <div className="tagContent" key={tag.id}>
                        <div className="tagName">{tag.name}</div>
                      </div>
                    );
                  })}
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </PopoverTagStyled>
    );
  };
  return (
    <React.Fragment key={item.id + item.number + "containerTag"}>
      {openReadOnlyModal && (
        <ReadOnlyModal
          isOpen={openReadOnlyModal}
          onClick={() => setReadOnlyModal(false)}
          onClose={() => setReadOnlyModal(false)}
        />
      )}
      {openModalAttention && (
        <AttentionModal
          isOpen={openModalAttention}
          description={descriptionModalAttention}
          title="Attention"
          onClick={(ev) => {
            setOpenModalAttention(false);
            setDescriptionModalAttention(<React.Fragment></React.Fragment>);
            ev.preventDefault();
            ev.stopPropagation();
          }}
        />
      )}
      <TagLabelStyled
        key={item.id}
        className={cx("tagContainer", classContainer)}
        style={{ ...containerStyle }}
      >
        <TooltipTD
          label={label}
          showToolTip={status !== TAG_STATUS.WITHOUT_TAGS}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <LabelSVGIcon
            onClick={handleClick}
            style={{ ...imageStyle }}
            svgClass={
              status === TAG_STATUS.NO_FILTER ||
              status === TAG_STATUS.WITHOUT_TAGS
                ? "hideShowTags"
                : "showTags"
            }
            color={TAG_COLOR[status]}
            fill={isWhiteIcon ? "#FFF" : TAG_COLOR[status]}
          />
        </TooltipTD>
        {open && popperCustomized()}
      </TagLabelStyled>
    </React.Fragment>
  );
}

export default TagsModal;
