import React, { useEffect } from "react";
import { useCompanyId } from "../../hooks";
import { Outlet, useNavigate, useParams } from "react-router";
import { isSuperAdmin, isTradeDashEmployee } from "../../helpers/helpers";
import { useUser } from "../../hooks/user";
import { GENERAL_PERMISSION_VALUE } from "../../helpers/constants";
import { useRolesPermissionList } from "../../hooks/permissions";
import { TabRoutes } from "./Tabs";
import { updateDoc } from "firebase/firestore";

function AdminLayOut() {
  const user = useUser();
  const tradedashEmployee = isTradeDashEmployee(user);
  const superAdminUser = isSuperAdmin(user);
  const companyId = useCompanyId();
  const navigate = useNavigate();
  const params = useParams();
  const rolePermissions = useRolesPermissionList();
  const allPermission = tradedashEmployee || superAdminUser;

  useEffect(() => {
    if (
      params.companyId &&
      tradedashEmployee &&
      companyId !== params.companyId &&
      params.companyId !== "settings"
    ) {
      updateDoc(user.ref, { observedCompany: params.companyId });
    }
  }, [params.companyId]);

  useEffect(() => {
    if (!params.companyId && (rolePermissions.size > 0 || allPermission)) {
      let isAllowedProject =
        rolePermissions.get(GENERAL_PERMISSION_VALUE.SETTINGS_PROJECTS) || {};
      isAllowedProject = isAllowedProject.enabled || allPermission;
      let tabPath;
      if (isAllowedProject) {
        tabPath = "projects";
      } else {
        const allowedRoute = TabRoutes.find((route) => {
          const permission = rolePermissions.get(route.permission) || {};
          return permission.enabled;
        }) || { url: "notFound" };
        tabPath = allowedRoute.url;
      }
      navigate(`/app/admin/${companyId || "settings"}/${tabPath}`, {
        replace: true,
      });
    }
  }, [rolePermissions]);

  return <Outlet />;
}

export default AdminLayOut;
