import React from "react";
import moment from "moment";
import TooltipTD from "../components/Tooltip/TooltipTD";

import { getCustomerName, getFactoryName, sortObjectsBy } from "./helpers";
import userTypes from "../api/types/userTypes";
import { ORDER_TYPE, dbTables } from "../api/types/dbTables";
import { firestore } from "../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { GENERAL_PERMISSION_VALUE } from "./constants";
import { verifyPermission } from "../hooks/permissions";
import { ShipmentBadge } from "../container/StyledComponent/BadgeStyled";
import { cx } from "@emotion/css";
import SalesOrderBadge from "../components/Badge/SalesOrderBadge";
import POBadge from "../components/Badge/POBadge";

function getShipmentTooltipLabel({ shipment }) {
  let label = "";
  const { finalDestination, shipDate, deliveryDate } = shipment;
  if (finalDestination) {
    label = label + finalDestination;
  }
  if (shipDate) {
    if (finalDestination) {
      label = label + ", ";
    }
    label = label + "Shipping on " + moment(shipDate).format("M/D/YY");
  }
  if (deliveryDate) {
    if (shipDate) {
      label = label + ", ";
    }
    label = label + "Delivery on " + moment(deliveryDate).format("M/D/YY");
  }
  return label;
}

function getShipmentInfo({ shipment = {} }) {
  const { finalDestination, shipDate, deliveryDate } = shipment;
  let prefix = shipment.number;
  let suffix = "";
  if (!finalDestination && !shipDate && !deliveryDate) {
    return { prefix, suffix };
  }
  prefix = `${prefix}: `;
  if (finalDestination) {
    suffix = suffix + finalDestination;
    if (shipDate || deliveryDate) {
      suffix = suffix + ", ";
    }
  }
  if (shipDate) {
    suffix = suffix + "Shipping on " + moment(shipDate).format("M/D/YY");
    if (deliveryDate) {
      suffix = suffix + ", ";
    }
  }
  if (deliveryDate) {
    suffix = suffix + "Delivery on " + moment(deliveryDate).format("M/D/YY");
  }
  return { prefix, suffix };
}

export function getShipmentBadge({
  shipment,
  styles = {},
  classes,
  onChangeShipment = () => {},
  tooltipStyle = {},
  showToolTip = true,
  id = "",
  label,
}) {
  if (shipment) {
    return (
      <TooltipTD
        label={label || getShipmentTooltipLabel({ shipment })}
        style={tooltipStyle}
        showToolTip={showToolTip}
      >
        <ShipmentBadge
          id={id}
          className={cx("badgeStyle", classes)}
          style={{
            ...styles,
          }}
          onClick={(ev) => {
            onChangeShipment(shipment, ev);
          }}
        >
          <p>{shipment.number}</p>
        </ShipmentBadge>
      </TooltipTD>
    );
  }
  return null;
}

export const orderNavList = ({
  salesOrders,
  queryParam,
  customers,
  shipments,
  shipmentClass,
  purchaseOrders,
  factories,
  user,
}) => {
  const salesorderList = salesOrders.map((so) => ({
    id: so.id,
    name: <SalesOrderBadge number={so.number} />,
    number: so.number,
    divider: true,
    selected: so.id === queryParam.salesOrderId,
    subTitle: [
      getCustomerName(customers, so.customerId, true),
      so.referenceNumber,
      so.customerPO,
    ],
    diamondStatus: so.diamondStatus,
    purchaseOrderIds: so.purchaseOrderIds,
    status: so.status,
    creationDate: so.creationDate,
  }));

  const shipmentList = shipments.map((shipment) => ({
    id: shipment.id,
    name: getShipmentBadge({
      shipment: shipment,
      classes: shipmentClass,
      tooltipPlacement: "bottom",
    }),
    number: `${shipment.number}`,
    divider: false,
    diamondStatus: shipment.diamondStatus,
    subTitle: [shipment.finalDestination || ""],
    creationDate: shipment.creationDate,
  }));
  let poList = purchaseOrders
    .filter((po) =>
      verifyPermission({
        user: user,
        permissionToCheck: [po.factoryId, GENERAL_PERMISSION_VALUE.ALL_VENDORS],
      })
    )
    .map((po) => ({
      id: po.id,
      name: <POBadge number={po.number} />,
      number: po.number,
      divider: true,
      selected: po.id === queryParam.purchaseOrderId,
      diamondStatus: po.diamondStatus,
      subTitle: [getFactoryName(factories, po.factoryId)],
      status: po.status,
      shipmentIds: po.shipmentIds || [],
      creationDate: po.creationDate,
    }));

  return {
    [ORDER_TYPE.PURCHASE_ORDER]: {
      list: poList,
      scope: "purchase-order-tab-",
      id: queryParam.purchaseOrderId,
      sizeNumber: Math.max(...poList.map((po) => po.number.length)),
      sizeSubtitle: [Math.max(...poList.map((po) => po.subTitle[0].length))],
      searchBox: true,
      searchBoxText: "Search for PO or Vendor",
    },
    [ORDER_TYPE.SHIPMENT]: {
      list: shipmentList,
      scope: "shipment-tab-",
      id: queryParam.shipmentId,
      searchBoxText: "Search for Shipment or Customer",
      sizeNumber: 6,
      searchBox: true,
      sizeSubtitle: [
        Math.max(
          ...shipmentList.map((shipment) => shipment.subTitle[0].length)
        ),
      ],
    },
    [ORDER_TYPE.SALES_ORDER]: {
      list: salesorderList,
      scope: ORDER_TYPE.SALES_ORDER,
      id: queryParam.shipmentId,
      sizeNumber: Math.max(...salesorderList.map((so) => so.number.length)),
      sizeSubtitle: [
        Math.max(...salesorderList.map((so) => (so.subTitle[0] || "").length)),
        Math.max(...salesorderList.map((so) => (so.subTitle[1] || "").length)),
        Math.max(...salesorderList.map((so) => (so.subTitle[2] || "").length)),
      ],
      searchBox: true,
      searchBoxSort: { key: "creationDate", asc: true },
    },
  };
};

export function getPOShipments({ shipments = [], purchaseOrder = {} }) {
  if (purchaseOrder.shipmentIds && purchaseOrder.shipmentIds.length > 0) {
    const currentShipments = [];
    purchaseOrder.shipmentIds.forEach((shipmentId) => {
      const shipment = shipments.find((shipment) => shipment.id === shipmentId);
      if (shipment) {
        currentShipments.push(shipment);
      }
    });
    const sortShipments = currentShipments.sort(sortObjectsBy("number", false));
    return sortShipments.map((shipment, index) => ({ ...shipment, index }));
  }
  return [];
}
export async function hasShipmentRootFolderPermission({
  shipment,
  user,
  allowed = () => {},
}) {
  function existsPermissionGroup({
    permissionGroupsDocument = {},
    permissionGroupsUser = {},
  }) {
    let exist = false;
    Object.keys(permissionGroupsDocument).forEach((key) => {
      if (permissionGroupsDocument[key] && !!permissionGroupsUser[key]) {
        exist = true;
      }
    });
    return exist;
  }
  const shipmentDocumentSnap = await getDocs(
    query(
      collection(
        firestore,
        `${dbTables.COMPANIES}/${shipment.companyId}/${dbTables.SHIPMENTS}/${shipment.id}/${dbTables.SHIPMENT_DOCUMENTS}`
      ),
      where("isShipmentRootTask", "==", true)
    )
  );
  const [shipmentDocument] = shipmentDocumentSnap.docs.map((doc) => doc.data());
  if (!shipmentDocument) {
    return false;
  }
  if (
    user.role === userTypes.SUPER_ADMIN ||
    (shipmentDocument.permissionUsers &&
      !!shipmentDocument.permissionUsers[user.id]) ||
    allowed(GENERAL_PERMISSION_VALUE.CAN_SEE_ALL_DASHBOARD_DOCUMENT) ||
    existsPermissionGroup({
      permissionGroupsDocument: shipmentDocument.permissionGroups,
      permissionGroupsUser: user.permissionGroups,
    })
  ) {
    return true;
  }
  return false;
}

export { getShipmentTooltipLabel };
export { getShipmentInfo };
