import {
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { colors } from "../../assets/jss/variables";
import CustomModal from "../Modal/Modal";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../firebase";
import {
  getCompanyUserDisplayName,
  sortObjectsBy,
} from "../../helpers/helpers";
import {
  ExpandMore as ExpandMoreIcon,
  Group as GroupIcon,
  Restore as RestoreIcon,
} from "@mui/icons-material";
import moment from "moment";
import { STAGE_LIST, taskTableHeader } from "../../helpers/constants";
import { useCompanyUsers } from "../../hooks/user";
import { cx } from "@emotion/css";
import {
  AccordionChangeLogsStyled,
  ChangeLogHeaderStyled,
  ChangeLogsHeaderStyled,
} from "./styles";
import TooltipTD from "../Tooltip/TooltipTD";
import { isEqual } from "lodash";
import FullTemplateDetail from "./FullTemplateDetail";
import { dbTables } from "../../api/types/dbTables";
import { useCompanyId } from "../../hooks";
import { CATEGORY_STATUS_BY_STAGE } from "../../helpers/tasks";
import { getUserAvatar } from "../../helpers/users";
import { getLabelScope } from "../PurchaseOrderDashboard/ActivityStream/ActivityHelpers";
import { useFactories } from "../../hooks/factories";

function TemplateChangeLog({ changeLogsPath = "", entity = {}, scope = "" }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [changeLogs, setChangeLogs] = useState([]);
  const companyId = useCompanyId();
  const users = useCompanyUsers({});
  const factories = useFactories();

  useEffect(() => {
    if (anchorEl) {
      loadData();
    }
  }, [anchorEl]);

  async function loadData() {
    const changeLogsSnap = await getDocs(collection(firestore, changeLogsPath));
    const changeLogs = changeLogsSnap.docs.map((doc) => doc.data());
    setChangeLogs(changeLogs);
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function renderEditedTask({ task, type, field, value = null }) {
    if (type === "updated-tasks" && task.prevTask[field] !== task[field]) {
      if (value) {
        return <s>{value}</s>;
      }
      if (field === "dependencyName" && !task.prevTask[field]) {
        return <s>Sales Order task was deleted</s>;
      } else {
        return <s>{task.prevTask[field]}</s>;
      }
    } else return "";
  }

  function displayUsersInTooltip({ userNotifications = {}, users = [] }) {
    let activeUserNotifications = {};
    Object.keys(userNotifications).forEach((userId) => {
      if (userNotifications[userId] === true) {
        activeUserNotifications[userId] = true;
      }
    });
    if (Object.keys(activeUserNotifications).length > 0) {
      return (
        <ul style={{ marginBottom: 0 }}>
          {Object.keys(activeUserNotifications).map((userId) => {
            if (activeUserNotifications[userId] === true) {
              return <li>{getCompanyUserDisplayName(users, userId)}</li>;
            }
            return "";
          })}
        </ul>
      );
    } else {
      return <span>None</span>;
    }
  }

  function renderEditedNotificationTask({ task, type, field }) {
    if (type === "updated-tasks") {
      const prevUserNotifications = task.prevTask[field] || {};
      const userNotifications = task[field] || {};
      if (!isEqual(prevUserNotifications, userNotifications)) {
        return (
          <TooltipTD
            label={displayUsersInTooltip({
              userNotifications: task?.prevTask[field],
              users,
            })}
            style={{ display: "flex" }}
            placement="bottom"
          >
            <GroupIcon
              style={{
                color: colors.dangerRed,
              }}
            />
          </TooltipTD>
        );
      }
    } else return "";
  }

  function renderEditedTaskType({ task, type }) {
    if (type !== "updated-tasks") {
      return;
    }
    if (task.prevTask?.type !== task.type) {
      return (
        <span>
          <s>{getLabelScope(task.prevTask?.type)}</s> ⮕{" "}
          {getLabelScope(task.type)}
        </span>
      );
    }
    return "";
  }

  function renderStage({ tasks = [], stage, type = "" }) {
    return tasks
      .filter((task) => task.stage === stage)
      .map((task) => {
        const { userNotificationEarly = {}, userNotificationLate = {} } = task;
        return (
          <div key={task.id} className={cx("task-displayed", type)}>
            <div className="left-justified"></div>
            <div className="left-justified">
              <span>{task.fullIndex}</span>
              <span>
                {renderEditedTask({ task, type, field: "fullIndex" })}
              </span>
            </div>
            <div className="left-justified">
              <span>{task.description}</span>
              <span>
                {renderEditedTask({ task, type, field: "description" })}
              </span>
            </div>
            <div className="left-justified">
              <span>{task.dependencyType}</span>
              <span>
                {renderEditedTask({ task, type, field: "dependencyType" })}
              </span>
            </div>
            <div className="left-justified">
              <span>{task.dependencyName}</span>
              <span>
                {renderEditedTask({
                  task,
                  type,
                  field: "dependencyName",
                  value: task.prevTask?.dependencyName,
                })}
              </span>
            </div>
            <div className="right-justified">
              <span>{task.duration}</span>
              <span>{renderEditedTask({ task, type, field: "duration" })}</span>
            </div>
            <div className="right-justified">
              <span>{task.offset}</span>
              <span>{renderEditedTask({ task, type, field: "offset" })}</span>
            </div>
            <div className="left-justified">
              <span>{getCompanyUserDisplayName(users, task.assignedTo)}</span>{" "}
              <span>
                {renderEditedTask({
                  task,
                  type,
                  field: "assignedTo",
                  value: getCompanyUserDisplayName(
                    users,
                    task.prevTask?.assignedTo
                  ),
                })}
              </span>
            </div>
            <div className="center-justified">
              <span>
                <TooltipTD
                  label={displayUsersInTooltip({
                    userNotifications: userNotificationEarly,
                    users,
                  })}
                  style={{
                    display: "flex",
                  }}
                >
                  <GroupIcon />
                </TooltipTD>
              </span>
              <span>
                {renderEditedNotificationTask({
                  task,
                  type,
                  field: "userNotificationEarly",
                })}
              </span>
            </div>
            <div className="center-justified">
              <span>
                <TooltipTD
                  label={displayUsersInTooltip({
                    userNotifications: userNotificationLate,
                    users,
                  })}
                  style={{
                    display: "flex",
                  }}
                >
                  <GroupIcon />
                </TooltipTD>
              </span>
              <span>
                {renderEditedNotificationTask({
                  task,
                  type,
                  field: "userNotificationLate",
                })}
              </span>
            </div>
            <div className="left-justified">
              <span>
                {renderEditedTask({
                  task,
                  type,
                  field: "stage",
                  value: CATEGORY_STATUS_BY_STAGE[task.prevTask?.stage],
                })}
              </span>
              {renderEditedTaskType({ task, type })}
            </div>
          </div>
        );
      });
  }

  function getTemplatePath({ scope, changeLog = {} }) {
    if (scope === dbTables.FACTORIES) {
      return `${dbTables.COMPANIES}/${companyId}/${dbTables.FACTORIES}/${entity.id}/${dbTables.FACTORY_TASK_TEMPLATE_VIEW}/${changeLog.templateViewId}`;
    } else if (scope === dbTables.SALES_ORDER_TASKS_TEMPLATE) {
      return `${dbTables.COMPANIES}/${companyId}/${dbTables.SALES_ORDER_TEMPLATE}/${changeLog.versionId}/${dbTables.SALES_ORDER_TASKS_TEMPLATE}`;
    }
  }

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        style={{
          backgroundColor: "white",
          color: colors.primaryDark,
          fontWeight: 500,
          fontSize: 14,
          textWrap: "nowrap",
          gap: 6,
        }}
      >
        <RestoreIcon
          style={{
            color: colors.primaryDark,
            width: 20,
            height: 20,
          }}
        />
        Template history
      </Button>
      <CustomModal
        header={
          <ChangeLogsHeaderStyled className="change-logs-header">
            <Typography className="title">Template history</Typography>
            <div className="change-log-legend-container">
              <div className="legend-content">
                <div className="rectangle-green">
                  <span className="label">New</span>
                </div>
              </div>
              <div className="legend-content">
                <div className="rectangle-red">
                  <span className="label">Deleted</span>
                </div>
              </div>
              <div className="legend-content">
                <div className="rectangle-yellow">
                  <span className="label">Modified</span>
                </div>
              </div>
            </div>
          </ChangeLogsHeaderStyled>
        }
        isOpen={open}
        onClose={handleClose}
        headerStyles={{
          height: 60,
        }}
        bodyStyles={{
          height: "calc(100vh - 200px)",
        }}
      >
        <div
          style={{
            width: "calc(100vw - 200px)",
            height: "calc(100vh - 200px)",
            overflow: "auto",
          }}
        >
          {changeLogs
            .sort(sortObjectsBy("creationDate", true))
            .map((changeLog) => {
              const {
                addedTasks = [],
                deletedTasks = [],
                updatedTasks = [],
                id,
                version,
                creationDate,
                description,
              } = changeLog;
              const hasChanges =
                addedTasks.length > 0 ||
                deletedTasks.length > 0 ||
                updatedTasks.length > 0;

              const currentFactory = factories.find(
                (factory) => factory.id === changeLog.cloneFrom
              );
              return (
                <AccordionChangeLogsStyled
                  key={id}
                  classes={{
                    expanded: "accordionSummaryExpanded",
                  }}
                >
                  <AccordionSummary
                    classes={{
                      expandIcon: "expandIcon",
                      content: "content",
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div
                      className={"accordion-description"}
                      style={{
                        gridTemplateColumns: `200px 100px  ${
                          !!currentFactory ? "200px" : ""
                        } ${!hasChanges ? "360px" : ""}  200px 1fr`,
                      }}
                    >
                      <span className="span-content">
                        <b>Created: </b>
                        {moment(creationDate).format("M/D/YY hh:mma")}
                      </span>
                      <span className="span-content">
                        <b>Version: </b>
                        {version}
                      </span>
                      {currentFactory && (
                        <span className="span-content">{`(Clone from ${currentFactory.name})`}</span>
                      )}
                      {!hasChanges && (
                        <span className="span-content">{`(No changes after Sales Order template change)`}</span>
                      )}
                      <div
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "100%",
                          padding: 2,
                          display: "flex",
                          gap: 4,
                        }}
                      >
                        {getUserAvatar({
                          user: users.find(
                            (user) => user.id === changeLog.user
                          ),
                          styles: {
                            width: 24,
                            height: 24,
                            fontSize: 12,
                            outline: "1px solid #000",
                          },
                          className: "avatar-displayed",
                        })}
                        <span
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "100%",
                          }}
                        >
                          <TooltipTD
                            label={getCompanyUserDisplayName(
                              users,
                              changeLog.user
                            )}
                          >
                            {getCompanyUserDisplayName(users, changeLog.user)}
                          </TooltipTD>
                        </span>
                      </div>
                      <TooltipTD
                        label={description}
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "100%",
                          display: "flex",
                          gap: 4,
                        }}
                      >
                        <span
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textWrap: "nowrap",
                            fontStyle: "italic",
                          }}
                          className="span-content"
                        >
                          {description}
                        </span>
                      </TooltipTD>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails style={{ display: "block" }}>
                    <FullTemplateDetail
                      templatePath={getTemplatePath({
                        scope,
                        changeLog,
                      })}
                      scope={scope}
                      changeLog={changeLog}
                    />
                    {hasChanges && (
                      <>
                        <ChangeLogHeaderStyled className="table-header-stage">
                          <span />
                          <span>{taskTableHeader.BIG_REF}</span>
                          <span>{taskTableHeader.DESCRIPTION}</span>
                          <span>{taskTableHeader.SMALL_DEPENDENCY_TYPE}</span>
                          <span>{taskTableHeader.BIG_PREDECESSOR}</span>
                          <span>{taskTableHeader.BIG_DURATION}</span>
                          <span>{taskTableHeader.BIG_OFFSET}</span>
                          <span>{taskTableHeader.ASSIGNED_TO}</span>
                          <span style={{ textAlign: "center" }}>
                            {taskTableHeader.NOTIFICATION_COMPLETED_EARLY}
                          </span>
                          <span style={{ textAlign: "center" }}>
                            {taskTableHeader.NOTIFICATION_COMPLETED_LATE}
                          </span>
                          <span>{taskTableHeader.STAGE}</span>
                        </ChangeLogHeaderStyled>

                        {STAGE_LIST.map((stage) => {
                          return (
                            <div>
                              <div className="stage-title">
                                {CATEGORY_STATUS_BY_STAGE[stage]}
                              </div>
                              <div className="tasks-in-stage-container">
                                {renderStage({
                                  tasks: addedTasks,
                                  stage,
                                  type: "added-tasks",
                                })}
                              </div>
                              <div className="tasks-in-stage-container">
                                {renderStage({
                                  tasks: deletedTasks,
                                  stage,
                                  type: "deleted-tasks",
                                })}
                              </div>
                              <div className="tasks-in-stage-container">
                                {renderStage({
                                  tasks: updatedTasks,
                                  stage,
                                  type: "updated-tasks",
                                })}
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </AccordionDetails>
                </AccordionChangeLogsStyled>
              );
            })}
        </div>
      </CustomModal>
    </div>
  );
}

export default TemplateChangeLog;
