import React, { useState } from "react";
import { MultiUserPickerPopoverStyled, OnlyFromStyled } from "./styles";
import { sortObjectsBy } from "../../helpers/helpers";
import { getUserAvatar } from "../../helpers/users";
import { DefaultUserIcon } from "../../helpers/iconSvgPath";
import { colors } from "../../assets/jss/variables";
import CustomCheckbox from "../Inputs/CustomCheckbox";

function MultiUserPicker({
  list,
  selectedOptions = () => {},
  filterByUsers = [],
}) {
  const [OpenUsers, setOpenUsers] = useState(false);
  const [SelectedElements, setSelectedElements] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  function handleOpen(event) {
    setAnchorEl(event.currentTarget);
    setOpenUsers(true);
  }
  function handleClose() {
    selectedOptions(SelectedElements);
    setOpenUsers(false);
  }
  const onChange = ({ user, erase }) => {
    if (erase) {
      const newSelected = SelectedElements.filter(
        (oldUser) => oldUser.id !== user.id
      );
      setSelectedElements(newSelected);
    } else {
      setSelectedElements([...SelectedElements, user]);
    }
  };

  useState(() => {
    setSelectedElements(list.filter((user) => filterByUsers.includes(user.id)));
  }, [filterByUsers]);

  function renderDynamicSelectedUsers() {
    const selectedUser = SelectedElements || [];
    const avatarStyles = {
      width: 20,
      height: 20,
      fontSize: 10,
      outline: "1px solid #000",
    };
    if (selectedUser.length >= 1) {
      if (selectedUser.length <= 3) {
        return selectedUser.map((element) =>
          getUserAvatar({
            user: element,
            styles: avatarStyles,
          })
        );
      } else {
        const firstThreeUsers = selectedUser.slice(0, 3);
        const plusUser = getUserAvatar({
          user: {
            firstName: "+",
            lastName: [`${selectedUser.length - 3}`],
          },
          styles: avatarStyles,
        });
        return [
          ...firstThreeUsers.map((element) =>
            getUserAvatar({
              user: element,
              styles: avatarStyles,
            })
          ),
          plusUser,
        ];
      }
    }
  }

  return (
    <React.Fragment>
      <OnlyFromStyled
        onClick={(ev) => handleOpen(ev)}
        className="onlyFromContainer"
      >
        <div className="onlyFromData">
          <div className="selected-users-only-from">
            {renderDynamicSelectedUsers()}
            {SelectedElements.length === 0 && (
              <DefaultUserIcon color={colors.primaryDark} />
            )}
          </div>
          <div className="onlyFrom">ONLY FROM</div>
        </div>
      </OnlyFromStyled>
      {OpenUsers && (
        <MultiUserPickerPopoverStyled
          open={OpenUsers}
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          classes={{ paper: "multiUserPapper" }}
          className="multiUserPickerPopoverContainer"
        >
          <div className="selectAllNoneContainer">
            <span
              className="select-all-button"
              onClick={() => setSelectedElements(list)}
            >
              Select all
            </span>
            -
            <span
              className="select-none-button"
              onClick={() => setSelectedElements([])}
            >
              Clear
            </span>
          </div>
          <ul className="listContainer">
            {list.sort(sortObjectsBy("displayName", false)).map((user) => (
              <li
                key={user.id}
                className="listItem"
                onClick={(ev) => {
                  const customCheckboxElement = document.getElementById(
                    user.id + "-checkbox-multi-user-picker"
                  );
                  if (customCheckboxElement) {
                    customCheckboxElement.click();
                  }
                  ev.stopPropagation();
                }}
              >
                <CustomCheckbox
                  checked={
                    !!SelectedElements.find((item) => item.id === user.id)
                  }
                  id={user.id + "-checkbox-multi-user-picker"}
                  onChange={(ev, checked) => {
                    onChange({ user, erase: !checked });
                    ev.preventDefault();
                    ev.stopPropagation();
                  }}
                  iconSize={20}
                  styles={{ padding: 0, marginBottom: -1 }}
                />

                {getUserAvatar({
                  user: user,
                  styles: {
                    width: 24,
                    height: 24,
                    fontSize: 13,
                    outline: "2px solid #000",
                  },
                })}
                <span>{user.displayName}</span>
              </li>
            ))}
          </ul>
        </MultiUserPickerPopoverStyled>
      )}
    </React.Fragment>
  );
}

export default MultiUserPicker;
