import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import CancelIcon from "@mui/icons-material/Cancel";
import PropTypes from "prop-types";
import { getUserAvatar } from "../../helpers/users";
import { cx } from "@emotion/css";
import {
  Chip,
  MenuItem,
  MenuList,
  NoSsr,
  Paper,
  TextField,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import AppConfig from "../../constants/AppConfig";
import { filterAutocompleteUserList } from "../../helpers/documentsv2.helper";

function NoOptionsMessage(props) {
  const { selectProps } = props;
  return (
    <Typography
      color="textSecondary"
      className={
        selectProps.classes.noOptionsMessage +
        " " +
        selectProps.classes.userClasses.noOptionsMessage
      }
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

function Control(props) {
  const { selectProps } = props;
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextField
      fullWidth
      className={
        classes.inputContainer + " " + classes.userClasses.inputContainer
      }
      InputProps={{
        inputComponent,

        classes: {
          underline: classes.userClasses.underline,
          focused: classes.userClasses.inputFocused,
        },
        inputProps: {
          className:
            classes.input + " " + selectProps.classes.userClasses.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired,
};

function Option(props) {
  const { selectProps } = props;
  return (
    <MenuItem
      className={selectProps.classes.userClasses.menuItem}
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      {...props.innerProps}
    >
      {props.data &&
        getUserAvatar({
          user: props.data,
          styles: {
            width: 30,
            height: 30,
            fontSize: 15,
            marginRight: 3,
            outline: "2px solid #000",
          },
        })}
      <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
        {props.children}
      </div>
    </MenuItem>
  );
}

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
};

function Placeholder(props) {
  const { selectProps } = props;
  return (
    <Typography
      color="textSecondary"
      className={
        selectProps.classes.placeholder +
        " " +
        selectProps.classes.userClasses.placeholder
      }
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function SingleValue(props) {
  const { selectProps } = props;

  return (
    <Typography
      className={
        selectProps.classes.singleValue +
        " " +
        selectProps.classes.userClasses.singleValue
      }
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function ValueContainer(props) {
  const { selectProps } = props;
  return (
    <div className={selectProps.classes.valueContainer}>{props.children}</div>
  );
}

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
};

function IndicatorsContainer(props) {
  const { selectProps } = props;
  return (
    <div className={selectProps.classes.userClasses.indicatorsContainer}>
      <components.IndicatorsContainer {...props} />
    </div>
  );
}

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
};

function MultiValue(props) {
  const { selectProps, removeProps, data } = props;
  return (
    <Chip
      key={data.id}
      tabIndex={-1}
      label={
        <div className="user-content">
          {getUserAvatar({
            user: props.data,
            styles: {
              width: 30,
              height: 30,
              fontSize: 15,
              outline: "2px solid #000",
              outlineOffset: -2,
              margin: "4px 0px",
            },
          })}
          <span>{props.children}</span>
        </div>
      }
      className={cx(
        selectProps.classes.chip,
        selectProps.classes.userClasses.chip,
        {
          [selectProps.classes.chipFocused]: props.isFocused,
        }
      )}
      onDelete={removeProps.onClick}
      deleteIcon={
        <CancelIcon
          {...removeProps}
          className={selectProps.classes.userClasses.deleteIcon}
        />
      }
    />
  );
}

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool,
  removeProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};

function Menu(props) {
  const { selectProps } = props;
  return (
    <Paper
      className={
        selectProps.classes.paper + " " + selectProps.classes.userClasses.menu
      }
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

function MenuListCustom(props) {
  const menuStyle = props.menuStyle;
  const { selectProps } = props;
  return (
    <MenuList
      className={
        selectProps.classes.paper + " " + selectProps.classes.userClasses.paper
      }
      style={menuStyle}
      {...props.innerProps}
    >
      {props.children}
    </MenuList>
  );
}

Menu.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object,
};

const customComponents = ({ menuStyle }) => ({
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  IndicatorsContainer,
  MenuList: (props) => MenuListCustom({ ...props, menuStyle }),
});

export default function Autocomplete({
  options,
  label,
  placeholder,
  onChange = () => {},
  value = [],
  classes: userClasses = {},
  isMulti = true,
  focused,
  id = "autocomple-id",
  menuStyle = {},
  styles = {},
  selectClassname = "",
  disabled = false,
}) {
  const theme = useTheme();
  const [lastValue, setLastValue] = useState(value);
  useEffect(() => {
    setLastValue(value);
  }, [value]);

  function handleChangeMulti(value, lastValue) {
    onChange(value, lastValue);
  }

  const selectStyles = {
    input: (base) => ({
      ...base,
      color: theme.palette.text.primary,
      "& input": {
        font: "inherit",
      },
    }),
    control: (base) => ({
      ...base,
      boxShadow: "none",
    }),
  };
  return (
    <AutoCompleteFieldStyled
      className="autocomplete-root"
      style={{ ...styles }}
    >
      <NoSsr>
        <Select
          id={id}
          className={selectClassname}
          classes={{ userClasses: userClasses }}
          styles={selectStyles}
          TextFieldProps={{
            label: label,
            InputLabelProps: {
              htmlFor: "react-select-multiple",
              shrink: true,
            },
            placeholder: placeholder,
            focused: focused,
          }}
          options={options}
          components={customComponents({ menuStyle })}
          value={value}
          onChange={(value) => handleChangeMulti(value, lastValue)}
          isMulti={isMulti}
          closeMenuOnSelect={false}
          filterOption={(candidate, input) =>
            filterAutocompleteUserList({ input, candidate })
          }
          isDisabled={disabled}
        />
      </NoSsr>
    </AutoCompleteFieldStyled>
  );
}

Autocomplete.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    input: PropTypes.string,
    valueContainer: PropTypes.string,
    chip: PropTypes.string,
    chipFocused: PropTypes.string,
    noOptionsMessage: PropTypes.string,
    singleValue: PropTypes.string,
    placeholder: PropTypes.string,
    paper: PropTypes.string,
    divider: PropTypes.string,
    deleteIcon: PropTypes.string,
    menuItem: PropTypes.string,
  }),
};

const AutoCompleteFieldStyled = styled("div")(() => ({
  "&.autocomplete-root": {
    flexGrow: 0,
    minWidth: 120,
    "& .selectContainer": {
      position: "unset",
      "& .paper": { maxHeight: "20vh", overflow: "auto", minWidth: 80 },
    },
    "& .permission-group-drag": {
      color: "#25486d",
    },
    "& .delete": {
      margin: 0,
    },
    "& .user-content": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      div: {
        width: 30,
        height: 30,
        textTransform: "capitalize",
      },
      "& .p": {
        color: "#4a4a4a",
        fontFamily: "Avenir Next",
        fontSize: 14,
        lineHeight: "20px",
        margin: 0,
        paddingLeft: 3,
      },
    },
    "& .chip": {
      background: AppConfig.themeColors.primaryLight,
      margin: 4,
      padding: "0px 6px",
      "& .MuiChip-label": {
        paddingLeft: 0,
        paddingRight: 0,
      },
      "& span": {
        paddingLeft: 0,
        paddingRight: 4,
      },
    },
    "& .value-container": {
      flexWrap: "nowrap",
      display: "flex",
      alignItems: "center",
      overflow: "hidden",
      width: 20,
    },
    "& .input:focus": {
      border: "1px solid #ccc",
      flexWrap: "nowrap",
      display: "flex",
      alignItems: "center",
      overflow: "hidden",
      "& .placeholder": {
        display: "none",
      },
    },
    "& .input": {
      paddingLeft: 8,
      height: "auto",
    },
    "& .input::placeholder": {
      display: "none",
      content: '""',
    },
    "& .input-focused": {
      border: "1px solid rgb(156, 153, 153)",
      outline: "none",
      boxShadow: "none",
    },
    "& .underline::before": {
      display: "none",
    },
    "& .underline::after": {
      display: "none",
    },
    "& .indicators": {
      display: "none",
    },
    "& .paper": {
      display: "flex",
      flexWrap: "wrap !important",
      backgroundColor: "white",
      border: "1px solid #ccc",
      position: "absolute",
      minWidth: 300,
      zIndex: 100,
    },
  },
}));
