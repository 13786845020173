import { now } from "moment";
import { getRandomId } from "../../helpers/helpers";
import { REPORTS_TYPE, REPORT_EMBED_TYPES } from "../../helpers/constants";

export default class Report {
  constructor({
    id = getRandomId(),
    name = "",
    description = "",
    reportEmbed = "",
    loomEmbed = "",
    URLToUpdateReport = "",
    URLToDownloadReport = "",
    notes = "",
    creationDate = now(),
    active = true,
    parentId = "",
    type = REPORTS_TYPE.REPORT,
    embedType = REPORT_EMBED_TYPES.GOOGLE_SHEET,
  }) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.reportEmbed = reportEmbed;
    this.loomEmbed = loomEmbed;
    this.URLToUpdateReport = URLToUpdateReport;
    this.URLToDownloadReport = URLToDownloadReport;
    this.notes = notes;
    this.creationDate = creationDate;
    this.active = active;
    this.parentId = parentId;
    this.type = type;
    this.embedType = embedType;
  }
}
