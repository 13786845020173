import React from "react";
import { NavLink } from "react-router-dom";
import {Visibility as VisibilityIcon} from "@mui/icons-material"
import { useUser } from "../../hooks/user";

function AdminListItem({ company, active }) {
  const currentUser = useUser();
  return (
    <NavLink
      style={{ background: active ? "white" : "" }}
      className={"link"}
      to={`/app/admin/${company.id}/projects`}
    >
      <li key={company.id} className="company-li">
        <span>{company.name}</span>
        {currentUser.observedCompany === company.id && <VisibilityIcon />}
      </li>
    </NavLink>
  );
}

export default AdminListItem;
