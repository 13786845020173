import React, { useState, useEffect } from "react";
import CustomButton from "../Buttons/CustomButton";
import "react-phone-input-2/lib/plain.css";
import {
  REPORTS_TYPE,
  REPORT_EMBED_TYPES,
  reportFields,
} from "../../helpers/constants";
import { CreateReportFormContainerStyled } from "./styles";
import { isTradeDashEmployee, sortObjectsBy } from "../../helpers/helpers";
import IntlMessages from "../../util/IntlMessages";
import ModalPermissionUsers from "../Documents/ModalPermissionUsers";
import { getNewToolTipByPermissionGroup } from "../../helpers/purchaseOrderList";
import { CustomTooltip } from "../Tooltip/CustomToolTip";
import { Grid, TextareaAutosize } from "@mui/material";
import { useCompanyId } from "../../hooks";
import { getselectedUsersObj } from "../../helpers/documents";
import {
  getAddedDeleteElement,
  getGroupAndUserPermission,
  onDisolveGroups,
} from "../ReportsTree/helpers";

function CreateReportForm({
  report,
  isUpdating,
  onChange,
  onSubmit = () => {},
  handleClose,
  companyUsers = [],
  permissionGroupsDB = [],
  user,
  type,
  isRoot,
  parentPermission,
}) {
  const companyId = useCompanyId();
  const newReport = { ...report };
  delete newReport.isRoot;
  delete newReport.reportParent;

  const [permissions, setPermissions] = useState({
    permissionUsers: [],
    permissionGroups: [],
  });

  useEffect(() => {
    const { newPermissionGroups, newPermissionUsers } =
      getGroupAndUserPermission({
        permissionGroups: newReport.permissionGroups,
        permissionUsers: newReport.permissionUsers,
        permissionGroupsDB,
        companyUsers,
        isRoot,
        parentPermission,
      });
    setPermissions({
      permissionGroups: newPermissionGroups,
      permissionUsers: newPermissionUsers,
    });
  }, [report.permissionGroups, report.permissionUsers]);

  const getAvalibleUsers = () => {
    let users;
    if (isRoot) {
      users = companyUsers.filter((user) => user.companyId === companyId);
    } else {
      const selectedUsersObj = getselectedUsersObj(
        parentPermission.permissionUsers,
        parentPermission.permissionGroups,
        permissionGroupsDB
      );
      users = companyUsers.filter((user) => {
        return (
          user.companyId === companyId && selectedUsersObj[user.id] === true
        );
      });
    }
    return users;
  };

  function getUserSelected(permissions) {
    const userSelected = [...permissions.permissionUsers];
    permissions.permissionGroups.forEach((group) => {
      if (group.checked) {
        Object.keys(group.users).forEach((userId) => {
          const user = companyUsers.find((user) => user.id === userId);
          if (user && !userSelected.find((el) => el.id === userId)) {
            userSelected.push(user);
          }
        });
      }
    });
    return userSelected;
  }

  function handleChange(value, lastValue) {
    const { deletedItem, addedItem } = getAddedDeleteElement({
      value,
      lastValue,
    });
    const { permissionGroups, permissionUsers } = permissions;
    let permissionUsersCpy = [...permissionUsers];
    if (value.length > lastValue.length) {
      permissionUsersCpy.push(addedItem);
      setPermissions({ ...permissions, permissionUsers: permissionUsersCpy });
    } else {
      const permissionGroupCpy = [...permissionGroups];
      const checkedPermission =
        permissionGroupCpy.filter((group) => group.checked).length <= 0;
      permissionUsersCpy = permissionUsersCpy.filter(
        (cUser) => cUser.id !== deletedItem.id
      );
      if (checkedPermission) {
        setPermissions({ ...permissions, permissionUsers: permissionUsersCpy });
      } else {
        const { newPermissionGroups, newPermissionUsers } = onDisolveGroups({
          deletedItem,
          permissionGroups: permissionGroupCpy,
          permissionUsers: permissionUsersCpy,
          companyUsers,
        });
        setPermissions({
          permissionGroups: newPermissionGroups,
          permissionUsers: newPermissionUsers,
        });
      }
    }
  }

  function handleChangeGroups(permission, checked) {
    const { permissionGroups } = permissions;
    let permissionGroupsCpy = [...permissionGroups];
    permissionGroupsCpy = permissionGroupsCpy.map((elem) => {
      if (elem.id === permission.id) {
        if (checked) {
          let usersCpy = { ...elem.users };
          Object.keys(usersCpy).map((key) => (usersCpy[key] = true));
          return { ...elem, checked, users: usersCpy };
        } else {
          return { ...elem, checked };
        }
      } else {
        return elem;
      }
    });
    if (checked) {
      let permissionUsersCpy = [...permissions.permissionUsers];
      Object.keys(permission.users).forEach((key) => {
        permissionUsersCpy = permissionUsersCpy.filter(
          (user) => user.id !== key
        );
      });
      setPermissions({
        permissionGroups: permissionGroupsCpy,
        permissionUsers: permissionUsersCpy,
      });
    } else {
      setPermissions({
        ...permissions,
        permissionGroups: permissionGroupsCpy,
      });
    }
  }

  function getUserAndGroupPermissionsObj() {
    let permissionUsersObj = {};
    let permissionGroupsObj = {};
    const { permissionUsers, permissionGroups } = permissions;
    permissionUsers.forEach((user) => (permissionUsersObj[user.id] = true));
    permissionGroups.forEach((group) => {
      if (!!group.checked) {
        permissionGroupsObj[group.id] = true;
      }
    });
    return {
      permissionUsers: permissionUsersObj,
      permissionGroups: permissionGroupsObj,
    };
  }

  function onChangeReport({ field, value }) {
    const { permissionGroups, permissionUsers } =
      getUserAndGroupPermissionsObj();
    onChange({
      value: {
        ...newReport,
        permissionGroups,
        permissionUsers,
        [field]: value,
      },
    });
  }

  const UPDATE_NAME = {
    [REPORT_EMBED_TYPES.GOOGLE_SHEET]: "Update API URL",
    [REPORT_EMBED_TYPES.DOMO]: "Data source Id",
  };
  const reportEmbedType = UPDATE_NAME[newReport[reportFields.EMBED_TYPE]];

  return (
    <CreateReportFormContainerStyled
      onSubmit={(ev) => {
        const { permissionGroups, permissionUsers } =
          getUserAndGroupPermissionsObj();
        onSubmit({
          ev,
          currentReport: { ...newReport, permissionGroups, permissionUsers },
        });
        ev.preventDefault();
      }}
      onKeyDown={(ev) => {
        if (ev.key === "Escape") {
          handleClose();
        }
      }}
      className="formContainer"
    >
      <Grid
        container
        spacing={5}
        alignItems="center"
        justifyContent="center"
        style={{
          maxHeight: "calc(100vh - 188px)",
          overflowY: "scroll",
          width: "auto",
          margin: 0,
          display: "block",
          padding: 20,
        }}
      >
        <div className="itemFormContainer">
          <label>Name</label>
          <TextareaAutosize
            value={newReport[reportFields.NAME]}
            onChange={(ev) =>
              onChangeReport({
                value: ev.target.value,
                field: reportFields.NAME,
              })
            }
            placeholder={`${type}'s name`}
            required={true}
            autoFocus={true}
            minRows={2}
            maxLength={140}
            id="create-report-form-id"
          />
        </div>

        <div className="itemFormContainer">
          <label>Description</label>
          <TextareaAutosize
            value={newReport[reportFields.DESCRIPTION]}
            onChange={(ev) =>
              onChangeReport({
                value: ev.target.value,
                field: reportFields.DESCRIPTION,
              })
            }
            placeholder={`${type}'s description`}
            required={false}
            autoFocus={false}
            minRows={6}
          />
        </div>

        {isTradeDashEmployee(user) && report.type === REPORTS_TYPE.REPORT && (
          <div className="itemFormContainer">
            <label>Report embed</label>
            <TextareaAutosize
              value={newReport[reportFields.REPORT_EMBED]}
              onChange={(ev) =>
                onChangeReport({
                  value: ev.target.value,
                  field: reportFields.REPORT_EMBED,
                })
              }
              placeholder="Report's embed"
              required={true}
              autoFocus={false}
              minRows={2}
            />
          </div>
        )}

        {isTradeDashEmployee(user) && report.type === REPORTS_TYPE.REPORT && (
          <div className="itemFormContainer">
            <label>Loom embed</label>
            <TextareaAutosize
              value={newReport[reportFields.LOOM_EMBED]}
              onChange={(ev) =>
                onChangeReport({
                  value: ev.target.value,
                  field: reportFields.LOOM_EMBED,
                })
              }
              placeholder="Loom's embed"
              required={false}
              autoFocus={false}
              minRows={2}
            />
          </div>
        )}
        {isTradeDashEmployee(user) && report.type === REPORTS_TYPE.REPORT && (
          <div className="itemFormContainer">
            <label>Type</label>
            <select
              value={newReport[reportFields.EMBED_TYPE]}
              onChange={(ev) =>
                onChangeReport({
                  value: ev.target.value,
                  field: reportFields.EMBED_TYPE,
                })
              }
            >
              <option value={REPORT_EMBED_TYPES.GOOGLE_SHEET}>
                Google sheets
              </option>
              <option value={REPORT_EMBED_TYPES.DOMO}>DOMO</option>
            </select>
          </div>
        )}

        {isTradeDashEmployee(user) && report.type === REPORTS_TYPE.REPORT && (
          <div className="itemFormContainer">
            <label>{reportEmbedType}</label>
            <TextareaAutosize
              value={newReport[reportFields.URL_TO_UPDATE_REPORT]}
              onChange={(ev) =>
                onChangeReport({
                  value: ev.target.value,
                  field: reportFields.URL_TO_UPDATE_REPORT,
                })
              }
              placeholder={reportEmbedType}
              required={false}
              autoFocus={false}
              minRows={2}
            />
          </div>
        )}

        {isTradeDashEmployee(user) && report.type === REPORTS_TYPE.REPORT && (
          <div className="itemFormContainer">
            <label>Link to download</label>
            <TextareaAutosize
              value={newReport[reportFields.URL_TO_DOWNLOAD_REPORT]}
              onChange={(ev) =>
                onChangeReport({
                  value: ev.target.value,
                  field: reportFields.URL_TO_DOWNLOAD_REPORT,
                })
              }
              placeholder="Link to download"
              required={false}
              autoFocus={false}
              minRows={2}
            />
          </div>
        )}

        {isTradeDashEmployee(user) && report.type === REPORTS_TYPE.REPORT && (
          <div className="itemFormContainer">
            <label>Notes</label>
            <TextareaAutosize
              value={newReport[reportFields.NOTES]}
              onChange={(ev) =>
                onChangeReport({
                  value: ev.target.value,
                  field: reportFields.NOTES,
                })
              }
              placeholder="Notes"
              required={false}
              autoFocus={false}
              minRows={2}
            />
          </div>
        )}
        {newReport.type === REPORTS_TYPE.FOLDER && (
          <div className="itemFormContainer">
            <label>
              <IntlMessages id="components.documents.modalpermission.viewpermission" />
            </label>
            <div style={{ width: 350 }} />
          </div>
        )}

        {newReport.type === REPORTS_TYPE.FOLDER && (
          <div className="reportsFormPermissionsContainer">
            <div className="permissionsGroupContainer">
              <div className="permissionsGroupContent">
                <div className="multiselect">
                  {permissions.permissionGroups
                    .sort(sortObjectsBy("name", false))
                    .map((permission, index) => (
                      <div
                        key={permission.id + index}
                        className="permissionContent"
                        onClick={(ev) => {
                          if (!!permission.enable) {
                            handleChangeGroups(permission, !permission.checked);
                          }
                          ev.stopPropagation();
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={permission.checked}
                          disabled={!permission.enable}
                          className="permissionCheck"
                        />
                        <CustomTooltip
                          label={getNewToolTipByPermissionGroup({
                            permission,
                            users: companyUsers,
                          })}
                          style={{ color: permission.enable ? "" : "#ccc" }}
                        >
                          {permission.name}
                        </CustomTooltip>
                      </div>
                    ))}
                </div>
              </div>
              <div className="viewPermissionOnUpload">
                <ModalPermissionUsers
                  users={getAvalibleUsers()}
                  userSelected={getUserSelected(permissions)}
                  handleChange={handleChange}
                  selectClassname="selectContainer"
                />
              </div>
            </div>
          </div>
        )}
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        style={{ marginTop: 8 }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginRight: 24,
          }}
        >
          <CustomButton type="success">
            {isUpdating ? "generic.text.update" : "generic.text.create"}
          </CustomButton>
          <CustomButton type="cancel" onClick={handleClose} noSubmit>
            {"generic.text.cancel"}
          </CustomButton>
        </div>
      </Grid>
    </CreateReportFormContainerStyled>
  );
}

export default CreateReportForm;
