import moment from "moment";
import {
  GENERAL_PERMISSION_VALUE,
  NOTES_SCOPE,
  NOTIFICATION_SCOPE,
} from "../../helpers/constants";
import {
  getDashboardSearchPath,
  getDayOffset,
  getPurchaseOrders,
  getUserDisplayName,
} from "../../helpers/helpers";
import { dbTables, globalScopes } from "../../api/types/dbTables";
import { collection, doc, query, where } from "firebase/firestore";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "../PurchaseOrderDashboard/sections/helpers";
import { firestore } from "../../firebase";

export const SALES_ORDER_NOTIFICATION_SCOPES = [
  NOTIFICATION_SCOPE.SO_MENTION,
  NOTIFICATION_SCOPE.SO_TASK_EARLY,
  NOTIFICATION_SCOPE.SO_TASK_LATE,
  NOTIFICATION_SCOPE.SO_TASK_CREATED,
];
export const PO_NOTIFICATION_SCOPES = [
  NOTIFICATION_SCOPE.PO_MENTION,
  NOTIFICATION_SCOPE.PO_TASK_EARLY,
  NOTIFICATION_SCOPE.PO_TASK_LATE,
  NOTIFICATION_SCOPE.PO_TASK_CREATED,
];
export const SHIPMENT_NOTIFICATION_SCOPES = [
  NOTIFICATION_SCOPE.SHIPMENT_MENTION,
  NOTIFICATION_SCOPE.SHIPMENT_TASK_EARLY,
  NOTIFICATION_SCOPE.SHIPMENT_TASK_LATE,
  NOTIFICATION_SCOPE.SHIPMENT_TASK_CREATED,
];

export const dashboardRedirect = ({
  salesOrderId,
  purchaseOrderId,
  shipmentId,
  id,
  idKey = "taskId",
}) => {
  const idsPath = getDashboardSearchPath({
    salesOrderId,
    purchaseOrderId,
    shipmentId,
    section: ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION,
    [idKey]: id,
  });
  let path = idsPath;
  return path;
};

function getDay(finishDate) {
  return moment(finishDate).format("dddd");
}

function getDueDateFormat(finishDate) {
  const diffDays = moment(finishDate)
    .startOf("day")
    .diff(moment().startOf("day"), "days");
  const todayDay = moment().day();
  if (diffDays === 0) {
    return "Due today, " + moment(finishDate).format("M/D/YY");
  } else if (diffDays === 1) {
    return "Due tomorrow, " + moment(finishDate).format("M/D/YY");
  } else if (todayDay + diffDays <= 7) {
    return `Due ${getDay(finishDate)}, ` + moment(finishDate).format("M/D/YY");
  } else if (todayDay + diffDays <= 13) {
    return (
      `Due next ${getDay(finishDate)}, ` + moment(finishDate).format("M/D/YY")
    );
  } else {
    return "Due " + moment(finishDate).format("M/D/YY");
  }
}

export function getNotificationText(notification = {}, users = []) {
  switch (notification.scope) {
    case NOTIFICATION_SCOPE.SHIPMENT_MENTION:
      return `
          <span>
            <strong>@${getUserDisplayName(
              users,
              notification.createdBy
            )}</strong> tagged you in a note about:
          </span>
          <br/>
          <br/>
          <ul class='notification-list-section'>
            <li>
              <div class='notification-sales-order-mention-badge' >
                <span>
                ${notification.SONumber}
                </span>
              </div>
            </li>
            <li>${notification.customerName}</li>
            <li>
              <div class='notification-shipment-mention-badge-container' >
              <div class='notification-shipment-mention-badge' >
              <span>
              ${notification.shipment?.number} 
              </span>
              </div>
              ${
                notification.shipment
                  ? notification.shipment.finalDestination || ""
                  : ""
              }
              </div>
            </li>
          </ul>
          <br/>
          <span>
            <span class='notification-payload-detail'>
              ${notification.description}
            </span>
          </span>`.replace(/\s\s+/g, " ");
    case NOTIFICATION_SCOPE.SO_MENTION:
      return `
          <span>
            <strong>@${getUserDisplayName(
              users,
              notification.createdBy
            )}</strong> tagged you in a note about:
          </span>
          <br/>
          <br/>
          <ul class='notification-list-section'>
            <li>
              <div class='notification-sales-order-mention-badge'>
                <span>
                ${notification.SONumber}
                </span>
              </div>
            </li>
            <li>${notification.customerName}</li>
          </ul>
          <br/>
          <span>
            <span class='notification-payload-detail'>
              ${notification.description}
            </span>
          </span>`.replace(/\s\s+/g, " ");
    case NOTIFICATION_SCOPE.PO_MENTION:
      return `
        <span>
          <strong>@${getUserDisplayName(
            users,
            notification.createdBy
          )}</strong> tagged you in a note about:
        </span>
        <br/>
        <br/>
        <ul class='notification-list-section'>
          <li>
            <div class='notification-sales-order-mention-badge'>
              <span>
                ${notification.SONumber}
                </span>
            </div>
          </li>
          <li>${notification.customerName}</li>
          <li>
            <div class='notification-purchase-order-mention-badge'>\
              <span>
              ${notification.PONumber}
              </span>
            </div>
          </li>
          <li>${notification.factoryName}</li>
        </ul>
        <br/>
        <span>
          <span class='notification-payload-detail'>
            ${notification.description}
          </span>
        </span>`.replace(/\s\s+/g, " ");
    case NOTIFICATION_SCOPE.SO_TASK_CREATED:
      return `
              <span>
                <strong>@${getUserDisplayName(
                  users,
                  notification.createdBy
                )}</strong> created a task for you:
              </span>
              <br/>
              <br/>
              <span>
                <strong>${notification.description} </strong>
                <br/>
                <br/>
                <ul class='notification-list-section'>
                  <li>
                    <div class='notification-sales-order-mention-badge'>
                      <span>
                ${notification.SONumber}
                </span>
                    </div>
                  </li>
                  <li>${notification.customerName}</li>
                </ul>
                <br/>
                ${getDueDateFormat(notification.finishDate)}
              </span>`.replace(/\s\s+/g, " ");
    case NOTIFICATION_SCOPE.PO_TASK_CREATED:
      return `
              <span>
                <strong>@${getUserDisplayName(
                  users,
                  notification.createdBy
                )}</strong> created a task for you:
              </span>
              <br/>
              <br/>
              <span>
                <strong>${notification.description} </strong>
                <br/>
                <br/>
                <ul class='notification-list-section'>
                  <li>
                    <div class='notification-sales-order-mention-badge'>
                      <span>
                ${notification.SONumber}
                </span>
                    </div>
                  </li>
                  <li>${notification.customerName}</li>
                  
                  <li>
                    <div class='notification-purchase-order-mention-badge'>
                      <span>
                      ${notification.PONumber}
                      </span>
                    </div>

                  </li>
                  <li>${notification.factoryName}</li>
                </ul>
                <br/>
                ${getDueDateFormat(notification.finishDate)}
              </span>`.replace(/\s\s+/g, " ");
    case NOTIFICATION_SCOPE.SHIPMENT_TASK_CREATED:
      return `
                        <span>
                          <strong>@${getUserDisplayName(
                            users,
                            notification.createdBy
                          )}</strong> created a task for you:
                        </span>
                        <br/>
                        <br/>
                        <span>
                          <strong>${notification.description} </strong>
                          <br/>
                          <br/>
                          <ul class='notification-list-section'>
                            <li>
                              <div class='notification-sales-order-mention-badge' >
                                <span>
                ${notification.SONumber}
                </span>
                              </div>
                            </li>
                            <li>${notification.customerName}</li>
                            <li>
                              <div class='notification-shipment-mention-badge-container' >
                                <div class='notification-shipment-mention-badge' >
                                <span>
                                ${notification.shipmentNumber} 
                                </span>
                                </div>
                                ${
                                  notification.shipment
                                    ? notification.shipment.finalDestination ||
                                      ""
                                    : ""
                                }
                              </div>
                            </li> 
                          </ul>
                          <br/>
                          ${getDueDateFormat(notification.finishDate)}
                        </span>`.replace(/\s\s+/g, " ");

    case NOTIFICATION_SCOPE.SO_TASK_LATE:
      return `
          <span>
            <strong>@${getUserDisplayName(
              users,
              notification.completedBy
            )}</strong> completed a task late${
        notification.assignedTo !== notification.completedBy
          ? " on behalf of "
          : ""
      }${
        notification.assignedTo !== notification.completedBy
          ? `<strong>@${getUserDisplayName(
              users,
              notification.assignedTo
            )}</strong>`
          : ""
      }: 
            <br/>
            <br/>
          <span>
            <strong>${notification.description}</strong>
            <br/>
            <br/>
            <ul class='notification-list-section'>
              <li>
                <div class='notification-sales-order-mention-badge'>
                  <span>
                ${notification.SONumber}
                </span>
                </div>
              </li>
              <li>${notification.customerName}</li>
            </ul>
            <br/>
            Was 
            <span style='color: red'>
              completed
              ${
                getDayOffset(notification) === 1
                  ? getDayOffset(notification) + " day late"
                  : getDayOffset(notification) + " days late"
              }.
            </span><br><br>
            ${
              notification.isAbleToModifyTaskDueDate
                ? notification.confirmedTask
                  ? `<del><strong>Move all dependent tasks forward</strong></del>?`
                  : "<strong>Move all dependent tasks forward?</strong>"
                : ""
            }
          </span>`.replace(/\s\s+/g, " ");
    case NOTIFICATION_SCOPE.PO_TASK_LATE:
      return `
            <span>
              <strong>@${getUserDisplayName(
                users,
                notification.completedBy
              )}</strong> completed a task late${
        notification.assignedTo !== notification.completedBy
          ? " on behalf of "
          : ""
      }${
        notification.assignedTo !== notification.completedBy
          ? `<strong>@${getUserDisplayName(
              users,
              notification.assignedTo
            )}</strong>`
          : ""
      }: 
              <br/>
              <br/>
            <span>
              <strong>${notification.description}</strong>
              <br/>
              <br/>
              <ul class='notification-list-section'>
                <li>
                  <div class='notification-sales-order-mention-badge'>
                    <span>
                ${notification.SONumber}
                </span>
                  </div>
                </li>
                <li>${notification.customerName}</li>
                <li>
                  <div class='notification-purchase-order-mention-badge'>
                    <span> 
                    ${notification.PONumber}
                    </span>
                  </div>
                </li>
                <li>${notification.factoryName}</li>
              </ul>
              <br/>
              Was 
              <span style='color: red'>
                completed
                ${
                  getDayOffset(notification) === 1
                    ? getDayOffset(notification) + " day late"
                    : getDayOffset(notification) + " days late"
                }.
              </span><br><br>
              ${
                notification.isAbleToModifyTaskDueDate
                  ? notification.confirmedTask
                    ? `<del><strong>Move all dependent tasks forward</strong></del>?`
                    : "<strong>Move all dependent tasks forward?</strong>"
                  : ""
              }
            </span>`.replace(/\s\s+/g, " ");
    case NOTIFICATION_SCOPE.SHIPMENT_TASK_LATE:
      return `
          <span>
            <strong>@${getUserDisplayName(
              users,
              notification.completedBy
            )}</strong> completed a task late${
        notification.assignedTo !== notification.completedBy
          ? " on behalf of "
          : ""
      }${
        notification.assignedTo !== notification.completedBy
          ? `<strong>@${getUserDisplayName(
              users,
              notification.assignedTo
            )}</strong>`
          : ""
      }: 
            <br/>
            <br/>
          <span>
            <strong>${notification.description}</strong>
            <br/>
            <br/>
            <ul class='notification-list-section'>
              <li>
                <div class='notification-sales-order-mention-badge' >
                  <span>
                ${notification.SONumber}
                </span>
                </div>
              </li>
              <li>${notification.customerName}</li>
              <li>
                <div class='notification-shipment-mention-badge-container' >
                  <div class='notification-shipment-mention-badge' >
                  <span>
                  ${notification.shipmentNumber} 
                  </span>
                  </div>
                  ${
                    notification.shipment
                      ? notification.shipment.finalDestination || ""
                      : ""
                  }
                </div>
              </li>
            </ul>
            <br/>
            Was 
            <span style='color: red'>
              completed
              ${
                getDayOffset(notification) === 1
                  ? getDayOffset(notification) + " day late"
                  : getDayOffset(notification) + " days late"
              }.
            </span><br><br>
            ${
              notification.isAbleToModifyTaskDueDate
                ? notification.confirmedTask
                  ? `<del><strong>Move all dependent tasks forward</strong></del>?`
                  : "<strong>Move all dependent tasks forward?</strong>"
                : ""
            }
          </span>`.replace(/\s\s+/g, " ");
    case NOTIFICATION_SCOPE.SO_TASK_EARLY:
      return `
        <span>
          <strong>@${getUserDisplayName(
            users,
            notification.completedBy
          )}</strong> completed a task early${
        notification.assignedTo !== notification.completedBy
          ? " on behalf of "
          : ""
      }${
        notification.assignedTo !== notification.completedBy
          ? `<strong>@${getUserDisplayName(
              users,
              notification.assignedTo
            )}</strong>`
          : ""
      }: 
        <br/>
        <br/>
      <span>
        <strong>${notification.description}</strong>
        <br/>
        <br/>
        <ul class='notification-list-section'>
          <li>
            <div class='notification-sales-order-mention-badge'>
              <span>
                ${notification.SONumber}
                </span>
            </div>
          </li>
          <li>${notification.customerName}</li>
        </ul>
        <br/>
        Was 
        <span style='color: green'>
          completed
          ${
            getDayOffset(notification) === 1
              ? getDayOffset(notification) + " day early"
              : getDayOffset(notification) + " days earlier"
          }.
        </span><br><br>
        ${
          notification.isAbleToModifyTaskDueDate
            ? notification.confirmedTask
              ? `<del><strong>Move all dependent tasks earlier</strong></del>?`
              : "<strong>Move all dependent tasks earlier?</strong>"
            : ""
        }
      </span>`.replace(/\s\s+/g, " ");
    case NOTIFICATION_SCOPE.PO_TASK_EARLY:
      return `
        <span>
          <strong>@${getUserDisplayName(
            users,
            notification.completedBy
          )}</strong> completed a task early${
        notification.assignedTo !== notification.completedBy
          ? " on behalf of "
          : ""
      }${
        notification.assignedTo !== notification.completedBy
          ? `<strong>@${getUserDisplayName(
              users,
              notification.assignedTo
            )}</strong>`
          : ""
      }: 
          <br/>
          <br/>
        <span>
          <strong>${notification.description}</strong>
          <br/>
          <br/>
          <ul class='notification-list-section'>
            <li>
              <div class='notification-sales-order-mention-badge'>
                <span>
                ${notification.SONumber}
                </span>
              </div>
            </li>
            <li>${notification.customerName}</li>
            <li>
              <div class='notification-purchase-order-mention-badge'>
              <span>
              ${notification.PONumber}
              </span>
              </div>
            </li>
            <li>${notification.factoryName}</li>
          </ul>
          <br/>
          Was 
          <span style='color: green'>
            completed
            ${
              getDayOffset(notification) === 1
                ? getDayOffset(notification) + " day early"
                : getDayOffset(notification) + " days early"
            }.
          </span><br><br>
          ${
            notification.isAbleToModifyTaskDueDate
              ? notification.confirmedTask
                ? `<del><strong>Move all dependent tasks earlier</strong></del>?`
                : "<strong>Move all dependent tasks earlier?</strong>"
              : ""
          }
        </span>`.replace(/\s\s+/g, " ");
    case NOTIFICATION_SCOPE.SHIPMENT_TASK_EARLY:
      return `
            <span>
              <strong>@${getUserDisplayName(
                users,
                notification.completedBy
              )}</strong> completed a task early${
        notification.assignedTo !== notification.completedBy
          ? " on behalf of "
          : ""
      }${
        notification.assignedTo !== notification.completedBy
          ? `<strong>@${getUserDisplayName(
              users,
              notification.assignedTo
            )}</strong>`
          : ""
      }: 
            <br/>
            <br/>
          <span>
            <strong>${notification.description}</strong>
            <br/>
            <br/>
            <ul class='notification-list-section'>
              <li>
                <div class='notification-sales-order-mention-badge' >
                  <span>
                ${notification.SONumber}
                </span>
                </div>
              </li>
              <li>${notification.customerName}</li>
              <li>
                <div class='notification-shipment-mention-badge-container' >
                <div class='notification-shipment-mention-badge' >
                <span>
                ${notification.shipmentNumber} 
                </span>
                </div>
                ${
                  notification.shipment
                    ? notification.shipment.finalDestination || ""
                    : ""
                }
                </div>
              </li>
            </ul>
            <br/>
            Was 
            <span style='color: green'>
              completed
              ${
                getDayOffset(notification) === 1
                  ? getDayOffset(notification) + " day early"
                  : getDayOffset(notification) + " days earlier"
              }.
            </span><br><br>
            ${
              notification.isAbleToModifyTaskDueDate
                ? notification.confirmedTask
                  ? `<del><strong>Move all dependent tasks earlier</strong></del>?`
                  : "<strong>Move all dependent tasks earlier?</strong>"
                : ""
            }
          </span>`.replace(/\s\s+/g, " ");

    case NOTIFICATION_SCOPE.API_ERROR_LOG:
      return `<span><strong>API error</strong></span>
        <br/><br/> 
        <span>${notification.description}</span> `;

    case NOTIFICATION_SCOPE.TRADEDASH_WARNING_NOTIFICATION:
      return `<span><strong>TradeDash warning</strong></span>
        <br/><br/> 
        <span>${notification.description}</span> `;
    default:
      return "New Notification!";
  }
}

export const verifyPermissionNotification = async ({
  notification = {},
  isAllowed = () => {},
  idKey,
  companyId,
  getDocumentsQuery = () => {},
  getDocumentQuery = () => {},
  currentUser,
}) => {
  const companyRef = `${dbTables.COMPANIES}/${companyId}`;
  const {
    salesOrderId,
    shipmentId,
    purchaseOrderId,
    mainDocumentId,
    factoryId,
    customerId,
  } = notification;
  let hasPermission;
  let block = true;
  let path;
  const notificationShipment = notification.shipment || {};
  switch (notification.scope) {
    case NOTIFICATION_SCOPE.PO_TASK_CREATED:
    case NOTIFICATION_SCOPE.PO_TASK_EARLY:
    case NOTIFICATION_SCOPE.PO_TASK_LATE:
    case NOTES_SCOPE.PURCHASE_ORDER:
    case NOTIFICATION_SCOPE.PO_MENTION:
    case NOTIFICATION_SCOPE.TRADEDASH_WARNING_NOTIFICATION:
      hasPermission =
        isAllowed(factoryId) || isAllowed(GENERAL_PERMISSION_VALUE.ALL_VENDORS);
      const customerPermission =
        isAllowed(customerId) ||
        isAllowed(GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS);
      if (hasPermission && customerPermission) {
        const poSalesOrder =
          (await getDocumentsQuery(
            query(
              collection(firestore, `${companyRef}/${dbTables.SALES_ORDERS}`),
              where("purchaseOrderIds", "array-contains", purchaseOrderId)
            )
          )) || [];
        const soAllowed = poSalesOrder.find(
          (so) =>
            isAllowed(so.customerId) ||
            isAllowed(GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS)
        );
        if (!soAllowed) {
          block = globalScopes.SALES_ORDER;
        } else {
          const purchaseOrder = await getDocumentQuery(
            doc(
              firestore,
              `${companyRef}/${dbTables.PURCHASE_ORDERS}/${purchaseOrderId}`
            )
          );
          path = dashboardRedirect({
            salesOrderId: soAllowed.id,
            purchaseOrderId: purchaseOrderId,
            shipmentId:
              (purchaseOrder &&
                purchaseOrder.shipmentIds &&
                purchaseOrder.shipmentIds[0]) ||
              "",
            id: mainDocumentId,
            idKey,
          });
          block = false;
        }
      } else {
        block = !customerPermission
          ? globalScopes.SALES_ORDER
          : globalScopes.PURCHASE_ORDER;
      }

      break;
    case NOTIFICATION_SCOPE.SO_TASK_CREATED:
    case NOTIFICATION_SCOPE.SO_TASK_EARLY:
    case NOTIFICATION_SCOPE.SO_TASK_LATE:
    case NOTIFICATION_SCOPE.SO_MENTION:
    case NOTES_SCOPE.SALES_ORDER:
      hasPermission =
        isAllowed(customerId) ||
        isAllowed(GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS);
      if (hasPermission) {
        const soData = await getDocumentQuery(
          doc(
            firestore,
            `${companyRef}/${dbTables.SALES_ORDERS}/${salesOrderId}`
          )
        );
        const purchaseOrderIds = soData.purchaseOrderIds || [];
        const purchaseOrdersDB = await getPurchaseOrders({
          purchaseOrderIds,
          companyId,
          user: currentUser,
        });
        if (purchaseOrdersDB.length !== 0) {
          const purchaseOrder = purchaseOrdersDB[0];
          path = dashboardRedirect({
            salesOrderId,
            purchaseOrderId: purchaseOrder.id,
            shipmentId:
              (purchaseOrder &&
                purchaseOrder.shipmentIds &&
                purchaseOrder.shipmentIds[0]) ||
              "",
            id: mainDocumentId,
            idKey,
          });
          block = false;
        } else {
          block = globalScopes.PURCHASE_ORDER;
        }
      } else {
        block = globalScopes.SALES_ORDER;
      }
      break;
    default:
      hasPermission =
        isAllowed(customerId) ||
        isAllowed(GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS);
      if (hasPermission) {
        const poShipments = await getDocumentsQuery(
          query(
            collection(firestore, `${companyRef}/${dbTables.PURCHASE_ORDERS}`),
            where("shipmentIds", "array-contains", shipmentId)
          )
        );
        const poAllowed = poShipments.find(
          (po) =>
            (isAllowed(po.factoryId) ||
              isAllowed(GENERAL_PERMISSION_VALUE.ALL_VENDORS)) &&
            po.salesOrderIds.length !== 0
        );
        if (!poAllowed) {
          block = "PERMISSION_TO_CUSTOMER_BUT_NONE_OF_VENDOR";
        } else {
          path = dashboardRedirect({
            salesOrderId: poAllowed.salesOrderIds[0],
            purchaseOrderId: poAllowed.id,
            shipmentId: notificationShipment.id || notification.shipmentId,
            id: mainDocumentId,
            idKey,
          });
          block = false;
        }
      } else {
        block = globalScopes.SALES_ORDER;
      }
      break;
  }
  return { newBlock: block, newPath: path, isShipment: true };
};

export const verifyRouteTaskPermission = ({
  notification = {},
  isAllowed = () => {},
  currentShipment = {},
  purchaseOrders = [],
  idKey,
}) => {
  const {
    salesOrderId,
    shipmentId,
    purchaseOrderId,
    mainDocumentId,
    customerId,
  } = notification;

  if (
    !isAllowed(customerId) &&
    !isAllowed(GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS)
  ) {
    return { canRedirect: false, block: globalScopes.SALES_ORDER };
  }

  let poAllowed = purchaseOrders.filter(
    (po) =>
      isAllowed(po.factoryId) || isAllowed(GENERAL_PERMISSION_VALUE.ALL_VENDORS)
  );

  if (poAllowed.length === 0) {
    return {
      canRedirect: false,
      block: globalScopes.PURCHASE_ORDER,
    };
  }
  const currentPO = poAllowed.find((po) => po.id === purchaseOrderId);
  let shipmentPath = false;
  switch (notification.scope) {
    case NOTIFICATION_SCOPE.PO_TASK_CREATED:
    case NOTIFICATION_SCOPE.PO_TASK_EARLY:
    case NOTIFICATION_SCOPE.PO_TASK_LATE:
    case NOTES_SCOPE.PURCHASE_ORDER:
    case NOTIFICATION_SCOPE.TRADEDASH_WARNING_NOTIFICATION:
      if (currentPO) {
        return {
          path: dashboardRedirect({
            salesOrderId,
            purchaseOrderId,
            shipmentId: currentPO.shipmentIds[0] || "",
            id: mainDocumentId,
            idKey,
          }),
          canRedirect: true,
        };
      } else {
        return {
          canRedirect: false,
          block: globalScopes.PURCHASE_ORDER,
        };
      }

    default:
      if (shipmentId) {
        let shipmentIds = [];
        poAllowed = poAllowed.filter((po) => {
          if (po.shipmentIds.includes(shipmentId)) {
            shipmentIds = [...shipmentIds, ...po.shipmentIds];
            return true;
          }
          return false;
        });
        if (
          shipmentIds.includes(shipmentId) &&
          currentShipment &&
          poAllowed.length !== 0
        ) {
          shipmentPath = currentShipment.id || shipmentId;
        } else {
          return {
            canRedirect: false,
            block: globalScopes.SHIPMENT,
          };
        }
      }
      const currentPurchaseOrder = currentPO || {};
      const isPOAllowed = poAllowed.find(
        (po) => po.id === currentPurchaseOrder.id
      );
      if (currentPO && isPOAllowed) {
        return {
          path: dashboardRedirect({
            salesOrderId,
            purchaseOrderId,
            shipmentId: shipmentPath,
            id: mainDocumentId,
            idKey,
          }),
          canRedirect: true,
          shipmentId: shipmentPath,
        };
      } else {
        return {
          path: dashboardRedirect({
            salesOrderId,
            purchaseOrderId: poAllowed[0].id,
            shipmentId: shipmentPath,
            id: mainDocumentId,
            idKey,
          }),
          canRedirect: true,
          shipmentId: shipmentPath,
        };
      }
  }
};
