import React, { useState } from "react";
import { useAutoCompleteLists } from "../../hooks";
import { SettingsTabTable } from "./styles";
import { getSettingsTableHeader } from "./settingsHelper";
import { TYPE_OF_AUTOCOMPLETE_LISTS } from "../../helpers/constants";
import { cx } from "@emotion/css";
import { TrashCanIcon } from "../../helpers/iconSvgPath";
import { colors } from "../../assets/jss/variables";
import TooltipTD from "../Tooltip/TooltipTD";
import { updateDoc } from "firebase/firestore";
import AttentionModal from "../Modal/AttentionModal";

const tableheader = [
  {
    id: 1,
    label: "title.text.Supplier",
    key: "name",
    canBeSort: true,
  },
  {
    id: 2,
    label: "generic.text.blank",
    key: "",
  },
  {
    id: 3,
    label: "generic.text.blank",
    key: "",
  },
];

function SuppliersTab() {
  const uniquePaidToMiscellaneo = useAutoCompleteLists({
    id: TYPE_OF_AUTOCOMPLETE_LISTS.UNIQUE_PAID_TO,
  });
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    supplier: "",
  });
  const [nonLookUpfilters, setNonLookUpFilters] = useState({
    user: false,
    sortedColumn: "name",
    orderBy: "DESC",
  });

  const sortList = ({ supplierList }) => {
    return [...supplierList].sort((a, b) => {
      const comparison = a.toLowerCase().localeCompare(b.toLowerCase());
      return nonLookUpfilters.orderBy !== "DESC" ? -comparison : comparison;
    });
  };

  const tbodyDisplay = ({ supplierList }) => {
    return sortList({ supplierList }).map((supplierItem) => {
      return (
        <div className="rowTable" key={supplierItem}>
          <div className={cx("cellTable", "name")}>{supplierItem}</div>
          <div className={cx("cellTable", "actionButtonsContainer")}>
            <TooltipTD
              label={"Delete Supplier"}
              tooltipClick={(ev) => {
                setDeleteModal({
                  open: true,
                  supplier: supplierItem,
                });
                ev.stopPropagation();
              }}
            >
              <TrashCanIcon
                size={20}
                color={colors.diamondRed}
                svgClass={"trashIcon"}
              />
            </TooltipTD>
          </div>
          <div />
        </div>
      );
    });
  };
  return (
    <div className="mainTableContainer">
      {deleteModal.open && (
        <AttentionModal
          title="Attention"
          description={"  Are you sure to delete this supplier?"}
          isOpen={deleteModal.open}
          onClick={() => {
            const list = uniquePaidToMiscellaneo.list;
            const newList = list.filter(
              (item) => item !== deleteModal.supplier
            );
            updateDoc(uniquePaidToMiscellaneo.ref, {
              list: newList,
            });
            setDeleteModal({
              open: false,
              supplier: "",
            });
          }}
          cancellable
          confirmationText="Ok"
          onClose={() =>
            setDeleteModal({
              open: false,
              supplier: "",
            })
          }
        />
      )}
      <div className="action-buttons-container" />
      <SettingsTabTable className="SupplierTable">
        {getSettingsTableHeader({
          headers: tableheader,
          filters: nonLookUpfilters,
          updateSorting: (nonLookUpfilters) => {
            setNonLookUpFilters({ ...nonLookUpfilters });
          },
        })}
        {tbodyDisplay({
          supplierList: uniquePaidToMiscellaneo.list,
        })}
      </SettingsTabTable>
    </div>
  );
}

export default SuppliersTab;
