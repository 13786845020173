import React, { useState, useEffect, Fragment } from "react";
import ItemTableButtons from "../Buttons/ItemTableButtons";
import { useTags, tagsByType } from "../../hooks/tags";
import { useStorage } from "../../hooks/index";
import { useCustomers } from "../../hooks/customers";
import {
  filterSORowTableItem,
  formatCsvPOtable,
  parseRowTableItem,
  saveItem,
  updateSOTag,
} from "./SKUTables/SKUHelper";
import { useCurrentShipment, useShipments } from "../../hooks/shipments";
import { PREFIX_ITEM_TABLE, TYPE_OF_TAGS } from "../../helpers/constants";
import { useIsAllowedFunction } from "../../hooks/permissions";
import {
  SOSKUheader,
  csvSOHeader,
  pdfSOHeader,
} from "../../helpers/itemsTableColumns";
import { onChangeSoSKUData } from "../../helpers/orderDashboardRefactored";
import { writeBatch } from "firebase/firestore";
import ExpandSimpleTableSKUModal from "../Modal/ExpandSimpleTableSKUModal";
import { trace } from "firebase/performance";
import { firestore, performanceFirebase } from "../../firebase";
import { difference } from "lodash";
import SKUTableSimple from "./SKUTables/SKUTableSimple";

function SOTableSKU({
  prefix,
  dataTable = [],
  currentPurchaseOrder = {},
  currentSalesOrder = {},
  purchaseOrders = [],
  handleShowImage = () => {},
  isModal,
  modalShowImage,
  cleanSearchBox,
  onWriteSearch,
  handleSearchBox,
  filterText,
  hasMismatchTag = false,
}) {
  const [imagesTableStorage, setImagesTableStorage] =
    useStorage("item_table_images");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const customers = useCustomers();
  const tags = useTags();
  const shipments = useShipments();
  const [showImage, setShowImage] = useState(
    !imagesTableStorage[prefix] || !!modalShowImage
  );
  const [rowTable, setRowTable] = useState(dataTable);
  const [isLoading, setIsLoading] = useState(false);
  const [isCopyTable, setIsCopyTable] = useState(true);
  const [sortTable, setSortTable] = useState({
    sortedColumn: "itemNumber",
    orderBy: "ASC",
  });
  const isAllowed = useIsAllowedFunction();
  const [expandModal, setExpandModal] = useState(false);

  const currentShipmentDB = useCurrentShipment({
    purchaseOrder: currentPurchaseOrder,
  });

  useEffect(() => {
    if (difference(dataTable, rowTable)) {
      setRowTable(dataTable);
    }
  }, [dataTable]);

  const saveTags = ({ allRows, batch }) => {
    updateSOTag({
      items: allRows,
      purchaseOrders,
      tags: tagsByType(tags, TYPE_OF_TAGS.MISMATCH_TAG),
      batch,
    });
  };

  async function onChangeData({ item, row }) {
    const traceInstance = trace(performanceFirebase, "dashboard_update_so_sku");
    const batch = writeBatch(firestore);
    traceInstance.start();
    setIsLoading(true);
    const newRow = onChangeSoSKUData({
      item,
      row,
      currentSalesOrder,
    });
    saveItem({ newRow, batch });
    const allRows = rowTable.map((row) =>
      row.id === newRow.id ? newRow : row
    );
    setRowTable(allRows);
    saveTags({ allRows, batch });
    const cpyItems = allRows.map((soItem) => ({
      id: soItem.id,
      cbmMaster: soItem.cbmMaster,
      description: soItem.description,
      image: [soItem.image[0] || ""],
      itemNumber: soItem.itemNumber,
      orderQuantity: parseFloat(
        soItem.salesOrderQuantity[currentSalesOrder.id] || 0
      ),
      piecesPerMaster: soItem.piecesPerMaster,
      price: parseFloat(soItem.salesOrderPrice[currentSalesOrder.id] || 0),
    }));
    batch.update(currentSalesOrder.ref, {
      items: cpyItems,
    });
    await batch.commit();
    setIsLoading(false);
    traceInstance.stop();
  }

  const handleChangeShowImage = (value) => {
    setShowImage(value);
    handleShowImage(value);
  };

  const handleChange = (event, forceStatus) => {
    const showImage = event ? event.target.checked : forceStatus;
    setImagesTableStorage(prefix, !showImage);
    setShowImage(showImage);
    handleShowImage(showImage);
  };

  const currentRowTable = filterSORowTableItem({
    rowTable,
    searchText: filterText,
  });

  const actualRows = parseRowTableItem({
    rows: currentRowTable,
    sortTable,
    currentIdPath: {
      currentShipmentId: currentShipmentDB.id,
      currentSalesOrderId: currentSalesOrder.id,
      currentPurchaseOrder: currentPurchaseOrder.id,
    },
    columnsTable: SOSKUheader,
    purchaseOrders,
  });

  const getHeaderByScope = () =>
    csvSOHeader.filter((header) => {
      if (header.amountPermission && !isAllowed(header.amountPermission)) {
        return false;
      }
      return true;
    });

  const getPDFHeaderByScope = () =>
    pdfSOHeader.filter((header) => {
      if (header.amountPermission && !isAllowed(header.amountPermission)) {
        return false;
      }
      return true;
    });

  const idExpandModal = isModal ? "ExpandModal" : "";

  return (
    <Fragment>
      {expandModal && !isModal && (
        <ExpandSimpleTableSKUModal
          isOpen={expandModal}
          columnsTable={SOSKUheader}
          currentPurchaseOrder={currentPurchaseOrder}
          currentSalesOrder={currentSalesOrder}
          rowTable={rowTable}
          setExpandModal={setExpandModal}
          key={`so_expand`}
          purchaseOrders={purchaseOrders}
          handleShowImage={(show) => {
            handleChange(false, show);
          }}
          modalShowImage={showImage}
          prefix={prefix}
          hasMismatchTag={hasMismatchTag}
        />
      )}

      <ItemTableButtons
        isModal={isModal}
        idExpandModal={idExpandModal}
        isAllOpen={true}
        handleHiddenImages={() => {
          let currentShowImage = showImage;
          handleChangeShowImage(false);
          if (currentShowImage) {
            setTimeout(() => {
              handleChangeShowImage(currentShowImage);
            }, 500);
          }
        }}
        csvData={formatCsvPOtable({
          rows: actualRows,
          shipments,
          customers,
          currentShipment: currentShipmentDB,
          currentSalesOrder,
        })}
        prefix={prefix}
        tableHeader={getHeaderByScope()}
        onExpandModal={() => setExpandModal(true)}
        pdfTableHeader={getPDFHeaderByScope()}
        handleOnCopyTable={(value) => setIsCopyTable(value)}
        cleanSearchBox={cleanSearchBox}
        onWriteSearch={onWriteSearch}
        handleSearchBox={handleSearchBox}
        handleOpenSnackBar={(value) =>
          setTimeout(() => {
            setOpenSnackBar(value);
          }, 50)
        }
        hasMismatchTag={hasMismatchTag}
      />
      {(isModal || !expandModal) && (
        <SKUTableSimple
          actualRows={actualRows}
          entity={currentSalesOrder}
          imagesTableStorage={imagesTableStorage}
          isCopyTable={isCopyTable}
          isLoading={isLoading}
          isModal={isModal}
          modalFilterText={filterText}
          modalShowImage={modalShowImage}
          onChangeData={onChangeData}
          prefix={PREFIX_ITEM_TABLE.SALES_ORDER}
          purchaseOrders={purchaseOrders}
          tableHeader={SOSKUheader}
          openSnackBar={openSnackBar}
          handleSnackBar={(value) => setOpenSnackBar(value)}
          handleSorting={(sorting) => setSortTable(sorting)}
          sorting={sortTable}
        />
      )}
    </Fragment>
  );
}

export default SOTableSKU;
