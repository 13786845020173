import moment from "moment";
import { getRandomId } from "../../helpers/helpers";

export default class MetaDataShipment {
  constructor({ id = getRandomId(), name = "", created = moment().valueOf() }) {
    this.id = id;
    this.name = name;
    this.created = created;
  }
}
