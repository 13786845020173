import React, { useEffect, useRef, useState } from "react";
import folderPlaceholder from "../../assets/flag-icons/folder-placeholder.svg";
import filePlaceholder from "../../assets/flag-icons/file-placeholder.svg";
import { TYPE_OF_FILE } from "../../helpers/constants";
import { InputBase } from "@mui/material";
import styled from "@emotion/styled";
import { colors } from "../../assets/jss/variables";
import { CreateNewFolderContainerStyled } from "./styles";
import {
  CancelCreationIcon,
  DoneCreationIcon,
} from "../../helpers/iconSvgPath";

const InputField = styled(InputBase)(({ theme }) => ({
  "&.rootInput": {
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #D2DFEC",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "16px",
    padding: "8px 10px",
    borderRadius: 4,
    color: colors.primaryDark,
    height: 32,
    width: "100%",
    "& input": {
      padding: "4px 0 4px",
    },
  },
}));

function CreateFileFolderTemplate({
  itemDocument,
  handleSaveFolder,
  handleCancel,
}) {
  const [name, setName] = useState(itemDocument.name || "New Folder");
  const inputRef = useRef(null);

  const onFocus = () => {
    inputRef.current?.focus();
    inputRef.current?.select();
  };

  useEffect(() => {
    setTimeout(() => {
      onFocus();
    }, 150);
  }, []);

  useEffect(() => {
    setName(itemDocument.name);
  }, [itemDocument.type]);

  return (
    <CreateNewFolderContainerStyled className="createNewFolderContainer">
      <img
        src={
          itemDocument.type === TYPE_OF_FILE.FOLDER
            ? folderPlaceholder
            : filePlaceholder
        }
        className={"imgListItemIcon"}
        alt="file"
        height={30}
      />

      <InputField
        autoFocus
        value={name}
        inputRef={inputRef}
        className="rootInput"
        onChange={(e) => {
          const { value } = e.target;
          const cleanedValue = value.replace(/\s+/g, " ");
          const validCharacters = /^[^\t\\/\\]+$/;
          if (cleanedValue === "" || validCharacters.test(cleanedValue)) {
            setName(value);
          }
        }}
        onKeyDown={(ev) => {
          if (ev.key === "Enter") {
            handleSaveFolder(name);
          }
          if (ev.key === "Escape") {
            handleCancel();
          }
        }}
      />
      <div className="buttonOptions">
        <CancelCreationIcon
          onClick={handleCancel}
          type="danger"
          containerColor="unset"
        />
        <DoneCreationIcon onClick={() => handleSaveFolder(name)} />
      </div>
    </CreateNewFolderContainerStyled>
  );
}

export default CreateFileFolderTemplate;
