import React, { useState, useEffect, useCallback } from "react";
import { WeeklyViewStyled } from "./styles";
import GeneralFilters from "../../components/FilterTable/GeneralFilters";
import ButtonFilter from "../../components/PurchaseOrderList/ButtonFilter";
import { useCompanyId, useJSONLocalStorage } from "../../hooks";
import WeeklyContent from "../../components/WeeklyView/WeeklyContent";
import DayCard from "../../components/WeeklyView/DayCard";
import moment from "moment";
import FilterDisplay from "../../components/PurchaseOrderList/FilterDisplay";
import { dbTables, typeOfTask } from "../../api/types/dbTables";
import SearchBox from "../../components/SearchBox/SearchBox";
import {
  sortObjectsBy,
  useTaskNotificationCreator,
  getRandomId,
  getWeekTabScreenFormatted,
  isTradeDashEmployee,
  taskNotificationType,
  debounce,
} from "../../helpers/helpers";
import { getToolTipArrayValue } from "../../helpers/purchaseOrderList";
import Loader from "../../components/General/Loader";
import User from "../../api/model/user";
import arrowIcon from "../../assets/flag-icons/arrow_icon.png";
import { useIsAllowedFunction } from "../../hooks/permissions";
import { useUser, useCompanyUsers } from "../../hooks/user";
import ReadOnlyModal from "../../components/Modal/ReadOnlyModal";
import { useFactories } from "../../hooks/factories";
import { useCustomers } from "../../hooks/customers";
import { triggerTaskTypes } from "../../helpers/tasks";
import {
  excludeElementsScreen,
  taskStatus,
  TYPE_OF_TAGS,
} from "../../helpers/constants";
import { useTags } from "../../hooks/tags";
import { getFilteredTaskStatus } from "../../helpers/screens";
import {
  getCorrectTimezone,
  taskCompletionVerifier,
} from "../../helpers/ganttChart";
import { firestore, performanceFirebase } from "../../firebase";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import { trace } from "firebase/performance";
import { doc, updateDoc } from "firebase/firestore";
import {
  getFunctionByName,
  globalEnvironment,
} from "../../constants/globalVariables";
import Mark from "mark.js";
import { Button, useMediaQuery } from "@mui/material";
import { isEqual } from "lodash";
import DiamondLegend from "../../components/LegendStatus/DiamondLegend";
import TaskStatusFilters from "../../components/ListViews/TaskStatusFilters";

function WeeklyView() {
  const { get, set } = useJSONLocalStorage("weekFilters");
  const weekTabsLocalStorage = useJSONLocalStorage("weekTabs");
  const user = new User(useUser());
  const companyId = useCompanyId();
  const companyUsers = useCompanyUsers({});
  const tags = useTags();
  const [dayExpandedProps, setDayExpandedProps] = useState({});
  const [openReadOnlyModal, setReadOnlyModal] = useState(false);
  const filtersLocalStorage = get() || {};
  const [filters, setFilters] = useState({
    assignedTo: filtersLocalStorage.assignedTo || [user.id],
    customer: filtersLocalStorage.customer || [],
    factory: filtersLocalStorage.factory || [],
    tag: filtersLocalStorage.tag || [],
    weekPage: filtersLocalStorage.weekPage || 0,
  });
  const [openList, setOpenList] = useState(false);
  const [callFunction, setCallFunction] = useState(true);
  const [previousFilter, setPreviousFilter] = useState({});
  const [nonLookUpfilters, setNonLookUpFilters] = useState(
    get()
      ? {
          completed: filtersLocalStorage.completed || false,
          late: filtersLocalStorage.late || false,
          nearDue: filtersLocalStorage.nearDue || false,
          inProgress: filtersLocalStorage.inProgress || false,
          notStarted: filtersLocalStorage.notStarted || false,
        }
      : { late: true, nearDue: true, inProgress: true, notStarted: true }
  );
  const [loading, setLoading] = useState(false);
  const [weeksTask, setWeeksTask] = useState({});
  const [weeksTaskFiltered, setWeeksTaskFiltered] = useState({});
  const factories = useFactories();
  const customers = useCustomers();
  const matches = useMediaQuery("(max-width:1425px)");
  const createTaskNotification = useTaskNotificationCreator();
  const isAllowed = useIsAllowedFunction();
  const onDebounce = useCallback(
    debounce(() => {
      setCallFunction(true);
    }, 1250),
    []
  );

  function changeFilters(newFilters) {
    if (!isEqual(filters, newFilters)) {
      onDebounce();
      setFilters({ ...newFilters });
    }
  }

  useEffect(() => {
    if (!openList && !isEqual(filters, previousFilter)) {
      onDebounce();
    }
  }, [openList]);

  useEffect(() => {
    if (!callFunction) {
      return;
    }
    setCallFunction(false);
    if (openList) {
      return;
    }
    if (isEqual(filters, previousFilter)) {
      console.log("same filter");
      return;
    }
    const dayExpandedName = filtersLocalStorage.dayExpandedName || false;
    set({
      ...nonLookUpfilters,
      ...filters,
      dayExpandedName,
    });
    setWeeksTaskFiltered({});
    setWeeksTask({});
    getTasks();
  }, [callFunction]);

  useEffect(() => {
    const dayExpandedName = filtersLocalStorage.dayExpandedName || false;
    set({
      ...nonLookUpfilters,
      ...filters,
      dayExpandedName,
    });
    if (Object.keys(weeksTask).length === 0) {
      return;
    }
    const filteredTasks = filterTasks({ ...weeksTask });
    if (Object.keys(dayExpandedProps).length > 0) {
      const dayExpandedPropsCpy = { ...dayExpandedProps };
      dayExpandedPropsCpy.tasks = filteredTasks[dayExpandedProps.day.label];
      setDayExpandedProps({ ...dayExpandedPropsCpy });
    }
    setWeeksTaskFiltered(filteredTasks);
  }, [nonLookUpfilters]);

  useEffect(() => {
    if (filtersLocalStorage.dayExpandedName) {
      onExpand(
        {
          day: {
            label: filtersLocalStorage.dayExpandedName,
          },
        },
        filtersLocalStorage.dayExpandedName,
        true
      );
    }
  }, []);

  async function getTasks() {
    console.log("lookup");
    setPreviousFilter(filters);
    const dateStart = moment()
      .startOf("isoWeek")
      .add(filters.weekPage, "weeks")
      .valueOf();
    const dateEnd = moment()
      .endOf("isoWeek")
      .add(filters.weekPage, "weeks")
      .valueOf();

    const traceInstance = trace(performanceFirebase, "week_view_db_lookup");
    traceInstance.start();

    setLoading(true);
    const { status } = getFilteredTaskStatus({ filters });
    const filtersToSend = {
      ...filters,
      dateStart,
      dateEnd,
      status,
      todayDateValue: moment().startOf("day").valueOf(),
    };
    delete filtersToSend.dateRange;
    try {
      const functions = getFunctions();
      const weeklyview = httpsCallableFromURL(
        functions,
        getFunctionByName({
          name: "listview2",
          env: globalEnvironment,
          params: "/weeklytasks",
        })
      );
      weeklyview({
        companyId: companyId,
        userId: user.id,
        filters: filtersToSend,
      }).then((result) => {
        const { tasksByDay } = result.data;
        const filteredTasks = filterTasks({ ...tasksByDay });
        setWeeksTask(tasksByDay);
        setWeeksTaskFiltered({ ...filteredTasks });
        if (Object.keys(dayExpandedProps).length > 0) {
          let dayExpandedPropsCpy = { ...dayExpandedProps };
          dayExpandedPropsCpy["tasks"] = filterTasksByDay(
            tasksByDay[dayExpandedProps.day.label]
          );
          setDayExpandedProps({ ...dayExpandedPropsCpy });
        }
        traceInstance.stop();
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      traceInstance.stop();
      setLoading(false);
    }
  }

  function filterTasks(weeksTask) {
    let newWeeksTask = {
      Monday: {
        late: [],
        dueDate: [],
        startDate: [],
      },
      Tuesday: {
        late: [],
        dueDate: [],
        startDate: [],
      },
      Wednesday: {
        late: [],
        dueDate: [],
        startDate: [],
      },
      Thursday: {
        late: [],
        dueDate: [],
        startDate: [],
      },
      Friday: {
        late: [],
        dueDate: [],
        startDate: [],
      },
      Saturday: {
        late: [],
        dueDate: [],
        startDate: [],
      },
      Sunday: {
        late: [],
        dueDate: [],
        startDate: [],
      },
    };
    Object.keys(weeksTask).forEach((day) => {
      newWeeksTask[day]["late"] =
        weeksTask[day]["late"] &&
        [
          ...(nonLookUpfilters.late
            ? weeksTask[day]["late"].filter((task) => task.status === "late")
            : []),
          ...(nonLookUpfilters.completed
            ? weeksTask[day]["late"].filter(
                (task) => task.status === "complete"
              )
            : []),
        ]
          .map((task) => ({
            ...task,
            SONumberPONumber:
              task.SONumber.toLowerCase() + " " + task.PONumber.toLowerCase(),
          }))
          .sort(sortObjectsBy("SONumberPONumber", false));

      if (nonLookUpfilters.query) {
        newWeeksTask[day]["late"] =
          newWeeksTask[day]["late"] &&
          newWeeksTask[day]["late"].filter(
            (task) =>
              task.description
                .toLowerCase()
                .includes(nonLookUpfilters.query.toLowerCase()) ||
              task.SONumber.toLowerCase().includes(
                nonLookUpfilters.query.toLowerCase()
              ) ||
              task.PONumber.toLowerCase().includes(
                nonLookUpfilters.query.toLowerCase()
              ) ||
              task.customerName
                .toLowerCase()
                .includes(nonLookUpfilters.query.toLowerCase()) ||
              task.factoryName
                .toLowerCase()
                .includes(nonLookUpfilters.query.toLowerCase()) ||
              task.assignedToName
                .toLowerCase()
                .includes(nonLookUpfilters.query.toLowerCase())
          );
      }
      newWeeksTask[day]["dueDate"] = [
        ...(nonLookUpfilters.late
          ? weeksTask[day]["dueDate"].filter((task) => task.status === "late")
          : []),
        ...(nonLookUpfilters.nearDue
          ? weeksTask[day]["dueDate"].filter(
              (task) => task.status === "nearDue"
            )
          : []),
        ...(nonLookUpfilters.inProgress
          ? weeksTask[day]["dueDate"].filter(
              (task) => task.status === "inProgress"
            )
          : []),
        ...(nonLookUpfilters.completed
          ? weeksTask[day]["dueDate"].filter(
              (task) => task.status === "complete"
            )
          : []),
        ...(nonLookUpfilters.notStarted
          ? weeksTask[day]["dueDate"].filter(
              (task) => task.status === "notStarted"
            )
          : []),
      ]
        .map((task) => ({
          ...task,
          SONumberPONumber:
            task.SONumber.toLowerCase() + " " + task.PONumber.toLowerCase(),
        }))
        .sort(sortObjectsBy("SONumberPONumber", false));
      if (nonLookUpfilters.query) {
        newWeeksTask[day]["dueDate"] = newWeeksTask[day]["dueDate"].filter(
          (task) =>
            task.description
              .toLowerCase()
              .includes(nonLookUpfilters.query.toLowerCase()) ||
            task.SONumber.toLowerCase().includes(
              nonLookUpfilters.query.toLowerCase()
            ) ||
            task.PONumber.toLowerCase().includes(
              nonLookUpfilters.query.toLowerCase()
            ) ||
            task.customerName
              .toLowerCase()
              .includes(nonLookUpfilters.query.toLowerCase()) ||
            task.factoryName
              .toLowerCase()
              .includes(nonLookUpfilters.query.toLowerCase()) ||
            task.assignedToName
              .toLowerCase()
              .includes(nonLookUpfilters.query.toLowerCase())
        );
      }

      newWeeksTask[day]["startDate"] = [
        ...(nonLookUpfilters.late
          ? weeksTask[day]["startDate"].filter((task) => task.status === "late")
          : []),
        ...(nonLookUpfilters.nearDue
          ? weeksTask[day]["startDate"].filter(
              (task) => task.status === "nearDue"
            )
          : []),
        ...(nonLookUpfilters.inProgress
          ? weeksTask[day]["startDate"].filter(
              (task) => task.status === "inProgress"
            )
          : []),
        ...(nonLookUpfilters.completed
          ? weeksTask[day]["startDate"].filter(
              (task) => task.status === "complete"
            )
          : []),
        ...(nonLookUpfilters.notStarted
          ? weeksTask[day]["startDate"].filter(
              (task) => task.status === "notStarted"
            )
          : []),
      ]
        .map((task) => ({
          ...task,
          SONumberPONumber:
            task.SONumber.toLowerCase() + " " + task.PONumber.toLowerCase(),
        }))
        .sort(sortObjectsBy("SONumberPONumber", false));
      if (nonLookUpfilters.query) {
        newWeeksTask[day]["startDate"] = newWeeksTask[day]["startDate"].filter(
          (task) =>
            task.description
              .toLowerCase()
              .includes(nonLookUpfilters.query.toLowerCase()) ||
            task.SONumber.toLowerCase().includes(
              nonLookUpfilters.query.toLowerCase()
            ) ||
            task.PONumber.toLowerCase().includes(
              nonLookUpfilters.query.toLowerCase()
            ) ||
            task.factoryName
              .toLowerCase()
              .includes(nonLookUpfilters.query.toLowerCase()) ||
            task.assignedToName
              .toLowerCase()
              .includes(nonLookUpfilters.query.toLowerCase())
        );
      }
    });
    return { ...newWeeksTask };
  }

  const markInstance = new Mark(document.getElementById("weeklyContentId"));

  function performMark(keyword = "") {
    var options = {
      separateWordSearch: false,
      diacritics: false,
      debug: false,
      acrossElements: true,
      accuracy: "partially",
      exclude: excludeElementsScreen,
    };
    markInstance.unmark({
      done: () => {
        markInstance.mark(keyword, options);
      },
    });
  }

  useEffect(() => {
    if (!loading) {
      setTimeout(() => performMark(nonLookUpfilters.query), 150);
    }
  }, [nonLookUpfilters, loading]);

  function filterTasksByDay(tasks, day) {
    let newWeeksTask = {
      day: {
        late: [],
        dueDate: [],
        startDate: [],
      },
    };
    Object.keys(tasks).forEach(() => {
      newWeeksTask["late"] =
        tasks["late"] &&
        [
          ...(nonLookUpfilters.late
            ? tasks["late"].filter((task) => task.status === "late")
            : []),
          ...(nonLookUpfilters.completed
            ? tasks["late"].filter((task) => task.status === "complete")
            : []),
        ]
          .map((task) => ({
            ...task,
            SONumberPONumber:
              task.SONumber.toLowerCase() + " " + task.PONumber.toLowerCase(),
          }))
          .sort(sortObjectsBy("SONumberPONumber", false));

      if (nonLookUpfilters.query) {
        newWeeksTask["late"] =
          newWeeksTask["late"] &&
          newWeeksTask["late"].filter(
            (task) =>
              task.description
                .toLowerCase()
                .includes(nonLookUpfilters.query.toLowerCase()) ||
              task.SONumber.toLowerCase().includes(
                nonLookUpfilters.query.toLowerCase()
              ) ||
              task.PONumber.toLowerCase().includes(
                nonLookUpfilters.query.toLowerCase()
              ) ||
              task.customerName
                .toLowerCase()
                .includes(nonLookUpfilters.query.toLowerCase()) ||
              task.factoryName
                .toLowerCase()
                .includes(nonLookUpfilters.query.toLowerCase()) ||
              task.assignedToName
                .toLowerCase()
                .includes(nonLookUpfilters.query.toLowerCase())
          );
      }
      newWeeksTask["dueDate"] = [
        ...(nonLookUpfilters.late
          ? tasks["dueDate"].filter((task) => task.status === "late")
          : []),
        ...(nonLookUpfilters.nearDue
          ? tasks["dueDate"].filter((task) => task.status === "nearDue")
          : []),
        ...(nonLookUpfilters.inProgress
          ? tasks["dueDate"].filter((task) => task.status === "inProgress")
          : []),
        ...(nonLookUpfilters.completed
          ? tasks["dueDate"].filter((task) => task.status === "complete")
          : []),
        ...(nonLookUpfilters.notStarted
          ? tasks["dueDate"].filter((task) => task.status === "notStarted")
          : []),
      ]
        .map((task) => ({
          ...task,
          SONumberPONumber:
            task.SONumber.toLowerCase() + " " + task.PONumber.toLowerCase(),
        }))
        .sort(sortObjectsBy("SONumberPONumber", false));
      if (nonLookUpfilters.query) {
        newWeeksTask["dueDate"] = newWeeksTask["dueDate"].filter(
          (task) =>
            task.description
              .toLowerCase()
              .includes(nonLookUpfilters.query.toLowerCase()) ||
            task.SONumber.toLowerCase().includes(
              nonLookUpfilters.query.toLowerCase()
            ) ||
            task.PONumber.toLowerCase().includes(
              nonLookUpfilters.query.toLowerCase()
            ) ||
            task.customerName
              .toLowerCase()
              .includes(nonLookUpfilters.query.toLowerCase()) ||
            task.factoryName
              .toLowerCase()
              .includes(nonLookUpfilters.query.toLowerCase()) ||
            task.assignedToName
              .toLowerCase()
              .includes(nonLookUpfilters.query.toLowerCase())
        );
      }

      newWeeksTask["startDate"] = [
        ...(nonLookUpfilters.late
          ? tasks["startDate"].filter((task) => task.status === "late")
          : []),
        ...(nonLookUpfilters.nearDue
          ? tasks["startDate"].filter((task) => task.status === "nearDue")
          : []),
        ...(nonLookUpfilters.inProgress
          ? tasks["startDate"].filter((task) => task.status === "inProgress")
          : []),
        ...(nonLookUpfilters.completed
          ? tasks["startDate"].filter((task) => task.status === "complete")
          : []),
        ...(nonLookUpfilters.notStarted
          ? tasks["startDate"].filter((task) => task.status === "notStarted")
          : []),
      ]
        .map((task) => ({
          ...task,
          SONumberPONumber:
            task.SONumber.toLowerCase() + " " + task.PONumber.toLowerCase(),
        }))
        .sort(sortObjectsBy("SONumberPONumber", false));
      if (nonLookUpfilters.query) {
        newWeeksTask["startDate"] = newWeeksTask[day]["startDate"].filter(
          (task) =>
            task.description
              .toLowerCase()
              .includes(nonLookUpfilters.query.toLowerCase()) ||
            task.SONumber.toLowerCase().includes(
              nonLookUpfilters.query.toLowerCase()
            ) ||
            task.PONumber.toLowerCase().includes(
              nonLookUpfilters.query.toLowerCase()
            ) ||
            task.customerName
              .toLowerCase()
              .includes(nonLookUpfilters.query.toLowerCase()) ||
            task.factoryName
              .toLowerCase()
              .includes(nonLookUpfilters.query.toLowerCase()) ||
            task.assignedToName
              .toLowerCase()
              .includes(nonLookUpfilters.query.toLowerCase())
        );
      }
    });
    return { ...newWeeksTask };
  }

  function getExpandedDay(day, isBack) {
    if (isBack) {
      if (day.label === "Monday") {
        return {
          label: "Sunday",
          date: getMoment(0, filters.weekPage),
        };
      } else if (day.label === "Tuesday") {
        return {
          label: "Monday",
          date: getMoment(1, filters.weekPage),
        };
      } else if (day.label === "Wednesday") {
        return {
          label: "Tuesday",
          date: getMoment(2, filters.weekPage),
        };
      } else if (day.label === "Thursday") {
        return {
          label: "Wednesday",
          date: getMoment(3, filters.weekPage),
        };
      } else if (day.label === "Friday") {
        return {
          label: "Thursday",
          date: getMoment(4, filters.weekPage),
        };
      } else if (day.label === "Saturday") {
        return {
          label: "Friday",
          date: getMoment(5, filters.weekPage),
        };
      } else if (day.label === "Sunday") {
        return {
          label: "Saturday",
          date: getMoment(6, filters.weekPage),
        };
      }
    } else {
      if (day.label === "Monday") {
        return {
          label: "Tuesday",
          date: getMoment(1, filters.weekPage),
        };
      } else if (day.label === "Tuesday") {
        return {
          label: "Wednesday",
          date: getMoment(2, filters.weekPage),
        };
      } else if (day.label === "Wednesday") {
        return {
          label: "Thursday",
          date: getMoment(3, filters.weekPage),
        };
      } else if (day.label === "Thursday") {
        return {
          label: "Friday",
          date: getMoment(4, filters.weekPage),
        };
      } else if (day.label === "Friday") {
        return {
          label: "Saturday",
          date: getMoment(5, filters.weekPage),
        };
      } else if (day.label === "Saturday") {
        return {
          label: "Sunday",
          date: getMoment(6, filters.weekPage),
        };
      } else if (day.label === "Sunday") {
        return {
          label: "Monday",
          date: getMoment(7, filters.weekPage),
        };
      }
    }
  }

  function getMoment(index) {
    let result1;
    result1 =
      filters.weekPage < 0
        ? moment()
            .startOf("isoWeek")
            .subtract(Math.abs(filters.weekPage + 1) * 7, "days")
            .subtract(7 - index, index > 1 ? "days" : "day")
        : moment()
            .startOf("isoWeek")
            .add(Math.abs(filters.weekPage * 7), "days")
            .add(index, "days");

    return result1;
  }

  function onComplete(task) {
    const dayOffset = moment()
      .startOf("day")
      .diff(moment(task.finishDate).startOf("day"), "days");
    let weeksTaskCpy = { ...weeksTask };
    Object.keys(weeksTaskCpy).forEach((day) => {
      Object.keys(weeksTaskCpy[day]).forEach((status) => {
        weeksTaskCpy[day][status] = weeksTaskCpy[day][status].map((el) => {
          if (task.type === typeOfTask.SALES_ORDER) {
            if (el.id === task.id && el.salesOrderId === task.salesOrderId) {
              return {
                ...el,
                complete: !task.complete,
                completedBy: user.id,
                status: taskStatus.COMPLETE,
                dayOffset,
                moved: dayOffset === 0 ? true : false,
              };
            } else return el;
          } else {
            if (
              el.id === task.id &&
              el.salesOrderId === task.salesOrderId &&
              el.purchaseOrderId === task.purchaseOrderId
            ) {
              return {
                ...el,
                complete: !task.complete,
                completedBy: user.id,
                status: taskStatus.COMPLETE,
                dayOffset,
                moved: dayOffset === 0 ? true : false,
              };
            } else return el;
          }
        });
      });
    });
    const filteredTasks = filterTasks({ ...weeksTaskCpy });
    setWeeksTask(weeksTaskCpy);
    setWeeksTaskFiltered({ ...filteredTasks });
    if (Object.keys(dayExpandedProps).length > 0) {
      let dayExpandedPropsCpy = { ...dayExpandedProps };
      dayExpandedPropsCpy["tasks"] = filterTasksByDay(
        weeksTaskCpy[dayExpandedProps.day.label],
        dayExpandedProps.day.label
      );
      setDayExpandedProps({ ...dayExpandedPropsCpy });
    }
    if (!task.complete) {
      const notificationId = getRandomId();
      if (dayOffset !== 0) {
        const currentShipment = task.shipment || {};
        createTaskNotification({
          task: {
            ...task,
            completedBy: user.id,
            PONumber: task.PONumber,
            SONumber: task.SONumber,
            shipmentNumber: currentShipment.number,
            finalDestination :currentShipment.finalDestination||'',
            SOT: !task.factoryId ? true : false,
          },
          randomId: notificationId,
          type: taskNotificationType.COMPLETED,
        });
      }
      const { duration, startDate, finishDate } = taskCompletionVerifier({
        task: {
          ...task,
          startDate: getCorrectTimezone({
            date: task.startDate,
            isServerTime: true,
          }).valueOf(),
          finishDate: getCorrectTimezone({
            date: task.finishDate,
            isServerTime: true,
          }).valueOf(),
        },
      });

      const fieldsToUpdate = {
        complete: !task.complete,
        completedBy: user.id,
        status: taskStatus.COMPLETE,
        dayOffset,
        moved: true,
        triggerType: triggerTaskTypes.COMPLETE,
        duration,
        startDate,
        finishDate,
      };

      if (task.type === typeOfTask.SHIPMENT) {
        updateDoc(
          doc(
            firestore,
            `${dbTables.COMPANIES}/${companyId}/${dbTables.SHIPMENTS}/${task.shipmentId}/${dbTables.SHIPMENT_TASKS}/${task.id}`
          ),
          {
            ...fieldsToUpdate,
          }
        );
      } else if (task.type === typeOfTask.SALES_ORDER) {
        updateDoc(
          doc(
            firestore,
            `${dbTables.COMPANIES}/${companyId}/${dbTables.SALES_ORDERS}/${task.salesOrderId}/${dbTables.SALES_ORDER_TASKS}/${task.id}`
          ),
          {
            ...fieldsToUpdate,
          }
        );
      } else {
        updateDoc(
          doc(
            firestore,
            `${dbTables.COMPANIES}/${companyId}/${dbTables.PURCHASE_ORDERS}/${task.purchaseOrderId}/${dbTables.PURCHASE_ORDER_TASKS}/${task.id}`
          ),
          {
            ...fieldsToUpdate,
          }
        );
      }
    }
  }

  function handleReadOnlyModal() {
    setReadOnlyModal(true);
  }

  function getClassName(day) {
    if (!Object.keys(dayExpandedProps).length) {
      return "expanded";
    } else if (dayExpandedProps.day.label === day) {
      return "expanded";
    } else {
      return "collapse";
    }
  }

  function getTasksUpdated(tasks, item) {
    let tasksCpy = [...tasks];
    tasksCpy = tasksCpy.map((task) => {
      if (task.salesOrderId === item.salesOrderId) {
        return {
          ...task,
          salesOrder: {
            id: item.salesOrderId,
            tags: item.tags,
            updated_by: item.updated_by,
          },
        };
      }
      return task;
    });
    return tasksCpy;
  }

  function updatePOs(purchaseOrders = [], item) {
    let purchaseOrdersCpy = [...purchaseOrders];
    purchaseOrdersCpy = purchaseOrdersCpy.map((po) => {
      if (po.id === item.purchaseOrderId) {
        return {
          ...po,
          tags: item.tags,
          updated_by: item.updated_by,
        };
      }
      return po;
    });
    return purchaseOrdersCpy;
  }

  function getPOTasksUpdated(tasks, item) {
    let tasksCpy = [...tasks];
    tasksCpy = tasksCpy.map((task) => {
      if (!task.purchaseOrder) {
        return {
          ...task,
          purchaseOrders: updatePOs(task.purchaseOrders, item),
        };
      } else {
        return {
          ...task,
          purchaseOrder: item,
        };
      }
    });
    return tasksCpy;
  }

  function handleUpdateItem(item, tagReference) {
    let weeksTaskCpy = {};
    if (tagReference === dbTables.SALES_ORDERS) {
      Object.keys(weeksTask).forEach((day) => {
        if (!weeksTask[day]["late"]) {
          return (weeksTaskCpy[day] = {
            startDate: getTasksUpdated(weeksTask[day]["startDate"], item),
            dueDate: getTasksUpdated(weeksTask[day]["dueDate"], item),
          });
        } else {
          weeksTaskCpy[day] = {
            late: getTasksUpdated(weeksTask[day]["late"], item),
            startDate: getTasksUpdated(weeksTask[day]["startDate"], item),
            dueDate: getTasksUpdated(weeksTask[day]["dueDate"], item),
          };
        }
      });
    } else {
      Object.keys(weeksTask).forEach((day) => {
        if (!weeksTask[day]["late"]) {
          return (weeksTaskCpy[day] = {
            startDate: getPOTasksUpdated(weeksTask[day]["startDate"], item),
            dueDate: getPOTasksUpdated(weeksTask[day]["dueDate"], item),
          });
        } else {
          weeksTaskCpy[day] = {
            late: getPOTasksUpdated(weeksTask[day]["late"], item),
            startDate: getPOTasksUpdated(weeksTask[day]["startDate"], item),
            dueDate: getPOTasksUpdated(weeksTask[day]["dueDate"], item),
          };
        }
      });
    }
    setWeeksTask(weeksTaskCpy);
    const filteredTasks = filterTasks({ ...weeksTaskCpy });
    setWeeksTaskFiltered({ ...filteredTasks });
    if (Object.keys(dayExpandedProps).length > 0) {
      let dayExpandedPropsCpy = { ...dayExpandedProps };
      dayExpandedPropsCpy["tasks"] = filterTasksByDay(
        weeksTaskCpy[dayExpandedProps.day.label]
      );
      setDayExpandedProps({ ...dayExpandedPropsCpy });
    }
  }

  function getWeekDays() {
    if (!weeksTaskFiltered || Object.keys(weeksTaskFiltered).length === 0) {
      return;
    }
    const renderWeekDays = [];
    const dayCard = (daysTask, day, i) => {
      return (
        <DayCard
          {...dayExpandedProps}
          key={day}
          onComplete={onComplete}
          onExpand={onExpand}
          day={{
            label: day,
            date: getMoment(i, filters.weekPage),
            dateFormated: getWeekTabScreenFormatted({
              date: getMoment(i, filters.weekPage),
            }),
          }}
          tasks={daysTask}
          nonLookUpfilters={nonLookUpfilters}
          className={getClassName(day)}
          expanded={Object.keys(dayExpandedProps).length > 0 ? true : false}
          showTags={filters.tag && filters.tag.length > 0 ? true : false}
          tags={tags}
          onUpdateItem={handleUpdateItem}
          user={user}
          isAllowed={isAllowed}
          filters={filters}
          isReadOnly={isTradeDashEmployee(user)}
          handleReadOnlyModal={handleReadOnlyModal}
          factories={factories}
        />
      );
    };
    for (let i = 0; i <= 6; i++) {
      if (i === 0) {
        renderWeekDays.push(dayCard(weeksTaskFiltered["Monday"], "Monday", i));
      } else if (i === 1) {
        renderWeekDays.push(
          dayCard(weeksTaskFiltered["Tuesday"], "Tuesday", i)
        );
      } else if (i === 2) {
        renderWeekDays.push(
          dayCard(weeksTaskFiltered["Wednesday"], "Wednesday", i)
        );
      } else if (i === 3) {
        renderWeekDays.push(
          dayCard(weeksTaskFiltered["Thursday"], "Thursday", i)
        );
      } else if (i === 4) {
        renderWeekDays.push(dayCard(weeksTaskFiltered["Friday"], "Friday", i));
      } else if (i === 5) {
        renderWeekDays.push(
          dayCard(weeksTaskFiltered["Saturday"], "Saturday", i)
        );
      } else {
        renderWeekDays.push(dayCard(weeksTaskFiltered["Sunday"], "Sunday", i));
      }
    }
    return renderWeekDays;
  }

  function updateExpandDayLabel(dayName) {
    set({
      ...filtersLocalStorage,
      dayExpandedName: dayName,
    });
  }

  function onExpand(props, day, isLocalStorageData = false) {
    if (!isLocalStorageData) {
      updateExpandDayLabel(!props.day ? false : day);
    }
    setDayExpandedProps(props);
    const element = document.getElementById(day);
    if (day && element) {
      setTimeout(() => {
        element.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }, 600);
    }
  }

  function resetFilters() {
    weekTabsLocalStorage.remove();
    setDayExpandedProps(false);
    updateExpandDayLabel(false);
    setFilters({ assignedTo: [user.id], weekPage: 0 });
    onDebounce();
    setNonLookUpFilters({
      late: true,
      nearDue: true,
      inProgress: true,
      notStarted: true,
      completed: false,
    });
  }

  function changeNonLookUpButtonFilter(filter) {
    setNonLookUpFilters({
      ...nonLookUpfilters,
      [filter]: !nonLookUpfilters[filter],
    });
  }

  const clearFilterData = ({ filterKey }) => {
    setFilters({ ...filters, [filterKey]: [] });
    onDebounce();
  };

  return (
    <React.Fragment>
      {openReadOnlyModal && (
        <ReadOnlyModal
          isOpen={openReadOnlyModal}
          onClick={() => setReadOnlyModal(false)}
          onClose={() => setReadOnlyModal(false)}
        />
      )}
      {loading && (
        <Loader
          style={{
            justifyContent: "center",
            display: "flex",
            zIndex: 1000,
            alignItems: "center",
            top: 0,
            left: -86,
            width: "100vw",
          }}
        />
      )}
      <WeeklyViewStyled className="weeklyViewContainer">
        <div className={"filtersContent"}>
          <GeneralFilters
            enableExport={false}
            currentFilters={filters}
            handleListOpen={(value) => {
              setTimeout(() => setOpenList(value), value ? 200 : 50);
            }}
            onChange={changeFilters}
            enabledFields={{
              customer: true,
              factory: true,
              assignedTo: true,
              tag: true,
            }}
            tagFilters={[TYPE_OF_TAGS.MISMATCH_TAG, TYPE_OF_TAGS.FILE_TAG]}
          />
          <div className={"searchboxContainer"}>
            <SearchBox
              placeholder="Search PO or Sales Order #, Task, Customer Etc."
              onDebounceValue={(nonLookUpfilters, value) => {
                setNonLookUpFilters({
                  ...nonLookUpfilters,
                  query: value,
                });
              }}
              value={nonLookUpfilters.query}
              filters={nonLookUpfilters}
              hasBeenReseted={(nonLookUpfilters, value) => {
                setNonLookUpFilters({
                  ...nonLookUpfilters,
                  query: value,
                });
              }}
              style={{ width: 425, right: 0, position: "relative" }}
            />
          </div>
        </div>
        <div
          className={
            Object.keys(dayExpandedProps).length > 0
              ? "contentContainerExpanded"
              : "contentContainer"
          }
          style={{ width: "calc( 100vw - 118px)", overflow: "hidden" }}
        >
          <div
            style={{
              height: 135,
            }}
          >
            <div className={"filtersContainer"}>
              <div className="buttons" style={{ marginTop: 34 }}>
                <TaskStatusFilters
                  filters={nonLookUpfilters}
                  changeButtonFilter={changeNonLookUpButtonFilter}
                  existsDate={true}
                  changeFilters={() => changeNonLookUpButtonFilter("completed")}
                />

                {filters.assignedTo && filters.assignedTo.length > 0 && (
                  <FilterDisplay
                    clearIconId="list-view-filter-badge-clear-assignedto"
                    onClear={() => clearFilterData({ filterKey: "assignedTo" })}
                    label="Assigned To"
                    tooltip={
                      getToolTipArrayValue(
                        filters.assignedTo,
                        companyUsers,
                        "displayName"
                      ).tooltip
                    }
                    value={
                      getToolTipArrayValue(
                        filters.assignedTo,
                        companyUsers,
                        "displayName"
                      ).value
                    }
                  />
                )}
                {filters.customer && filters.customer.length > 0 && (
                  <FilterDisplay
                    clearIconId="list-view-filter-badge-clear-customer"
                    onClear={() => clearFilterData({ filterKey: "customer" })}
                    label={
                      filters.customer.length === 1 ? "Customer" : "Customers"
                    }
                    tooltip={
                      getToolTipArrayValue(filters.customer, customers).tooltip
                    }
                    value={
                      getToolTipArrayValue(filters.customer, customers).value
                    }
                  />
                )}
                {filters.factory && filters.factory.length > 0 && (
                  <FilterDisplay
                    clearIconId="list-view-filter-badge-clear-factory"
                    onClear={() => clearFilterData({ filterKey: "factory" })}
                    label={filters.factory.length === 1 ? "Vendor" : "Vendors"}
                    tooltip={
                      getToolTipArrayValue(filters.factory, factories).tooltip
                    }
                    value={
                      getToolTipArrayValue(filters.factory, factories).value
                    }
                  />
                )}
                {isAllowed("see_tags") &&
                  filters.tag &&
                  filters.tag.length > 0 && (
                    <FilterDisplay
                      clearIconId="list-view-filter-badge-clear-label"
                      onClear={() => clearFilterData({ filterKey: "tag" })}
                      label={filters.tag.length === 1 ? "Label" : "Labels"}
                      tooltip={getToolTipArrayValue(filters.tag, tags).tooltip}
                      value={
                        tags && getToolTipArrayValue(filters.tag, tags).value
                      }
                    />
                  )}
                <Button
                  id="list-view-button-filter-reset"
                  className="resetButton"
                  onClick={resetFilters}
                >
                  {matches ? "RESET" : "RESET FILTERS"}
                </Button>
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                right: 60,
                top: 95,
                zIndex: 101,
              }}
            >
              <DiamondLegend />
              <div className={"actionContainer"}>
                <div className={matches ? "weekPageResized" : "weekPage"}>
                  <ButtonFilter
                    buttonId="list-view-button-filter-previous"
                    onClick={() => {
                      setCallFunction(true);
                      if (Object.keys(dayExpandedProps).length === 0) {
                        setFilters({
                          ...filters,
                          weekPage: filters.weekPage - 1,
                        });
                      } else {
                        if (dayExpandedProps.day.label !== "Monday") {
                          let dayExpandedPropsCpy = { ...dayExpandedProps };
                          const newDay = getExpandedDay(
                            dayExpandedProps.day,
                            true
                          );
                          updateExpandDayLabel(newDay.label);
                          dayExpandedPropsCpy["tasks"] = filterTasksByDay(
                            weeksTask[newDay.label]
                          );
                          dayExpandedPropsCpy["day"] = newDay;
                          setDayExpandedProps({ ...dayExpandedPropsCpy });
                        } else {
                          let dayExpandedPropsCpy = { ...dayExpandedProps };
                          const newDay = getExpandedDay(
                            dayExpandedProps.day,
                            true
                          );
                          updateExpandDayLabel(newDay.label);
                          dayExpandedPropsCpy["tasks"] = filterTasksByDay(
                            weeksTask[newDay.label]
                          );
                          dayExpandedPropsCpy["day"] = newDay;
                          setDayExpandedProps({ ...dayExpandedPropsCpy });
                          setFilters({
                            ...filters,
                            weekPage: filters.weekPage - 1,
                          });
                        }
                      }
                    }}
                    mainClass={
                      matches ? "boxed-filter-resized" : "boxed-filter"
                    }
                  >
                    <div className={"buttonNavigator"}>
                      <img
                        // className={"arrowIconPrev"}
                        src={arrowIcon}
                        style={{
                          width: 14,
                          height: 12,
                          transform: "rotateY(180deg)",
                        }}
                        alt="arrow"
                      />
                      Prev.
                    </div>
                  </ButtonFilter>
                  <ButtonFilter
                    buttonId="list-view-button-filter-today"
                    onClick={() => {
                      if (Object.keys(dayExpandedProps).length === 0) {
                        if (filters.weekPage === 0) {
                          return;
                        }
                        setCallFunction(true);
                        setFilters({
                          ...filters,
                          weekPage: 0,
                        });
                      } else if (filters.weekPage === 0) {
                        let dayExpandedPropsCpy = { ...dayExpandedProps };
                        const newDay = {
                          label: moment().format("dddd"),
                          date: moment(),
                        };
                        updateExpandDayLabel(newDay.label);
                        dayExpandedPropsCpy["tasks"] = filterTasksByDay(
                          weeksTask[newDay.label]
                        );
                        dayExpandedPropsCpy["day"] = newDay;
                        setDayExpandedProps({ ...dayExpandedPropsCpy });
                      } else {
                        let dayExpandedPropsCpy = { ...dayExpandedProps };
                        dayExpandedPropsCpy["day"] = {
                          label: moment().format("dddd"),
                          date: moment(),
                        };
                        updateExpandDayLabel(dayExpandedPropsCpy.day.label);
                        setDayExpandedProps({
                          ...dayExpandedPropsCpy,
                          tasks: { late: [], dueDate: [], startDate: [] },
                        });
                        setFilters({ ...filters, weekPage: 0 });
                      }
                    }}
                    mainClass={
                      matches ? "boxed-filter-resized" : "boxed-filter"
                    }
                  >
                    <div className={"buttonNavigatorToday"}>Today</div>
                  </ButtonFilter>
                  <ButtonFilter
                    buttonId="list-view-button-filter-next"
                    onClick={() => {
                      setCallFunction(true);
                      if (Object.keys(dayExpandedProps).length === 0) {
                        setFilters({
                          ...filters,
                          weekPage: filters.weekPage + 1,
                        });
                      } else {
                        if (dayExpandedProps.day.label !== "Sunday") {
                          let dayExpandedPropsCpy = { ...dayExpandedProps };
                          const newDay = getExpandedDay(
                            dayExpandedProps.day,
                            false
                          );
                          updateExpandDayLabel(newDay.label);

                          dayExpandedPropsCpy["tasks"] = filterTasksByDay(
                            weeksTask[newDay.label]
                          );
                          dayExpandedPropsCpy["day"] = newDay;
                          setDayExpandedProps({ ...dayExpandedPropsCpy });
                        } else {
                          let dayExpandedPropsCpy = { ...dayExpandedProps };
                          const newDay = getExpandedDay(
                            dayExpandedProps.day,
                            false
                          );
                          updateExpandDayLabel(newDay.label);

                          dayExpandedPropsCpy["tasks"] = filterTasksByDay(
                            weeksTask[newDay.label]
                          );
                          dayExpandedPropsCpy["day"] = newDay;
                          setDayExpandedProps({ ...dayExpandedPropsCpy });
                          setFilters({
                            ...filters,
                            weekPage: filters.weekPage + 1,
                          });
                        }
                      }
                    }}
                    mainClass={
                      matches ? "boxed-filter-resized" : "boxed-filter"
                    }
                  >
                    <div className={"buttonNavigator"}>
                      Next
                      <img
                        src={arrowIcon}
                        // className={"arrowIconNext"}
                        style={{ width: 14, height: 12 }}
                        alt="arrow"
                      />
                    </div>
                  </ButtonFilter>
                </div>
              </div>
            </div>
          </div>

          <WeeklyContent isExpand={Object.keys(dayExpandedProps).length > 0}>
            {getWeekDays()}
          </WeeklyContent>
        </div>
      </WeeklyViewStyled>
    </React.Fragment>
  );
}

export default WeeklyView;
