import React, { Fragment, useContext, useEffect, useState } from "react";
import Activityv2 from "./Activityv2";
import {
  ACTIVITY_STREAM_ACTION,
  ActivityStreamContext,
} from "./ActivityStreamContext";
import {
  hasFeatureFlagPermission,
  replacePathReference,
} from "../../../helpers/helpers";
import ExpandActivityModal from "../../Widgets/Activities/ExpandActivityModal";
import { useFeatureFlags } from "../../../hooks/featureFlags";
import {
  READ_FROM_TYPE,
  featureFlagNames,
  notesFilters,
} from "../../../helpers/constants";
import {
  useCompanyId,
  usePermissionGroups,
  useQueryParams,
} from "../../../hooks";
import moment from "moment";
import {
  GroupedActivitiesByDateContainerStyled,
  UnreadActivitiesContainerStyled,
} from "./styles";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../firebase";
import { useNavigate } from "react-router-dom";
import {
  useBackdropState,
  useNotifications,
  useTabId,
  useUserMentions,
} from "../../../hooks/user";
import {
  getUnreadActivity,
  usePurchaseOrders,
  useSalesOrders,
} from "../../../hooks/salesOrders";
import { useIsAllowedFunction } from "../../../hooks/permissions";
import AttentionModal from "../../Modal/AttentionModal";
import LoadingBackdrop from "../../WholeScreenFocusBackdrop/LoadingBackdrop";
import {
  getDateTitle,
  handleMarkAsRead,
  markUnreadActivities,
} from "../../../helpers/activitiesStream";
import { useDispatch } from "react-redux";
import { dbTables, reduxState } from "../../../api/types/dbTables";
import PendingDivider from "../../Dividers/PendingDivider";
import { Cancel } from "@mui/icons-material";
import { cx } from "@emotion/css";
import {
  alreadyReadNote,
  getNewestUnreadActivity,
  onClickMentions,
} from "./ActivityHelpers";
import { ACTIVITY_LOAD_FROM } from "../../../actions/types";

const ACTIVITY_SCROLLED_OPTIONS = {
  NOT_STARTED: "NOT_STARTED",
  SCROLLED: "SCROLLED",
};

function FilterActivitiesv2({
  activities = [],
  currentUser,
  salesOrder = {},
  purchaseOrder = {},
  currentShipment = {},
  performMark = () => {},
  activitiesLoading = false,
}) {
  const { activityStreamState, dispatchActivityStream } = useContext(
    ActivityStreamContext
  );
  const {
    activityModal,
    salesOrderActivitySetup,
    noteThread,
    loadedFromTheBegining,
    scrollTo,
    querySearch,
  } = activityStreamState;

  const currentDate = salesOrderActivitySetup.startDate;
  const navigate = useNavigate();
  const currentTabId = useTabId();
  const companyId = useCompanyId();
  const notifications = useNotifications();

  const featureFlags = useFeatureFlags({ companyId });
  const [attentionData, setAttentionData] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const purchaseOrders = usePurchaseOrders();
  const orderCreationDate = salesOrder.quoteCreationDate
    ? salesOrder.quoteCreationDate
    : salesOrder.creationDate;
  const userMentions = useUserMentions();
  const backdropState = useBackdropState();
  const isAllowed = useIsAllowedFunction();
  const salesOrders = useSalesOrders();
  const queryParams = useQueryParams() || {};
  const permissionGroupDB = usePermissionGroups({
    companyId,
    filteringByInactive: true,
  });

  const salesOrderUnreadActivity = getUnreadActivity({
    userId: currentUser.id,
    orderId: salesOrder.id,
    reference: reduxState.SALES_ORDER_UNREAD_ACTIVITY_USERS,
  });
  const purchaseOrderUnreadActivity = getUnreadActivity({
    userId: currentUser.id,
    orderId: purchaseOrder.id,
    reference: reduxState.PURCHASE_ORDER_UNREAD_ACTIVITY_USERS,
  });
  const shipmentUnreadActivity = getUnreadActivity({
    userId: currentUser.id,
    orderId: currentShipment.id,
    reference: reduxState.SHIPMENT_UNREAD_ACTIVITY_USERS,
  });

  const unreadActivity = getNewestUnreadActivity({
    activities: [
      salesOrderUnreadActivity,
      purchaseOrderUnreadActivity,
      shipmentUnreadActivity,
    ],
  });

  const hasThreadPermission = hasFeatureFlagPermission({
    featureFlags,
    user: currentUser,
    featureFlagName: featureFlagNames.THREAD_NOTES,
  });

  useEffect(() => {
    if (loading && !backdropState.id && !queryParams.fileId) {
      setLoading(false);
    }
  }, [backdropState.id, queryParams.fileId]);
  useEffect(() => {
    setTimeout(() => performMark(), 2500);
  }, [activities.length, salesOrderActivitySetup.startDate]);

  function groupActivitiesByDate(activities) {
    return activities.reduce((acc, activity) => {
      const date = moment(activity.creationDate).format("YYYY-MM-DD");
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(activity);
      return acc;
    }, {});
  }

  const handleShowThread = async (currentReplyActivity) => {
    const replyId = currentReplyActivity.threadId || currentReplyActivity.id;
    let currentActivity = activities.find(
      (activity) => activity.id === replyId
    );
    const { salesOrderActivitySetup } = activityStreamState;
    const currentDate = salesOrderActivitySetup.startDate;
    if (currentActivity) {
      const dispatchMode =
        currentDate > currentActivity.creationDate
          ? ACTIVITY_STREAM_ACTION.BRING_ACTIVITIES_FROM_THE_BEGINING
          : ACTIVITY_STREAM_ACTION.COMMON;

      dispatchActivityStream({
        type: dispatchMode,
        payload: {
          loadingPOActivities: true,
          noteThread: {
            ...currentActivity,
            originalId: currentReplyActivity.id,
          },
          activeTab: notesFilters.NOTES,
          cleanSearchBox: true,
        },
        startDate: currentActivity.creationDate,
      });
    } else {
      const newPath = replacePathReference({
        oldPath: currentReplyActivity.ref.path,
        newId: replyId,
      });
      const snapDoc = await getDoc(doc(firestore, `${newPath}`));
      if (snapDoc.exists()) {
        const newCreationDate = snapDoc.data().creationDate;
        const dispatchMode =
          currentDate > newCreationDate
            ? ACTIVITY_STREAM_ACTION.BRING_ACTIVITIES_FROM_THE_BEGINING
            : ACTIVITY_STREAM_ACTION.COMMON;
        dispatchActivityStream({
          type: dispatchMode,
          payload: {
            loadingPOActivities: true,
            noteThread: {
              ...snapDoc.data(),
              ref: snapDoc.ref,
              originalId: currentReplyActivity.id,
            },
            activeTab: notesFilters.NOTES,
            cleanSearchBox: true,
          },
          startDate: newCreationDate,
        });
      }
    }
  };

  const addMentionBackdrop = async ({ ev, activity, onReadActivity }) => {
    setLoading(true);
    const currentSalesOrder = salesOrders.find(
      (so) => so.id === activity.salesOrderId
    );
    const hasFlagPermission = hasFeatureFlagPermission({
      featureFlags,
      user: currentUser,
      featureFlagName: featureFlagNames.DOCUMENT_IN_NOTES,
    });
    onClickMentions({
      activity,
      companyId,
      currentUser,
      event: ev,
      hasFlagPermission,
      isAllowed,
      navigate,
      permissionGroupDB,
      purchaseOrders,
      query: queryParams,
      salesOrders,
      handleError: ({ open, text }) => {
        setLoading(false);
        if (open && text) {
          setAttentionData({ isOpen: open, text });
        }
      },
      currentSalesOrder,
      onReadActivity,
    });
  };

  const getAllActivities = (ev) => {
    const startDate = salesOrder.quoteCreationDate || salesOrder.creationDate;
    dispatchActivityStream({
      type: ACTIVITY_STREAM_ACTION.BRING_ACTIVITIES_FROM_THE_BEGINING,
      startDate,
    });
    dispatch({
      type: ACTIVITY_LOAD_FROM,
      payload: {
        creationDate: startDate,
      },
    });
    ev.stopPropagation();
  };

  function handleMarkAsReadUnreadActivities({ allActivities }) {
    const unreadActivities = allActivities.filter(
      (activity) => !activity.readBy || !activity.readBy[currentUser.id]
    );
    unreadActivities.forEach((activity) => {
      const readByCpy = { ...activity.readBy };
      readByCpy[currentUser.id] = true;
      updateDoc(activity.ref, { readBy: readByCpy });
    });
    if (salesOrderUnreadActivity.activityId) {
      markUnreadActivities({
        currentUser,
        parentCollection: dbTables.SALES_ORDERS,
        parentDocumentId: salesOrder.id,
      });
    }
    if (purchaseOrderUnreadActivity.activityId) {
      markUnreadActivities({
        currentUser,
        parentCollection: dbTables.PURCHASE_ORDERS,
        parentDocumentId: purchaseOrder.id,
      });
    }
    if (shipmentUnreadActivity.activityId) {
      markUnreadActivities({
        currentUser,
        parentCollection: dbTables.SHIPMENTS,
        parentDocumentId: currentShipment.id,
      });
    }
  }

  function handleScrollToUnreadActivity({ activityId }) {
    if (scrollTo === ACTIVITY_SCROLLED_OPTIONS.SCROLLED) {
      const element = document.getElementById("activityStreamByDateContainer");
      element.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      handleMarkAsReadUnreadActivities({ allActivities: activities });
      dispatchActivityStream({
        type: ACTIVITY_STREAM_ACTION.COMMON,
        payload: {
          scrollTo: null,
        },
      });
    } else if (!loadedFromTheBegining) {
      const startDate = salesOrder.quoteCreationDate || salesOrder.creationDate;
      dispatchActivityStream({
        type: ACTIVITY_STREAM_ACTION.BRING_ACTIVITIES_FROM_THE_BEGINING,
        startDate,
        payload: {
          activeTab: notesFilters.ALL,
          scrollTo: "activity-backdrop-" + activityId,
        },
      });
      dispatch({
        type: ACTIVITY_LOAD_FROM,
        payload: {
          creationDate: startDate,
        },
      });
    } else {
      dispatchActivityStream({
        type: ACTIVITY_STREAM_ACTION.COMMON,
        payload: {
          scrollTo: "activity-backdrop-" + activityId,
          activeTab: notesFilters.ALL,
        },
      });
    }
  }

  const activityCanEdit = ({ activity = {} }) =>
    activity.tabId === currentTabId ? activity.enableToEdit : false;

  useEffect(() => {
    if (!scrollTo || scrollTo === ACTIVITY_SCROLLED_OPTIONS.SCROLLED) return;
    let counter = 0;
    const timer = setInterval(() => {
      const activityToScroll = document.getElementById(scrollTo);
      if (activityToScroll) {
        clearInterval(timer);
        setTimeout(() => {
          activityToScroll.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
        }, 1000);
        dispatchActivityStream({
          type: ACTIVITY_STREAM_ACTION.COMMON,
          payload: {
            scrollTo: ACTIVITY_SCROLLED_OPTIONS.SCROLLED,
          },
        });
      }
      counter++;
      if (counter > 9) {
        clearInterval(timer);
      }
    }, 1000);
  }, [scrollTo]);

  const currentActivityModal = activityModal.id
    ? activities.find((activity) => activity.id === activityModal.id) || {}
    : {};

  return (
    <>
      {activityModal.id && (
        <ExpandActivityModal
          activity={currentActivityModal}
          openModalActivity={!!currentActivityModal.id}
          onAddMentionBackdrop={addMentionBackdrop}
          onCloseModalNotes={() => {
            dispatchActivityStream({
              type: ACTIVITY_STREAM_ACTION.COMMON,
              payload: {
                activityModal: {},
              },
            });
          }}
          noteIsReaded={alreadyReadNote({
            activity: currentActivityModal,
            user: currentUser,
          })}
          user={currentUser}
          onMarkRead={async () => {
            await handleMarkAsRead({
              activity: currentActivityModal,
              currentShipment,
              purchaseOrder,
              salesOrder,
              user: currentUser,
              userMentions,
              dispatch,
              notifications,
              type: READ_FROM_TYPE.ACTIVITY_PANEL,
            });
          }}
        />
      )}
      {attentionData && attentionData.isOpen && (
        <AttentionModal
          title="Attention"
          description={attentionData.text}
          isOpen={attentionData.isOpen}
          onClick={() => setAttentionData({})}
          confirmationText="ok"
          onClose={() => setAttentionData({})}
        />
      )}
      {unreadActivity.activityId && !noteThread && (
        <UnreadActivitiesContainerStyled
          className={"notificationUnreadContainer"}
        >
          <div
            className="notificationActivities"
            onClick={() =>
              handleScrollToUnreadActivity({
                activityId: unreadActivity.activityId,
              })
            }
          >
            <div className="textUnreadActivities">
              {scrollTo === ACTIVITY_SCROLLED_OPTIONS.SCROLLED ? (
                <React.Fragment> Jump to Now </React.Fragment>
              ) : (
                <React.Fragment>
                  Show new activity since{" "}
                  {moment(unreadActivity.creationDate).format("MMMM D h:mma")}
                </React.Fragment>
              )}
            </div>
            <Cancel
              className="cancelIcon"
              onClick={(ev) => {
                handleMarkAsReadUnreadActivities({
                  allActivities: activities,
                });
                ev.stopPropagation();
              }}
            />
          </div>
        </UnreadActivitiesContainerStyled>
      )}
      <GroupedActivitiesByDateContainerStyled
        id="activityStreamByDateContainer"
        className={cx("group-activities-by-date-container", {
          "unread-activity": !!unreadActivity.activityId && !noteThread,
        })}
      >
        {loading && <LoadingBackdrop withLogo />}

        {Object.keys(groupActivitiesByDate(activities)).map(
          (date, dateIndex) => (
            <div
              className="group-activities-content"
              id="group-activities-content"
              key={date + moment(date).format("MMM D")}
            >
              <div className="group-activity-date">
                {getDateTitle({ date })}
              </div>
              {groupActivitiesByDate(activities)[date].map(
                (activity, activityIndex, array) => {
                  const userEditId = activityCanEdit({ activity });
                  const activityFocus = backdropState.id.includes(activity.id);
                  let hasAnimationByTime = false;
                  const hasAnimation =
                    (dateIndex === 0 &&
                      array.length > 5 &&
                      activityIndex < 8) ||
                    (dateIndex <= 1 && activityIndex <= 5);
                  if (hasAnimation) {
                    hasAnimationByTime =
                      moment().diff(
                        moment(activities[0].creationDate),
                        "seconds"
                      ) < 10;
                  }

                  return (
                    <>
                      <Activityv2
                        key={activity.id}
                        activity={activity}
                        sizeThreadChilds={Math.max(
                          activity.numberOfReplies || 0,
                          activities.filter(
                            (currentActivity) =>
                              currentActivity.threadId === activity.id
                          ).length
                        )}
                        currentUser={currentUser}
                        hasThreadPermission={hasThreadPermission}
                        handleShowThread={handleShowThread}
                        addMentionBackdrop={addMentionBackdrop}
                        activityFocus={activityFocus}
                        userEditId={userEditId}
                        activityReadStatus={alreadyReadNote({
                          activity,
                          user: currentUser,
                        })}
                        onChangeReadStatus={async () => {
                          await handleMarkAsRead({
                            activity,
                            currentShipment,
                            purchaseOrder,
                            salesOrder,
                            user: currentUser,
                            userMentions,
                            dispatch,
                            type: READ_FROM_TYPE.ACTIVITY_PANEL,
                            notifications,
                          });
                        }}
                        newActivityApper={
                          (activityCanEdit({
                            activity: activities[0],
                          }) ||
                            hasAnimationByTime) &&
                          hasAnimation
                        }
                      />
                      {unreadActivity.activityId === activity.id && (
                        <div
                          id="unread-line-activity"
                          className="recent-activity-line"
                        >
                          <div className="recent-activity-content">
                            <PendingDivider text="New Activity" />
                          </div>
                        </div>
                      )}
                    </>
                  );
                }
              )}
            </div>
          )
        )}
        {!activitiesLoading && (
          <>
            {moment(currentDate).valueOf() <=
              moment(orderCreationDate).valueOf() && (
              <div className="activity-stream-search-text">
                {querySearch && activities.length === 0 ? (
                  <span>
                    The current filter selection does not have any results
                  </span>
                ) : (
                  <span>{`This is the beginning of ${
                    noteThread ? "this thread" : "the Activity Stream"
                  }`}</span>
                )}
              </div>
            )}
            {moment(currentDate).valueOf() >
              moment(orderCreationDate).valueOf() &&
              !noteThread && (
                <span
                  id="load-activities-container-id"
                  className="load-activities-container"
                  onClick={getAllActivities}
                >
                  Load Activity Stream from the beginning
                </span>
              )}
          </>
        )}
      </GroupedActivitiesByDateContainerStyled>
    </>
  );
}

export default FilterActivitiesv2;
