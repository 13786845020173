import { dbTables } from "../../api/types/dbTables";
import {
  GENERAL_PERMISSION_VALUE,
  headerColumns,
} from "../../helpers/constants";
import { POObjFields, shipmentObjFields } from "../../helpers/orderDashboard";
import { SALES_ORDER_FIELDS } from "../../helpers/salesOrder";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "../PurchaseOrderDashboard/sections/helpers";

export const searchSalesOrderHeader = [
  {
    id: 1,
    index: 1,
    label: "Quote/Sales Order",
    displayLabel: <span>Quote/ Sales Order</span>,
    canBeSort: true,
    reference: headerColumns.SALES_ORDER,
    referenceSorting: "number",
    enabled: true,
    styles: {
      textAlign: "left",
      width: 260,
      padding: "0px 8px 0px 24px",
      wordBreak: "break-all",
    },
    disabledDraggable: true,
  },

  {
    id: 2,
    label: headerColumns.CUSTOMER,
    reference: headerColumns.CUSTOMER,
    referenceSorting: "customerName",
    index: 2,
    enabled: true,
    canBeSort: true,
    disabledDraggable: true,
    styles: {
      textAlign: "left",
      padding: "0px 10px 0px 16px",
      width: "90%",
    },
  },
];

export const searchPurchaseOrderHeader = [
  {
    index: 1,
    id: 1,
    label: "PO/Purchase Quote",
    reference: headerColumns.PO,
    referenceSorting: "number",
    enabled: true,
    canBeSort: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px 0px 24px",
      width: 220,
    },
    disabledDraggable: true,
  },

  {
    index: 2,
    id: 2,
    label: headerColumns.VENDOR,
    reference: headerColumns.VENDOR,
    referenceSorting: "vendorName",
    enabled: true,
    disabledDraggable: true,
    styles: {
      textAlign: "left",
      padding: "9px 16px",
      width: "90%",
    },
  },
];

export const searchShipmentHeader = [
  {
    index: 1,
    id: 1,
    label: headerColumns.SHIPMENT,
    reference: headerColumns.SHIPMENT,
    referenceSorting: "number",
    enabled: true,
    canBeSort: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px 0px 24px",
      width: 150,
    },
    disabledDraggable: true,
  },

  {
    index: 2,
    id: 2,
    label: headerColumns.CUSTOMER,
    reference: headerColumns.CUSTOMER,
    referenceSorting: "customerName",
    enabled: true,
    disabledDraggable: true,
    styles: {
      textAlign: "left",
      padding: "9px 16px",
      width: "40%",
    },
  },
  {
    index: 3,
    id: 3,
    label: headerColumns.FINAL_DESTINATION,
    reference: headerColumns.FINAL_DESTINATION,
    referenceSorting: "finalDestination",
    enabled: true,
    disabledDraggable: true,
    styles: {
      textAlign: "left",
      padding: "9px 16px",
      width: "60%",
    },
  },
];

export const searchSOReferenceNumberHeader = [
  {
    id: 0,
    label: headerColumns.REFERENCE_NUMBER,
    reference: headerColumns.REFERENCE_NUMBER,
    index: 0,
    enabled: true,
    canBeSort: true,
    disabledDraggable: true,
    referenceSorting: "referenceNumber",
    styles: {
      textAlign: "left",
      width: "65%",
      padding: "0px 10px 0px 16px",
    },
  },
  {
    id: 1,
    index: 1,
    label: "Quote/Sales Order",

    canBeSort: true,
    reference: headerColumns.SALES_ORDER,
    referenceSorting: "number",
    enabled: true,
    styles: {
      textAlign: "left",
      width: 215,
      padding: "0px 8px 0px 24px",
      wordBreak: "break-all",
    },
    disabledDraggable: true,
  },

  {
    id: 2,
    label: headerColumns.CUSTOMER,
    reference: headerColumns.CUSTOMER,
    referenceSorting: "customerName",
    index: 2,
    enabled: true,
    canBeSort: true,
    disabledDraggable: true,
    styles: {
      textAlign: "left",
      width: "35%",
      padding: "0px 10px 0px 16px",
    },
  },
];

export const searchSOCustomerPOHeader = [
  {
    id: 0,
    label: headerColumns.CUSTOMER_PO,
    reference: headerColumns.CUSTOMER_PO,
    referenceSorting: "customerPO",
    index: 0,
    enabled: true,
    canBeSort: true,
    disabledDraggable: true,
    styles: {
      textAlign: "left",
      width: "65%",
      padding: "0px 10px 0px 16px",
    },
  },
  {
    id: 1,
    index: 1,
    label: "Quote/Sales Order",
    canBeSort: true,
    reference: headerColumns.SALES_ORDER,
    referenceSorting: "number",
    enabled: true,
    styles: {
      textAlign: "left",
      width: 215,
      padding: "0px 8px 0px 24px",
      wordBreak: "break-all",
    },
    disabledDraggable: true,
  },

  {
    id: 2,
    label: headerColumns.CUSTOMER,
    reference: headerColumns.CUSTOMER,
    referenceSorting: "customerName",
    index: 2,
    enabled: true,
    canBeSort: true,
    disabledDraggable: true,
    styles: {
      textAlign: "left",
      padding: "0px 10px 0px 16px",
      width: "35%",
    },
  },
];

export const searchShipmentContainerHeader = [
  {
    index: 0,
    id: 0,
    label: headerColumns.CONTAINER,
    reference: headerColumns.CONTAINER,
    enabled: true,
    disabledDraggable: true,
    canBeSort: true,
    referenceSorting: "container",
    styles: {
      textAlign: "left",
      padding: "9px 16px",
      width: "60%",
    },
  },
  {
    index: 1,
    id: 1,
    label: headerColumns.SHIPMENT,
    reference: headerColumns.SHIPMENT,
    referenceSorting: "number",
    enabled: true,
    canBeSort: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px 0px 24px",
      width: 150,
    },
    disabledDraggable: true,
  },

  {
    index: 2,
    id: 2,
    label: headerColumns.CUSTOMER,
    reference: headerColumns.CUSTOMER,
    referenceSorting: "customerName",
    enabled: true,
    disabledDraggable: true,
    canBeSort: true,
    styles: {
      textAlign: "left",
      padding: "9px 16px",
      width: "40%",
    },
  },
];

export const searchBillLandingHeader = [
  {
    index: 0,
    id: 0,
    label: headerColumns.BILL_OF_LADING,
    reference: headerColumns.BILL_OF_LADING,
    enabled: true,
    canBeSort: true,
    disabledDraggable: true,
    referenceSorting: "billOfLading",
    styles: {
      textAlign: "left",
      padding: "9px 16px",
      width: "60%",
    },
  },
  {
    index: 1,
    id: 1,
    label: headerColumns.SHIPMENT,
    reference: headerColumns.SHIPMENT,
    referenceSorting: "number",
    enabled: true,
    canBeSort: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px 0px 24px",
      width: 150,
    },
    disabledDraggable: true,
  },

  {
    index: 2,
    id: 2,
    label: headerColumns.CUSTOMER,
    reference: headerColumns.CUSTOMER,
    referenceSorting: "customerName",
    enabled: true,
    canBeSort: true,
    disabledDraggable: true,
    styles: {
      textAlign: "left",
      padding: "9px 16px",
      width: "40%",
    },
  },
];

export const searchPOInvoiceHeader = [
  {
    index: 0,
    id: 0,
    label: headerColumns.INVOICE,
    reference: headerColumns.INVOICE,
    enabled: true,
    disabledDraggable: true,
    canBeSort: true,
    styles: {
      textAlign: "left",
      padding: "9px 16px",
      width: "60%",
    },
    referenceSorting: "invoice",
  },
  {
    index: 1,
    id: 1,
    label: "PO/Purchase Quote",
    reference: headerColumns.PO,
    referenceSorting: "number",
    enabled: true,
    canBeSort: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px 0px 24px",
      width: 200,
    },
    disabledDraggable: true,
  },
  {
    index: 2,
    id: 2,
    label: headerColumns.VENDOR,
    reference: headerColumns.VENDOR,
    referenceSorting: "vendorName",
    enabled: true,
    disabledDraggable: true,
    canBeSort: true,
    styles: {
      textAlign: "left",
      padding: "9px 16px",
      width: "40%",
    },
  },
];

export const searchSOInvoiceHeader = [
  {
    id: 0,
    label: headerColumns.INVOICE,
    reference: headerColumns.INVOICE,
    index: 0,
    enabled: true,
    canBeSort: true,
    disabledDraggable: true,
    referenceSorting: "invoice",
    styles: {
      textAlign: "left",
      width: "60%",
      padding: "0px 10px 0px 16px",
    },
  },
  {
    id: 1,
    index: 1,
    label: "Quote/Sales Order",
    canBeSort: true,
    reference: headerColumns.SALES_ORDER,
    referenceSorting: "number",
    enabled: true,
    styles: {
      textAlign: "left",
      width: 215,
      padding: "0px 8px 0px 24px",
      wordBreak: "break-all",
    },
    disabledDraggable: true,
  },

  {
    id: 2,
    label: headerColumns.CUSTOMER,
    reference: headerColumns.CUSTOMER,
    referenceSorting: "customerName",
    index: 2,
    enabled: true,
    canBeSort: true,
    disabledDraggable: true,
    styles: {
      textAlign: "left",
      width: "40%",
      padding: "0px 10px 0px 16px",
    },
  },
];

export const searchShipmentInvoiceHeader = [
  {
    index: 0,
    id: 0,
    label: headerColumns.INVOICE,
    reference: headerColumns.INVOICE,
    enabled: true,
    referenceSorting: "invoice",
    canBeSort: true,
    disabledDraggable: true,
    styles: {
      textAlign: "left",
      padding: "9px 16px",
      width: "60%",
    },
  },
  {
    index: 1,
    id: 1,
    label: headerColumns.SHIPMENT,
    reference: headerColumns.SHIPMENT,
    referenceSorting: "number",
    enabled: true,
    canBeSort: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px 0px 24px",
      width: 150,
    },
    disabledDraggable: true,
  },

  {
    index: 2,
    id: 2,
    label: headerColumns.CUSTOMER,
    reference: headerColumns.CUSTOMER,
    referenceSorting: "customerName",
    enabled: true,
    disabledDraggable: true,
    canBeSort: true,
    styles: {
      textAlign: "left",
      padding: "9px 16px",
      width: "40%",
    },
  },
];

export const optionsSearch = [
  {
    id: 1,
    scope: dbTables.SALES_ORDERS,
    value: SALES_ORDER_FIELDS.NUMBER,
    label: "Sales Order or Quote",
    tooltipText: "Searches the Sales Order numbers",
    searchText: "Enter Sales Order number (partial is ok)",
    headers: searchSalesOrderHeader,
    permission: GENERAL_PERMISSION_VALUE.SALES_ORDERS_VIEW,
    referenceSorting: "number",
  },
  {
    id: 2,
    scope: dbTables.PURCHASE_ORDERS,
    value: SALES_ORDER_FIELDS.NUMBER,
    label: "PO",
    tooltipText: "Searches the PO numbers",
    searchText: "Enter PO number (partial is ok)",
    headers: searchPurchaseOrderHeader,
    permission: GENERAL_PERMISSION_VALUE.PURCHASE_ORDERS_VIEW,
    referenceSorting: "number",
  },
  {
    id: 3,
    scope: dbTables.SHIPMENTS,
    value: SALES_ORDER_FIELDS.NUMBER,
    label: "Shipment",
    tooltipText: "Searches the Shipment numbers",
    searchText: "Enter Shipment number (partial is ok)",
    headers: searchShipmentHeader,
    permission: GENERAL_PERMISSION_VALUE.SHIPMENTS_VIEW,
    referenceSorting: "number",
  },
  {
    id: 4,
    scope: dbTables.SALES_ORDERS,
    value: SALES_ORDER_FIELDS.REFERENCE_NUMBER,
    label: "Reference Number",
    detail: "from SO or Quote",
    tooltipText: "Searches the Reference Number field in Sales Orders/Quote",
    searchText: "Enter Sales Order reference number (partial is ok)",
    headers: searchSOReferenceNumberHeader,
    permission: GENERAL_PERMISSION_VALUE.SALES_ORDERS_VIEW,
    referenceSorting: "referenceNumber",
  },
  {
    id: 5,
    scope: dbTables.SALES_ORDERS,
    value: SALES_ORDER_FIELDS.CUSTOMER_PO,
    label: "Customer PO",
    detail: "from SO or Quote",
    tooltipText: "Searches the Customer PO field in Sales Orders/Quote",
    searchText: "Enter Customer PO number (partial is ok)",
    headers: searchSOCustomerPOHeader,
    permission: GENERAL_PERMISSION_VALUE.SALES_ORDERS_VIEW,
    referenceSorting: "customerPO",
  },
  {
    id: 6,
    scope: dbTables.SHIPMENTS,
    value: shipmentObjFields.CONTAINER,
    label: "Container",
    detail: "from Shipment",
    tooltipText: "Searches the Container field in Shipments",
    searchText: "Enter Shipment container (partial is ok)",
    headers: searchShipmentContainerHeader,
    permission: GENERAL_PERMISSION_VALUE.SHIPMENTS_VIEW,
    referenceSorting: "container",
  },
  {
    id: 7,
    scope: dbTables.SHIPMENTS,
    value: shipmentObjFields.BILL_OF_LADING,
    label: "Bill of Lading",
    detail: "from Shipment",
    tooltipText: "Searches the Bill of Lading field in Shipments",
    searchText: "Enter Shipment bill of lading (partial is ok)",
    headers: searchBillLandingHeader,
    permission: GENERAL_PERMISSION_VALUE.SHIPMENTS_VIEW,
    referenceSorting: "billOfLading",
  },
  {
    id: 8,
    scope: dbTables.PURCHASE_ORDERS,
    value: POObjFields.INVOICE,
    label: "Vendor Invoice",
    detail: "from PO",
    tooltipText: "Searches the Vendor Invoice field in POs",
    searchText: "Enter Invoice (partial is ok)",
    headers: searchPOInvoiceHeader,
    permission: GENERAL_PERMISSION_VALUE.PURCHASE_ORDERS_VIEW,
    referenceSorting: "invoice",
  },
  {
    id: 9,
    scope: dbTables.SALES_ORDERS,
    value: SALES_ORDER_FIELDS.INVOICE,
    label: "Invoice to Customer",
    detail: "from SO or Quote",
    tooltipText: "Searches the Invoice to Customer field in Sales Orders/Quote",
    searchText: "Enter Invoice (partial is ok)",
    headers: searchSOInvoiceHeader,
    permission: GENERAL_PERMISSION_VALUE.SALES_ORDERS_VIEW,
    referenceSorting: "invoice",
  },
  {
    id: 10,
    scope: dbTables.SHIPMENTS,
    value: SALES_ORDER_FIELDS.INVOICE,
    label: "Invoice to Customer",
    detail: "from Shipment",
    tooltipText: "Searches the Invoice to Customer field in Shipments",
    searchText: "Enter Invoice (partial is ok)",
    headers: searchShipmentInvoiceHeader,
    permission: GENERAL_PERMISSION_VALUE.SHIPMENTS_VIEW,
    referenceSorting: "invoice",
  },
];

export const getVerificationPath = ({ id, scope, toThreePanel }) => {
  const orderSection = {
    [dbTables.SALES_ORDERS]: ORDER_DASHBOARD_ACTUAL_VIEWS.SALES_ORDER,
    [dbTables.PURCHASE_ORDERS]: ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER,
    [dbTables.SHIPMENTS]: ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT,
  };
  return `dashboard?documentId=${id}&&section=${
    toThreePanel
      ? ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION
      : orderSection[scope]
  }&&toVerify=${scope}`;
};
