import React from "react";
import PropTypes from "prop-types";
import taskStages from "../../api/types/taskStages";
import IntlMessages from "../../util/IntlMessages";
import { Popover } from "@mui/material";

function PhasePicker({ el, open, onClose, onKeyDown, onChange, value }) {
  return (
    <Popover
      anchorEl={el}
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      transformOrigin={{ horizontal: "left", vertical: "top" }}
      open={open}
      onClose={() => onClose()}
      className="phase-picker"
      onKeyDown={onKeyDown}
    >
      <ul>
        {Object.keys(taskStages).map((stage) => (
          <li
            key={stage}
            className={stage === value ? "phase-item-selected" : "phase-item"}
            onClick={() => onChange(stage)}
          >
            <IntlMessages id={stage} />
          </li>
        ))}
      </ul>
    </Popover>
  );
}

PhasePicker.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PhasePicker;
