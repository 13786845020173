import React, { useContext, useEffect, useState } from "react";
import { getDiamondStatus } from "../../../helpers/ScreenComponetHelper/componetHelper";
import { cx } from "@emotion/css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { dbTables } from "../../../api/types/dbTables";
import { shipmentBadgeClass } from "../../StyledComponent/BadgeStyled";
import { getShipmentBadge } from "../../../helpers/shipments";
import {
  getDataFromListener,
  getFilterTasksByStatus,
} from "../TaskScreenHelper";
import TaskInfoRow from "./TaskInfoRow";
import TooltipTD from "../../../components/Tooltip/TooltipTD";
import { NavLink } from "react-router-dom";
import {
  getDashboardSearchPath,
  getProgressValue,
} from "../../../helpers/helpers";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "../../../components/PurchaseOrderDashboard/sections/helpers";
import {
  TASK_GROUPED_BY_PROJECT_ACTION,
  TaskGroupedByProjectContext,
} from "../TaskByProject/TaskByProjectContext";
import { CircularStaticProgress } from "../../../components/CircularProgress/CircularStaticProgress";
import { PO_STATUS } from "../../../helpers/constants";
import { VoidLabel } from "../../../helpers/iconSvgPath";
import { colors } from "../../../assets/jss/variables";
import POBadge from "../../../components/Badge/POBadge";

function ItemOrderInfoRow({
  itemOrder,
  isShipment,
  type = dbTables.PURCHASE_ORDERS,
  taskCollection = dbTables.PURCHASE_ORDER_TASKS,
  companyId,
  addUnsubscribeListener = () => {},
  companyUsers,
  lastItemOrder,
  filters,
  openAll,
  salesOrder,
  currentUser,
  purchaseOrders = [],
  searchText,
}) {
  const [showTask, setShowTaks] = useState(false);
  const { taskByProjectContext, dispatchTaskByProject } = useContext(
    TaskGroupedByProjectContext
  );
  const { tasks = {} } = taskByProjectContext;
  const currentTasks = tasks[itemOrder.id] || [];

  const filteredTasks =
    getFilterTasksByStatus({
      filters: filters,
      tasks: currentTasks,
      companyUsers,
    }) || [];

  const totalTasks = filteredTasks.length;

  useEffect(() => {
    if (openAll !== showTask) {
      setShowTaks(openAll);
    }
  }, [openAll]);

  useEffect(() => {
    const taskUnsubscribe = getDataFromListener({
      path: `${dbTables.COMPANIES}/${companyId}/${type}/${itemOrder.id}/${taskCollection}`,
      setStateData: (data) => {
        dispatchTaskByProject({
          type: TASK_GROUPED_BY_PROJECT_ACTION.ADDING_TASKS,
          payload: {
            [itemOrder.id]: data,
          },
        });
      },
    });
    addUnsubscribeListener(taskUnsubscribe);
  }, []);

  const findFirstPO = () => {
    const ids = itemOrder.purchaseOrderIds || [];
    for (let po of purchaseOrders) {
      if (ids.includes(po.id)) {
        return po;
      }
    }
    return "";
  };

  let orderParams;
  let shipment;
  let purchaseOrder;
  if (isShipment) {
    shipment = itemOrder;
    purchaseOrder = findFirstPO() || {};
    orderParams = {
      salesOrderId: salesOrder.id,
      purchaseOrderId: purchaseOrder.id,
      shipmentId: itemOrder.id,
    };
  } else {
    orderParams = {
      salesOrderId: salesOrder.id,
      purchaseOrderId: itemOrder.id,
      shipmentId: itemOrder.shipmentIds ? itemOrder?.shipmentIds[0] : "",
    };
    purchaseOrder = itemOrder;
  }
  const openOrder = showTask || searchText;
  const lastItem = (lastItemOrder && !openOrder) || filteredTasks.length === 0;
  return (
    <div className={cx("orderTaskRow", "infoContainer", `${type}Background`)}>
      <div className={cx("blank")} />
      <div
        className={cx("cellRow", "subOrderNumber", {
          lastItem,
        })}
      >
        <div className="buttonArrow">
          {itemOrder.totalTasks > 0 && (
            <ExpandMoreIcon
              onClick={() => {
                if (!searchText) {
                  setShowTaks(!showTask);
                }
              }}
              className={cx("arrowDropDownIcon", { arrowActive: openOrder })}
            />
          )}
        </div>
        <CircularStaticProgress
          label={`${itemOrder.completedTasks}/${itemOrder.totalTasks} Tasks`}
          value={+getProgressValue(itemOrder)}
          thickness={16}
          size={18}
          style={{ transform: "rotate(270deg)", color: colors.primaryDark }}
          backgroundColor="#B9B9B9"
          withBorder={7}
        />
        {getDiamondStatus({
          diamondStatus: itemOrder.diamondStatus,
          itemStatus: itemOrder.status,
          style: {
            width: 10,
            height: "auto",
            alignItems: "center",
            justifyContent: "center",
          },
        })}
        {isShipment && (
          <NavLink
            to={getDashboardSearchPath({
              ...orderParams,
              section: ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT,
            })}
            className="navLink"
            id="shipmentNumber"
          >
            {getShipmentBadge({
              shipment: itemOrder,
              classes: shipmentBadgeClass.mediumInverted,
              id: "mediumInverted",
            })}
          </NavLink>
        )}
        {!isShipment && (
          <NavLink
            to={getDashboardSearchPath({
              ...orderParams,
              section: ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER,
            })}
            className="navLink"
            id="poNumber"
          >
            <span
              className="span-description"
              style={{ textDecoration: "none" }}
            >
              <POBadge
                number={itemOrder.number}
                className="purchase-order-badge-white"
              />
            </span>
          </NavLink>
        )}
        {itemOrder.status === PO_STATUS.VOIDED && <VoidLabel />}
      </div>
      <div
        className={cx("cellRow", "tooltipCell", {
          lastItem,
        })}
      >
        <TooltipTD
          label={itemOrder.customerVendorName || ""}
          className={"tooltipData"}
        >
          <NavLink
            to={`/${itemOrder.customerVendorPath}`}
            className="navLink"
            id="list-view-content-customer"
          >
            <span className="span-description" style={{ maxWidth: 260 }}>
              {itemOrder.customerVendorName}
            </span>
          </NavLink>
        </TooltipTD>
      </div>

      <div
        className={cx("cellRow", {
          lastItem,
        })}
      >
        {totalTasks > 1 ? `${totalTasks} tasks` : `${totalTasks} task`}
      </div>
      <div
        className={cx("cellRow", {
          lastItem,
        })}
        style={{ gridColumn: "span 4" }}
      />
      <div
        className={cx("cellRow", "lastCell", {
          lastItem,
        })}
      />
      <div style={{ background: colors.salesOrderBackgroundColor }} />
      {filteredTasks.map((task, index, list) => (
        <TaskInfoRow
          task={task}
          key={task.id}
          companyUsers={companyUsers}
          type={type}
          lastItem={list.length - 1 === index}
          hiddenItems={!openOrder}
          currentOrder={itemOrder}
          salesOrder={salesOrder}
          currentUser={currentUser}
          orderParams={orderParams}
          companyId={companyId}
          purchaseOrder={purchaseOrder}
          shipment={shipment}
        />
      ))}
    </div>
  );
}

export default ItemOrderInfoRow;
