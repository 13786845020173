import { CircularProgress, styled } from "@mui/material";
import React from "react";
import { EditIcon } from "../../helpers/iconSvgPath";

const CircularProgressTimerStyled = styled("div")(() => ({
  "&.circularProgressContainer": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "& .circular-progress-MU": {
      position: "absolute",
      color: "#FF9494",
      transform: "scaleX(-1) rotate(-90deg)",
    },
    "& img": {
      zIndex: 2,
      width: 14,
      height: 14,
    },
  },
}));

function TimerLoader({
  userId = "a",
  userEditId = "b",
  seconds = 1,
  onEditTask = () => {},
}) {
  return (
    <CircularProgressTimerStyled
      className="circularProgressContainer"
      onClick={(ev) => {
        if (userEditId === userId) {
          onEditTask(ev);
        }
      }}
    >
      <CircularProgress
        variant="determinate"
        value={(100 * seconds) / 6}
        size={30}
        className="circular-progress-MU"
      />
      <EditIcon
        style={{
          cursor: "pointer",
        }}
        color="#578ec7"
        width={17}
        height={17}
      />
    </CircularProgressTimerStyled>
  );
}

export default TimerLoader;
