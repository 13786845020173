/**
 * App Settings Reducers
 */

import { ORDER_DASHBOARD_ACTUAL_VIEW, SET_LANGUAGE } from "../actions/types";

// app config
import AppConfig from "../constants/AppConfig";

/**
 * initial app settings
 */
const INIT_STATE = {
  searchFormOpen: false, // search form by default false
  startUserTour: false,
  isDarkSidenav: AppConfig.isDarkSidenav,
  themes: [
    {
      id: 1,
      name: "primary",
    },
    {
      id: 2,
      name: "secondary",
    },
    {
      id: 3,
      name: "warning",
    },
    {
      id: 4,
      name: "info",
    },
    {
      id: 5,
      name: "danger",
    },
    {
      id: 6,
      name: "success",
    },
  ],
  activeTheme: {
    id: 1,
    name: "primary",
  },
  // sidebar background image
  sidebarBackgroundImages: [],
  enableSidebarBackgroundImage: AppConfig.enableSidebarBackgroundImage, // default enable sidebar background
  selectedSidebarImage: AppConfig.sidebarImage, // default sidebar background image
  locale: AppConfig.locale,
  languages: [
    {
      languageId: "english",
      locale: "en",
      name: "English",
      icon: "en",
    },

    {
      languageId: "spanish",
      locale: "es",
      name: "Spanish",
      icon: "es",
    },
  ],
  agencyLayoutBgColors: [
    {
      id: 1,
      class: "bg-grdnt-violet",
      active: true,
    },
    {
      id: 2,
      class: "bg-grdnt-youtube",
    },
    {
      id: 3,
      class: "bg-grdnt-primary-danger",
    },
    {
      id: 4,
      class: "bg-grdnt-purple-danger",
    },
    {
      id: 5,
      class: "bg-grdnt-purple-dark",
    },
  ],
  orderDashboardActualView: "",
};

const settings = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return { ...state, locale: action.payload };
    case ORDER_DASHBOARD_ACTUAL_VIEW: {
      return { ...state, orderDashboardActualView: action.payload };
    }
    default:
      return { ...state };
  }
};

export default settings;
