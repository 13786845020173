import React, { useCallback, useContext, useEffect, useRef } from "react";
import {
  GANTT_CHART_ACTION,
  GanttChartTemplateContext,
  soTaskTableSize,
} from "../../CompanyTabs/SalesOrderTemplateContainer";
import { useCompanyUsers } from "../../../hooks/user";
import { TaskTableStyled } from "../../Timeline/styles";
import {
  debounce,
  reorder,
  validateDataTaskTemplate,
} from "../../../helpers/helpers";
import {
  STAGE_TYPE,
  TASK_TYPE,
  labelTaskPhases,
} from "../../../helpers/constants";
import { KeyboardArrowDown } from "@mui/icons-material";
import { cx } from "@emotion/css";
import { GANTT_CHART_SETTINGS } from "../../../helpers/ganttChart";
import { GANTTCHART_TABLE_SIZE } from "../../Timeline/GanttChartContext";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { sortTaskList, taskListByStage } from "../soTemplateHelper";
import { TASK_TEMPLATE_OPTIONS } from "../../../helpers/salesOrderHelpers";
import TaskTemplateItem from "./TaskTemplateItem";

function TaskTemplateContent({
  tasks,
  onAddTask = () => {},
  vendorTemplate = false,
}) {
  const { ganttChartTemplateState, dispatchGanttChartTemplate } = useContext(
    GanttChartTemplateContext
  );
  const { taskPhases, tableSize, graphTableRef, salesOrderTasks } =
    ganttChartTemplateState;
  const companyUsers = useCompanyUsers({});
  const taskTableRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (taskTableRef.current) {
        const currentTop = graphTableRef.current.scrollTop;
        taskTableRef.current.scrollTop = currentTop;
      }
    };
    if (graphTableRef && graphTableRef.current) {
      graphTableRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (graphTableRef && graphTableRef.current) {
        graphTableRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [graphTableRef]);

  useEffect(() => {
    if (tableSize === GANTTCHART_TABLE_SIZE.MEDIUM) {
      const currentTop = taskTableRef.current.scrollTop;
      graphTableRef.current.scrollTop = currentTop;
    }
  }, [tableSize]);

  const handleTaskStage = (stage) => {
    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        taskPhases: {
          ...taskPhases,
          [stage]: !taskPhases[stage],
        },
      },
    });
  };

  const onOpenAllStage = () => {
    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        taskPhases: {
          [labelTaskPhases.PROPOSAL_QUOTE]: true,
          [labelTaskPhases.PRE_PRODUCTION]: true,
          [labelTaskPhases.PRODUCTION]: true,
          [labelTaskPhases.BOOKING_TRANSIT]: true,
          [labelTaskPhases.PAYMENT_BILLING]: true,
        },
      },
    });
  };

  const addHoverUser = (tasks = []) => {
    const ids = tasks.map((task) => task.id);
    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        highlightedTask: {
          ids,
        },
      },
    });
  };

  const onDebounce = useCallback(
    debounce((tasks) => {
      addHoverUser(tasks);
    }, 1000),
    []
  );

  function isSamePosition(result) {
    const { source, destination } = result;
    return (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    );
  }

  function onDragEnd(result) {
    if (!result.destination || isSamePosition(result)) {
      return;
    }
    const { source, destination } = result;
    if (
      destination.index === 1 &&
      destination.droppableId === labelTaskPhases.PROPOSAL_QUOTE &&
      vendorTemplate
    ) {
      return;
    }

    const sourceStage = STAGE_TYPE[source.droppableId];
    const destinationStage = STAGE_TYPE[destination.droppableId];

    const taskList = taskListByStage(tasks);

    let updatedTaskList;

    if (sourceStage === destinationStage) {
      const reorderedItems = reorder(
        taskList[destinationStage],
        source.index - 1,
        destination.index - 1
      ).map((item, index) => ({ ...item, listIndex: index + 1 }));

      taskList[destinationStage] = reorderedItems;
    } else {
      const [movedTask] = taskList[sourceStage].splice(source.index - 1, 1);
      taskList[destinationStage].splice(destination.index - 1, 0, movedTask);

      const newList = taskList[destinationStage].map((item, index) => ({
        ...item,
        listIndex: index + 1,
        index,
        stage: destinationStage,
      }));
      taskList[destinationStage] = newList;

      const oldList = taskList[sourceStage].map((item, index) => ({
        ...item,
        listIndex: index + 1,
        index,
        stage: sourceStage,
      }));
      taskList[sourceStage] = oldList;
    }

    updatedTaskList = sortTaskList([
      ...taskList.PROPOSAL,
      ...taskList.PRE_PRODUCTION,
      ...taskList.PRODUCTION,
      ...taskList.BOOKING_TRANSIT,
      ...taskList.PAYMENT_BILLING,
    ]);

    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        tasks: updatedTaskList.map((taskData, index) => ({
          ...taskData,
          dependencyType: index === 0 ? "" : taskData.dependencyType,
          dependency: index === 0 ? "" : taskData.dependency,
          index,
        })),
      },
      typeChange: TASK_TEMPLATE_OPTIONS.TASK_CHANGED_POSITION,
    });
  }

  return (
    <TaskTableStyled
      id="soTask-table"
      className="soTaskTable"
      ref={taskTableRef}
      style={{
        width: soTaskTableSize[tableSize],
        overflowY: tableSize === GANTTCHART_TABLE_SIZE.LARGE ? "scroll" : "",
        paddingRight: tableSize === GANTTCHART_TABLE_SIZE.LARGE ? 10 : "",
      }}
    >
      <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={onOpenAllStage}>
        {Object.values(labelTaskPhases).map((stage) => {
          const stageTasks = tasks.filter(
            (task) => task.stage === STAGE_TYPE[stage]
          );

          const isOpen = taskPhases[stage];
          return (
            <div
              key={stage}
              className="taskList"
              style={{
                height: isOpen
                  ? (stageTasks.length + 1) * GANTT_CHART_SETTINGS.ROW_HEIGHT
                  : GANTT_CHART_SETTINGS.ROW_HEIGHT,
              }}
            >
              <Droppable droppableId={stage}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="droppableSOContainer"
                    style={{ minHeight: GANTT_CHART_SETTINGS.ROW_HEIGHT }}
                  >
                    <div className="stageTitle">
                      <div
                        className="arrowContainer"
                        onClick={() => handleTaskStage(stage)}
                      >
                        <KeyboardArrowDown
                          className="arrow"
                          style={{
                            transform: !isOpen ? "rotate(-90deg)" : "none",
                            height: 20,
                            width: 20,
                          }}
                        />
                      </div>
                      <div
                        className={cx("titleStage", {
                          collapseListTask:
                            tableSize === GANTTCHART_TABLE_SIZE.SMALL,
                        })}
                      >
                        {stage}
                        {tableSize !== GANTTCHART_TABLE_SIZE.SMALL && (
                          <div
                            className="addTask"
                            onClick={() => {
                              if (!isOpen) {
                                handleTaskStage(stage);
                              }
                              setTimeout(
                                () => onAddTask(STAGE_TYPE[stage]),
                                50
                              );
                            }}
                          >
                            + Add Task
                          </div>
                        )}
                      </div>
                    </div>

                    {stageTasks.map((task, index) => (
                      <TaskTemplateItem
                        task={task}
                        index={index}
                        tableSize={tableSize}
                        stage={stage}
                        key={task.id + "dragListItem"}
                        companyUsers={companyUsers}
                        addHoverUser={(assignedTo) =>
                          onDebounce(
                            tasks.filter(
                              (task) => task.assignedTo === assignedTo
                            )
                          )
                        }
                        removerHoverUser={() => {
                          onDebounce.cancel();
                          addHoverUser();
                        }}
                        isOpen={isOpen}
                        vendorTemplate={vendorTemplate}
                        taskError={
                          task.type !== TASK_TYPE.SALES_ORDER &&
                          !validateDataTaskTemplate({
                            poTasks: [task],
                            salesOrderTasks: salesOrderTasks,
                            users: companyUsers,
                            poList: tasks.filter(
                              (taskData) =>
                                taskData.type !== TASK_TYPE.SALES_ORDER
                            ),
                          })
                        }
                      />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          );
        })}
      </DragDropContext>
    </TaskTableStyled>
  );
}

export default TaskTemplateContent;
