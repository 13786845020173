import { useUser } from "../../hooks/user";
import LoadingBackdrop from "../WholeScreenFocusBackdrop/LoadingBackdrop";

export const ProtectedRoute = (props) => {
  const user = useUser();
  if (!user.id) {
    return <LoadingBackdrop />;
  }

  return <div>{props.children}</div>;
};
