import { Accordion, MenuItem, styled } from "@mui/material";
import { colors } from "../../assets/jss/variables";
import { convertHex } from "../../helpers/timelineCalendar";

export const MenuItemTemplateStyled = styled(MenuItem)(() => ({
  "&.template-version-list-item": {
    "& span": {
      fontSize: 14,
      color: colors.primaryDark,
    },
    "& .span-right-justified": {
      justifySelf: "end",
      marginRight: 32,
    },
  },
  "&.active": {
    "&::before": {
      content: '""',
      position: "absolute",
      width: 5,
      height: "100%",
      background: colors.primaryDark,
      left: 0,
    },
  },
  "&.template-version-list-item-header": {
    display: "grid",
    gridTemplateColumns: "84px 120px 180px 1fr",
    backgroundColor: colors.tableHeaderColor,
    color: colors.primaryDark,
    fontWeight: 500,
    position: "sticky",
    top: 0,
    zIndex: 1,
    "& span": {
      fontSize: 14,
      color: colors.primaryDark,
    },
    "& .span-right-justified": {
      justifySelf: "end",
      marginRight: 32,
    },
  },
}));

export const SalesOrderTemplateContainerStyled = styled("div")(() => ({
  "&.sales-order-container": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    "& .sales-order-title-container": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: 10,
      padding: "8px 16px",
      width: "100%",
      "& .left-title-container": {
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        gap: 24,
        color: colors.primaryDark,
        width: "auto",
        "& .title": {
          fontSize: "1rem",
          fontWeight: 700,
          color: colors.primaryDark,
          textWrap: "nowrap",
          display: "flex",
          alignItems: "center",

          "&::after": {
            content: '""',
            display: "block",
            width: 2,
            height: 28,
            background: colors.dividerColor,
            marginLeft: 24,
          },
        },
        "& .label": {
          textWrap: "nowrap",
          fontSize: 14,
        },
        "& .created-by": {
          padding: 2,
          display: "flex",
          alignItems: "center",
          gap: 4,
          fontSize: 14,
        },
        "& .description-tooltip": {
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "flex",
          gap: 4,
          fontSize: 14,
          "& .description-span": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            textWrap: "nowrap",
            fontStyle: "italic",
            paddingRight: 5,
          },
        },
      },
      "& .right-buttons-container": {
        display: "flex",
        gap: 10,
      },
    },
  },
}));

export const ChangeLogHeaderStyled = styled("div")(() => ({
  "&.table-header-stage": {
    gridTemplateColumns:
      "36px 75px 1fr 38px 204px 68px 47px 140px 124px 123px 135px",
    gap: "21px",
    display: "grid",
    backgroundColor: colors.tableHeaderColor,
    padding: "8px 16px",
    margin: 0,
    alignItems: "center",
    "& span": {
      fontSize: 13,
      fontWeight: 600,
    },
  },
}));
export const AccordionChangeLogsStyled = styled(Accordion)(() => ({
  "& .container": {
    margin: "0px 4px 4px 4px",
    boxShadow: "0 2px 4px 2px #ccc",
    height: "calc(100vh - 165px)",
    display: "block",
    padding: 16,
  },
  "&.accordionSummaryExpanded": {
    background: "#f5f5f5",
  },
  "& .expandIcon": {
    position: "absolute",
    left: "0px",
  },
  "& .tasks-in-stage-container": {
    margin: "0px  24px 0px 32px",
  },
  "& .stage-title": {
    fontSize: 16,
    fontWeight: 700,
    color: colors.primaryDark,
    padding: "16px 0px",
    marginLeft: 16,
  },
  "& .task-displayed": {
    gridTemplateColumns:
      "8px 82px 1fr 33px 224px 50px 60px 157px 119px 135px 108px",
    gap: 15,
    display: "grid",
    backgroundColor: colors.backgroundBlue,
    padding: 16,
    borderBottom: `1px solid ${colors.dividerColor}`,
    "& span": {
      color: colors.primaryDark,
      fontSize: 14,
      "& s": {
        color: colors.dangerRed,
      },
    },
    "& .left-justified": {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
    },
    "& .right-justified": {
      display: "flex",
      alignItems: "flex-end",
      flexDirection: "column",
      padding: "0px 10px 0px 0px",
    },
    "& .center-justified": {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
  },
  "& .added-tasks": {
    backgroundColor: convertHex(colors.diamondGreen, 0.15),
    alignItems: "center",
  },
  "& .deleted-tasks": {
    backgroundColor: convertHex(colors.dangerRed, 0.15),
    alignItems: "center",
  },
  "& .updated-tasks": {
    backgroundColor: convertHex("#FFDC00", 0.2),
  },
  "& .accordion-description": {
    display: "grid",
    gridTemplateColumns: "200px 100px 200px 1fr",
    gap: "24px",
    "& .span-content": {
      padding: 4,
    },
  },
}));

export const ChangeLogsHeaderStyled = styled("div")(() => ({
  "&.change-logs-header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .title": {
      fontSize: "1.5rem",
      fontWeight: 700,
      color: colors.primaryDark,
    },
    "& .change-log-legend-container": {
      display: "flex",
      alignItems: "center",
      gap: 10,
      "& .legend-content": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        "& .label": {
          fontSize: 13,
          color: colors.primaryDark,
          fontWeight: 600,
        },
        "& .rectangle-green": {
          width: 120,
          height: 30,
          backgroundColor: convertHex(colors.diamondGreen, 0.15),
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        "& .rectangle-red": {
          width: 120,
          height: 30,
          backgroundColor: convertHex(colors.dangerRed, 0.15),
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        "& .rectangle-yellow": {
          width: 120,
          height: 30,
          backgroundColor: convertHex("#FFDC00", 0.2),
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      },
    },
  },
}));

export const FullTemplateDetailsStyled = styled("div")(() => ({
  "&.full-template-details": {
    width: "calc(100vw - 200px)",
    height: "calc(100vh - 200px)",
    overflow: "auto",
    "& .tasks-in-stage-container": {
      margin: "0px  0px 0px 32px",
    },
    "& .stage-title": {
      fontSize: 16,
      fontWeight: 700,
      color: colors.primaryDark,
      padding: "16px 0px",
      marginLeft: 16,
    },
    "& .task-displayed": {
      gridTemplateColumns: "83px 1fr 100px 271px 52px 46px 124px 153px 135px",
      gap: 21,
      display: "grid",
      backgroundColor: colors.backgroundBlue,
      padding: 16,
      borderBottom: `1px solid ${colors.dividerColor}`,
      //alignItems: "center",
      alignItems: "center",
      "& span": {
        color: colors.primaryDark,
        fontSize: 12,
        "& s": {
          color: colors.dangerRed,
        },
      },
      "& .left-justified": {
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
      },
      "& .right-justified": {
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "column",
        padding: "0px 10px 0px 0px",
      },
      "& .center-justified": {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      },
    },
    "& .SALES_ORDER": {
      backgroundColor: colors.salesOrderBackgroundColor,
    },
    "& .PURCHASE_ORDER": {
      backgroundColor: colors.purchaseOrderBackgroundColor,
    },
    "& .SHIPMENT": {
      backgroundColor: colors.shipmentBadge,
    },
  },
}));

export const FullTemplateDetailTitleStyled = styled("div")(() => ({
  "&.title-detailed-container": {
    display: "flex",
    alignItems: "center",
    gap: 24,
    width: "calc(100vw - 200px)",
    "& .label": {
      textWrap: "nowrap",
    },
    "& .created-by": {
      padding: 2,
      display: "flex",
      alignItems: "center",
      gap: 4,
    },
    "& .description-tooltip": {
      overflow: "hidden",
      textOverflow: "ellipsis",

      "& .description-span": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        textWrap: "nowrap",
        fontStyle: "italic",
        paddingRight: 5,
      },
    },
  },
}));
