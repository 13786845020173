import React, { useState } from "react";
import CustomModal from "./Modal";
import IntlMessages from "../../util/IntlMessages";
import CustomButton from "../Buttons/CustomButton";
import { TextareaAutosize } from "@mui/material";

const errorLabel = {
  emptyValue: "Label name cannot be blank",
  duplicityName: "Label name already exists",
  biggerName: "Label name cannot exceed 40 characters",
};

function CreateEditLabel({
  openModal,
  handleClose,
  currentTag,
  onSubmit,
  isUpdating,
  tags,
}) {
  const [name, setName] = useState(currentTag.name);
  const [description, setDescription] = useState(currentTag.description || "");
  const [error, setError] = useState("");

  const isSame =
    name.trim().toLowerCase() ===
      (currentTag.name || "").trim().toLowerCase() &&
    description.trim().toLowerCase() ===
      (currentTag.description || "").trim().toLowerCase();
  return (
    <CustomModal
      header={
        <IntlMessages
          id={`title.text.label.${isUpdating ? "update" : "create"}`}
        />
      }
      isOpen={openModal}
      onClose={handleClose}
      className={"CreateEditLabel"}
    >
      <div className={"formContainer"}>
        <span style={{ width: 80 }}>Name</span>
        <input
          id={"category-id-" + currentTag.id}
          value={name}
          style={{ width: 300 }}
          onChange={(ev) => {
            const value = ev.target.value;
            const parseValue = value.replace(/\s+/g, " ");
            setName(parseValue);
            if (parseValue === "") {
              setError("emptyValue");
              return;
            }
            const isThereTag = tags.find(
              (tag) =>
                tag.name.trim().toLowerCase() ===
                  parseValue.trim().toLowerCase() && tag.id !== currentTag.id
            );
            if (isThereTag) {
              setError("duplicityName");
              return;
            }
            if (parseValue.trim().length > 40) {
              setError("biggerName");
              return;
            }
            return setError("");
          }}
          autoFocus
        />
      </div>
      <span className="errorText">{errorLabel[error]}</span>
      <div className={"formContainer"}>
        <span style={{ width: 80 }}>Description</span>
        <TextareaAutosize
          id={"description-id-" + currentTag.id}
          value={description}
          onChange={(ev) => {
            const value = ev.target.value;
            setDescription(value);
          }}
          placeholder="Category description"
          required={false}
          minRows={2}
          maxLength={250}
          style={{ width: 300, minHeight: 45, height: "auto", maxHeight: 250 }}
        />
      </div>
      <div className="buttonContainer">
        <CustomButton
          onClick={() => onSubmit(name, description)}
          className={"button-success"}
          disabled={error || isSame}
        >
          {isUpdating ? "generic.text.update" : "generic.text.create"}
        </CustomButton>

        <CustomButton type="cancel" onClick={handleClose}>
          {"generic.text.cancel"}
        </CustomButton>
      </div>
    </CustomModal>
  );
}

export default CreateEditLabel;
