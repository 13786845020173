import moment from "moment";
import { getRandomId } from "../../helpers/helpers";

export default class FactorySummary {
  constructor({
    id = getRandomId(),
    factories = [],
    size = 1,
    created = moment().valueOf(),
    updated = moment().valueOf(),
  }) {
    this.id = id;
    this.factories = factories;
    this.size = size;
    this.created = created;
    this.updated = updated;
  }
}
