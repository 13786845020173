import React from "react";
import { orderType } from "../../helpers/salesOrder";
import {
  NOTES_SCOPE,
  PO_STATUS,
  SALES_ORDER_STATUS,
} from "../../helpers/constants";
import { colors } from "../../assets/jss/variables";
import {
  PurchaseOrderIcon,
  SalesOrderIcon,
  ShipmentOrderIcon,
} from "../../helpers/iconSvgPath";
import { CreateNoteCollapseStyled } from "./styles";
import { cx } from "@emotion/css";
import { Button, ButtonGroup } from "@mui/material";
import TooltipTD from "../Tooltip/TooltipTD";

const orderData = ({ purchaseOrder, salesOrder }) => [
  {
    type: orderType.SALES_ORDER,
    name: salesOrder.type === orderType.QUOTE ? "Quote" : "Sales Order",
    scope: NOTES_SCOPE.SALES_ORDER,
    icon: <SalesOrderIcon width={18} height={18} svgClass={"orderIcon"} />,
    className: "soCreationNote",
    key: `${orderType.SALES_ORDER}_Button${NOTES_SCOPE.SALES_ORDER}`,
  },
  {
    type: orderType.PURCHASE_ORDER,
    name:
      purchaseOrder.type === orderType.PURCHASE_QUOTE
        ? "Purchase Quote"
        : "Purchase Order",
    scope: NOTES_SCOPE.PURCHASE_ORDER,
    className: "poCreationNote",
    icon: (
      <PurchaseOrderIcon
        width={18}
        height={18}
        color={colors.primaryDark}
        svgClass={"orderIcon"}
      />
    ),
    key: `${orderType.SALES_ORDER}_Button${NOTES_SCOPE.SALES_ORDER}`,
  },
  {
    type: orderType.SHIPMENT,
    name: "Shipment",
    scope: NOTES_SCOPE.SHIPMENT,
    className: "shipmentCreationNote",
    icon: <ShipmentOrderIcon width={18} height={18} svgClass={"orderIcon"} />,
    key: `${orderType.SHIPMENT}_Button${NOTES_SCOPE.SHIPMENT}`,
  },
];

function ButtonsCreateNotes({
  salesOrder,
  purchaseOrder,
  shipment,
  onOpenNote,
  showButtons,
  isSmallPanel,
}) {
  function getDisabledStatus(type) {
    switch (type) {
      case orderType.SALES_ORDER:
        return salesOrder.status === SALES_ORDER_STATUS.VOIDED;
      case orderType.PURCHASE_ORDER:
        return purchaseOrder.status === PO_STATUS.VOIDED;
      case orderType.SHIPMENT:
        return !shipment.id;
      default:
        return false;
    }
  }

  return (
    <CreateNoteCollapseStyled
      in={showButtons}
      className="createNoteCollapse"
      timeout={750}
    >
      <span style={{ color: "#92A1B0", marginLeft: 4 }}>New note:</span>
      <ButtonGroup style={{ marginRight: 8, marginLeft: 4 }}>
        {orderData({ purchaseOrder, salesOrder }).map(
          ({ type, name, scope, icon, className, key }) => {
            const disabled = getDisabledStatus(type);
            return (
              <TooltipTD
                showToolTip={isSmallPanel}
                label={name}
                style={{ width: `${100 / orderData.length}%` }}
                key={key}
              >
                <Button
                  className={cx("noteButton", className, {
                    disableStyled: disabled,
                  })}
                  onClick={() => onOpenNote(scope)}
                  disabled={disabled}
                  color="info"
                  disableRipple={disabled}
                >
                  {icon}
                  <span className="text-name">{!isSmallPanel && name}</span>
                </Button>
              </TooltipTD>
            );
          }
        )}
      </ButtonGroup>
    </CreateNoteCollapseStyled>
  );
}

export default ButtonsCreateNotes;
