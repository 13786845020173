import moment from "moment";
import IntlMessages from "../../util/IntlMessages";
import { ArrowsToSortIcon } from "../../helpers/iconSvgPath";
import { colors } from "../../assets/jss/variables";
import CustomCheckbox from "../Inputs/CustomCheckbox";
import { cx } from "@emotion/css";

export const getDateFormat = (date) =>
  date ? moment(date).format("M/D/YY") : "";

export const getSettingsTableHeader = ({
  headers = [],
  updateSorting = () => {},
  filters,
  sortArrowsColor = "#EEEEEE",
  checked = false,
  onChangeCheckBox = () => {},
  hadSpecialStyle = false,
}) => {
  function sortActualColumn(label) {
    if (filters.sortedColumn === label) {
      if (filters.orderBy === "ASC") {
        updateSorting({ ...filters, orderBy: "DESC" });
      } else {
        updateSorting({ ...filters, orderBy: "ASC" });
      }
    } else {
      updateSorting({
        ...filters,
        sortedColumn: label,
        orderBy: "ASC",
      });
    }
  }

  function renderArrow(headerLabel) {
    if (filters.sortedColumn === headerLabel) {
      if (filters.orderBy === "ASC") {
        return (
          <ArrowsToSortIcon
            style={{
              opacity: 1,
              minWidth: 13,
              minHeight: 11,
            }}
            leftColor={colors.primaryDark}
            rightColor={sortArrowsColor}
          />
        );
      }
      return (
        <ArrowsToSortIcon
          style={{
            opacity: 1,
            minWidth: 13,
            minHeight: 11,
          }}
          rightColor={colors.primaryDark}
          leftColor={sortArrowsColor}
        />
      );
    }
    return (
      <ArrowsToSortIcon
        style={{ minWidth: 13, minHeight: 11 }}
        leftColor={sortArrowsColor}
        rightColor={sortArrowsColor}
        svgClass="arrow"
      />
    );
  }

  return headers.map((itemHeader) => {
    const {
      styles = {},
      label = "",
      id = "",
      key,
      spanStyle = {},
      className = "",
    } = itemHeader;
    const permissionStyle = hadSpecialStyle
      ? itemHeader.permissionStyle || {}
      : {};
    return (
      <div
        key={id + label}
        className={cx("headerTable", className)}
        style={{ ...styles, ...permissionStyle }}
        onClick={() => {
          if (itemHeader.canBeSort) sortActualColumn(key);
        }}
      >
        <span style={spanStyle}>{<IntlMessages id={label} />}</span>
        {itemHeader.canBeSort &&
          renderArrow(itemHeader.key, itemHeader.arrowStyles)}
        {itemHeader.checkIcon && (
          <CustomCheckbox
            className={"headerCheckbox"}
            checked={checked}
            onChange={(ev) => {
              onChangeCheckBox(ev.target.value);
              ev.stopPropagation();
            }}
          />
        )}
        <div className="whiteDivider" />
      </div>
    );
  });
};
