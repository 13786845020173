import React, { useContext, useRef, useState } from "react";
import {
  GANTT_CHART_ACTION,
  GanttChartTemplateContext,
} from "../../CompanyTabs/SalesOrderTemplateContainer";

import {
  ArrowDownIcon,
  DeleteIconv2,
  DraggableIcon,
} from "../../../helpers/iconSvgPath";
import { Draggable } from "react-beautiful-dnd";
import { TemplateItemStyled } from "../../Timeline/styles";
import { cx } from "@emotion/css";
import {
  DEPENDENCY_TYPE,
  STAGE_TYPE,
  TASK_TYPE,
} from "../../../helpers/constants";
import {
  getCompanyUserDisplayName,
  sortObjectsBy,
} from "../../../helpers/helpers";
import SimpleEditableNumber from "../../TextFields/SimpleEditableNumber";
import UserPicker from "../../DatePicker/UserPicker";
import { getUserAvatar } from "../../../helpers/users";
import TooltipTD from "../../Tooltip/TooltipTD";
import SetupNotifications from "../SetupNotifications";
import CustomSelect from "../CustomSelected";
import { GANTTCHART_TABLE_SIZE } from "../../Timeline/GanttChartContext";
import SimpleEditableLabel from "../../TextFields/SimpleEditableLabel";
import {
  getVendorIndex,
  sortTaskList,
  taskListByStage,
} from "../soTemplateHelper";
import { colors } from "../../../assets/jss/variables";
import { TASK_TEMPLATE_OPTIONS } from "../../../helpers/salesOrderHelpers";
import CustomCheckbox from "../../Inputs/CustomCheckbox";
import AppConfig from "../../../constants/AppConfig";
import { KeyboardTab } from "@mui/icons-material";
import { isEqual } from "lodash";

function TaskTemplateItem({
  task,
  index,
  tableSize,
  companyUsers,
  stage,
  addHoverUser,
  removerHoverUser,
  vendorTemplate = false,
  taskError = false,
  isOpen = false,
}) {
  const userRef = useRef();
  const [openUserPicker, setOpenUserPicker] = useState(false);
  const { ganttChartTemplateState, dispatchGanttChartTemplate } = useContext(
    GanttChartTemplateContext
  );
  const {
    graphTableRef,
    tasks,
    salesOrderTasks = [],
    deletedTasks = [],
  } = ganttChartTemplateState;
  const isFirstElement = task.index === 0;

  const onChangeTaks = (updatedData) => {
    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.UPDATE_TASK,
      payload: {
        id: task.id,
        ...updatedData,
      },
      typeChange: TASK_TEMPLATE_OPTIONS.ADD_TASK,
    });
  };
  const onDelete = (taskId) => {
    const taskFilters = tasks.filter((taskData) => taskData.id !== taskId);
    const newTaskList = taskListByStage(taskFilters);
    const currentStage = STAGE_TYPE[stage];

    newTaskList[currentStage] = newTaskList[currentStage].map(
      (taskData, index) => ({
        ...taskData,
        listIndex: index + 1,
      })
    );

    const updatedTaskList = sortTaskList(Object.values(newTaskList).flat());
    const newDeletedTasks = [...deletedTasks];
    if (task.ref) {
      newDeletedTasks.push(task);
    }
    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        tasks: updatedTaskList.map((taskData, index) => ({
          ...taskData,
          dependencyType: index === 0 ? "" : taskData.dependencyType,
          dependency: index === 0 ? "" : taskData.dependency,
          index,
        })),
        deletedTasks: newDeletedTasks,
      },
      typeChange: TASK_TEMPLATE_OPTIONS.DELETE_TASK,
    });
  };

  const getLabelTask = () => {
    if (!vendorTemplate) {
      return `S.${task.index + 1}`;
    } else {
      const suffix = task.type === TASK_TYPE.SALES_ORDER ? "S." : "P.";
      const index = getVendorIndex({
        currentTask: task,
        salesOrderTasks,
        tasks,
      });

      return `${suffix}${index}`;
    }
  };
  const isBigSize = tableSize === GANTTCHART_TABLE_SIZE.LARGE;
  const isDisableSoTask = vendorTemplate && task.type === TASK_TYPE.SALES_ORDER;
  const bigVendorGrid = vendorTemplate && isBigSize ? "bigLargeTemplate" : "";

  return (
    <>
      {openUserPicker && (
        <UserPicker
          el={userRef.current}
          onChange={(userId, ev, blockPermission) => {
            if (blockPermission) {
              return;
            }
            onChangeTaks({
              assignedTo: userId,
              lastAssignedTo: task.assignedTo,
            });
            setOpenUserPicker(!openUserPicker);
            ev.stopPropagation();
          }}
          open={openUserPicker}
          onClose={() => {
            setOpenUserPicker(!openUserPicker);
          }}
          value={task.assignedTo}
          users={companyUsers
            .filter((user) => user.active)
            .sort(sortObjectsBy("displayName", false))}
          permissionToVerify={task.factoryId}
          isTherePermission={false}
          isCustomer={!vendorTemplate}
        />
      )}
      <Draggable
        key={task.id + "Draggable"}
        draggableId={task.id}
        index={index + 1}
        isDragDisabled={isDisableSoTask || !isOpen}
        shouldRespectForcePress
      >
        {(providedDrag) => (
          <TemplateItemStyled
            key={task.id + "itemRow"}
            ref={providedDrag.innerRef}
            {...providedDrag.draggableProps}
            {...providedDrag.dragHandleProps}
            style={{
              ...providedDrag.draggableProps.style,
              background: taskError ? colors.diamondRed : "",
            }}
            className={cx(
              `task${task.type}`,
              "itemTemplate",
              `${tableSize}Grid`,
              bigVendorGrid
            )}
          >
            <div className="taskNumber">
              {!isDisableSoTask && (
                <DraggableIcon svgClass="dragClass" height={14} width={14} />
              )}
            </div>
            <span>{getLabelTask()}</span>
           <SimpleEditableLabel
              onSave={(ev) =>
                onChangeTaks({
                  description: ev.target.value,
                })
              }
              text={task.description}
              className="soTaskEdit"
              showTooltip
              alwaysInput={!isDisableSoTask}
              disable={isDisableSoTask}
            />
            {tableSize !== GANTTCHART_TABLE_SIZE.SMALL && (
              <>
                <CustomSelect
                  task={task}
                  dependencyTypeOptions={Object.keys(DEPENDENCY_TYPE)}
                  isDependencyType
                  labelIdSuffix="type"
                  handleChange={(ev) =>
                    onChangeTaks({
                      dependencyType: ev.target.value,
                    })
                  }
                  showLabel={isBigSize}
                  disabled={isDisableSoTask}
                />

                <CustomSelect
                  tasks={tasks}
                  task={task}
                  labelIdSuffix="dependency"
                  handleChange={(ev) => {
                    const newDependencyId = ev.target.value;
                    if (vendorTemplate) {
                      const newDependency =
                        tasks.find(
                          (taskData) => taskData.id === newDependencyId
                        ) || {};
                      onChangeTaks({
                        dependency: newDependencyId,
                        dependsOnSOT:
                          newDependency.type === TASK_TYPE.SALES_ORDER,
                      });
                    } else {
                      onChangeTaks({
                        dependency: newDependencyId,
                      });
                    }
                  }}
                  showToolTip
                  disabled={isDisableSoTask}
                  getVendorIndex={
                    vendorTemplate
                      ? (currentTask) =>
                          getVendorIndex({
                            currentTask: currentTask,
                            salesOrderTasks,
                            tasks,
                          })
                      : ""
                  }
                />
                <SimpleEditableNumber
                  number={task.duration}
                  className="numberValue"
                  onSave={(ev) => {
                    onChangeTaks({
                      duration: ev.target.value,
                    });
                  }}
                  showEditIcon
                  disable={isDisableSoTask}
                />
                <SimpleEditableNumber
                  number={isFirstElement ? "" : task.offset}
                  disable={isFirstElement || isDisableSoTask}
                  className="numberValue"
                  allowNegative
                  onSave={(ev) => {
                    onChangeTaks({
                      offset: ev.target.value,
                    });
                  }}
                  showEditIcon
                />

                <div
                  ref={userRef}
                  className={"userData"}
                  onClick={() => {
                    if (!isDisableSoTask) {
                      setOpenUserPicker(true);
                    }
                  }}
                  onMouseEnter={() => addHoverUser(task.assignedTo)}
                  onMouseLeave={() => removerHoverUser()}
                  style={{
                    cursor: isDisableSoTask ? "default" : "pointer",
                    fontWeight: isDisableSoTask ? "400" : "",
                    opacity: isDisableSoTask ? 0.5 : 1,
                  }}
                >
                  {getUserAvatar({
                    user: companyUsers.find(
                      (companyUser) => companyUser.id === task.assignedTo
                    ),
                    styles: {
                      width: 20,
                      height: 20,
                      fontSize: 13,
                      outline: "1px solid #000",
                    },
                    showEmptyAvatar: true,
                  })}

                  {isBigSize && (
                    <TooltipTD
                      label={
                        getCompanyUserDisplayName(
                          companyUsers,
                          task.assignedTo
                        ) || "Pick a User"
                      }
                      className="userName"
                    >
                      {getCompanyUserDisplayName(
                        companyUsers,
                        task.assignedTo
                      ) || "SelectUser"}
                    </TooltipTD>
                  )}
                  {!isDisableSoTask && (
                    <ArrowDownIcon svgClass={"avatarArrow"} />
                  )}
                </div> 
              </>
            )}
            {isBigSize && (
              <>
                <SetupNotifications
                  item={{
                    ...task,
                    permissionUsers: task.userNotificationEarly || {},
                    permissionGroups: task.groupNotificationEarly || {},
                  }}
                  onSubmit={({ item }) => {
                    if (
                      !isEqual(item.permissionUsers, task.userNotificationEarly)
                    ) {
                      onChangeTaks({
                        userNotificationEarly: item.permissionUsers,
                      });
                    }
                  }}
                  hiddenButton={isDisableSoTask}
                />
                <SetupNotifications
                  item={{
                    ...task,
                    permissionUsers: task.userNotificationLate || {},
                    permissionGroups: task.groupNotificationLate || {},
                  }}
                  onSubmit={({ item }) => {
                    if (
                      !isEqual(item.permissionUsers, task.userNotificationLate)
                    ) {
                      onChangeTaks({
                        userNotificationLate: item.permissionUsers,
                      });
                    }
                  }}
                  hiddenButton={isDisableSoTask}
                />
                {vendorTemplate && (
                  <div>
                    {task.type !== TASK_TYPE.SALES_ORDER && (
                      <CustomCheckbox
                        onChange={(event) => {
                          if (event.target.checked) {
                            onChangeTaks({
                              type: TASK_TYPE.SHIPMENT,
                              color:
                                AppConfig.themeColors
                                  .shipmentBackgroundGanttChartColor,
                            });
                          } else {
                            const currentDependency =
                              tasks.find(
                                (taskData) => taskData.id === task.dependency
                              ) || {};
                            onChangeTaks({
                              type: TASK_TYPE.PURCHASE_ORDER,
                              color: colors.purchaseOrderBorderColor,
                              dependency:
                                !currentDependency.id ||
                                currentDependency.type === TASK_TYPE.SHIPMENT
                                  ? ""
                                  : task.dependency,
                            });
                          }
                        }}
                        checked={task.type === TASK_TYPE.SHIPMENT}
                        iconSize={20}
                        styles={{ padding: 0 }}
                      />
                    )}
                  </div>
                )}

                <div>
                  {!isDisableSoTask && (
                    <DeleteIconv2
                      onClick={() => {
                        onDelete(task.id);
                      }}
                      color={!taskError ? "#DC5E5E" : "white"}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  )}
                </div>
              </>
            )}
            <div
              className={cx("taskColorColumn", `border${task.type}`)}
              style={{
                background:
                  task.type === TASK_TYPE.SALES_ORDER
                    ? colors.salesOrderSelectedBorderColor
                    : task.color,
              }}
              onClick={(ev) => {
                const element = document.getElementById(`${task.id}-rnd`);
                const scrollElement = graphTableRef.current;
                const elementRect = element.getBoundingClientRect();
                const scrollRect = scrollElement.getBoundingClientRect();
                const scrollLeft =
                  elementRect.left -
                  scrollRect.left +
                  scrollElement.scrollLeft -
                  20;
                scrollElement.scrollTo({
                  left: scrollLeft,
                  behavior: "smooth",
                });

                ev.stopPropagation();
              }}
            >
              <TooltipTD
                label={"Scroll to this task"}
                style={{ display: "flex" }}
              >
                <KeyboardTab
                  style={{
                    width: 16,
                    height: 16,
                    color: "#000",
                  }}
                />
              </TooltipTD>
            </div> 
          </TemplateItemStyled>
        )}
      </Draggable>
    </>
  );
}

export default TaskTemplateItem;
