import React from "react";
import { InactiveContainerStyled } from "./styles";

function InactiveDivider({ text = "" }) {
  return (
    <InactiveContainerStyled className="InactiveContainer">
      <span className="inactiveText">{text}</span>
    </InactiveContainerStyled>
  );
}

export default InactiveDivider;
