import Customer from "../api/model/customer";
import CustomerItem from "../api/model/customerItem";
import { dbTables } from "../api/types/dbTables";
import { getRandomId } from "./helpers";
import CustomerSummary from "../api/model/customerSummary";
import { firestore } from "../firebase";
import { doc, increment, setDoc, updateDoc } from "firebase/firestore";

export const triggerCustomerTypes = {
  NUMBER: "NUMBER",
  NAME: "NAME",
  INACTIVE: "INACTIVE",
  OTHERS: "OTHERS",
  DISMISS_TRIGGER: "DISMISS_TRIGGER",
};

export const createCustomer = async function({
  companyId,
  customer,
  summary,
  allCustomers = [],
  user = {},
}) {
  const companyPath = `${dbTables.COMPANIES}/${companyId}`;
  const newCustomer = { ...new Customer({ ...customer }) };
  let errorName = false;
  let errorNumber = false;
  allCustomers.forEach((customerData) => {
    const currentName = customerData.name.toLowerCase();
    const currentNumber = `${customerData.number}`.toLowerCase();
    const newName = newCustomer.name.toLowerCase();
    const newNumber = `${newCustomer.number}`.toLowerCase();
    errorName = errorName || currentName === newName;
    errorNumber = errorNumber || currentNumber === newNumber;
  });
  if (errorName || errorNumber) {
    return {
      errorName,
      errorNumber,
    };
  }

  const newPermission = {
    ...user.permissions,
    [newCustomer.id]: true,
  };
  await updateDoc(doc(firestore, `${dbTables.USERS}/${user.id}`), {
    permissions: {
      ...newPermission,
    },
  });

  const currentSummary = summary[0];
  let customersSummaryId = currentSummary.id;
  const newCustomerItem = new CustomerItem({
    id: newCustomer.id,
    name: customer.name,
    inactive: false,
    number: customer.number,
  });
  if (currentSummary && currentSummary.size < 100) {
    const customerCpy = [...currentSummary.customers];
    customerCpy.push({ ...newCustomerItem });
    await updateDoc(currentSummary.ref, {
      size: increment(1),
      customers: customerCpy,
    });
    customersSummaryId = currentSummary.id;
  } else {
    // CREATE NEW SUMMARY DOC AND ADD THE NEW FACTORY INTO IT.
    const newCustomerSummary = {
      ...new CustomerSummary({
        size: 1,
        id: getRandomId(),
        customers: [{ ...newCustomerItem }],
      }),
    };
    customersSummaryId = newCustomerSummary.id;
    await setDoc(
      doc(
        firestore,
        `${companyPath}/${dbTables.CUSTOMERS_SUMMARY}/${newCustomerSummary.id}`
      ),
      newCustomerSummary
    );
  }
  await setDoc(
    doc(firestore, `${companyPath}/${dbTables.CUSTOMERS}/${newCustomer.id}`),
    { ...newCustomer, customersSummaryId }
  );
  return { success: true, id: newCustomer.id };
};
