import React from "react";
import IntlMessages from "../../util/IntlMessages";
import ReactPhoneInput from "react-phone-input-2";
import MultiSelect from "./MultiSelect";
import { getTheDate } from "../../helpers/helpers";

function FormTextField({
  value,
  onChange,
  onDeletePermission = () => {},
  width,
  label,
  options = [],
  required = false,
  type = "text",
  autoFocus = false,
  name,
  disabled,
  onlyText = false,
  labelStyle = {},
  inputStyle = {},
  containerClass,
  inputError,
  spanErrorStyle = {},
}) {
  return (
    <div className={containerClass || "form-input"} key={type + label}>
      <label
        style={{
          ...labelStyle,
        }}
        htmlFor="input"
      >
        <span style={{ color: required ? "red" : "white" }}> * </span>
        {<IntlMessages id={label} />}
      </label>

      {type === "phone" && (
        <ReactPhoneInput
          disabled={disabled}
          inputStyle={{
            required: true,
            disabled: disabled,
          }}
          containerClass="phoneInputSettings"
          defaultCountry="us"
          value={value}
          onChange={onChange}
          autoFormat={false}
        />
      )}
      {type === "select" && (
        <select
          style={{ width: width, marginLeft: 5 }}
          id={"input"}
          onChange={onChange}
          value={value}
          disabled={disabled}
          required={required}
        >
          <option value="" disabled>
            Select one
          </option>
          {options.map((item) => {
            if (item.value === "SEPARATOR") {
              return <hr />;
            } else {
              return (
                <option value={item.value} key={item.value}>
                  {!onlyText && <IntlMessages id={item.label} />}
                  {onlyText && item.label}
                </option>
              );
            }
          })}
        </select>
      )}
      {type === "multiSelect" && (
        <MultiSelect
          value={value}
          onChange={onChange}
          options={options}
          disabled={disabled}
          required={false}
          onDeletePermission={onDeletePermission}
        />
      )}

      {type !== "select" && type !== "phone" && type !== "multiSelect" && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            position: "relative",
          }}
        >
          <input
            autoFocus={autoFocus}
            type={type}
            id={"input"}
            onChange={onChange}
            style={{
              ...inputStyle,
              width: width,
              marginLeft: "4px",
              textTransform: type === "email" ? "lowercase" : "",
            }}
            value={type === "date" ? getTheDate(value, "YYYY-MM-DD") : value}
            required={required}
            name={name}
            disabled={disabled}
          />
          {inputError && (
            <span className="inputError" style={{ ...spanErrorStyle }}>
              {inputError}
            </span>
          )}
        </div>
      )}
    </div>
  );
}
export default FormTextField;
