import React, { useState, useEffect } from "react";

function AttentionModalDescription({ offset, isMovingForward }) {
  const [dayOffsetRemainingTasks, setDayOffsetRemainingTasks] = useState(1);

  useEffect(() => {
    setDayOffsetRemainingTasks(Math.abs(offset));
  }, [offset]);

  return (
    <span className="task-sheet-modal-input">
      Move all dependant tasks{" "}
      <input
        id="remaining-offset-days"
        value={dayOffsetRemainingTasks}
        style={{ width: 54 }}
        type="number"
        onChange={(ev) => {
          const value = ev.target.value;
          if (value > 0) {
            setDayOffsetRemainingTasks(
              value > 364 ? dayOffsetRemainingTasks : value
            );
          } else {
            setDayOffsetRemainingTasks(1);
          }
        }}
        min={1}
      />
      {dayOffsetRemainingTasks > 1 ? "days" : "day"}
      <span
        style={{
          color: isMovingForward ? "red" : "green",
        }}
      >
        {isMovingForward ? " later?" : " earlier?"}
      </span>
    </span>
  );
}

export default AttentionModalDescription;
