import { now } from "moment";
import { TYPE_OF_TAGS } from "../../helpers/constants";
import { getRandomId } from "../../helpers/helpers";

export default class Tag {
  constructor({
    id = getRandomId(),
    name = "",
    description = "",
    createdDate = now(),
    createdBy = "",
    updatedDate = "",
    updatedBy = "",
    type = TYPE_OF_TAGS.REGULAR_TAG,
    inactive = false,
  }) {
    this.id = id;
    this.name = name;
    this.createdDate = createdDate;
    this.createdBy = createdBy;
    this.updatedDate = updatedDate;
    this.updatedBy = updatedBy;
    this.type = type;
    this.inactive = inactive;
    this.description = description;
  }
}
