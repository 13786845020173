import React, { useState } from "react";
import { notesFilters } from "../../../helpers/constants";
import IntlMessages from "../../../util/IntlMessages";
import { cx } from "@emotion/css";
import { NavPickerPopoverStyled } from "../../DatePicker/styles";
import { getUserAvatar } from "../../../helpers/users";
import { TAB_LABEL } from "../../../helpers/activitiesStream";
import TooltipTD from "../../Tooltip/TooltipTD";
import { FilterNotificationIcon } from "../../../helpers/iconSvgPath";
import { Badge } from "@mui/material";

function ActivityStreamSmallFilter({
  activeTab,
  noteThread,
  onChangeFilter,
  currentUser,
  hasThreadPermission,
  backdropBadge,
  numberOfMentions,
}) {
  const [anchorEl, setAnchorEl] = useState(false);

  const onClose = () => setAnchorEl(false);

  return (
    <>
      {anchorEl && (
        <NavPickerPopoverStyled
          open={!!anchorEl}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          classes={{ paper: "paper" }}
          className="navPickerPopoverContainer"
          onClose={onClose}
        >
          {!noteThread && (
            <div
              key={notesFilters.ALL + "navTitle"}
              className={cx("listContainer", {
                selectedStyle: activeTab === notesFilters.ALL,
              })}
              onClick={() => {
                onChangeFilter(notesFilters.ALL);
                onClose();
              }}
            >
              <label className="filterLabel">
                <IntlMessages id="purchaseorderdashboard.allNotes" />
              </label>
            </div>
          )}
          {!noteThread && (
            <div
              key={notesFilters.ACTIVITY + "navTitle"}
              className={cx("listContainer", {
                selectedStyle: activeTab === notesFilters.ACTIVITY,
              })}
              onClick={() => {
                onChangeFilter(notesFilters.ACTIVITY);
                onClose();
              }}
            >
              <label className="filterLabel">
                <IntlMessages id="purchaseorderdashboard.activity" />
              </label>
            </div>
          )}
          <div
            key={notesFilters.NOTES + "navTitle"}
            className={cx("listContainer", {
              selectedStyle: activeTab === notesFilters.NOTES,
            })}
            onClick={() => {
              onChangeFilter(notesFilters.NOTES);
              onClose();
            }}
          >
            <label className="filterLabel">
              <IntlMessages id="purchaseorderdashboard.notes" />
            </label>
          </div>
          {!noteThread && hasThreadPermission && (
            <div
              key={notesFilters.THREADS + "navTitle"}
              className={cx("listContainer", {
                selectedStyle: activeTab === notesFilters.THREADS,
              })}
              onClick={() => {
                onChangeFilter(notesFilters.THREADS);
                onClose();
              }}
            >
              <label className="filterLabel">
                <IntlMessages id="purchaseorderdashboard.threads" />
              </label>
            </div>
          )}
          <div
            key={notesFilters.MENTIONS + "navTitle"}
            className={cx("listContainer", {
              selectedStyle: activeTab === notesFilters.MENTIONS,
            })}
            onClick={() => {
              onChangeFilter(notesFilters.MENTIONS);
              onClose();
            }}
          >
            <Badge
              badgeContent={numberOfMentions}
              max={9}
              classes={{
                badge: cx("notification-badge", "badge-notification-number"),
              }}
              overlap="circular"
              anchorOrigin={{ horizontal: "right", vertical: "top" }}
            >
              <label className="filterLabel">
                {getUserAvatar({
                  user: currentUser,
                  styles: {
                    fontSize: 10,
                    cursor: "pointer",
                    position: "relative",
                    borderRadious: "50%",
                    outline: "1px solid #000",
                    padding: 0,
                    marginBottom: 0,
                    width: 15,
                    height: 15,
                  },
                })}
                ONLY
              </label>
            </Badge>
          </div>
        </NavPickerPopoverStyled>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: 12,
          fontWeight: 600,
          gap: 5,
        }}
      >
        <span className="filterLabel">Filter by:</span>
        <Badge
          badgeContent={numberOfMentions}
          max={9}
          classes={{
            badge: cx("notification-badge", "badge-notification-number"),
          }}
          overlap="circular"
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <TooltipTD
            label={<IntlMessages id={TAB_LABEL[activeTab]} />}
            className="hambugerIconButton"
            showToolTip={TAB_LABEL[activeTab]}
            tooltipClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
            style={{
              zIndex: backdropBadge ? 1202 : "",
              background: backdropBadge ? "white" : "",
            }}
          >
            <FilterNotificationIcon />
          </TooltipTD>
        </Badge>
      </div>
    </>
  );
}

export default ActivityStreamSmallFilter;
