import { getRandomId } from "../../helpers/helpers";
import { now } from "moment";
import { SHIPMENT_STATUS } from "../../helpers/constants";

export default class Shipment {
  constructor({
    id = getRandomId(),
    creationDate = now(),
    date = now(),
    shipDate = null,
    shipDates = [],
    deliveryDate = null,
    deliveryDates = [],
    CBM = "",
    weight = "",
    uniqueItems = 0,
    booking = "",
    billOfLading = "",
    container = "",
    loadingPort = "",
    unloadingPort = "",
    shippingTerms = "",
    invoice = 0,
    customerPO = 0,
    amount = 0,
    potentialLateFee = "",
    companyId = "",
    finalDestination = "",
    freightForwarder = "",
    customerId = "",
    number = 1000,
    purchaseOrderIds = [],
    salesOrderIds = [],
    status = SHIPMENT_STATUS.IN_PROGRESS,
    diamondStatus = "",
    completedTasks = 0,
    totalTasks = 0,
    tags = [],
    items = [],
    completedDate = "",
  }) {
    this.id = id;
    this.date = date;
    this.creationDate = creationDate;
    this.shipDate = shipDate;
    this.shipDates = shipDates;
    this.deliveryDate = deliveryDate;
    this.deliveryDates = deliveryDates;
    this.CBM = CBM;
    this.weight = weight;
    this.uniqueItems = uniqueItems;
    this.booking = booking;
    this.billOfLading = billOfLading;
    this.container = container;
    this.loadingPort = loadingPort;
    this.unloadingPort = unloadingPort;
    this.shippingTerms = shippingTerms;
    this.invoice = invoice;
    this.customerPO = customerPO;
    this.customerId = customerId;
    this.amount = amount;
    this.potentialLateFee = potentialLateFee;
    this.companyId = companyId;
    this.finalDestination = finalDestination;
    this.freightForwarder = freightForwarder;
    this.number = number;
    this.purchaseOrderIds = purchaseOrderIds;
    this.salesOrderIds = salesOrderIds;
    this.status = status;
    this.diamondStatus = diamondStatus;
    this.completedTasks = completedTasks;
    this.totalTasks = totalTasks;
    this.tags = tags;
    this.items = items;
    this.completedDate = completedDate;
  }
}
