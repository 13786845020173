import React, { useRef, useState, useEffect, useContext } from "react";
import {
  sortObjectsBy,
  isTradeDashEmployee,
  getDateByTimezone,
} from "../../../helpers/helpers";
import DatePicker from "../../DatePicker/DatePicker";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Cancel";
import UserPicker from "../../DatePicker/UserPicker";
import { useIsAllowedFunction } from "../../../hooks/permissions";
import { ArrowDownIcon, CalendarIcon } from "../../../helpers/iconSvgPath";
import { useUser } from "../../../hooks/user";
import { getDisplayName, getUserAvatar } from "../../../helpers/users";
import TooltipTD from "../../Tooltip/TooltipTD";
import { TASK_FIELDS, triggerTaskTypes } from "../../../helpers/tasks";
import {
  GENERAL_PERMISSION_VALUE,
  taskStatus,
  TASK_MOVED_STATUS,
} from "../../../helpers/constants";
import { TaskItemContainerStyled } from "../styles";
import { useShipments } from "../../../hooks/shipments";
import { cx } from "@emotion/css";
import { CircularProgress } from "@mui/material";
import { shipmentBadgeClass } from "../../../container/StyledComponent/BadgeStyled";
import {
  DueLateDiamond,
  moveStartFinishDate,
} from "../../../container/TaskListScreen/TaskScreenHelper";
import CustomCheckbox from "../../Inputs/CustomCheckbox";
import { getShipmentBadge } from "../../../helpers/shipments";
import { ENTITY_TASK_ACTION, EntityTaskContext } from "./TaskContext";
import SimpleEditableLabel from "../../TextFields/SimpleEditableLabel";

function TaskData({
  item,
  classesTask,
  isVoid,
  changeField,
  companyUsers,
  changeStyleTo,
  completeTask,
  onLastFinishDate,
  openPicker,
  onOpenPicker,
  openUsers,
  onOpenUsers,
  onClickOffset,
  editingAdHocTask,
  handleChange,
  onLastElementId,
  lastElementId,
  permissionToVerify,
  onCleanPendingTask,
  backdropId = "",
  isSmallPanel,
  firstItem,
  user,
  panelWidth,
}) {
  const { dispatchTaskcontext } = useContext(EntityTaskContext);
  const dateRef = useRef(null);
  const userRef = useRef(null);
  const isAllowed = useIsAllowedFunction();
  const currentUser = useUser();
  const isReadOnly = isTradeDashEmployee(currentUser);
  const [pending, setPending] = useState(false);

  const activeCompanyUsers = companyUsers.filter((user) => user.active);
  const shipments = useShipments();

  function getTaskStatusBadgeInfo({ task, isVoid }) {
    const otherClass = classesTask.includes("completeSize") ? "hiddenText" : "";
    if (task.moved === TASK_MOVED_STATUS.WAITING) {
      return (
        <CircularProgress
          style={{ position: "relative", width: 20, height: 20, marginLeft: 5 }}
        />
      );
    } else if (isVoid) {
      return <div className={cx("taskStatusContainer", otherClass)} />;
    } else if (task.status === taskStatus.LATE && !task.complete) {
      return <DueLateDiamond item={item} width={"100%"} />;
    } else if (task.status === taskStatus.NEAR_DUE && !task.complete) {
      return <DueLateDiamond item={item} width={"100%"} />;
    } else if (task.dayOffset && task.dayOffset !== 0 && item.complete) {
      return (
        <div
          className={cx("taskStatusContainer", otherClass)}
          style={{ cursor: "pointer" }}
        >
          <span
            onClick={(ev) => {
              ev.stopPropagation();
              if (isVoid) {
                return;
              }
              if (!isAllowed(GENERAL_PERMISSION_VALUE.REASSIGN_TASK)) {
                handleOpenModalAttention();
              } else if (!item.moved) {
                onClickOffset({ task: item, changeStyleTo });
              }
            }}
            className={cx("dayoffset", {
              early: !item.moved && item.dayOffset < 0,
              late: !item.moved && item.dayOffset > 0,
              disabled: item.moved,
            })}
          >
            <div
              style={{ marginTop: 1, marginRight: item.dayOffset > 9 ? 1 : 0 }}
            >
              {item.dayOffset
                ? item.dayOffset > 0
                  ? `+${Math.abs(item.dayOffset)}`
                  : `-${Math.abs(item.dayOffset)}`
                : ""}
            </div>
          </span>
        </div>
      );
    } else {
      return <div className={cx("taskStatusContainer", otherClass)} />;
    }
  }

  function handleOpenModalAttention() {
    dispatchTaskcontext({
      type: ENTITY_TASK_ACTION.COMMON,
      payload: {
        modalData: {
          isOpen: true,
          description: "You do not have permission to change task due dates",
        },
      },
    });
  }

  useEffect(() => {
    if (item.complete) {
      setPending(false);
    }
  }, [item.complete]);

  const handleUserPicker = () => {
    if (
      isVoid ||
      (item.enableToEdit && !editingAdHocTask) ||
      item.status === taskStatus.COMPLETE
    ) {
      return;
    }
    const canReassignTask = isAllowed(GENERAL_PERMISSION_VALUE.REASSIGN_TASK);
    if (canReassignTask || editingAdHocTask) {
      onOpenUsers(true);
    } else {
      dispatchTaskcontext({
        type: ENTITY_TASK_ACTION.COMMON,
        payload: {
          modalData: {
            isOpen: true,
            description: "You do not have permission to reassing a task",
            confirmationText: "Ok",
          },
        },
      });
    }
  };

  const handleDatePicker = () => {
    if (
      isVoid ||
      (item.enableToEdit && !editingAdHocTask) ||
      item.status === taskStatus.COMPLETE
    ) {
      return;
    }

    const canReassignTask = isAllowed(
      GENERAL_PERMISSION_VALUE.MODIFY_DUE_DATES
    );
    if (canReassignTask || editingAdHocTask) {
      onLastFinishDate(item.finishDate);
      onOpenPicker(true);
      onLastElementId("users-container-task-item" + item.id);
    } else {
      handleOpenModalAttention();
    }
  };

  return (
    <TaskItemContainerStyled
      className={cx(
        "taskListContainer",
        `task${item.type}`,
        { firstItem },
        classesTask
      )}
      id={`${item.id}task-item`}
      style={{
        zIndex: backdropId.includes(item.id) ? 1202 : "",
      }}
    >
      {item.status !== taskStatus.COMPLETE && (
        <div
          className={cx("nonCompleted", {
            onComplete: pending,
            slowTransition: pending,
            fastTransition: !pending,
          })}
        />
      )}
      {item.status === taskStatus.COMPLETE && (
        <div className={cx("completeBackground")} />
      )}
      {openUsers && (
        <UserPicker
          el={userRef.current}
          onChange={(userId, ev, blockPermission) => {
            if (blockPermission) {
              ev.preventDefault();
              ev.stopPropagation();
              return;
            } else if (editingAdHocTask) {
              handleChange("assignedTo", userId);
            } else {
              changeField({
                taskToUpdate: { ...item, assignedTo: userId },
                field: "assignedTo",
                triggerType: triggerTaskTypes.ASSIGNED_TO,
              });
            }
          }}
          open={openUsers}
          onClose={() => onOpenUsers(false)}
          value={item.assignedTo}
          users={activeCompanyUsers.sort(sortObjectsBy("displayName", false))}
          onKeyDown={(ev) => {
            if (ev.key === "Tab") {
              onLastElementId("");
              onOpenUsers(false);
            }
          }}
          permissionToVerify={permissionToVerify}
          isTherePermission={true}
          isCustomer={!item.factoryId}
        />
      )}

      {getTaskStatusBadgeInfo({ task: item, isVoid })}
      <CustomCheckbox
        checked={item.complete || false}
        onClick={(ev) => ev.stopPropagation()}
        onChange={(ev) => {
          if (isReadOnly) {
            return;
          }
          if (!pending) {
            setPending(true);
            changeStyleTo("completing");
            completeTask({
              task: !item.factoryId ? { ...item } : item,
              changeStyleTo,
            });
            ev.stopPropagation();
          }
        }}
        disabled={item.complete || isVoid || item.enableToEdit || pending}
        size="small"
        color={"#6B7A89"}
        styles={{
          padding: 0,
        }}
        iconSize={14}
      />
      <SimpleEditableLabel
        disable={!editingAdHocTask}
        alwaysInput={editingAdHocTask}
        text={item.description}
        className="taskLabelContainer"
        onPressTab={() => {
          const el = document.getElementById(lastElementId);
          el.click();
        }}
        onClick={() => onLastElementId("date-container-task-item" + item.id)}
        onSave={(ev) =>
          handleChange(
            "description",
            ev.target.value,
            triggerTaskTypes.AVOID_ACTIVITY_ENTRY
          )
        }
        textStyle={{
          opacity: 1,
        }}
        labelDescription={
          <span
            className={cx("labelContainer", {
              voidedElement: isVoid || item.status === taskStatus.COMPLETE,
            })}
          >
            {item.shipmentId &&
              getShipmentBadge({
                shipment: shipments.find(
                  (shipment) => shipment.id === item.shipmentId
                ),
                classes: shipmentBadgeClass.secondary,
                id: "mediumInverted",
              })}
            <span className="span-task-description">{item.description}</span>
          </span>
        }
      />

      <div
        id={"date-container-task-item" + item.id}
        onClick={handleDatePicker}
        ref={dateRef}
        className={cx("dateTaskBox", {
          voidedElement: isVoid || item.status === taskStatus.COMPLETE,
        })}
      >
        {item.finishDate
          ? getDateByTimezone({
              date: item.finishDate,
            })
          : moment(new Date()).format("M/D/YY")}
        <CalendarIcon style={{ marignBottom: 2 }} svgClass="calendarIcon" />
      </div>
      {openPicker && (
        <DatePicker
          el={dateRef.current}
          onChange={(newDate) => {
            if (editingAdHocTask) {
              onLastElementId("users-container-task-item" + item.id);
              if (
                moment(newDate, "MM/DD/YY").startOf("day") >=
                moment().startOf("day")
              ) {
                handleChange(
                  TASK_FIELDS.FINISH_DATE,
                  newDate,
                  triggerTaskTypes.FINISH_DATE
                );
              }
            } else {
              const { startDate, finishDate, diffDays } = moveStartFinishDate({
                newDate,
                task: item,
              });
              changeField({
                taskToUpdate: {
                  ...item,
                  finishDate: finishDate,
                  startDate: startDate,
                  currentDiffDays: diffDays,
                },
                field: TASK_FIELDS.FINISH_DATE,
                triggerType: triggerTaskTypes.FINISH_DATE,
              });
            }
          }}
          withFormat="MM/DD/YY"
          open={openPicker}
          onClose={() => {
            onOpenPicker(false);
          }}
          value={moment(
            getDateByTimezone({
              date: item.finishDate,
            })
          )}
          cancelIcon={true}
          onKeyDown={(ev) => {
            if (ev.key === "Tab") {
              onLastElementId("users-container-task-item" + item.id);
              onOpenPicker(false);
              const el = document.getElementById(lastElementId);
              el.click();
            }
          }}
        />
      )}
      <TooltipTD
        label={getDisplayName({ user })}
        tooltipClick={handleUserPicker}
        className={cx("userTaskBox", {
          voidedElement: isVoid || item.status === taskStatus.COMPLETE,
          onlyAvatar: isSmallPanel,
        })}
        style={{
          width: panelWidth === "50%" ? 180 : "",
        }}
        id={"users-container-task-item" + item.id}
      >
        {item.assignedTo &&
          getUserAvatar({
            user,
            styles: {
              width: 20,
              height: 20,
              fontSize: 13,
              outline: "2px solid #000",
            },
            showEmptyAvatar: true,
          })}
        {!isSmallPanel && (
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {item.assignedTo && getDisplayName({ user })}
          </span>
        )}
        {!pending && (
          <>
            <span ref={userRef} />
            <ArrowDownIcon
              svgClass={"arrowButton"}
              onClick={handleUserPicker}
            />
          </>
        )}
      </TooltipTD>
      {pending && (
        <ClearIcon
          fontSize="small"
          className="cancelIcon"
          onClick={() => {
            setPending(false);
            onCleanPendingTask(item);
            changeStyleTo("");
          }}
        />
      )}
    </TaskItemContainerStyled>
  );
}

export default TaskData;
