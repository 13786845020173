import { getDocs, query, collection, orderBy, limit } from "firebase/firestore";
import { firestore } from "../../firebase";
import { dbTables } from "../../api/types/dbTables";
import { TASK_TYPE } from "../../helpers/constants";
import AppConfig from "../../constants/AppConfig";
import { sortObjectsBy } from "../../helpers/helpers";

export const fetchSalesOrderTemplate = async ({ companyRef }) => {
  try {
    const templateQuery = query(
      collection(firestore, `${companyRef}/${dbTables.SALES_ORDER_TEMPLATE}`),
      orderBy("version", "desc"),
      limit(1)
    );
    const snapshot = await getDocs(templateQuery);

    if (!snapshot.empty) {
      return snapshot.docs.map((doc) => ({
        ...doc.data(),
        ref: doc.ref,
      }))[0];
    } else {
      console.log("No sales order templates found");
      return null;
    }
  } catch (err) {
    console.error("ERROR RETRIEVING SALES ORDER TEMPLATE", err);
    throw err;
  }
};

export const fetchSalesOrderTasks = async ({
  companyRef,
  salesOrderTemplateId,
  factory = {},
}) => {
  try {
    let factoryCpy = factory;
    const factoryIndexes = factoryCpy.vendorTemplateIndexes || {};
    const snapshot = await getDocs(
      collection(
        firestore,
        `${companyRef}/${dbTables.SALES_ORDER_TEMPLATE}/${salesOrderTemplateId}/${dbTables.SALES_ORDER_TASKS_TEMPLATE}`
      )
    );

    const salesOrderTasks = snapshot.docs
      .map((doc) => {
        const templateTask = doc.data();
        const templateIndex = factoryCpy.isOutdated
          ? templateTask.listIndex
          : factoryIndexes[templateTask.id] || templateTask.listIndex;
        return {
          ...templateTask,
          listIndex: templateIndex,
          type: TASK_TYPE.SALES_ORDER,
          ref: doc.ref,
          color: AppConfig.themeColors.salesOrderBackgroundGanttChartColor,
        };
      })
      .sort(sortObjectsBy("number"));

    return salesOrderTasks;
  } catch (err) {
    console.error("ERROR RETRIEVING SALES ORDER TASKS TEMPLATE", err);
    throw err;
  }
};

export const fetchVendorTasks = async ({ companyRef, factory }) => {
  try {
    const querySnapshot = await getDocs(
      collection(
        firestore,
        `${companyRef}/${dbTables.FACTORIES}/${factory.id}/${dbTables.MINI_PROJECT_TEMPLATE}`
      )
    );

    const vendorTasks = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      type: doc.data().type || TASK_TYPE.PURCHASE_ORDER,
      color:
        doc.data().type === TASK_TYPE.SHIPMENT
          ? AppConfig.themeColors.shipmentBackgroundGanttChartColor
          : AppConfig.themeColors.purchaseOrderBorderColor,
      ref: doc.ref,
    }));

    return { vendorTasks };
  } catch (error) {
    console.error("ERROR RETRIEVING MINI PROJECT TASKS TEMPLATE", error);
    throw error;
  }
};
