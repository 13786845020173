import moment from "moment";
import { getRandomId } from "../../helpers/helpers";
import ExpenseItem from "./ExpenseItem";

export default class Expense {
  constructor({
    id = getRandomId(),
    creationDate = moment.now(),
    date = "",
    paidTo = "",
    items = [{ ...new ExpenseItem({}) }],
    parentDocumentId = "",
    type = "",
    status = "ACTIVE",
    companyId,
    createdBy = "",
    isExported = false,
    exportedDate = "",
    glAccountIds = [],
    pdfURL = "",
  }) {
    this.id = id;
    this.creationDate = creationDate;
    this.date = date;
    this.paidTo = paidTo;
    this.items = items;
    this.parentDocumentId = parentDocumentId;
    this.type = type;
    this.status = status;
    this.companyId = companyId;
    this.createdBy = createdBy;
    this.isExported = isExported;
    this.exportedDate = exportedDate;
    this.glAccountIds = glAccountIds;
    this.pdfURL = pdfURL;
  }
}
