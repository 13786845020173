import React, { useState } from "react";
import { CALENDAR_SETUP } from "../../helpers/ganttChart";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Button } from "@mui/material";
import TooltipTD from "../Tooltip/TooltipTD";
import { GANTTCHART_TABLE_SIZE } from "./GanttChartContext";
import {
  CalendarIcon,
  ChevronIcon,
  DailyTimeframeIcon,
  MonthlyTimeframeIcon,
  QuarterlyTimeframeIcon,
} from "../../helpers/iconSvgPath";
import { cx } from "@emotion/css";
import { colors } from "../../assets/jss/variables";
import { Crop169, Crop32, Crop54 } from "@mui/icons-material";

const MENU_TYPE = { CALENDAR: "calendar", EXPANSION: "expansion" };

const EXPANSION_OPTIONS = [
  {
    label: "Full table size",
    id: GANTTCHART_TABLE_SIZE.LARGE,
    icon: () => <Crop54 style={{ width: 20, height: 20 }} />,
  },
  {
    label: "40% of table size",
    id: GANTTCHART_TABLE_SIZE.MEDIUM,
    icon: () => <Crop32 style={{ width: 20, height: 20, rotate: "270deg" }} />,
  },
  {
    label: "10% of table size",
    id: GANTTCHART_TABLE_SIZE.SMALL,
    icon: () => <Crop169 style={{ width: 20, height: 20, rotate: "90deg" }} />,
  },
];

const CALENDAR_DISPLAYED_OPTIONS = [
  {
    label: "Daily",
    id: CALENDAR_SETUP.WEEKLY,
    icon: () => <DailyTimeframeIcon />,
  },
  {
    label: "Monthly",
    id: CALENDAR_SETUP.MONTHLY,
    icon: () => <MonthlyTimeframeIcon />,
  },
  {
    label: "Quarterly",
    id: CALENDAR_SETUP.QUARTERLY,
    icon: () => <QuarterlyTimeframeIcon />,
  },
];

function CalendarDisplayOptions({
  calendarSetup,
  onChangeCalendarSetup,
  tableSize,
  handleChangeTableSize,
  soTemplate,
  collapseListTask = false,
}) {
  const [menuData, setMenuData] = useState({
    anchorEl: null,
    type: MENU_TYPE.CALENDAR,
  });
  const handleClickListItem = (event, type = MENU_TYPE.CALENDAR) => {
    setMenuData({ anchorEl: event.currentTarget, type });
  };

  const handleClose = () => {
    setMenuData({ anchorEl: null, type: MENU_TYPE.CALENDAR });
  };

  return (
    <>
      <Button
        className={cx("buttonViewOptions", {
          openButton:
            !!menuData.anchorEl && MENU_TYPE.CALENDAR === menuData.type,
          smallButton: collapseListTask,
        })}
        variant="contained"
        color="primary"
        onClick={handleClickListItem}
      >
        <TooltipTD
          label={"Timeframe"}
          showToolTip={collapseListTask}
          className={"tooltipContainer"}
        >
          <CalendarIcon size={14} />
          <span className="labelOption">Timeframe</span>
          <ChevronIcon svgClass={"chrevronClass"} />
        </TooltipTD>
      </Button>
      {soTemplate && (
        <Button
          className={cx("buttonViewOptions", {
            openButton:
              !!menuData.anchorEl && MENU_TYPE.EXPANSION === menuData.type,
            smallButton: collapseListTask,
          })}
          variant="contained"
          color="primary"
          onClick={(ev) => handleClickListItem(ev, MENU_TYPE.EXPANSION)}
        >
          <TooltipTD
            className={"tooltipContainer"}
            label={"Table Size"}
            showToolTip={collapseListTask}
          >
            <Crop54
              style={{
                width: 20,
                height: 20,
              }}
            />
            <span className="labelOption">Table Size</span>
            <ChevronIcon svgClass={"chrevronClass"} />
          </TooltipTD>
        </Button>
      )}
      <Menu
        id="lock-menu"
        anchorEl={menuData.anchorEl}
        open={!!menuData.anchorEl}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        {MENU_TYPE.CALENDAR === menuData.type &&
          CALENDAR_DISPLAYED_OPTIONS.map((option) => (
            <MenuItem
              key={option.id}
              selected={option.id === calendarSetup}
              onClick={() => {
                onChangeCalendarSetup(calendarSetup, option.id);
                handleClose();
              }}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                color: colors.primaryDark,
                gap: 15,
              }}
            >
              {option.icon()}
              {option.label}
            </MenuItem>
          ))}
        {MENU_TYPE.EXPANSION === menuData.type &&
          EXPANSION_OPTIONS.map((option) => (
            <MenuItem
              key={option.id}
              selected={option.id === tableSize}
              onClick={() => {
                handleChangeTableSize(option.id);
                handleClose();
              }}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                color: colors.primaryDark,
                gap: 15,
              }}
            >
              {option.icon()}
              {option.label}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
}

export default CalendarDisplayOptions;
