import React, { useEffect, useState } from "react";
import { PREFIX_ITEM_TABLE } from "../../../helpers/constants";
import {
  useCurrentPurchaseOrder,
  usePurchaseOrders,
} from "../../../hooks/salesOrders";
import { useSOItemList } from "../../../hooks/itemTable";
import SOTableSKU from "../SOTableSKU";
import AttentionModal from "../../Modal/AttentionModal";
import { getSOMismatchTable } from "../SKUTables/SKUHelper";
import Mark from "mark.js";

function SectionSoSKU({ salesOrder, user }) {
  const purchaseOrders = usePurchaseOrders();
  const [modalAttention, setModalAttention] = useState(false);
  const purchaseOrder = useCurrentPurchaseOrder();
  const soItemsList = useSOItemList({
    salesOrder: salesOrder,
    purchaseOrders,
    user: user,
    purchaseOrder,
  });

  const [cleanSearchBox, setCleanSearchBox] = useState(false);
  const [search, setSearch] = useState("");
  const markInstance = new Mark(document.getElementById("itemTablesalesOrder"));

  function performMark(keyword = "") {
    var options = {
      separateWordSearch: false,
      diacritics: false,
      debug: false,
      acrossElements: false,
      accuracy: "partially",
      exclude: [
        ".headerTable, .headerTable *",
        ".alignRight, .alignRight *",
        ".totalNumber, .totalNumber *",
      ],
    };
    markInstance.unmark({
      done: () => {
        markInstance.mark(keyword, options);
      },
    });
  }

  useEffect(() => {
    setTimeout(() => performMark(search), 200);
  }, [search]);

  const mismatchText = getSOMismatchTable({
    currentSalesOrder: salesOrder,
    soItemsList: soItemsList,
  });
  return (
    <div
      id={"item-table-SO"}
      className="item-table-SO"
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {!!modalAttention && (
        <AttentionModal
          title="Attention"
          description={modalAttention}
          isOpen={!!modalAttention}
          onClick={() => setModalAttention(false)}
        />
      )}

      <SOTableSKU
        prefix={PREFIX_ITEM_TABLE.SALES_ORDER}
        dataTable={soItemsList}
        currentSalesOrder={salesOrder}
        currentPurchaseOrder={purchaseOrder}
        purchaseOrders={purchaseOrders}
        cleanSearchBox={cleanSearchBox}
        onWriteSearch={setSearch}
        handleSearchBox={setCleanSearchBox}
        filterText={search}
        hasMismatchTag={mismatchText}
      />
    </div>
  );
}

export default SectionSoSKU;
