import React from "react";
import numeral from "numeral";

import { getTotal } from "../../../helpers/expenses";
import { EXPENSE_FIELDS } from "../../../helpers/salesOrder";

function ExpensesFooter({ filterList }) {
  const getTotalActual = () => {
    let total = 0;
    filterList.forEach((expense) => {
      total += getTotal({
        list: expense.items,
        field: EXPENSE_FIELDS.ACTUAL,
        format: false,
      });
    });
    return numeral(total).format("$0,0.00");
  };

  const getTotalForecast = () => {
    let total = 0;
    filterList.forEach((expense) => {
      total += getTotal({
        list: expense.items,
        field: EXPENSE_FIELDS.FORECAST,
        format: false,
      });
    });
    return numeral(total).format("$0,0.00");
  };

  return (
    <div className="expense-itemRow" style={{ gap: 0 }}>
      <div style={{ gridColumn: "span 4" }} />
      <span
        id="totalFooterText"
        className="footer-content-grid"
        style={{
          gridColumn: "span 2",
          gap: 15,
        }}
      >
        Totals:
        <span
          style={{
            paddingRight: 31,
          }}
          className="footerTotalText"
        >
          {getTotalForecast()}
        </span>
      </span>
      <span id="totalFooterField" className="footer-content-grid">
        <span
          className="footerTotalText"
          style={{
            paddingRight: 14,
          }}
        >
          {getTotalActual()}
        </span>
      </span>

      {/* {expensesHeader.map((header) => {
        return (
          <div key={header.id} style={header.footerStyles}>
            {getFooterTotals({
              expenses: filterList,
              header: header,
            })}
          </div>
        );
      })} */}
    </div>
  );
}

export default ExpensesFooter;
