import React, { useContext } from "react";
import { GANTT_CHART_SETTINGS, getTaskWidth } from "../../helpers/ganttChart";
import { convertHex, isThereGhostTask } from "../../helpers/timelineCalendar";
import { GanttChartContext } from "./GanttChartContext";

function GhostedTask({ task = {}, allTasks = [], flagViewOptions = [] }) {
  const { ganttChartState } = useContext(GanttChartContext);
  const { calendarSetup, startDate: calendarStartDate } = ganttChartState;
  const WIDTH_BY_SETTING = GANTT_CHART_SETTINGS[calendarSetup];
  const ghostTask = allTasks.find((aTask) => aTask.id === task.id);
  const isGhostedTask = isThereGhostTask({
    task,
    ghostTask,
    flagViewOptions,
  });
  const { width, left } = getTaskWidth({
    task: ghostTask,
    calendarStartDate: calendarStartDate,
    WIDTH_BY_SETTING,
  });

  return (
    isGhostedTask && (
      <div
        className="ghost-task"
        style={{
          height: GANTT_CHART_SETTINGS.ROW_HEIGHT - 4,
          left: left,
          width: width,
          position: "absolute",
          top: -5,
          opacity: 0.15,
          borderRadius: 5,
          border: `3px solid ${task.color}`,
          borderStyle: "dashed",
          background: convertHex(task.color, 0.6),
          zIndex: 1,
        }}
      />
    )
  );
}

export default GhostedTask;
