import React, { useState, useEffect, useCallback } from "react";
import { SearchLabelStyled } from "../Inputs/styles";
import { useStorage } from "../../hooks";
import CancelIcon from "@mui/icons-material/Cancel";
import { debounce } from "../../helpers/helpers";
import { SEARCH_BOX_TYPES } from "../../helpers/orderDashboard";
import { MagnifyingGlassIcon } from "../../helpers/iconSvgPath";
import { cx } from "@emotion/css";

function SearchLabel({
  placeholderOnExpand,
  cookie = "",
  returnQuery = () => {},
  cleanSearchBox = false,
  onDisableCleanSearchBox,
  styles = {},
  storageField = "activitysearch",
  inputStyle = {},
  searchLabelId = "search-label-customized",
  type = SEARCH_BOX_TYPES.DOCUMENTS,
  className = "",
}) {
  const [storage, setStorage] = useStorage(storageField);
  const [focus, setFocus] = useState(false);
  const [query, setQuery] = useState(
    storage[cookie] ? storage[cookie].search : ""
  );

  const onDebounce = useCallback(
    debounce((value) => {
      returnQuery(value);
    }, 1250),
    []
  );

  useEffect(() => {
    if (cleanSearchBox) {
      const persitence = storage[cookie] || {};
      setQuery("");
      if (cookie) {
        setStorage(cookie, { ...persitence, search: "" }, { maxAge: 84600 });
      }

      onDisableCleanSearchBox();
      onDebounce("");
    }
  }, [cleanSearchBox]);

  const SEARCH_BOX_ACTIVE_CLASSES = {
    [SEARCH_BOX_TYPES.SKU_TABLE]: "searchBoxActiveSKUTable",
    [SEARCH_BOX_TYPES.NOTES]: "searchBoxActiveNotes",
    [SEARCH_BOX_TYPES.DOCUMENTS]: "searchBoxActiveDocuments",
    [SEARCH_BOX_TYPES.NOTE_DOCUMENTS]: "searchBoxActiveNoteDocuments",
  };

  const SEARCH_BOX_CLASSES = {
    [SEARCH_BOX_TYPES.SKU_TABLE]: "searchBoxSKUTable",
    [SEARCH_BOX_TYPES.NOTES]: "searchBoxNotes",
    [SEARCH_BOX_TYPES.DOCUMENTS]: "searchBoxDocuments",
    [SEARCH_BOX_TYPES.NOTE_DOCUMENTS]: "searchBoxNoteDocuments",
  };

  return (
    <SearchLabelStyled
      className={cx("searchContainerRoot", className, {
        [SEARCH_BOX_ACTIVE_CLASSES[type]]: !!query,
        [SEARCH_BOX_CLASSES[type]]: !query,
        searchContainerRootActive: !!query,
        focus: focus || !!query,
      })}
      style={{ ...styles }}
      onClick={() => {
        if (type === SEARCH_BOX_TYPES.SKU_TABLE) {
          document.getElementById(searchLabelId).focus();
        }
      }}
    >
      <input
        id={searchLabelId}
        value={query}
        onChange={(ev) => {
          const persitence = storage[cookie] || {};
          setQuery(ev.target.value);
          if (cookie) {
            setStorage(
              cookie,
              { ...persitence, search: ev.target.value },
              { maxAge: 84600 }
            );
          }
          onDebounce(ev.target.value);
        }}
        autoFocus={false}
        placeholder={placeholderOnExpand || "search"}
        className={"input"}
        style={inputStyle}
        onFocus={() => {
          setFocus(true);
        }}
        onBlur={() => {
          setFocus(false);
        }}
      />

      {query ? (
        <CancelIcon
          className={"buttonX"}
          onClick={() => {
            const persitence = storage[cookie] || {};
            setQuery("");
            if (cookie) {
              setStorage(
                cookie,
                { ...persitence, search: "" },
                { maxAge: 84600 }
              );
            }
            onDebounce("");
            document.getElementById(searchLabelId).focus();
          }}
        />
      ) : (
        <MagnifyingGlassIcon
          svgClass={"iconSearch"}
          size={15}
          fill={"transparent"}
          onClick={() => {
            document.getElementById(searchLabelId).focus();
          }}
        />
      )}
      <div className="divider" />
    </SearchLabelStyled>
  );
}

export default SearchLabel;
