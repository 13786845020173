import React, { useState } from "react";
import CustomModal from "../Modal/Modal";
import FormTextField from "../TextFields/FormTextField";
import CustomButton from "../Buttons/CustomButton";
import Role from "../../api/model/Role";
import IntlMessages from "../../util/IntlMessages";

function UserRolesModal({ open, onClose, onSuccess }) {
  const [role, setRole] = useState(new Role({}));

  return (
    <CustomModal
      header={
        <IntlMessages
          id={"components.userManagement.userRolesModal.createRoles"}
        />
      }
      isOpen={open}
      onClose={() => {
        setRole(new Role({}));
        onClose();
      }}
      isCentered
    >
      <form
        onSubmit={(event) => {
          event.preventDefault();
          event.stopPropagation();
          onSuccess(role);
          setRole(new Role({}));
        }}
      >
        <FormTextField
          width={220}
          required={true}
          autoFocus={true}
          value={role.name}
          onChange={(ev) => {
            const value = ev.target.value;
            if (value.length <= 30) {
              setRole({ ...role, name: ev.target.value });
            }
          }}
          label="generic.textForm.name"
          type="input"
        />
        <div
          style={{
            width: "50%",
            display: "inline-flex",
            justifyContent: "flex-start",
            marginTop: "10px",
          }}
        ></div>
        <div
          style={{
            width: "50%",
            display: "inline-flex",
            justifyContent: "flex-end",
            marginTop: "10px",
          }}
        >
          <CustomButton type="success">{"generic.text.create"}</CustomButton>
          <CustomButton
            type={"cancel"}
            onClick={(event) => {
              event.preventDefault();
              setRole(new Role({}));
              onClose();
              event.stopPropagation();
            }}
          >
            {"generic.text.cancel"}
          </CustomButton>
        </div>
      </form>
    </CustomModal>
  );
}

export default UserRolesModal;
