import React from "react";

function RightColumn({ title = ".", children, button = "" }) {
  return (
    <section className={"rightColumn"}>
      <header>
        {title} {button}
      </header>
      {children}
    </section>
  );
}

export default RightColumn;
