import React from "react";
import {
  BlockedIcon,
  CheckCircleIcon,
  SandClockIcon,
} from "../../helpers/iconSvgPath";
import { OrderStatusButtons } from "./styles";
import { cx } from "@emotion/css";
import {
  ButtonFilterComponent,
  buttonFilterClasses,
} from "../../container/StyledComponent/ButtonFilterComponent";
import { useMediaQuery } from "@mui/material";

function StatusOrderButtons({ filters, changeButtonFilter }) {
  const activeFilters = [
    filters.factory?.length > 0,
    filters.customer?.length > 0,
    filters.tag?.length > 0,
    !!filters.existsDate,
  ].filter((activeFilter) => activeFilter);

  const maxFilterActive =
    useMediaQuery("(max-width:1600px)") && activeFilters.length >= 2;
  return (
    <OrderStatusButtons className="rootGroup" variant="outlined">
      <ButtonFilterComponent
        id="list-view-button-filter-inprogress"
        className={cx(
          buttonFilterClasses.buttonFilter,
          buttonFilterClasses.buttonsStatus,
          {
            active: filters.inProgress,
          }
        )}
        onClick={() => changeButtonFilter("inProgress")}
        style={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        {!maxFilterActive && (
          <SandClockIcon svgClassname="icons" height={20} width={20} />
        )}
        <span className="text">In Progress</span>
      </ButtonFilterComponent>
      <ButtonFilterComponent
        id="list-view-button-filter-completed"
        className={cx(
          buttonFilterClasses.buttonFilter,
          buttonFilterClasses.buttonsStatus,
          {
            active: filters.completed,
          }
        )}
        onClick={() => changeButtonFilter("completed")}
        style={{
          borderRadius: 0,
        }}
      >
        {!maxFilterActive && (
          <CheckCircleIcon svgClassname="icons" height={20} width={20} />
        )}
        <span className="text">Completed</span>
      </ButtonFilterComponent>
      <ButtonFilterComponent
        id="list-view-button-filter-voided"
        className={cx(
          buttonFilterClasses.buttonFilter,
          buttonFilterClasses.buttonsStatus,
          {
            active: filters.voided,
          }
        )}
        onClick={() => changeButtonFilter("voided")}
        style={{
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }}
      >
        {!maxFilterActive && (
          <BlockedIcon svgClassname="icons" height={20} width={20} />
        )}
        <span className="text">Void</span>
      </ButtonFilterComponent>
    </OrderStatusButtons>
  );
}

export default StatusOrderButtons;
