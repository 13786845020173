import appLogo from "../assets/img/site-logo.png";
import appLogoWB from "../assets/img/site-logo-wb.png";

const AppConfig = {
  appLogo: appLogo, // App Logo
  appLogoWB: appLogoWB, // App Logo Without boat
  brandName: "Tradedash", // Brand Name
  navCollapsed: false, // Sidebar collapse
  darkMode: false, // Dark Mode
  boxLayout: false, // Box Layout
  rtlLayout: false, // RTL Layout
  miniSidebar: false, // Mini Sidebar
  enableSidebarBackgroundImage: true, // Enable Sidebar Background Image
  sidebarImage: "", // Select sidebar image
  isDarkSidenav: true, // Set true to dark sidebar
  enableThemeOptions: true, // Enable Theme Options
  locale: {
    languageId: "english",
    locale: "en",
    name: "English",
    icon: "en",
  },
  enableUserTour: process.env.NODE_ENV === "production" ? true : false, // Enable / Disable User Tour
  copyRightText: "Tradedash © 2019 All Rights Reserved.", // Copy Right Text
  // light theme colors
  themeColors: {
    primary: "#5D92F4",
    secondary: "#677080",
    success: "#00D014",
    danger: "#FF3739",
    warning: "#FFB70F",
    info: "#00D0BD",
    dark: "#464D69",
    default: "#FAFAFA",
    greyLighten: "#A5A7B2",
    grey: "#677080",
    white: "#FFFFFF",
    purple: "#896BD6",
    yellow: "#D46B08",
    primaryLight: "#e5edf7",
    blue: "#5D92F4",
    shipmentColor: "#F6E6F9",
    salesOrderColor: "#e6f0f9",
    tableHeaderColor: "#C5DBF6",
    salesOrderBackgroundColor: "#EBF3D4",
    salesOrderBorderColor: "#B6C193",
    purchaseOrderBackgroundColor: "#E6F0F9",
    purchaseOrderBorderColor: "#90AED1",
    shipmentBordeColor: "#DAA2E3",
    dividerColor: "#87A3C2",
    salesOrderBackgroundGanttChartColor: "#A6C86E",
    shipmentBackgroundGanttChartColor: "#F4B9FF",
    blueTextColor: "#25486D",
    yellowOnHover: "#FEFCE7",
  },
  // dark theme colors
  darkThemeColors: {
    darkBgColor: "#424242",
  },
};

export default AppConfig;
