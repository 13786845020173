import React, { useState, useEffect } from "react";
import { Autocomplete, Button, useMediaQuery } from "@mui/material";

import { MultiSelectButton, PopoverMultiSelect } from "./styles";
import TooltipTD from "../Tooltip/TooltipTD";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AttentionModal from "../Modal/AttentionModal";
import { colors } from "../../assets/jss/variables";
import { cx } from "@emotion/css";
import { InputAutocomplete } from "./InputAutocomplete";
import { OptionAutocomplete } from "./OptionAutocomplete";
import { TYPE_OF_TAGS } from "../../helpers/constants";

function MultiSelectFilter({
  buttonId = "",
  label,
  icon,
  value = [],
  onChange = () => {},
  onOpenList = () => {},
  notificationStyles = false,
  tooltip,
  scope = "",
  currentList,
  setCurrentList = () => {},
  isIconComponent = false,
  placeholder = "",
  options,
  maxWidth = 260,
  showInfo = false,
  mismatchLabelDivider = TYPE_OF_TAGS.MISMATCH_TAG,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState(value);
  const [changedFilter, setChangedFilter] = useState(false);
  const [openAttentionModal, setOpenAttentionModal] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [descriptionAttentionModal, setDescriptionAttentionModal] = useState(
    <React.Fragment></React.Fragment>
  );

  useEffect(() => {
    if (anchorEl) {
      setSelectedOptions(value);
    }
  }, [anchorEl]);

  // function trackUse() {
  //   if (multiple && value) {
  //     // trackEvent("UsedDropdownFilters", {
  //     //   selectedNumber: value.length,
  //     //   filter: label
  //     // });
  //   }
  // }
  const matches = useMediaQuery("(max-width:1424px)");

  function handleOnChange() {
    const selectedOptionsId = selectedOptions.map((option) => option.value);
    onChange(selectedOptionsId);
  }

  const handleClick = (event, anchorEl) => {
    if (anchorEl) {
      setAnchorEl(null);
      if (changedFilter) {
        handleOnChange();
      }
    } else {
      onOpenList(true);
      setCurrentList(label);
      setAnchorEl(event.currentTarget);
    }
  };

  const poppoverClasses = {
    paper: notificationStyles ? "paperNotification" : "paper",
    option: "option",
    popperDisablePortal: "popperDisablePortal",
    listbox: "listbox",
  };

  const onChangeAutocomplete = (event, newValue) => {
    if (newValue.length > 10) {
      setOpenAttentionModal(true);
      setDescriptionAttentionModal(
        <React.Fragment>
          10 is the maximum number of {scope} that can be used in the filter
        </React.Fragment>
      );
    } else {
      setSelectedOptions(newValue);
      setChangedFilter(true);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "toggleInput") {
      return;
    }
    setInputValue("");
    onOpenList(false);
    setTimeout(() => {
      setAnchorEl(null);
    }, 150);

    handleOnChange();
    if (changedFilter) {
    }
  };

  const open = !!anchorEl && currentList === label;
  const id = open ? "customized-select-options" : undefined;

  return (
    <React.Fragment>
      {openAttentionModal && (
        <AttentionModal
          isOpen={openAttentionModal}
          title="Attention"
          description={descriptionAttentionModal}
          onClick={() => {
            setOpenAttentionModal(false);
            setDescriptionAttentionModal(<React.Fragment></React.Fragment>);
          }}
        />
      )}
      <MultiSelectButton>
        <Button
          id={buttonId}
          className={
            notificationStyles ? "notificationContainer" : "regularContainer"
          }
          style={{ backgroundColor: open ? "#e0e3e7" : "" }}
          onClick={(event) => handleClick(event, anchorEl)}
          color="inherit"
        >
          {isIconComponent && (
            <TooltipTD style={{ height: 19, display: "flex" }} label={tooltip}>
              {icon}
            </TooltipTD>
          )}
          {!isIconComponent && (
            <TooltipTD label={tooltip} style={{ height: 22, display: "flex" }}>
              <img
                alt=""
                height={19}
                width={19}
                className="location-icon"
                src={icon}
              />
            </TooltipTD>
          )}

          {!matches && !notificationStyles && (
            <label className="textFilter">{label}</label>
          )}
          <ExpandMoreIcon
            className={cx("arrowDropDownIcon", { arrowActive: open })}
            style={{ color: colors.primaryDark }}
          />
        </Button>
        {open && (
          <PopoverMultiSelect
            id={id}
            open={open}
            anchorEl={anchorEl}
            placement="bottom-start"
            className="popperSelect"
            style={{ maxWidth }}
          >
            <Autocomplete
              open
              openOnFocus
              multiple
              classes={poppoverClasses}
              onClose={handleClose}
              groupBy={(option) => option.type}
              value={selectedOptions}
              onInputChange={(ev, newInputValue, reason) => {
                if (reason === "reset") {
                  return;
                }
                setInputValue(ev.target.value || "");
              }}
              renderInput={(params) => (
                <InputAutocomplete
                  inputValue={inputValue}
                  placeholder={placeholder}
                  setInputValue={setInputValue}
                  params={params}
                />
              )}
              renderTags={() => null}
              onChange={onChangeAutocomplete}
              disableCloseOnSelect
              disablePortal
              noOptionsText="No options"
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              options={options.filter((option) =>
                option.label.toLowerCase().includes(inputValue.toLowerCase())
              )}
              getOptionLabel={(option) => option.label}
              renderGroup={(params) => (
                <li key={params.key + params.group}>
                  {params.key > 0 && (
                    <div
                      style={{
                        borderTop: "1px solid #D2DFEC",
                        width: "100%",
                        backgroundColor: "#D2DFEC",
                        marginBlock: params.group === mismatchLabelDivider ? 16 : 2,
                        position: "relative",
                      }}
                    >
                      {params.group === mismatchLabelDivider && (
                        <span
                          style={{
                            position: "absolute",
                            background: "white",
                            top: -8,
                            fontSize: 11,
                            right: 30,
                            paddingInline:8
                          }}
                        >
                          Automatic system labels
                        </span>
                      )}
                    </div>
                  )}
                  {params.children}
                </li>
              )}
              renderOption={(props, option, state) => (
                <OptionAutocomplete
                  inputProps={props}
                  option={option}
                  state={state}
                  key={props.key + props.id}
                  showInfo={showInfo}
                />
              )}
            />
          </PopoverMultiSelect>
        )}
      </MultiSelectButton>
    </React.Fragment>
  );
}

export default MultiSelectFilter;
