import { keyframes, styled } from "@mui/material";
import { colors } from "../../../assets/jss/variables";
import {
  NOTES_SCOPE,
  basesMentionStyle,
  shipmentMentionBase,
} from "../../../helpers/constants";
import { ORDER_TYPE } from "../../../api/types/dbTables";

const slideUpDown = keyframes`
  0% {
    transform: translateY(-90px);
  }
  100% {
    transform: translateY(0);
  }
`;

const showOpacity = keyframes`
  0% {
    opacity:0
  }
  100% {
    opacity: 1
  }
`;

export const ActivityStreamFiltersStyled = styled("div")(() => ({
  "&.activityStream-header-container": {
    height: 80,
    width: "100%",
    padding: "12px 18px 0px 16px",
    "& .activityTool": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      marginRight: 4,
      "& .replyToolContainer": {
        display: "flex",
        alignItems: "center",
        gap: 16,
        marginRight: 16,
        "& .replyContainer": {
          display: "flex",
          cursor: "pointer",
        },
        "& .divider": {
          transition: "height 0.8s",
          width: 2,
          minWidth: 2,
          height: 20,
          backgroundColor: colors.primaryDark,
        },
      },
    },
    "& .activity-stream-filters-container": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      height: 40,
      paddingRight: 8,
      "& .title": {
        fontSize: 23,
        fontWeight: "normal",
        color: colors.primaryDark,
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxHeight: 60,
        display: "flex",
        alignItems: "baseline",
        gap: 6,
      },

      "& .general-filters": {
        display: "flex",
        justifyContent: "space-between",
        fontSize: 11,
        position: "relative",
        "& .indicator": {
          position: "absolute",
          background: "rgb(204, 204, 204)",
          height: 2,
          width: "98%",
          top: 26,
          opacity: 0.6,
        },
      },
    },
  },
}));

export const CreateNotesContainerStyled = styled("div")(() => ({
  "&.activityStream-new-note-container": { padding: "0px 19px 6px 17px" },
}));

export const ActivityTimelineStyled = styled("div")(() => ({
  "&.activity-timeline": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: 65,
    position: "relative",
    "&:before": {
      content: '""',
      width: 1,
      height: "100%",
      background: "rgb(121, 138, 155)",
      position: "absolute",
      left: 32,
      zIndex: 1,
    },
    "& .time-info": {
      display: "flex",
      flexDirection: "column",
      background: "#FFF",
      borderRadius: 8,
      padding: "12px 4px",
      zIndex: 2,
      "& span": {
        fontSize: 12,
        fontWeight: 400,
        color: "rgb(121, 138, 155)",
      },
    },
  },
}));

export const GroupedActivitiesByDateContainerStyled = styled("div")(() => ({
  "&.group-activities-by-date-container": {
    padding: "0px 14px 4px 0px",
    height: "100%",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "scroll",
    "& .group-activities-content": {
      width: "100%",
      "& .group-activity-date": {
        fontSize: 13,
        fontWeight: 500,
        color: colors.primaryDark,
        background: "#FFF",
        position: "sticky",
        top: 0,
        zIndex: 3,
        width: 60,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "12px 4px",
        marginLeft: 2.5,
      },
      "& .recent-activity-line": {
        position: "relative",
        padding: "10px 0px 10px 48px",
      },
    },
    "& .notificationUnreadContainer": {
      background: "white",
      position: "sticky",
      top: 0,
      zIndex: 4,
      width: "100%",
      "& .notificationActivities": {
        height: 28,
        background: colors.primaryDark,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        position: "sticky",
        borderRadius: 4,
        // outline: `1px solid ${colors.primaryDark}`,
        "&:hover": {
          cursor: "pointer",
        },
        "& .textUnreadActivities": {
          color: "white",
          fontSize: 14,
          fontWeight: 700,
          padding: "0px 8px",
        },
        "& .cancelIcon": {
          background: colors.primaryDark,
          color: "#fff",
          width: 13,
          height: 13,
          borderRadius: "50%",
          margin: "0px 8px",
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    },
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent",
      width: 16,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "transparent",
      borderRadius: 7,
      minHeight: 48,
    },
    "&::-webkit-scrollbar-track": {
      border: " solid 1px transparent",
    },
    "&:hover": {
      "&::-webkit-scrollbar-thumb": {
        boxShadow: `inset 0 0 10px 10px ${colors.thumbScrollColor}`,
        borderBlock: "1px solid transparent",
        borderInline: "5px solid transparent",
      },
    },
  },
  "&.unread-activity": {
    height: "calc(100% - 40px)",
  },
  "& .load-activities-container": {
    background: colors.primaryDark,
    width: 331,
    fontSize: 16,
    fontWeight: 700,
    padding: 15,
    height: "auto",
    borderRadius: 4,
    marginTop: 39,
    color: "white",
    cursor: "pointer",
    textAlign: "center",
    marginLeft: 45,
  },
  "& .activity-stream-search-text": {
    marginLeft: 45,
    marginTop: 64,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 24,
    color: colors.primaryDark,
    fontWeight: 700,
    textAlign: "center",
  },
}));

export const UnreadActivitiesContainerStyled = styled("div")(() => ({
  "&.notificationUnreadContainer": {
    background: "white",
    position: "sticky",
    top: 0,
    zIndex: 4,
    width: "100%",
    padding: "4px 28px 12px 21px",
    "& .notificationActivities": {
      height: 28,
      background: colors.primaryDark,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      position: "sticky",
      borderRadius: 4,
      // outline: `1px solid ${colors.primaryDark}`,
      "&:hover": {
        cursor: "pointer",
      },
      "& .textUnreadActivities": {
        color: "white",
        fontSize: 14,
        fontWeight: 700,
        padding: "0px 8px",
      },
      "& .cancelIcon": {
        background: colors.primaryDark,
        color: "#fff",
        width: 13,
        height: 13,
        borderRadius: "50%",
        margin: "0px 8px",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  },
}));

export const ActivityStyled = styled("div")(() => ({
  "&.activity-container": {
    margin: "8px 0px",
    position: "relative",
    width: "calc(100% - 65px)",
    transform: "translateY(0)",
    transition: "all 0.8s ease",
    "& .activity-content": {
      display: "grid",
      gridTemplateColumns: "74px 1fr 30px",
      gridTemplateRows: "100%",
      width: "calc(100%)",
      overflow: "hidden",
      "&:hover": {
        "& .activityReadStatus": {
          opacity: 1,
          width: 14,
          height: 14,
        },
        "& .activityPointRead": {
          borderWidth: 2,
        },
        "& .expandIcon": { opacity: 1 },
      },
      "& .created-by-avatar": {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gridRow: 1,
        height: "100%",
      },
      "& .activity-body": {
        height: "100%",
        overflowWrap: "break-word",
        paddingTop: 10,
        lineHeight: 1.8,
        fontSize: 12,
        minHeight: 40,
        "& .subHeaderNote": {
          display: "block",
          flexDirection: "row",
          padding: "4px 0px",
          gap: 10,
          width: "calc(100% - 74px)",
          height: 28,
          position: "absolute",
          right: 0,
          fontWeight: 500,
          "& .icons": {
            display: "flex",
            justifyContent: "flex-end",
            fontWeight: 700,
            fontSize: 12,
            alignItems: "center",
          },
        },
        "& .SONMentionClass": {
          ...basesMentionStyle,
        },
        "& .documentsMentionClass": {
          ...basesMentionStyle,
          "&:hover": {
            background: " #948686",
          },
          "&:active": {
            background: "#615E5E",
            border: "1px solid #615E5E",
          },
        },
        "& .SHIPMENTMentionClass": {
          ...basesMentionStyle,
        },
        "& .PONMentionClass": {
          ...basesMentionStyle,
        },
        [`& .${NOTES_SCOPE.SHIPMENT}MentionClass`]: {
          ...basesMentionStyle,
        },
        [`& .${NOTES_SCOPE.SALES_ORDER}MentionClass`]: {
          ...basesMentionStyle,
        },
        [`& .${NOTES_SCOPE.PURCHASE_ORDER}MentionClass`]: {
          ...basesMentionStyle,
        },
        "& .mentionsShipmenClass": {
          ...shipmentMentionBase,
        },
        "& .mentionsShipmenClassUpdated": {
          ...shipmentMentionBase,
        },
        "& .reverseShipmentClass": {
          ...shipmentMentionBase,
          background: "#ffffff",
          border: `2px solid ${colors.shipmentBorderColor}`,
        },
        "& p": {
          marginBottom: 0,
          fontSize: 12,
          "& .styles-activity-stream-from-backend-red": {
            color: "red",
          },
          "& .styles-activity-stream-from-backend-green": {
            color: "green",
          },
          "& .styles-activity-stream-from-backend-bold": {
            fontWeight: 700,
          },
          "& .checkbox-input-done": {
            position: "relative",
            top: 2,
          },
        },
        "& strong": {
          marginBottom: 0,
          fontSize: 12,
        },
        "& .activityNote": {
          fontSize: 14,
          fontFamily: "monospace",
          "& p": {
            fontSize: 14,
            overflowWrap: "anywhere",
          },
          "& figure": {
            margin: 0,
            height: "auto",
          },
        },
        "& .uploaded-multifiles-container": {
          margin: 0,
          padding: "0px 0px 0px 14px",
          "& .root-deleted-documents": {
            margin: 0,
            fontWeight: 500,
            "& .first-child-deleted-documents": {
              marginLeft: 18,
              fontWeight: 500,
            },
            "& .child-deleted-documents": {
              marginLeft: 18,
              fontWeight: 500,
            },
          },
        },
      },
      "& ul": {
        paddingInlineStart: 30,
        display: "flow-root",
        "& li": {
          display: "list-item",
          listStyleType: "disc",
        },
      },
      "& ol": {
        paddingInlineStart: 30,
        display: "flow-root",
        "& li": {
          display: "list-item",
        },
        "& ol": {
          paddingInlineStart: 60,
        },
      },
    },
    "& .activity-header": {
      height: "fit-content",
      paddingLeft: 74,
      paddingRight: 14,
      display: "flex",
      borderTopRightRadius: 8,
      borderTopLeftRadius: 8,
      alignItems: "center",
      justifyContent: "space-between",
      "& .activity-scope-left": {
        fontSize: 13,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: colors.primaryDark,
        fontWeight: 700,
        position: "absolute",
        left: 8,
      },
      "& .title": {
        fontSize: 14,
        display: "flex",
        alignItems: "center",
        color: colors.primaryDark,
        fontWeight: 400,
        height: "100%",
        padding: "6px 0px",
      },
      "& .options": {
        "& .threadStyle": {
          background: "#FFFFFF",
          borderRadius: 4,
          paddingInline: 8,
          cursor: "pointer",
          fontSize: 13,
          fontWeight: 400,
          color: "#000000",
          height: 20,
          display: "flex",
          alignItems: "center",
          margin: "auto",
          overflow: "hidden",
          "& span": {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          },
        },
      },
      "& .scope": {
        gap: 15,
        height: "100%",
        display: "flex",
        alignItems: "center",
        fontSize: 13,
        color: colors.primaryDark,
        fontWeight: 700,
        width: "auto",
        justifyContent: "flex-end",
      },
    },

    "& .activity-read-container": {
      width: 30,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      gridRow: 1,
      gridColumn: 3,
      height: "100%",
      "& .activityReadStatus": {
        width: 8,
        height: 8,
        background: "#FFF",
        border: "1px solid #39f",
        borderRadius: "50%",
        transition: "all 0.5s",
        opacity: 0,
      },
      "& .activityPointRead": {
        background: "#39f",
        opacity: 1,
      },
    },

    "& .activity-footer": {
      height: 30,
      fontSize: 12,
      display: "flex",
      alignItems: "center",
      color: colors.darkGray,
    },
    "& .activity-info": {
      background: "white",
      display: "flex",
      height: 0,
      alignItems: "center",
      fontSize: 12,
      paddingInline: 12,
      overflow: "hidden",
      transition: "height 0.5s ease-in-out",
      "& .trashIcon": { marginLeft: "auto", cursor: "pointer" },
      "& .editOption": {
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "100%",
        position: "relative",
        opacity: 1,
        transition: "opacity 0.5s ease-in-out",
      },
      "& .vanish": {
        opacity: 0,
      },
      "& .edit-note": {
        padding: "0px 24px",
        fontWeight: 700,
        color: "#578ec7",
        cursor: "pointer",
      },
    },
    "& .activity-editable": {
      height: 50,
      borderTop: "2px solid",
    },

    "&:hover": {
      "& .readMore": {
        color: colors.primaryDark,
        borderBottom: `${colors.primaryDark} solid 1px`,
      },
      "& .iconVisibility": {
        opacity: 1,
      },
    },
    [`& .${ORDER_TYPE.SALES_ORDER}Border`]: {
      borderColor: colors.salesOrderBorderColor,
    },
    [`& .${ORDER_TYPE.PURCHASE_ORDER}Border`]: {
      borderColor: colors.purchaseOrderBorderColor,
    },
    [`& .${ORDER_TYPE.QUOTE}Border`]: {
      borderColor: colors.salesOrderBorderColor,
    },
    [`& .${ORDER_TYPE.PURCHASE_QUOTE}Border`]: {
      borderColor: colors.purchaseOrderBorderColor,
    },
    [`& .${ORDER_TYPE.SHIPMENT}Border`]: {
      borderColor: colors.shipmentBorderColor,
    },
  },
  "&.activity-newContainer": {
    animation: `${slideUpDown} 1s ease-in-out`,
  },
  "&.activity-opacity": {
    animation: `${showOpacity} 1s ease-in-out`,
  },

  "&.SALES_ORDER": {
    borderRadius: 8,
    outline: `2px solid ${colors.salesOrderActivityHeaderColor}`,
    background: colors.salesOrderActivityBackgroundColor,
    "& .activity-header": {
      backgroundColor: colors.salesOrderActivityHeaderColor,
    },
    "& .subHeaderNote": {
      borderBottom: `2px solid ${colors.salesOrderActivityHeaderColor}`,
    },
  },
  "&.QUOTE": {
    borderRadius: 8,
    outline: `2px solid ${colors.salesOrderActivityHeaderColor}`,
    background: colors.salesOrderActivityBackgroundColor,
    "& .activity-header": {
      backgroundColor: colors.salesOrderActivityHeaderColor,
    },
    "& .subHeaderNote": {
      borderBottom: `2px solid ${colors.salesOrderActivityHeaderColor}`,
    },
  },
  "&.PURCHASE_ORDER": {
    borderRadius: 8,
    outline: `2px solid ${colors.purchaseOrderActivityHeaderColor}`,
    background: colors.purchaseOrderActivityBackgroundColor,
    "& .activity-header": {
      backgroundColor: colors.purchaseOrderActivityHeaderColor,
    },
    "& .subHeaderNote": {
      borderBottom: `2px solid ${colors.purchaseOrderActivityHeaderColor}`,
    },
  },
  "&.PURCHASE_QUOTE": {
    borderRadius: 8,
    outline: `2px solid ${colors.purchaseOrderActivityHeaderColor}`,
    background: colors.purchaseOrderBackgroundColor,
    "& .activity-header": {
      backgroundColor: colors.purchaseOrderActivityHeaderColor,
    },
    "& .subHeaderNote": {
      borderBottom: `2px solid ${colors.purchaseOrderActivityHeaderColor}`,
    },
  },
  "&.SHIPMENT": {
    borderRadius: 8,
    outline: `2px solid ${colors.shipmentActivityHeaderColor}`,
    background: colors.shipmentActivityBackgroundColor,
    "& .activity-header": {
      backgroundColor: colors.shipmentActivityHeaderColor,
    },
    "& .subHeaderNote": {
      borderBottom: `2px solid ${colors.shipmentActivityHeaderColor}`,
    },
  },
  "& .expandIcon": {
    position: "absolute",
    zIndex: 10,
    bottom: -9,
    right: -9,
    borderRadius: 15,
    boxShadow: "-1px 1px 5px 1px rgba(0,0,0, 0.30)",
    background: "white",
    cursor: "pointer",
    opacity: 0,
    "&:hover": {
      "& .lineColor": {
        stroke: "white",
      },
      "& .circleColor": {
        fill: "#1581BF",
      },
    },
  },
  "& .readMore": {
    fontStyle: "italic",
    fontWeight: 400,
    color: "#9D9D9D",
    height: 16,
    width: 68,
    textAlign: "center",
    borderBottom: "#9D9D9D solid 0.5px",
    cursor: "pointer",
    marginRight: 16,
    padding: "0px 4px 0px 6px",
    marginLeft: "auto",
  },
}));
