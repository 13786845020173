import React from "react";
import { dbTables } from "../../api/types/dbTables";
import { useData } from "../../hooks";
import CompanyPermissions from "../CompanyPermissions/CompanyPermissions";
import { useParams } from "react-router-dom";

function CompanyPermissionsTab() {
  const { companyId } = useParams()
  const permissions = useData(
    [dbTables.COMPANIES, companyId, dbTables.COMPANY_PERMISSION],
    [companyId]
  );
  return <CompanyPermissions companyId={companyId} permissions={permissions} />;
}

export default CompanyPermissionsTab;
