import { ArrowDropDownCircle } from "@mui/icons-material";
import React from "react";
import { colorPickerArray } from "../../helpers/timelineCalendar";
import PickerIcon from "./PickerIcon";
import { ColorPickerPopoverContainerStyled, ColorPickerStyled } from "./styles";

function ColorPicker({ milestone, onChangeMilestone, onClose=()=>{} }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose()
  };

  return (
    <ColorPickerStyled className="colorPickerContainer">
      <div className={"arrowDropDownCircleWrapper"}>
        <ArrowDropDownCircle
          style={{
            color: milestone.color,
            cursor: "pointer",
          }}
          onClick={handleClick}
        />
      </div>
      {Boolean(anchorEl) && (
        <ColorPickerPopoverContainerStyled
          id="simple-popover"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          classes={{ paper: "colorPickerPaperContainer" }}
          className="colorPickerPopoverContainer"
        >
          <div className={"colorPickerTopSection"}>
            {colorPickerArray
              .filter((colorPicker) => colorPicker.position === "top")
              .map((colorPicker) => (
                <PickerIcon
                  key={colorPicker.color}
                  milestone={milestone}
                  colorPicker={colorPicker}
                  onChangeMilestone={(value) => {
                    setAnchorEl(null);
                    onChangeMilestone(value);
                  }}
                />
              ))}
          </div>
          <div className={"colorPickerBottomSection"}>
            {colorPickerArray
              .filter((colorPicker) => colorPicker.position === "bottom")
              .map((colorPicker) => (
                <PickerIcon
                  key={colorPicker.color}
                  milestone={milestone}
                  colorPicker={colorPicker}
                  onChangeMilestone={(value) => {
                    setAnchorEl(null);
                    onChangeMilestone(value);
                  }}
                />
              ))}
          </div>
        </ColorPickerPopoverContainerStyled>
      )}
    </ColorPickerStyled>
  );
}

export default ColorPicker;
