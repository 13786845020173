import React, { useEffect, useState } from "react";
import Factory from "../../api/model/factory";
import CustomModal from "../Modal/Modal";
import FormTextField from "../TextFields/FormTextField";
import CustomButton from "../Buttons/CustomButton";
import CustomSnackbar from "../../routes/components/snackbar/component/CustomSnackbar";
import { getRandomId } from "../../helpers/helpers";
import IntlMessages from "../../util/IntlMessages";

function FactoryModal({
  open,
  onClose,
  factory,
  onSubmit,
  factories,
  disabled = false,
}) {
  const [selectedFactory, setSelectedFactory] = useState(
    new Factory({
      id: getRandomId(),
    })
  );
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  useEffect(() => {
    if (!factory) {
      setSelectedFactory(
        new Factory({
          id: getRandomId(),
        })
      );
    } else {
      setSelectedFactory(factory);
    }
  }, [open]);

  function onChangeField(field, event) {
    setSelectedFactory({ ...selectedFactory, [field]: event.target.value });
  }

  function handleSave() {
    let isValidate = true;
    if (
      factories
        .filter((item) => item.id !== selectedFactory.id)
        .find(
          (factory) =>
            factory.name.toLowerCase().replace(/\s\s+/g, " ").trim() ===
            selectedFactory.name
              .toLocaleLowerCase()
              .replace(/\s\s+/g, " ")
              .trim()
        )
    ) {
      setSnackbarMessage(
        "components.companytabs.factorymodal.customsnackbar.message"
      );
      setOpenSnackbar(true);
      isValidate = false;
    }
    if (selectedFactory.name.length > 50) {
      setSnackbarMessage(
        "components.companytabs.factorymodal.customsnackbar.message.toomuchcharacters"
      );
      setOpenSnackbar(true);
      isValidate = false;
    }
    /* eslint-disable no-useless-escape */
    if (selectedFactory.name.match(/[\<\>!@#\$%^\*]+/i)) {
      setSnackbarMessage(
        "components.companytabs.factorymodal.customsnackbar.message.invalidcharacters"
      );
      setOpenSnackbar(true);
      isValidate = false;
    }
    if (!selectedFactory.name.match(/[a-zA-Z&]/)) {
      setSnackbarMessage(
        "components.companytabs.factorymodal.customsnackbar.message.isblank"
      );
      setOpenSnackbar(true);
      isValidate = false;
    }
    if (isValidate) {
      onSubmit({
        ...selectedFactory,
        name: selectedFactory.name.replace(/\s\s+/g, " ").trim(),
      });
    }
  }

  function handleKeyPress(keyPress) {
    if (keyPress.key === "Enter") {
      handleSave();
    }
  }

  return (
    <div onKeyPress={handleKeyPress}>
      <CustomSnackbar
        open={openSnackbar}
        message={snackbarMessage}
        variant="error"
        handleCloseSnackbar={() => setOpenSnackbar(false)}
        autoHideDuration={2500}
      />
      <CustomModal
        isOpen={open}
        onClose={onClose}
        header={
          factory ? (
            <IntlMessages id={"factorymodal.custommodal.editfactory"} />
          ) : (
            <IntlMessages id={"factorymodal.custommodal.createfactory"} />
          )
        }
      >
        <FormTextField
          label={"generic.textForm.name"}
          fullWidth
          margin="4px 4px 4px 4px"
          value={selectedFactory.name}
          onChange={(event) => {
            onChangeField("name", event);
          }}
          autoFocus={true}
          disabled={disabled}
        />
        <FormTextField
          label={"components.companytabs.factorymodal.namelabel.terms"}
          value={selectedFactory.terms}
          margin="4px 4px 4px 4px"
          fullWidth
          onChange={(event) => {
            onChangeField("terms", event);
          }}
          disabled={disabled}
          type="number"
        />
        <FormTextField
          label={"components.companytabs.factorymodal.namelabel.contact"}
          fullWidth
          margin="4px 0 4px 4px"
          value={selectedFactory.mainContact}
          onChange={(event) => {
            onChangeField("mainContact", event);
          }}
          disabled={disabled}
        />
        <FormTextField
          label={"components.companytabs.factorymodal.namelabel.notes"}
          margin="4px 0"
          fullWidth
          multiline
          maxRows={5}
          value={selectedFactory.notes}
          onChange={(event) => {
            onChangeField("notes", event);
          }}
          disabled={disabled}
        />

        <CustomButton type="cancel" onClick={onClose}>
          {"generic.text.cancel"}
        </CustomButton>
        <CustomButton
          disabled={selectedFactory.name.trim() === "" || disabled}
          type="success"
          onClick={handleSave}
        >
          {factory ? "generic.text.update" : "generic.text.create"}
        </CustomButton>
      </CustomModal>
    </div>
  );
}

export default FactoryModal;
