import React, { useState } from "react";
import { Switch } from "@mui/material";
import { getCompanyUserDisplayName } from "../../../helpers/helpers";
import { getUserAvatar } from "../../../helpers/users";
import { updateDoc } from "firebase/firestore";
import TooltipTD from "../../Tooltip/TooltipTD";
import { EditIcon } from "../../../helpers/iconSvgPath";
import { GENERAL_PERMISSION_VALUE } from "../../../helpers/constants";
import { colors } from "../../../assets/jss/variables";
import Tag from "../../../api/model/tag";
import CreateEditLabel from "../../Modal/CreateEditLabel";
import { now } from "lodash";
import { cx } from "@emotion/css";
import { getDateFormat } from "../settingsHelper";

function TagsTableRow({
  item = {},
  user,
  isAllowed = () => {},
  companyUsers = [],
  tags,
  isReadOnly = false,
}) {
  const [newTagData, setNewTagData] = useState({
    open: false,
    currentTag: { ...new Tag({}) },
    isUpdating: false,
  });
  const onClose = () =>
    setNewTagData({
      open: false,
      currentTag: { ...new Tag({}) },
      isUpdating: false,
    });

  return (
    <div className="rowTable">
      {newTagData.open && (
        <CreateEditLabel
          openModal={newTagData.open}
          handleClose={onClose}
          currentTag={newTagData.currentTag}
          isUpdating={newTagData.isUpdating}
          onSubmit={(name, description) => {
            updateDoc(item.ref, {
              name: name,
              description: description || "",
              updatedBy: user.id,
              updatedDate: now(),
            });
            onClose();
          }}
          tags={tags}
        />
      )}
      <div className={cx("cellTable", "name")}>
        <span className="labelContainer">
          <span className="spanText">{item.name}</span>
        </span>
      </div>
      <TooltipTD
        className={cx("cellTable", "name")}
        label={item.description || ""}
      >
        <span className="labelContainer">
          <span className="spanText">{item.description}</span>
        </span>
      </TooltipTD>
      <div className={cx("cellTable", "name", "labelContainer")}>
        {getUserAvatar({
          user: companyUsers.find(
            (companyUser) => companyUser.id === item.createdBy
          ),
          avatarClassName: "avatarTable",
        })}
        <span style={{ position: "relative", paddingLeft: 5 }}>
          {getCompanyUserDisplayName(companyUsers, item.createdBy)}
        </span>
      </div>
      <div className={cx("cellTable", "name", "labelContainer")}>
        {getDateFormat(item.createdDate)}
      </div>
      <div className={cx("cellTable", "name", "labelContainer")}>
        {getUserAvatar({
          user: companyUsers.find(
            (companyUser) => companyUser.id === item.updatedBy
          ),
          avatarClassName: "avatarTable",
        })}
        <span style={{ position: "relative", paddingLeft: 5 }}>
          {getCompanyUserDisplayName(companyUsers, item.updatedBy)}
        </span>
      </div>
      <div className={cx("cellTable", "name", "labelContainer")}>
        {getDateFormat(item.updatedDate)}
      </div>
      <div className={cx("cellTable", "actionButtonsContainer")}>
        <TooltipTD
          label={!item.inactive ? "Deactivate" : "Activate"}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Switch
            checked={!item.inactive}
            onChange={(ev) => {
              updateDoc(item.ref, {
                inactive: !item.inactive,
                updatedBy: user.id,
                updatedDate: now(),
              });
              ev.preventDefault();
              ev.stopPropagation();
            }}
            onClick={(ev) => ev.stopPropagation()}
            value={item.inactive}
            inputProps={{ "aria-label": "primary checkbox" }}
            style={{
              color: colors.primaryDark,
            }}
            size="small"
            disabled={
              !isAllowed(GENERAL_PERMISSION_VALUE.DELETE_TAGS) || isReadOnly
            }
          />
        </TooltipTD>
        {!isReadOnly && isAllowed(GENERAL_PERMISSION_VALUE.RENAME_TAGS) && (
          <TooltipTD
            label="Edit"
            style={{ display: "flex", cursor: "pointer", alignItems: "center" }}
            tooltipClick={() => {
              setNewTagData({
                open: true,
                currentTag: item,
                isUpdating: true,
              });
            }}
          >
            <EditIcon />
          </TooltipTD>
        )}
      </div>
    </div>
  );
}

export default TagsTableRow;
