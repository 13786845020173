import React, { useState } from "react";
import { updateDoc } from "firebase/firestore";
import CustomModal from "../../components/Modal/Modal";
import { Button } from "@mui/material";
import CustomCheckbox from "../../components/Inputs/CustomCheckbox";

function ModalWarningMark({
  user,
  handleAttentionModal,
  onMarkAllRead,
  openAttentionModal,
  text = "This will mark everything currently in the notifications panel as read",
  hiddenCheck = false,
}) {
  const [checkMark, setCheckMark] = useState(true);

  return (
    <CustomModal
      modalId="attentionMarkAll"
      className={"markAllModal"}
      isOpen={openAttentionModal}
      onClose={() => handleAttentionModal(false)}
      header={<span className="headerMark">Attention</span>}
      hasCloseIcon={false}
    >
      <div className="bodyTex">
        <span className="text">{text}</span>
        {!hiddenCheck && (
          <div className="checkOption" onClick={() => setCheckMark(!checkMark)}>
            <CustomCheckbox
              checked={checkMark}
              iconSize={17}
              styles={{ padding: 0, marginBottom: -2 }}
              onChange={(ev) => {
                setCheckMark(!checkMark);
                ev.stopPropagation();
              }}
            />
            <span>Warn me every time</span>
          </div>
        )}
        <div className="buttons">
          <Button
            onClick={() => {
              if (!checkMark && !hiddenCheck) {
                updateDoc(user.ref, {
                  skipWarning: true,
                });
              }
              onMarkAllRead({ skipWarning: true });
            }}
            className="done"
          >
            Proceed
          </Button>
          <Button
            onClick={() => handleAttentionModal(false)}
            className="cancel"
          >
            Cancel
          </Button>
        </div>
      </div>
    </CustomModal>
  );
}

export default ModalWarningMark;
