import React from "react";
import {
  AlignLeftIcon,
  AlignCenterIcon,
  OrderListIcon,
  UnorderListIcon,
  AlignRightIcon,
} from "../ToolbarButtons";
import { StyleButton } from "../ComponentHelper";
import { RICH_STYLE } from "../../../helpers/constants";
import { Divider } from "@mui/material";

const BLOCK_TYPES = [
  { label: "align-left", icon: <AlignLeftIcon />, style: "left" },
  { label: "align-center", icon: <AlignCenterIcon />, style: "center" },
  { label: "align-right", icon: <AlignRightIcon />, style: "right" },
];
const LIST_TYPE = [
  {
    label: RICH_STYLE.ORDER_LIST,
    icon: <OrderListIcon />,
    style: RICH_STYLE.ORDER_LIST,
  },
  {
    label: RICH_STYLE.UNORDER_LIST,
    icon: <UnorderListIcon />,
    style: RICH_STYLE.UNORDER_LIST,
  },
];

export const BlockStyleControls = (props) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <React.Fragment>
      <div className={"inlineGroup"}>
        {LIST_TYPE.map((type, i) => (
          <StyleButton
            key={i}
            icon={type.icon}
            onToggle={props.onToggle}
            label={type.label}
            style={type.style}
            active={type.style === blockType}
          />
        ))}
        <Divider
          orientation="vertical"
          style={{ color: "#E0E0E0", width: 1, height: 18 }}
        />
      </div>
      <div className={"inlineGroup"}>
        {BLOCK_TYPES.map((type, i) => (
          <StyleButton
            key={i}
            icon={type.icon}
            onToggle={props.onToggle}
            label={type.label}
            style={type.style}
            active={type.style === blockType}
          />
        ))}
        <Divider
          orientation="vertical"
          style={{ color: "#E0E0E0", width: 1, height: 18 }}
        />
      </div>
    </React.Fragment>
  );
};
