import { ButtonGroup } from "@mui/material";
import React from "react";
import ButtonFilter, {
  BUTTON_FILTER_BOX_SHADOW,
} from "../PurchaseOrderList/ButtonFilter";
import { colors } from "../../assets/jss/variables";
import { CheckBoxIcon, DiamondIcon } from "../../helpers/iconSvgPath";

function TaskStatusFilters({
  filters = {},
  changeButtonFilter = () => {},
  existsDate,
  handleOpenAttentionModal = () => {},
  changeFilters = () => {},
  taskByProject,
}) {
  return (
    <ButtonGroup variant="outlined" style={{ boxShadow: "unset" }}>
      <ButtonFilter
        buttonId="list-view-button-filter-late"
        value={filters.late}
        onClick={() => changeButtonFilter("late")}
        toolTip="Late"
        style={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
        boxShadowType={BUTTON_FILTER_BOX_SHADOW.RED_DIAMOND}
      >
        <DiamondIcon color={colors.diamondRed} />
      </ButtonFilter>
      <ButtonFilter
        buttonId="list-view-button-filter-neardue"
        value={filters.nearDue}
        onClick={() => changeButtonFilter("nearDue")}
        toolTip="Due Today or Tomorrow"
        style={{
          borderRadius: 0,
        }}
        boxShadowType={BUTTON_FILTER_BOX_SHADOW.ORANGE_DIAMOND}
      >
        <DiamondIcon color={colors.diamondOrange} />
      </ButtonFilter>
      <ButtonFilter
        buttonId="list-view-button-filter-inprogress"
        value={filters.inProgress}
        onClick={() => changeButtonFilter("inProgress")}
        toolTip="In Progress"
        style={{
          borderRadius: 0,
        }}
        boxShadowType={BUTTON_FILTER_BOX_SHADOW.GREEN_DIAMOND}
      >
        <DiamondIcon color={colors.diamondGreen} />
      </ButtonFilter>
      <ButtonFilter
        buttonId="list-view-button-filter-notstarted"
        value={filters.notStarted}
        onClick={() => changeButtonFilter("notStarted")}
        toolTip="Not Started"
        style={{
          borderRadius: 0,
        }}
        boxShadowType={BUTTON_FILTER_BOX_SHADOW.WHITE_DIAMOND}
      >
        <DiamondIcon color="white" strokeColor={colors.diamondWhiteBorder} />
      </ButtonFilter>
      <ButtonFilter
        buttonId="list-view-button-filter-completed"
        value={filters.completed}
        onClick={() => {
          if (taskByProject) {
            return changeButtonFilter("completed");
          }
          if (!filters.completed && existsDate) {
            return changeFilters({
              ...filters,
              completed: !filters["completed"],
            });
          } else if (!filters.completed && !existsDate) {
            return handleOpenAttentionModal();
          } else {
            return changeFilters({
              ...filters,
              completed: !filters["completed"],
            });
          }
        }}
        toolTip="Completed"
        style={{
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }}
        boxShadowType={BUTTON_FILTER_BOX_SHADOW.GRAY_DIAMOND}
      >
        <CheckBoxIcon width={15} height={15} color="#6B7A89" />
      </ButtonFilter>
    </ButtonGroup>
  );
}

export default TaskStatusFilters;
