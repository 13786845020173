import React from "react";
import { ColumnContainerStyled } from "../../assets/jss/generalStyles";

function ColumnContainer({ children }) {
  return (
    <ColumnContainerStyled className={"columnContainer"}>
      {children}
    </ColumnContainerStyled>
  );
}

export default ColumnContainer;
