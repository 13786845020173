import { now } from "moment";
import { TYPE_OF_FILE } from "../../helpers/constants";
import { getRandomId } from "../../helpers/helpers";

export default class FolderDocument {
  constructor({
    id = getRandomId(),
    name = "New folder",
    parent = "",
    parentId = "",
    mainFileId = "",
    mainFile = false,
    listIndex = "",
    currentPath = "",
    oldPath = "",
    placeholder = false,
    lastModifiedDate = now(),
    user = "",
    created = now(),
    permissionUsers = {},
    permissionGroups = {},
    createdBy = "",
    parentDocumentId = "",
    scope = "",
    triggerType = "",
  }) {
    this.id = id;
    this.name = name;
    this.type = TYPE_OF_FILE.FOLDER;
    this.parent = parent;
    this.currentPath = currentPath;
    this.oldPath = oldPath;
    this.parentId = parentId;
    this.mainFileId = mainFileId;
    this.mainFile = mainFile;
    this.listIndex = listIndex;
    this.placeholder = placeholder;
    this.lastModifiedDate = lastModifiedDate;
    this.user = user;
    this.created = created;
    this.permissionUsers = permissionUsers;
    this.permissionGroups = permissionGroups;
    this.createdBy = createdBy;
    this.parentDocumentId = parentDocumentId;
    this.scope = scope;
    this.triggerType = triggerType;
  }
}
