import React from "react";
import excel from "../../assets/flag-icons/excel.svg";
import pdf from "../../assets/flag-icons/pdf.svg";
import word from "../../assets/flag-icons/word.svg";
import powerPoint from "../../assets/flag-icons/power-point.svg";
import photoshop from "../../assets/flag-icons/photoshop.svg";
import adobe from "../../assets/flag-icons/adobe.svg";
import defaultFile from "../../assets/flag-icons/defaultFile.png";
import filePlaceholder from "../../assets/flag-icons/file-placeholder.svg";
import folder from "../../assets/flag-icons/folder-placeholder.svg";
import { TYPE_OF_FILE } from "../../helpers/constants";
import { cx } from "@emotion/css";
import { FileSelectorStyled } from "./styles";
import {
  CSVIconSVG,
  JPEGIconSVG,
  MP4IconSVG,
  PNGIconSVG,
  RTFIconSVG,
  SVGIcon,
  ZIPIconSVG,
} from "../../helpers/iconSvgPath";

function FileSelector({
  version,
  type = "",
  imgStyles = {},
  currentDocument = {},
}) {
  const getFileTypeIcon = () => {
    const parseType = type.toLowerCase();
    let typeFile = "";
    switch (parseType) {
      case "odt":
      case "doc":
      case "docx":
        typeFile = (
          <img
            className={!currentDocument.isVersion ? "normalIcon" : "smallIcon"}
            style={imgStyles}
            src={word}
            alt="word"
            draggable={false}
          />
        );
        break;
      case "csv":
        typeFile = (
          <CSVIconSVG
            className={!currentDocument.isVersion ? "normalIcon" : "smallIcon"}
            style={imgStyles}
          />
        );
        break;
      case "xls":
      case "xlsx":
      case "xlsm":
      case "ods":
        typeFile = (
          <img
            className={!currentDocument.isVersion ? "normalIcon" : "smallIcon"}
            style={imgStyles}
            src={excel}
            alt="excel"
            draggable={false}
          />
        );
        break;
      case "pdf":
        typeFile = (
          <img
            className={!currentDocument.isVersion ? "normalIcon" : "smallIcon"}
            style={imgStyles}
            src={pdf}
            alt="pdf"
            draggable={false}
          />
        );
        break;
      case "pptx":
        typeFile = (
          <img
            className={!currentDocument.isVersion ? "normalIcon" : "smallIcon"}
            style={imgStyles}
            src={powerPoint}
            alt="power-point"
            draggable={false}
          />
        );
        break;
      case "png":
        typeFile = (
          <PNGIconSVG
            className={!currentDocument.isVersion ? "normalIcon" : "smallIcon"}
            style={imgStyles}
          />
        );
        break;
      case "jpeg":
      case "jpg":
        typeFile = (
          <JPEGIconSVG
            className={!currentDocument.isVersion ? "normalIcon" : "smallIcon"}
            style={imgStyles}
          />
        );
        break;
      case "psd":
        typeFile = (
          <img
            className={!currentDocument.isVersion ? "normalIcon" : "smallIcon"}
            style={imgStyles}
            src={photoshop}
            alt="psd"
            draggable={false}
          />
        );
        break;
      case "svg":
        typeFile = (
          <SVGIcon
            className={!currentDocument.isVersion ? "normalIcon" : "smallIcon"}
            style={imgStyles}
          />
        );
        break;
      case "ai":
        typeFile = (
          <img
            className={!currentDocument.isVersion ? "normalIcon" : "smallIcon"}
            style={imgStyles}
            src={adobe}
            alt="adobe"
            draggable={false}
          />
        );
        break;
      case "zip":
        typeFile = (
          <ZIPIconSVG
            className={!currentDocument.isVersion ? "normalIcon" : "smallIcon"}
            style={imgStyles}
          />
        );
        break;
      case "rtf":
        typeFile = (
          <RTFIconSVG
            className={!currentDocument.isVersion ? "normalIcon" : "smallIcon"}
            style={imgStyles}
          />
        );
        break;
      case "mp4":
        typeFile = (
          <MP4IconSVG
            className={!currentDocument.isVersion ? "normalIcon" : "smallIcon"}
            style={imgStyles}
          />
        );
        break;
      case "file":
        typeFile = (
          <img
            className={!currentDocument.isVersion ? "normalIcon" : "smallIcon"}
            style={{ ...imgStyles, width: 29, height: 35 }}
            src={filePlaceholder}
            alt="file-placeholder"
            draggable={false}
          />
        );
        break;
      case TYPE_OF_FILE.FOLDER:
        typeFile = (
          <img
            className={!currentDocument.isVersion ? "normalIcon" : "smallIcon"}
            style={imgStyles}
            src={folder}
            alt="file-placeholder"
            draggable={false}
          />
        );
        break;
      default:
        typeFile = typeFile = (
          <img
            className={!currentDocument.isVersion ? "normalIcon" : "smallIcon"}
            style={{ ...imgStyles }}
            src={defaultFile}
            alt="df"
            draggable={false}
          />
        );
        break;
    }
    return typeFile;
  };

  return (
    <FileSelectorStyled className="fileSelectorContainer">
      {(version > 1 || currentDocument.isVersion) && (
        <label className={cx("versionLabel", `badge${currentDocument.scope}`)}>
          V{version}
        </label>
      )}
      {getFileTypeIcon()}
    </FileSelectorStyled>
  );
}

export default FileSelector;
