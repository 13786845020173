import React, { useContext, useEffect, useRef } from "react";
import {
  GanttChartTemplateContext,
  soTaskTableSize,
} from "../../CompanyTabs/SalesOrderTemplateContainer";
import {
  CALENDAR_SETUP,
  GANTT_CHART_SETTINGS,
  renderLabel,
} from "../../../helpers/ganttChart";
import moment from "moment";
import { GanttChartHeaderContainerStyled } from "../../Timeline/styles";
import { GANTTCHART_TABLE_SIZE } from "../../Timeline/GanttChartContext";

function GanttChartHeader({
  calendarRange = {
    headers: [],
    subheaders: [],
  },
}) {
  const { ganttChartTemplateState } = useContext(GanttChartTemplateContext);
  const headerRef = useRef(null);
  const {
    highlightedTask,
    startDate,
    tableSize,
    graphTableRef,
    calendarSetup,
  } = ganttChartTemplateState;

  const WIDTH_BY_SETTING = GANTT_CHART_SETTINGS[calendarSetup];

  useEffect(() => {
    const handleScroll = () => {
      if (headerRef.current) {
        const currentLeft = graphTableRef.current.scrollLeft;
        headerRef.current.scrollLeft = currentLeft;
      }
    };
    if (graphTableRef && graphTableRef.current) {
      graphTableRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (graphTableRef && graphTableRef.current) {
        graphTableRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [graphTableRef]);

  function getDatesWithoutDuplicates({
    calendarRange,
    type = CALENDAR_SETUP.WEEKLY,
  }) {
    return calendarRange.headers.reduce((acc, current) => {
      const x = acc.find((item) => item[type] === current[type]);
      if (!x) {
        return acc.concat([current]);
      }
      return acc;
    }, []);
  }

  function getHighlightedHeader({ highlightedTask }) {
    if (!highlightedTask.finishDate || !highlightedTask.startDate) return null;
    const duration =
      moment(highlightedTask.finishDate).diff(
        highlightedTask.startDate,
        "days"
      ) + 1;
    let diffDays = moment(startDate).diff(highlightedTask.startDate, "days");
    diffDays = diffDays * -1;
    let width = WIDTH_BY_SETTING * duration;
    let left = WIDTH_BY_SETTING * diffDays;

    if (duration <= 1) {
      return (
        <div
          className="calendar-highligthed-range"
          style={{
            left: left,
            width:
              width < GANTT_CHART_SETTINGS.DAY_WIDTH
                ? GANTT_CHART_SETTINGS.DAY_WIDTH
                : width,
            zIndex: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span>{moment(highlightedTask.startDate).format("D")} </span>
        </div>
      );
    }
    return (
      <div
        className="calendar-highligthed-range"
        style={{
          left: left,
          width: width < 50 ? 50 : width,
        }}
      >
        <span>{moment(highlightedTask.startDate).format("MMM D")} </span>
        <span>{moment(highlightedTask.finishDate).format("MMM D")}</span>
      </div>
    );
  }

  function getCalendarHeaders({
    calendarRange,
    calendarSetup = CALENDAR_SETUP.WEEKLY,
  }) {
    let headers = [];
    let subheaders = [];
    let headerType = CALENDAR_SETUP.WEEKLY;
    let subHeaderType = CALENDAR_SETUP.DAILY;
    if (calendarSetup === CALENDAR_SETUP.WEEKLY) {
      headers = getDatesWithoutDuplicates({
        calendarRange,
        type: CALENDAR_SETUP.WEEKLY,
      });
      subheaders = calendarRange.headers;
    } else if (
      calendarSetup === CALENDAR_SETUP.MONTHLY ||
      calendarSetup === CALENDAR_SETUP.QUARTERLY
    ) {
      headers = getDatesWithoutDuplicates({
        calendarRange,
        type: CALENDAR_SETUP.QUARTERLY,
      });
      subheaders = getDatesWithoutDuplicates({
        calendarRange,
        type: CALENDAR_SETUP.MONTHLY,
      });
      headerType = CALENDAR_SETUP.QUARTERLY;
      subHeaderType = CALENDAR_SETUP.MONTHLY;
    }

    return (
      <div className="calendar-header-container">
        <div className="header-content">
          {headers.map((date) => {
            const numberOfDays = calendarRange.headers.filter(
              (d) => d[headerType] === date[headerType]
            ).length;

            return (
              <div
                key={date.fullDate + "header"}
                className="calendar-header-cell"
                style={{
                  width: WIDTH_BY_SETTING * numberOfDays - 1,
                }}
              >
                {renderLabel({
                  numberOfDays,
                  headerType,
                  WIDTH_BY_SETTING,
                }) ? (
                  <span className="calendar-header-span">
                    {date[headerType]}
                  </span>
                ) : (
                  <span className="calendar-header-span" />
                )}
              </div>
            );
          })}
        </div>
        <div className="subheader-content">
          {subheaders.map((date) => {
            const numberOfDays = calendarRange.headers.filter(
              (d) => d[subHeaderType] === date[subHeaderType]
            ).length;
            return subHeaderType === CALENDAR_SETUP.MONTHLY ? (
              <div
                key={date.fullDate + "subheader"}
                className="calendar-header-cell"
                style={{
                  width: WIDTH_BY_SETTING * numberOfDays - 1,
                }}
              >
                {renderLabel({
                  numberOfDays,
                  headerType: subHeaderType,
                  WIDTH_BY_SETTING,
                }) ? (
                  <span className="calendar-header-span">
                    {date["monthLabel"]}
                  </span>
                ) : (
                  <span className="calendar-header-span" />
                )}
              </div>
            ) : (
              <div
                key={date.fullDate + "subheader"}
                className={"calendar-header-cell-daily"}
                style={{
                  width: WIDTH_BY_SETTING,
                }}
              >
                <span className="calendar-header-span">
                  {date.dayCharacter}
                </span>
                <span className="calendar-header-span">{date.day}</span>
              </div>
            );
          })}
          {highlightedTask &&
            getHighlightedHeader({
              highlightedTask,
            })}
        </div>
      </div>
    );
  }
  return (
    <GanttChartHeaderContainerStyled
      className="ganttchart-header-container"
      id="calendar-headers-container"
      ref={headerRef}
      style={{
        width: `calc(100% - ${soTaskTableSize[tableSize]})`,
        opacity: tableSize === GANTTCHART_TABLE_SIZE.LARGE ? 0 : 1,
      }}
    >
      {getCalendarHeaders({
        calendarRange,
        calendarSetup,
      })}
    </GanttChartHeaderContainerStyled>
  );
}

export default GanttChartHeader;
