import React, { useState, useEffect } from "react";
import { isEmpty as isEmptyLodash } from "lodash";
import Modal from "../Modal/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { stateToHTML } from "draft-js-export-html";
import { hasFeatureFlagPermission, pathToString } from "../../helpers/helpers";
import AttentionModal from "../Modal/AttentionModal";
import { useFactories } from "../../hooks/factories";
import { useCustomers } from "../../hooks/customers";
import { useCompanyId, usePermissionGroups } from "../../hooks";
import RichText from "./RichText";
import { convertFromRaw, EditorState, convertToRaw } from "draft-js";
import {
  featureFlagNames,
  GENERAL_PERMISSION_VALUE,
  NOTES_SCOPE,
} from "../../helpers/constants";

import {
  NoteSectionBodyContainerStyled,
  NotesSectionStyled,
} from "../PurchaseOrderDashboard/styles";
import LoadingBackdrop from "../WholeScreenFocusBackdrop/LoadingBackdrop";
import { firestore, storage } from "../../firebase";
import FooterNoteSection from "./FooterNoteSection";
import DocumentsNote from "./FoldersStructure/DocumentsNote";
import { useUser } from "../../hooks/user";
import { dbTables } from "../../api/types/dbTables";
import {
  blockSaveStyle,
  getDocumentErrorInfo,
  getParseHtml,
  getShipmentMentions,
  handleAddData,
  inlineImages,
  INLINE_STYLES,
  orderPromiseDocs,
  verifyDocumentsPermissions,
  getDocumentMentionData,
  prepareMemoryData,
  filterShipmentsByType,
  updateDocumentBlock,
  generateMentionElement,
  generateUserPermissionErrorMessage,
  getDescriptionLock,
  getUserMentions,
} from "./ComponentHelper";
import userTypes from "../../api/types/userTypes";
import { useFeatureFlags } from "../../hooks/featureFlags";
import { documentScope } from "../../helpers/documents";
import { useIsAllowedFunction } from "../../hooks/permissions";
import { getShipmentsByPOs } from "../../helpers/salesOrderHelpers";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { deleteObject, ref } from "firebase/storage";
import moment from "moment";
import { cx } from "@emotion/css";
import { useDispatch } from "react-redux";
import { CLIENT_IS_OFFLINE } from "../../actions/types";

const WARNING_MODAL = "WARNING";

const DATA_TYPE = {
  GROUP: "group",
  USER: "user",
};

function NotesSection({
  openModalNotes,
  onCloseModalNotes,
  users,
  handleSubmit,
  typeNote,
  editingNote,
  currentNote,
  salesOrder,
  purchaseOrder,
  currentShipment = {},
  purchaseOrders = [],
  cleanReplyActivity = () => {},
  currentPermission = {
    permission: "",
    globalPermission: "",
  },
}) {
  const companyId = useCompanyId();
  const featureFlags = useFeatureFlags({ companyId });
  const allowed = useIsAllowedFunction();
  const dispatch = useDispatch();

  const initState =
    editingNote && currentNote.mentionsFormat
      ? convertFromRaw(JSON.parse(currentNote.mentionsFormat))
      : "";
  const [editorState, setEditorState] = useState(() =>
    editingNote && currentNote.mentionsFormat
      ? EditorState.createWithContent(initState)
      : EditorState.createEmpty()
  );
  const navigatorData = (navigator || {}).userAgentData || {};
  const OS = navigatorData.platform || "";
  const user = useUser();

  const hasFlagPermission = hasFeatureFlagPermission({
    featureFlags,
    user,
    featureFlagName: featureFlagNames.DOCUMENT_IN_NOTES,
  });

  const [lockNote, setLockNote] = useState(!!currentNote.lockNote);
  const [temporalNote, setTemporalNote] = useState(false);
  const [submitData, setSubmitData] = useState({});
  const [documentError, setDocumentError] = useState({});
  const [poDocumentId, setPODocumentId] = useState(purchaseOrder.id);
  const [shipmentDocumentId, setShipmentDocumentId] = useState(
    currentShipment.id
  );
  const [documentsDB, setDocumentDB] = useState({});

  const [documentData, setDocumentData] = useState({});
  const [isUnmounted, setUnmounted] = useState(false);
  const [hasMention, setHasMention] = useState(
    !isEmptyLodash(currentNote.userMentions)
  );
  const [isDocumentOpen, setIsDocumentOpen] = useState(false);
  const [closed, setClosed] = useState(true);
  const [urlUploaded, setUrlUploaded] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shipments, setShipments] = useState([]);

  const permissionGroups = usePermissionGroups({
    companyId,
    filteringByInactive: true,
  });

  const [saveDraftModal, setSaveDraftModal] = useState(false);

  const [attentionModal, setAttentionModal] = useState({
    type: WARNING_MODAL,
    open: false,
    message: "",
  });

  const customers = useCustomers();
  const factories = useFactories();
  const filterShipment = filterShipmentsByType({
    currentShipment,
    purchaseOrder,
    salesOrder,
    shipments,
    typeNote,
  });
  const companyRef = `${dbTables.COMPANIES}/${companyId}`;

  const handleCloseModal = ({ isDeleted = false, incompleted = false }) => {
    if (editingNote && !isDeleted) {
      updateDoc(currentNote.ref, { editing: false });
    }
    let id = getCurrentOrderId();
    cleanReplyActivity();
    if (!incompleted) {
      deleteUnsaveImages(urlUploaded);
      if (temporalNote) {
        deleteDoc(temporalNote.ref);
      }
    }
    let memoryData = false;
    if (incompleted && !isEmpty() && !editingNote) {
      memoryData = prepareMemoryData({
        editorState,
        lockNote,
        typeNote,
        urlUploaded,
        userId: user.id,
        orderId: getCurrentOrderId(),
      });
    }
    if (temporalNote && temporalNote.id !== id) {
      deleteDoc(temporalNote.ref);
    }
    onCloseModalNotes({ memoryData: { ...memoryData } });
  };

  useEffect(() => {
    setIsLoading(true);
    setShipmentsDB();
    if (editingNote) {
      updateDoc(currentNote.ref, { editing: true });
    }
  }, []);

  const setShipmentsDB = async () => {
    const newShipments = await getShipmentsByPOs({
      purchaseOrders,
      path: pathToString([dbTables.COMPANIES, companyId, dbTables.SHIPMENTS]),
      dispatch: dispatch,
    });
    if (newShipments?.length > 0) {
      setShipments(newShipments);
    }
    getTemporalNote();
  };

  const getCurrentOrderId = () => {
    switch (typeNote) {
      case NOTES_SCOPE.PURCHASE_ORDER:
        return purchaseOrder.id;
      case NOTES_SCOPE.SALES_ORDER:
        return salesOrder.id;
      default:
        return currentShipment.id;
    }
  };

  const getTemporalNote = async () => {
    try {
      let id = getCurrentOrderId();
      const temporalNoteSnap = await getDoc(
        doc(
          firestore,
          `${dbTables.USERS}/${user.id}/${dbTables.TEMPORAL_NOTE}/${id}`
        )
      );
      if (temporalNoteSnap.exists()) {
        const now = moment();
        const fecha = moment(temporalNoteSnap.data().createDate, "YYYY-MM-DD");
        const diff = now.diff(fecha, "days");
        if (diff > 30) {
          deleteDoc(temporalNoteSnap.ref);
        } else {
          const currentTemporalData = temporalNoteSnap.data();
          loadOldDocumentData({
            documentsMentions: currentTemporalData.documentsMentions,
          });
          setTemporalNote({
            ...currentTemporalData,
            ref: temporalNoteSnap.ref,
            id: temporalNoteSnap.id,
          });
          setEditorState(
            EditorState.createWithContent(
              convertFromRaw(JSON.parse(currentTemporalData.mentionsFormat))
            )
          );
          setLockNote(currentTemporalData.lockNote);
          setUrlUploaded(currentTemporalData.urlUploaded);
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log("ERROR Notes section temporal note", error);
      dispatch({
        type: CLIENT_IS_OFFLINE,
        payload: {
          value: true,
          text: "Please wait a moment and try again",
        },
      });
      setIsLoading(false);
    }
  };

  const fetchDocuments = async ({
    orderType,
    documentId,
    documentCollection,
    scope,
  }) => {
    if (!documentId) {
      return;
    }
    const currentDocuments = documentsDB[documentId];
    let canSearch = true;

    if (editingNote && currentDocuments) {
      canSearch = currentDocuments.some((doc) => doc.oldData);
    }
    if (!canSearch) {
      return;
    }
    try {
      const newDocumentsDB = await getDocs(
        collection(
          firestore,
          `${companyRef}/${orderType}/${documentId}/${documentCollection}`
        )
      );

      setDocumentDB((oldDocument) => {
        return {
          ...oldDocument,
          [documentId]: newDocumentsDB.docs.map((doc) => ({
            ...doc.data(),
            purchaseOrderId: poDocumentId,
            shipmentId: shipmentDocumentId,
            salesOrderId: salesOrder.id,
            ref: doc.ref,
            documentId: doc.id,
            scope,
          })),
        };
      });
    } catch (error) {
      console.error(`ERROR RETRIEVING ${documentCollection} DOCUMENTS`, error);
      dispatch({
        type: CLIENT_IS_OFFLINE,
        payload: {
          value: true,
          text: "Please wait a moment and try again",
        },
      });
    }
  };

  useEffect(() => {
    fetchDocuments({
      orderType: dbTables.SALES_ORDERS,
      documentId: salesOrder.id,
      documentCollection: dbTables.SALES_ORDER_DOCUMENTS,
      scope: documentScope.SALES_ORDER,
    });
  }, [salesOrder.id]);

  useEffect(() => {
    fetchDocuments({
      orderType: dbTables.PURCHASE_ORDERS,
      documentId: poDocumentId,
      documentCollection: dbTables.PURCHASE_ORDER_DOCUMENTS,
      scope: documentScope.PURCHASE_ORDER,
    });
  }, [poDocumentId]);

  useEffect(() => {
    fetchDocuments({
      orderType: dbTables.SHIPMENTS,
      documentId: shipmentDocumentId,
      documentCollection: dbTables.SHIPMENT_DOCUMENTS,
      scope: documentScope.SHIPMENT,
    });
  }, [shipmentDocumentId]);

  const alertUser = (e) => {
    setUnmounted(true);
    e.preventDefault();
    setTimeout(() => {
      onCloseModalNotes(false);
    }, 100);
  };

  const loadOldDocumentData = ({ documentsMentions = {} }) => {
    const promises = Object.values(documentsMentions).map(
      async (documentData) => {
        const { documentId, parentId, scope } = documentData;
        const collectionPath =
          scope === documentScope.SHIPMENT
            ? `${dbTables.SHIPMENTS}/${parentId}/${dbTables.SHIPMENT_DOCUMENTS}/${documentId}`
            : `${dbTables.PURCHASE_ORDERS}/${parentId}/${dbTables.PURCHASE_ORDER_DOCUMENTS}/${documentId}`;

        const docRef = doc(firestore, `${companyRef}/${collectionPath}`);
        return getDoc(docRef);
      }
    );
    Promise.all(promises)
      .then((resolvedDocs) => {
        const newDocumentsObject = orderPromiseDocs(resolvedDocs);
        setDocumentDB((oldDocument) => {
          const newListDocument = { ...oldDocument };
          Object.keys(newDocumentsObject).forEach((key) => {
            const currentOldDocument = oldDocument[key] || [];
            const combinedArray = [...newDocumentsObject[key], ...currentOldDocument];
            const documentMap = new Map();
            combinedArray.forEach((doc) => {
              documentMap.set(doc.id, doc);
            });
            newListDocument[key] = Array.from(documentMap.values());
          });
          return newListDocument;
        });
      })
      .catch((error) => {
        console.error("Error fetching documents:", error);
      });
  };

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    if (editingNote) {
      loadOldDocumentData({ documentsMentions: currentNote.documentsMentions });
    }
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  useEffect(() => {
    if (isUnmounted) {
      deleteUnsaveImages(urlUploaded);
    }
  }, [isUnmounted]);

  function isEmpty() {
    const contentState = editorState.getCurrentContent();
    return !contentState.hasText();
  }

  function verifyExistsUserMentionWithouPermission(
    userMentions = {},
    users,
    selectedButton
  ) {
    let usersWithoutPermission = [];
    Object.keys(userMentions).forEach((key) => {
      const user = users.find((user) => user.id === key) || {};
      const userPermissions = user.permissions;
      if (!user.id) {
        return;
      }
      if (selectedButton === NOTES_SCOPE.PURCHASE_ORDER) {
        if (
          user.role !== userTypes.SUPER_ADMIN &&
          !userPermissions[purchaseOrder.factoryId] &&
          !userPermissions[GENERAL_PERMISSION_VALUE.ALL_VENDORS]
        ) {
          usersWithoutPermission.push(user);
        }
      } else {
        if (
          user.role !== userTypes.SUPER_ADMIN &&
          !userPermissions[salesOrder.customerId] &&
          !userPermissions[GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS]
        ) {
          usersWithoutPermission.push(user);
        }
      }
    });
    return { usersWithoutPermission };
  }

  function handleSaveNote() {
    const contentState = editorState.getCurrentContent();
    const newEditorState = updateDocumentBlock({ contentState, editorState });
    //
    let allUserMentions = {};
    let newNotes = convertToRaw(newEditorState.getCurrentContent());
    const oldList = currentNote.urlList || [];
    const imagesInNote = [];
    const state = newEditorState.getCurrentContent();
    const permissionGroupObjIds = {};
    const shipmentObjIds = {};
    const userObjIds = {};
    let documentObjIds = {};
    const options = {
      inlineStyles: INLINE_STYLES,
      blockStyleFn: (block) => blockSaveStyle(block.getType()),
      entityStyleFn: (entity) => {
        const entityType = entity.get("type").toLowerCase();
        if (entityType.includes("mention")) {
          const data = entity.getData();
          const mention = data.mention;
          let classes = typeNote + "MentionClass";
          if (mention.isShipment) {
            shipmentObjIds[mention.id] = true;
            classes =
              typeNote === NOTES_SCOPE.SHIPMENT
                ? "reverseShipmentClass"
                : "mentionsShipmenClass";
          } else {
            if (mention.isDocument) {
              documentObjIds = getDocumentMentionData({
                mention,
                oldDocumentId: documentObjIds,
              });
            }
            if (mention.dataType === DATA_TYPE.GROUP) {
              permissionGroupObjIds[mention.id] = true;
            } else {
              userObjIds[mention.id] = true;
            }
            if (!mention.isDocument && !mention.isShipment) {
              const usersMention = handleAddData({
                id: mention.id,
                permissionGroupsDB: permissionGroups,
                users: users,
              });
              allUserMentions = { ...allUserMentions, ...usersMention };
            }
            if (mention.isDocument) {
              classes = "documentsMentionClass";
            }
          }
          return generateMentionElement({
            classes,
            mention,
            purchaseOrderId: purchaseOrder.id,
            shipmentId: currentShipment.id,
            typeNote,
          });
        } else if (entityType.includes("image")) {
          const image = entity.getData();
          const { dataImage, src } = inlineImages(image, imagesInNote);
          imagesInNote.push(src);
          return dataImage;
        }
      },
    };
    const html = stateToHTML(state, options);
    const documents = Object.values(documentsDB).flat(1);
    const {
      folderHasPermission,
      fileHasPermission,
      allowSave,
      usersWithoutDocPermissions,
      usersWithoutDocPermissionsObj,
    } = verifyDocumentsPermissions({
      documentsIds: documentObjIds,
      userMentions: allUserMentions,
      users,
      allDocuments: [...documents],
      permissionGroups,
      purchaseOrders,
      shipments,
      currentUser: user,
      allowed,
    });

    const { usersWithoutPermission } = verifyExistsUserMentionWithouPermission(
      allUserMentions,
      users,
      typeNote
    );
    if (usersWithoutPermission.length > 0) {
      const message = generateUserPermissionErrorMessage({
        customers,
        factories,
        purchaseOrder,
        salesOrder,
        typeNote,
        usersWithoutPermission,
      });
      setAttentionModal({
        type: WARNING_MODAL,
        message,
        open: true,
      });
      return;
    } else {
      let parseHTML = getParseHtml({
        html,
        documentsDB,
        permissionGroups,
        shipments,
        users,
        purchaseOrders,
        documentObjIds,
      });
      const missingUrlList = urlUploaded.filter(
        (url) => !imagesInNote.includes(url)
      );
      deleteUnsaveImages(missingUrlList);
      if (editingNote) {
        const eraseUrlList = oldList.filter(
          (url) => !imagesInNote.includes(url)
        );
        deleteUnsaveImages(eraseUrlList);
      }
      const newSubmitData = {
        mentionsFormat: JSON.stringify(newNotes),
        typeScope: typeNote,
        editing: editingNote,
        userMentions: allUserMentions,
        detail: parseHTML,
        lockNote: !hasMention ? false : lockNote,
        urlList: imagesInNote,
        shipmentObjIds,
        permissionGroupObjIds,
        userObjIds,
        documentObjIds,
        temporalNote,
      };
      if (!allowSave && hasFlagPermission) {
        setSubmitData(newSubmitData);
        setDocumentError({
          folderHasPermission,
          fileHasPermission,
          usersWithoutDocPermissions,
          usersWithoutDocPermissionsObj,
          isOpen: true,
        });
      } else {
        handleSubmit(newSubmitData);
      }
    }
  }

  const handleSaveDraft = () => {
    if (isEmpty() || editingNote) {
      if (temporalNote) {
        deleteDoc(temporalNote.ref);
      }
      if (editingNote) {
        updateDoc(currentNote.ref, { editing: false });
      }
      onCloseModalNotes(false);
    }
    setSaveDraftModal(true);
  };

  const handleCloseAttentionModal = () => {
    setTimeout(() => {
      setAttentionModal({ open: false, message: "", type: WARNING_MODAL });
    }, 100);
  };

  const deleteUnsaveImages = (list = []) => {
    const currentList = list;
    currentList.forEach((url) => {
      deleteObject(ref(storage, url));
    });
    return;
  };

  return (
    <NotesSectionStyled
      className="notesSectionContainer"
      onKeyDown={(ev) => {
        if (ev.key === "Escape") {
          if (saveDraftModal) {
            return;
          }
          if (documentError.isOpen) {
            setDocumentError({});
            return;
          }
          if (attentionModal.open) {
            handleCloseAttentionModal();
            return;
          }
          handleSaveDraft();
        }
        const auxKey = ev.ctrlKey || ev.metaKey;
        if (ev.key === "Enter" && auxKey && !isEmpty()) {
          handleSaveNote();
        }
      }}
    >
      {documentError.isOpen && (
        <AttentionModal
          isOpen={!!documentError.isOpen}
          description={getDocumentErrorInfo({ ...documentError, users })}
          onClick={() => {
            handleSubmit(submitData);
          }}
          onClose={() => {
            setTimeout(() => {
              setDocumentError({});
            }, 100);
          }}
          title="Attention"
          confirmationText="Leave permissions as they are"
          cancelText="Return to edit note"
          cancellable
          classNameModal="modalPermission"
          bodyClassname="permissionTable"
        />
      )}
      {attentionModal.open && (
        <AttentionModal
          isOpen={attentionModal.open}
          description={
            attentionModal.message ||
            getDescriptionLock({ lockNote, setLockNote })
          }
          onClick={() => {
            handleCloseAttentionModal();
          }}
          onClose={() => {
            handleCloseAttentionModal();
          }}
          title={attentionModal.title ? attentionModal.title : "Attention"}
          confirmationText={
            attentionModal.type === WARNING_MODAL ? "Ok" : "Done"
          }
          styleModal={{ width: 532, height: 344 }}
        />
      )}
      {saveDraftModal && (
        <AttentionModal
          isOpen={saveDraftModal}
          description="Do you want to save this note as a draft?"
          blockKeydown
          onClick={() => {
            handleCloseModal({ incompleted: true });
          }}
          onClose={(ev, reason) => {
            if (reason) {
              setTimeout(() => setSaveDraftModal(false), 100);
            } else {
              handleCloseModal({ incompleted: false });
            }
          }}
          title={"Save as draft"}
          confirmationText={"Yes"}
          cancellable
          acceptBlue
        />
      )}

      <Modal
        modalId="modal-notes"
        hasToogle={false}
        isOpen={openModalNotes}
        onClose={(ev) => {
          if (saveDraftModal) {
            return;
          }
          handleSaveDraft(ev);
        }}
        className={cx(`modal-notes-section`, {
          marginMoved: isDocumentOpen && hasFlagPermission,
          marginInit: closed,
        })}
        blockBackdropClick
        header={
          <h2 className="title-note">{`${
            editingNote ? "Edit" : "Add"
          } Note`}</h2>
        }
        headerStyles={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        modalStyles={{
          padding: "32px 44px 32px 38px",
        }}
        hasCloseIcon={!isDocumentOpen}
      >
        <NoteSectionBodyContainerStyled className="noteSectionBodyContainer">
          {isLoading && <LoadingBackdrop withLogo={true} />}
          {isDocumentOpen && hasFlagPermission && (
            <div
              className={cx("document-note-open", {
                slideRight: isDocumentOpen,
                slideLeft: closed,
              })}
            >
              {!closed && (
                <DocumentsNote
                  currentSalesOrder={salesOrder}
                  user={user}
                  permissionGroupsDB={permissionGroups}
                  handleRichDocument={setDocumentData}
                  currentPurchaseOrder={purchaseOrder}
                  currentDocuments={documentsDB}
                  poDocumentId={poDocumentId}
                  shipmentDocumentId={shipmentDocumentId}
                  handlePOId={setPODocumentId}
                  handleShipmentId={setShipmentDocumentId}
                  shipments={shipments}
                  purchaseOrders={purchaseOrders}
                  handleDocumentsDB={setDocumentDB}
                  typeNote={typeNote}
                  closeIcon={
                    <CloseIcon
                      className="note-secction-cancel-icon"
                      onClick={handleSaveDraft}
                      style={{
                        position: "absolute",
                        right: 6,
                        top: 6,
                        height: 24,
                        width: 24,
                      }}
                    />
                  }
                />
              )}
            </div>
          )}

          {isLoading && <LoadingBackdrop />}

          <RichText
            shipmentMentions={getShipmentMentions({
              shipments: filterShipment,
              typeNote,
            })}
            userMentions={getUserMentions({
              currentPermission,
              permissionGroups,
              users,
            })}
            editorState={editorState}
            setEditorState={setEditorState}
            saveNote={handleSaveNote}
            typeNote={typeNote}
            setHasMention={setHasMention}
            note={currentNote}
            companyId={companyId}
            setIsLoading={setIsLoading}
            urlUploaded={urlUploaded}
            setUrlUploaded={setUrlUploaded}
            isDocumentOpen={isDocumentOpen}
            documentData={documentData}
            purchaseOrders={purchaseOrders}
            shipments={shipments}
            hasFlagPermission={hasFlagPermission}
            handleDocumentComponent={() => {
              if (isDocumentOpen) {
                setClosed(true);
                setTimeout(() => setIsDocumentOpen(false), 200);
              } else {
                setClosed(false);
                setIsDocumentOpen(true);
              }
            }}
          />
          <FooterNoteSection
            OS={OS}
            handleDelete={() => {
              deleteDoc(currentNote.ref);
              handleCloseModal({ isDeleted: true });
            }}
            editingNote={editingNote}
            handleClick={() => {
              if (!attentionModal.open) {
                handleSaveNote();
              }
            }}
            hasMention={hasMention}
            isEmpty={isEmpty}
            lockNote={lockNote}
            handleModalAtention={() =>
              setAttentionModal({
                open: true,
                type: "LOCK",
                title: "Who can see this note?",
              })
            }
            typeNote={typeNote}
          />
        </NoteSectionBodyContainerStyled>
      </Modal>
    </NotesSectionStyled>
  );
}

export default NotesSection;
