export const BROWSER_NAME = {
  CHROME: "CHROME",
  FIREFOX: "FIREFOX",
  SAFARI: "SAFARI",
  OPERA: "OPERA",
  EDGE: "EDGE",
};

export function getBrowserFromUserAgent() {
  let userAgent = navigator.userAgent;
  let browserName;
  if (userAgent.indexOf("Edg") !== -1) {
    browserName = BROWSER_NAME.EDGE;
  } else if (userAgent.indexOf("Chrome") !== -1) {
    browserName = browserName = BROWSER_NAME.CHROME;
  } else if (userAgent.indexOf("Firefox") !== -1) {
    browserName = browserName = BROWSER_NAME.FIREFOX;
  } else if (
    userAgent.indexOf("MSIE") !== -1 ||
    !!document.documentMode === true
  ) {
    browserName = browserName = BROWSER_NAME.EDGE;
  } else if (userAgent.indexOf("Safari") !== -1) {
    browserName = browserName = BROWSER_NAME.SAFARI;
  } else {
    browserName = "OTHER";
  }
  return browserName;
}

export const OS_NAME = {
  WINDOWS: "WINDOWS",
  MAC: "MAC",
};

export function getOperatingSystem() {
  const isMacOS = navigator.userAgent.includes("Mac OS X");
  const isWindows = navigator.userAgent.includes("Windows");
  if (isMacOS) {
    return OS_NAME.MAC;
  } else if (isWindows) {
    return OS_NAME.WINDOWS;
  } else {
    return OS_NAME.WINDOWS;
  }
}
