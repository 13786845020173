import { doc, getDoc, setDoc, updateDoc, writeBatch } from "firebase/firestore";
import { now } from "moment";
import { dbTables } from "../api/types/dbTables";
import userTypes from "../api/types/userTypes";
import { firestore } from "../firebase";
import {
  activityType,
  updateCompanyUsersUnreadActivity,
} from "./activitiesStream";
import { GENERAL_PERMISSION_VALUE, TYPE_OF_FILE } from "./constants";
import { convertToTreeRefactored, getRandomId, sortObjectsBy } from "./helpers";
import Activity from "../api/model/activity";
import React from "react";
import { getCurrentPermission } from "../components/Notes/ComponentHelper";

export const COPIED_DOCUMENT_TYPE = {
  FOLDER: "COPIED_FOLDER_LINK",
  FILE: "COPIED_FILE_LINK",
};

export const DOWNLOAD_DOCUMENT_TYPE = {
  FOLDER_LANDING: "DOWNLOADED_FOLDER_FROM_LINK",
  FILE_LANDING: "DOWNLOADED_FILE_FROM_LINK",
  FILES_AND_FOLDER: "DOWNLOADED_MULTI_FILES_AND_FOLDERS",
  MULTI_FOLDER: "DOWNLOADED_MULTI_FOLDERS",
  ONLY_FOLDER: "DOWNLOADED_FOLDER",
  ONLY_FILE: "DOWNLOADED_FILE",
  MULTI_FILE: "DOWNLOADED_MULTI_FILES",
};

export function createActivityCopyLinkV2({
  companyId,
  parentCollection,
  parentDocumentId,
  currentUser,
  currentDocument = {},
  activityId = getRandomId(),
  setDoc = () => {},
  doc = () => {},
  companyUsers = [],
  mainEntity = {},
}) {
  if (!currentDocument.id || !parentCollection || !parentDocumentId) {
    return;
  }
  let activity = {
    id: activityId,
  };
  activity.companyId = companyId;
  activity.creationDate = now();
  activity.type =
    currentDocument.type === TYPE_OF_FILE.FOLDER
      ? COPIED_DOCUMENT_TYPE.FOLDER
      : COPIED_DOCUMENT_TYPE.FILE;
  activity.detail = `A link to the current version of <strong>${currentDocument.currentPath}</strong> was copied to the clipboard`;
  activity.user = currentUser.id || "";
  activity.scope = currentDocument.scope;
  setDoc(
    doc(
      firestore,
      `${dbTables.COMPANIES}/${companyId}/${parentCollection}/${parentDocumentId}/${dbTables.ACTIVITIES}/${activity.id}`
    ),
    activity
  );
  updateCompanyUsersUnreadActivity({
    activity,
    users: companyUsers,
    companyId,
    order: mainEntity,
  });
}

export function createActivityDownloadv2({
  companyId,
  parentDocumentId,
  parentCollection,
  userId,
  documents = [],
  isLandingPage = false,
  scope = "",
  currentPath = "top level",
  doc = () => {},
  setDoc = () => {},
  activityId = getRandomId(),
  companyUsers = [],
  mainEntity = {},
}) {
  if (documents.length === 0 || !parentCollection || !parentDocumentId) {
    return;
  }
  let activity = {
    id: activityId,
  };
  activity.companyId = companyId;
  activity.creationDate = now();
  let type = "";
  const hasFolder = documents.some((doc) => doc.type === TYPE_OF_FILE.FOLDER);
  const hasFile = documents.some((doc) => doc.type !== TYPE_OF_FILE.FOLDER);
  if (isLandingPage) {
    if (hasFolder) {
      type = DOWNLOAD_DOCUMENT_TYPE.FOLDER_LANDING;
    } else {
      type = DOWNLOAD_DOCUMENT_TYPE.FILE_LANDING;
    }
  } else if (hasFolder && hasFile) {
    type = DOWNLOAD_DOCUMENT_TYPE.FILES_AND_FOLDER;
  } else if (hasFolder) {
    if (documents.length === 1) {
      type = DOWNLOAD_DOCUMENT_TYPE.ONLY_FOLDER;
    } else {
      type = DOWNLOAD_DOCUMENT_TYPE.MULTI_FOLDER;
    }
  } else if (hasFile) {
    if (documents.length === 1) {
      type = DOWNLOAD_DOCUMENT_TYPE.ONLY_FILE;
    } else {
      type = DOWNLOAD_DOCUMENT_TYPE.MULTI_FILE;
    }
  }
  activity.type = type;
  let detail = `<strong>`;
  documents
    .sort(sortObjectsBy("currentPath", false))
    .forEach((document, index) => {
      detail += `<li> <strong>${index + 1}. ${
        document.type === TYPE_OF_FILE.FOLDER ? "📂 " : "📄 "
      } ${document.name}</strong></li>`;
    });
  detail += `</strong><br/> From <strong> ${currentPath} </strong>`;
  activity.detail = detail;
  activity.user = userId || "";
  activity.scope = scope;

  setDoc(
    doc(
      firestore,
      `${dbTables.COMPANIES}/${companyId}/${parentCollection}/${parentDocumentId}/${dbTables.ACTIVITIES}/${activity.id}`
    ),
    activity
  );
  updateCompanyUsersUnreadActivity({
    activity,
    users: companyUsers,
    companyId,
    order: mainEntity,
  });
}

export const getGroupAndUserPermissionsText = ({
  file,
  companyUsersDB,
  permissionGroupsDB,
}) => {
  let textUserGroupInformation = "";

  if (
    Object.keys(file.permissionUsers).length === 0 &&
    Object.keys(file.permissionGroups).length === 0
  ) {
    textUserGroupInformation += `any permission`;
  } else if (
    Object.keys(file.permissionUsers).length === companyUsersDB.length
  ) {
    return (textUserGroupInformation = `<br/> <strong>All —</strong> @${companyUsersDB
      .sort(sortObjectsBy("displayName", false))
      .map((user) => user.displayName)
      .join(", @")}
    `);
  } else if (Object.keys(file.permissionGroups).length === 0) {
    textUserGroupInformation += ` no Access Groups, only `;
  } else if (Object.keys(file.permissionGroups).length > 0) {
    textUserGroupInformation += `${
      Object.keys(file.permissionGroups).length === 1
        ? "current Access Group: <br/>"
        : "current Access Groups: <br/>"
    } `;
    const permissionGroupsArr = [];
    Object.keys(file.permissionGroups).forEach((groupId) => {
      const permissionGroupDB = permissionGroupsDB.find(
        (group) => group.id === groupId
      );
      if (permissionGroupDB) {
        permissionGroupsArr.push(permissionGroupDB);
      }
    });
    //
    permissionGroupsArr
      .sort(sortObjectsBy("name", false))
      .forEach((permissionGroupDB, index) => {
        const permissionUsers = [];
        Object.keys(permissionGroupDB.users).forEach((userId) => {
          const userDB = companyUsersDB.find((user) => user.id === userId);
          if (userDB) {
            permissionUsers.push(userDB);
          }
        });
        textUserGroupInformation += `<strong>${
          permissionGroupDB.name
        } —</strong> @${permissionUsers
          .sort(sortObjectsBy("displayName", false))
          .map((user) => user.displayName)
          .join(", @")}${
          index < Object.keys(file.permissionGroups).length - 1 ? "<br/>" : ""
        }`;
      });
  }
  if (Object.keys(file.permissionUsers).length > 0) {
    if (Object.keys(file.permissionGroups).length > 0) {
      textUserGroupInformation += `${
        Object.keys(file.permissionUsers).length === 1
          ? Object.keys(file.permissionGroups).length > 0
            ? "<br/><strong>and user: </strong>"
            : "<br/><strong>user: </strong>"
          : Object.keys(file.permissionGroups).length > 0
          ? "<br/><strong>and users: </strong>"
          : "<br/><strong>users: </strong>"
      }`;
    }

    const users = [];
    Object.keys(file.permissionUsers).forEach((userId) => {
      const userDB = companyUsersDB.find((user) => user.id === userId);
      if (userDB) {
        users.push(userDB);
      }
    });
    if (Object.keys(file.permissionGroups).length === 0) {
      if (Object.keys(file.permissionUsers).length > 0) {
        textUserGroupInformation += `<strong>@${users
          .sort(sortObjectsBy("displayName", false))
          .map((user) => user.displayName)
          .join(", @")}</strong>`;
      }
    } else {
      if (Object.keys(file.permissionUsers).length > 0) {
        textUserGroupInformation += `@${users
          .sort(sortObjectsBy("displayName", false))
          .map((user) => user.displayName)
          .join(", @")}`;
      }
    }
  }
  return textUserGroupInformation;
};

export function createActivityMultiUpload({
  companyId,
  orderEntityId,
  parentCollection,
  userId = "",
  files = [],
  scope = "",
  currentPath = "top level",
  doc = () => {},
  setDoc = () => {},
  activityId = getRandomId(),
  companyUsers = [],
  mainEntity = {},
  folders = [],
  permissionGroupsDB,
  permissions,
  parentDocumentId,
}) {
  if (files.length === 0 || !parentCollection || !orderEntityId) {
    return;
  }
  let activity = {
    ...new Activity({
      id: activityId,
      companyId,
      type: activityType.UPLOADED_MULTI_FILES,
      user: userId,
      scope,
    }),
  };
  if (folders.length === 0) {
    activity.detail = `<strong><ol class="uploaded-multifiles-container" >`;
    files.forEach((document) => {
      activity.detail += `<li> <strong> 📄 ${document.name}</strong> <br/> </li>`;
    });
    activity.detail += `</ol></strong>to <strong>${currentPath}</strong>`;
  } else {
    activity.detail = getDetailDeleteMultiselect({
      documents: convertToTreeRefactored({
        documents: [...folders, ...files],
        parentDocumentId,
      }),
      fileUpload: true,
    });
    activity.detail += getGroupAndUserPermissionsText({
      file: permissions,
      companyUsersDB: companyUsers,
      permissionGroupsDB,
    });
    activity.detail += `<br/>to <strong>${currentPath}</strong>`;
  }

  setDoc(
    doc(
      firestore,
      `${dbTables.COMPANIES}/${companyId}/${parentCollection}/${orderEntityId}/${dbTables.ACTIVITIES}/${activity.id}`
    ),
    activity
  );
  updateCompanyUsersUnreadActivity({
    activity,
    users: companyUsers,
    companyId,
    order: mainEntity,
  });
}

export const getDetailDeleteMultiselect = ({
  documents = [],
  activityDeleteBatch = { delete: () => {} },
  fileUpload = false,
}) => {
  let detail = `<ol class="uploaded-multifiles-container" >`;
  if (documents.length === 0) {
    return "";
  }
  documents.sort(sortObjectsBy("currentPath", false)).forEach((document) => {
    activityDeleteBatch.delete(document.ref);
    detail += `<li class="root-deleted-documents"> ${
      document.type === TYPE_OF_FILE.FOLDER ? "📂 " : ""
    } ${
      fileUpload ? document.currentPath || document.name : document.currentPath
    }`;
    if (document.type === TYPE_OF_FILE.FOLDER && document.child.length > 0) {
      detail += `${getNestedDocumentsMultiDeleted({
        documents: document.child,
        className: "first-child-deleted-documents",
        activityDeleteBatch,
      })}`;
    }
    detail += `</li>`;
  });
  return detail + `</ol>`;
};

export const getNestedDocumentsMultiDeleted = ({
  documents = [],
  className = "child-deleted-documents",
  activityDeleteBatch,
}) => {
  let detail = `<div class=${className} >`;
  documents.sort(sortObjectsBy("name", false)).forEach((document) => {
    if (!document.isVersion) {
      activityDeleteBatch.delete(document.ref);
    }
    detail += `<div>${document.type === TYPE_OF_FILE.FOLDER ? "📂 " : ""}  ${
      document.name
    }</div> `;
    const child = document.child || [];
    if (child.length > 0) {
      detail += `${getNestedDocumentsMultiDeleted({
        documents: child,
        activityDeleteBatch,
      })}`;
    }
  });
  return (detail += `</div>`);
};

export function deleteDocuments({
  companyId,
  parentDocumentId,
  parentCollection,
  currentUser,
  documents = [],
  scope = "",
  isMultiSelect = false,
  companyUsers = [],
  mainEntity = {},
}) {
  let type = "";
  const hasFolder = documents.some((doc) => doc.type === TYPE_OF_FILE.FOLDER);
  const hasFile = documents.some((doc) => doc.type !== TYPE_OF_FILE.FOLDER);
  if (!isMultiSelect) {
    if (hasFolder) {
      type = activityType.DELETED_FOLDER;
    } else {
      type = activityType.DELETED_FILE;
    }
  } else if (hasFolder && hasFile) {
    type = activityType.DELETE_MULTI_FILES_AND_FOLDERS;
  } else if (hasFolder) {
    type = activityType.DELETE_MULTI_FOLDERS;
  } else if (hasFile) {
    type = activityType.DELETE_MULTI_FILES;
  }
  const activityDeleteBatch = writeBatch(firestore);

  let activity = {
    ...new Activity({
      id: getRandomId(),
      companyId,
      type,
      user: currentUser.id || "",
      scope,
    }),
  };

  activity.detail = getDetailDeleteMultiselect({
    documents,
    activityDeleteBatch,
  });
  activityDeleteBatch.commit().then(() => {
    console.log("SUCCESSFULLY DELETED");
    setDoc(
      doc(
        firestore,
        `${dbTables.COMPANIES}/${companyId}/${parentCollection}/${parentDocumentId}/${dbTables.ACTIVITIES}/${activity.id}`
      ),
      activity
    );
  });

  updateCompanyUsersUnreadActivity({
    activity,
    users: companyUsers,
    companyId,
    order: mainEntity,
  });
}

export const getDeleteMultiselectPlaceholder = ({
  documents = [],
  activityDeleteBatch = { delete: () => {} },
}) => {
  documents.sort(sortObjectsBy("currentPath", false)).forEach((document) => {
    activityDeleteBatch.delete(document.ref);
    if (document.type === TYPE_OF_FILE.FOLDER && document.child.length > 0) {
      getNestedDocumentsDeletedPlaceholder({
        documents: document.child,
        activityDeleteBatch,
      });
    }
  });
};

export const getNestedDocumentsDeletedPlaceholder = ({
  documents = [],
  activityDeleteBatch,
}) => {
  documents.sort(sortObjectsBy("name", false)).forEach((document) => {
    activityDeleteBatch.delete(document.ref);
    if (document.child.length > 0) {
      getNestedDocumentsDeletedPlaceholder({
        documents: document.child,
        activityDeleteBatch,
      });
    }
  });
};

export function deletePlaceholderDocuments({ documents = [] }) {
  const activityDeleteBatch = writeBatch(firestore);
  getDeleteMultiselectPlaceholder({ documents, activityDeleteBatch });
  activityDeleteBatch.commit().then(() => {
    console.log("SUCCESSFULLY DELETED");
  });
}

export function getselectedUsersObj(
  permissionUsers = {},
  permissionGroups = {},
  permissionGroupsDB = []
) {
  const selectedUsersObj = {};
  Object.keys(permissionUsers).forEach(
    (user) => (selectedUsersObj[user] = true)
  );
  Object.keys(permissionGroups).forEach((permission) => {
    const permissionDB = permissionGroupsDB.find(
      (permissionGroup) => permissionGroup.id === permission
    );
    permissionDB &&
      Object.keys(permissionDB.users).forEach(
        (user) => (selectedUsersObj[user] = true)
      );
  });
  return selectedUsersObj;
}

export function filtersDocumentsPermission({
  documents = [],
  permissionGroupsDB = [],
  user = {},
  permissionScope = "",
  allowed = () => {},
  filterFilePlaceholder = false,
  search = "",
}) {
  const userDocuments = documents.filter((document) => {
    const selectedUsersObj = getselectedUsersObj(
      document.permissionUsers,
      document.permissionGroups,
      permissionGroupsDB
    );
    if (
      filterFilePlaceholder &&
      document.placeholder &&
      document.type !== TYPE_OF_FILE.FOLDER
    ) {
      return false;
    }
    if (document.type !== TYPE_OF_FILE.FOLDER) {
      return true;
    }
    return (
      user.role === userTypes.SUPER_ADMIN ||
      user.role === userTypes.TRADEDASH_EMPLOYEE ||
      allowed(permissionScope) ||
      (selectedUsersObj && selectedUsersObj[user.id] === true)
    );
  });
  if (search) {
    function existsChild({ documents = [], document = {}, search = "" }) {
      const children = documents.filter((doc) => doc.parentId === document.id);
      if (children.length === 0) {
        return false;
      }
      return children.some(
        (child) =>
          child.name.toLowerCase().includes(search.toLowerCase()) ||
          existsChild({
            documents,
            document: child,
            search,
          })
      );
    }
    return userDocuments.filter(
      (document) =>
        document.name.toLowerCase().includes(search.toLowerCase()) ||
        existsChild({
          documents: userDocuments,
          document,
          search,
        })
    );
  }
  return userDocuments;
}

const documentTypesArr = [
  "odt",
  "doc",
  "docx",
  "csv",
  "xls",
  "xlsx",
  "xlsm",
  "ods",
  "pdf",
  "pptx",
  "png",
  "jpeg",
  "psd",
  "ai",
  "cdr",
  "svg",
  "eps",
  "file",
  TYPE_OF_FILE.FOLDER,
];

export function addNewDocumentType({ company, document }) {
  const documentTypesCpy = [...(company.document_types || [])];
  const documentType = document.type.toLowerCase();
  if (
    !documentTypesArr.includes(documentType) &&
    !documentTypesCpy.includes(documentType)
  ) {
    documentTypesCpy.push(documentType);
    updateDoc(company.ref, { document_types: documentTypesCpy });
  }
}

export const documentScope = {
  QUOTE: "QUOTE",
  SALES_ORDER: "SALES_ORDER",
  PURCHASE_ORDER: "PURCHASE_ORDER",
  PURCHASE_QUOTE: "PURCHASE_QUOTE",
  SHIPMENT: "SHIPMENT",
  CUSTOMER: "CUSTOMER",
  FACTORY: "FACTORY",
};

export const getDocumentDB = async ({
  scope,
  salesOrder,
  poId,
  shipmentId,
  documentId,
  companyId,
}) => {
  const companyRef = `${dbTables.COMPANIES}/${companyId}`;
  switch (scope) {
    case documentScope.PURCHASE_ORDER:
    case documentScope.PURCHASE_QUOTE:
      return await getDoc(
        doc(
          firestore,
          `${companyRef}/${dbTables.PURCHASE_ORDERS}/${poId}/${dbTables.PURCHASE_ORDER_DOCUMENTS}/${documentId}`
        )
      );

    case documentScope.SALES_ORDER:
    case documentScope.QUOTE:
      return await getDoc(
        doc(
          firestore,
          `${companyRef}/${dbTables.SALES_ORDERS}/${salesOrder.id}/${dbTables.SALES_ORDER_DOCUMENTS}/${documentId}`
        )
      );

    case documentScope.SHIPMENT:
      return await getDoc(
        doc(
          firestore,
          `${companyRef}/${dbTables.SHIPMENTS}/${shipmentId}/${dbTables.SHIPMENT_DOCUMENTS}/${documentId}`
        )
      );

    default:
      return {};
  }
};

export const hasDocumentPermission = ({
  document,
  currentUser,
  permissionGroupDB,
  canSeeAllDocuments,
  parentDocument = {},
}) => {
  if (!document || !currentUser || !permissionGroupDB) {
    return false;
  }
  if (!document.parentId && document.type !== TYPE_OF_FILE.FOLDER) {
    return true;
  }
  if (
    currentUser.role === userTypes.SUPER_ADMIN ||
    currentUser.role === userTypes.TRADEDASH_EMPLOYEE ||
    canSeeAllDocuments
  ) {
    return true;
  }

  const { permissionGroupDoc, permissionUserDoc } = getCurrentPermission({
    childDoc: document,
    parentDoc: parentDocument,
  });

  const userPermissionList = getselectedUsersObj(
    permissionUserDoc,
    permissionGroupDoc,
    permissionGroupDB
  );
  return !!userPermissionList[currentUser.id];
};

export function hasPermissionToDelete({ scope, user, isAllowed }) {
  const ORDER_DASHBOARD_SCOPES = [
    dbTables.SALES_ORDERS,
    dbTables.PURCHASE_ORDERS,
    dbTables.SHIPMENTS,
  ];
  if (user.role === userTypes.SUPER_ADMIN) {
    return true;
  } else if (ORDER_DASHBOARD_SCOPES.includes(scope)) {
    return isAllowed(
      GENERAL_PERMISSION_VALUE.CAN_DELETE_ORDER_DASHBOARD_FILES_AND_FOLDERS
    );
  } else if (scope === dbTables.CUSTOMERS) {
    return isAllowed(
      GENERAL_PERMISSION_VALUE.CAN_DELETE_CUSTOMER_FILES_AND_FOLDERS
    );
  } else if (scope === dbTables.FACTORIES) {
    return isAllowed(
      GENERAL_PERMISSION_VALUE.CAN_DELETE_VENDOR_FILES_AND_FOLDERS
    );
  }
  return false;
}

export function getModalForDeleting({
  isMultiSelect = false,
  documents = [],
  placeholderText = "",
}) {
  let type = "";
  let description = "";
  const hasFolder = documents.some((doc) => doc.type === TYPE_OF_FILE.FOLDER);
  const hasFile = documents.some((doc) => doc.type !== TYPE_OF_FILE.FOLDER);
  if (!isMultiSelect) {
    if (hasFolder) {
      type = "folder";
    } else {
      type = "file";
    }
  } else if (hasFolder && hasFile) {
    type = "files and folders";
  } else if (hasFolder) {
    type = "folders";
  } else if (hasFile) {
    type = "files";
  }
  function getNestedDocuments({
    documents = [],
    className = "child-deleted-documents",
    sortBy = "name",
    desc = false,
  }) {
    if (documents.length === 0) {
      return "";
    }
    return (
      <div
        className={className}
        style={{
          textAlign: "left",
          marginLeft: 22,
        }}
      >
        {documents.sort(sortObjectsBy(sortBy, desc)).map((document) => {
          return (
            <div
              style={{
                padding: "4px 0px",
              }}
            >
              {document.type === TYPE_OF_FILE.FOLDER ? "📂 " : ""}
              {document.name}
              {getNestedDocuments({
                documents: document.child,
                sortBy:
                  document.type === TYPE_OF_FILE.FOLDER ? "name" : "version",
                desc: document.type === TYPE_OF_FILE.FOLDER ? false : true,
              })}
            </div>
          );
        })}
      </div>
    );
  }

  function getShallowThreeView(documents = []) {
    return (
      <div
        class="uploaded-multifiles-container"
        style={{
          padding: "0px 24px",
          maxHeight: "calc(100vh - 280px)",
          overflow: "scroll",
        }}
      >
        {documents.sort(sortObjectsBy("currentPath", false)).map((document) => {
          return (
            <div
              class="root-deleted-documents"
              style={{
                textAlign: "left",
                padding: "4px 0px",
                height: "auto",
              }}
            >
              {document.type === TYPE_OF_FILE.FOLDER ? "📂 " : ""}
              {document.currentPath}
              {getNestedDocuments({
                documents: document.child,
                className: "first-child-deleted-documents",
                sortBy:
                  document.type === TYPE_OF_FILE.FOLDER ? "name" : "version",
                desc: document.type === TYPE_OF_FILE.FOLDER ? false : true,
              })}
            </div>
          );
        })}
      </div>
    );
  }

  const documentThreeView = getShallowThreeView(documents);
  description = (
    <React.Fragment>
      <div
        style={{
          padding: 16,
        }}
      >
        Do you want to permanently delete the following {placeholderText} {type}
        ?
        <br />
        <strong>This cannot be undone</strong>
      </div>
      {documentThreeView}
    </React.Fragment>
  );
  return {
    open: true,
    description,
    style: { height: "auto" },
  };
}

export function getDocumentContainerClass({
  openedFolders,
  document,
  search,
  isMultiSelect = false,
}) {
  if (isMultiSelect) {
    return "document-file-container";
  } else if (search && document.type === TYPE_OF_FILE.FOLDER) {
    return "document-folder-container";
  } else if (
    openedFolders[document.id] &&
    document.type === TYPE_OF_FILE.FOLDER
  ) {
    return "document-folder-container";
  } else {
    return "document-file-container";
  }
}
