import React, { useEffect, useState } from "react";
import { dbTables } from "../../api/types/dbTables";
import { getSizeTransformedAccordingKBSize } from "../../helpers/helpers";
import { TYPE_OF_FILE } from "../../helpers/constants";
import { TradeDashFullLogo, YellowFolderIcon } from "../../helpers/iconSvgPath";
import axios from "axios";
import Loader from "../../components/General/Loader";
import { firestore } from "../../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { styled } from "@mui/material";
import FileSelector from "../../components/Documentsv2/FileSelector";
import JSZip from "jszip";
import {
  getFunctionByName,
  globalEnvironment,
} from "../../constants/globalVariables";
import { getDocumentNameByType } from "../../helpers/documentsv2.helper";

function DocumentLanding() {
  const [documentObj, setDocument] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorHandling, setErrorHandling] = useState(null);
  const [sharedLink, setSharedLink] = useState({});

  async function downloadDocument(documentObj) {
    setLoading(true);
    const url = getFunctionByName({
      name: "ladingpage",
      env: globalEnvironment,
      params: "/createActivityEntry",
    });
    await axios.post(url, {
      ...sharedLink,
      currentDocument: documentObj,
    });
    if (documentObj.type === TYPE_OF_FILE.FOLDER) {
      const zip = new JSZip();
      async function zipHelper({ link }) {
        try {
          const response = await fetch(link);
          const buffer = await response.arrayBuffer();
          return buffer;
        } catch (error) {
          console.error("Error fetching file:", error);
          throw error;
        }
      }
      async function buildZipStructure(zip, documents = []) {
        for (let index = 0; index < documents.length; index++) {
          if (documents[index].type !== "folder") {
            const buffer = await zipHelper({ link: documents[index].url });
            zip.file(documents[index].name, buffer);
          } else {
            const newZip = zip.folder(documents[index].name);
            await buildZipStructure(newZip, documents[index].child);
          }
        }
      }
      await buildZipStructure(zip, sharedLink.child);
      zip.generateAsync({ type: "blob" }).then(function (content) {
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(content);
        downloadLink.download = `${documentObj.name}.zip`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        setLoading(false);
      });
    } else {
      window
        .fetch(documentObj.url)
        .then((response) => {
          return response.blob();
        })
        .then((blob) => {
          var a = document.createElement("a");
          a.href = window.URL.createObjectURL(blob);
          a.download = documentObj.name;
          document.body.appendChild(a);
          a.click();
          a.remove();
          setLoading(false);
        });
    }
  }

  async function getDocument({ link }) {
    const sharedLinkSnapDB = await getDocs(
      query(
        collection(firestore, dbTables.SHARED_LINKS),
        where("link", "==", link)
      )
    );
    const sharedLinkDB = sharedLinkSnapDB.docs.map((doc) => doc.data())[0];
    if (sharedLinkDB) {
      const { expirationDate } = sharedLinkDB;
      if (expirationDate < Date.now()) {
        setErrorHandling({
          status: 404,
          message: "The link has expired",
        });
        setLoading(false);
        return;
      }
      const { companyId, userId, document } = sharedLinkDB;

      setDocument({
        ...document,
        companyId,
        userId,
      });
      setSharedLink(sharedLinkDB);
      setLoading(false);
    } else {
      setErrorHandling({
        status: 404,
        message: "No file/folder was found",
      });
      setLoading(false);
    }
  }

  useEffect(() => {
    const link = window.location.href;
    getDocument({ link });
  }, []);

  return (
    <DocumentLandingPageStyled className="documentLandingPageContainer">
      {loading && <Loader />}
      <div className="header"></div>
      {errorHandling && errorHandling.status === 404 ? (
        <div className="errorHandling">{errorHandling.message}</div>
      ) : (
        documentObj?.name && (
          <div className="landingPageContent">
            <div className="companyLogoContainer">
              {documentObj?.companyUrlLogo && (
                <img
                  className="companyLogo"
                  src={documentObj.companyUrlLogo}
                  alt={"company_logo"}
                />
              )}
            </div>

            <div className="documentContainer">
              <div className="title">Download Document</div>
              <div className="documentWrapper">
                <div className="documentContent">
                  <div className="documentInfo">
                    {documentObj.type === TYPE_OF_FILE.FOLDER ? (
                      <YellowFolderIcon width={36} height={38} />
                    ) : (
                      <FileSelector
                        currentDocument={documentObj}
                        type={documentObj.type}
                      />
                    )}
                    <div className="document-name">
                      <span className="name">
                        {getDocumentNameByType({
                          currentDocument: documentObj,
                        })}
                      </span>
                      <span className="size">
                        {"(" +
                          getSizeTransformedAccordingKBSize(documentObj.size) +
                          ")"}
                      </span>
                    </div>
                  </div>
                  <div className="documentOptions">
                    <span
                      onClick={() => downloadDocument(documentObj)}
                      className="download"
                    >
                      DOWNLOAD
                    </span>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        )
      )}
      <div
        className="tradedashLogo"
        onClick={(ev) => {
          window.open("https://tradedash.com/", "_blank");
          ev.stopPropagation();
        }}
      >
        Powered by{" "}
        <TradeDashFullLogo
          width={250}
          height={"auto"}
          style={{
            padding: "0px 6px",
          }}
        />
      </div>
    </DocumentLandingPageStyled>
  );
}

const DocumentLandingPageStyled = styled("div")(() => ({
  "&.documentLandingPageContainer": {
    position: "fixed",
    width: "100vw",
    height: "100vh",
    backgroundColor: "#FFF",
    "& .landingPageContent": {
      width: 600,
      height: 450,
      margin: "auto",
      transform: "translateY(40%)",
      "& .companyLogoContainer": {
        height: "30%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: 16,
        "& .companyLogo": {
          height: "100%",
          margin: "auto",
        },
      },
      "& .documentContainer": {
        height: "70%",
        boxShadow: "0 2px 6px 0 rgba(0,0,0,0.13)",
        "& .title": {
          height: "25%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: 24,
          fontWeight: 300,
          color: "#25486D",
          padding: "0 28px",
          borderBottom: "1px solid #D4DFED",
        },
        "& .documentWrapper": {
          height: "75%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "& .documentContent": {
            width: "100%",
            height: 90,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderLeft: "6px solid #25486D",
            backgroundColor: "#E5F2FF",
            padding: "0 16px",
            "& .documentInfo": {
              display: "flex",
              alignItems: "center",
              "& .name": {
                fontSize: 14,
                fontWeight: "bold",
                color: "#25486D",
                marginLeft: 12,
                maxWidth: 150,
              },
              "& .size": {
                fontSize: 12,
                color: "#798A9B",
                marginLeft: 6,
              },
            },
            "& .documentOptions": {
              "& .download": {
                fontSize: 14,
                color: "#39F",
                textDecoration: "underline",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
              },
            },
          },
        },
      },
    },
  },

  "& .errorHandling": {
    height: "calc(100vh - 105px)",
    fontWeight: 700,
    fontSize: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  "& .tradedashLogo": {
    height: "6%",
    display: "flex",
    alignItems: "center",
    padding: 8,
    fontSize: 14,
    fontWeight: 500,
    position: "absolute",
    right: 16,
    bottom: 16,
    cursor: "pointer",
  },
}));

export default DocumentLanding;
