import React from "react";
import { isTradeDashEmployee } from "../../../helpers/helpers";
import { getPOShipments } from "../../../helpers/shipments";
import { usePermissionGroups, useQueryParams } from "../../../hooks";
import {
  useOrderDashboardSetup,
  useCurrentPurchaseOrder,
  useCurrentSalesOrder,
  usePurchaseOrders,
} from "../../../hooks/salesOrders";
import { useShipments } from "../../../hooks/shipments";
import { useUser } from "../../../hooks/user";
import ProjectDashboard from "../ProjectDashboard";
import { PERMISSION_TEXT } from "../../../helpers/constants";

function ThreePanelSection({ vendorPermission }) {
  const purchaseOrders = usePurchaseOrders();
  const shipments = useShipments();
  const user = useUser();
  const currentPurchaseOrder = useCurrentPurchaseOrder();
  const currentSalesOrder = useCurrentSalesOrder({});
  const { shipmentId } = useOrderDashboardSetup();
  const queryParam = useQueryParams();
  const isEmptyParam = !queryParam.purchaseOrderId || !queryParam.salesOrderId;
  const poShipments =
    getPOShipments({
      shipments,
      purchaseOrder: currentPurchaseOrder,
    }) || [];
  const currentShipment =
    poShipments.find((shipment) => shipment.id === shipmentId) || {};
  const permissionGroups = usePermissionGroups({
    companyId: user.companyId,
    filteringByInactive: true,
  });
  const DashboardContent = () => {
    if (queryParam.toVerify) {
      return "";
    }
    if (isEmptyParam) {
      return (
        <div className="three-panel-section-empty">
          Sales Order and PO is needed to view this project dashboard
        </div>
      );
    }

    if (!vendorPermission && currentPurchaseOrder.id) {
      return (
        <div className="three-panel-section-empty">
          {PERMISSION_TEXT.VENDOR}
        </div>
      );
    }
    if (!currentSalesOrder.id || !currentPurchaseOrder.id) {
      return <div />;
    }

    return (
      <ProjectDashboard
        currentPurchaseOrder={currentPurchaseOrder}
        currentSalesOrder={currentSalesOrder}
        companyID={user.companyId}
        purchaseOrders={purchaseOrders}
        permissionGroups={permissionGroups}
        isReadOnly={isTradeDashEmployee(user)}
        currentShipment={currentShipment}
      />
    );
  };

  return (
    <div className="three-panel-section" id="three-panel-section">
      {DashboardContent()}
    </div>
  );
}
export default ThreePanelSection;
