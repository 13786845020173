import moment from "moment";
export default class User {
  constructor({
    id = "",
    active = true,
    avatar = "",
    cellPhone = "",
    displayName = "@",
    email = "",
    firstName = "",
    lastName = "",
    role = "customer",
    typeOfUser = "",
    urlPhoto = "",
    urlPhotoThumbail = "",
    companyId = "",
    observedCompany = "",
    created = moment.now(),
    permissions = {},
    permissionGroups = {},
    automaticSystemUser = false,
    errorLogNotification = false,
  }) {
    this.id = id;
    this.active = active;
    this.avatar = avatar;
    this.cellPhone = cellPhone;
    this.displayName = displayName;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.role = role;
    this.typeOfUser = typeOfUser;
    this.urlPhoto = urlPhoto;
    this.urlPhotoThumbail = urlPhotoThumbail;
    this.companyId = companyId;
    this.observedCompany = observedCompany;
    this.created = created;
    this.permissions = permissions;
    this.permissionGroups = permissionGroups;
    this.automaticSystemUser = automaticSystemUser;
    this.errorLogNotification = errorLogNotification;
  }
}
