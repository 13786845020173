import moment, { now } from "moment";
import { direction as globalDirection } from "../../helpers/constants";
import { typeOfTask } from "../../api/types/dbTables";
import taskStages from "../../api/types/taskStages";
import AppConfig from "../../constants/AppConfig";
import { labelTaskPhases } from "../../helpers/constants";
import { sortObjectsBy } from "../../helpers/helpers";
import {
  getFlagViewOptions,
  viewOptionLabels,
} from "../../helpers/timelineCalendar";
import { getStatusTask } from "../../helpers/tasks";
import { actionType, dependencyTypesOnMove } from "../../helpers/timelineModal";

export function getTasksByEntities({
  tasks,
  currentPOs,
  currentShipments = [],
  viewOptions,
}) {
  let tasksCpy = [
    ...tasks.filter(
      (task) => task.type === typeOfTask.SALES_ORDER || task.isPhase
    ),
  ];
  const flagViewOptions = getFlagViewOptions(viewOptions);
  let POTasksCpy = [];
  currentPOs.forEach((po) => {
    if (po.showInGanttChart) {
      let POTasks = tasks.filter(
        (task) =>
          task.purchaseOrderId === po.id &&
          task.type === typeOfTask.PURCHASE_ORDER
      );
      POTasksCpy = [...POTasksCpy, ...POTasks];
    }
  });
  tasksCpy = [...tasksCpy, ...POTasksCpy];
  let shipmentTasksCpy = [];
  currentShipments.forEach((shipment) => {
    if (shipment.showInGanttChart) {
      let shipmentTasks = tasks.filter(
        (task) => task.shipmentId === shipment.id
      );
      shipmentTasksCpy = [...shipmentTasksCpy, ...shipmentTasks];
    }
  });
  tasksCpy = [...tasksCpy, ...shipmentTasksCpy];
  if (!flagViewOptions[viewOptionLabels.AD_HOC_TASKS]) {
    tasksCpy = tasksCpy.filter((task) => !task.isAdHocTask);
  }
  return tasksCpy;
}

export const filterTaskByPhases = ({ taskPhases, tasks = [] }) => {
  let filteredTasks = [...tasks];
  if (!taskPhases[labelTaskPhases.PROPOSAL_QUOTE]) {
    filteredTasks = filteredTasks.filter(
      (task) => task.stage !== taskStages.PROPOSAL
    );
  }
  if (!taskPhases[labelTaskPhases.PRE_PRODUCTION]) {
    filteredTasks = filteredTasks.filter(
      (task) => task.stage !== taskStages.PRE_PRODUCTION
    );
  }
  if (!taskPhases[labelTaskPhases.PRODUCTION]) {
    filteredTasks = filteredTasks.filter(
      (task) => task.stage !== taskStages.PRODUCTION
    );
  }
  if (!taskPhases[labelTaskPhases.BOOKING_TRANSIT]) {
    filteredTasks = filteredTasks.filter(
      (task) => task.stage !== taskStages.BOOKING_TRANSIT
    );
  }
  if (!taskPhases[labelTaskPhases.PAYMENT_BILLING]) {
    filteredTasks = filteredTasks.filter(
      (task) => task.stage !== taskStages.PAYMENT_BILLING
    );
  }
  return filteredTasks;
};

export const parseTaskBackground = ({ task, currentSO, POsObj }) => {
  return task.map((task) => {
    if (
      task.salesOrderId === currentSO.id &&
      task.type === typeOfTask.SALES_ORDER
    ) {
      return {
        ...task,
        color: AppConfig.themeColors.salesOrderBackgroundGanttChartColor,
      };
    }
    if (task.type === typeOfTask.SHIPMENT) {
      return {
        ...task,
        color: AppConfig.themeColors.shipmentBackgroundGanttChartColor,
      };
    }
    if (
      POsObj[task.purchaseOrderId] &&
      task.type === typeOfTask.PURCHASE_ORDER
    ) {
      return { ...task, color: POsObj[task.purchaseOrderId] };
    }
    return task;
  });
};

export function getTasksFiltered({
  tasks,
  currentSO,
  currentPOs,
  currentShipments = [],
  viewOptions,
  taskPhases,
}) {
  let currentTasks = getTasksByEntities({
    currentPOs,
    tasks,
    viewOptions,
    currentShipments,
  });
  currentTasks = filterTaskByPhases({ tasks: currentTasks, taskPhases });

  const POsObj = {};
  currentPOs.forEach((po) => (POsObj[po.id] = po.color));
  return parseTaskBackground({ currentSO, POsObj, task: currentTasks });
}

export const getStarEndTaksDate = ({ allTasks }) => {
  const tasks = allTasks.filter((task) => !task.isPhase);
  const [firstTask] = tasks.sort(sortObjectsBy("startDate", false));
  const [lastTask] = tasks.sort(sortObjectsBy("finishDate", true));
  if (!firstTask || !lastTask) {
    return {
      startDate: moment().startOf("month"),
      endDate: moment().endOf("month"),
    };
  }
  const startDate = moment(firstTask.startDate)
    .subtract(1, "month")
    .startOf("month");
  const endDate = moment(lastTask.finishDate).add(3, "months").endOf("month");
  return { startDate, endDate };
};

export const movedTasksList = ({ tasks, taskId, offset }) => {
  return tasks.map((task) => {
    if (task.isPhase) {
      return task;
    }
    //TO DO TIMEZONE
    if (taskId === task.id) {
      if (offset > 0) {
        const taskToUpdate = {
          ...task,
          startDate: moment(task.startDate)
            .add(Math.abs(offset), "days")
            .valueOf(),
          finishDate: moment(task.finishDate)
            .add(Math.abs(offset), "days")
            .valueOf(),
          hasBeenUpdated: true,
        };
        return {
          ...taskToUpdate,
          status: getStatusTask(taskToUpdate),
        };
      } else {
        const taskToUpdate = {
          ...task,
          startDate: moment(task.startDate)
            .subtract(Math.abs(offset), "days")
            .valueOf(),
          finishDate: moment(task.finishDate)
            .subtract(Math.abs(offset), "days")
            .valueOf(),
          hasBeenUpdated: true,
        };
        return {
          ...taskToUpdate,
          status: getStatusTask(taskToUpdate),
        };
      }
    }
    return task;
  });
};

export const changeTaskDurationList = ({
  tasks,
  taskId,
  direction,
  offset,
  newDuration,
}) => {
  return tasks.map((task) => {
    if (task.id === taskId) {
      if (direction === globalDirection.LEFT) {
        if (offset < 0) {
          const taskToUpdate = {
            ...task,
            startDate: moment(task.startDate)
              .add(Math.abs(offset), "days")
              .valueOf(),
            duration: newDuration,
            hasBeenUpdated: true,
          };
          return { ...taskToUpdate, status: getStatusTask(taskToUpdate) };
        } else {
          const taskToUpdate = {
            ...task,
            startDate: moment(task.startDate)
              .subtract(Math.abs(offset), "days")
              .valueOf(),
            duration: newDuration,
            hasBeenUpdated: true,
          };
          return {
            ...taskToUpdate,
            status: getStatusTask(taskToUpdate),
          };
        }
      } else {
        if (offset < 0) {
          const taskToUpdate = {
            ...task,
            finishDate: moment(task.finishDate)
              .subtract(Math.abs(offset), "days")
              .valueOf(),
            duration: newDuration,
            hasBeenUpdated: true,
          };
          return {
            ...taskToUpdate,
            status: getStatusTask(taskToUpdate),
          };
        } else {
          const taskToUpdate = {
            ...task,
            finishDate: moment(task.finishDate)
              .add(Math.abs(offset), "days")
              .valueOf(),
            duration: newDuration,
            hasBeenUpdated: true,
          };
          return {
            ...taskToUpdate,
            status: getStatusTask(taskToUpdate),
          };
        }
      }
    }
    return task;
  });
};

export const getTaskConfirmationChange = ({ actionTypeTask, task, offset }) => {
  if (actionTypeTask === actionType.CHANGED_DURATION_TASK) {
    return {
      ...task,
      [actionTypeTask]: true,
      newDuration: parseInt(task.duration),
      offsetDaysChangedDuration: parseInt(offset),
      typeChange: dependencyTypesOnMove.FINISH_DATE_CHANGED,
      creationDate: now(),
    };
  }
  if (actionTypeTask === actionType.MOVED_TASK) {
    return {
      ...task,
      [actionTypeTask]: true,
      offsetDaysMoved: parseInt(offset),
      typeChange: dependencyTypesOnMove.START_DATE_AND_FINISH_DATE_CHANGED,
      creationDate: now(),
    };
  }
  if (actionTypeTask === actionType.COMPLETED_TASK) {
    return {
      ...task,
      [actionTypeTask]: true,
      creationDate: now(),
    };
  }
};

export const getShipmentSelectedByPO = ({
  shipments,
  purchaseOrder,
  currentShipments,
}) => {
  let currentShipmentsCpy = [...currentShipments];
  let shipmentByPO = [
    ...shipments.filter((shipment) =>
      purchaseOrder.shipmentIds.includes(shipment.id)
    ),
  ];
  shipmentByPO.forEach((shipmentPO) => {
    const shipmentFound = currentShipmentsCpy.find(
      (cShipment) => cShipment.id === shipmentPO.id
    );
    if (!shipmentFound) {
      currentShipmentsCpy.push({ ...shipmentPO, showInGanttChart: true });
    } else {
      currentShipmentsCpy = currentShipmentsCpy.map((cShipment) => {
        if (cShipment.id === shipmentPO.id) {
          return { ...cShipment, showInGanttChart: true };
        }
        return cShipment;
      });
    }
  });
  return currentShipmentsCpy;
};
