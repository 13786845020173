import { createContext, useReducer } from "react";
import AppConfig from "../../../constants/AppConfig";
import { TaskPanelStyled } from "../styles";
import AttentionModal from "../../Modal/AttentionModal";
import LoadingBackdrop from "../../WholeScreenFocusBackdrop/LoadingBackdrop";
import { TASK_FILTER } from "../../../helpers/constants";
import AdHocTask from "../../../api/model/AdHocTask";
import { typeOfTask } from "../../../api/types/dbTables";

export const EntityTaskContext = createContext(null);
export const ENTITY_TASK_ACTION = {
  COMMON: "COMMON",
  RESET_ADHOC: "RESET_ADHOC",
};

export function TaskProvider({ children, panelWidth }) {
  const [TaskContextState, dispatchTaskcontext] = useReducer(
    TaskListReducer,
    initialState
  );
  const { modalData, loading } = TaskContextState;

  const onCloseModal = (closeAll = false) => {
    if (closeAll) {
      modalData?.acceptedAction();
    }
    dispatchTaskcontext({
      type: ENTITY_TASK_ACTION.COMMON,
      payload: {
        modalData: {
          isOpen: false,
          description: "",
        },
      },
    });
  };

  return (
    <TaskPanelStyled
      className="tasksPanelContainer"
      style={{
        flex: "unset",
        width: panelWidth,
        minWidth: panelWidth,
        maxWidth: panelWidth,
        marginRight: 0,
        height: "100%",
        border: `1px solid ${AppConfig.themeColors.dividerColor}`,
      }}
      id="dashboard-tasks"
    >
      {modalData.isOpen && (
        <AttentionModal
          isOpen={modalData.isOpen}
          title="Attention"
          description={
            modalData.description ||
            "A task can't be created for a date in the past"
          }
          onClick={() => onCloseModal(true)}
          onClose={() => onCloseModal(false)}
          cancellable={modalData.cancellable}
          cancelText={modalData.cancelText}
          confirmationText={modalData.confirmationText}
        />
      )}
      {loading && <LoadingBackdrop withLogo />}

      <EntityTaskContext.Provider
        value={{
          TaskContextState,
          dispatchTaskcontext,
        }}
      >
        {children}
      </EntityTaskContext.Provider>
    </TaskPanelStyled>
  );
}

const initialState = {
  modalData: {
    isOpen: false,
    description: "A task can't be created for a date in the past",
  },
  loading: false,
  filterTabActive: TASK_FILTER.MINE,
  showCompletedTask: false,
  adHocTask: { ...new AdHocTask({}) },
  tabScope: typeOfTask.SALES_ORDER,
  categoryStatus: {},
};

function TaskListReducer(state, action) {
  switch (action.type) {
    case ENTITY_TASK_ACTION.COMMON:
      return {
        ...state,
        ...action.payload,
      };
    case ENTITY_TASK_ACTION.RESET_ADHOC:
      return {
        ...state,
        ...action.payload,
        adHocTask: { ...new AdHocTask({}) },
      };
    default:
      console.log("Unknown action type: ", action.type);
      return state;
  }
}
