import React, { useState } from "react";
import {
  AlertTriangleMenuContainerStyled,
  AlertTrianglePopoverContainer,
} from "./styles";
import chromeIcon from "../../assets/flag-icons/chrome-icon.svg";
import { WarningVendorIcon } from "../../helpers/iconSvgPath";

function AlertTriangleMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <AlertTriangleMenuContainerStyled className="alertTriangleMenuContainer">
      <div aria-describedby={id} onMouseEnter={handleClick}>
        <WarningVendorIcon
          height={45}
          width={35}
          svgClass="warningIcon"
          backgroundClass="backgroundClass"
          linealClass="linealClass"
        />
      </div>
      <AlertTrianglePopoverContainer
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        classes={{
          paper: "alertTriangleContainer",
        }}
        className="alertTrianglePopoverContainer"
      >
        <div className="description-content">
          TradeDash is optimized to work on the <br />
          <img src={chromeIcon} alt="chrome" /> Google Chrome web browser.{" "}
          <br />
          <br />
          We advice that you login again in Chrome.
          <br />
          There may be unpredictable behavior in other web
          <br />
          browsers
          <br />
          <br />
          Download here:{" "}
          <a href="https://www.google.com/chrome/">
            https://www.google.com/chrome/
          </a>
        </div>
      </AlertTrianglePopoverContainer>
    </AlertTriangleMenuContainerStyled>
  );
}

export default AlertTriangleMenu;
