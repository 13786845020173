import React from "react";
import { NavLink } from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";

function AdminSettingsItem({ label, isLoggedOut, action, path }) {
  if (isLoggedOut) {
    return (
      <NavLink
        key={label}
        onClick={action}
        className="link"
        to="#"
      >
        <li key={label}>
          <span>{label && <IntlMessages id={label} />} </span>
        </li>
      </NavLink>
    );
  }
  return (
    <NavLink key={label} className="link" to={path}>
      <li key={label}>
        <span>{label && <IntlMessages id={label} />}</span>
      </li>
    </NavLink>
  );
}

export default AdminSettingsItem;
