import React, { useState, useEffect, useRef } from "react";
import ColorPicker from "./ColorPicker";
import moment from "moment";
import DeleteIcon from "../../assets/flag-icons/menu-delete-file.svg";
import EditableLabel from "../TextFields/EditableLabel";
import DatePicker from "../DatePicker/DatePicker";

function MilestoneItem({ milestone, onChangeMilestone, onDeleteMilestone }) {
  const [milestoneName, setMilestoneName] = useState("");
  const dateRef = useRef(null);
  const [openPicker, setOpenPicker] = useState(false);
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    setMilestoneName(milestone.name);
  }, [milestone.name]);

  return (
    <div className="milestone-container" key={milestone.id}>
      <div style={{ width: "15%" }}>
        <ColorPicker
          milestone={milestone}
          onChangeMilestone={onChangeMilestone}
        />
      </div>
      <div style={{ width: "60%" }}>
        {!isEditable ? (
          <span
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "90%",
              minWidth: "90%",
            }}
            onClick={() => setIsEditable(true)}
          >
            {milestoneName}
          </span>
        ) : (
          <EditableLabel
            editable={isEditable}
            onChange={(ev) => {
              const { value } = ev.target;
              if (value.trim().length <= 25) {
                setMilestoneName(value);
              }
            }}
            autoFocus={false}
            editing={"false"}
            onValid={() => {
              setIsEditable(false);
              if (!milestoneName.trim()) {
                return setMilestoneName(milestone.name);
              }
              return onChangeMilestone({ ...milestone, name: milestoneName });
            }}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "90%",
              minWidth: "90%",
            }}
            children={milestoneName}
            defaultChildren={milestoneName}
            onclose={() => setIsEditable(false)}
          />
        )}
      </div>
      <div style={{ width: "20%" }}>
        <span
          ref={dateRef}
          style={{ cursor: "pointer" }}
          onClick={() => setOpenPicker(true)}
        >
          {moment(milestone.date).format("M/D/YY")}
        </span>
        {openPicker && (
          <DatePicker
            el={dateRef.current}
            onChange={(value) => {
              onChangeMilestone({ ...milestone, date: value });
              setOpenPicker(false);
            }}
            open={openPicker}
            onClose={() => {
              setOpenPicker(false);
            }}
            value={moment(milestone.date)}
            cancelIcon={true}
          />
        )}
      </div>
      <div style={{ width: "5%" }}>
        <img
          style={{ cursor: "pointer" }}
          src={DeleteIcon}
          alt="delete"
          onClick={() => onDeleteMilestone(milestone.id)}
        />
      </div>
    </div>
  );
}

export default MilestoneItem;
