import React from "react";
import { NavLink } from "react-router-dom";
import { useJSONLocalStorage } from "../../hooks";
import TaskPopoverInfo from "./TaskPopoverInfo";
import { useCompanyUsers } from "../../hooks/user";
import { typeOfTask } from "../../api/types/dbTables";
import { getDashboardSearchPath, sortObjectsBy } from "../../helpers/helpers";
import { LOCAL_STORAGE_KEY } from "../../helpers/constants";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "../../components/PurchaseOrderDashboard/sections/helpers";
import { ProgressStageStyled } from "../StyledComponent/StageStyled";

function ProgressStage({
  salesOrderId,
  purchaseOrderId,
  shipment,
  tasks = [],
}) {
  const { set } = useJSONLocalStorage(LOCAL_STORAGE_KEY.projectTasks);
  const companyUsers = useCompanyUsers({});

  return (
    <ProgressStageStyled className="progressRoot">
      {tasks.sort(sortObjectsBy("listIndex", false)).map((task, index) => {
        return (
          <NavLink
            key={task.id + index}
            className="ellipsisText"
            style={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
            to={getDashboardSearchPath({
              salesOrderId,
              purchaseOrderId,
              shipmentId: task.type === typeOfTask.SHIPMENT ? shipment.id : "",
              section: ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION,
              taskId: task.id,
            })}
            onClick={() => {
              set({ taskFilter: "ALL", showCompleted: !!task.complete });
            }}
            onContextMenu={() => {
              set({ taskFilter: "ALL", showCompleted: !!task.complete });
            }}
            id="list-view-content-taskname"
          >
            <TaskPopoverInfo task={task} companyUsers={companyUsers} />
          </NavLink>
        );
      })}
    </ProgressStageStyled>
  );
}

export default ProgressStage;
