import {
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

// make the TableBody scrollable

function DataTable({ children, header, footer, className }) {
  return (
    <Table>
      <TableHead>
        <TableRow key={header} className="sticky-header">
          {header}
        </TableRow>
      </TableHead>
      <TableBody
        className={className}
        sx={{ height: "100%", overflow: "scroll" }}
      >
        {children}
      </TableBody>
      {footer && (
        <TableFooter>
          <TableRow>{footer}</TableRow>
        </TableFooter>
      )}
    </Table>
  );
}

export default DataTable;
