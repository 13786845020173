import { PO_DIAMOND_STATUS, PO_STATUS, taskStatus } from "./constants";

export function getStatusAndDiamondStatus({ filters }) {
  const status = [];
  if (filters.inProgress) {
    status.push(PO_STATUS.IN_PROGRESS);
  }
  if (filters.completed) {
    status.push(PO_STATUS.COMPLETED);
  }
  if (filters.voided) {
    status.push(PO_STATUS.VOIDED);
  }
  const diamondStatus = [];
  if (filters.late) {
    diamondStatus.push(PO_DIAMOND_STATUS.LATE);
  }
  if (filters.nearDue) {
    diamondStatus.push(PO_DIAMOND_STATUS.NEAR_DUE);
  }

  return { status, diamondStatus };
}

export function getFilteredTaskStatus({ filters }) {
  const status = [];
  if (filters.late) {
    status.push(taskStatus.LATE);
  }
  if (filters.nearDue) {
    status.push(taskStatus.NEAR_DUE);
  }
  if (filters.inProgress) {
    status.push(taskStatus.IN_PROGRESS);
  }
  if (filters.notStarted) {
    status.push(taskStatus.NOT_STARTED);
  }
  if (filters.completed) {
    status.push(taskStatus.COMPLETE);
  }

  return { status };
}
