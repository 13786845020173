/*======= Fade Snackbar ======*/
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import IntlMessages from "../../../../util/IntlMessages";
import { amber, green } from "@mui/material/colors";
import styled from "@emotion/styled";
import { Alert, IconButton, Portal, Snackbar } from "@mui/material";
import { cx } from "@emotion/css";

const AlertStyled = styled(Alert)(({ theme }) => ({
  "&.alertRoot": {
    display: "flex",
    alignItems: "center",
    "& .MuiAlert-icon": {
      color: "white",
    },
    "& .MuiAlert-message": {
      color: "white",
    },
  },
  "&.success": {
    backgroundColor: green[600],
  },
  "&.error": {
    backgroundColor: theme.palette.error.dark,
  },
  "&.info": {
    backgroundColor: theme.palette.primary.main,
  },
  "&.warning": {
    backgroundColor: amber[700],
  },
}));

function CustomSnackbar({
  open,
  PONumber,
  message = "",
  handleCloseSnackbar,
  variant = "success",
  autoHideDuration = 1500,
  anchorOrigin = { vertical: "bottom", horizontal: "left" },
}) {
  return (
    <Portal>
      <Snackbar
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={handleCloseSnackbar}
        anchorOrigin={anchorOrigin}
      >
        <AlertStyled className={cx("alertRoot", variant)} severity={variant}>
          <IntlMessages id={message} />
          {PONumber !== 0 ? PONumber : ""}
          <IconButton
            classes={{ root: "clases-icon-root" }}
            key="close"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackbar}
          >
            <CloseIcon className={"icon"} />
          </IconButton>
        </AlertStyled>
      </Snackbar>
    </Portal>
  );
}

export default CustomSnackbar;
