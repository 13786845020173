import React from "react";
import { useCompanyId } from "../../../hooks";
import SOMetadataInfo from "./SOMetaDataInfo";
import SectionSoSKU from "./SectionSoSKU";
import { useState } from "react";
import { useShipments } from "../../../hooks/shipments";
import { useEffect } from "react";

function SalesOrderMetadata({ salesOrder, customerPermission, user }) {
  const companyId = useCompanyId();
  const [toRender, setToRender] = useState(true);
  const shipments = useShipments();

  useEffect(() => {
    if (shipments && shipments.length > 0) {
      setTimeout(() => {
        setToRender(!toRender);
      }, 2500);
    }
  }, [shipments.length]);

  if (!customerPermission) {
    return (
      <div className="blockContainer">
        {salesOrder.id && (
          <p className="blockText">
            You do not hace permissions to see this sales order
          </p>
        )}
      </div>
    );
  }

  return (
    <div className={"sales-order-info"} id="sales-order-info">
      <div
        key={salesOrder.id}
        tabIndex={0}
        className="metadata-info-container  sales-order-header-height"
      >
        <SOMetadataInfo salesOrder={salesOrder} user={user} />
        <SectionSoSKU
          companyId={companyId}
          salesOrder={salesOrder}
          user={user}
        />
      </div>
    </div>
  );
}

export default SalesOrderMetadata;
