import React from "react";

import { TYPE_OF_FILE } from "../../helpers/constants";
import { getDocumentsDescendants } from "../ReportsTree/helpers";
import { ManagementDocumentNoteStyled } from "../Notes/FoldersStructure/styles";
import { convertToTreeRefactored, sortObjectsBy } from "../../helpers/helpers";
import DocumentItemUpload from "./DocumentItemUpload";

const IDENTATION = 60;

function DocumentsListUpload({
  files = [],
  folders = [],
  parentRootId,
  progressUpload = {},
}) {
  const documentsTree = convertToTreeRefactored({
    documents: [...files, ...folders],
    parentDocumentId: parentRootId,
  });
  function recursiveDocuments({
    document = {},
    childNumber = 0,
    isRoot = false,
  }) {
    const progressValue = progressUpload[document.documentId] || 0;
    const { child = [] } = document;

    const childrenSize =
      document.type === TYPE_OF_FILE.FOLDER
        ? getDocumentsDescendants({
            documents: [...files, ...folders],
            originId: document.id,
          }).length
        : document.versions?.length;

    return (
      <div
        key={document.id + "activeNote"}
        style={{
          marginLeft: isRoot ? 0 : IDENTATION,
          width: "-webkit-fill-available",
        }}
        id={`documentItem_${document.id}`}
        className={"document-file-container"}
      >
        <DocumentItemUpload
          currentDocument={document}
          progressValue={progressValue}
        />
        <div
          className="children-container"
          style={{
            maxHeight: childrenSize * 190,
            overflow: "hidden",
            transition: "max-height 1s ease-in-out",
          }}
        >
          {child.sort(sortObjectsBy("name")).map((cDocument) =>
            recursiveDocuments({
              document: cDocument,
              childNumber: childNumber + 1,
            })
          )}
        </div>
      </div>
    );
  }

  return (
    <ManagementDocumentNoteStyled className="managementDocumentContainer">
      <div className={"documentsList"} id="document-note-list-container">
        {documentsTree.sort(sortObjectsBy("name")).map((rootDocument) => {
          return recursiveDocuments({
            document: { ...rootDocument },
            childNumber: 0,
            isRoot: true,
          });
        })}
      </div>
    </ManagementDocumentNoteStyled>
  );
}

export default DocumentsListUpload;
