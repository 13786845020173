import { now } from "moment";
import { dbTables, typeOfTask } from "../api/types/dbTables";
import {
  getCompanyUserDisplayName,
  getDashboardSearchPath,
  sortObjectsBy,
} from "./helpers";
import {
  getAssignedTaskDetail,
  getDetailFromMoveDependantTask,
  getDetailFromMovedTask,
} from "./tasks";
import {
  COMPANY_PERMISSION_VALUE,
  GENERAL_PERMISSION_VALUE,
  SALES_ORDER_STATUS,
} from "./constants";
import {
  activityType,
  updateCompanyUsersUnreadActivity,
} from "./activitiesStream";
import { firestore } from "../firebase";
import { SALES_ORDER_FIELDS } from "./salesOrder";
import { colors } from "../assets/jss/variables";
import AppConfig from "../constants/AppConfig";
import {
  doc,
  getDocs,
  query,
  setDoc,
  collection as collectionFirestore,
  where,
  collection,
  getDoc,
  updateDoc,
  FieldValue,
} from "firebase/firestore";
import Activity from "../api/model/activity";
import { actionType } from "./timelineModal";

const propertyType = {
  DATE: "DATE",
  DOLAR_AMOUNT: "DOLAR_AMOUNT",
  TEXT: "TEXT",
};

function getActivityFromCompleted({ task, companyUsers = [] }) {
  const isTheSameUser = task.assignedTo === task.completedBy;
  const diffDays = task.dayOffset;

  const assignedUserName = companyUsers.find(
    (cUser) => cUser.id === task.assignedTo
  ).displayName;

  return `<p><input class='checkbox-input-done' type='checkbox' checked={true} disabled={true} /> <strong>${
    task.description
  }</strong> ${
    !isTheSameUser
      ? `that was assigned to <strong>@${assignedUserName}</strong>`
      : ""
  } ${
    diffDays > 0
      ? `<span class='styles-activity-stream-from-backend-red'> ${Math.abs(
          diffDays
        )} ${Math.abs(diffDays) === 1 ? "day" : "days"} late </span>`
      : diffDays < 0
      ? `<span class='styles-activity-stream-from-backend-green'> ${Math.abs(
          diffDays
        )} ${Math.abs(diffDays) === 1 ? "day" : "days"} early </span>`
      : ""
  }  </p> `;
}

export function createActivityEntryFromMovedTask({
  task,
  creationDate = now(),
  user = {},
  typeChange,
  isDependantTaskMoved,
  isMovingForward,
  diffDaysTask,
  diffDaysRemainingTasks,
  companyUsers = [],
  mainEntity,
}) {
  const { companyId } = user;
  const activity = {
    ...new Activity({
      scope: mainEntity.type || mainEntity.taskScope,
      companyId: companyId,
      creationDate: creationDate,
      type: "UPDATED_TASK",
      user: user.id || "",
    }),
  };
  if (task[actionType.COMPLETED_TASK]) {
    activity.detail = getActivityFromCompleted({
      task,
      companyUsers,
    });
    activity.type = activityType.TASK_COMPLETED;
  } else if (task[actionType.CONFIRMED_REMAINING_DAY_OFFSET_MOVED]) {
    activity.detail = getDetailFromMoveDependantTask({
      task,
      companyUsers,
      type: actionType.CONFIRMED_REMAINING_DAY_OFFSET_MOVED,
      user,
      dayOffsetAbs: Math.abs(task.dayOffset),
      remainingOffset: task.offsetRemainingTask,
    });
  } else if (task[actionType.DENIED_REMAINING_DAY_OFFSET_MOVED]) {
    activity.detail = getDetailFromMoveDependantTask({
      task,
      companyUsers,
      type: actionType.DENIED_REMAINING_DAY_OFFSET_MOVED,
      user,
      dayOffsetAbs: Math.abs(task.dayOffset),
      remainingOffset: task.offsetRemainingTask,
    });
  } else if (task[actionType.CHANGED_ASSIGNED_TO]) {
    activity.detail = getAssignedTaskDetail({
      description: task.description,
      currentUser: getCompanyUserDisplayName(companyUsers, task.assignedTo),
      lastUser: getCompanyUserDisplayName(companyUsers, task.lastAssignedTo),
    });
    activity.type = activityType.REASSIGNED_TASK;
  } else {
    activity.detail = getDetailFromMovedTask({
      task: task,
      typeChange,
      isDependantTaskMoved,
      isMovingForward,
      diffDaysTask,
      diffDaysRemainingTasks,
    });
  }
  setDoc(
    doc(
      firestore,
      `${dbTables.COMPANIES}/${companyId}/${mainEntity.parentCollection}/${mainEntity.id}/${dbTables.ACTIVITIES}/${activity.id}`
    ),
    activity
  );

  updateCompanyUsersUnreadActivity({
    activity,
    companyId,
    users: companyUsers,
    order: mainEntity,
  });
}

export const FIELD_NAME = {
  UNIQUE_ITEMS: "uniqueItems",
  CBM: "CBM",
  AMOUNT: "amount",
  DATE: "date",
  INVOICE: "invoice",
  SHIP_DATE: "shipDate",
  TOTAL: "total",
  WEIGHT: "weight",
  DEPOSIT: "deposit",
  TOTAL_SHIPMENTS: "totalShipments",
  POTENTIAL_LATE_FEE: "potentialLateFee",
  BALANCE: "balance",
  SHIPPING_TERMS: "shippingTerms",
  SHIPMENT_WINDOW: "shipmentWindow",
  DELIVERY_WINDOW: "deliveryWindow",
  DISCOUNT_PERCENTAGE: "discountPercentage",
  DISCOUNT: "discount",
  CREATION_DATE: "creationDate",
  SUB_TOTAL: "subtotal",
  TOTAL_POS: "TOTAL_POS",
  TOTAL_DISCOUNT: "totalDiscount",
  NUMBER: "number",
};

const POObjFields = {
  CREATION_DATE: FIELD_NAME.CREATION_DATE,
  ORDER_READY_DATE: "orderReadyDate",
  DATE: FIELD_NAME.DATE,
  CBM: FIELD_NAME.CBM,
  AMOUNT: FIELD_NAME.AMOUNT,
  INVOICE: FIELD_NAME.INVOICE,
  SHIP_DATE: FIELD_NAME.SHIP_DATE,
  WEIGHT: FIELD_NAME.WEIGHT,
  DEPOSIT: FIELD_NAME.DEPOSIT,
  TOTAL_SHIPMENTS: FIELD_NAME.TOTAL_SHIPMENTS,
  POTENTIAL_LATE_FEE: FIELD_NAME.POTENTIAL_LATE_FEE,
  UNIQUE_ITEMS: FIELD_NAME.UNIQUE_ITEMS,
  BALANCE: FIELD_NAME.BALANCE,
  SHIPPING_TERMS: FIELD_NAME.SHIPPING_TERMS,
  SHIPMENT_WINDOW: FIELD_NAME.SHIPMENT_WINDOW,
  DELIVERY_WINDOW: FIELD_NAME.DELIVERY_WINDOW,
  DISCOUNT_PERCENTAGE: FIELD_NAME.DISCOUNT_PERCENTAGE,
  DISCOUNT: FIELD_NAME.DISCOUNT,
  TOTAL: FIELD_NAME.TOTAL,
  TOTAL_DISCOUNT: FIELD_NAME.TOTAL_DISCOUNT,
  NUMBER: FIELD_NAME.NUMBER,
};

export const purchaseOrderFields = [
  {
    field: POObjFields.DATE,
    editable: false,
    tooltip: true,
    labelId: "orderDashboard.purchaseOrder.info.date",
    propertyType: propertyType.DATE,
    styles: {
      textAlign: "left",
      paddingLeft: 24,
    },
  },
  {
    field: POObjFields.CBM,
    editable: false,
    labelId: "orderDashboard.purchaseOrder.info.cbm",
    tooltip: true,
    propertyType: propertyType.TEXT,
    styles: {
      textAlign: "left",
      paddingLeft: 5,
    },
  },
  {
    field: POObjFields.AMOUNT,
    editable: false,
    labelId: "orderDashboard.purchaseOrder.info.subTotal",
    propertyType: propertyType.DOLAR_AMOUNT,
    tooltip: true,
    styles: {
      textAlign: "right",
      paddingRight: 16,
    },
  },
  {
    field: POObjFields.DEPOSIT,
    editable: true,
    labelId: "orderDashboard.purchaseOrder.info.deposit",
    tooltip: false,
    propertyType: propertyType.DOLAR_AMOUNT,
    styles: {
      textAlign: "right",
      paddingRight: 16,
    },
    permission: GENERAL_PERMISSION_VALUE.PURCHASE_ORDER_AMOUNT,
  },

  {
    field: POObjFields.INVOICE,
    editable: true,
    labelId: "orderDashboard.purchaseOrder.info.invoice",
    tooltip: true,
    propertyType: propertyType.TEXT,
    styles: {
      textAlign: "left",
    },
  },
  {
    field: POObjFields.ORDER_READY_DATE,
    editable: true,
    tooltip: true,
    labelId: "orderDashboard.purchaseOrder.info.orderReadyDate",
    propertyType: propertyType.DATE,
    styles: {
      textAlign: "left",
      paddingLeft: 24,
    },
    permission: GENERAL_PERMISSION_VALUE.ORDER_READY_DATE,
    companyPermission: COMPANY_PERMISSION_VALUE.ORDER_READY_DATE,
  },
  {
    field: POObjFields.WEIGHT,
    editable: false,
    labelId: "orderDashboard.purchaseOrder.info.weight",
    tooltip: true,
    propertyType: propertyType.TEXT,
    styles: {
      textAlign: "left",
      paddingLeft: 5,
    },
  },
  {
    field: POObjFields.DISCOUNT,
    editable: true,
    labelId: "orderDashboard.purchaseOrder.info.discountPercentage",
    tooltip: true,
    propertyType: propertyType.DOLAR_AMOUNT,
    styles: {
      textAlign: "right",
      paddingRight: 16,
    },
    labelStyle: {
      textOverflow: "unset",
      overflow: "visible",
    },
    permission: GENERAL_PERMISSION_VALUE.CAN_EDIT_PO_DISCOUNT_PERCENTAGE,
  },
  {
    field: POObjFields.BALANCE,
    editable: false,
    labelId: "orderDashboard.purchaseOrder.info.balance",
    tooltip: true,
    propertyType: propertyType.DOLAR_AMOUNT,
    styles: {
      textAlign: "right",
      paddingRight: 16,
    },
  },
  {
    field: POObjFields.SHIPPING_TERMS,
    editable: true,
    labelId: "orderDashboard.purchaseOrder.info.shippingTerms",
    propertyType: propertyType.TEXT,
    styles: {
      textAlign: "left",
    },
  },
  {
    field: POObjFields.SHIPMENT_WINDOW,
    editable: false,
    labelId: "orderDashboard.salesOrder.info.shipmentWindow",
    tooltip: false,
    propertyType: propertyType.DATE,
    styles: {
      textAlign: "left",
    },
  },
  {
    field: POObjFields.TOTAL_SHIPMENTS,
    editable: false,
    tooltip: true,
    labelId: "orderDashboard.purchaseOrder.info.totalShipments",
    propertyType: propertyType.TEXT,
    styles: {
      textAlign: "left",
      paddingLeft: 5,
    },
  },

  {
    field: POObjFields.TOTAL_DISCOUNT,
    editable: false,
    labelId: "orderDashboard.purchaseOrder.info.discount",
    tooltip: true,
    propertyType: propertyType.TEXT,
    styles: {
      textAlign: "right",
      paddingRight: 16,
    },
  },

  {
    field: POObjFields.POTENTIAL_LATE_FEE,
    editable: true,
    tooltip: false,
    labelId: "orderDashboard.purchaseOrder.info.potentialLateFee",
    propertyType: propertyType.DOLAR_AMOUNT,
    styles: {
      textAlign: "right",
      paddingRight: 16,
    },
    permission: GENERAL_PERMISSION_VALUE.PURCHASE_ORDER_AMOUNT,
  },
  {
    field: POObjFields.CREATION_DATE,
    editable: false,
    tooltip: true,
    labelId: "orderDashboard.purchaseOrder.info.create",
    propertyType: propertyType.DATE,
    styles: {
      textAlign: "left",
      paddingLeft: 24,
    },
  },
  {
    field: POObjFields.DELIVERY_WINDOW,
    editable: false,
    labelId: "orderDashboard.salesOrder.info.deliveryWindow",
    tooltip: false,
    propertyType: propertyType.DATE,
    styles: {
      textAlign: "left",
    },
  },
  {
    field: FIELD_NAME.UNIQUE_ITEMS,
    editable: false,
    labelId: "orderDashboard.salesOrder.info.uniqueItems",
    tooltip: true,
    propertyType: propertyType.TEXT,
    styles: {
      textAlign: "left",
      paddingLeft: 5,
    },
  },
  {
    field: POObjFields.TOTAL,
    editable: false,
    labelId: "orderDashboard.purchaseOrder.info.amount",
    propertyType: propertyType.DOLAR_AMOUNT,
    tooltip: true,
    styles: {
      textAlign: "right",
      paddingRight: 16,
    },
  },
  {
    field: "",
    editable: false,
    labelId: "orderDashboard.emptyField",
  },
];

const shipmentObjFields = {
  DATE: FIELD_NAME.DATE,
  CBM: FIELD_NAME.CBM,
  BOOKING: "booking",
  LOADING_PORT: "loadingPort",
  AMOUNT: FIELD_NAME.AMOUNT,
  INVOICE: FIELD_NAME.INVOICE,
  SHIP_DATE: FIELD_NAME.SHIP_DATE,
  WEIGHT: FIELD_NAME.WEIGHT,
  BILL_OF_LADING: "billOfLading",
  UNLOADING_PORT: "unloadingPort",
  DELIVERY_DATE: "deliveryDate",
  CUSTOMER_PO: "customerPO",
  CONTAINER: "container",
  UNIQUE_ITEMS: FIELD_NAME.UNIQUE_ITEMS,
  POTENTIAL_LATE_FEE: FIELD_NAME.POTENTIAL_LATE_FEE,
  SHIPPING_TERMS: FIELD_NAME.SHIPPING_TERMS,
  FINAL_DESTINATION: "finalDestination",
  FREIGHT_FORWARDER: "freightForwarder",
};

export const shipmentFinalDestinationField = {
  field: shipmentObjFields.FINAL_DESTINATION,
  editable: true,
  labelId: "orderDashboard.shipment.info.finalDestination",
  propertyType: propertyType.TEXT,
  tooltip: true,
  styles: {
    textAlign: "left",
  },
};

export const shipmentFreightForwarderField = {
  field: shipmentObjFields.FREIGHT_FORWARDER,
  editable: true,
  labelId: "orderDashboard.shipment.info.freightForwarder",
  propertyType: propertyType.TEXT,
  tooltip: true,
  styles: {
    textAlign: "left",
  },
};

export const shipmentFields = [
  {
    field: shipmentObjFields.DATE,
    editable: false,
    tooltip: true,
    labelId: "orderDashboard.shipment.info.date",
    propertyType: propertyType.DATE,
    styles: {
      textAlign: "right",
      paddingRight: 24,
    },
  },
  {
    field: shipmentObjFields.CBM,
    editable: false,
    labelId: "orderDashboard.shipment.info.cbm",
    tooltip: true,
    propertyType: propertyType.TEXT,
    styles: {
      textAlign: "left",
      paddingRight: 16,
    },
  },
  {
    field: shipmentObjFields.BOOKING,
    editable: true,
    labelId: "orderDashboard.shipment.info.booking",
    propertyType: propertyType.TEXT,
    tooltip: true,
    styles: {
      textAlign: "left",
    },
  },
  {
    field: shipmentObjFields.LOADING_PORT,
    editable: true,
    labelId: "orderDashboard.shipment.info.loadingPort",
    propertyType: propertyType.TEXT,
    tooltip: true,
    listValue: "loadingPorts",
    styles: {
      textAlign: "left",
    },
  },
  {
    field: shipmentObjFields.AMOUNT,
    editable: false,
    labelId: "orderDashboard.shipment.info.amount",
    propertyType: propertyType.DOLAR_AMOUNT,
    tooltip: true,
    styles: {
      textAlign: "right",
      paddingRight: 16,
    },
  },
  {
    field: shipmentObjFields.SHIP_DATE,
    editable: true,
    labelId: "orderDashboard.shipment.info.shipDate",
    tooltip: true,
    propertyType: propertyType.DATE,
    styles: {
      textAlign: "right",
      paddingRight: 24,
    },
    permission: GENERAL_PERMISSION_VALUE.SHIPMENT_SHIP_DATE,
  },
  {
    field: shipmentObjFields.WEIGHT,
    editable: false,
    labelId: "orderDashboard.salesOrder.info.weight",
    tooltip: true,
    propertyType: propertyType.TEXT,
    styles: {
      textAlign: "left",
      paddingRight: 16,
    },
  },
  {
    field: shipmentObjFields.BILL_OF_LADING,
    editable: true,
    labelId: "orderDashboard.shipment.info.billOfLading",
    tooltip: true,
    propertyType: propertyType.TEXT,
    styles: {
      textAlign: "left",
    },
  },
  {
    field: shipmentObjFields.UNLOADING_PORT,
    editable: true,
    tooltip: true,
    labelId: "orderDashboard.shipment.info.unloadingPort",
    listValue: "unloadingPorts",
    propertyType: propertyType.TEXT,
    styles: {
      textAlign: "left",
    },
  },
  {
    field: shipmentObjFields.POTENTIAL_LATE_FEE,
    editable: true,
    tooltip: false,
    labelId: "orderDashboard.shipment.info.potentialLateFee",
    propertyType: propertyType.DOLAR_AMOUNT,
    styles: {
      textAlign: "right",
      paddingRight: 16,
    },
  },
  {
    field: shipmentObjFields.DELIVERY_DATE,
    editable: true,
    labelId: "orderDashboard.shipment.info.deliveryDate",
    tooltip: true,
    propertyType: propertyType.DATE,
    styles: {
      textAlign: "right",
      paddingRight: 24,
    },
    permission: GENERAL_PERMISSION_VALUE.SHIPMENT_DELIVERY_DATE,
  },
  {
    field: FIELD_NAME.UNIQUE_ITEMS,
    editable: false,
    labelId: "orderDashboard.shipment.info.uniqueItems",
    tooltip: true,
    propertyType: propertyType.TEXT,
    styles: {
      textAlign: "left",
    },
  },
  {
    field: shipmentObjFields.CONTAINER,
    editable: true,
    tooltip: true,
    labelId: "orderDashboard.shipment.info.container",
    propertyType: propertyType.TEXT,
    styles: {
      textAlign: "left",
    },
  },
  {
    field: shipmentObjFields.SHIPPING_TERMS,
    editable: true,
    labelId: "orderDashboard.shipment.info.shippingTerms",
    propertyType: propertyType.TEXT,
    styles: {
      textAlign: "left",
    },
  },
  {
    field: shipmentObjFields.INVOICE,
    editable: true,
    labelId: "orderDashboard.shipment.info.invoice",
    tooltip: true,
    propertyType: propertyType.TEXT,
    styles: {
      textAlign: "left",
    },
  },
  {
    field: "",
    editable: false,
    labelId: "orderDashboard.emptyField",
  },
  {
    field: "",
    editable: false,
    labelId: "orderDashboard.emptyField",
  },
  {
    field: "",
    editable: false,
    labelId: "orderDashboard.emptyField",
  },
  {
    field: shipmentObjFields.FREIGHT_FORWARDER,
    editable: true,
    tooltip: true,
    labelId: "orderDashboard.shipment.info.freightForwarder",
    listValue: "freightForwarder",
    propertyType: propertyType.TEXT,
    styles: {
      textAlign: "left",
    },
  },
  {
    field: shipmentObjFields.CUSTOMER_PO,
    editable: true,
    tooltip: true,
    labelId: "orderDashboard.shipment.info.customerPO",
    propertyType: propertyType.TEXT,
    styles: {
      textAlign: "left",
    },
  },
];

const SOObjFields = {
  DATE: FIELD_NAME.DATE,
  DISCOUNT_PERCENTAGE: FIELD_NAME.DISCOUNT_PERCENTAGE,
  DISCOUNT: FIELD_NAME.DISCOUNT,
  CBM: FIELD_NAME.CBM,
  INVOICE: FIELD_NAME.INVOICE,
  AMOUNT: FIELD_NAME.AMOUNT,
  SHIP_DATE: FIELD_NAME.SHIP_DATE,
  CUSTOMER_PO: "customerPO",
  DEPOSIT: FIELD_NAME.DEPOSIT,
  POTENTIAL_LATE_FEE: FIELD_NAME.POTENTIAL_LATE_FEE,
  SHIPPING_TERMS: FIELD_NAME.SHIPPING_TERMS,
  SHIPMENT_WINDOW: FIELD_NAME.SHIPMENT_WINDOW,
  DELIVERY_WINDOW: FIELD_NAME.DELIVERY_WINDOW,
  UNIQUE_ITEMS: FIELD_NAME.UNIQUE_ITEMS,
  TOTAL_SHIPMENTS: FIELD_NAME.TOTAL_SHIPMENTS,
  TOTAL: FIELD_NAME.TOTAL,
  SALES_REPRESENTATIVE: "salesRepresentative",
  BALANCE: FIELD_NAME.BALANCE,
  WEIGHT: FIELD_NAME.WEIGHT,
  CREATION_DATE: FIELD_NAME.CREATION_DATE,
  DELIVERY_DATE: "deliveryDate",
  TOTAL_POS: "TOTAL_POS",
  REFERENCE_NUMBER: "referenceNumber",
  TOTAL_DISCOUNT: FIELD_NAME.TOTAL_DISCOUNT,
  PAY_TERMS_START: "payTermsStart",
};

export const salesOrderFields = [
  {
    field: SOObjFields.DATE,
    editable: false,
    tooltip: true,
    labelId: "orderDashboard.salesOrder.info.date",
    propertyType: propertyType.DATE,
    styles: {
      textAlign: "left",
      paddingLeft: 8,
    },
    containerStyle: {
      borderTop: "unset",
    },
  },
  {
    field: SOObjFields.TOTAL_POS,
    editable: false,
    labelId: "orderDashboard.salesOrder.info.totalPOs",
    tooltip: true,
    propertyType: propertyType.TEXT,
    styles: {
      textAlign: "left",
      paddingLeft: 15,
    },
    containerStyle: {
      borderTop: "unset",
    },
  },

  {
    field: SOObjFields.AMOUNT,
    editable: false,
    labelId: "orderDashboard.salesOrder.info.amount",
    tooltip: true,
    propertyType: propertyType.DOLAR_AMOUNT,
    styles: {
      textAlign: "right",
      paddingRight: 16,
    },
    containerStyle: {
      borderTop: "unset",
    },
  },
  {
    field: SOObjFields.DEPOSIT,
    editable: true,
    labelId: "orderDashboard.salesOrder.info.deposit",
    propertyType: propertyType.DOLAR_AMOUNT,
    styles: {
      textAlign: "right",
      paddingRight: 16,
    },
    containerStyle: {
      borderTop: "unset",
    },
  },
  {
    field: SOObjFields.INVOICE,
    editable: true,
    labelId: "orderDashboard.salesOrder.info.invoice",
    propertyType: propertyType.TEXT,
    styles: {
      textAlign: "left",
    },
    containerStyle: {
      borderTop: "unset",
    },
  },
  {
    field: SOObjFields.SHIPMENT_WINDOW,
    editable: false,
    labelId: "orderDashboard.salesOrder.info.shipmentWindow",
    tooltip: false,
    propertyType: propertyType.DATE,
    styles: {
      textAlign: "left",
      paddingLeft: 8,
    },
  },
  {
    field: SOObjFields.TOTAL_SHIPMENTS,
    editable: false,
    labelId: "orderDashboard.salesOrder.info.totalshipments",
    tooltip: true,
    propertyType: propertyType.TEXT,
    styles: {
      textAlign: "left",
      paddingLeft: 15,
    },
  },
  {
    field: SOObjFields.DISCOUNT,
    editable: false,
    labelId: "orderDashboard.purchaseOrder.info.discountPercentage",
    tooltip: true,
    propertyType: propertyType.TEXT,
    styles: {
      textAlign: "right",
      padding: "0px 5px",
    },
    labelStyle: {
      textOverflow: "unset",
      overflow: "visible",
    },
  },
  {
    field: SOObjFields.BALANCE,
    editable: false,
    labelId: "orderDashboard.salesOrder.info.balance",
    propertyType: propertyType.DOLAR_AMOUNT,
    tooltip: true,
    styles: {
      textAlign: "right",
      paddingRight: 16,
    },
  },
  {
    field: SOObjFields.CUSTOMER_PO,
    editable: true,
    tooltip: true,
    labelId: "orderDashboard.salesOrder.info.customerPO",
    propertyType: propertyType.TEXT,
    companyPermission: COMPANY_PERMISSION_VALUE.CUSTOMER_PO,
    styles: {
      textAlign: "left",
    },
  },
  {
    field: SOObjFields.DELIVERY_WINDOW,
    editable: false,
    labelId: "orderDashboard.salesOrder.info.deliveryWindow",
    tooltip: false,
    propertyType: propertyType.DATE,
    styles: {
      textAlign: "left",
      paddingLeft: 8,
    },
  },
  {
    field: FIELD_NAME.UNIQUE_ITEMS,
    editable: false,
    labelId: "orderDashboard.salesOrder.info.uniqueItems",
    tooltip: true,
    propertyType: propertyType.TEXT,
    styles: {
      textAlign: "left",
      paddingLeft: 15,
    },
  },
  {
    field: SOObjFields.TOTAL_DISCOUNT,
    editable: false,
    labelId: "orderDashboard.purchaseOrder.info.discount",
    tooltip: true,
    propertyType: propertyType.TEXT,
    styles: {
      textAlign: "right",
      paddingRight: 16,
    },
  },
  {
    field: SOObjFields.POTENTIAL_LATE_FEE,
    editable: true,
    labelId: "orderDashboard.salesOrder.info.potentialLateFee",
    propertyType: propertyType.DOLAR_AMOUNT,
    styles: {
      textAlign: "right",
      paddingRight: 16,
    },
  },
  //
  {
    field: SOObjFields.REFERENCE_NUMBER,
    editable: true,
    tooltip: true,
    labelId: "orderDashboard.salesOrder.info.referenceNumber",
    propertyType: propertyType.TEXT,
    styles: {
      textAlign: "left",
    },
  },
  //
  {
    field: SOObjFields.SHIP_DATE,
    editable: true,
    labelId: "orderDashboard.salesOrder.info.shipDate",
    tooltip: true,
    companyPermission: COMPANY_PERMISSION_VALUE.SHIP_BY_DATE,
    propertyType: propertyType.DATE,
    styles: {
      textAlign: "left",
      paddingLeft: 8,
    },
  },
  {
    field: SOObjFields.DELIVERY_DATE,
    editable: false,
    tooltip: true,
    labelId: "orderDashboard.salesOrder.info.deliveryDate",
    propertyType: propertyType.DATE,
    styles: {
      textAlign: "left",
      paddingLeft: 8,
    },
  },
  {
    field: SOObjFields.TOTAL,
    editable: false,
    labelId: "orderDashboard.purchaseOrder.info.amount",
    propertyType: propertyType.DOLAR_AMOUNT,
    tooltip: true,
    styles: {
      textAlign: "right",
      paddingRight: 16,
    },
  },
  {
    field: SOObjFields.CBM,
    editable: false,
    labelId: "orderDashboard.salesOrder.info.cbm",
    tooltip: true,
    propertyType: propertyType.TEXT,
    styles: {
      textAlign: "right",
      paddingRight: 16,
    },
  },
  {
    field: SOObjFields.SALES_REPRESENTATIVE,
    editable: true,
    tooltip: true,
    labelId: "orderDashboard.salesOrder.info.salesRepresentative",
    companyPermission: COMPANY_PERMISSION_VALUE.SALES_REP,
    propertyType: propertyType.TEXT,
    styles: {
      textAlign: "left",
    },
  },
  {
    field: SOObjFields.PAY_TERMS_START,
    editable: true,
    tooltip: true,
    labelId: "orderDashboard.salesOrder.info.paymentTermsStart",
    propertyType: propertyType.DATE,
    styles: {
      textAlign: "left",
      paddingLeft: 8,
    },
  },
  {
    field: SOObjFields.CREATION_DATE,
    editable: false,
    tooltip: true,
    labelId: "orderDashboard.salesOrder.info.create",
    propertyType: propertyType.DATE,
    styles: {
      textAlign: "left",
      paddingLeft: 8,
    },
  },
  {
    field: "",
    editable: false,
    labelId: "orderDashboard.emptyField",
  },
  {
    field: "",
    editable: false,
    labelId: "orderDashboard.emptyField",
  },
  {
    field: "",
    editable: false,
    labelId: "orderDashboard.emptyField",
  },
];

export const SOAmountFields = [
  SOObjFields.AMOUNT,
  SOObjFields.DEPOSIT,
  SOObjFields.REMAINDER,
  SOObjFields.POTENTIAL_LATE_FEE,
  SOObjFields.BALANCE,
  SOObjFields.DISCOUNT,
  SOObjFields.DISCOUNT_PERCENTAGE,
  SOObjFields.TOTAL,
  POObjFields.TOTAL_DISCOUNT,
];

export const POAmountFields = [
  POObjFields.AMOUNT,
  POObjFields.DEPOSIT,
  POObjFields.REMAINDER,
  POObjFields.POTENTIAL_LATE_FEE,
  POObjFields.BALANCE,
  POObjFields.DISCOUNT,
  POObjFields.TOTAL_DISCOUNT,
];

export const shipmentAmountFields = [
  shipmentObjFields.AMOUNT,
  shipmentObjFields.POTENTIAL_LATE_FEE,
];

export function getShipmentsBySalesOrder({ purchaseOrders, shipments }) {
  let availableShipments = [];
  purchaseOrders.forEach((purchaseOrder) => {
    if (purchaseOrder.shipmentIds && purchaseOrder.shipmentIds.length > 0) {
      purchaseOrder.shipmentIds.forEach((shipmentId) => {
        if (!availableShipments.includes(shipmentId)) {
          availableShipments.push(shipmentId);
        }
      });
    }
  });
  let shipmentsCpy = [...shipments];
  shipmentsCpy = shipmentsCpy.filter((shipment) => {
    if (availableShipments.includes(shipment.id)) {
      return true;
    }
    return false;
  });
  return shipmentsCpy;
}

export function getShipmentsByPO({ purchaseOrder, shipments }) {
  let availableShipments = [];
  if (purchaseOrder.shipmentIds && purchaseOrder.shipmentIds.length > 0) {
    purchaseOrder.shipmentIds.forEach((shipmentId) => {
      if (!availableShipments.includes(shipmentId)) {
        availableShipments.push(shipmentId);
      }
    });
  }
  let shipmentsCpy = [...shipments];
  shipmentsCpy = shipmentsCpy.filter((shipment) => {
    if (availableShipments.includes(shipment.id)) {
      return true;
    }
    return false;
  });
  return shipmentsCpy;
}

export const menuTabScope = {
  PURCHASE_ORDER_TAB: "purchase-order-tab",
  SHIPMENT_TAB: "shipment-tab",
};

export function getShipmentValue({ shipments, shipmentId, field }) {
  const shipment = shipments.find(
    (shipment = {}) => shipment.id === shipmentId
  );
  if (shipment) {
    return shipment[field];
  }
  return "";
}

export const shipmentWindowHeaderCells = [
  {
    id: "shipment",
    label: "Shipment",
    align: "left",
    reference: "number",
    canBeSort: true,
    style: {
      width: 120,
      padding: "8px 0px 8px 29px",
      color: colors.darkGray,
      border: "none",
    },
    arrowStyles: {
      marginLeft: 6,
      position: "relative",
    },
  },
  {
    id: "date",
    label: "Ship Date",
    reference: "shipDate",
    canBeSort: true,
    style: {
      width: 105,
      padding: "8px 15px 8px 0px",
      color: colors.darkGray,
      textAlign: "right",
      border: "none",
    },
    arrowStyles: {
      marginLeft: 6,
      position: "relative",
    },
  },
  {
    id: "finalDestination",
    label: "Final Destination",
    reference: "finalDestination",
    canBeSort: true,
    style: {
      width: 250,
      padding: "8px 6px",
      color: colors.darkGray,
      textAlign: "left",
      border: "none",
    },
    arrowStyles: {
      marginLeft: 6,
      position: "relative",
    },
  },
];

export const deliveryWindowHeaderCells = [
  {
    id: "shipment",
    label: "Shipment",
    align: "left",
    reference: "number",
    canBeSort: true,
    style: {
      width: 120,
      padding: "8px 0px 8px 29px",
      color: colors.darkGray,
      border: "none",
    },
    arrowStyles: {
      marginLeft: 6,
      position: "relative",
    },
  },
  {
    id: "date",
    label: "Delivery Date",
    reference: "deliveryDate",
    canBeSort: true,
    style: {
      width: 125,
      padding: "8px 15px 8px 0px",
      color: colors.darkGray,
      textAlign: "right",
      border: "none",
    },
    arrowStyles: {
      marginLeft: 6,
      position: "relative",
    },
  },
  {
    id: "finalDestination",
    label: "Final Destination",
    reference: "finalDestination",
    canBeSort: true,
    style: {
      width: 250,
      padding: "8px 6px",
      color: colors.darkGray,
      textAlign: "left",
      border: "none",
    },
    arrowStyles: {
      marginLeft: 6,
      position: "relative",
    },
  },
];

export const itemTableRowHeight = {
  NORMAL: 41,
  WITH_IMAGE: 65,
};

export { POObjFields, SOObjFields, shipmentObjFields, propertyType };

export const salesOrderAmountFieldsToVerify = [
  SALES_ORDER_FIELDS.DEPOSIT,
  SALES_ORDER_FIELDS.DISCOUNT,
  SALES_ORDER_FIELDS.POTENTIAL_LATE_FEE,
  "price",
];

export const poAmountFieldsToVerify = [
  SALES_ORDER_FIELDS.DEPOSIT,
  SALES_ORDER_FIELDS.DISCOUNT,
  SALES_ORDER_FIELDS.POTENTIAL_LATE_FEE,
  "unitCost",
];

export const shipmentAmountFieldsToVerify = [
  SALES_ORDER_FIELDS.POTENTIAL_LATE_FEE,
  "unitCost",
];

export function getBackgroundColorByType({ type }) {
  switch (type) {
    case typeOfTask.SALES_ORDER:
      return AppConfig.themeColors.salesOrderBackgroundColor;

    case typeOfTask.PURCHASE_ORDER:
      return AppConfig.themeColors.purchaseOrderBackgroundColor;

    case typeOfTask.SHIPMENT:
      return AppConfig.themeColors.shipmentColor;

    default:
      return "#FFF";
  }
}

export function getBorderColorByType({ type }) {
  switch (type) {
    case typeOfTask.SALES_ORDER:
      return AppConfig.themeColors.salesOrderBorderColor;

    case typeOfTask.PURCHASE_ORDER:
      return AppConfig.themeColors.purchaseOrderBorderColor;

    case typeOfTask.SHIPMENT:
      return AppConfig.themeColors.shipmentBordeColor;

    default:
      return "#FFF";
  }
}

export function getEntityByField({ itemList, itemId, field = "name" }) {
  const currentItem = itemList.find((item) => item.id === itemId);
  if (currentItem) {
    return currentItem[field] || "NO_DATA";
  }
  return "";
}

export const getRemainingPromises = function ({
  list = [],
  field = "salesOrderIds",
  actualList = [],
  incomingList = [],
  incomingListField = "salesOrderIds",
  type = "array",
}) {
  let arrIds = [];
  if (type === "singleField") {
    list.forEach((el) => {
      if (el[field]) {
        if (!arrIds.includes(el[field])) {
          arrIds.push(el[field]);
        }
      }
    });
    incomingList.forEach((el) => {
      if (el[incomingListField] && el[incomingListField].length > 0) {
        el[incomingListField].forEach((id) => {
          if (!arrIds.includes(id)) {
            arrIds.push(id);
          }
        });
      }
    });
  } else if (type === "array") {
    list.forEach((el) => {
      const arrayList = el[field] || [];
      arrIds = [...arrIds, ...arrayList];
    });
    incomingList.forEach((el) => {
      if (el[field] && el[field].length > 0) {
        const arrayList = el[field] || [];
        arrIds = [...arrIds, ...arrayList];
      }
    });
    arrIds = [...new Set(arrIds)];
  }
  arrIds = arrIds.filter((id) => !actualList.find((el) => el.id === id));
  const vectorIds = [];
  do {
    vectorIds.push(arrIds.splice(0, 10));
  } while (arrIds.length > 0);
  return vectorIds;
};

export const arrPromisesResolved = async function ({
  arrPromises = [],
  withRef = false,
}) {
  const result = [];
  await Promise.all(arrPromises)
    .then((data) => {
      data.forEach((snapshot) => {
        const dataResolve = snapshot.docs.map((doc) => {
          if (withRef) {
            return { ...doc.data(), ref: doc.ref };
          }
          return doc.data();
        });
        result.push(...dataResolve);
      });
      return result;
    })
    .catch((error) => console.log("PROMISSE TO GET POs DB...", error));
  return result;
};

export const getOrderByIds = async ({ list, collection, companyId }) => {
  const vectorIds = [];
  do {
    vectorIds.push(list.splice(0, 10));
  } while (list.length > 0);

  let arrPromises = [];
  vectorIds.forEach((idsList) => {
    if (idsList.length > 0) {
      const promiseOrder = getDocs(
        query(
          collectionFirestore(
            firestore,
            `${dbTables.COMPANIES}/${companyId}/${collection}`
          ),
          where("id", "in", idsList),
          where("status", "!=", SALES_ORDER_STATUS.VOIDED)
        )
      );
      arrPromises.push(promiseOrder);
    }
  });
  const arrResolved = await arrPromisesResolved({
    arrPromises,
  });
  return arrResolved;
};

export const QUERY_PARAMS_SCOPE = {
  SHIPMENT: "SHIPMENT",
  PURCHASE_ORDER: "PURCHASE_ORDER",
  SALES_ORDER: "SALES_ORDER",
};

export async function onChangeQueryParamsv2({
  scope = "",
  shipmentId = "",
  purchaseOrderId = "",
  salesOrderId = "",
  queryParams = {},
  companyId = "",
  navigate = () => {},
  poRedirect,
}) {
  if (scope === QUERY_PARAMS_SCOPE.SHIPMENT) {
    navigate(
      getDashboardSearchPath(
        {
          ...queryParams,
          shipmentId,
        },
        { replace: true }
      )
    );
  } else if (scope === QUERY_PARAMS_SCOPE.PURCHASE_ORDER) {
    if (poRedirect) {
      const shipmentsIds = poRedirect.shipmentIds || [];
      const currentShipmentId = queryParams.shipmentId || "";
      navigate(
        getDashboardSearchPath({
          ...queryParams,
          purchaseOrderId: poRedirect.id,
          shipmentId: shipmentsIds.includes(currentShipmentId)
            ? currentShipmentId
            : shipmentsIds[0],
        }),
        { replace: true }
      );
    } else {
      const shipmentsByPOSnap = await getDocs(
        query(
          collection(
            firestore,
            `${dbTables.COMPANIES}/${companyId}/${dbTables.SHIPMENTS}`
          ),
          where("purchaseOrderIds", "array-contains", purchaseOrderId)
        )
      );
      const shipmentsByPO = shipmentsByPOSnap.docs
        .map((doc) => doc.data())
        .sort(sortObjectsBy("number", false));
      const shipmentId = shipmentsByPO[0] ? shipmentsByPO[0].id : "";
      navigate(
        getDashboardSearchPath({
          ...queryParams,
          purchaseOrderId,
          shipmentId,
        }),
        { replace: true }
      );
    }
  } else if (scope === QUERY_PARAMS_SCOPE.SALES_ORDER) {
    const salesOrderSnap = await getDoc(
      doc(
        firestore,
        `${dbTables.COMPANIES}/${companyId}/${dbTables.SALES_ORDERS}/${salesOrderId}`
      )
    );
    const salesOrder = salesOrderSnap.data();
    const poIds = salesOrder.purchaseOrderIds || [];
    const newPOid = !poIds.includes(queryParams.purchaseOrderId)
      ? [poIds[0] || ""]
      : queryParams.purchaseOrderId;

    const shipmentsByPOSnap = await getDocs(
      query(
        collection(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${dbTables.SHIPMENTS}`
        ),
        where("purchaseOrderIds", "array-contains", newPOid)
      )
    );
    console.log("SHIPMENTS BY PO ", shipmentsByPOSnap.size);
    const shipmentsByPO = shipmentsByPOSnap.docs
      .map((doc) => doc.data())
      .sort(sortObjectsBy("number", false));
    const shipmentId = shipmentsByPO[0] ? shipmentsByPO[0].id : "";
    console.log("ON CHANGE QUERY PARAMS V2 ", shipmentId, shipmentsByPO);
    //

    navigate(
      getDashboardSearchPath({
        ...queryParams,
        salesOrderId: salesOrder.id,
        purchaseOrderId: newPOid,
        shipmentId,
      }),
      { replace: true }
    );
  }
}

export function getAllMentions({
  salesOrder = {},
  purchaseOrders = [],
  shipments = [],
  userId = "",
  factories = [],
}) {
  let totalMentions = 0;
  let salesOrderMention = {};
  if (salesOrder.userMentions && salesOrder.userMentions[userId]) {
    salesOrderMention = {
      id: salesOrder.id,
      number: salesOrder.number,
      quantity: salesOrder.userMentions[userId],
    };
    totalMentions += salesOrder.userMentions[userId];
  }
  const POsMention = [];
  purchaseOrders.forEach((po) => {
    if (po.userMentions && po.userMentions[userId]) {
      POsMention.push({
        id: po.id,
        quantity: po.userMentions[userId] || 0,
        number: po.number,
        factoryName: getEntityByField({
          itemList: factories,
          itemId: po.factoryId,
          field: "name",
        }),
        shipmentIds: po.shipmentIds,
      });
      totalMentions += po.userMentions[userId];
    }
  });
  const shipmentsMention = [];

  shipments.forEach((shipment) => {
    if (shipment.userMentions && shipment.userMentions[userId]) {
      shipmentsMention.push({
        id: shipment.id,
        quantity: shipment.userMentions[userId] || 0,
        number: shipment.number,
        purchaseOrderId:
          purchaseOrders.find((po) => po.shipmentIds.includes(shipment.id)) &&
          purchaseOrders.find((po) => po.shipmentIds.includes(shipment.id)).id,
      });
      totalMentions += shipment.userMentions[userId];
    }
  });

  return {
    salesOrderMention,
    POsMention,
    shipmentsMention,
    totalMentions,
  };
}

export const MENTION_POPOVER_SCOPES = {
  LIST_VIEW: "LIST_VIEW",
  ORDER_DASHBOARD: "ORDER_DASHBOARD",
};

export const SEARCH_BOX_TYPES = {
  SKU_TABLE: "SKU_TABLE",
  NOTES: "NOTES",
  DOCUMENTS: "DOCUMENTS",
  NOTE_DOCUMENTS: "NOTE_DOCUMENTS",
  BASIC: "BASIC",
};

export function updateMention({
  mention = {},
  toClean = false,
  parentDocumentId = "",
}) {
  if (toClean) {
    updateDoc(mention.ref, {
      [parentDocumentId]: FieldValue.delete(),
    });
  } else if (mention[parentDocumentId] > 0) {
    updateDoc(mention.ref, {
      [parentDocumentId]: FieldValue.increment(1),
    });
  } else if (!mention[parentDocumentId]) {
    updateDoc(mention.ref, {
      [parentDocumentId]: 1,
    });
  }
}
