import React from "react";
import { useSelector } from "react-redux";
import NavMenuItem from "./NavMenuItem";
import AlertTriangleMenu from "./AlertTriangleMenu";
import { isChrome } from "../../helpers/helpers";
import { List } from "@mui/material";
import SearchButtonDrawer from "./SearchButtonDrawer";

function SidebarContent() {
  const { sidebarMenus } = useSelector((state) => state.sidebar) || [];
  const isBrowserChrome = isChrome();
  return (
    <nav
      style={{
        flex: 1,
        paddingTop: 15,
        marginBottom: 10,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <List className="rct-mainMenu p-0 m-0 list-unstyled" style={{ flex: 1 }}>
        {sidebarMenus.menu_items.map((menu, key) => (
          <NavMenuItem
            onClick={(e) => {
              e.stopPropagation();
            }}
            menu={menu}
            key={key}
            onToggleMenu={() => {}}
          />
        ))}
        {!isBrowserChrome && <AlertTriangleMenu />}
      </List>

      <SearchButtonDrawer />
    </nav>
  );
}

export default SidebarContent;
