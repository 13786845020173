import moment from "moment";
import { getRandomId } from "../../helpers/helpers";
import { typeOfTask } from "../types/dbTables";

export default class AdHocTask {
  constructor({
    id = getRandomId(),
    creationDate = moment().valueOf(),
    assignedTo = "",
    finishDate = moment().valueOf(),
    startDate = moment()
      .startOf("day")
      .valueOf(),
    stage = "",
    description = "",
    complete = false,
    listIndex = 0,
    inactive = false,
    shipmentId = "",
    type = typeOfTask.SALES_ORDER,
  }) {
    this.id = id;
    this.assignedTo = assignedTo;
    this.duration =
      Math.abs(
        moment(startDate)
          .startOf("day")
          .diff(moment(finishDate).startOf("day"), "days")
      ) + 1;
    this.stage = stage;
    this.description = description;
    this.startDate = startDate;
    this.finishDate = finishDate;
    this.complete = complete;
    this.listIndex = listIndex;
    this.creationDate = creationDate;
    this.inactive = inactive;
    this.shipmentId = shipmentId;
    this.type = type;
  }
}
