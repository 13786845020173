import React, { useState } from "react";
import { getSpreadSheetByName } from "../../helpers/helpers";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleSignInButton from "./GoogleSignInButton";

function SpreadSheetOptions({
  handleShowMessage,
  handleSuccessGoogleOAuthLogged,
  setAccessToken,
  setSheetID,
}) {
  const [spreadSheetOptions, setSpreadSheetOptions] = useState([]);

  const responseGoogle = async (response) => {
    console.log(response);
    const { access_token } = response;
    const sheetID = "1roC3odmeSz6bCiDsmK1X7LGom4ZPtZa74PGFTRFxTGo";
    const spreadSheetOptions = await getSpreadSheetByName(
      sheetID,
      access_token,
      "Sheet1"
    );
    console.log("-----responseGoogle", spreadSheetOptions);

    if (
      spreadSheetOptions.error &&
      Object.keys(spreadSheetOptions.error).length > 0
    ) {
      setSpreadSheetOptions([]);
      handleShowMessage(spreadSheetOptions.error.message);
      return;
    } else {
      handleSuccessGoogleOAuthLogged();
    }
    const { values } = spreadSheetOptions;
    const spreadSheetOptionsArr = [];
    values.forEach((element, index) => {
      if (index === 0) return;
      if (element[0]) {
        spreadSheetOptionsArr.push({ name: element[0], id: element[1] || "" });
      }
    });
    setAccessToken(access_token);
    setSpreadSheetOptions(spreadSheetOptionsArr);
  };

  return (
    <div>
      <div
        style={{
          padding: "8px 28px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <GoogleOAuthProvider clientId="725773308876-3d2dn58b4m3o8h16ak0utc3d2odcoq1u.apps.googleusercontent.com">
          <GoogleSignInButton
            onSuccess={responseGoogle}
            onError={responseGoogle}
          />
        </GoogleOAuthProvider>
        <div className="spreadsheet-options-container">
          <select
            onChange={(ev) => setSheetID(ev.target.value)}
            style={{ height: 42, borderRadius: 3 }}
          >
            <option value="">Select spreadsheet</option>
            {spreadSheetOptions.map((spreadSheet, index) => {
              return (
                <option
                  key={spreadSheet.id + index}
                  value={spreadSheet.id}
                  disabled={!spreadSheet.id}
                >
                  {spreadSheet.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </div>
  );
}

export default SpreadSheetOptions;
