import React, { useCallback, useEffect, useRef, useState } from "react";
import { doc, setDoc, updateDoc, writeBatch } from "firebase/firestore";
import { cx } from "@emotion/css";
import FolderDocument from "../../api/model/FolderDocument";
import {
  BACKDROP_TYPE,
  REPORTS_TYPE,
  TYPE_OF_FILE,
  errorDocumentName,
} from "../../helpers/constants";
import { canDrop, updateDocumentPermissions } from "../ReportsTree/helpers";
import { CopyLinkSnackbarStyled, ListItemDocumentStyled } from "./styles";
import { Grow } from "@mui/material";
import DocumentInfo from "./DocumentInfo";
import {
  checkFolderContents,
  getDocumentId,
  getParentRoot,
  getTittleUploadModal,
  getWarningMessage,
  hasExtension,
  processFiles,
  processFolder,
  verifyFolderDuplicateNames,
} from "../Documents/DocumentHelper";
import AttentionModal from "../Modal/AttentionModal";
import ModalUploadingFile from "../Documents/ModalUploadingFile";
import { dbTables } from "../../api/types/dbTables";
import { useCompanyId, useQueryParams } from "../../hooks";
import UploadFiles from "../Documents/UploadFiles";
import ModalCreateFolderDocument from "../Documents/ModalCreateFolderDocument";
import CreateNewFolder from "../Documents/CreateNewFolder";
import {
  addGenericDataToFirestore,
  setGlobalBackdrop,
} from "../../actions/DataActions";
import { useDispatch } from "react-redux";
import { DOCUMENT_TRIGGER_TYPES } from "../../helpers/purchaseOrder";
import { getDashboardSearchPath } from "../../helpers/helpers";
import {
  canSeeThreeDotMenu,
  createActivityForMovingDocuments,
  getDocumentPathv2,
  getDocumentScope,
  getDuplicatedNamesInDestination,
  isMultiSelectEnabled,
} from "../../helpers/documentsv2.helper";
import { firestore } from "../../firebase";
import MenuOptions from "./MenuOptions";
import { useNavigate } from "react-router-dom";
import { orderType } from "../../helpers/salesOrder";
import { useBackdropState } from "../../hooks/user";
import { colors } from "../../assets/jss/variables";
import { CopyLinkIcon } from "../../helpers/iconSvgPath";
import CustomCheckbox from "../Inputs/CustomCheckbox";
import { now } from "moment";
import { useDropzone } from "react-dropzone";

const backgroundColor = {
  [orderType.QUOTE]: colors.salesOrderBackgroundColor,
  [orderType.SALES_ORDER]: colors.salesOrderBackgroundColor,
  [orderType.PURCHASE_ORDER]: colors.purchaseOrderBackgroundColor,
  [orderType.PURCHASE_QUOTE]: colors.purchaseOrderBackgroundColor,
  [orderType.SHIPMENT]: colors.shipmentBadge,
};

function DocumentItem({
  currentDocument,
  selected,
  setSelected,
  documents,
  draggingTo,
  setDraggingTo,
  onOpenFolder = () => {},
  currentUser = {},
  users = [],
  permissionGroupsDB,
  dbCollection = "",
  parentCollection = "",
  parentDocumentId = "",
  mainEntity = {},
  isVoided = false,
  isReadOnly = false,
  parentDocument,
  isPlaceholder = false,
  onSelected = () => {},
  onModalAttention = () => {},
  sortBy = "",
}) {
  const companyId = useCompanyId();
  const [hoverDrag, setHoverDrag] = useState("");
  const backdropState = useBackdropState();
  const [openAccessGroup, setOpenAccesGroup] = useState(false);
  const [openNewFolder, setOpenNewFolder] = useState(false);
  const [snackOption, setSnackOption] = useState({});
  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const queryParams = useQueryParams();
  const [signClear, setSignClear] = useState(false);
  const Navigate = useNavigate();
  const fileUploaderRef = useRef(null);
  const dispatch = useDispatch();
  const [openModalFile, setOpenModalFile] = useState();
  const setBackdrop = useCallback((id) =>
    setGlobalBackdrop({
      id,
      type: BACKDROP_TYPE.FOLDER,
      isOpen: true,
    })(dispatch)
  );
  const [attentionModal, setAttentionModal] = useState({
    open: false,
    description: "",
    action: () => {},
    confirmationText: "",
    cancelText: "",
  });
  const [editing, setEditing] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const documentPath = `${dbTables.COMPANIES}/${companyId}/${parentCollection}/${parentDocumentId}/${dbCollection}`;
  const addDocument = useCallback((newDocument) =>
    addGenericDataToFirestore(
      [...documentPath.split("/"), newDocument.id],
      newDocument
    )(dispatch)
  );

  useEffect(() => {
    if (hoverDrag) {
      setHoverDrag(false);
    }
  }, [selected]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      handleOnDrop(acceptedFiles);
    },
    [currentDocument]
  );

  useEffect(() => {
    if (signClear) {
      Navigate(
        getDashboardSearchPath({
          ...queryParams,
          fileId: false,
        }),
        { replace: true }
      );
      setSignClear(false);
    }
  }, [signClear]);

  const openParentFolder = (parentId) => {
    if (!parentId) {
      return;
    }
    const parentDocument = documents.find((doc) => doc.id === parentId);
    if (parentDocument) {
      onOpenFolder(parentDocument.id, true);
      openParentFolder(parentDocument.parentId);
    }
  };

  useEffect(() => {
    if (queryParams.fileId) {
      const [, id, version] = queryParams.fileId.split("_");
      if (currentDocument.id.includes(id)) {
        if (
          version &&
          `V${currentDocument.version}` !== version &&
          !currentDocument.isVersion
        ) {
          onOpenFolder(currentDocument.id, true);
          return;
        }
        if (`V${currentDocument.version}` === version || !version) {
          openParentFolder(currentDocument.parentId);
          setTimeout(() => addBackdrop(), 400);
          setTimeout(() => setSignClear(true), 600);
        }
      }
    }
  }, [queryParams.fileId]);

  const addBackdrop = () => {
    const documentId = getDocumentId(currentDocument);
    const elementId = `document-item_${documentId}`;
    setTimeout(() => {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      setBackdrop(documentId);
    }, 600);
  };

  function handleDragStart(ev) {
    if (currentDocument.isVersion || currentDocument.placeholder) {
      return;
    }
    if (
      !selected.documents.includes(currentDocument.id) &&
      !currentDocument.isVersion
    ) {
      setSelected({
        parentId: currentDocument.parentId,
        documents: [currentDocument.id],
        hiddenMenu: !selected.parentId,
      });
    }
    ev.stopPropagation();
  }

  function handleDragEnd(e) {
    if (selected.documents.length === 0) {
      return;
    }
    setHoverDrag(false);
    if (
      !draggingTo ||
      draggingTo.id === currentDocument.id ||
      draggingTo.id === currentDocument.parentId ||
      draggingTo.type !== REPORTS_TYPE.FOLDER ||
      !canDrop({
        documents: documents,
        originId: currentDocument.id,
        destinationId: draggingTo.id,
      })
    ) {
      setSelected({
        parentId: null,
        documents: [],
      });
      return;
    }

    let documentList = [];
    let folderList = [];
    const { isDuplicatedName, attentionModalDescription } =
      getDuplicatedNamesInDestination({
        documentsInTheSameLevel: documents.filter(
          (doc) => doc.parentId === draggingTo.id
        ),
        documentsToMove: documents.filter((currentDocument) =>
          selected.documents.includes(currentDocument.id)
        ),
      });
    if (isDuplicatedName) {
      setAttentionModal({
        open: true,
        description: attentionModalDescription,
        action: () => {},
        confirmationText: "Ok",
        cancelText: "",
      });
      setSelected({
        parentId: null,
        documents: [],
      });
      return;
    }
    checkPendingPlaceholder({
      rootDocument: getParentRoot({
        currentDocument: draggingTo,
        documents,
      }),
      documents,
    });
    documents.forEach((docItem) => {
      if (selected.documents.includes(docItem.id)) {
        if (docItem.type !== REPORTS_TYPE.FOLDER) {
          documentList.push(docItem);
        } else {
          folderList.push(docItem);
        }
        updateDocumentPermissions({
          currentUser,
          permissionGroups: draggingTo.permissionGroups,
          permissionGroupsDB,
          permissionUsers: draggingTo.permissionUsers,
          currentDocument: docItem,
          documents: documents,
          updateDoc,
          users,
          parentId: draggingTo.id,
          clonePermission: true,
          userId: currentUser.id,
        });
      }
    });
    // MAKE AN ACTIVITY ENTRY FOR ALL MOVED DOCUMENTS
    const parentDoc = documents.find((doc) => doc.id === selected.parentId);
    let oldPath = "top level";
    let currentPath = getDocumentPathv2({
      currentDocument: draggingTo,
      documents: documents,
    });
    if (parentDoc) {
      oldPath = getDocumentPathv2({
        currentDocument: parentDoc,
        documents: documents,
      });
    }
    const selectedDocuments = documents.filter((currentDocument) =>
      selected.documents.includes(currentDocument.id)
    );

    createActivityForMovingDocuments({
      documents: selectedDocuments,
      scope: getDocumentScope({ parentCollection, mainEntity }),
      oldPath,
      currentPath,
      companyId,
      userId: currentUser.id,
      parentCollection,
      parentDocumentId,
      doc,
      setDoc,
      companyUsers: users,
      mainEntity,
    });
    //
    const hasFolder = selectedDocuments.some(
      (doc) => doc.type === TYPE_OF_FILE.FOLDER
    );
    const hasFile = selectedDocuments.some(
      (doc) => doc.type !== TYPE_OF_FILE.FOLDER
    );
    let description = "";
    if (hasFolder) {
      description = (
        <React.Fragment>
          Moving folder(s) to a different folder <br />
          will assign it that folder's access permission
        </React.Fragment>
      );
    } else if (hasFile) {
      description = (
        <React.Fragment>
          Succesfully moved to{" "}
          <strong>
            {getDocumentPathv2({
              currentDocument: draggingTo,
              documents: documents,
            })}
          </strong>
        </React.Fragment>
      );
    }
    onModalAttention({
      open: true,
      description,
      action: () => {},
      confirmationText: "Ok",
      cancelText: "",
    });
    setSelected({
      parentId: null,
      documents: [],
    });
    e.stopPropagation();
  }

  function handleDragEnter(e) {
    setDraggingTo(currentDocument);
    e.preventDefault();
    e.stopPropagation();
  }

  function handleDragOver(ev) {
    if (!currentDocument.isVersion && !hoverDrag) {
      setHoverDrag(true);
    }
    ev.preventDefault();
    ev.stopPropagation();
  }

  function getDocumentState({ currentDocument, selected }) {
    if (selected.documents.includes(currentDocument.id)) {
      return "SELECTED_" + currentDocument.scope;
    } else {
      return currentDocument.type === TYPE_OF_FILE.FOLDER
        ? "folderDocument_" + currentDocument.scope
        : currentDocument.scope;
    }
  }

  function handleMultiSelectMode({ currentDocument, isCancel = false }) {
    if (isCancel) {
      console.log("You can not select out of the context");
      setSelected({
        parentId: null,
        documents: [],
      });
      return;
    } else {
      setSelected({
        parentId: currentDocument.id,
        documents: [],
      });
      onOpenFolder(currentDocument.id, true);
    }
  }

  function handleClickDocumentItem({ selected = {}, currentDocument = {} }) {
    if (
      currentDocument.placeholder &&
      currentDocument.type !== TYPE_OF_FILE.FOLDER
    ) {
      uploadFileByModal();
      return;
    }
    if (selected.parentId) {
      if (
        selected.parentId === currentDocument.id ||
        selected.parentId === currentDocument.parentId
      ) {
        handleMultiSelect({ selected });
      } else {
        console.log("You can not select out of the context");
        setSelected({
          parentId: null,
          documents: [],
        });
        return;
      }
    } else {
      onOpenFolder(currentDocument.id);
    }
  }

  function getIsAllSelected({ selected = {} }) {
    if (
      currentDocument?.type === TYPE_OF_FILE.FOLDER &&
      currentDocument.child?.length > 0
    ) {
      const isAllSelected = currentDocument.child.every((el) =>
        selected.documents.includes(el.id)
      );
      return isAllSelected;
    }
    return false;
  }

  function handleMultiSelect({ selected = {} }) {
    if (selected.parentId === currentDocument.id) {
      const isAllSelected = getIsAllSelected({ selected });
      if (isAllSelected) {
        setSelected({
          parentId: currentDocument.id,
          documents: [],
        });
      } else {
        setSelected({
          parentId: currentDocument.id,
          documents: [...currentDocument.child.map((el) => el.id)],
        });
      }
    } else if (selected.documents.includes(currentDocument.id)) {
      setSelected({
        parentId: currentDocument.parentId,
        documents: selected.documents.filter((id) => id !== currentDocument.id),
      });
    } else {
      setSelected({
        parentId: currentDocument.parentId,
        documents: [...selected.documents, currentDocument.id],
      });
    }
  }

  function handleOnDrop(files) {
    setHoverDrag(false);
    if (selected.documents.length > 0) {
      return;
    }
    if (files.length === 0 || currentDocument.isVersion) {
      return;
    }
    if (files.length > 1 && currentDocument.type !== TYPE_OF_FILE.FOLDER) {
      setAttentionModal({
        open: true,
        description: (
          <React.Fragment>
            Only a single file can be added as a new version to an existing file
          </React.Fragment>
        ),
        action: () => {
          setFiles([]);
          setFolders([]);
        },
        confirmationText: "Ok",
        cancelText: "",
      });
      return;
    }
    const filesUpload = files.filter((file) => hasExtension(file.name));
    if (filesUpload.length === 0) {
      setAttentionModal({
        open: true,
        description: (
          <React.Fragment>
            Only files with an extension at the end can be uploaded. <br />{" "}
            Nothing was uploaded in this case.
          </React.Fragment>
        ),
        action: () => {
          setFiles([]);
          setFolders([]);
        },
        confirmationText: "Ok",
        cancelText: "",
      });
      return;
    }
    if (filesUpload.length !== files.length) {
      setSnackOption({
        type: "warning",
        message:
          "Only files with extensions are being uploaded, the others will be skipped",
      });
    }
    onOpenFolder(currentDocument.id, true);

    const sortingFiles = [...filesUpload].sort((a, b) =>
      b.name.localeCompare(a.name)
    );
    const foldersData = processFolder({
      filesUpload: sortingFiles,
      currentFiles: documents.filter(
        (childDocument) => childDocument.parentId === parentDocument.id
      ),
      documentPath: documentPath,
      currentUser,
      currentDocument,
      uploadingInFile: currentDocument.type !== TYPE_OF_FILE.FOLDER,
    });

    const { filesData, originalFiles } = processFiles({
      filesUpload: sortingFiles,
      currentFiles: documents.filter(
        (childDocument) => childDocument.parentId === parentDocument.id
      ),
      documentPath: documentPath,
      uploadingInFile: currentDocument.type !== TYPE_OF_FILE.FOLDER,
      currentDocument,
      foldersData,
    });
    if (!filesData.length) {
      setAttentionModal({
        open: true,
        description: errorDocumentName.file,
        action: () => {
          setFiles([]);
          setFolders([]);
        },
        confirmationText: "Ok",
        cancelText: "",
      });
      return;
    }
    setFiles({ filesData, originalFiles });
    setFolders(foldersData);
    setOpenModalFile(true);
    return;
  }

  const uploadFileByModal = () => {
    fileUploaderRef?.current?.click();
  };

  const handleSaveFolder = (name) => {
    addDocument({
      ...new FolderDocument({
        name: verifyFolderDuplicateNames(
          name.trim() || "New Folder",
          documents.filter((doc) => doc.parentId === currentDocument.id)
        ),
        permissionUsers: currentDocument.permissionUsers,
        permissionGroups: currentDocument.permissionGroups,
        parentId: currentDocument.id || "",
        createdBy: currentUser.id,
        created: now(),
        mainFile: true,
        currentPath:
          getDocumentPathv2({
            currentDocument,
            documents: documents,
          }) + "/New Folder",
        triggerType: DOCUMENT_TRIGGER_TYPES.CREATE_FOLDER,
      }),
    });
    if (isPlaceholder) {
      checkPendingPlaceholder({
        rootDocument: getParentRoot({ currentDocument, documents }),
        documents,
      });
    }
    setOpenNewFolder(false);
  };

  const folderColor =
    currentDocument.type === TYPE_OF_FILE.FOLDER &&
    backgroundColor[currentDocument.scope];

  const checkPendingPlaceholder = ({ rootDocument, documents }) => {
    if (!rootDocument) {
      return;
    }
    const { hasPlaceholderFile, childFoldersPending } = checkFolderContents({
      currentDocument: rootDocument,
      documents:
        currentDocument.type !== TYPE_OF_FILE.FOLDER
          ? documents.filter((doc) => doc.id !== currentDocument.id)
          : documents,
    });

    const batch = writeBatch(firestore);

    childFoldersPending.forEach((folderPending) => {
      if (hasPlaceholderFile) {
        if (folderPending.id !== rootDocument.id) {
          batch.update(folderPending.ref, {
            placeholder: false,
            triggerType: DOCUMENT_TRIGGER_TYPES.DISMISS_TRIGGER,
          });
        }
      } else {
        batch.update(folderPending.ref, {
          placeholder: false,
          triggerType: DOCUMENT_TRIGGER_TYPES.DISMISS_TRIGGER,
        });
      }
    });
    batch.commit().finally();
  };

  const { getRootProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
  });
  return (
    <>
      {!!attentionModal.open && (
        <AttentionModal
          isOpen={!!attentionModal.open}
          title="Attention"
          description={attentionModal.description}
          onClick={() => {
            attentionModal.action();
            setAttentionModal({
              open: false,
              description: "",
              action: () => {},
              confirmationText: "",
              cancelText: "",
            });
          }}
          onClose={() => {
            setAttentionModal({
              open: false,
              description: "",
              action: () => {},
              confirmationText: "",
              cancelText: "",
            });
          }}
          cancellable={!!attentionModal.cancellable}
          cancelText={attentionModal.cancelText}
          confirmationText={attentionModal.confirmationText}
        />
      )}
      {openAccessGroup && (
        <ModalCreateFolderDocument
          open={openAccessGroup}
          title="Folder Permissions"
          orderPath={documentPath}
          isNew={false}
          documentTree={documents.filter(
            (doc) => doc.type === TYPE_OF_FILE.FOLDER
          )}
          handleClose={() => {
            setOpenAccesGroup(false);
          }}
          folderData={currentDocument}
          parentDocument={documents.find(
            (doc) => doc.id === currentDocument.parentId
          )}
        />
      )}
      {openModalFile && (
        <ModalUploadingFile
          title={getTittleUploadModal({
            files: files.originalFiles,
            folders,
            currentDocument,
          })}
          open={openModalFile}
          folders={folders}
          onClose={() => {
            setAttentionModal({
              open: false,
              description: "",
            });
            setFiles([]);
            setFolders([]);
            setOpenModalFile(false);
          }}
          onCompleteUpload={() => {
            if (isPlaceholder) {
              setTimeout(() => {
                checkPendingPlaceholder({
                  rootDocument: getParentRoot({
                    currentDocument,
                    documents,
                  }),
                  documents,
                });
              }, 500);
            }
          }}
          files={files}
          warningMessage={getWarningMessage(snackOption)}
          companyUsers={users}
          permissionGroupsDB={permissionGroupsDB}
          currentUserId={currentUser.id}
          userId={currentUser.id}
          orderPath={documentPath}
          parentCollection={parentCollection}
          parentDocument={parentDocument}
          allDocuments={documents}
          parentDocumentId={parentDocumentId}
          currentDocument={
            currentDocument.type !== TYPE_OF_FILE.FOLDER ? currentDocument : ""
          }
          mainEntity={mainEntity}
        />
      )}
      {openSnackBar && (
        <CopyLinkSnackbarStyled
          className={"snackbarCopyLinkContainer"}
          open={openSnackBar}
          onClose={() => setOpenSnackBar(false)}
          TransitionComponent={Grow}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          autoHideDuration={1500}
          message={
            <div className="snackbar-copy-link-content">
              <CopyLinkIcon svgClass="img-options-icons" color="#FFF" />
              <span id="message-id">Link Copied</span>
            </div>
          }
        />
      )}
      <UploadFiles
        handleFiles={handleOnDrop}
        id={`UploadFiles${currentDocument.id}`}
        onRef={fileUploaderRef}
        key={`UploadFiles${currentDocument.id}`}
        multipleUpload={currentDocument.type === TYPE_OF_FILE.FOLDER}
      />
      <ListItemDocumentStyled
        draggable={!currentDocument.isVersion && !currentDocument.placeholder}
        className={cx("listItemDocumentContainer", {
          hoverDrag: hoverDrag,
        })}
        id={`document-item_${getDocumentId(currentDocument)}`}
        style={
          backdropState.id === getDocumentId(currentDocument)
            ? {
                zIndex: 1202,
                position: "relative",
                backgroundColor: folderColor,
                borderRadius: 4,
              }
            : {}
        }
        {...getRootProps()}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={() => setHoverDrag(false)}
        onClick={(ev) => {
          handleClickDocumentItem({
            selected,
            currentDocument,
          });
          ev.stopPropagation();
        }}
      >
        {isMultiSelectEnabled({
          selected,
          currentDocument,
        }) && (
          <CustomCheckbox
            className={
              selected.parentId === currentDocument.id
                ? "checkbox-folder-root"
                : "document-child-checkbox"
            }
            checked={
              selected.documents.includes(currentDocument.id) ||
              getIsAllSelected({ selected })
            }
            onChange={(ev) => {
              handleMultiSelect({
                selected,
              });
              ev.stopPropagation();
            }}
          />
        )}

        <div
          className={cx("listemItemDocument", {
            [getDocumentState({ currentDocument, selected })]: true,
          })}
          onClick={(ev) => {
            handleClickDocumentItem({
              selected,
              currentDocument,
            });
            ev.stopPropagation();
          }}
        >
          <DocumentInfo
            currentDocument={currentDocument}
            allDocuments={documents}
            editing={editing}
            setEditing={setEditing}
            userId={currentUser.id}
            selected={selected}
            sortBy={sortBy}
            isMultiSelectEnabled={isMultiSelectEnabled({
              selected,
              currentDocument,
            })}
          />
        </div>
        {canSeeThreeDotMenu({ currentDocument, selected, isVoided }) && (
          <MenuOptions
            currentDocument={currentDocument}
            isReadOnly={isReadOnly}
            dbCollection={dbCollection}
            parentCollection={parentCollection}
            parentDocumentId={parentDocumentId}
            mainEntity={mainEntity}
            isVoided={isVoided}
            onMultiSelectMode={handleMultiSelectMode}
            selected={selected}
            onRename={() => {
              setEditing(true);
            }}
            allDocuments={documents}
            onUploadFiles={uploadFileByModal}
            onAddNewFolder={() => {
              setOpenNewFolder(true);
              onOpenFolder(currentDocument.id, true);
            }}
            onPermission={() => setOpenAccesGroup(true)}
            onUploadNewVersionFile={uploadFileByModal}
            onSelected={onSelected}
            onModalAttention={onModalAttention}
            setOpenSnackBar={setOpenSnackBar}
          />
        )}
      </ListItemDocumentStyled>
      {openNewFolder && (
        <CreateNewFolder
          folderObj={{ ...new FolderDocument({}) }}
          handleSaveName={(name) => handleSaveFolder(name)}
          handleClose={() => setOpenNewFolder(false)}
        />
      )}
    </>
  );
}

export default DocumentItem;
