import React, { useCallback, useContext, useRef, useState } from "react";
import {
  getCorrectTimezone,
  taskCompletionVerifier,
} from "../../helpers/ganttChart";
import {
  GENERAL_PERMISSION_VALUE,
  TASK_TYPE,
  taskStatus,
} from "../../helpers/constants";
import {
  DueLateDiamond,
  moveStartFinishDate,
} from "../../container/TaskListScreen/TaskScreenHelper";
import CompletedTaskBadge from "./CompletedTaskBadge";
import TooltipTD from "../Tooltip/TooltipTD";
import { cx } from "@emotion/css";
import { getUserAvatar } from "../../helpers/users";
import {
  debounce,
  getCompanyUserDisplayName,
  sortObjectsBy,
} from "../../helpers/helpers";
import UserPicker from "../DatePicker/UserPicker";
import { typeOfTask } from "../../api/types/dbTables";
import CustomCheckbox from "../Inputs/CustomCheckbox";
import moment from "moment";
import DatePicker from "../DatePicker/DatePicker";
import { actionType } from "../../helpers/timelineModal";
import { TASK_FIELDS, triggerTaskTypes } from "../../helpers/tasks";
import { TaskDateField } from "./TaskDateField";
import SimpleEditableNumber from "../TextFields/SimpleEditableNumber";
import {
  convertHex,
  getPredecesorTask,
  getTaskReference,
} from "../../helpers/timelineCalendar";
import { GANTT_CHART_ACTION, GanttChartContext } from "./GanttChartContext";
import { shipmentBadgeClass } from "../../container/StyledComponent/BadgeStyled";
import { getShipmentBadge } from "../../helpers/shipments";
import { ArrowDownIcon } from "../../helpers/iconSvgPath";
import { KeyboardTab } from "@mui/icons-material";

function TaskItemData({
  task,
  companyUsers,
  isAllowed,
  TaksCompleteData,
  onChangeTask = () => {},
  currentUser,
  alltasks,
  onMoveRemainingTasks,
  handleOpenModalAttention,
  addHoverUser,
  removerHoverUser,
  isCollapse,
}) {
  const dateStartRef = useRef(null);
  const dateFinishRef = useRef(null);
  const [openUserPicker, setOpenUserPicker] = useState(false);
  const [datePickerData, setDatePickerData] = useState({
    open: false,
    date: "",
    ref: null,
  });
  const { ganttChartState, dispatchGanttChart } = useContext(GanttChartContext);
  const { highlightedTask, currentShipments, graphTableRef } = ganttChartState;
  const userRef = useRef(null);
  const isTaskCompleted = task.status === taskStatus.COMPLETE;
  const canReassignUser =
    isAllowed(GENERAL_PERMISSION_VALUE.REASSIGN_TASK) && !isTaskCompleted;

  const onDebounceHover = useCallback(
    debounce(() => {
      dispatchGanttChart({
        type: GANTT_CHART_ACTION.COMMON,
        payload: {
          highlightedTask: {
            purchaseOrderId: task.purchaseOrderId || "",
            shipmentId: task.shipmentId || "",
          },
        },
      });
    }, 1000),
    []
  );

  const resetColor = () => {
    onDebounceHover.cancel();
    dispatchGanttChart({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        highlightedTask: {},
      },
    });
  };

  const onCloseDate = () =>
    setDatePickerData({
      date: "",
      open: false,
      ref: null,
    });

  const isBigGrid = TaksCompleteData;
  let remark = false;
  if (task.isAdHocTask) {
    remark = convertHex("#B9B3B3", 0.5);
  }
  if (
    highlightedTask?.ids?.includes(task.id) &&
    task.type === TASK_TYPE.PURCHASE_ORDER
  ) {
    remark = convertHex(task.color, 0.5);
  }
  return (
    <div
      className={cx("taskItem", `task${task.type}`, {
        regularGrid: !isBigGrid,
        bigGrid: isBigGrid,
        collapseListGrid: isCollapse,
      })}
      style={{ background: remark ? remark : "" }}
    >
      {isTaskCompleted && <div className="completedTaskContainer" />}
      {openUserPicker && (
        <UserPicker
          el={userRef.current}
          onChange={(userId, ev, blockPermission) => {
            if (blockPermission) {
              ev.preventDefault();
              ev.stopPropagation();
              return;
            }
            onChangeTask(
              {
                ...task,
                assignedTo: userId,
                lastAssignedTo: task.assignedTo,
              },
              null,
              actionType.CHANGED_ASSIGNED_TO
            );
            setOpenUserPicker(!openUserPicker);
          }}
          open={openUserPicker}
          onClose={() => {
            setOpenUserPicker(!openUserPicker);
          }}
          value={task.assignedTo}
          users={companyUsers
            .filter((user) => user.active)
            .sort(sortObjectsBy("displayName", false))}
          permissionToVerify={
            task.type === typeOfTask.PURCHASE_ORDER
              ? task.factoryId
              : task.customerId
          }
          isTherePermission={true}
          isCustomer={!task.factoryId ? true : false}
        />
      )}
      {datePickerData.open && (
        <DatePicker
          el={datePickerData.ref.current}
          onChange={(value) => {
            const { startDate, finishDate, diffDays } = moveStartFinishDate({
              newDate: value,
              task: {
                ...task,
                startDate: getCorrectTimezone({
                  date: task.startDate,
                }).valueOf(),
                finishDate: getCorrectTimezone({
                  date: task.finishDate,
                }).valueOf(),
              },
              isStartDate: datePickerData.date === "startDate",
            });
            if (diffDays === 0) {
              return;
            }
            onChangeTask(
              {
                ...task,
                startDate,
                finishDate,
              },
              diffDays,
              actionType.MOVED_TASK
            );
            return onCloseDate();
          }}
          open={datePickerData.open}
          onClose={() => onCloseDate()}
          value={moment(task[datePickerData.date])}
          cancelIcon={true}
          withFormat="MM/DD/YY"
        />
      )}
      <div
        className="iconBadge"
        style={{
          justifyContent: isCollapse ? "flex-end" : "",
        }}
      >
        {!isTaskCompleted && <DueLateDiamond item={task} />}
        {isTaskCompleted && !isCollapse && (
          <CompletedTaskBadge
            task={task}
            onMoveRemainingTasks={(task) => {
              if (!isAllowed(GENERAL_PERMISSION_VALUE.MODIFY_DUE_DATES)) {
                handleOpenModalAttention();
              } else {
                onMoveRemainingTasks(task);
              }
            }}
          />
        )}
      </div>
      {!isCollapse && (
        <CustomCheckbox
          styles={{
            padding: 0,
            opacity: isTaskCompleted ? 0.55 : 1,
          }}
          color={"#757575"}
          className={"grayColor"}
          checked={task.complete}
          iconSize={18}
          disabled={task.complete}
          onClick={() => {
            if (!isTaskCompleted) {
              const dayOffset = moment()
                .startOf("day")
                .diff(moment(task.finishDate).startOf("day"), "days");
              const { duration, finishDate, startDate } =
                taskCompletionVerifier({
                  task,
                });
              onChangeTask(
                {
                  ...task,
                  complete: true,
                  dayOffset,
                  completedBy: currentUser.id,
                  moved:
                    dayOffset !== 0
                      ? !alltasks.some(
                          (cTask) =>
                            cTask.dependency === task.id &&
                            cTask.status !== taskStatus.COMPLETE
                        )
                      : true,
                  duration,
                  finishDate,
                  startDate,
                  triggerType: triggerTaskTypes.COMPLETE,
                  templateDuration: task.duration,
                },
                false,
                actionType.COMPLETED_TASK
              );
            }
          }}
        />
      )}
      {isBigGrid && !isCollapse && <div>{getTaskReference({ task })}</div>}
      {!isCollapse && (
        <div
          className={"description"}
          id={task.description}
          style={{ opacity: isTaskCompleted ? 0.55 : 1 }}
        >
          {task.shipmentId &&
            getShipmentBadge({
              shipment: currentShipments.find(
                (shipment) => shipment.id === task.shipmentId
              ),
              classes: shipmentBadgeClass.secondary,
            })}
          <TooltipTD label={task.description} className="tooltTipSpan">
            {task.description}
          </TooltipTD>
        </div>
      )}
      {isBigGrid && !isCollapse && (
        <div
          className="predecesorDependency"
          style={{
            justifyContent: "center",
          }}
        >
          {getPredecesorTask({
            task,
            predecesorTask: alltasks.find(
              (currentTask) => task.dependency === currentTask.id
            ),
          })}
        </div>
      )}
      {isBigGrid && !isCollapse && (
        <div
          className="predecesorDependency"
          style={{
            justifyContent: "center",
          }}
        >
          {task.dependencyType}
        </div>
      )}
      {isBigGrid && !isCollapse && (
        <SimpleEditableNumber
          number={task.duration}
          disable={isTaskCompleted}
          className={"timelineTaskEdit"}
          showEditIcon
          onSave={(ev) => {
            const duration = ev.target.value;
            const durationOffset = parseInt(duration) - parseInt(task.duration);
            if (durationOffset === 0) {
              return;
            }
            return onChangeTask(
              {
                ...task,
                duration: +duration,
                finishDate: getCorrectTimezone({
                  date: moment(task.finishDate).add(durationOffset, "days"),
                }).valueOf(),
                startDate: getCorrectTimezone({
                  date: task.startDate,
                }).valueOf(),
              },
              durationOffset,
              actionType.CHANGED_DURATION_TASK
            );
          }}
        />
      )}
      {isBigGrid && !isCollapse && (
        <TaskDateField
          dateRef={dateStartRef}
          fieldDate={TASK_FIELDS.START_DATE}
          task={task}
          isTaskCompleted={isTaskCompleted}
          setDatePickerData={setDatePickerData}
        />
      )}
      {!isCollapse && (
        <TaskDateField
          dateRef={dateFinishRef}
          fieldDate={TASK_FIELDS.FINISH_DATE}
          task={task}
          isTaskCompleted={isTaskCompleted}
          setDatePickerData={setDatePickerData}
          serverTime
        />
      )}
      <div
        ref={userRef}
        className={"userData"}
        onClick={() => {
          if (canReassignUser && !isCollapse) {
            setOpenUserPicker(true);
          }
        }}
        onMouseEnter={() => addHoverUser(task.assignedTo)}
        onMouseLeave={() => removerHoverUser()}
        style={{
          cursor: isCollapse ? "unset" : "",
          justifyContent: isCollapse ? "center" : "",
        }}
      >
        {getUserAvatar({
          user: companyUsers.find(
            (companyUser) => companyUser.id === task.assignedTo
          ),
          styles: {
            width: 20,
            height: 20,
            fontSize: 13,
            outline: "1px solid #000",
          },
        })}
        {!isCollapse && (
          <TooltipTD
            label={getCompanyUserDisplayName(companyUsers, task.assignedTo)}
            className="userName"
          >
            {getCompanyUserDisplayName(companyUsers, task.assignedTo)}
          </TooltipTD>
        )}
        {canReassignUser && !isTaskCompleted && !isCollapse && (
          <ArrowDownIcon svgClass={"avatarArrow"} />
        )}
      </div>
      <div
        className={cx("taskColorColumn", `border${task.type}`)}
        style={{
          background: task.type === TASK_TYPE.PURCHASE_ORDER ? task.color : "",
          opacity: isTaskCompleted ? 0.55 : 1,
        }}
        onMouseEnter={() => {
          if (task.type !== TASK_TYPE.SALES_ORDER) {
            onDebounceHover();
          }
        }}
        onMouseLeave={() => {
          if (task.type !== TASK_TYPE.SALES_ORDER) {
            resetColor();
          }
        }}
        onClick={(ev) => {
          const element = document.getElementById(`${task.id}-rnd`);
          const scrollElement = graphTableRef.current;
          const elementRect = element.getBoundingClientRect();
          const scrollRect = scrollElement.getBoundingClientRect();
          const scrollLeft =
            elementRect.left - scrollRect.left + scrollElement.scrollLeft - 20;
          scrollElement.scrollTo({
            left: scrollLeft,
            behavior: "smooth",
          });
          ev.stopPropagation();
        }}
      >
        <TooltipTD label={"Scroll to this task"} style={{ display: "flex" }}>
          <KeyboardTab
            style={{
              width: 16,
              height: 16,
              color: "#000",
            }}
          />
        </TooltipTD>
      </div>
    </div>
  );
}

export default TaskItemData;
