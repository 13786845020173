import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
} from "firebase/firestore";
import { firestore } from "../firebase";

export async function loadDataBatches({
  path,
  lastDoc,
  orderByField,
  limitFilter,
}) {
  let snapQuery;
  if (lastDoc) {
    snapQuery = query(
      collection(firestore, path),
      orderBy(orderByField, "desc"),
      startAfter(lastDoc),
      limit(limitFilter)
    );
  } else {
    snapQuery = query(
      collection(firestore, path),
      orderBy(orderByField, "desc"),
      limit(50)
    );
  }

  const docsSnap = await getDocs(snapQuery);
  const docs = docsSnap.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });
  let lastDocFound = null;
  let pendingToLoad = true;
  if (docs.length > 0) {
    lastDocFound = docsSnap.docs[docsSnap.docs.length - 1];
  }
  if (docs.length < limitFilter) {
    pendingToLoad = false;
  }
  return {
    docs,
    lastDoc: lastDocFound,
    pendingToLoad,
  };
}
