import Activity from "../api/model/activity";
import { dbTables } from "../api/types/dbTables";
import {
  activityType,
  updateCompanyUsersUnreadActivity,
} from "./activitiesStream";
import { TYPE_OF_FILE } from "./constants";
import { documentScope, hasPermissionToDelete } from "./documents";
import { getFileName, getRandomId, sortObjectsBy } from "./helpers";
import { firestore } from "../firebase";
import React from "react";
import moment from "moment";

export function getDocumentPathv2({ currentDocument = {}, documents = [] }) {
  const pathArray = [currentDocument.name];
  do {
    const parentDocument = documents.find(
      (document) => document.id === currentDocument.parentId
    );
    if (parentDocument) {
      pathArray.push(parentDocument.name);
      currentDocument = parentDocument;
    } else {
      currentDocument.parentId = null;
    }
  } while (currentDocument.parentId);
  const path = pathArray.reverse().join("/");
  return path;
}

export function isMultiSelectEnabled({ selected = {}, currentDocument = {} }) {
  if (currentDocument.isVersion) {
    return false;
  }
  if (
    selected.parentId &&
    (selected.parentId === currentDocument.id ||
      selected.parentId === currentDocument.parentId) &&
    !selected.hiddenMenu
  ) {
    return true;
  }
  return false;
}

export function getDocumentScope({ parentCollection, mainEntity }) {
  switch (parentCollection) {
    case dbTables.SALES_ORDERS:
    case dbTables.PURCHASE_ORDERS:
      return mainEntity.type;
    case dbTables.SHIPMENTS:
      return documentScope.SHIPMENT;
    case dbTables.CUSTOMERS:
      return documentScope.CUSTOMER;
    case dbTables.FACTORIES:
      return documentScope.FACTORY;
    default:
      return "NO_SCOPE";
  }
}

export function createActivityForMovingDocuments({
  documents = [],
  scope = "",
  oldPath = "top level",
  currentPath = "top level",
  companyId = "",
  userId = "",
  parentCollection = "",
  parentDocumentId = "",
  doc = () => {},
  setDoc = () => {},
  activityId = getRandomId(),
  companyUsers = [],
  mainEntity = {},
}) {
  if (documents.length === 0 || !parentCollection || !parentDocumentId) {
    return;
  }
  let type = "";
  const hasFolder = documents.some((doc) => doc.type === TYPE_OF_FILE.FOLDER);
  const hasFile = documents.some((doc) => doc.type !== TYPE_OF_FILE.FOLDER);
  if (hasFolder && hasFile) {
    type = activityType.MOVE_FILES_AND_FOLDERS;
  } else if (hasFolder) {
    type = activityType.MOVE_FOLDERS;
  } else if (hasFile) {
    type = activityType.MOVE_FILES;
  }

  let activity = {
    ...new Activity({
      id: activityId,
      companyId,
      type,
      user: userId,
      scope,
    }),
  };

  activity.detail = `<strong>`;
  documents.sort(sortObjectsBy("name", false)).forEach((document, index) => {
    activity.detail += `<div> <strong> ${index + 1}. ${
      document.type === TYPE_OF_FILE.FOLDER ? "📂 " : "📄 "
    } ${document.name}</strong></div>`;
  });
  activity.detail += `<br /></strong>${
    documents.length === 1 ? "Was" : "Were"
  } moved from <strong>${oldPath}</strong> to <strong>${currentPath}</strong>`;
  setDoc(
    doc(
      firestore,
      `${dbTables.COMPANIES}/${companyId}/${parentCollection}/${parentDocumentId}/${dbTables.ACTIVITIES}/${activity.id}`
    ),
    activity
  );
  updateCompanyUsersUnreadActivity({
    activity,
    users: companyUsers,
    companyId,
    order: mainEntity,
  });

  return;
}

export function canSeeThreeDotMenu({
  currentDocument = {},
  selected = {},
  isVoided = false,
}) {
  const isPlaceHolderFile =
    currentDocument.placeholder && currentDocument.type !== TYPE_OF_FILE.FOLDER;
  if (isPlaceHolderFile) {
    return false;
  }
  if (
    currentDocument.placeholder &&
    currentDocument.type === TYPE_OF_FILE.FOLDER &&
    isVoided
  ) {
    return false;
  }
  if (
    isMultiSelectEnabled({
      selected,
      currentDocument,
    })
  ) {
    return (
      selected.documents.includes(currentDocument.id) ||
      selected.parentId === currentDocument.id
    );
  }
  return true;
}

export function getDocumentNameByType({ currentDocument = {} }) {
  if (
    currentDocument.type === TYPE_OF_FILE.FOLDER ||
    currentDocument.placeholder
  ) {
    return currentDocument.name;
  } else {
    return getFileName(currentDocument.name);
  }
}

export const DOCUMENT_NAMES_ERROR_TYPES = {
  DUPLICATED_NAME: "DUPLICATED_NAME",
  INVALID_CHARACTER: "INVALID_CHARACTER",
};

export const filterFilesByType = ({ type, currentDocuments }) => {
  const cpyDocuments = currentDocuments.filter((currentDocument) =>
    type === TYPE_OF_FILE.FOLDER
      ? currentDocument.type === TYPE_OF_FILE.FOLDER
      : currentDocument.type !== TYPE_OF_FILE.FOLDER
  );
  return cpyDocuments;
};

export function verifyDocumentName({
  documentsInTheSameLevel = [],
  newName = "",
  type,
  currentDocumentId,
}) {
  if (newName.includes("/")) {
    return DOCUMENT_NAMES_ERROR_TYPES.INVALID_CHARACTER;
  }
  const documentsByType = filterFilesByType({
    type,
    currentDocuments: documentsInTheSameLevel,
  });
  const isDuplicatedName = documentsByType.find((document) => {
    return (
      getDocumentNameByType({ currentDocument: document }).toLowerCase() ===
        newName.toLocaleLowerCase() && document.id !== currentDocumentId
    );
  });
  if (isDuplicatedName) {
    return DOCUMENT_NAMES_ERROR_TYPES.DUPLICATED_NAME;
  }
  return null;
}

export function getDuplicatedNamesInDestination({
  documentsInTheSameLevel = [],
  documentsToMove = [],
}) {
  const duplicatedDocument = [];
  documentsToMove.forEach((document) => {
    const filesByType = filterFilesByType({
      currentDocuments: documentsInTheSameLevel,
      type: document.type,
    });
    const isDuplicatedName = filesByType.some((doc) => {
      return (
        getDocumentNameByType({
          currentDocument: document,
        }).toLocaleLowerCase() ===
        getDocumentNameByType({ currentDocument: doc }).toLocaleLowerCase()
      );
    });
    if (isDuplicatedName) {
      duplicatedDocument.push(document);
    }
  });
  if (duplicatedDocument.length === 0) {
    return {
      isDuplicatedName: false,
      attentionModalDescription: null,
    };
  }
  let type = "";
  const hasFolder = duplicatedDocument.some(
    (doc) => doc.type === TYPE_OF_FILE.FOLDER
  );
  const hasFile = duplicatedDocument.some(
    (doc) => doc.type !== TYPE_OF_FILE.FOLDER
  );
  if (hasFolder && hasFile) {
    type = "files and folders";
  } else if (hasFolder) {
    type = "folders";
  } else if (hasFile) {
    type = "files";
  }

  let attentionModalDescription = (
    <React.Fragment>
      Some of the items you want to move have the same name <br />
      as {type} in the destination. <br />
      Please change those names and try again.
      <div style={{ padding: "24px 0px" }}>
        {duplicatedDocument.sort(sortObjectsBy("name", false)).map((doc) => (
          <div
            key={doc.id}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {doc.type === TYPE_OF_FILE.FOLDER ? "📂 " : "📄 "} {doc.name}
          </div>
        ))}
      </div>
    </React.Fragment>
  );
  return { isDuplicatedName: true, attentionModalDescription };
}

export function isOpenedFolder({
  openedFolders = {},
  selected = { parentId: null, documents: [] },
  document = { id: null },
  search = "",
}) {
  if (search) {
    if (document.type !== TYPE_OF_FILE.FOLDER && !openedFolders[document.id]) {
      return false;
    }
    return true;
  }
  if (
    isMultiSelectEnabled({ selected, currentDocument: document }) &&
    selected.parentId === document.parentId
  ) {
    return false;
  }
  return !!openedFolders[document.id];
}

export const typeImage = ["png", "jpg", "jpeg", "svg"];

export function canSeePreview({ currentDocument = {}, selected = {} }) {
  const previewAvailableTypes = [TYPE_OF_FILE.FOLDER, "pdf", ...typeImage];
  const isPlaceholder = currentDocument.placeholder;
  const isNotMultiSelect = !isMultiSelectEnabled({
    selected,
    currentDocument,
  });
  const documentType = (currentDocument.type || "").toLowerCase();
  const isVersion = currentDocument.isVersion;
  const child = currentDocument.child || [];
  const hadImage =
    currentDocument.type === TYPE_OF_FILE.FOLDER
      ? child.some((doc) => typeImage.includes(doc.type))
      : true;
  const isSupportedType = previewAvailableTypes.includes(documentType);
  const canShowPreview =
    (isSupportedType && !isPlaceholder && isNotMultiSelect) ||
    (isVersion && isSupportedType);

  return (canShowPreview && hadImage) || false;
}

export const getPreviewImages = ({ currentDocument = {}, isFolder }) => {
  const child = currentDocument.child || [];
  const errorData = {
    src: "",
    number: "",
    description: "error",
  };
  const photoData = [];
  if (isFolder) {
    if (child.length === 0) {
      return [errorData];
    }
    child.forEach((file) => {
      if (typeImage.includes(file.type)) {
        photoData.push({
          src: file.url,
          number: "",
          description: file.name,
        });
      }
    });
  } else {
    if (!currentDocument.id) {
      return [errorData];
    }
    photoData.push({
      src: currentDocument.url,
      number: "",
      description: currentDocument.name,
    });
  }
  return photoData;
};

export function canEnterInMultiSelect({
  currentDocument = {},
  isVoided = false,
  selected,
}) {
  if (!isVoided && !currentDocument.placeholder) {
    if (
      selected.parentId &&
      (selected.parentId === currentDocument.id ||
        selected.parentId === currentDocument.parentId)
    ) {
      return true;
    } else if (
      currentDocument.type === TYPE_OF_FILE.FOLDER &&
      currentDocument.child.length > 0
    ) {
      return true;
    }
  }

  return false;
}

export function canUploadFile({
  currentDocument = "",
  isVoided = false,
  selected = {},
}) {
  const documentType = (currentDocument.type || "").toLowerCase();
  return (
    documentType === TYPE_OF_FILE.FOLDER &&
    !isVoided &&
    !isMultiSelectEnabled({ selected, currentDocument })
  );
}
export function canCreateFolder({
  currentDocument = {},
  isVoided = false,
  selected = {},
}) {
  const documentType = (currentDocument.type || "").toLowerCase();
  return (
    documentType === TYPE_OF_FILE.FOLDER &&
    !isVoided &&
    !isMultiSelectEnabled({ selected, currentDocument })
  );
}

export function canUploadNewFileVersion({
  currentDocument = {},
  isVoided = false,
  selected = {},
}) {
  if (currentDocument.isVersion || !currentDocument.id) {
    return false;
  }
  const documentType = (currentDocument.type || "").toLowerCase();
  if (
    documentType !== TYPE_OF_FILE.FOLDER &&
    !currentDocument.placeholder &&
    !isVoided &&
    !isMultiSelectEnabled({ selected, currentDocument })
  ) {
    return true;
  }
  return false;
}

export function canCopyLink({ currentDocument = {}, selected = {} }) {
  if (
    currentDocument.id &&
    !currentDocument.placeholder &&
    !isMultiSelectEnabled({ selected, currentDocument })
  ) {
    return true;
  }
  return false;
}

export function canRenameDocument({
  currentDocument = {},
  isVoided = false,
  selected = {},
}) {
  if (currentDocument.isVersion || !currentDocument.id) {
    return false;
  }
  if (
    !isVoided &&
    !currentDocument.placeholder &&
    !currentDocument.mainFileId &&
    !isMultiSelectEnabled({ selected, currentDocument })
  ) {
    return true;
  }
  return false;
}

export function canChangePermissions({
  currentDocument = {},
  isVoided = false,
  selected = {},
}) {
  if (
    currentDocument.type === TYPE_OF_FILE.FOLDER &&
    !isVoided &&
    !currentDocument.placeholder &&
    !isMultiSelectEnabled({ selected, currentDocument })
  ) {
    return true;
  }
  return false;
}

export function canDownload({ currentDocument = {}, selected = {} }) {
  if (
    isMultiSelectEnabled({
      selected,
      currentDocument,
    })
  ) {
    console.log("selected.documents", selected.documents);
    return selected.documents.includes(currentDocument.id);
  }

  if (!currentDocument.placeholder) {
    return true;
  }
  return false;
}

export function canMoveToTopLevel({
  currentDocument = {},
  isVoided = false,
  selected = {},
}) {
  if (isMultiSelectEnabled({ selected, currentDocument })) {
    return selected.documents.includes(currentDocument.id);
  }
  if (
    !currentDocument.placeholder &&
    !isVoided &&
    currentDocument.parentId &&
    !currentDocument.isVersion
  ) {
    return true;
  }
  return false;
}

export function canDeleteFileFolder({
  currentDocument = {},
  user = {},
  dbCollection = "",
  isAllowed = () => {},
  isVoided = false,
  selected = {},
}) {
  if (
    currentDocument.mainFileId ||
    currentDocument.placeholder ||
    isVoided ||
    currentDocument.isVersion
  ) {
    return false;
  }
  const { createdBy, created } = currentDocument;
  const diffSeconds = moment(new Date()).diff(created, "seconds");
  const limitInSecondsToDelete = 15;
  if (createdBy === user.id && diffSeconds < limitInSecondsToDelete) {
    return true;
  }
  if (
    !hasPermissionToDelete({
      scope: dbCollection,
      user,
      isAllowed,
    })
  ) {
    return false;
  }
  if (
    isMultiSelectEnabled({
      selected,
      currentDocument,
    })
  ) {
    return selected.documents.includes(currentDocument.id);
  }

  return true;
}

export function getChildDocumentsToCopyLink({ documents = [] }) {
  let documentsCpy = [...documents];
  documentsCpy = documentsCpy.map((document) => {
    if (document.type === TYPE_OF_FILE.FOLDER) {
      return {
        id: document.id,
        name: document.name,
        type: document.type,
        child: getChildDocumentsToCopyLink({
          documents: document.child,
        }),
      };
    }
    return {
      id: document.id,
      name: document.name,
      type: document.type,
      url: document.url,
    };
  });
  return documentsCpy;
}

export const filterAutocompleteUserList = ({ input = "", candidate = {} }) => {
  if (input === "") {
    return true;
  }
  const parseInput = input.toLowerCase();
  const candidateLabel = (candidate.label || "").toLowerCase();
  const filteredInput = input.startsWith("@")
    ? parseInput.substring(1)
    : parseInput;
  return candidateLabel.includes(filteredInput);
};
