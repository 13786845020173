import { Checkbox } from "@mui/material";
import { colors } from "../../assets/jss/variables";
import { styled } from "@mui/material";

export const CheckBoxStyled = styled(Checkbox)(() => ({
  "&.checkboxEffect": {
    strokeWidth: 1.5,
    "&:hover": {
      "& .uncheckIcon": {
        "& .square": {
          fill: "#f0f0f0",
        },
        "& .border": {
          stroke: "#8597a8",
          strokeWidth: 2,
        },
      },
      "& .checkIcon": {
        "& .square": {
          fill: "#297fc2",
          strokeWidth: 2,
        },
      },
    },
    "&:active": {
      "& .checkIcon": {
        "& .vector": {
          transform: "translateY(2px)",
        },
      },
    },
  },
  "&.grayColor": {
    "&:hover": {
      "& .checkIcon": {
        "& .square": {
          fill: "	#606060",
        },
      },
    },
  },
}));

export const SearchLabelStyled = styled("div")(() => ({
  "&.searchContainerRoot": {
    color: "#A8B9CA",
    fontSize: 13,
    zIndex: 1,
    width: 32,
    float: "right",
    borderRadius: 4,
    marginRight: 5,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    transition: "width 0.8s",
    position: "relative",
    "& input": {
      width: 0,
      padding: 0,
      transition: "width 0.8s",
    },
    "& .iconSearch": {
      position: "absolute",
      right: 16,
      transition: "all 0.8s",
      cursor: "pointer",
      "& line": {
        transition: "0.4s all",
      },
      "& circle": {
        transition: "0.4s all",
      },
    },
    "& .divider": {
      transition: "height 0.8s",
      width: 2,
      minWidth: 2,
      height: 20,
      backgroundColor: colors.primaryDark,
    },
    "&:focus-within": {
      boxShadow: "rgb(93 146 244 / 25%) 0 0 0 0.2rem",
      transition: "width 0.8s",
      border: `1px solid ${colors.radioCheck}`,
      "& input": {
        width: "100%",
        padding: "0 6px",
      },
      "& .iconSearch": {
        right: 5,
        cursor: "unset",
        "& line": {
          stroke: "#BDBDBD",
        },
        "& circle": {
          stroke: "#BDBDBD",
        },
      },
      "& .divider": {
        height: 0,
      },
    },
  },
  "&.searchContainerRootActive": {
    width: 200,
    border: "1px solid #D2DFEC",
    backgroundColor: "#fff",
    "& input": {
      width: "100%",
      padding: "0 6px",
    },
    "& .iconSearch": {
      right: 5,
    },
    "& .divider": {
      height: 0,
    },
  },

  "&.searchBoxDocuments": {
    marginRight: 6,
    width: 35,
    position: "relative",
    "&:focus-within": {
      width: "200px",
      backgroundColor: "#fff",
    },
  },

  "&.searchBoxNotes": {
    marginRight: 16,
    width: 32,
    "&:focus-within": {
      width: 280,
    },
  },
  "&.searchBoxActiveNotes": {
    width: 280,
    transition: "width 0.8s",
    border: "1px solid #D2DFEC",
    marginRight: 12,
    "&:focus-within": {
      width: 280,
    },
  },
  "&.searchBoxNoteDocuments": {
    width: 32,
    marginRight: -1,
    "&:focus-within": {
      width: 200,
      backgroundColor: "#fff",
    },
    "& .iconSearch": {
      right: 0,
    },
    "& .divider": {
      height: 0,
    },
  },

  "&.searchBoxActiveNoteDocuments": {
    width: 200,
    backgroundColor: "#fff",
    marginRight: 22,
    "& .iconSearch": {
      right: 16,
    },
    "& .divider": {
      height: 0,
    },
  },

  "&.searchBoxSKUTable": {
    width: 36,
    height: 36,
    justifyContent: "flex-end",
    "&:hover": {
      background: colors.backgroundWhite,
      cursor: "pointer",
    },
    "& .iconSearch": {
      right: 0,
      width: 22,
      height: 22,
      margin: "0px 8px",
    },
    "& .divider": {
      height: 0,
    },
    "&:active": {
      background: colors.backgroundWhiteAction,
    },
    "&:focus-within": {
      width: "310px !Important",
      backgroundColor: "#fff",
      height: 28,
      "&:hover": {
        backgroundColor: "#fff",
      },
      "& .iconSearch": {
        width: 18,
        height: 18,
      },
    },
  },

  "&.searchBoxActiveSKUTable": {
    width: "310px !Important",
    "&:focus-within": {
      width: "310px !Important",
    },
    "& svg": {
      width: 14,
      height: 14,
      marginRight: 8,
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  "& .magnifyingglass": {
    float: "right",
  },
  "& .magnifyingglassOnExpandedInput": {
    float: "right",
    marginTop: 5,
  },
  "& .placeholder": {
    float: "right",
    marginRight: 5,
  },
  "& input": {
    width: "100%",
    border: "none",
    lineHeight: 2,
    paddingInline: 5,
    color: colors.primaryDark,
    "&::placeholder": {
      color: "#D2DFEC",
    },
  },
  "& .buttonX": {
    background: "#FFF",
    color: "darkGray",
    width: 15,
    height: 15,
    borderRadius: "50%",
    margin: "0px 8px",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
