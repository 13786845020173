import React, { useState, useEffect } from "react";
import CustomModal from "../Modal/Modal";
import { useCompanyId } from "../../hooks";
import { dbTables } from "../../api/types/dbTables";
import CustomButton from "../Buttons/CustomButton";
import AttentionModal from "../Modal/AttentionModal";
import { sortObjectsBy } from "../../helpers/helpers";
import { useUser } from "../../hooks/user";
import { useFactories } from "../../hooks/factories";
import { orderType } from "../../helpers/salesOrder";
import { GENERAL_PERMISSION_VALUE, PO_STATUS } from "../../helpers/constants";
import { firestore } from "../../firebase";
import { collection, onSnapshot, query, where } from "firebase/firestore";

import { ShipmentOrderIcon, VoidLabel } from "../../helpers/iconSvgPath";
import { colors } from "../../assets/jss/variables";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import {
  getFunctionByName,
  globalEnvironment,
} from "../../constants/globalVariables";
import LoadingBackdrop from "../WholeScreenFocusBackdrop/LoadingBackdrop";
import { TextField, styled } from "@mui/material";
import IntlMessages from "../../util/IntlMessages";

const permissionValue = {
  [orderType.PURCHASE_QUOTE]: GENERAL_PERMISSION_VALUE.CAN_VOID_PURCHASE_QUOTES,
  [orderType.PURCHASE_ORDER]: GENERAL_PERMISSION_VALUE.CAN_VOID_PURCHASE_ORDERS,
};

const textPO = {
  [orderType.PURCHASE_ORDER]: "Purchase Order",
  [orderType.PURCHASE_QUOTE]: "Purchase Quote",
};

function ActionsModal({ open, onClose, salesOrder, isAllowed = () => {} }) {
  const user = useUser();
  const companyId = useCompanyId();
  const factories = useFactories();
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [confirmationText, setConfirmationText] = useState("");
  const [selectedPurchaseOrders, setSelectedPurchaseOrders] = useState([]);
  const [openModalAttention, setModalAttention] = useState({
    open: false,
    description: "",
  });
  const [confirmVoid, setConfirmVoid] = useState(false);
  const [emptyPOs, setEmptyPOs] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(
        collection(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${dbTables.PURCHASE_ORDERS}`
        ),
        where("salesOrderIds", "array-contains", salesOrder.id)
      ),
      (snap) => {
        if (snap.docs.length === 0 && !emptyPOs) {
          setEmptyPOs(true);
        } else {
          if (emptyPOs) {
            setEmptyPOs(false);
          }
        }
        setPurchaseOrders(
          snap.docs.map((doc) => ({ ...doc.data(), ref: doc.ref }))
        );
      }
    );

    return () => {
      unsubscribe();
    };
  }, []);

  const isVoidAllPO = () => {
    const poToVoid = selectedPurchaseOrders.map((po) => po.id);
    const poInProgress = purchaseOrders.filter(
      (po) => po.status !== PO_STATUS.VOIDED && !poToVoid.includes(po.id)
    );
    return poInProgress.length === 0;
  };

  const checkVoid = () => {
    setConfirmVoid(false);
    voidPOs(isVoidAllPO());
  };

  async function voidPOs(voidSalesOrder) {
    setIsLoading(true);
    const currentPOs = selectedPurchaseOrders.map((po) => ({
      id: po.id,
      number: po.number,
    }));
    const currentSO = { id: salesOrder.id, number: salesOrder.number };
    const functions = getFunctions();
    const callableEndpoint = httpsCallableFromURL(
      functions,
      getFunctionByName({
        name: "adjustproject",
        params: "/voidPOs",
        env: globalEnvironment,
      })
    );
    try {
      const result = await callableEndpoint({
        companyId,
        salesOrder: currentSO,
        purchaseOrders: currentPOs,
        isVoidSO: voidSalesOrder,
        userId: user.id,
      });
      const data = result.data || {};
      if (data.hasError) {
        onClose({ hasError: true });
        return;
      }
      if (voidSalesOrder) {
        onClose({ salesOrder, purchaseOrders: selectedPurchaseOrders });
        setSelectedPurchaseOrders([]);
      } else {
        setSelectedPurchaseOrders([]);
        onClose({
          purchaseOrders: selectedPurchaseOrders,
        });
      }
    } catch (error) {
      setIsLoading(false);
      onClose({ hasError: true });
      return;
    }
  }

  async function voidSO() {
    setIsLoading(true);
    const currentSO = { id: salesOrder.id, number: salesOrder.number };
    const functions = getFunctions();
    const callableEndpoint = httpsCallableFromURL(
      functions,
      getFunctionByName({
        name: "adjustproject",
        params: "/voidPOs",
        env: globalEnvironment,
      })
    );
    try {
      const result = await callableEndpoint({
        companyId,
        salesOrder: currentSO,
        purchaseOrders: [],
        isVoidSO: true,
        userId: user.id,
      });
      const data = result.data || {};

      if (data.hasError) {
        onClose({ hasError: true });
        return;
      }
      onClose({ salesOrder });
      setSelectedPurchaseOrders([]);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      onClose({ hasError: true });
      return;
    }
  }

  function onChangePO(purchaseOrder, isSelected) {
    if (purchaseOrder.shipmentIds.length !== 0) {
      const stringShipment =
        purchaseOrder.shipmentIds.length > 1 ? "shipments" : "shipment";
      setModalAttention({
        open: true,
        description: `This ${
          textPO[purchaseOrder.type]
        } has ${stringShipment} linked to it. A ${
          textPO[purchaseOrder.type]
        } with ${stringShipment} cannot be voided`,
      });
      return;
    }
    if (isAllowed(permissionValue[purchaseOrder.type])) {
      isSelected
        ? setSelectedPurchaseOrders([
            ...selectedPurchaseOrders.filter(
              (item) => item.id !== purchaseOrder.id
            ),
          ])
        : setSelectedPurchaseOrders([...selectedPurchaseOrders, purchaseOrder]);
    } else {
      setModalAttention({
        open: true,
        description: `you don't have permission to void this ${
          textPO[purchaseOrder.type]
        }`,
      });
    }
  }

  function verifyDissableButtonVoid() {
    if (emptyPOs) {
      return false;
    } else {
      return selectedPurchaseOrders.length === 0;
    }
  }

  function getPOText() {
    const allPO = selectedPurchaseOrders.every(
      (po) => po.type === orderType.PURCHASE_ORDER
    );
    if (allPO) {
      return textPO[orderType.PURCHASE_ORDER];
    }
    const allPQ = selectedPurchaseOrders.every(
      (po) => po.type === orderType.PURCHASE_QUOTE
    );
    if (allPQ) {
      return textPO[orderType.PURCHASE_QUOTE];
    }
    return "PO/PQ";
  }

  return (
    <React.Fragment>
      {openModalAttention.open && (
        <AttentionModal
          isOpen={openModalAttention.open}
          description={openModalAttention.description}
          onClose={() => setModalAttention({})}
          title="Attention"
          confirmationText={"Ok"}
          onClick={() => {
            setModalAttention({});
          }}
          acceptBlue={false}
          cancelText="No"
        />
      )}
      {isLoading && <LoadingBackdrop size={40} />}
      {confirmVoid && (
        <CustomModal isOpen={confirmVoid} onClose={() => setConfirmVoid(false)}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            {isLoading && <LoadingBackdrop size={40} />}

            {isVoidAllPO() && (
              <span style={{ textAlign: "center" }}>
                Voiding all the POs/PQs in a project also voids the entire
                project including the Sales Order. <br />
                Are you sure you want to void this PO(s)?{" "}
                <span style={{ fontWeight: 700 }}>This cannot be undone </span>
              </span>
            )}
            <span style={{ fontWeight: 700, marginTop: 10 }}>
              Confirm you want to void this{" "}
              {emptyPOs ? "Sales order" : getPOText()} by typing VOID
            </span>
            <TextField
              onChange={(ev) => {
                const text = ev.target.value;
                setConfirmationText(text.toLocaleUpperCase());
              }}
              value={confirmationText}
              placeholder="VOID"
              variant="outlined"
              className={"textFieldClass"}
              autoFocus
              onKeyDown={(ev) => {
                if (ev.key === "Enter" && confirmationText === "VOID") {
                  if (emptyPOs) {
                    voidSO();
                  } else {
                    checkVoid();
                  }
                }
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: "space-around",
              marginTop: 20,
            }}
          >
            <CustomButton
              onClick={() => {
                if (emptyPOs) {
                  voidSO();
                } else {
                  checkVoid();
                }
              }}
              disabled={confirmationText !== "VOID"}
            >
              {"generic.text.accept"}
            </CustomButton>
            <CustomButton onClick={() => setConfirmVoid(false)} type="cancel">
              {"generic.text.cancel"}
            </CustomButton>
          </div>
        </CustomModal>
      )}

      <VoidPOModal
        isOpen={open}
        onClose={() => {
          if (isLoading) {
            return;
          }
          onClose({});
        }}
        header={
          emptyPOs ? (
            <IntlMessages id={"components.saleOrders.ActionsModal.voidSO"} />
          ) : (
            <IntlMessages id={"components.saleOrders.ActionsModal.voidPOPQ"} />
          )
        }
        modalStyles={{
          alignItems: "center",
          justifyContent: "center",
        }}
        className={"voidPOModal"}
        hasCloseIcon={false}
      >
        {isLoading && <LoadingBackdrop size={40} />}
        <div className={"poListContent"}>
          {purchaseOrders
            .sort(sortObjectsBy("number", false))
            .map((purchaseOrder) => {
              const factory = factories.find(
                (factory) => factory.id === purchaseOrder.factoryId
              );
              const isVoided = purchaseOrder.status === PO_STATUS.VOIDED;
              const isSelected = selectedPurchaseOrders
                .map((item) => item.id)
                .includes(purchaseOrder.id);
              const colorType = !isAllowed(permissionValue[purchaseOrder.type])
                ? colors.diamondRed
                : "";
              const voidedStyle = {
                color: colorType || "gray",
              };
              return (
                <div
                  key={purchaseOrder.id}
                  className={"poItem"}
                  onClick={() => {
                    if (!isVoided) {
                      onChangePO(purchaseOrder, isSelected);
                    }
                  }}
                >
                  <input
                    checked={isSelected}
                    type="checkbox"
                    disabled={isVoided}
                  />
                  <span
                    className="po-information"
                    style={isVoided ? voidedStyle : { color: colorType }}
                  >
                    {`${
                      purchaseOrder.type === orderType.PURCHASE_ORDER
                        ? "PO"
                        : "PQ"
                    } 
                    ${purchaseOrder.number} (${factory.name})`}
                  </span>
                  <span
                    className="shipment-information"
                    style={{ minWidth: 50 }}
                  >
                    <ShipmentOrderIcon height={20} width={20} />
                    <span
                      className="item-subtitle"
                      style={{
                        fontSize: 16,
                        fontWeight: 700,
                        lineHeight: "normal",
                        position: "relative",
                        bottom: 2,
                        marginLeft: 3,
                      }}
                    >
                      {purchaseOrder.shipmentIds.length}
                    </span>
                  </span>
                  <div
                    style={{
                      width: 43,
                      minWidth: 43,
                    }}
                  >
                    {isVoided ? <VoidLabel style={{}} /> : <div />}
                  </div>
                </div>
              );
            })}
        </div>
        {emptyPOs && (
          <span>
            Are you sure you want to void this Sales Order? This cannot be
            undone.
          </span>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "space-around",
            marginTop: 20,
          }}
        >
          <CustomButton
            onClick={() => setConfirmVoid(true)}
            disabled={verifyDissableButtonVoid()}
          >
            {"generic.text.void"}
          </CustomButton>
          <CustomButton onClick={onClose} type="cancel">
            {"generic.text.cancel"}
          </CustomButton>
        </div>
      </VoidPOModal>
    </React.Fragment>
  );
}

const VoidPOModal = styled(CustomModal)(() => ({
  "& .poListContent": {
    maxHeight: "calc(100vh - 220px)",
    overflow: "scroll",
  },
  "& .poItem": {
    height: "auto",
    width: "100%",
    gap: 10,
    cursor: "pointer",
    "& input,select": {
      margin: "0 12px",
    },
    display: "flex",
    alignItems: "center",
    padding: 7,
    "& .po-information": {
      width: "70%",
    },
    "& .shipment-information": {},
  },
  "& .textFieldClass": {
    border: 0,
    margin: 0,
    display: "inline-flex",
    padding: 0,
    position: "relative",
    minWidth: 0,
    flexDirection: "column",
    verticalAlign: "top",
    fontSize: 12,
  },
}));

export default ActionsModal;
