import React, { useState, useEffect, useCallback, useContext } from "react";
import IntlMessages from "../../util/IntlMessages";
import { useDispatch } from "react-redux";
import { dbTables } from "../../api/types/dbTables";
import {
  getCompanyUserDisplayName,
  getRandomId,
  listenToData,
  verifyTaskTemplateErrors,
} from "../../helpers/helpers";
import SalesOrderTemplateObj from "../../api/model/salesOrderTemplate";
import CustomSnackbar from "../../routes/components/snackbar/component/CustomSnackbar";
import { useCompanyUsers, useUser } from "../../hooks/user";
import { STAGE_LIST } from "../../helpers/constants";
import ReadOnlyModal from "../Modal/ReadOnlyModal";
import { useSummaryFactories } from "../../hooks/factories";
import { TASK_TEMPLATE_OPTIONS } from "../../helpers/salesOrderHelpers";
import {
  collection,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { firestore } from "../../firebase";
import { unstable_usePrompt } from "react-router-dom";
import {
  GANTT_CHART_ACTION,
  GanttChartTemplateContext,
} from "../CompanyTabs/SalesOrderTemplateContainer";
import { GanttChartTemplateStyled } from "./GanttChartTemplate/styles";
import TableContentSetup from "./GanttChartTemplate/TableContentSetup";
import GanttChartHeader from "./GanttChartTemplate/GanttChartHeader";
import GanttChart from "./GanttChartTemplate/GanttChart";
import TaskContent from "./GanttChartTemplate/TaskTemplateContent";
import moment from "moment";
import AppConfig from "../../constants/AppConfig";
import {
  changeTaskDuration,
  getChangesFromBeforeAndCurrentTasks,
  getStarFinishCalendarDate,
  getTemplateTaskWithDate,
  shouldUpdateVendorTemplate,
  sortTaskList,
  taskListByStage,
} from "./soTemplateHelper";
import SalesOrderTask from "../../api/model/salesOrderTask";
import LoadingBackdrop from "../WholeScreenFocusBackdrop/LoadingBackdrop";
import CollapseExpandButtons from "./CollapseExpandButtons";
import { filterTaskByPhases } from "../Timeline/timelineHelper";
import { fetchSalesOrderTasks } from "../Factories/FactoryTemplateHelper";
import TemplateChangeLog from "./TemplateChangeLog";
import { SalesOrderTemplateContainerStyled } from "./styles";
import { Button, TextareaAutosize } from "@mui/material";
import ChangeLog from "../../api/model/ChangeLog";
import AttentionModal from "../Modal/AttentionModal";
import TooltipTD from "../Tooltip/TooltipTD";
import { colors } from "../../assets/jss/variables";
import { getUserAvatar } from "../../helpers/users";

function SalesOrderTemplate({ salesOrderTemplateId, companyId }) {
  const { ganttChartTemplateState, dispatchGanttChartTemplate } = useContext(
    GanttChartTemplateContext
  );
  const {
    startDate,
    endDate,
    tableSize,
    tasks,
    initializedTasks = [],
    templateChanges,
    taskPhases,
  } = ganttChartTemplateState;

  //Redux data and Actions
  const user = useUser();
  const summaryFactories = useSummaryFactories();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const listenToSalesOrderTemplates = useCallback(() =>
    listenToData({
      path: [dbTables.COMPANIES, companyId, dbTables.SALES_ORDER_TEMPLATE],
    })(dispatch)
  );

  const [currentTemplate, setCurrentTemplate] = useState({});
  const [openError, setOpenError] = useState(false);
  const [descriptionError, setDescriptionError] = useState("");
  const [openReadOnlyModal, setReadOnlyModal] = useState(false);
  const [calendarRange, setCalendarRange] = useState({
    headers: [],
    subheaders: [],
  });
  const [confirmationModal, setConfirmationModal] = useState(false);
  const users = useCompanyUsers({});

  useEffect(() => {
    const days = getStarFinishCalendarDate({ endDate, startDate });
    setCalendarRange({
      headers: days,
    });
  }, [startDate, endDate]);

  useEffect(() => {
    if (salesOrderTemplateId) {
      listenToSalesOrderTemplates();
    }
  }, [salesOrderTemplateId]);

  useEffect(() => {
    async function init() {
      const latestTemplateVersionSnap = await getDocs(
        query(
          collection(
            firestore,
            `${dbTables.COMPANIES}/${companyId}/${dbTables.SALES_ORDER_TEMPLATE}`
          ),
          orderBy("version", "desc"),
          limit(1)
        )
      );
      const latestTemplateVersion = latestTemplateVersionSnap.docs.map(
        (doc) => ({
          ...doc.data(),
        })
      )[0];
      if (!latestTemplateVersion) {
        setCurrentTemplate({ version: 1, id: getRandomId() });
        dispatchGanttChartTemplate({
          type: GANTT_CHART_ACTION.COMMON,
          payload: {
            tasks: [],
          },
        });
        return;
      } else {
        setCurrentTemplate({ ...latestTemplateVersion });
      }
    }
    init();
  }, [companyId]);

  useEffect(() => {
    if (currentTemplate.id) {
      getTasksTemplate({ templateId: currentTemplate.id });
    }
  }, [currentTemplate.id]);

  async function getTasksTemplate({ templateId }) {
    const templateTasks = await fetchSalesOrderTasks({
      companyRef: `${dbTables.COMPANIES}/${companyId}`,
      salesOrderTemplateId: templateId,
    });
    const combinedList = sortTaskList(templateTasks);
    const combinedListWithIndex = combinedList.map((item, index) => ({
      ...item,
      startDate: moment().valueOf(),
      finishDate: moment().add(item.duration, "days").valueOf(),
      dependencyType: index === 0 ? "" : item.dependencyType,
      dependency: index === 0 ? "" : item.dependency,
      index,
    }));
    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        tasks: combinedListWithIndex,
        initializedTasks: combinedListWithIndex,
      },
      typeChange: "reset",
    });
    setLoading(false);
  }

  async function resetTemplate() {
    setLoading(true);
    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        tasks: [],
      },
    });
    const latestTemplateVersionSnap = await getDocs(
      query(
        collection(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${dbTables.SALES_ORDER_TEMPLATE}`
        ),
        orderBy("version", "desc"),
        limit(1)
      )
    );
    const latestTemplateVersion = latestTemplateVersionSnap.docs.map((doc) => ({
      ...doc.data(),
    }))[0];
    console.log("latestTemplateVersion", latestTemplateVersion);
    console.log("currentTemplate", currentTemplate);

    if (latestTemplateVersion.id === currentTemplate.id) {
      getTasksTemplate({ templateId: currentTemplate.id });
    } else {
      setCurrentTemplate({ ...latestTemplateVersion });
    }

    setLoading(false);
  }

  function validateCreation() {
    let ableToCreate = true;
    const isSomeTaskWithoutDependency = tasks.some((item) => {
      if (tasks.findIndex((task) => task.id === item.id) !== 0) {
        return !tasks.map((task) => task.id).includes(item.dependency);
      } else {
        return false;
      }
    });
    if (isSomeTaskWithoutDependency) {
      ableToCreate = false;
    }
    const taskList = taskListByStage(tasks);
    STAGE_LIST.forEach((stage) => {
      if (
        taskList[stage].some((item) => {
          if (tasks.findIndex((task) => task.id === item.id) !== 0) {
            return (
              !item.assignedTo ||
              !item.duration ||
              !item.dependency ||
              !item.dependencyType
            );
          } else {
            return !item.assignedTo || !item.duration;
          }
        })
      ) {
        ableToCreate = false;
      }
    });
    if (!ableToCreate) {
      setDescriptionError(" Can't create template with an unassigned task");
      setOpenError(true);
      return false;
    }
    const { status } = verifyTaskTemplateErrors({
      isPOVerifier: false,
      SOTaskTemplate: tasks,
    });
    if (status === 400) {
      setDescriptionError("Template cannot have a circular dependency");
      setOpenError(true);
      return false;
    }

    return true;
  }

  function updateTemplate() {
    setLoading(true);

    if (!validateCreation()) {
      setLoading(false);

      return;
    }
    const { addedTasks, deletedTasks, updatedTasks, fieldChanged } =
      getChangesFromBeforeAndCurrentTasks({
        tasks,
        initializedTasks,
        type: dbTables.SALES_ORDER_TEMPLATE_CHANGE_LOG,
      });
    if (
      shouldUpdateVendorTemplate({ addedTasks, deletedTasks, fieldChanged })
    ) {
      summaryFactories.forEach((summary) => {
        const factories = summary.factories.map((factory) => ({
          ...factory,
          isOutdated: true,
        }));
        updateDoc(summary.ref, {
          factories,
        });
      });
    }
    const batch = writeBatch(firestore);
    const newVersionId = getRandomId();
    const textAreaEl = document.getElementById(
      "template-changes-description-text-area"
    );
    const commitDescription = textAreaEl.value;
    batch.set(
      doc(
        firestore,
        `${dbTables.COMPANIES}/${companyId}/${dbTables.SALES_ORDER_TEMPLATE}/${newVersionId}`
      ),
      {
        ...new SalesOrderTemplateObj({
          id: newVersionId,
          version: currentTemplate ? +currentTemplate.version + 1 : 1,
          user: user.id,
          description: commitDescription,
        }),
      }
    );

    const changeLogId = getRandomId();
    batch.set(
      doc(
        firestore,
        `${dbTables.COMPANIES}/${companyId}/${dbTables.SALES_ORDER_TEMPLATE_CHANGE_LOG}/${changeLogId}`
      ),
      {
        ...new ChangeLog({
          addedTasks,
          deletedTasks,
          updatedTasks,
          version: currentTemplate ? +currentTemplate.version + 1 : 1,
          versionId: newVersionId,
          user: user.id,
          description: commitDescription,
          id: changeLogId,
        }),
      }
    );

    setCurrentTemplate({
      ...new SalesOrderTemplateObj({
        id: newVersionId,
        version: currentTemplate ? +currentTemplate.version + 1 : 1,
        description: commitDescription,
      }),
    });
    const taskList = taskListByStage(tasks);
    STAGE_LIST.forEach((stage) => {
      taskList[stage].forEach((item) => {
        batch.set(
          doc(
            firestore,
            `${dbTables.COMPANIES}/${companyId}/${dbTables.SALES_ORDER_TEMPLATE}/${newVersionId}/${dbTables.SALES_ORDER_TASKS_TEMPLATE}/${item.id}`
          ),
          {
            ...item,
            number:
              tasks.findIndex((indexItem) => indexItem.id === item.id) + 1,
            dependencyType:
              tasks.findIndex((indexItem) => indexItem.id === item.id) !== 0
                ? item.dependencyType || ""
                : "",
            dependency:
              tasks.findIndex((indexItem) => indexItem.id === item.id) !== 0
                ? item.dependency || ""
                : "",
          }
        );
      });
    });

    batch.commit().then(() => {
      setLoading(false);
      dispatchGanttChartTemplate({
        type: GANTT_CHART_ACTION.COMMON,
        typeChange: "reset",
      });
    });
  }

  function handleChangeTaskDuration(taskId, offset, direction) {
    const newTasks = changeTaskDuration({ direction, offset, taskId, tasks });
    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        tasks: newTasks,
      },
      typeChange: TASK_TEMPLATE_OPTIONS.CHANGED_DATA,
    });
  }

  function onDropTask(task, offset) {
    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.UPDATE_TASK,
      payload: {
        id: task.id,
        offset: task.offset + offset,
      },
      typeChange: TASK_TEMPLATE_OPTIONS.ADD_TASK,
    });
  }

  const addStageTask = (currentStage) => {
    const newTaskList = taskListByStage(tasks);
    newTaskList[currentStage].push({
      ...new SalesOrderTask({
        description: "New Task",
        salesOrderId: salesOrderTemplateId,
        listIndex: newTaskList[currentStage].length + 1,
        stage: currentStage,
        duration: 1,
        offset: 0,
      }),
      color: AppConfig.themeColors.salesOrderBackgroundGanttChartColor,
    });

    const updatedTaskList = sortTaskList(Object.values(newTaskList).flat());
    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        tasks: updatedTaskList.map((taskData, index) => ({
          ...taskData,
          dependencyType: index === 0 ? "" : taskData.dependencyType,
          dependency: index === 0 ? "" : taskData.dependency,
          index,
        })),
      },
      typeChange: TASK_TEMPLATE_OPTIONS.ADD_TASK,
    });
  };

  //TODO
  unstable_usePrompt({
    when: !!Object.keys(templateChanges).length,
    message: "Are you sure you want to leave without saving?",
  });
  return (
    <SalesOrderTemplateContainerStyled className="sales-order-container">
      {loading && <LoadingBackdrop withLogo={true} />}
      {confirmationModal && (
        <AttentionModal
          title={
            <div
              style={{
                textAlign: "center",
                color: colors.primaryDark,
              }}
            >
              Save changes?
            </div>
          }
          isOpen={confirmationModal}
          onClose={() => setConfirmationModal(false)}
          onClick={() => {
            setConfirmationModal(false);
            updateTemplate();
          }}
          cancelText="Cancel"
          confirmationText="Save"
          cancellable={true}
          description={
            <div>
              <TextareaAutosize
                id="template-changes-description-text-area"
                placeholder="Optional notes (but highly recommended)"
                style={{
                  width: "100%",
                  padding: 10,
                  fontSize: 14,
                  border: "1px solid #d1d1d1",
                  borderRadius: 5,
                  marginBottom: 10,
                }}
                minRows={12}
                maxRows={15}
              />
            </div>
          }
          bodyStyles={{
            height: "auto",
          }}
          styleModal={{
            height: "auto",
          }}
        />
      )}

      {openReadOnlyModal && (
        <ReadOnlyModal
          isOpen={openReadOnlyModal}
          onClick={() => setReadOnlyModal(false)}
          onClose={() => setReadOnlyModal(false)}
        />
      )}
      <CustomSnackbar
        handleCloseSnackbar={() => {
          setOpenError(false);
        }}
        variant="error"
        open={openError}
        message={descriptionError}
      />
      <div className="sales-order-title-container">
        <div className="left-title-container">
          <span className="title">
            <IntlMessages id="salesordertemplate.title" />
          </span>
          <span className="label">
            <b>Created: </b>
            {moment(currentTemplate.creationDate).format("M/D/YY hh:mma")}
          </span>
          <span className="label">
            <b>Version: </b>
            {currentTemplate.version},
          </span>
          <div className="created-by">
            {getUserAvatar({
              user: users.find((user) => user.id === currentTemplate.user),
              styles: {
                width: 24,
                height: 24,
                fontSize: 12,
                outline: "1px solid #000",
              },
              className: "avatar-displayed",
            })}
            <span>
              {getCompanyUserDisplayName(users, currentTemplate.user)}
            </span>
          </div>
          <TooltipTD
            label={currentTemplate.description}
            className="description-tooltip"
          >
            <span className="description-span">
              {currentTemplate.description}
            </span>
          </TooltipTD>
        </div>
        <div className="right-buttons-container">
          <TemplateChangeLog
            changeLogsPath={`${dbTables.COMPANIES}/${companyId}/${dbTables.SALES_ORDER_TEMPLATE_CHANGE_LOG}`}
            scope={dbTables.SALES_ORDER_TASKS_TEMPLATE}
          />

          {Object.keys(templateChanges).length > 0 && (
            <>
              <Button
                onClick={resetTemplate}
                style={{
                  fontWeight: 500,
                  fontSize: 14,
                  textWrap: "nowrap",
                }}
                color="error"
                variant="contained"
              >
                <IntlMessages id="components-salesOrders-salesOrderTemplate-DiscardChanges" />
              </Button>
              <Button
                onClick={() => setConfirmationModal(true)}
                style={{
                  fontWeight: 500,
                  fontSize: 14,
                  textWrap: "nowrap",
                }}
                color="info"
                variant="contained"
              >
                <IntlMessages id="components-salesOrders-salesOrderTemplate-SavenewVersionofTemplate" />
              </Button>
            </>
          )}
        </div>
      </div>

      <GanttChartTemplateStyled className="ganttChartContainer">
        <div
          className="task-content-ganttchart-header-container"
          style={{ height: 80 }}
        >
          <TableContentSetup soTemplate />
          <GanttChartHeader calendarRange={calendarRange} />
        </div>
        <div className="task-content-and-ganttchart-container">
          <TaskContent tasks={tasks} onAddTask={addStageTask} />
          <CollapseExpandButtons
            dispatchGanttChartTemplate={dispatchGanttChartTemplate}
            tableSize={tableSize}
          />
          <GanttChart
            tasks={getTemplateTaskWithDate(
              filterTaskByPhases({ tasks, taskPhases })
            )}
            calendarRange={calendarRange}
            onChangeTaskDuration={handleChangeTaskDuration}
            onDropTask={onDropTask}
          />
        </div>
      </GanttChartTemplateStyled>
    </SalesOrderTemplateContainerStyled>
  );
}

export default SalesOrderTemplate;
