import { cx } from "@emotion/css";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import TooltipTD from "../../Tooltip/TooltipTD";
import defaultImage from "../../../assets/flag-icons/no_image_available.jpg";

import { getPOPathBySKU, getSKUProperties } from "./SKUHelper";
import { formatCash, formatWithComma } from "../../../helpers/helpers";
import itemStatus from "../../../api/types/itemStatus";
import { useQueryParams } from "../../../hooks";
import { setBackdropPanelSection } from "../../../actions/DataActions";
import {
  BACKDROP_TYPE,
  COMPANY_PERMISSION_VALUE,
  SALES_ORDER_STATUS,
  generalPermissions,
  notAllowedToSee,
} from "../../../helpers/constants";
import { useDispatch } from "react-redux";
import AttentionModal from "../../Modal/AttentionModal";
import SKUEditableLabelV2 from "./SKUEditableLabelV2";
import {
  useIsAllowedCompany,
  useIsAllowedFunction,
} from "../../../hooks/permissions";

function SimpleSORowSKU({
  itemSKU,
  showImage,
  handlePhotosViewer,
  entity,
  prefix,
  purchaseOrders,
  onChangeData = () => {},
}) {
  const {
    cartons = 0,
    cbmMaster,
    description,
    extendedCost,
    image,
    itemNumber,
    piecesPerMaster,
    quantity,
    totalCBM,
    price,
    purchaseOrder = {},
    status,
  } = getSKUProperties({ itemSKU, prefix, entity, purchaseOrders });
  const isCompanyAllowed = useIsAllowedCompany();
  const isAllowed = useIsAllowedFunction();
  const mismatch = itemSKU.mismatchStatus || {};
  const queryParams = useQueryParams();
  const dispatch = useDispatch();
  const [modalAttention, setModalAttention] = useState(false);
  const isReadOnly =
    itemSKU.status === itemStatus.READ_ONLY ||
    entity.status === SALES_ORDER_STATUS.VOIDED;
  const blockRedirect = itemStatus.READ_ONLY === status || !purchaseOrder.id;

  const onClickRedirect = (ev) => {
    if (!blockRedirect) {
      setTimeout(() => {
        setBackdropPanelSection({
          type: BACKDROP_TYPE.ITEM_TABLE_PO,
          id: itemSKU.id,
          dispatch,
        });
      }, 1000);
    } else {
      ev.preventDefault();
      setModalAttention(true);
    }
  };

  const isAllowedToShowPrice = isAllowed(generalPermissions.SALES_ORDER_AMOUNT);

  return (
    <div
      className={cx("itemSKURow", { withImage: showImage })}
      key={itemSKU.id}
    >
      {modalAttention && (
        <AttentionModal
          title="Attention"
          description={"This item is not part of any PO in this project"}
          isOpen={modalAttention}
          onClick={() => setModalAttention(false)}
        />
      )}
      <div className={"cellRow"}>
        {itemSKU.notAllowedToSee ? (
          notAllowedToSee
        ) : (
          <NavLink
            to={
              blockRedirect
                ? null
                : getPOPathBySKU({ purchaseOrder, queryParams })
            }
            className={cx("navLinkClass")}
            onClick={onClickRedirect}
          >
            {itemNumber}
          </NavLink>
        )}
      </div>
      <div className={cx("cellRow", "centerAlign")}>
        {showImage && !itemSKU.notAllowedToSee && (
          <img
            className="image"
            src={image}
            alt={`${itemNumber}Img`}
            onClick={() => {
              handlePhotosViewer({
                open: true,
                photos: [
                  ...itemSKU.image.map((el) => ({
                    src: el || defaultImage,
                    number: itemSKU.itemNumber,
                    description: itemSKU.description,
                  })),
                ],
              });
            }}
          />
        )}
      </div>
      <div
        className={cx("customized-tooltip", {
          hiddenPrice: !isAllowedToShowPrice,
        })}
        id={description}
      >
        {itemSKU.notAllowedToSee ? (
          notAllowedToSee
        ) : (
          <TooltipTD label={description} className="tooltTipSpan">
            {description}
          </TooltipTD>
        )}
      </div>

      <div
        className={cx("cellRow", "alignRight", {
          dangerStyle: itemSKU.notAllowedToSee
            ? false
            : mismatch.salesOrder || mismatch.purchaseOrder,
        })}
      >
        {itemSKU.notAllowedToSee ? (
          notAllowedToSee
        ) : (
          <SKUEditableLabelV2
            showToolTip={mismatch.salesOrder || mismatch.purchaseOrder}
            value={quantity}
            tooltipLabel={
              "This item quantity does not match the quantity in the PO"
            }
            disabled={
              !isCompanyAllowed(
                COMPANY_PERMISSION_VALUE.SALES_ORDER_QUANTITY
              ) || isReadOnly
            }
            preventDelete
            labelFormat={(value) => formatWithComma(value)}
            onChangeLabel={(temporalLabel) => {
              onChangeData({
                item: itemSKU,
                row: {
                  ...itemSKU,
                  salesOrderQuantity: temporalLabel,
                },
              });
            }}
          />
        )}
      </div>
      {isAllowedToShowPrice && (
        <div className={cx("cellRow", "alignRight")}>
          {itemSKU.notAllowedToSee ? (
            notAllowedToSee
          ) : (
            <SKUEditableLabelV2
              value={price}
              disabled={
                !isCompanyAllowed(COMPANY_PERMISSION_VALUE.SALES_ORDER_PRICE) ||
                isReadOnly
              }
              preventDelete
              inputType="cash"
              labelFormat={(value) => formatCash(value)}
              onChangeLabel={(temporalLabel) => {
                onChangeData({
                  item: itemSKU,
                  row: {
                    ...itemSKU,
                    salesOrderPrice: temporalLabel,
                  },
                });
              }}
            />
          )}
        </div>
      )}
      {isAllowedToShowPrice && (
        <div className={cx("cellRow", "alignRight", "extendedCost")}>
          {itemSKU.notAllowedToSee ? notAllowedToSee : formatCash(extendedCost)}
        </div>
      )}
      <div className={cx("cellRow", "alignRight", "cbmMaster")}>
        {itemSKU.notAllowedToSee ? notAllowedToSee : cbmMaster}
      </div>
      <div className={cx("cellRow", "alignRight", "piecesPerMaster")}>
        {itemSKU.notAllowedToSee ? notAllowedToSee : piecesPerMaster}
      </div>
      <div
        className={cx("cellRow", "alignRight", "cartons", {
          dangerStyle: itemSKU.notAllowedToSee ? false : cartons % 1 !== 0,
        })}
      >
        {itemSKU.notAllowedToSee ? (
          notAllowedToSee
        ) : (
          <TooltipTD
            label={"The order quantity is not a whole number of cartons"}
            showToolTip={cartons % 1 !== 0}
          >
            {cartons.toLocaleString("en-US", {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })}
          </TooltipTD>
        )}
      </div>

      <div className={cx("cellRow", "alignRight", "totalCBM")}>
        {itemSKU.notAllowedToSee ? notAllowedToSee : totalCBM}
      </div>
    </div>
  );
}

export default SimpleSORowSKU;
