import { getRandomId } from "../../helpers/helpers";

export default class Factory {
  constructor({
    id = getRandomId(),
    name = "",
    number = "",
    mainContact = "",
    terms = "",
    notes = "",
    companyId = "",
    factoriesSummaryId = "",
    version = 1,
  }) {
    this.id = id;
    this.name = `${name}`.trim();
    this.number = number;
    this.mainContact = mainContact;
    this.terms = terms;
    this.notes = notes;
    this.companyId = companyId;
    this.factoriesSummaryId = factoriesSummaryId;
    this.version = version;
  }
}
