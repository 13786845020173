import { styled } from "@mui/material";
import { GANTT_CHART_SETTINGS } from "../../../helpers/ganttChart";
import { colors } from "../../../assets/jss/variables";

const scrollButtonContainerStyles = {
  width: 80,
  height: 80,
  position: "absolute",
  zIndex: 10,
  background: "#8D8D8D",
  opacity: 0.3,
  borderRadius: 15,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
export const GanttChartTemplateStyled = styled("div")(() => ({
  "&.ganttChartContainer": {
    display: "flex",
    overflow: "hidden",
    height: "100%",
    padding: 12,
    flexDirection: "column",
    background:"white",
    "& .task-content-ganttchart-header-container": {
      display: "flex",
      width: "100%",
      height: GANTT_CHART_SETTINGS.ROW_HEIGHT * 2,
    },
    "& .task-content-and-ganttchart-container": {
      display: "flex",
      gap: 1,
      width: "100%",
      overflow: "hidden",
      position: "relative",
      background: "white",
      "& .ganttchart-row-container": {
        position: "absolute",
        height: GANTT_CHART_SETTINGS.ROW_HEIGHT,
      },
      "& .expandCollapseContainer": {
        width: 20,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        zIndex: 5,
        transition: "all 0.5s",
        height: 20,
        top: "48%",
        gap: 5,
        flexDirection: "column",
        "& .expand-collapse-icon": {
          background: "#fff",
          borderRadius: "50%",
          color: colors.primaryDark,
          cursor: "pointer",
          transform: "rotate(90deg)",

          "&:hover": {
            transform: "scale(1.5) rotate(90deg) !Important",
            transition: "all 0.5s",
          },
        },
        "& .rotateIcon": {
          transform: "rotate(-90deg)",
          "&:hover": {
            transform: "scale(1.5) rotate(-90deg) !Important",
            transition: "all 0.5s",
          },
        },
      },
      "& .SMALL": {
        left: "14%",
      },
      "& .MEDIUM": {
        left: "39%",
      },
      "& .LARGE": {
        left: "98%",
      },
    },
    "& .scrollToRightButtonContainer": {
      ...scrollButtonContainerStyles,
      right: 50,
      top: 30,
      "& .img-scroll-to-right": {
        cursor: "pointer",
      },
    },
    "& .scrollToLeftButtonContainer": {
      ...scrollButtonContainerStyles,
      right: 135,
      top: 30,
      "& .img-scroll-to-left": {
        transform: "rotate(180deg)",
        cursor: "pointer",
      },
    },
  },
}));
