import { cx } from "@emotion/css";
import React, { useState, useEffect } from "react";
import { useRef } from "react";
import { EditableLabelStyled } from "./styles";
import TooltipTD from "../Tooltip/TooltipTD";

function SimpleEditableLabel({
  text = 0,
  onSave = () => {},
  onCancel = () => {},
  className = "",
  disable = false,
  showTooltip = false,
  onPressEnter = () => {},
  alwaysInput = false,
  onPressTab,
  onClick = () => {},
  labelDescription,
  textStyle = {},
}) {
  const [value, setValue] = useState("");
  const [editing, setEditing] = useState(alwaysInput);
  const inputRef = useRef(null);
  useEffect(() => {
    if (editing) {
      setValue(text);
      if (!alwaysInput) {
        setTimeout(() => {
          if (inputRef) {
            inputRef.current.focus();
            inputRef.current.select();
          }
        }, 50);
      }
    }
  }, [editing]);

  useEffect(() => {
    if (alwaysInput) {
      setEditing(alwaysInput);
    }
  }, [alwaysInput]);

  useEffect(() => {
    if (!alwaysInput && disable && editing) {
      setEditing(false);
    }
  }, [disable]);

  function handleSave() {
    let parseValue = value.trim();
    if (value === text || !parseValue) {
      handleCancel();
    } else {
      const ev = { target: { value: parseValue } };
      onSave(ev);
      setEditing(alwaysInput);
    }
  }

  const handleCancel = () => {
    onCancel();
    setValue(text);
    setEditing(alwaysInput);
  };

  return (
    <EditableLabelStyled className={cx("containerEditableLabel", className)}>
      {editing ? (
        <input
          id="text-editable-label"
          ref={inputRef}
          className="editableLabel"
          value={value}
          onChange={(e) => {
            let { value } = e.target;
            setValue(value.replace(/\s\s+/g, " "));
          }}
          autoFocus={!alwaysInput}
          onClick={onClick}
          onBlur={(ev) => {
            if (editing) {
              handleSave(value);
            }
            setEditing(alwaysInput);
            ev.stopPropagation();
          }}
          onKeyUp={(ev) => {
            if (ev.key === "Enter") {
              handleSave();
              onPressEnter();
            } else if (ev.key === "Escape") {
              handleCancel();
            }
            if (onPressTab && ev.key === "Tab") {
              handleSave();
              onPressTab();
            }
            ev.stopPropagation();
          }}
        />
      ) : (
        <TooltipTD
          className="text-static-name"
          showToolTip={showTooltip}
          label={text}
          style={{
            cursor: disable ? "default" : "pointer",
            fontWeight: disable ? "400" : "",
            opacity: disable ? 0.5 : 1,
            ...textStyle,
          }}
          tooltipClick={(ev) => {
            if (!editing && !disable) {
              setEditing(true);
            }
            ev.stopPropagation();
          }}
        >
          {labelDescription || text}
        </TooltipTD>
      )}
    </EditableLabelStyled>
  );
}

export default SimpleEditableLabel;
