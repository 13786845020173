import { getRandomId } from "../../helpers/helpers";

export default class Role {
  constructor({
    id = getRandomId(),
    name = '',
  }) {
    this.id = id;
    this.name = name;
  }
}