import { colors } from "../assets/jss/variables";
import { styled } from "@mui/material";

export const AuthPageStyled = styled("div")({
  "&.authContainer": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& .magicLinkField": {
      width: "90%",
      maxWidth: 500,
      marginRight: 2,
      background: "#fff",
      borderRadius: 10,
      border: "1px solid #25486D",
      fontSize: 18,
      fontWeight: 400,
      textAlign: "left",
      marginBottom: 35,
      "& fieldset": {
        border: 0,
      },
      "& input": {
        color: colors.primaryDark,
      },
    },
    "& .signInText": {
      fontSize: 36,
      fontWeight: 400,
      color: colors.primaryDark,
      marginTop: 123,
      marginBottom: 84,
    },
    "& .buttonConfirmation": {
      padding: " 10px 39px",
      gap: "10px",
      width: 275,
      height: 41,
      background: colors.primaryDark,
      borderRadius: 6,
      fontWeight: 400,
      fontSize: 18,
      color: "#FFFFFF",
      marginBottom: 10,
    },
    "& .informationText": {
      fontWeight: 400,
      fontSize: 24,
      lineHeight: "28px",
      textAlign: "center",
      color: "#25486D",
      marginTop: -45,
      marginBottom: 70,
    },
  },
});
