import { cx } from "@emotion/css";
import { createContext, useReducer } from "react";
import { GanttChartProviderStyled } from "./styles";
import { CALENDAR_SETUP } from "../../helpers/ganttChart";
import moment from "moment";
import { labelTaskPhases } from "../../helpers/constants";

export const GanttChartContext = createContext(null);
export const GANTT_CHART_ACTION = {
  COMMON: "COMMON",
};
export const GANTTCHART_TABLE_SIZE = {
  SMALL: "SMALL",
  MEDIUM: "MEDIUM",
  LARGE: "LARGE",
};
export const TaskTableSize = {
  [GANTTCHART_TABLE_SIZE.MEDIUM]: 580,
  [GANTTCHART_TABLE_SIZE.SMALL]: 150,
  [GANTTCHART_TABLE_SIZE.LARGE]: 700,
};

const initialState = {
  viewOptions: [],
  milestones: [],
  calendarSetup: CALENDAR_SETUP.WEEKLY,
  startDate: moment("2023-02-01").startOf("isoWeek"),
  endDate: moment("2026-12-31").endOf("isoWeek"),
  highlightedTask: null,
  collapseListTask: false,
  tableSize: GANTTCHART_TABLE_SIZE.MEDIUM,
  showPOs: true,
  reRenderArrow: false,
  POAlreadySetup: {},
  currentSO: {},
  currentPOs: [],
  currentShipments: [],
  graphTableRef: null,
  taskPhases: {
    [labelTaskPhases.PROPOSAL_QUOTE]: true,
    [labelTaskPhases.PRE_PRODUCTION]: true,
    [labelTaskPhases.PRODUCTION]: true,
    [labelTaskPhases.BOOKING_TRANSIT]: true,
    [labelTaskPhases.PAYMENT_BILLING]: true,
  },
};

export function GanttChartProvider({ children }) {
  const [ganttChartState, dispatchGanttChart] = useReducer(
    ganttChart,
    initialState
  );

  return (
    <GanttChartProviderStyled
      className={cx("ganttChartProvider")}
      id="dashboard-activities"
    >
      <GanttChartContext.Provider
        value={{
          ganttChartState,
          dispatchGanttChart,
        }}
      >
        {children}
      </GanttChartContext.Provider>
    </GanttChartProviderStyled>
  );
}

function ganttChart(state, action) {
  switch (action.type) {
    case GANTT_CHART_ACTION.COMMON:
      return {
        ...state,
        ...action.payload,
      };

    default:
      console.log("Unknown action type: ", action.type);
      return state;
  }
}
