/**
 * App Light Theme
 */
import { createTheme } from "@mui/material/styles";
import AppConfig from "../../constants/AppConfig";

const theme = createTheme({
  palette: {
    primary: {
      main: AppConfig.themeColors.secondary,
    },
    secondary: {
      main: AppConfig.themeColors.primary,
    },
  },
});

export default theme;
