export const FSOrgId = "RE18T";
export const HIGH_LIGHT_IO = "4g8619d5";

const globalEnvironments = {
  DEV: "DEV",
  TEST: "TEST",
  STAGING: "STAGING",
  TRAINING: "TRAINING",
  EXHART_PRODUCTION: "EXHART_PRODUCTION",
};

const globalEnvironment = globalEnvironments.EXHART_PRODUCTION;
export const globalURLBackEnd = {
  DEV: "tradedash-dev",
  TEST: "tradedash-test",
  STAGING: "tradedash-staging",
  TRAINING: "tradedash-training-site",
  EXHART_PRODUCTION: "tradedash-exhart",
};

const CLOUD_FUNC_CONFIG = {
  DEV: "r3shgad77q-uc.a.run.app",
  TEST: "medaraswlq-uc.a.run.app",
  STAGING: "ypq3e6iisa-uc.a.run.app",
  TRAINING: "2ulpbhqc4q-uc.a.run.app",
  EXHART_PRODUCTION: "rzc6yud67a-uc.a.run.app",
};
export function getFunctionByName({ name = "", env = "", params = "" }) {
  if (params) {
    return `https://${name}-${CLOUD_FUNC_CONFIG[env]}${params}`;
  } else {
    return `https://${name}-${CLOUD_FUNC_CONFIG[env]}`;
  }
}

export { globalEnvironments, globalEnvironment };
