import { now } from "moment";
import { getRandomId } from "../../helpers/helpers";
import Activity from "./activity";

export default class ActivityTask extends Activity {
  constructor({
    id = getRandomId(),
    creationDate = now(),
    detail = "",
    scope = "",
    type = "",
    user = "",
    companyId = "",
    salesOrderId = "",
    purchaseOrderId = "",
    shipmentId = "",
    taskId = "",
  }) {
    super({
      id,
      creationDate,
      detail,
      scope,
      type,
      user,
      companyId,
      salesOrderId,
      shipmentId,
      purchaseOrderId,
    });
    this.taskId = taskId;
  }
}
