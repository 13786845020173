import React from "react";
import { Tooltip } from "@mui/material";

function getChildren(children, style, id, tooltipClick, className) {
  return (
    <span
      id={id}
      onClick={tooltipClick}
      className={className}
      style={{ zIndex: 1, ...style }}
    >
      {children}
    </span>
  );
}
function TooltipTD({
  label,
  style = {},
  children,
  showToolTip = true,
  id = "",
  tooltipClick = () => {},
  placement = "top",
  className = "",
  ...props
}) {
  if (!showToolTip || !label) {
    return (
      <span
        className={className}
        id={id}
        style={{ ...style }}
        onClick={tooltipClick}
        {...props}
      >
        {children}
      </span>
    );
  }

  return (
    <Tooltip
      disableHoverListener={false}
      disableInteractive
      arrow
      title={<React.Fragment>{label}</React.Fragment>}
      placement={placement}
      {...props}
    >
      {getChildren(children, { ...style }, id, tooltipClick, className)}
    </Tooltip>
  );
}

export default TooltipTD;
