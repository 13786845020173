import { getRandomId } from "../../helpers/helpers";
import { parseNumberValue } from "../../helpers/orderDashboardRefactored";

export default class ExpenseItem {
  constructor({
    id = getRandomId(),
    GLAccountId = "",
    descriptionOfCharges = "",
    notes = "",
    forecast = 0,
    actual = 0,
    index = 1,
  }) {
    this.id = id;
    this.GLAccountId = GLAccountId;
    this.descriptionOfCharges = descriptionOfCharges;
    this.notes = notes;
    this.forecast = forecast;
    this.actual = actual;
    this.delta = parseNumberValue(((forecast - actual) / forecast) * -1).toFixed(4);
    this.index = index;
  }
}
