import moment from "moment";
import { getRandomId } from "../../helpers/helpers";

export default class SharedLink {
  constructor({
    id = getRandomId(),
    creationDate = moment.now(),
    expirationDate = moment().add(14, "days").valueOf(),
    companyId = "",
    parentCollection = "",
    parentDocumentId = "",
    collection = "",
    userId = "",
    link = "",
    document = {},
    child = [],
    documentId = "",
  }) {
    this.id = id;
    this.creationDate = creationDate;
    this.expirationDate = expirationDate;
    this.companyId = companyId;
    this.parentCollection = parentCollection;
    this.parentDocumentId = parentDocumentId;
    this.collection = collection;
    this.userId = userId;
    this.link = link;
    this.document = document;
    this.child = child;
    this.documentId = documentId;
  }
}
