import React, { useCallback, useState } from "react";
import AttentionModal from "../Modal/AttentionModal";
import { useUser, useCompanyUsers } from "../../hooks/user";
// import { trackEvent } from "../../helpers/analytics";
import { errorDocumentName } from "../../helpers/constants";
import {
  getTittleUploadModal,
  getWarningMessage,
  hasExtension,
  processFiles,
  processFolder,
} from "./DocumentHelper";
import { usePermissionGroups } from "../../hooks";
import { cx } from "@emotion/css";
import { DragAndDropStyled } from "./styles";
import ModalUploadingFile from "./ModalUploadingFile";
import { useDropzone } from "react-dropzone";

function DragAndDropUploadFileV2({
  companyId,
  parentCollection,
  parentDocumentId,
  documents,
  handleReadOnlyModal = () => {},
  orderIsVoided,
  documentPath,
  mainEntity = {},
}) {
  const [open, setOpen] = useState(false);
  const [snackOption, setSnackOption] = useState({});
  const [files, setFiles] = useState({});
  const [folders, setFolder] = useState([]);
  const [attentionModalDescription, setAttentionModalDescription] =
    useState("");
  const [stylesDiv, setStylesDiv] = useState(false);
  const permissionGroupsDB = usePermissionGroups({
    companyId,
    filteringByInactive: true,
  });
  const currentUser = useUser();
  const companyUsers = useCompanyUsers({
    showInactiveUsers: false,
    showBotUser: false,
  });

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (orderIsVoided) {
        handleReadOnlyModal();
        return;
      }
      const filesUpload = acceptedFiles.filter((file) =>
        hasExtension(file.name)
      );
      if (filesUpload.length === 0) {
        setAttentionModalDescription(
          <React.Fragment>
            Only files with an extension at the end can be uploaded.
            <br /> Nothing was uploaded in this case.
          </React.Fragment>
        );
        return;
      }
      if (filesUpload.length !== acceptedFiles.length) {
        setSnackOption({
          type: "warning",
          message:
            "Only files with extensions are being uploaded, the others will be skipped.",
        });
      }

      const sortingFiles = [...filesUpload].sort((a, b) =>
        b.name.localeCompare(a.name)
      );
      const foldersData = processFolder({
        filesUpload: sortingFiles,
        currentFiles: documents.filter((rootFile) => !rootFile.parentId),
        documentPath: documentPath,
        currentUser,
      });

      const { filesData, originalFiles } = processFiles({
        filesUpload: sortingFiles,
        currentFiles: documents.filter((rootFile) => !rootFile.parentId),
        documentPath: documentPath,
        foldersData,
      });

      if (!filesData.length) {
        setAttentionModalDescription(errorDocumentName.file);
        return;
      }
      setFolder(foldersData);
      setFiles({ filesData, originalFiles });
      setOpen(true);
      cancelStyleDiv();
    },
    [orderIsVoided, handleReadOnlyModal, documents, documentPath]
  );

  const cleanData = () => {
    setFiles({});
    setOpen(false);
    handleCloseAlert();
    setFolder([]);
  };

  const cancelStyleDiv = () => {
    if (stylesDiv) {
      setStylesDiv(false);
    }
  };

  const handleCloseAlert = (ev, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOption({});
  };

  const onDragEnter = (event) => {
    event.preventDefault();
    if (orderIsVoided || stylesDiv) {
      return;
    }
    setStylesDiv(true);
    event.stopPropagation();
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
  });

  return (
    <DragAndDropStyled className="styled_dnd">
      {!!attentionModalDescription && (
        <AttentionModal
          isOpen={!!attentionModalDescription}
          title="Attention"
          description={attentionModalDescription}
          onClick={() => {
            setAttentionModalDescription("");
            setFiles([]);
            setOpen(false);
          }}
        />
      )}

      {open && (
        <ModalUploadingFile
          title={getTittleUploadModal({
            files: files.originalFiles,
            folders,
            isRoot: true,
          })}
          open={open}
          onClose={cleanData}
          files={files}
          warningMessage={getWarningMessage(snackOption)}
          companyUsers={companyUsers}
          permissionGroupsDB={permissionGroupsDB}
          currentUserId={currentUser.id}
          userId={currentUser.id}
          orderPath={documentPath}
          parentCollection={parentCollection}
          parentDocumentId={parentDocumentId}
          mainEntity={mainEntity}
          folders={folders}
        />
      )}
      <div
        {...getRootProps({})}
        className={cx(
          "dragDropContainer",
          { activeDrop: stylesDiv },
          `dnd${parentCollection}`
        )}
        onDragEnter={onDragEnter}
        onDragLeave={cancelStyleDiv}
        onDragOver={onDragEnter}
      >
        <p style={{ fontFamily: "Avenir Next" }} className="text">
          Drag & Drop Files and Folders
        </p>
        <input
          id="principalDropOption"
          {...getInputProps()}
          style={{ display: "none" }}
        />
      </div>
    </DragAndDropStyled>
  );
}

export default DragAndDropUploadFileV2;
