const userTypes = {
  EMPLOYEE: "EMPLOYEE",
  ADMIN: "ADMIN",
  COMPANY_USER: "COMPANY_USER",
  PO_ADMIN: "PO Admin",
  SUPER_ADMIN: "SuperAdmin",
  CURRENT_USER: "currentUser",
  TRADEDASH_EMPLOYEE: "TRADEDASH_EMPLOYEE",
};

export default userTypes;
