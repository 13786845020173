import React from "react";
//TODO
// import { useInView } from "react-intersection-observer";

function VirtualScrollList({
  list = [],
  itemHeight = 0,
  renderItem = () => {},
  className = "",
  expanded,
}) {
  // const [ref, inView] = useRef();
  const style = {
    height: `${itemHeight}`,
    overflow: "hidden",
  };

  return (
    <div
      //  ref={ref}
      style={style}
    >
      {expanded ? (
        <div className={className}>
          <div
            className="column1"
            style={{ width: "50%", float: "left", padding: "0px 8px" }}
          >
            {list.map((item, index) => {
              let response = null;
              //if (index % 2 === 0) response = renderItem(item, index, inView);

              if (index % 2 === 0) response = renderItem(item, index, true);
              return response;
            })}
          </div>
          <div
            className="column2"
            style={{ width: "50%", float: "left", padding: "0px 8px" }}
          >
            {list.map((item, index) => {
              let response = null;
              if (index % 2 !== 0) response = renderItem(item, index);
              return response;
            })}
          </div>
        </div>
      ) : (
        <div className={className}>
          {list.map((item, index) => {
            //return renderItem(item, index, inView);
            return renderItem(item, index, true);
          })}
        </div>
      )}
    </div>
  );
}

export default VirtualScrollList;
