import { createContext, useReducer } from "react";

export const TaskGroupedByProjectContext = createContext(null);

export const TASK_GROUPED_BY_PROJECT_ACTION = {
  COMMON: "COMMON",
  ADDING_TASKS: "ADDING_TASKS",
  CLEAN: "CLEAN",
};

export function TaskByProjectProvider({ children }) {
  const [taskByProjectContext, dispatchTaskByProject] = useReducer(
    taskBySalesOrderReducer,
    initialState
  );
  return (
    <TaskGroupedByProjectContext.Provider
      value={{
        taskByProjectContext,
        dispatchTaskByProject,
      }}
    >
      {children}
    </TaskGroupedByProjectContext.Provider>
  );
}

const initialState = {
  currentSalesOrder: {},
  purchaseOrders: [],
  shipments: [],
  tasks: {},
  cleanListener: false,
  signShipment: false,
};

function taskBySalesOrderReducer(state, action = {}) {
  switch (action.type) {
    case TASK_GROUPED_BY_PROJECT_ACTION.COMMON:
      return {
        ...state,
        ...action.payload,
      };
    case TASK_GROUPED_BY_PROJECT_ACTION.ADDING_TASKS: {
      return {
        ...state,
        tasks: {
          ...state.tasks,
          ...action.payload,
        },
      };
    }
    case TASK_GROUPED_BY_PROJECT_ACTION.CLEAN:
      return { ...initialState, ...action.payload };

    default:
      console.log("Unknown action type: ", action.type);
      return state;
  }
}
