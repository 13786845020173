import { dbTables } from "../../../api/types/dbTables";
import { colors } from "../../../assets/jss/variables";
import AppConfig from "../../../constants/AppConfig";
import { ListItemText, Tabs, styled } from "@mui/material";

export const ManagementDocumentNoteHeaderStyled = styled("div")(() => ({
  "&.documentsHeader": {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0px 10px",
    color: colors.primaryDark,
    margin: "22px 0px",
  },
  "& .buttonExpandCollapse": {
    background: "white",
    color: colors.primaryDark,
    border: "1px solid #25486d",
    height: 26,
    fontSize: 10,
    fontWeight: 500,
    padding: 5,
    width: 82,
    minWidth: "70px !important",
    marginLeft: 22,

    borderRadius: 4,
  },
  "& .documentsHeaderTitleDocument": {
    fontSize: 19,
    fontWeight: 500,
  },
  "& .documentsHeaderButtonAddDocument": {
    display: "flex",
    alignItems: "center",
    marginRight: 4,
    "& .addNewFolder": {
      margin: 4,
      "&:hover": {
        cursor: "pointer",
      },
      "&:active": {
        transform: "scale(0.9)",
      },
      "& span": {
        fontSize: 13,
        fontWeight: 600,
        paddingLeft: 4,
      },
    },
  },
  "& .imgNewFolderPlaceholder": {
    width: 15,
    height: 16,
    paddingBottom: 1,
  },
  "& .documentsIconAddDocument": {
    cursor: "pointer",
  },
}));

export const ManagementDocumentNoteStyled = styled("div")(() => ({
  "&.managementDocumentContainer": {
    width: "100%",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    position: "relative",
  },
  "& .document-folder-container": {
    position: "relative",
    marginBottom: 16,
    "&:before": {
      content: "''",
      position: "absolute",
      top: 72,
      left: 33,
      width: 1,
      height: "calc(100% - 82px)",
      background: colors.dividerColor,
    },
    "&:after": {
      content: "''",
      position: "absolute",
      left: 34,
      width: "calc(100% - 68px)",
      height: 1,
      background: colors.dividerColor,
    },
  },
  "& .stickyHeader": {
    padding: "20px 36px 3px 14px",
    "& .searchBoxDocuments:after": {
      width: 0,
    },
  },

  "& .documentsList": {
    padding: "0px 10px 0px 16px",
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent",
      width: 16,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "transparent",
      borderRadius: 7,
    },
    "&::-webkit-scrollbar-track": {
      border: " solid 1px transparent",
    },
    "&:hover": {
      "&::-webkit-scrollbar-thumb": {
        boxShadow: `inset 0 0 10px 10px ${colors.thumbScrollColor}`,
        borderBlock: "1px solid transparent",
        borderInline: "5px solid transparent",
      },
    },
  },

  [`& .background${dbTables.SALES_ORDERS}`]: {
    background: AppConfig.themeColors.salesOrderBackgroundColor,
    outline: `3px solid ${AppConfig.themeColors.salesOrderBackgroundColor}`,
  },
  [`& .background${dbTables.PURCHASE_ORDERS}`]: {
    background: AppConfig.themeColors.purchaseOrderBackgroundColor,
    outline: `3px solid ${AppConfig.themeColors.purchaseOrderBackgroundColor}`,
  },
  [`& .background${dbTables.SHIPMENTS}`]: {
    background: AppConfig.themeColors.shipmentColor,
    outline: `3px solid ${AppConfig.themeColors.shipmentColor}`,
  },
  "& .folderStructureWrapper": {
    height: "100%",
    display: "flex",
    maxHeight: "calc(100vh - 367px)",
  },
  "& .folderStructureList": {
    overflow: "auto",
    userSelect: "none",
    height: "100%",
    width: "100%",
    padding: "0px 18px",
    paddingTop: 5,
    marginRight: 3,
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent",
      width: 16,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "transparent",
      borderRadius: 7,
    },
    "&::-webkit-scrollbar-track": {
      border: " solid 1px transparent",
    },
    "&:hover": {
      "&::-webkit-scrollbar-thumb": {
        boxShadow: `inset 0 0 10px 10px ${colors.thumbScrollColor}`,
        borderBlock: "1px solid transparent",
        borderInline: "5px solid transparent",
      },
    },
  },
}));

export const ListItemTextDocumentNoteStyled = styled(ListItemText)(() => ({
  "&.listItemText": {
    height: 60,
    display: "flex",
    justifyContent: "initial",
    alignItems: "center",
    "& span": {
      width: "100%",
    },
  },
  "& .primaryItemText": {},
  "& .listItemTextPrimary": {
    height: 24,
    color: colors.primaryDark,
    fontSize: 14,
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "& .filename": {
      top: 0,
      fontSize: 14,
      fontWeight: 400,
      color: colors.primaryDark,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },

  "& .listItemTextSecondary": {
    paddingRight: 4,
    "& label": {
      margin: 0,
    },
    "& .fileinfo": {
      fontSize: 14,
      color: "#798A9B",
      fontFamily: "Roboto",
      height: 13,
      flexWrap: "wrap",
    },
  },
  "& .fileInformationContainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "& .fileInformationBottom": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

export const ListItemNoteDocumentStyled = styled("div")(() => ({
  "& .listDocumentNote": {
    "& .permissionIcon": {
      opacity: 0,
      cursor: "pointer",
    },
  },
  "& .listemItemDocument": {
    margin: "0px 10px",

    "& ul": {
      paddingTop: 0,
      paddingBottom: 0,
      margin: 0,
    },
    "& .listItemFile": {
      borderRadius: 4,
      marginLeft: 0,
      paddingLeft: 0,
      paddingRight: 0,
      "&:hover": {
        outlineOffset: -2,
        borderRadius: 4,
      },
      height: 75,
      margin: "10px 0px",
    },

    "& .listItemFolderClose": {
      border: "1px solid #e5edf7",
      borderRadius: 4,
      marginLeft: 0,
      paddingLeft: 0,
      paddingRight: 0,
      "&:hover": {
        outline: "4px solid #698EC5",
        outlineOffset: -2,
        borderRadius: 4,
      },
      height: 75,
      margin: "10px 0px",
    },

    "& .listItemFolderOpen": {
      marginTop: 10,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 8,
      "&:hover": {
        outline: "4px solid #698EC5",
        outlineOffset: -2,
        borderRadius: 4,
      },
      height: 75,
    },
    "& .listItemFolderCloseMainFile": {
      borderBottom: `1px solid ${AppConfig.themeColors.dividerColor}`,
      marginLeft: 0,
      paddingLeft: 0,
      paddingRight: 0,
      "&:hover": {
        outline: "4px solid #698EC5",
        outlineOffset: -2,
        borderRadius: 4,
      },
      height: 75,
      margin: "10px 0px",
    },

    "& .F_SALES_ORDER": {
      "&:hover": {
        outline: `4px solid ${colors.salesOrderBorderColor}`,
      },
    },
    "& .F_PURCHASE_ORDER": {
      "&:hover": {
        outline: `4px solid ${colors.purchaseOrderBorderColor}`,
      },
    },

    "& .SALES_ORDER": {
      border: `2px solid ${colors.salesOrderBorderColor}`,
      background: colors.salesOrderDocBackgroundColor,
      "&:hover": {
        outline: `4px solid ${colors.salesOrderBorderColor}`,
      },
    },
    "& .PURCHASE_ORDER": {
      border: `2px solid ${colors.purchaseOrderBorderColor}`,
      background: colors.purchaseOrderDocBackgroundColor,
      "&:hover": {
        outline: `4px solid ${colors.purchaseOrderBorderColor}`,
        background: "#DDEFFF",
      },
    },
  },
  "& .dotsImage": {
    transform: "rotate(90deg)",
  },
  "& .listItemIcon": {
    minWidth: 0,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& img": {
      margin: "0px 6px 15px 14px",
      cursor: "grab",
    },
  },
  "& .imgListItemIcon": {
    marginLeft: 16,
  },
  "& .leftContainerLineDivider": {
    width: "2%",
    marginTop: -30,
  },
  "& .dividerPlaceholderDocuments": {
    height: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 5px",
  },
  "& .labelVersionSmallCenter": {
    width: 24,
    height: 18,
    borderRadius: 3,
    textAlign: "center",
    fontSize: 12,
    fontWeight: 400,
    position: "absolute",
    left: -16,
    paddingTop: 3,
    background: "#FFF",
  },
  "& .labelVersionBotton": {
    border: "2px solid #e5edf7",
    background: "white",
    width: 24,
    height: 16,
    borderRadius: 3,
    textAlign: "center",
    fontSize: 12,
    fontWeight: 600,
    position: "absolute",
    left: -16,
    top: 32,
    paddingLeft: 2,
  },
  "& .listItemDocumentCollapseItemsFolder": {
    display: "flex",
    "& #folder-structure-list-container": {
      width: "100%",
      "& .root-list-content": {
        marginLeft: 16,
      },
    },
  },
  "& .listItemDocumentCollapseItems": {
    "& div": {
      "& ul": {
        marginLeft: 12,
      },
    },
  },
}));

export const FolderStructureListNoteStyled = styled("div")(() => ({
  folderStructureListContainer: {
    minWidth: 250,
    width: "100%",
  },
  paddingList: {
    padding: "0px",
  },
}));

export const ProjectDocumentNoteStyled = styled("div")(() => ({
  "&.documentNoteContentCard": {
    flex: 1,
    position: "relative",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    transition: "all 1s ease",
    padding: "38px 10px 20px 10px",
  },
  "& .content-card-content": {
    flex: 1,
    fontSize: 13,
    fontWeight: "normal",
    position: "relative",
    color: colors.primaryDark,
    borderRadius: 10,
    background: "#fff",
    border: "2px solid",
    height: "100%",
    overflow: "auto",
  },
  [`& .container${dbTables.SALES_ORDERS}`]: {
    background: AppConfig.themeColors.salesOrderBackgroundColor,
    borderColor: AppConfig.themeColors.salesOrderBorderColor,
  },
  [`& .container${dbTables.PURCHASE_ORDERS}`]: {
    background: AppConfig.themeColors.purchaseOrderBackgroundColor,
    borderColor: AppConfig.themeColors.purchaseOrderBorderColor,
  },
  [`& .container${dbTables.SHIPMENTS}`]: {
    background: AppConfig.themeColors.shipmentColor,
    borderColor: AppConfig.themeColors.shipmentBordeColor,
  },
  "& .documentScrollContainers": {
    height: "100%",
  },
  "& .cardHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    padding: "0px 7px 30px 37px",
    "& .documents-title": {
      fontWeight: 500,
      fontSize: 20,
    },
    "& .NOTE-PURCHASE_ORDER_NOTE": {
      background: colors.purchaseOrderBackgroundColor,
      outline: `2px solid ${colors.purchaseOrderBorderColor}`,
      marginRight: 8,
      "&:hover": {
        outline: "2px solid #3f96e6",
        background: "#97ccfd",
      },
      "&:active": {
        outline: `2px solid ${colors.backgroundBlueAction}`,
        background: colors.backgroundBlueAction,
      },
    },
    "& .NOTE-PURCHASE_ORDER_NOTE-active": {
      outline: `2px solid ${colors.backgroundBlueAction}`,
      background: colors.backgroundBlueAction,
      marginRight: 8,
    },
    "& .title-list": {
      display: "flex",
      flex: "0 0 auto",
      alignItems: "center",
      padding: "7px 16px",
      borderRadius: 5,
      height: 33,
      maxHeight: 33,
      cursor: "pointer",
      "& .strong-text": {
        maxWidth: 110,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      },
      "& .arrowIcon": {
        marginLeft: "8px !important",
      },
    },
    "& .NOTE-SHIPMENT_NOTE": {
      background: colors.shipmentBadge,
      outline: `2px solid ${colors.shipmentBorderColor}`,
      marginRight: 32,

      "&:hover": {
        background: colors.shipmentHoverColor,
        outline: "2px solid #CB5EDE",
      },
      " &:active ": {
        background: "#d885e8",
        outline: "2px solid #d885e8",
      },
    },
    "& .NOTE-SHIPMENT_NOTE-active": {
      background: "#d885e8",
      outline: "2px solid #d885e8",
      marginRight: 32,
    },
  },
  "& .documentScrollContainer": {
    paddingBottom: 0,
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    paddingTop: 64,
    borderRadius: 8,
  },
}));

export const DocumentNoteTabsStyled = styled(Tabs)(() => ({
  "&.customTabs": {
    display: "flex",
    overflow: "hidden",
    minHeight: 48,
    paddingInline: 32,
    "& .custom-tab-note": {
      padding: 0,
      minHeight: "0 !important",
      height: 40,
      fontSize: 15,
      margin: " 0 6px",
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      transform: "scale(1)",
      color: colors.primaryDark,
      width: "50%",
    },
    "& .TabSALES_ORDER": {
      background: colors.salesOrderBackgroundColor,
      border: `1px solid ${colors.salesOrderBorderColor}`,
    },
    "& .activeSALES_ORDER": {
      background: colors.salesOrderBackgroundColor,
      border: `2px solid ${colors.salesOrderBorderColor}`,
      borderBottom: `2px solid ${colors.salesOrderBackgroundColor}`,
      zIndex: 1,
      boxShadow: "0px -5px 5px 0px rgba(0, 0, 0, 0.25)",
    },
    "& .TabPURCHASE_ORDER": {
      background: colors.purchaseOrderBackgroundColor,
      border: `1px solid ${colors.purchaseOrderBorderColor}`,
    },
    "& .activePURCHASE_ORDER": {
      background: colors.purchaseOrderBackgroundColor,
      border: `2px solid ${colors.purchaseOrderBorderColor}`,
      zIndex: 1,
      boxShadow: "0px -5px 5px 0px rgba(0, 0, 0, 0.25)",
      borderBottom: `2px solid ${colors.purchaseOrderBackgroundColor}`,
    },
    "& .TabSHIPMENT": {
      background: colors.shipmentBadge,
      border: `1px solid ${colors.shipmentBorderColor}`,
    },
    "& .activeSHIPMENT": {
      background: colors.shipmentBadge,
      border: `2px solid ${colors.shipmentBorderColor}`,
      zIndex: 1,
      boxShadow: "0px -5px 5px 0px rgba(0, 0, 0, 0.25)",
      borderBottom: `2px solid ${colors.shipmentBadge}`,
    },
  },
}));
