import React, { useState } from "react";
import {
  DeleteIconv2,
  DraggableIcon,
  EditIcon,
  MoveToRootIcon,
  YellowFolderIcon,
} from "../../helpers/iconSvgPath";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Switch } from "@mui/material";
import { ReportTreeItemStyled } from "./styles";
import { updateDoc } from "firebase/firestore";
import FolderReport from "../../api/model/FolderReport";
import Report from "../../api/model/Report";
import { cx } from "@emotion/css";
import TooltipTD from "../Tooltip/TooltipTD";
import { REPORTS_TYPE } from "../../helpers/constants";
import { canDrop, updateReportPermissions } from "./helpers";
import { colors } from "../../assets/jss/variables";

const IDENTATION = 32;
function ReportTreeItem({
  document,
  childNumber = 0,
  selected,
  setSelected,
  reports,
  draggingTo,
  setDraggingTo,
  onOpenFolder = () => {},
  onEditing = () => {},
  onDeactivate = () => {},
  onDelete = () => {},
  onMoveToRoot = () => {},
  isRoot = false,
  isOpen = false,
  currentUser = {},
  users = [],
  permissionGroupsDB,
  handleAttentionModal,
}) {
  const [hoverDrag, setHoverDrag] = useState("");
  const getDisclouserArrow = () => {
    if (document.type !== REPORTS_TYPE.FOLDER) {
      return <div style={{ width: 15, minWidth: 15 }} />;
    }
    return (
      <div
        onClick={(ev) => {
          onOpenFolder(document.id);
          ev.stopPropagation();
        }}
        className="disclouserArrowStyle"
      >
        <KeyboardArrowDownIcon
          className="arrow"
          style={{
            transform: !isOpen ? "rotate(-90deg)" : "none",
          }}
        />
      </div>
    );
  };
  return (
    <ReportTreeItemStyled
      draggable
      className={cx("reportTreeItemContainer", {
        selectedTree: selected.reports.includes(document.id),
        hoverDrag: hoverDrag,
      })}
      onDragStart={(e) => {
        if (!selected.reports.includes(document.id)) {
          setSelected({
            parentId: document.parentId,
            reports: [document.id],
          });
        }
        e.stopPropagation();
      }}
      onDragEnd={(e) => {
        setHoverDrag(false);
        if (!draggingTo) {
          setSelected({
            parentId: null,
            reports: [],
          });
          return console.log("You can not drag to nowhere");
        }
        if (draggingTo.type !== REPORTS_TYPE.FOLDER) {
          setSelected({
            parentId: null,
            reports: [],
          });
          return console.log("You can only drag into folders");
        }
        if (draggingTo.id === document.id) {
          setSelected({
            parentId: null,
            reports: [],
          });
          return console.log("You can not drag into the same folder");
        }
        if (draggingTo.id === document.parentId) {
          setSelected({
            parentId: null,
            reports: [],
          });

          return console.log("You can not drag into the same folder");
        }
        if (
          !canDrop({
            documents: reports,
            originId: document.id,
            destinationId: draggingTo.id,
          })
        ) {
          setSelected({
            parentId: null,
            reports: [],
          });
          return console.log("You can not drag into the child folder");
        }
        let reportList = [];
        let folderList = [];
        reports.forEach((report) => {
          if (selected.reports.includes(report.id)) {
            if (report.type !== REPORTS_TYPE.FOLDER) {
              reportList.push(report);
            } else {
              folderList.push(report);
            }
            updateReportPermissions({
              currentUser,
              permissionGroups: draggingTo.permissionGroups,
              permissionGroupsDB,
              permissionUsers: draggingTo.permissionUsers,
              report,
              reports,
              updateDoc,
              users,
              parentId: draggingTo.id,
              clonePermission: true,
            });
          }
        });
        handleAttentionModal({ folderList, reportList });
        setSelected({
          parentId: null,
          reports: [],
        });
        e.stopPropagation();
      }}
      onDragEnter={(ev) => {
        setDraggingTo(document);
        ev.preventDefault();
        ev.stopPropagation();
      }}
      onDragOver={(ev) => {
        if (document.type === REPORTS_TYPE.FOLDER && !hoverDrag) {
          setHoverDrag(true);
        }
        ev.preventDefault();
        ev.stopPropagation();
      }}
      onDragLeave={() => setHoverDrag(false)}
      onDrop={() => {
        if (document.type === REPORTS_TYPE.FOLDER) {
          onOpenFolder(document.id, true);
        }
        setHoverDrag(false);
      }}
      onClick={() => {
        if (
          selected.reports.length > 0 &&
          selected.parentId !== document.parentId
        ) {
          console.log("You can not select out of the context");
          setSelected({
            parentId: null,
            reports: [],
          });
          return;
        } else if (selected.reports.includes(document.id)) {
          setSelected({
            parentId: document.parentId,
            reports: selected.reports.filter((id) => id !== document.id),
          });
        } else {
          setSelected({
            parentId: document.parentId,
            reports: [...selected.reports, document.id],
          });
        }
      }}
    >
      <div
        style={{
          width: 550 + -IDENTATION * childNumber,
          minWidth: 550 + -IDENTATION * childNumber,
          display: "flex",
          alignItems: "center",
          gap: 8,
          paddingRight: 10,
        }}
      >
        <div className={cx("dragContainer", "hiddenIcon")}>
          <DraggableIcon svgClass="dragClass" />
        </div>

        {getDisclouserArrow()}
        <span>
          {document.type === REPORTS_TYPE.FOLDER ? <YellowFolderIcon /> : null}
        </span>
        {document.name}
      </div>
      <div className="descriptionToolBox">
        <span className="reportDescription">{document.description}</span>
        <div className="reportItemOptions">
          {!isRoot && (
            <TooltipTD className="hiddenIcon" label="Move to top level">
              <MoveToRootIcon
                onClick={(ev) => {
                  console.log("MOVE TO ROOT", document);
                  onMoveToRoot(document);
                  ev.stopPropagation();
                }}
                color={colors.primaryDark}
              />
            </TooltipTD>
          )}
          {document.type !== REPORTS_TYPE.FOLDER && (
            <TooltipTD
              label={`${
                !document.enable ? "Deactivate report" : " Activate report"
              }`}
              className="hiddenIcon"
            >
              <Switch
                onClick={(ev) => {
                  onDeactivate(document);
                  ev.stopPropagation();
                }}
                checked
                onChange={() => {}}
                style={{
                  color: colors.primaryDark,
                }}
                size="small"
              />
            </TooltipTD>
          )}
          {document.type === REPORTS_TYPE.FOLDER &&
            reports.filter((report) => report.parentId === document.id)
              .length === 0 && (
              <TooltipTD
                className="hiddenIcon"
                label="Delete folder"
                tooltipClick={(ev) => {
                  onDelete(document);
                  ev.stopPropagation();
                }}
              >
                <DeleteIconv2 />
              </TooltipTD>
            )}
          <TooltipTD
            className="hiddenIcon"
            label={`Edit ${document.type || REPORTS_TYPE.REPORT}`}
          >
            <EditIcon
              onClick={(ev) => {
                if (document.type === REPORTS_TYPE.FOLDER) {
                  onEditing({ ...new FolderReport({ ...document }) });
                } else {
                  onEditing({ ...new Report({ ...document }) });
                }
                ev.stopPropagation();
              }}
            />
          </TooltipTD>
        </div>
      </div>
    </ReportTreeItemStyled>
  );
}

export default ReportTreeItem;
