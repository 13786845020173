import React, { useState, useEffect } from "react";
import {
  Edit as EditIcon,
  Cancel as CancelIcon,
  Info as InfoIcon,
} from "@mui/icons-material";
import superAdminIcon from "../../assets/flag-icons/super-admin.svg";
import TooltipTD from "../Tooltip/TooltipTD";
import userTypes from "../../api/types/userTypes";
import {
  ClickAwayListener,
  InputBase,
  ListItem,
  Typography,
  styled,
} from "@mui/material";

const MAX_LENGTH = 30;

const InputField = styled(InputBase)(({ theme }) => ({
  position: "relative",
  backgroundColor: theme.palette.common.white,
  border: "1px solid black",
  fontSize: 16,
  padding: "10px 12px",
}));

const RenameLabelOptions = (props) => {
  const {
    item,
    setActiveItemList,
    isActive,
    idLabelEdit,
    setIdLabel,
    hasPermissionToEdit,
    renameItem,
    styleActiveList,
    textToolTip,
    isRole,
    handleReadOnlyModal = () => {},
    isReadOnly = false,
  } = props;

  const [name, setName] = useState(item.name);
  const [hover, sethover] = useState(false);
  const [editing, setEditing] = useState(false);
  const styleActive = isActive ? styleActiveList : {};
  const error = name.trim().length;

  useEffect(() => {
    if (idLabelEdit !== item.id && editing) setEditing(false);
    return;
  }, [idLabelEdit]);

  const handleSaveName = (newName) => {
    if (!newName.trim().length) cancelOption(item.name);
    else {
      setIdLabel(false);
      setEditing(false);
      renameItem(item, newName.trim());
    }
  };

  const cancelOption = (oldName) => {
    setIdLabel(false);
    setName(oldName);
    setEditing(false);
  };

  const isSuperAdmin = item.name === userTypes.SUPER_ADMIN;
  const isEditable = !isSuperAdmin && hover && hasPermissionToEdit;
  const isCancelable = !isSuperAdmin && editing && hasPermissionToEdit;
  const errorStyle = !error ? { style: { border: "1px solid red" } } : {};
  return (
    <div
      key={item.id}
      style={{
        ...styleActive,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
      onMouseEnter={(e) => {
        sethover(true);
        e.stopPropagation();
      }}
      onMouseLeave={(e) => {
        sethover(false);
        e.stopPropagation();
      }}
    >
      <ListItem
        style={{
          paddingTop: 0,
          paddingBottom: 0,
        }}
        key={item.id}
        onClick={() => {
          setActiveItemList(item);
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div style={{ width: "90%" }}>
            {!editing && (
              <Typography
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "100%",
                  fontSize: "1rem",
                }}
                varian="h4"
                key={`label_${item.id}`}
              >
                {`${name}`}
                {isSuperAdmin && isRole && (
                  <img src={superAdminIcon} alt={"superAdmin"} />
                )}
              </Typography>
            )}
            {editing && (
              <ClickAwayListener
                onClickAway={() => {
                  handleSaveName(name);
                }}
              >
                <InputField
                  autoFocus
                  id={`item_${item.id}`}
                  key={`input_${item.id}`}
                  inputProps={errorStyle}
                  style={{ width: "100%" }}
                  value={name}
                  onChange={(e) => {
                    e.preventDefault();
                    const text = e.target.value.replace(/\s\s+/g, " ");
                    if (text.length <= MAX_LENGTH) setName(text);
                  }}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      handleSaveName(name);
                    }
                  }}
                  onKeyDown={(ev) => {
                    if (ev.key === "Escape") {
                      cancelOption(item.name);
                    }
                  }}
                />
              </ClickAwayListener>
            )}
          </div>
          <div style={{ width: "10%" }}>
            {(isEditable || isCancelable) && (
              <div>
                {!editing && (
                  <EditIcon
                    style={{
                      margin: "8px",
                      cursor: "pointer",
                    }}
                    fontSize="small"
                    onClick={(e) => {
                      if (isReadOnly) {
                        handleReadOnlyModal();
                      } else {
                        setIdLabel(item.id);
                        setEditing(true);
                      }
                      e.stopPropagation();
                    }}
                  />
                )}
                {editing && (
                  <div>
                    <CancelIcon
                      style={{
                        margin: "8px",
                        cursor: "pointer",
                      }}
                      fontSize="small"
                      onClick={(e) => {
                        cancelOption(item.name);
                        e.stopPropagation();
                      }}
                    />
                  </div>
                )}
              </div>
            )}
            {isSuperAdmin && hover && (
              <TooltipTD label={textToolTip}>
                <InfoIcon
                  style={{
                    margin: "8px",
                  }}
                  fontSize="small"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              </TooltipTD>
            )}
          </div>
        </div>
      </ListItem>
    </div>
  );
};

export default RenameLabelOptions;
