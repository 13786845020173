import React, { useState } from "react";
import GreenSmallSwitch from "../Inputs/GreenSmallSwitch";
import { dbTables } from "../../api/types/dbTables";
import { firestore } from "../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { Button, Divider } from "@mui/material";
import { UserSettingsDrawerStyled } from "./styles";
import ModalWarningMark from "../../container/NotificationsContainer/ModalWarningMark";

function UserNotificationSetting({
  setting,
  currentUser,
  handleClearMentions = () => {},
}) {
  const fieldToUpdate = setting.property;
  const [openModal, setOpenModal] = useState(false);
  function onChange() {
    updateDoc(doc(firestore, `${dbTables.USERS}/${currentUser.id}`), {
      [fieldToUpdate]: !currentUser[fieldToUpdate],
    });
  }
  const handleOnClose = () => {
    setTimeout(() => setOpenModal(false), 200);
  };

  return (
    <UserSettingsDrawerStyled key={setting.property} className={"setting"}>
      {openModal && (
        <ModalWarningMark
          handleAttentionModal={handleOnClose}
          openAttentionModal={openModal}
          onMarkAllRead={() => {
            handleOnClose();
            handleClearMentions();
          }}
          user={currentUser}
          text="All your notes will be marked as read and this cannot be undone"
          hiddenCheck
        />
      )}
      <div className="switchRow">
        <div style={{ marginTop: 2 }}>
          {setting.type === "switch" && (
            <GreenSmallSwitch
              onChange={onChange}
              checked={currentUser[fieldToUpdate]}
              width={76}
              height={23}
            />
          )}
          {setting.type === "button" && (
            <Button
              className="buttonSetting"
              onClick={() => setOpenModal(true)}
            >
              Proceed
            </Button>
          )}
        </div>
        <label className="settingLabel">{setting.label}</label>
      </div>

      <Divider style={{ marginLeft: 14, marginRight: 19 }} />
      <p
        className="settingDescription"
        key={`settingDescription_${setting.property}`}
      >
        {setting.description.replace("{username}", currentUser.displayName)}
      </p>
    </UserSettingsDrawerStyled>
  );
}

export default UserNotificationSetting;
