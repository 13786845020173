import React from "react";
import { globalScopes } from "../../api/types/dbTables";
import {
  shipmentAmountFields,
  shipmentFields,
  shipmentFinalDestinationField,
  shipmentObjFields,
} from "../../helpers/orderDashboard";

import { ShipmentInfoGridStyled } from "./styles";
import CustomButton from "../Buttons/CustomButton";
import FinalDestination from "../../api/model/finalDestination";
import { useCurrentCustomer } from "../../hooks/customers";
import MetaDataShipment from "../../api/model/MetadataShipment";
import { useIsAllowedFunction } from "../../hooks/permissions";
import { generalPermissions } from "../../helpers/constants";
import { colors } from "../../assets/jss/variables";
import MetadataProperty from "./sections/MetadataProperty";

function NewShipmentInfo({
  companyId,
  currentShipment = {},
  currentNumber = 1000,
  isReadOnly = false,
  isLoading = false,
  onSave = () => {},
  onClose = () => {},
  onUpdate = () => {},
}) {
  const currentCustomer = useCurrentCustomer() || {};
  const isAllowed = useIsAllowedFunction();
  const customerFinalDestinations = currentCustomer.finalDestinations || {};
  return (
    <ShipmentInfoGridStyled className="newShipmentDetailContainer">
      <div className="shipment-title-container">
        <div className="stc-shipment-number">
          <span
            style={{
              fontSize: 20,
              color: colors.darkGray,
            }}
          >
            Shipment:
          </span>
          <span className="shipmentBadge">{currentNumber}</span>
        </div>
        <MetadataProperty
          onSave={onUpdate}
          property={shipmentObjFields.FINAL_DESTINATION}
          popperClass={""}
          item={currentShipment}
          disabled={isReadOnly || !shipmentFinalDestinationField.editable}
          companyId={companyId}
          propertyScope={globalScopes.SHIPMENT}
          propertyContainerStyles={{
            borderBottom: "none",
            marginLeft: 16,
            borderTop: "none",
          }}
          field={shipmentFinalDestinationField}
          secondaryClassName="property-as-title"
          currentOptions={Object.values(customerFinalDestinations || {})}
          customer={currentCustomer || {}}
          metaDataModel={FinalDestination}
          metadataKey="finalDestinations"
          customerMetadatas={customerFinalDestinations}
        />
      </div>
      <div className="sales-order-info">
        <div className="new-info-shipment">
          <div className={"shipmentInfoContainer"}>
            {shipmentFields.map((item, index) => {
              const hasPermission = item.permission
                ? isAllowed(item.permission)
                : true;
              const disabled =
                isReadOnly ||
                !item.editable ||
                !hasPermission ||
                (shipmentAmountFields.includes(item.field) &&
                  !isAllowed(generalPermissions.SHIPMENT_AMOUNT));
              return (
                <div
                  style={{ gridColumnEnd: "span 1" }}
                  key={item.field || index}
                >
                  <MetadataProperty
                    onSave={onUpdate}
                    property={item.field}
                    item={currentShipment}
                    disabled={disabled}
                    companyId={companyId}
                    propertyLabelStyles={
                      item.field === "totalUniqueItems"
                        ? { textOverflow: "unset" }
                        : {}
                    }
                    propertyScope={globalScopes.SHIPMENT}
                    field={item}
                    classProperty={
                      disabled ? "disabled-sales-order-property" : false
                    }
                    currentOptions={
                      item.listValue
                        ? Object.values(currentCustomer[item.listValue] || {})
                        : []
                    }
                    customer={currentCustomer || {}}
                    metaDataModel={MetaDataShipment}
                    customerMetadatas={currentCustomer[item.listValue]}
                    metadataKey={item.listValue}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div style={{ position: "absolute", right: 27, display: "flex" }}>
        <CustomButton
          type="success"
          onClick={onSave}
          className={"doneButton"}
          disabled={isLoading}
        >
          {"generic.text.done"}
        </CustomButton>
        <CustomButton
          type="cancel"
          onClick={onClose}
          className={"cancelButton"}
        >
          {"generic.text.cancel"}
        </CustomButton>
      </div>
    </ShipmentInfoGridStyled>
  );
}

export default NewShipmentInfo;
