import React from "react";
import TooltipTD from "../Tooltip/TooltipTD";
import { PANEL_TEXT } from "../../helpers/constants";
import { ExpandPanelIcon } from "../../helpers/iconSvgPath";
import { cx } from "@emotion/css";
import { styled } from "@mui/material";
import { colors } from "../../assets/jss/variables";

const StyledTooltip = styled(TooltipTD)(() => ({
  "&.TooltipBase": {
    cursor: "pointer",
    padding: "4px 5px",
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    "& .arrow1": {
      transition: "1s ease ",
    },
    "& .arrow2": {
      transition: "1s ease ",
    },
    "&:hover": {
      background: colors.backgroundWhite,
    },
    "&:active": {
      background: colors.backgroundWhiteAction,
      "& .expandIcon": {
        "& .arrow1": {
          transform: "translate(-2px, 0px)",
        },
        "& .arrow2": {
          transform: "translate(2px, 0px)",
        },
        "& rect": {
          fill: "#25486D4D",
        },
      },
    },
  },
  "&.activeButton": {
    "& .arrow1": {
      transform: "translate(11px, 0px)",
    },
    "& .arrow2": {
      transform: "translate(-11px, 0px)",
    },
    "&:active": {
      background: colors.backgroundWhiteAction,
      "& .expandIcon": {
        "& .arrow1": {
          transform: "translate(13px, 0px)",
        },
        "& .arrow2": {
          transform: "translate(-13px, 0px)",
        },
      },
    },
  },
}));

function ExpandPanelButton({ handleActivePanel, activePanel }) {
  return (
    <StyledTooltip
      tooltipClick={handleActivePanel}
      label={activePanel ? PANEL_TEXT.SMALL : PANEL_TEXT.BIG}
      className={cx("TooltipBase", { activeButton: activePanel })}
    >
      <ExpandPanelIcon />
    </StyledTooltip>
  );
}

export default ExpandPanelButton;
