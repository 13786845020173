import React from "react";
import { Provider } from "react-redux";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
// import MomentUtils from "@date-io/moment";
// import { MuiPickersUtilsProvider } from "@material-ui/pickers";

// css
import "./lib/reactifyCss";

import { configureStore } from "./store";
import Layout from "./container/Layout";
import RctThemeProvider from "./container/RctThemeProvider";
import AuthPage from "./container/AuthPage";
import { ROUTES } from "./routes/helpers/routesConfig";
import TradeDashLayout from "./components/TradeDashLayout/TradeDashLayout";
import { ProtectedRoute } from "./components/TradeDashLayout/ProtectedRoute";
import PurchaseOrderDashboard from "./routes/purchaseOrderDashboard";
import FactoriesScreen from "./container/FactoriesScreen/FactoriesScreen";
import FactoryLayout from "./container/FactoriesScreen/FactoryLayout";
import CustomersScreen from "./container/CustomersScreen/CustomersScreen";
import CustomerLayout from "./container/CustomersScreen/CustomerLayout";
import AdminSettingsView from "./components/AdminSettings/AdminSettingsView";
import AdminLayOut from "./components/AdminSettings/AdminLayout";
import { ADMINROUTES } from "./routes/helpers/routesAdminConfig";
import DocumentLanding from "./container/DocumentLanding/DocumentLanding";

// comment out
function MainApp() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        element={<Layout />}
        errorElement={<div>something went wrong with tradedash</div>}
      >
        <Route path="/signin" element={<AuthPage />} />
        <Route path="/links*" element={<DocumentLanding />} />

        <Route
          path="/app"
          element={
            <ProtectedRoute>
              <TradeDashLayout />
            </ProtectedRoute>
          }
        >
          {ROUTES.map((route) => (
            <Route path={route.path} element={route.element} key={route.path} />
          ))}
          <Route path="dashboard/*" element={<PurchaseOrderDashboard />} />
          <Route path="factories" element={<FactoryLayout />}>
            <Route path=":factoryId" element={<FactoriesScreen />} />
          </Route>
          <Route path="customers" element={<CustomerLayout />}>
            <Route path=":customerId" element={<CustomersScreen />} />
          </Route>
          <Route path="admin" element={<AdminLayOut />}>
            <Route path=":companyId" element={<AdminSettingsView />}>
              {ADMINROUTES.map((route) => (
                <Route
                  path={route.path}
                  element={
                    route.element ? (
                      route.element
                    ) : (
                      <div className="main-route-container">{route.path}</div>
                    )
                  }
                  key={route.path}
                />
              ))}
            </Route>
          </Route>
        </Route>
      </Route>
    )
  );
  return (
    <Provider store={configureStore()}>
      <RctThemeProvider>
        <RouterProvider router={router} />
      </RctThemeProvider>
    </Provider>
  );
}

export default MainApp;
