import React, { useState } from "react";
import { isTradeDashEmployee } from "../../../helpers/helpers";
import { useCurrentCustomer } from "../../../hooks/customers";
import {
  useOrderDashboardSetup,
  useCurrentSalesOrder,
  useSortPuchaseOrders,
} from "../../../hooks/salesOrders";
import { useSortShipments } from "../../../hooks/shipments";
import LoadingBackdrop from "../../WholeScreenFocusBackdrop/LoadingBackdrop";
import DashboardTitlev2 from "../DashboardTitlev2";
import { useCompanyId } from "../../../hooks";

function OrderDashboardHeader({
  customerPermission,
  vendorPermission,
  user,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { salesOrderId, purchaseOrderId, shipmentId } =
    useOrderDashboardSetup();
  const companyId = useCompanyId();
  const shipments = useSortShipments();
  const purchaseOrders = useSortPuchaseOrders();
  const currentSalesOrder = useCurrentSalesOrder({ salesOrderId });
  const currentCustomer = useCurrentCustomer() || {};
  const currentPurchaseOrder =
    purchaseOrders.find((po) => po.id === purchaseOrderId) || {};
  const currentShipment =
    shipments.find((shipment) => shipment.id === shipmentId) || {};

  return (
    <React.Fragment>
      {isLoading && <LoadingBackdrop withLogo={true} />}
      {currentSalesOrder.id && (
        <DashboardTitlev2
          currentSalesOrder={currentSalesOrder}
          customer={currentCustomer}
          isReadOnly={isTradeDashEmployee(user)}
          currentShipment={currentShipment}
          currentPurchaseOrder={currentPurchaseOrder}
          purchaseOrders={purchaseOrders}
          shipments={shipments}
          companyId={companyId}
          handleLoading={setIsLoading}
          customerPermission={customerPermission}
          vendorPermission={vendorPermission}
          user={user}
        />
      )}
    </React.Fragment>
  );
}

export default OrderDashboardHeader;
