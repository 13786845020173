import React from "react";
import { getPOShipments } from "../../../helpers/shipments";
import { useCompanyId, useQueryParams } from "../../../hooks";
import {
  useCurrentPurchaseOrder,
  useCurrentSalesOrder,
} from "../../../hooks/salesOrders";
import { useCurrentShipmentV2, useShipments } from "../../../hooks/shipments";
import { useUser } from "../../../hooks/user";
import ShipmentMetadataInfo from "./ShipmentMetadaInfo";
import SectionShipmentSKU from "./SectionShipmentSKU";

function ShipmentMetadata() {
  const companyId = useCompanyId();
  const user = useUser();
  const queryParams = useQueryParams();
  const shipments = useShipments();
  const currentPurchaseOrder = useCurrentPurchaseOrder();
  const currentSalesOrder = useCurrentSalesOrder({});
  const currentShipment = useCurrentShipmentV2({
    poShipments: getPOShipments({
      shipments,
      purchaseOrder: currentPurchaseOrder,
    }),
    shipmentId: queryParams.shipmentId,
  });

  return (
    <div className={"shipment-info"} id="shipment-info">
      {currentShipment && (
        <div id="shipmentInfo" className="shipmentInfoBody">
          <ShipmentMetadataInfo shipment={currentShipment} />
          <SectionShipmentSKU
            companyId={companyId}
            currentPurchaseOrder={currentPurchaseOrder}
            currentSalesOrder={currentSalesOrder}
            user={user}
            shipment={currentShipment}
            queryParams={queryParams}
          />
        </div>
      )}
    </div>
  );
}

export default ShipmentMetadata;
