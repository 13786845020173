import { Box, styled } from "@mui/material";
import { colors } from "../../../assets/jss/variables";

export const ExpensesContainerStyled = styled(Box)(() => ({
  "&.expensesBoxContainer": {
    margin: "85px 36px 36px 36px !Important",
    "& .expenseRootAutocomplete": {
      "& .expense-end-adorment": {
        display: "none",
      },
      "& .expense-input-root-autocomplete": {
        padding: "2px 8px",
        fontSize: 12,
      },
    },
    "& .expensePopperAutocomplate": {
      width: "auto !Important",
      minWidth: "85px",
      "& .expense-option-autocomplete": {
        fontSize: 12,
        padding: "6px",
      },
    },
    "& .expensesHeaderContainer": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
      padding: "16px 0px",
      "& .search-box-list": {
        position: "relative",
        margin: "auto",
      },
      "& .drag-and-drop-expense-pdf": {
        padding: "22px 10px",
        border: "2px solid #25486D",
        borderStyle: "dashed",
        width: 486,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#25486D",
        fontWeight: 500,
        position: "relative",
        "& .input-to-load-pdf": {
          width: "-webkit-fill-available",
          height: "-webkit-fill-available",
        },
      },
      "& .drag-and-drop-expense-pdf-disabled": {
        padding: "22px 10px",
        border: "2px solid #ccc",
        borderStyle: "dashed",
        width: 486,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#ccc",
        weight: 700,
      },
      "& .button-to-enter-expense-container": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .button-to-enter-expense": {
          fontSize: 14,
          fontWeight: 700,
          background: "#3399FF",
          color: "#fff",
          padding: "6px 40px",
        },
      },
    },
    "& .expensesBodyContainer": {
      maxHeight: "calc(100vh - 190px)",
      borderRadius: 6,

      "& .expense-item-disabled": {
        background: "transparent",
        "& .accordion-summary-container": {
          opacity: 1,
        },
      },

      "& .expense-item": {
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #87A3C2",
        boxShadow: "none",
        background: "transparent",
        margin: 0,
        "& .MuiInput-underline:before": {
          borderBottom: "0px",
        },
        "& .MuiInput-underline:after": {
          border: "0px",
        },

        "& .expense-date-input": {
          padding: "8px 6px",
          fontSize: 12,
        },
        "& .expense-date": {
          background: "white",
          border: "1px solid #D2DFEC",
          borderRadius: 5,
          width: "100%",
          maxWidth: 105,
          "&:focus-within": {
            border: `1px solid ${colors.radioCheck}`,
            borderRadius: 4,
            boxShadow: "rgb(93 146 244 / 25%) 0 0 0 0.2rem",
          },
        },
        "& .expense-paid-to": {
          background: "white",
          border: "1px solid #D2DFEC",
          borderRadius: 5,
          width: "100%",
          "&:focus-within": {
            border: `1px solid ${colors.radioCheck}`,
            borderRadius: 4,
            boxShadow: "rgb(93 146 244 / 25%) 0 0 0 0.2rem",
          },
        },

        "& .expense-gl-account": {
          background: "white",
          border: "1px solid #D2DFEC",

          borderRadius: 5,
          width: "100%",
          "&:focus-within": {
            border: `1px solid ${colors.radioCheck}`,
            borderRadius: 4,
            boxShadow: "rgb(93 146 244 / 25%) 0 0 0 0.2rem",
          },
        },
        "& .expense-description-of-charges": {
          background: "white",
          border: "1px solid #D2DFEC",

          borderRadius: 5,
          width: "100%",
          "&:focus-within": {
            border: `1px solid ${colors.radioCheck}`,
            borderRadius: 4,
            boxShadow: "rgb(93 146 244 / 25%) 0 0 0 0.2rem",
          },
        },
        "& .expense-notes": {
          background: "white",
          border: "1px solid #D2DFEC",

          borderRadius: 5,
          width: "100%",
          "&:focus-within": {
            border: `1px solid ${colors.radioCheck}`,
            borderRadius: 4,
            boxShadow: "rgb(93 146 244 / 25%) 0 0 0 0.2rem",
          },
        },
        "& .expense-forecast": {
          background: "white",
          border: "1px solid #D2DFEC",

          borderRadius: 5,
          width: "100%",
          "&:focus-within": {
            border: `1px solid ${colors.radioCheck}`,
            borderRadius: 4,
            boxShadow: "rgb(93 146 244 / 25%) 0 0 0 0.2rem",
          },
          "& input": {
            textAlign: "right",
          },
          "& input::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
          "& input::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
        },
        "& .expense-actual": {
          background: "white",
          border: "1px solid #D2DFEC",

          borderRadius: 5,
          width: "100%",
          "&:focus-within": {
            border: `1px solid ${colors.radioCheck}`,
            borderRadius: 4,
            boxShadow: "rgb(93 146 244 / 25%) 0 0 0 0.2rem",
          },
          "& input": {
            textAlign: "right",
          },
          "& input::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
          "& input::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
        },
        "& .editing-expense-button-to-add": {
          minWidth: 20,
          minHeight: 20,
          padding: 0,
          margin: 6,
          "& .MuiButton-label": {
            width: "100%",
            height: "100%",
          },
        },
        "& .editing-expense-button-to-save": {
          background: "#3399FF",
          color: "white",
          margin: "0px 4px",
        },
        "& .expand-more-icon-class-close": {
          position: "absolute",
          left: 0,
          padding: 0,
          top: 20,
          transform: "rotateZ(270deg)",
        },
        "& .expand-more-icon-class-open": {
          position: "absolute",
          left: 0,
          padding: 0,
          top: 28,
        },
        "& .accordion-focus-visible": {
          background: "transparent",
        },
        "& .accordion-summary-container": {
          width: "100%",
          padding: 0,
          "& .disclouserArrowStyle": {
            borderRadius: 5,
            cursor: "pointer",
            fontSize: 20,
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            color: colors.primaryDark,
            "& .arrow": {
              transition: "transform 0.3s ease-in-out",
            },
            "&:hover": {
              background: "#E5E5E5",
            },
          },
          "& .accordion-expand-icon-wrapper": {
            left: 12,
            position: "absolute",
            top: 19,
            transform: "unset",
            transition: "all 0.3s ease-in-out",
          },

          "& .accordion-expand-icon-wrapper-expended": {
            left: 12,
            position: "absolute",
            top: 26,
            transform: "unset",
            transition: "all 0.3s ease-in-out",
          },

          "& .totals-footer": {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: "0px 5px 0px 4px",
            height: 40,
            fontWeight: 700,
            color: "#334C73",
          },
          "& .totals-footer-amount": {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: "0px 5px 0px 0px",
            height: 40,
            fontWeight: 700,
            color: "#334C73",
          },
          "& .copy-total-container": {
            display: "flex",
            alignItems: "center",
            "& .iconContainer": {
              width: 35,
              minWidth: 35,
              height: 35,
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              borderRadius: 5,
              justifyContent: "center",
              "&:hover": {
                background: colors.backgroundWhite,
                "& .copyIcon": {
                  "& path": {
                    strokeWidth: 2.5,
                  },
                },
              },
              "&:active": {
                background: colors.backgroundWhiteAction,
                "& .copyIcon": {
                  "& .item2": {
                    transform: "translate(2px, 2px)",
                  },
                  "& path": {
                    strokeWidth: 3.2,
                  },
                },
              },
            },
          },
          "& .totals-footer-icons": {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 0,
            "& svg": {
              margin: "0px 4px",
            },
          },
          "& .accordion-content": {
            width: "100%",
          },
          "& .autocomplete-select": {
            height: "32.25px",
            border: "1px solid #D2DFEC",
            borderRadius: 4,
            background: "#fff",
            "&:focus-within": {
              border: `1px solid ${colors.radioCheck}`,
              borderRadius: 4,
              boxShadow: "rgb(93 146 244 / 25%) 0 0 0 0.2rem",
            },
          },
          //
        },
        "& .accordion-content-expended": {
          display: "block",
          width: "100%",
          // margin: 0,
        },
        "& .no-display-icon": {
          display: "none !important",
        },
      },
      "& .expenses-table-body": {
        maxHeight: "calc(100vh - 292px)",
        overflow: "auto",
        "&::-webkit-scrollbar": {
          backgroundColor: "transparent",
          width: 17,
        },
        "&::-webkit-scrollbar-thumb": {
          background: "transparent",
          borderRadius: 8,
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "&:hover": {
          "&::-webkit-scrollbar-thumb": {
            boxShadow: "inset 0 0 10px 10px #B1B1B1",
            border: "solid 5px transparent",
          },
        },
        "& .pdf-viewer-container": {
          position: "absolute",
          top: "50%",
          left: "27%",
          right: "100%",
          bottom: "50%",
          width: "50%",
          height: "98%",
          margin: "auto",
          "& iframe": {
            width: "100%",
            height: "88vh",
            position: "relative",
            zIndex: 2,
          },
          "& .cancel-icon-pdf": {
            width: 24,
            height: 24,
            padding: 4,
            border: "2px solid",
            borderRadius: "50%",
            position: "relative",
            left: "100%",
            cursor: "pointer",
          },
        },
      },
      "& .PDFViewer-container-bottom": {
        width: 1000,
        height: 400,
        margin: "16px auto",
        overflow: "hidden",
        boxShadow: "1px 2px 11px rgb(0,0,0,0.5)",
        position: "relative",
        "& .button-to-change-position": {
          borderRadius: 0,
          background: "gray",
          padding: "4px 8px",
          height: 22,
          margin: 0,
          position: "absolute",
          zIndex: 11,
        },
        "& .expense-pdf": {
          width: "inherit",
          height: "inherit",
        },
      },
      "& .PDFViewer-container-left": {
        width: "41%",
        margin: "auto",
        position: "absolute",
        top: 160,
        height: "calc(100vh - 258px)",
        overflow: "hidden",
        zIndex: 10,
        boxShadow: "1px 2px 11px rgb(0,0,0,0.5)",
        "& .button-to-change-position": {
          borderRadius: 0,
          background: "gray",
          padding: "4px 8px",
          height: 22,
          margin: 0,
          position: "absolute",
          zIndex: 11,
        },
        "& .expense-pdf": {
          width: "-webkit-fill-available",
          height: "inherit",
        },
      },
      "& .PDFViewer-container-leftwithRef": {
        width: "41%",
        margin: "auto",
        position: "absolute",
        top: 42,
        height: "calc(100vh - 258px)",
        overflow: "hidden",
        zIndex: 10,
        boxShadow: "1px 2px 11px rgb(0,0,0,0.5)",
        "& .button-to-change-position": {
          borderRadius: 0,
          background: "gray",
          padding: "4px 8px",
          height: 22,
          margin: 0,
          position: "absolute",
          zIndex: 11,
        },
        "& .expense-pdf": {
          width: "-webkit-fill-available",
          height: "inherit",
        },
      },
      "& .PDFViewer-empty": {
        height: "calc(100vh - 300px)",
      },

      "& .expense-itemRow": {
        display: "grid",
        gridTemplateColumns:
          "115px  repeat(4, minmax(100px, 1fr)) 115px 115px 130px",
        gap: 16,
        paddingLeft: 20,
        width: "100%",
        "& .expenses-itemData": {
          height: 40,
          display: "flex",
          alignItems: "center",
          fontSize: 14,
          color: colors.primaryDark,
          fontFamily: "Roboto",
        },
        "& .header-content-grid": {
          height: 40,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          fontSize: 14,
          fontWeight: 500,
          color: "#334C73",
          fontFamily: "Roboto",
          "&:hover": {
            "& .header-arrow-button": {
              opacity: 1,
            },
          },
          "& .header-arrow-button": {
            opacity: 0,
          },
          "& .header-arrow-button-active": {
            opacity: 1,
            color: "#39F",
          },
        },
        "& .footer-content-grid": {
          height: 69,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          fontSize: 16,
          fontWeight: 700,
          color: colors.primaryDark,
          fontFamily: "Roboto",
          padding: "0px 8px",
          "& .footerTotalText": {
            minWidth: 150,
            textAlign: "end",
            borderTop: "2px solid #578EC7",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          },
        },
      },
    },
  },
}));
