import React from "react";
import CustomButton from "../Buttons/CustomButton";
import "react-phone-input-2/lib/plain.css";
import { GLAccountFields } from "../../helpers/constants";
import { Grid, Switch, TextareaAutosize } from "@mui/material";
import { GLAccountFormStyled } from "./styles";

function CreateGLAccountForm({
  GLAccountItem = {},
  isUpdating,
  onChange,
  onSubmit = () => {},
  handleClose,
}) {
  return (
    <GLAccountFormStyled
      onSubmit={(ev) => {
        onSubmit({
          ev,
          currentGLAccountItem: { ...GLAccountItem },
        });
        ev.preventDefault();
      }}
      onKeyDown={(ev) => {
        if (ev.key === "Escape") {
          handleClose();
        }
      }}
      className="GLAccountForm"
    >
      <Grid container alignItems="center" justifyContent="center">
        <div className={"formContainer"}>
          <label>G/L Account</label>
          <input
            id={"GLAccount-id-" + GLAccountItem.id}
            style={{
              width: 350,
              height: 36,
            }}
            required={true}
            value={GLAccountItem[GLAccountFields.NAME] || ""}
            onChange={(ev) =>
              onChange({
                value: ev.target.value,
                field: GLAccountFields.NAME,
              })
            }
          />
        </div>
      </Grid>
      <Grid container alignItems="center" justifyContent="center">
        <div className={"formContainer"}>
          <label>Description</label>
          <TextareaAutosize
            value={GLAccountItem[GLAccountFields.DESCRIPTION]}
            onChange={(ev) =>
              onChange({
                value: ev.target.value,
                field: GLAccountFields.DESCRIPTION,
              })
            }
            placeholder="G/L Account's description"
            required={true}
            autoFocus={true}
            minRows={2}
            style={{
              width: 350,
              maxHeight: 400,
              minHeight: 38,
              border: "1px solid #ccc",
            }}
          />
        </div>
      </Grid>
      <div className={"inactiveContainer"}>
        <label>Active/Inactive</label>
        <Switch
          checked={!!GLAccountItem[GLAccountFields.ACTIVE]}
          color="primary"
          onChange={(ev, checked) =>
            onChange({
              value: checked,
              field: GLAccountFields.ACTIVE,
            })
          }
        />
      </div>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Grid item xs={10}>
          <CustomButton
            styleContainer={{
              marginLeft: "81%",
            }}
            type="success"
          >
            {isUpdating ? "generic.text.update" : "generic.text.create"}
          </CustomButton>
        </Grid>
        <Grid item xs={2}>
          <CustomButton type="cancel" onClick={handleClose} noSubmit>
            {"generic.text.cancel"}
          </CustomButton>
        </Grid>
      </Grid>
    </GLAccountFormStyled>
  );
}

export default CreateGLAccountForm;
