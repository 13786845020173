import React, { useState, useEffect } from "react";
import CustomModal from "../Modal/Modal";
import CreateGLAccountForm from "./CreateGLAForm";
import IntlMessages from "../../util/IntlMessages";

function CreateGLAccount({
  openModal,
  handleClose,
  GLAccountItem,
  onSubmit,
  isUpdating,
  user = {},
}) {
  const [currentGLAccountItem, setCurrentGLAccountItem] = useState(null);

  useEffect(() => {
    setCurrentGLAccountItem(GLAccountItem);
  }, [GLAccountItem]);

  function onChange({ value, field }) {
    setCurrentGLAccountItem({ ...currentGLAccountItem, [field]: value });
  }

  if (!currentGLAccountItem) {
    return null;
  }
  return (
    <CustomModal
      header={
        isUpdating ? (
          <IntlMessages id={"title.text.GLAccount.update"} />
        ) : (
          <IntlMessages id={"title.text.GLAccount.create"} />
        )
      }
      isOpen={openModal}
      onClose={handleClose}
      modalStyle={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <CreateGLAccountForm
        GLAccountItem={currentGLAccountItem}
        onChange={onChange}
        onSubmit={({ currentGLAccountItem }) => {
          onSubmit({ currentGLAccountItem });
        }}
        handleClose={handleClose}
        isUpdating={isUpdating}
        user={user}
      />
    </CustomModal>
  );
}

export default CreateGLAccount;
