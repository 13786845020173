import {
  Popover,
  styled,
  keyframes,
  Drawer,
  ListItemButton,
} from "@mui/material";
import { colors } from "../../assets/jss/variables";
import { NavLink } from "react-router-dom";

const changeBackgroundColor = keyframes`

  to {
    fill: #25486D;
    stroke: #25486D;
  }
  from {
    fill: #F7D401;
    stroke: #E02020;
  }
`;
const changeLinealColor = keyframes`
  0% {
    stroke: #black;
  }
  50% {
    stroke: #white;
  }
  100% {
    stroke: #white;
  }
`;

export const SearchDrawerStyle = styled(Drawer)(() => ({
  "&.drawerContainer ": {
    marginLeft: 86,
    marginTop: 66,
    position: "absolute",
    zIndex: 1,
    "& .closeModal": {
      position: "absolute",
      right: 10,
      top: 10,
      cursor: "pointer",
      width: 32,
      height: 32,
      display: "flex",
      alignItems: "center",
      "&:hover": {
        background: "#e0e0e0",
        borderRadius: 5,
        cursor: "pointer",
      },
    },
    "& .MuiDrawer-paper": {
      width: 609,
      marginLeft: 86,
      marginTop: 66,
      boxShadow: "15px -5px 21px 0px rgba(0, 0, 0, 0.25)",
    },
    "& .MuiBackdrop-root": {
      marginLeft: 86,
      marginTop: 66,
    },
    "& .container": {
      padding: "71px 63px 20px 31px",
      display: "flex",
      flexDirection: "column",
      height: "calc(100% - 70px)",
      gap: 50,
      overFlow: "auto",
      "@media (max-height: 1900px)": {
        padding: "71px 63px 20px 31px",
        gap: 50,
      },
      "@media (max-height: 900px)": {
        padding: "40px 49px 20px 31px",
        gap: 25,
      },
      "@media (max-height: 700px)": {
        padding: 20,
        gap: 10,
      },
    },
    "& .title": {
      color: colors.primaryDark,
      fontSize: 24,
      fontWeight: 500,
    },
    "& .radioGroup": {
      gap: 8,
      "& .item": {
        padding: 0,
        height: 20,
        gap: 6,
        width: 270,
        "& .labelText": {
          color: colors.primaryDark,
          fontSize: 14,
          fontWeight: 400,
        },
        "& .detailText": {
          color: colors.primaryDark,
          fontSize: 10,
          fontWeight: 400,
          marginLeft: -4,
        },
        "& .radioIcon": {
          padding: 0,
          color: colors.primaryDark,
          width: 20,
        },
        "& .checkedClass": {
          color: colors.radioCheck,
        },
        "& .infoIcon": {
          position: "absolute",
          right: 0,
          display: "flex",
        },
      },
    },
    "& .searchContainer": {
      display: "flex",
      gap: 10,
      alignItems: "center",
    },
    "& .searchButton": {
      borderRadius: 5,
      background: colors.proceedButtonColor,
      color: "white",
      marginLeft: "auto",
      padding: "10px 24px",
      height: 40,
      fontSize: 14,
      fontWeight: 700,
    },
  },
}));
export const NavigationLinkStyled = styled(NavLink)(() => ({
  "&.navigation-link-container": {
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    padding: "11px 16px",
    width: "100%",
    color: "#25486d",
    fontWeight: "bold",
    "& .blue-bar": {
      width: 6,
      height: "100%",
      backgroundColor: "#25486d",
      position: "absolute",
      right: 0,
      top: 0,
    },
  },
  "&.active": {
    backgroundColor: colors.navigationActiveColor,
  },
}));

export const ListItemButtonStyle = styled(ListItemButton)({
  "&.listButtonContainer": {
    flexDirection: "column",
    display: "flex",
    transition: "all 0.5s ease",
    borderRight: "0px solid #25486d",
    "& .blue-bar": {
      width: 6,
      height: "100%",
      backgroundColor: "#25486d",
      position: "absolute",
      right: 0,
      top: 0,
    },
  },
  "&.openButton": {
    backgroundColor: colors.navigationActiveColor,
  },
  "& .menu": {
    textAlign: "center",
    fontSize: 10,
    marginTop: 4,
    fontWeight: "bold",
  },
  "& .searchBadge": {
    background: colors.primaryDark,
    borderRadius: 4,
    width: 28,
    height: 28,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

export const AlertTriangleMenuContainerStyled = styled("div")({
  "&.alertTriangleMenuContainer": {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    padding: 8,
    "& .warningIcon": {
      cursor: "pointer",
    },

    "& .backgroundClass": {
      animationName: changeBackgroundColor,
      animationDuration: "60s",
      animationIterationCount: "infinite",
      animationDirection: "alternate",
      animationTimingFunction: "ease-in-out",
    },
    "& .linealClass": {
      animationName: changeLinealColor,
      animationDuration: "60s",
      animationIterationCount: "infinite",
      animationDirection: "alternate",
      animationTimingFunction: "ease-in-out",
    },
  },
});

export const AlertTrianglePopoverContainer = styled(Popover)({
  "&.alertTrianglePopoverContainer": {},
  "& .alertTriangleContainer": {
    borderRadius: 5,
    "& .description-content": {
      padding: "16px 15px",
      fontSize: 12,
      fontWeight: 400,
      color: "#25486D",
      lineHeight: 1.7,
      "& a": {
        color: "#25486D",
        textDecoration: "underline",
      },
      "& img": {
        width: 14,
        height: 14,
        position: "relative",
        top: -2,
      },
    },
  },
});
