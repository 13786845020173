import React from "react";

function CompletedTaskBadge({ task, onMoveRemainingTasks }) {
  if (task.moved) {
    return (
      <span className="task-state-moved">
        {task.dayOffset > 0 ? "+" + task.dayOffset : task.dayOffset}
      </span>
    );
  } else if (task.dayOffset > 0) {
    return (
      <div
        className="task-state-to-move-forward"
        onClick={(ev) => {
          onMoveRemainingTasks(task);
          ev.preventDefault();
          ev.stopPropagation();
        }}
      >
        {"+" + task.dayOffset}
      </div>
    );
  } else if (task.dayOffset < 0) {
    return (
      <div
        className="task-state-to-move-backward"
        onClick={(ev) => {
          onMoveRemainingTasks(task);
          ev.preventDefault();
          ev.stopPropagation();
        }}
      >
        {task.dayOffset}
      </div>
    );
  }
  return null;
}

export default CompletedTaskBadge;
