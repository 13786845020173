import { colors } from "../../../assets/jss/variables";
import AppConfig from "../../../constants/AppConfig";
import { Modal, Popover, styled } from "@mui/material";
import {
  NOTES_SCOPE,
  basesMentionStyle,
  shipmentMentionBase,
} from "../../../helpers/constants";

export const ExpandedActivityModalStyled = styled(Modal)(() => ({
  "&.modalActivity": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "&:active": {
      "& .type": {
        opacity: 1,
      },
      "& .timestamp": {
        opacity: 1,
      },
      "& .icons": {
        opacity: 1,
      },
      "& .iconResize": {
        opacity: 1,
        cursor: "grab",
      },
      "& .left-handle-and-activity-type": {
        opacity: 1,
      },
      "& .right-handle-and-scope": {
        opacity: 1,
      },
    },
    "& .expandModalBody": {
      maxHeight: "calc(100vh - 130px)",
      zIndex: 2,
      "&:hover": {
        "& .activity-notification-read": {
          width: 8,
          height: 8,
          position: "absolute",
          background: "#fff",
          border: " 1px solid #39f",
          borderRadius: "50%",
          cursor: "pointer",
          top: "inherit",
          transition: "width 0.5s, height 0.5s, border 0.2s",
        },
        "& .type": {
          opacity: 1,
        },
        "& .timestamp": {
          opacity: 1,
        },
        "& .icons": {
          opacity: 1,
        },
        "& .iconResize": {
          opacity: 1,
          cursor: "grab",
          "& circle": {
            fill: "rgb(139, 139, 139)",
            r: 1.5,
          },
          "&:hover": {
            "& circle": {
              fill: "black",
            },
          },
          "&:active": {
            "& circle": {
              fill: "black",
            },
          },
        },
        "& .activityReadStatus": {
          opacity: 1,
          width: 14,
          height: 14,
        },
        "& .activityPointRead": {
          borderWidth: 2,
        },
        "& .left-handle-and-activity-type": {
          opacity: 1,
        },
        "& .right-handle-and-scope": {
          opacity: 1,
        },
      },
    },
    "& .note-detail": {
      maxHeight: "calc(100vh - 205px)",
    },
    "& .dragImages": {
      position: "absolute",
      top: 0,
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      padding: "6px 10px 0px 10px",
      "& .iconResize": {
        cursor: "grab",
        opacity: 0,
        "& :active": {
          cursor: "grabbing",
        },
        "& circle": {
          fill: "black",
          r: 1.5,
        },
      },
    },
  },
  "& .headerTitle": {
    paddingRight: 30,
    marginBottom: 4,
    fontWeight: 400,
    fontSize: 13,
    "& .type": {
      opacity: 0,
    },
    "& .timestamp": {
      opacity: 0,
    },
    "& .icons": {
      opacity: 0,
      fontSize: "10px !important",
      fontWeight: "400 !important",
    },
    "& .left-handle-and-activity-type": {
      opacity: 0,
    },
    "& .right-handle-and-scope": {
      opacity: 0,
    },
  },

  "& .activityStyle": {
    borderRadius: 4,
    color: colors.primaryDark,
    margin: `4px 6px 12px 16px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "96%",
    flexDirection: "column",
    lineHeight: 1.8,
    position: "relative",
    "& .iconVisibility": {
      opacity: 0,
    },
    "&:hover": {
      "& .readMore": {
        color: colors.primaryDark,
        borderBottom: `${colors.primaryDark} solid 0.5px`,
      },
      "& .iconVisibility": {
        opacity: 1,
      },
    },
    "& .headerNote": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "4px 10px 4px 10px",
      gap: 10,
      width: "100%",
      height: 28,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      "& .left-handle-and-activity-type": {
        display: "flex",
        alignItems: "center",
        gap: 55,
      },
      "& .right-handle-and-scope": {
        display: "flex",
        alignItems: "center",
        gap: 24,

        "& .header-icons-container": {
          display: "flex",
          gap: 14,
          alignItems: "center",
        },
      },

      "& .icons": {
        display: "flex",
        justifyContent: "flex-end",
        fontWeight: 700,
        fontSize: 12,
        alignItems: "center",
      },
      "& .scope": {
        fontSize: 13,
      },
      background: colors.purchaseOrderActivityHeaderColor,
    },
    "& .activity-scope-label": {
      position: "absolute",
      right: 0,
      zIndex: 2,
      top: 0,
      width: 45,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: 700,
      height: 28,
    },
    "& .note-detail": {
      overflowY: "scroll",
      "&::-webkit-scrollbar": {
        backgroundColor: "transparent",
        width: 16,
      },
      "&::-webkit-scrollbar-thumb": {
        background: "transparent",
        borderRadius: 7,
      },
      "&::-webkit-scrollbar-track": {
        border: " solid 1px transparent",
      },
      "&:hover": {
        "&::-webkit-scrollbar-thumb": {
          boxShadow: `inset 0 0 10px 10px ${colors.thumbScrollColor}`,
          borderBlock: "1px solid transparent",
          borderInline: "5px solid transparent",
        },
      },
      "& .subHeaderNote": {
        display: "block",
        flexDirection: "row",
        // justifyContent: "space-between",
        // alignItems: "center",
        padding: "4px 14px 4px 74px",
        gap: 10,
        width: "100%",
        height: 28,
        marginBottom: 14,
        "& .icons": {
          display: "flex",
          justifyContent: "flex-end",
          fontWeight: 700,
          fontSize: 12,
          alignItems: "center",
        },
        background: colors.purchaseOrderActivityHeaderColor,
        position: "absolute",
        left: 0,
      },
    },
  },

  "& .SALES_ORDER_NOTE": {
    outline: `2px solid ${colors.salesOrderBorderColor}`,
    backgroundColor: colors.salesOrderBackgroundColor,
    "& .readMore": {
      backgroundColor: colors.salesOrderBackgroundColor,
    },
    "& .headerNote": {
      backgroundColor: colors.salesOrderBorderColor,
    },
    "& .note-detail": {
      "& .subHeaderNote": {
        backgroundColor: colors.salesOrderBorderColor,
      },
    },
  },
  "& .PURCHASE_ORDER_NOTE": {
    outline: `2px solid ${colors.purchaseOrderActivityHeaderColor}`,
    backgroundColor: colors.purchaseOrderBackgroundColor,
    "& .readMore": {
      backgroundColor: colors.purchaseOrderBackgroundColor,
    },
    "& .headerNote": {
      backgroundColor: colors.purchaseOrderActivityHeaderColor,
    },
    "& .note-detail": {
      "& .subHeaderNote": {
        backgroundColor: colors.purchaseOrderActivityHeaderColor,
      },
    },
  },

  "& .SHIPMENT_NOTE": {
    outline: "2px solid #E5BFEC",
    backgroundColor: colors.shipmentBadge,
    "& .readMore": {
      backgroundColor: colors.shipmentBadge,
    },
    "& .headerNote": {
      backgroundColor: "#E5BFEC",
    },
    "& .note-detail": {
      "& .subHeaderNote": {
        backgroundColor: "#E5BFEC",
      },
    },
  },

  "& .bodyNote": {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 7,
    minHeight: 50,
    position: "relative",
    "& .avatarNote": {
      width: 44,
      height: 40,
      marginInline: 12,
      position: "absolute",
    },
    "& .readGrid": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 30,
      height: "100%",
      cursor: "pointer",
      position: "absolute",
      right: 16,
      "& .activityReadStatus": {
        width: 8,
        height: 8,
        background: "#FFF",
        border: "1px solid #39f",
        borderRadius: "50%",
        transition: "all 0.5s",
        opacity: 0,
      },
      "& .activityPointRead": {
        background: "#39f",
        opacity: 1,
      },
    },
    "& .note-detail": {
      width: "100%",
      overflowWrap: "break-word",
      padding: "14px 40px 0px 74px",
      "& p": {
        marginBottom: 0,
        fontSize: 12,
        "& .styles-activity-stream-from-backend-red": {
          color: "red",
        },
        "& .styles-activity-stream-from-backend-green": {
          color: "green",
        },
        "& .styles-activity-stream-from-backend-bold": {
          fontWeight: 700,
        },
        "& .checkbox-input-done": {
          position: "p",
          top: 2,
        },
      },
      "& .activityNote": {
        fontSize: 14,
        fontFamily: "monospace",
        "& p": {
          fontSize: 14,
          overflowWrap: "anywhere",
        },
        "& figure": {
          margin: 0,
          height: "auto",
        },
      },
    },
    "& .documentsMentionClass": {
      background: colors.gray,
      borderRadius: 4,
      padding: "1px 7px",
      marginInline: 3,
      border: "1px solid #948686",
      "& *": {
        fontSize: "13px !important",
        textDecoration: "unset !important",
        fontStyle: "unset !important",
        color: `${colors.primaryDark} !important`,
      },
      "&:hover": {
        background: " #948686",
      },
      "&:active": {
        background: "#615E5E",
        border: "1px solid #615E5E",
      },
    },
    "& .SONMentionClass": {
      ...basesMentionStyle,
    },
    "& .SHIPMENTMentionClass": {
      ...basesMentionStyle,
    },

    "& .PONMentionClass": {
      ...basesMentionStyle,
    },
    [`& .${NOTES_SCOPE.SHIPMENT}MentionClass`]: {
      ...basesMentionStyle,
    },
    [`& .${NOTES_SCOPE.SALES_ORDER}MentionClass`]: {
      ...basesMentionStyle,
    },
    [`& .${NOTES_SCOPE.PURCHASE_ORDER}MentionClass`]: {
      ...basesMentionStyle,
    },
    "& .mentionsShipmenClass": {
      ...shipmentMentionBase,
    },
    "& .mentionsShipmenClassUpdated": {
      ...shipmentMentionBase,
    },
    "& .reverseShipmentClass": {
      ...shipmentMentionBase,
      background: "#ffffff",
      border: `2px solid ${colors.shipmentBorderColor}`,
    },

    "& ul": {
      paddingInlineStart: 30,
      display: "flow-root",
      "& li": {
        display: "list-item !important",
        listStyleType: "disc",
      },
    },
    "& ol": {
      paddingInlineStart: 30,
      display: "flow-root",
      "& li": {
        display: "list-item !important",
      },
      "& ol": {
        paddingInlineStart: 60,
      },
    },
  },
  "& .footer": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 12,
    width: "-webkit-fill-available",
    margin: "12px 16px 12px 74px",
    "& .username": { color: "#9D9D9D" },
    "& .text-info-edit": {
      color: "#9D9D9D",
    },
  },
}));

export const ReadByPopoverContainerStyled = styled(Popover)(() => ({
  "&.readByPopoverContainer": {
    "& .readByPopoverContainer": {
      padding: 14,
      minWidth: 220,
      "& .item-value": {
        fontSize: 11,
        margin: "0px 6px",
        minWidth: 60,
      },
      "& .read-user-item": {
        display: "flex",
        alignItems: "center",
        height: 24,
        color: AppConfig.themeColors.blueTextColor,
      },
      "& .unread-user-item": {
        display: "flex",
        alignItems: "center",
        height: 24,
        opacity: 0.4,
      },
    },
  },
}));
