import React, { useState } from "react";
import SearchBox from "../../SearchBox/SearchBox";
import Expense from "../../../api/model/Expense";

import {
  GENERAL_PERMISSION_VALUE,
  SALES_ORDER_STATUS,
} from "../../../helpers/constants";
import { orderType } from "../../../helpers/salesOrder";
import { dbTables } from "../../../api/types/dbTables";

import { useIsAllowedFunction } from "../../../hooks/permissions";
import { useUser } from "../../../hooks/user";
import { ExpenseFileIcon } from "../../../helpers/iconSvgPath";

import { Button, Grid } from "@mui/material";

let DB_TABLES_TO_SCOPE = {
  [dbTables.SALES_ORDERS]: orderType.SALES_ORDER,
  [dbTables.PURCHASE_ORDERS]: orderType.PURCHASE_ORDER,
  [dbTables.SHIPMENTS]: orderType.SHIPMENT,
};

function ExpensesHeader({
  collection = "",
  documentId = "",
  companyId,
  order = {},
  setFileTransferedData,
  query,
  onSetFilter = () => {},
  editing,
  expenses,
  setEditing,
  setExpenses,
}) {
  const [enableUploadFile, setEnableUploadFile] = useState(false);
  const isVoided = order.status === SALES_ORDER_STATUS.VOIDED;

  const isAllowed = useIsAllowedFunction();
  const user = useUser();

  function openTypeOfDocumentToProccess(ev) {
    const file = ev.dataTransfer.files[0];
    setFileTransferedData(file);
    setEnableUploadFile(false);
    ev.preventDefault();
  }

  return (
    <Grid
      container
      spacing={3}
      className={"expensesHeaderContainer"}
      style={{ opacity: editing ? 0.4 : 1 }}
    >
      <Grid item xs={4}>
        <div className="searchbox-expense-container">
          <SearchBox
            value={query}
            onChange={(ev) =>
              onSetFilter((oldData) => ({
                ...oldData,
                query: ev.target.value,
              }))
            }
            hasBeenReseted={() =>
              onSetFilter((oldData) => ({
                ...oldData,
                query: "",
              }))
            }
            placeholder="Search"
            disabled={!!editing}
          />
        </div>
      </Grid>
      <Grid item xs={4} style={{ textAlign: "-webkit-center" }}>
        <div
          className={
            isAllowed(
              GENERAL_PERMISSION_VALUE.CAN_ENTER_EXPENSES_VIA_DRAG_AND_DROP
            ) && !isVoided
              ? "drag-and-drop-expense-pdf"
              : "drag-and-drop-expense-pdf-disabled"
          }
          onDrop={openTypeOfDocumentToProccess}
        >
          <ExpenseFileIcon
            style={{
              margin: "0px 8px",
              top: -1,
              position: "relative",
            }}
          />
          DRAG & DROP PDF INVOICE HERE
          {isAllowed(
            GENERAL_PERMISSION_VALUE.CAN_ENTER_EXPENSES_VIA_DRAG_AND_DROP
          ) &&
            !isVoided &&
            !editing && (
              <input
                className="input-to-load-pdf"
                type={"file"}
                disabled={!enableUploadFile}
                onDragEnter={(ev) => {
                  ev.preventDefault();
                  setEnableUploadFile(true);
                }}
              />
            )}
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className="button-to-enter-expense-container">
          <Button
            variant="contained"
            color="primary"
            className="button-to-enter-expense"
            onClick={() => {
              onSetFilter((oldData) => ({
                ...oldData,
                query: "",
              }));
              const expensesCpy = [...expenses];
              const newExpense = {
                ...new Expense({
                  companyId,
                  parentDocumentId: documentId,
                  type: DB_TABLES_TO_SCOPE[collection],
                  createdBy: user.id,
                }),
              };
              setEditing({ ...newExpense, isCreating: true });
              expensesCpy.push(newExpense);
              setExpenses(expensesCpy);
              setTimeout(() => {
                const dateElement =
                  document.getElementById("expense_date_input");
                dateElement.focus();
              }, 50);
            }}
            disabled={
              !!(
                editing ||
                !isAllowed(
                  GENERAL_PERMISSION_VALUE.CAN_ENTER_EXPENSES_MANUALLY
                ) ||
                isVoided
              )
            }
          >
            + ENTER EXPENSE MANUALLY
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}

export default ExpensesHeader;
