import { colors } from "../../assets/jss/variables";
import AppConfig from "../../constants/AppConfig";
import { styled } from "@mui/material";

export const WeeklyViewContainerStyled = styled("div")(() => ({
  "&.weeklyViewContainer": {
    display: "flex",
    minWidth: "100%",
    flexDirection: "column",
    height: "calc(90vh - 213px)",
    transition: "all 1s",
    padding: "4px 0px",
    overflowX: "auto",
    "& .dayCardFlexContainer": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .collapse": {
      height: "100%",
      flex: 0,
      display: "flex",
      flexDirection: "column",
      // padding: 12,
      minWidth: 0,
      background: "#D5E4F7",
      width: 0,
      opacity: 0,
      transition: "all 500ms",
      "& span": {
        fontWeight: 400,
      },
    },
    "& .expanded": {
      height: "100%",
      flex: 1,
      display: "flex",
      flexDirection: "column",
      padding: 12,
      minWidth: 473,
      width: 473,
      background: "#D5E4F7",
      opacity: 1,
      transition: "all 500ms",
      "& span": {
        fontWeight: 400,
      },
    },
    "@keyframes expand": {
      from: {
        flex: 0.23,
      },
      to: {
        flex: 1,
      },
    },
    "& .header": {
      display: "flex",
      height: 80,
      minHeight: 80,
      padding: 16,
      position: "relative",
      "& div": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        maxHeight: 35,
        "& span": {
          color: "#25486D",
          fontSize: 16,
          fontWeight: 500,
        },
        "& p": {
          margin: 0,
          color: "#587495",
          fontSize: 13,
          fontWeight: 400,
        },
      },
      "& button": {
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        padding: 0,
        margin: 0,
        color: colors.primaryDark,
        fontWeight: 400,
        height: 21,
        width: 80,
        borderRadius: 3,
        fontSize: 11,
        marginRight: -6,
        "& img": {
          width: 10,
          height: 10,
          marginBottom: 2,
          marginLeft: 6,
        },
      },
    },
    "& .content": {
      height: "100%",
      flex: 1,
      backgroundColor: "white",
      padding: "15px 35px 4px 35px",
      zIndex: 1,
      overflowY: "auto !Important",
      "&::-webkit-scrollbar-thumb": {
        background: "transparent",
      },
      "&:hover": {
        "&::-webkit-scrollbar-thumb": {
          background: "#CCC",
        },
      },
    },
    "& .tab": {
      borderRadius: "4px 4px 0 0",
      backgroundColor: "#DDECFF",
      opacity: 1,
      minWidth: 100,
      minHeight: 40,
      textTransform: "uppercase",
      width: "49%",
    },
    "& .tabs": {
      minHeight: 40,
      "& button": {
        "&:active": {
          transform: "none",
        },
      },
    },
    "& .spacedTab": {
      borderRadius: "4px 4px 0 0",
      backgroundColor: "#DDECFF",
      opacity: 1,
      margin: "0 2px",
      minWidth: 100,
      minHeight: 40,
      textTransform: "uppercase",
      width: "32%",
    },
    "& .spacedTabs": {
      minHeight: 40,
      padding: "0 8px",
      "& button": {
        "&:active": {
          transform: "none",
        },
      },
    },
    "& .active": {
      backgroundColor: "white",
    },
    "& .whiteTab": {
      borderRadius: "4px 4px 0 0",
      backgroundColor: "white",
      opacity: 1,
      minWidth: 160,
      minHeight: 40,
      textTransform: "uppercase",
    },
  },
}));

export const WeeklyTaskCardStyled = styled("div")(() => ({
  "&.weeklytaskCardContent": {
    height: "auto",
    minHeight: 185,
    borderRadius: 6,
    padding: 6,
    marginBottom: 20,
    fontSize: 14,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    width: "100%",
    top: 0,
    border: `1px solid ${AppConfig.themeColors.salesOrderColor}`,
    "&::before": {
      position: "absolute",
      content: '""',
      left: -3,
      top: 0,
      zIndex: -1,
      borderRadius: 6,
    },
  },
  "& .containerSALES_ORDER": {
    backgroundColor: AppConfig.themeColors.salesOrderBackgroundColor,
  },
  "& .containerPURCHASE_ORDER": {
    backgroundColor: AppConfig.themeColors.purchaseOrderBackgroundColor,
  },
  "& .containerSHIPMENT": {
    backgroundColor: AppConfig.themeColors.shipmentColor,
  },
  "& .projectInfoContent": {
    width: "100%",
  },
  "& .salesOrderTask": {
    display: "flex",
    alignItems: "baseline",
  },
  "& .salesOrderTextContent": {
    maxWidth: "fit-content",
    width: "-webkit-fill-available",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .title": {
    display: "flex",
    alignItems: "center",
    padding: 12,
    marginTop: 4,
    "& span": {
      margin: 0,
    },
  },
  "& .projectInfo": {
    padding: 12,
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
  },
  "& .info": {
    paddingLeft: 12,
  },
  "& .lateBackground": {
    background: "linear-gradient(90deg, #E02020, #FFF)",
  },
  "& .nearDueBackground": {
    background: "linear-gradient(90deg, #FA9917, #FFF)",
  },
  "& .inProgressBackground": {
    background: "linear-gradient(90deg, #2AC940, #FFF)",
  },
  "& .notStartedBackground": {
    background: "linear-gradient(90deg, #FFF, #FFF)",
  },
  "& .completeBackground": {
    background: "linear-gradient(90deg, #E1E1E1, #FFF)",
  },
  "& .late": {
    "&::before": {
      backgroundColor: "#E02020",
      height: "100%",
      width: 4,
      zIndex: 1,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  "& .nearDue": {
    "&::before": {
      backgroundColor: "#FA9917",
      height: "100%",
      width: 4,
      zIndex: 1,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  "& .inProgress": {
    "&::before": {
      backgroundColor: "#2AC940",
      height: "100%",
      width: 4,
      zIndex: 1,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  "& .notStarted": {
    "&::before": {
      backgroundColor: "#FFF",
      height: "100%",
      border: "1px solid #F0F6FF",
      width: 4,
      zIndex: 1,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  "& .complete": {
    "&::before": {
      backgroundColor: "#E1E1E1",
      height: "100%",
      width: 4,
      zIndex: 1,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  "& .horizontalDivider": {
    width: "calc(100% + 12px)",
    height: 1,
    backgroundColor: colors.dividerColor,
    marginLeft: -5,
  },
  "& .customerNameContainer": {
    color: "#6F7E8E",
    marginBottom: 4,
    display: "flex",
    alignItems: "center",
    "& img": {
      marginRight: 8,
      marginLeft: 2,
    },
  },
  "& .factoryNameContainer": {
    color: "#6F7E8E",
    marginBottom: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& img": {
      marginRight: 8,
    },
  },
  "& .factoryNameContent": {
    display: "flex",
    alignItems: "center",
  },
  "& .lateDays": {
    color: "#E02020",
    fontSize: 12,
    fontWeight: "bold",
  },
  "& .salesOrderInfoContainer": {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#A3AE81",
    color: "white",
    borderRadius: "4px",
    padding: "4px 6px",
    fontSize: "12px",
    textDecoration: "underline",
    "&:hover": {
      color: "white",
      textDecoration: "underline",
    },
  },
  "& .purchaseOrderInfoContainer": {
    fontSize: "13px",
    paddingRight: "5px",
    margin: "0px 9px",
    fontWeight: 400,
  },
  "& .checkbox": {
    marginRight: 8,
  },
}));
