import moment from "moment";
import { getRandomId } from "../../helpers/helpers";

export default class FinalDestination {
  constructor({
    id = getRandomId(),
    address = "",
    name = "",
    created = moment().valueOf(),
  }) {
    this.id = id;
    this.name = name;
    this.created = created;
    this.address = address;
  }
}
