import React from "react";
import { PendingDividerStyled } from "./styles";
import { cx } from "@emotion/css";
import { InfoIcon } from "../../helpers/iconSvgPath";
import TooltipTD from "../Tooltip/TooltipTD";

function PendingDivider({
  text,
  style = {},
  containerStyle = {},
  firstLineStyles = {},
  lineNameStyles = {},
  className,
  infoLabel = null,
}) {
  return (
    <PendingDividerStyled
      className={cx("container", className)}
      style={containerStyle}
    >
      <div className="firstLine" style={firstLineStyles}></div>
      <div className="contentText" style={style}>
        <span className="lineName" style={lineNameStyles}>
          {text}
        </span>
      </div>
      {infoLabel && (
        <TooltipTD
          label={infoLabel}
          style={{ position: "absolute", right: -24 }}
        >
          <InfoIcon width={18} height={18} />
        </TooltipTD>
      )}
    </PendingDividerStyled>
  );
}

export default PendingDivider;
