import { now } from "moment";
import { getRandomId } from "../../helpers/helpers";
import { REPORTS_TYPE } from "../../helpers/constants";

export default class FolderReport {
  constructor({
    id = getRandomId(),
    name = "",
    description = "",
    permissionUsers = {},
    permissionGroups = {},
    creationDate = now(),
    active = true,
    parentId = "",
    type = REPORTS_TYPE.FOLDER,
  }) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.permissionUsers = permissionUsers;
    this.permissionGroups = permissionGroups;
    this.creationDate = creationDate;
    this.active = active;
    this.parentId = parentId;
    this.type = type;
  }
}
