import React from "react";
import { now } from "moment";
import {
  BACKDROP_TYPE,
  GENERAL_PERMISSION_VALUE,
  PO_STATUS,
} from "../../../helpers/constants";
import {
  POAmountFields,
  POObjFields,
  purchaseOrderFields,
} from "../../../helpers/orderDashboard";
import { useQueryParams } from "../../../hooks";
import { usePurchaseOrders } from "../../../hooks/salesOrders";
import {
  useIsAllowedCompany,
  useIsAllowedFunction,
} from "../../../hooks/permissions";
import { useBackdropState, useUser } from "../../../hooks/user";
import { dbTables, globalScopes } from "../../../api/types/dbTables";
import { useShipments } from "../../../hooks/shipments";
import MetadataProperty from "./MetadataProperty";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../firebase";
import { isEqual } from "lodash";
import { cx } from "@emotion/css";
import { BackdropDashboard } from "./BackdropDashboard";
import AppConfig from "../../../constants/AppConfig";

function POMetadataInfo() {
  const queryParams = useQueryParams();
  const purchaseOrders = usePurchaseOrders();
  const shipments = useShipments();
  const backdrop = useBackdropState();
  const currentPurchaseOrder =
    purchaseOrders.find(
      (purchaseOrder) => purchaseOrder.id === queryParams.purchaseOrderId
    ) || {};

  const user = useUser() || {};
  const companyId = user.companyId;
  const isAllowed = useIsAllowedFunction();
  const isCompanyAllowed = useIsAllowedCompany();
  const isReadOnly = false;
  const POAmountPermission = isAllowed(
    GENERAL_PERMISSION_VALUE.PURCHASE_ORDER_AMOUNT
  );

  function onChangeProperty(value, field) {
    if (isEqual(currentPurchaseOrder[field], value)) {
      return;
    }
    console.log(value);
    const companyRef = `${dbTables.COMPANIES}/${companyId}`;
    if (field === POObjFields.SHIP_DATE) {
      let fieldDates;
      if (!currentPurchaseOrder.shipDates) {
        fieldDates = [];
      } else {
        fieldDates = [...currentPurchaseOrder.shipDates];
      }
      fieldDates.push({ creationDate: now(), shipDate: value, user: user.id });
      updateDoc(
        doc(
          firestore,
          `${companyRef}/${dbTables.PURCHASE_ORDERS}/${currentPurchaseOrder.id}`
        ),
        {
          [field]: value,
          updated_by: user.id,
          triggerType: field,
          shipDates: fieldDates,
        }
      );
    } else {
      updateDoc(
        doc(
          firestore,
          `${companyRef}/${dbTables.PURCHASE_ORDERS}/${currentPurchaseOrder.id}`
        ),
        {
          [field]: value,
          updated_by: user.id,
          triggerType: field,
        }
      );
    }
  }

  return (
    <React.Fragment>
      {currentPurchaseOrder.id && (
        <BackdropDashboard backdropType={BACKDROP_TYPE.METADATA} />
      )}
      <div className="sales-order-info">
        <div className="metadata-info-container">
          <div className={cx("metadata-info", "poInfoGrid")}>
            {purchaseOrderFields.map((item, index) => {
              let labelStyle = item.labelStyle || {};
              const hasUserPermission = item.permission
                ? isAllowed(item.permission)
                : true;
              const hasCompanyPermission = item.companyPermission
                ? isCompanyAllowed(item.companyPermission)
                : true;
              const hasPermission = hasUserPermission && hasCompanyPermission;
              const disabled =
                isReadOnly ||
                currentPurchaseOrder.status === PO_STATUS.VOIDED ||
                !item.editable ||
                (POAmountFields.includes(item.field) && !POAmountPermission) ||
                !hasPermission;
              return (
                <div
                  style={{ gridColumnEnd: "span 1" }}
                  key={item.field || index}
                >
                  <MetadataProperty
                    onSave={onChangeProperty}
                    propertyLabelStyles={labelStyle}
                    property={item.field}
                    showEmpty={item.showEmpty}
                    item={{
                      ...currentPurchaseOrder,
                      balance:
                        parseFloat(currentPurchaseOrder.amount) -
                        parseFloat(currentPurchaseOrder.deposit),
                    }}
                    disabled={disabled}
                    companyId={companyId}
                    propertyScope={globalScopes.PURCHASE_ORDER}
                    field={item}
                    classProperty={
                      disabled ? "disabled-sales-order-property" : false
                    }
                    currentPurchaseOrder={currentPurchaseOrder}
                    purchaseOrders={[currentPurchaseOrder]}
                    shipments={shipments}
                    backdrop={{
                      open: backdrop.id === item.field,
                      background:
                        AppConfig.themeColors.purchaseOrderBackgroundColor,
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default POMetadataInfo;
