import { colors } from "../../assets/jss/variables";
import { FONTSIZE_NOTES, FONT_FAMILY_NOTES } from "../../helpers/constants";
import { Box, styled } from "@mui/material";

export const PermissionBodyStyled = styled("div")(() => ({
  "&.PermissionBody": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  "& .gridContainer": {
    display: "grid",
    gridTemplateColumns: "200px 1fr",
    padding: "10px 0px",
    borderRadius: 6,
    outline: `1px solid ${colors.primaryDark}`,
    maxHeight: "calc(100vh - 400px)",
    overflow: "scroll",
    marginInline: 48,
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent",
      width: 16,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "transparent",
      borderRadius: 7,
    },
    "&::-webkit-scrollbar-track": {
      border: " solid 1px transparent",
    },
    "&:hover": {
      "&::-webkit-scrollbar-thumb": {
        boxShadow: `inset 0 0 10px 10px ${colors.thumbScrollColor}`,
        borderBlock: "1px solid transparent",
        borderInline: "5px solid transparent",
      },
    },
    "& .rowContainer": {
      display: "contents",
      "& .userDataContainer": {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        paddingInline: "12px 6px",
      },
      "& .userData": {
        display: "flex",
        gap: 6,
        flexDirection: "row",
        alignItems: "center",
        height: 40,
      },
      "& .fileDocumentName": {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        height: 41,
        justifyContent: "flex-start",
        alignItems: "center",
        display: "flex",
        alignContent: "center",
        "& .fileDocumentContainer": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: 40,
          minWidth: 40,
        },
      },
      "& .borderBottom": {
        borderBottom: `1px solid ${colors.primaryDark}`,
      },
    },
  },
  "& .attentionText": {
    padding: "0px 70px",
  },
  "& .tableContainer": {
    overflow: "auto",
    borderRadius: 6,
    border: `1px solid ${colors.primaryDark}`,
    display: "flex",
    padding: "10px 12px",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  "& .tablePermission ": {
    "& .MuiTableCell-body": {
      padding: 0,
      border: 0,
      fontWeight: 400,
      height: 30,
      color: colors.primaryDark,
      fontSize: 17,
      "& .userData": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "3px 0px",
      },
    },
  },
}));

export const NoteEditorStyled = styled(Box)(() => ({
  "&.richTextBoxContainer": {
    borderRadius: 3,
    border: "1px solid #A8B9CA",
    width: "100%",
    height: "100%",
    flexDirection: "column-reverse",
    display: "flex",
    marginTop: 42,
    maxHeight: "calc(100vh - 290px)",
    "& .dropRichText": {
      width: "100%",
      height: "100%",
      "& .DraftEditor-root": {
        height: "100%",
        "& .DraftEditor-editorContainer": {
          height: "100%",
        },
      },
    },
    "& .RichEditor-editor": {
      cursor: "text",
      minHeight: 200,
      padding: "10px 15px",
      overflow: "auto",
      fontFamily: FONT_FAMILY_NOTES,
      fontSize: FONTSIZE_NOTES.NORMAL,
      lineHeight: 1.8,
      color: "#464D69",
      height: "100%",
      width: "100%",
      // zIndex: 15,
      // "& figure":{
      //   overflow: 'hidden'
      // },
      "& figure": {
        margin: "0",
        "& img": {
          minWidth: "100px !important",
          margin: 6,
          position: "unset !important",
        },
      },
      "& .notranslate": {
        height: "100%",
      },

      "& ol": {
        paddingInline: "40px",
        display: "flow-root",
      },
      "& ul": {
        paddingInline: "40px",
        listStyleType: "disc",
        display: "flow-root",
      },
      "& .m126ak5t": {
        overflow: "auto",
        maxHeight: "42%",
        maxWidth: 700,
      },
      "& .lxvs42t": {
        cursor: "initial",
      },
      "& .public-DraftEditorPlaceholder-root": {
        position: "absolute",
        color: "gray",
      },
      "& .align-left": {
        textAlign: "left",
      },
      "& .align-center": {
        textAlign: "center",
      },
      "& .align-right": {
        textAlign: "right",
      },
    },
    "& .m6zwb4v": {
      borderRadius: 4,
      padding: "0px 8px",
      fontSize: FONTSIZE_NOTES.MENTION,
      cursor: "initial",
      lineHeight: 1.3,
      marginInline: 6,
      marginBottom: 5,
    },
    "& .userBadge": {
      background: colors.gray,
      outline: "2px solid #948686",
    },
  },
  "&.typeNote-PURCHASE_ORDER_NOTE": {
    background: colors.primaryLight,
    "& .shipmentBadge": {
      background: colors.shipmentBadge,
      outline: `2px solid ${colors.shipmentBorderColor}`,
    },
  },
  "&.typeNote-SALES_ORDER_NOTE": {
    background: colors.salesOrderBackgroundColor,
    "& .shipmentBadge": {
      background: colors.shipmentBadge,
      outline: `2px solid ${colors.shipmentBorderColor}`,
    },
  },
  "&.typeNote-SHIPMENT_NOTE": {
    background: colors.shipmentBadge,
    "& .shipmentBadge": {
      background: "white",
      outline: `2px solid ${colors.shipmentBorderColor}`,
    },
  },
  "& .toolbar": {
    height: 26,
    display: "flex",
    outline: "1px solid #A8B9CA",
    background: "white",
    paddingLeft: 10,
    "& .inlineGroup": {
      display: "flex",
      height: "100%",
      alignItems: "center",
      padding: "0px 4px",
      "& .iconButton": {
        borderRadius: 2,
        height: "100%",
        display: "flex",
        width: 26,
        margin: "0px 3px",
        justifyContent: "center",
        "&:hover": {
          background: colors.buttomToolbarActive,
        },
        "& img": {
          alignSelf: "center",
        },
      },
    },
  },
  "& .groupButtom": {
    paddingInline: 20,
    borderRight: "1px solid #ccc",
    display: "flex",
  },
  "& .headline": {
    width: 26,
    marginLeft: 5,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    cursor: "pointer",
    borderRadius: 5,
    "&:hover": {
      background: "#efefef",
    },
    "& button": {
      minWidth: "unset",
    },
    "& .colorContainer": {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      height: "100%",
      marginTop: 3,
      "& .colorGradient": {
        marginTop: 2,
        width: 15,
        height: 3,
        borderRadius: 3,
        background:
          "linear-gradient(to right, rgb(101, 84, 192) 25%, rgb(0, 184, 217) 25%, rgb(0, 184, 217) 50%, rgb(255, 153, 31) 50%, rgb(255, 153, 31) 75%, rgb(222, 53, 11) 75%)",
      },
    },
    "& .e13wqaj6": {
      width: "100%",
      height: "100%",
      border: 0,
    },
    "& .e183m4hm": {
      width: "100%",
      height: "100%",
      border: 0,
      "& .e8k2yoa": {
        width: "100%",
        height: "100%",
        minWidth: "unset",
        border: 0,
        borderRadius: 5,
        color: colors.primaryDark,
      },
      "& .e6nwac2": {
        width: 60,
        height: 40,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },

      "& .ejr02pv": {
        width: 450,
        "& .e16zneum": {
          width: "100%",
        },
        "& .e1kg9q3n": {
          width: "100%",
          "& .ec6zxdw": {
            display: "flex",
            flexDirection: "column",
            "& .eyoq5wq": {
              "& span": {
                fontSize: 24,
                color: "#D7D7D7",
              },
            },
            "& .e1eigyu0": {
              "& span": {
                fontSize: 24,
              },
            },
          },
        },
        "& .e1cibj9i": {
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          padding: "0px 20px",
        },
      },
    },
    "& .e19xmvdb": {
      display: "none",
    },
    "& .containerHeadline": {
      display: "flex",
      position: "absolute",
      top: 20,
      left: -5,
      flexDirection: "column",
      zIndex: 100,
      background: "white",
      boxShadow: "0px 4px 10px rgb(0 0 0 / 25%)",
      borderRadius: 5,
      width: 109,
      "& .iconButton": {
        padding: "20px 16px",
      },
    },
    "& .containerColorHeadLine": {
      display: "flex",
      position: "absolute",
      top: 20,
      left: -5,
      zIndex: 100,
      background: "white",
      boxShadow: "0px 4px 10px rgb(0 0 0 / 25%)",
      borderRadius: 5,
      width: 240,
      padding: "4px 8px",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
  },
}));

export const ModalWarningStyled = styled("div")(() => ({
  "&.modalStyle": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: "unset",
    paddingTop: 50,
    "& .On": { width: 98, textAlign: "left" },
    "& .Off": { width: 151, textAlign: "left" },
    "& .switch": {
      "& .slider": {
        background: colors.sliderGreen,
        "&::before": {
          height: 17,
          width: 78,
          color: colors.sliderGreen,
          boxShadow: "0px 2px 4px rgb(0 0 0 / 25%)",
          left: 3.5,
          right: 3.5,
        },
      },
      "& input:checked + .slider.small": {
        background: colors.dangerRed,
        "&::before": {
          content: "attr(data-positive)",
          transform: "translateX(71px)",
          height: 17,
          width: 78,
          color: colors.dangerRed,
          boxShadow: "0px 2px 4px rgb(0 0 0 / 25%)",
          left: 3.5,
          right: 3.5,
        },
      },
    },
  },
}));
