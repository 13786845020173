export const categoriesTableheader = [
  {
    id: 1,
    label: "generic.text.category",
    key: "name",
  },
  {
    id: 2,
    label: "generic.text.description",
    key: "description",
  },
  {
    id: 3,
    label: "generic.text.createdBy",
    key: "createdBy",
  },
  {
    id: 4,
    label: "generic.text.dateCreated",
    key: "createdDate",
    styles: {
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  {
    id: 5,
    label: "generic.text.updatedBy",
    key: "updatedBy",
  },
  {
    id: 6,
    label: "generic.text.dateUpdated",
    key: "updatedDate",
    styles: {
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  {
    id: 7,
    label: "generic.text.blank",
    key: "blank",
  },
];

export const TagsHeadCells = [
  {
    label: "Label",
    key: "name",
    id: 0,
    canBeSort: true,
  },
  {
    label: "Description",
    key: "description",
    id: 1,
    canBeSort: false,
  },
  {
    label: "generic.text.createdBy",
    key: "createdBy",
    id: 2,
    canBeSort: true,
  },
  {
    id: 3,
    label: "generic.text.dateCreated",
    key: "createdDate",
    canBeSort: true,
  },
  {
    id: 4,
    label: "generic.text.updatedBy",
    key: "updatedBy",
    canBeSort: true,
  },
  {
    id: 5,
    label: "generic.text.dateUpdated",
    key: "updatedDate",
    canBeSort: true,
  },
  {
    id: 6,
    label: "generic.text.blank",
    key: "blank",
    canBeSort: false,
  },
];

export const AccessGroupHeadCells = [
  {
    label: "generic.text.name",
    key: "name",
    id: 0,
    canBeSort: true,
  },
  {
    label: "generic.text.description",
    key: "description",
    id: 1,
    canBeSort: true,
  },
  {
    id: 2,
    label: "generic.text.blank",
  },
  {
    id: 3,
    label: "generic.text.blank",
  },
];

export const UserHeaderCell = [
  {
    id: 0,
    label: "generic.textForm.firstName",
    key: "firstName",
    canBeSort: true,
  },
  {
    id: 1,
    label: "generic.textForm.lastName",
    key: "lastName",
    canBeSort: true,
  },
  {
    id: 2,
    label: "generic.textForm.displayName",
    key: "displayName",
    canBeSort: true,
  },
  {
    id: 3,
    label: "generic.textForm.cellPhone",
    key: "cellPhone",
    canBeSort: true,
  },
  {
    id: 4,
    label: "generic.textForm.email",
    key: "email",
    canBeSort: true,
  },
  {
    id: 5,
    label: "generic.textForm.role",
    key: "role",
    canBeSort: true,
  },
  {
    id: 6,
    label: "generic.textForm.permissionGroup",
    key: "permissionGroup",
    canBeSort: false,
  },
  {
    id: 7,
    label: "userscompany.tableheader.analyticsPerson",
    key: "typeOfUser",
    canBeSort: true,
    onlyTradedashEmployee: true,
  },
  {
    id: 8,
    label: "userscompany.tableheader.automaticSystemUser",
    key: "automaticSystemUser",
    canBeSort: false,
    onlyTradedashEmployee: true,
  },
  {
    id: 9,
    label: "generic.textForm.avatar",
    key: "avatar",
    canBeSort: false,
    style: { padding: "10px 5px" },
  },
  {
    id: 10,
    label: "generic.text.blank",
    key: "blank",
    canBeSort: false,
  },
];
