import { useSelector } from "react-redux";
import { useCompanyId } from ".";
import { dbTables } from "../api/types/dbTables";
import userTypes from "../api/types/userTypes";
import { sortObjectsBy } from "../helpers/helpers";

export function useBackdropState() {
  return useSelector(
    (state) =>
      state.data.dashboardBackdrop || {
        type: "",
        isOpen: false,
        id: "",
      }
  );
}

export function useUser() {
  const user = useSelector(
    (state) => state.data.currentUser || state.authUser.user
  );
  return user || {};
}


export function useNotifications() {
  return useSelector(
    (state) => state.data.loadedNotifications
  )||{};

}

export function useUserMentions(orderScope) {
  const allMentionsOrder =
    useSelector((state) => state.data[dbTables.MENTIONS]) || {};
  if (orderScope) {
    return allMentionsOrder[orderScope] || {};
  }
  return allMentionsOrder;
}

export function useTabId() {
  return useSelector((state) => state.data.tabId);
}

export function useUserDraft() {
  const user = useUser();
  const drafts = useSelector(
    (state) => state.data[dbTables.DRAFTS][user.id] || []
  );
  return drafts.sort(sortObjectsBy("creationDate", true));
}

export function useCompanyUsers({
  id = false,
  showBotUser = false,
  showInactiveUsers = true,
}) {
  const currentCompanyId = useCompanyId();
  const companyId = id || currentCompanyId;
  let users = useSelector((state) => state.data.users).filter(
    (user) =>
      user.companyId === companyId && user.role !== userTypes.TRADEDASH_EMPLOYEE
  );
  if (!showInactiveUsers) {
    users = users.filter((user) => user.active);
  }
  if (showBotUser) {
    users = users.filter(
      (user) =>
        user.companyId === companyId &&
        (user.role !== userTypes.TRADEDASH_EMPLOYEE || user.automaticSystemUser)
    );
  } else {
    users = users.filter(
      (user) =>
        user.companyId === companyId &&
        user.role !== userTypes.TRADEDASH_EMPLOYEE &&
        !user.automaticSystemUser
    );
  }
  return users || [];
}

export function useEmployeeUsers() {
  const users = useSelector((state) => state.data.users).filter(
    (user) => user.role === userTypes.TRADEDASH_EMPLOYEE
  );
  return users || [];
}

export function useCompanyPermissionUser(currentUser) {
  const permissionGroups = useSelector(
    (state) => state.data.permission_groups[currentUser.companyId] || []
  );
  const userPermissionGroups = [];
  permissionGroups
    .filter((permissionGroup) => !permissionGroup.isInactive)
    .forEach((permissionGroup) => {
      const currentPermissionGroups = currentUser.permissionGroups || {};
      if (
        currentPermissionGroups[permissionGroup.id] &&
        permissionGroup.users[currentUser.id]
      )
        userPermissionGroups.push(permissionGroup);
    });
  const sortPermissionGroup = userPermissionGroups.sort(sortObjectsBy("name"));
  return sortPermissionGroup;
}

export function useUserCustomSetting({ userId, setting }) {
  const customSettings =
    useSelector((state) => state.data[dbTables.CUSTOM_SETTINGS][userId]) || [];
  if (setting) {
    return customSettings.find((cSetting) => cSetting.id === setting) || {};
  }
  return customSettings;
}

export function useCurrentActivityNote() {
  const activityNote = useSelector((state) => state.data.activityNote) || {};
  return activityNote;
}

export function useOrderDashboardActualView() {
  const actualView =
    useSelector((state) => state.settings.orderDashboardActualView) || "";
  return actualView;
}
