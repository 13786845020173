import { styled } from "@mui/material";
import { dbTables } from "../../api/types/dbTables";
import AppConfig from "../../constants/AppConfig";

export const PendingDividerStyled = styled("div")(() => ({
  "&.container": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  "& .firstLine": {
    width: "100%",
    height: 2,
    background: "#ff5f58",
  },
  "& .contentText": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "white",
    position: "absolute",
    right: 32,
    padding: "0px 6px",
  },
  "& .ArrowUpwardIcon": {
    background: "#FF5F58",
    borderRadius: "50%",
    color: "white",
    width: 16,
    height: 16,
  },
  "& .lineName": {
    fontSize: 11,
    color: "#ff5f58",
    textAlign: "center",
  },
  [`&.divider_${dbTables.SALES_ORDERS}`]: {
    "& .contentText": {
      background: AppConfig.themeColors.salesOrderBackgroundColor,
    },
  },
  [`&.divider_${dbTables.PURCHASE_ORDERS}`]: {
    "& .contentText": {
      background: AppConfig.themeColors.purchaseOrderBackgroundColor,
    },
  },
  [`&.divider_${dbTables.SHIPMENTS}`]: {
    "& .contentText": {
      background: AppConfig.themeColors.shipmentColor,
    },
  },
}));

export const InactiveContainerStyled = styled("div")(() => ({
  margin: "45px 0px 0px 0px",
  height: 31,
  background: "#7A7272",
  color: "white",
  fontSize: 20,
  fontWeight: 700,
  width: "100%",
  display: "flex",
  alignItems: "center",
  "& .inactiveText": {
    marginLeft: 18,
  },
}));
