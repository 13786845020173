import React from "react";
import FactoryItem from "./FactoryItem";

function FactoryTable({
  onItemClick,
  onButtonClick = () => {},
  factories,
  companyId,
}) {
  return (
    <div style={{ overflow: "auto" }}>
      <ul>
        {factories.map((factory) => (
          <FactoryItem
            key={factory.id}
            factory={factory}
            onItemClick={onItemClick}
            onButtonClick={onButtonClick}
            companyId={companyId}
          />
        ))}
      </ul>
    </div>
  );
}

export default FactoryTable;
