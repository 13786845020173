import React from "react";
import { Switch } from "@mui/material";
import { getUserByField } from "../../../helpers/helpers";
import { getUserAvatar } from "../../../helpers/users";
import { doc, updateDoc } from "firebase/firestore";
import TooltipTD from "../../Tooltip/TooltipTD";
import { EditIcon } from "../../../helpers/iconSvgPath";
import { colors } from "../../../assets/jss/variables";
import { cx } from "@emotion/css";
import { getDateFormat } from "../settingsHelper";
import { firestore } from "../../../firebase";
import { dbTables } from "../../../api/types/dbTables";

function CategoryTableRow({
  categoryItem = {},
  categories,
  users = [],
  companyId,
  canRename,
  canChangeActive,
  onEditData = () => {},
}) {
  return (
    <div className="rowTable">
      <div className={cx("cellTable", "name")}>
        <span>{categoryItem.name}</span>
      </div>
      <div className={cx("cellTable", "description")}>
        <span>{categoryItem.description}</span>
      </div>
      <div className={cx("cellTable", "createdBy")}>
        {getUserAvatar({
          user: users.find((user) => user.id === categoryItem.createdBy),
          avatarClassName: "avatarTable",
          hasToolTip: false,
        })}
        <span>
          @
          {getUserByField({
            users,
            userId: categoryItem.createdBy,
            field: "displayName",
          })}
        </span>
      </div>
      <div className={cx("cellTable", "dateCreated")}>
        {getDateFormat(categoryItem.createdDate)}
      </div>
      <div className={cx("cellTable", "createdBy")}>
        {getUserAvatar({
          user: users.find((user) => user.id === categoryItem.updatedBy),
          avatarClassName: "avatarTable",
          hasToolTip: false,
        })}
        <span>
          {categoryItem.updatedBy &&
            "@" +
              getUserByField({
                users,
                userId: categoryItem.updatedBy,
                field: "displayName",
              })}
        </span>
      </div>
      <div className={cx("cellTable", "dateCreated")}>
        {getDateFormat(categoryItem.updatedDate)}
      </div>

      <div className={cx("cellTable", "actionButtonsContainer")}>
        {canChangeActive && (
          <TooltipTD
            label={categoryItem.active ? "Deactivate" : "Activate"}
            style={{ display: "flex" }}
          >
            <Switch
              checked={categoryItem.active}
              onChange={(ev, checked) => {
                const category = categories.find(
                  (item) => item.id === categoryItem.summaryId
                );
                let categoriesCpy = [...category.categories];
                categoriesCpy = categoriesCpy.map((item) => {
                  if (item.id === categoryItem.id) {
                    return { ...item, active: checked };
                  }
                  return item;
                });
                updateDoc(
                  doc(
                    firestore,
                    `${dbTables.COMPANIES}/${companyId}/${dbTables.CATEGORIES}/${category.id}`
                  ),
                  {
                    categories: categoriesCpy,
                  }
                );
                ev.preventDefault();
                ev.stopPropagation();
              }}
              onClick={(ev) => ev.stopPropagation()}
              value={categoryItem.active}
              inputProps={{ "aria-label": "primary checkbox" }}
              style={{
                color: colors.primaryDark,
              }}
              size="small"
            />
          </TooltipTD>
        )}
        {canRename && (
          <TooltipTD
            label="Edit"
            style={{ display: "flex", cursor: "pointer" }}
          >
            <EditIcon onClick={onEditData} />
          </TooltipTD>
        )}
      </div>
    </div>
  );
}

export default CategoryTableRow;
