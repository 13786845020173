import React, { useState } from "react";
import { dbTables } from "../../api/types/dbTables";
import {
  CATEGORY_PERMISSION,
  COMPANY_PERMISSION_VALUE,
  GENERAL_PERMISSION_VALUE,
  permissionsLabels,
  ROLES_PERMISSION_CATEGORY,
  screenPermissionLabels,
} from "../../helpers/constants";
import AttentionModal from "../Modal/AttentionModal";
import userTypes from "../../api/types/userTypes";
import { SelectAllNoneStyled } from "./styles";
import {
  useCompanyIsAllowed,
  useSummaryPermisions,
} from "../../hooks/permissions";
import { firestore } from "../../firebase";
import { hasFeatureFlagPermission } from "../../helpers/helpers";
import { useFeatureFlags } from "../../hooks/featureFlags";
import RolePermissionMananger from "./RolePermissionMananger";
import RoleScreenPermission from "./RoleScreenPermission";
import RolesPermissionsModel from "../../api/model/RolesPermissions";
import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";

const idCategoryData = {
  "Screen Access": "screen_access",
  "General Permissions": "general_permissions",
};

function UserPermissions({
  role = {},
  user,
  companyId,
  isReadOnly = false,
  handleReadOnlyModal = () => {},
  permissions = [],
}) {
  const [openModal, setOpenModal] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const featureFlags = useFeatureFlags({ companyId });
  const permissionSummary = useSummaryPermisions({ userRole: role });
  const shipmentQuantityAllowed = useCompanyIsAllowed(
    COMPANY_PERMISSION_VALUE.SHIPMENT_QUANTITY,
    companyId
  );
  const orderReadyDate = useCompanyIsAllowed(
    COMPANY_PERMISSION_VALUE.ORDER_READY_DATE,
    companyId
  );

  async function changePermission({ permission, value, tab = {} }) {
    const blockUpdatePermissionsScreen =
      user.role === role.name &&
      (value === GENERAL_PERMISSION_VALUE.SETTINGS ||
        value === GENERAL_PERMISSION_VALUE.SETTINGS_ROLES);
    if (blockUpdatePermissionsScreen) {
      openAttentionModal(false);
    } else {
      const idCategory = idCategoryData[permission.category];
      const currentSummaryPermission =
        permissionSummary.find((summary) => summary.id === idCategory) || {};
      const rolePermission = {
        ...new RolesPermissionsModel({
          ...currentSummaryPermission,
          id: idCategory,
        }),
      };
      let newPermissions = rolePermission.permissions || {};
      const currentId = tab.value || permission.value;
      newPermissions = {
        ...newPermissions,
        [currentId]: !newPermissions[currentId],
      };
      setDoc(
        doc(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${dbTables.ROLES}/${role.id}/${dbTables.PERMISSIONS}/${rolePermission.id}`
        ),
        { ...rolePermission, permissions: newPermissions }
      );

      if (value === GENERAL_PERMISSION_VALUE.RECEIVE_NOTIFICATION_FROM_API) {
        const roleUsersSnapDB = await getDocs(
          query(
            collection(firestore, `${dbTables.USERS}`),
            where("companyId", "==", companyId),
            where("role", "==", role.name)
          )
        );
        const roleUsersDB = roleUsersSnapDB.docs.map((doc) => ({
          ...doc.data(),
          ref: doc.ref,
        }));

        roleUsersDB.forEach((user) => {
          updateDoc(user.ref, {
            errorLogNotification:
              !!newPermissions[
                GENERAL_PERMISSION_VALUE.RECEIVE_NOTIFICATION_FROM_API
              ],
          });
        });
      }
    }
  }

  const getScreenAccessList = (getTabs) => {
    let list = screenPermissionLabels;
    const tabs = list.find(
      (item) => item.value === GENERAL_PERMISSION_VALUE.SETTINGS
    ).tabs;
    if (getTabs) {
      list = [...list, ...tabs];
    }
    return list.filter((item) =>
      item.permissionFlag
        ? hasFeatureFlagPermission({
            featureFlags,
            user,
            featureFlagName: item.permissionFlag,
          })
        : true
    );
  };

  const getGeneralPermissions = () => {
    let list = permissionsLabels;
    if (!shipmentQuantityAllowed) {
      list = list.filter(
        (item) => item.value !== GENERAL_PERMISSION_VALUE.SHIPMENT_QUANTITY
      );
    }
    if (!orderReadyDate) {
      list = list.filter(
        (item) => item.value !== GENERAL_PERMISSION_VALUE.ORDER_READY_DATE
      );
    }
    return list;
  };

  function arePermissionsDisabled() {
    return !role || role.name === userTypes.SUPER_ADMIN;
  }
  function openAttentionModal(isBlockForPermission = true) {
    if (isBlockForPermission) {
      setMessageModal(
        <span>
          The Group SuperAdmin <strong>always</strong> has all the permissions
          turned on
        </span>
      );
    } else {
      setMessageModal(
        <span style={{ lineHeight: 1.6 }}>
          You cannot turn off access to the Settings/ Settings Roles screen
          because you belong to the current group and by turning off access to
          Settings you would lock yourself out of this screen
        </span>
      );
    }
    setOpenModal(true);
  }
  function closeAttentionModal() {
    setOpenModal(false);
  }

  function changeAllPermissionFromCategory({ category, list, enabled }) {
    if (isReadOnly) {
      handleReadOnlyModal();
      return;
    }
    const idCategory = idCategoryData[category.id];
    const currentSummaryPermission =
      permissionSummary.find((summary) => summary.id === idCategory) || {};
    const rolePermission = {
      ...new RolesPermissionsModel({
        ...currentSummaryPermission,
        id: idCategory,
      }),
    };
    let newPermissions = {};
    list.forEach(
      (item) => (newPermissions = { ...newPermissions, [item.value]: enabled })
    );
    const blockUpdatePermissionsScreen = user.role === role.name;
    if (blockUpdatePermissionsScreen) {
      newPermissions = {
        ...newPermissions,
        [GENERAL_PERMISSION_VALUE.SETTINGS]: true,
        [GENERAL_PERMISSION_VALUE.SETTINGS_ROLES]: true,
      };
    }
    setDoc(
      doc(
        firestore,
        `${dbTables.COMPANIES}/${companyId}/${dbTables.ROLES}/${role.id}/${dbTables.PERMISSIONS}/${rolePermission.id}`
      ),
      { ...rolePermission, permissions: newPermissions }
    );
  }
  const headerCategories = (category = {}) => {
    const list =
      category.id === CATEGORY_PERMISSION.SCREEN_ACCESS
        ? getScreenAccessList(true)
        : getGeneralPermissions();
    return (
      <div className="permissions-container-header">
        <h6>{category.id}</h6>
        {role.name !== userTypes.SUPER_ADMIN && (
          <SelectAllNoneStyled className="selectAllNoneContainer">
            <span
              className="select-all-button"
              onClick={() =>
                changeAllPermissionFromCategory({
                  category,
                  enabled: true,
                  list,
                })
              }
            >
              Select all
            </span>
            -
            <span
              className="select-none-button"
              onClick={() =>
                changeAllPermissionFromCategory({
                  category,
                  enabled: false,
                  list,
                })
              }
            >
              Clear
            </span>
          </SelectAllNoneStyled>
        )}
      </div>
    );
  };

  return (
    <div className="user-manager-permissions">
      {openModal && (
        <AttentionModal
          isOpen={openModal}
          onClose={closeAttentionModal}
          title="Attention"
          description={messageModal}
          onClick={closeAttentionModal}
        />
      )}
      <ul className="permissions-container">
        {ROLES_PERMISSION_CATEGORY.map((category, indexCategory) => {
          const list =
            category.id === CATEGORY_PERMISSION.SCREEN_ACCESS
              ? getScreenAccessList()
              : getGeneralPermissions();
          return (
            <React.Fragment key={category.id + indexCategory}>
              {headerCategories(category)}
              {category.id === CATEGORY_PERMISSION.SCREEN_ACCESS ? (
                <RoleScreenPermission
                  category={category}
                  list={list}
                  permissions={permissions}
                  isDisabled={arePermissionsDisabled()}
                  role={role}
                  onChangePermission={changePermission}
                />
              ) : (
                <RolePermissionMananger
                  category={category}
                  list={list}
                  permissions={permissions}
                  isDisabled={arePermissionsDisabled()}
                  role={role}
                  onChangePermission={changePermission}
                />
              )}
            </React.Fragment>
          );
        })}
      </ul>
    </div>
  );
}

export default UserPermissions;
