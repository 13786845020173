import React, { useEffect, useState } from "react";
import { ManagementDocumentNoteStyled } from "./styles";
import { isOpenedFolder } from "../../../helpers/documentsv2.helper";
import { useIsAllowedFunction } from "../../../hooks/permissions";
import {
  filtersDocumentsPermission,
  getDocumentContainerClass,
} from "../../../helpers/documents";
import AttentionModal from "../../Modal/AttentionModal";
import {
  convertToTreeRefactored,
  getIdFromStructureFolders,
  sortObjectsBy,
} from "../../../helpers/helpers";
import { useUser } from "../../../hooks/user";
import { getDocumentsDescendants } from "../../ReportsTree/helpers";
import ManagementDocumentsHeader from "../../Documents/ManagementDocumentsHeader";
import { TYPE_OF_FILE } from "../../../helpers/constants";
import DocumentNoteItem from "./DocumentNoteItem";
import Mark from "mark.js";
import { SEARCH_BOX_TYPES } from "../../../helpers/orderDashboard";

const IDENTATION = 60;

function ManagementNoteDocumentsV2({
  documents = [],
  dbCollection,
  parentCollection,
  parentDocumentId,
  isVoided = false,
  permissionScope,
  permissionGroupsDB,
  handleDocumentsDB,
  handleRichDocument,
  mainEntity,
}) {
  const allowed = useIsAllowedFunction();
  const [openedFolders, setOpenedFolders] = useState({});
  const [search, setSearch] = useState("");
  const [sortingDocument, setSortingDocument] = useState({
    sortBy: "name",
    asc: false,
  });
  const [attentionModal, setAttentionModal] = useState({
    open: false,
    description: "",
    action: () => {},
    confirmationText: "",
    cancelText: "",
  });
  const user = useUser();
  const filteredDocuments = filtersDocumentsPermission({
    allowed,
    documents,
    permissionGroupsDB,
    permissionScope: permissionScope,
    user,
    filterFilePlaceholder: true,
    search,
  });

  const documentsTree = convertToTreeRefactored({
    documents: filteredDocuments.filter((doc) => {
      if (doc.placeholder && doc.type !== TYPE_OF_FILE.FOLDER) return false;
      return true;
    }),
  });

  const isAllFoldersOpen = () => {
    const allFoldersIds = getIdFromStructureFolders(filteredDocuments);
    const openedFoldersIds = Object.values(openedFolders);
    return (
      allFoldersIds.length === openedFoldersIds.length &&
      openedFoldersIds.every((isOpen) => isOpen)
    );
  };

  const openCloseAllFolders = (value) => {
    let openFoldersIds = {};
    if (value) {
      const idsList = getIdFromStructureFolders(filteredDocuments);
      idsList.forEach(
        (id) => (openFoldersIds = { ...openFoldersIds, [id]: true })
      );
    }
    setOpenedFolders(openFoldersIds);
  };

  const markInstance = new Mark(
    document.getElementById("document-note-list-container")
  );
  function performMark(keyword = "") {
    var options = {
      separateWordSearch: false,
      diacritics: false,
      debug: false,
      acrossElements: true,
      accuracy: "partially",
      exclude: [
        ".document-creation-date-content > *",
        ".document-created-by-content > *",
        ".created-by-name",
        ".created-by-name > *",
        ".versionLabel",
      ],
    };
    markInstance.unmark({
      done: () => {
        setTimeout(() => {
          markInstance.mark(keyword, options);
        }, 300);
      },
    });
  }

  useEffect(() => {
    performMark(search);
  }, [search]);

  function recursiveDocuments({
    document = {},
    childNumber = 0,
    isRoot = false,
    isPlaceholder = false,
  }) {
    const { child = [], type } = document;
    let sortBy = sortingDocument.sortBy;
    let asc = sortingDocument.asc;
    if (type !== TYPE_OF_FILE.FOLDER) {
      sortBy = "version";
      asc = true;
    }
    const childrenSize =
      document.type === TYPE_OF_FILE.FOLDER
        ? getDocumentsDescendants({
            documents: filteredDocuments,
            originId: document.id,
          }).length
        : document.versions?.length;

    return (
      <div
        key={document.id + "activeNote"}
        style={{
          marginLeft: isRoot ? 0 : IDENTATION,
          width: "-webkit-fill-available",
        }}
        id={`documentItem_${document.id}`}
        className={getDocumentContainerClass({
          openedFolders,
          document,
          search,
        })}
      >
        <DocumentNoteItem
          document={document}
          documents={documents}
          dbCollection={dbCollection}
          parentCollection={parentCollection}
          parentDocumentId={parentDocumentId}
          isVoided={isVoided}
          onOpenFolder={(documentId, forceOpen = false) => {
            setOpenedFolders({
              ...openedFolders,
              [documentId]: forceOpen || !openedFolders[documentId],
            });
          }}
          onUpdatePermissionState={(newDocument) => {
            handleDocumentsDB((oldData) => {
              return {
                ...oldData,
                [mainEntity.id]: oldData[mainEntity.id].map((file) => {
                  if (
                    file.type === TYPE_OF_FILE.FOLDER &&
                    file.id === newDocument.id
                  ) {
                    return newDocument;
                  } else return file;
                }),
              };
            });
          }}
          handleRichDocument={handleRichDocument}
        />
        <div
          className="children-container"
          style={{
            maxHeight: isOpenedFolder({ openedFolders, document, search })
              ? childrenSize * 190
              : "0px",
            overflow: "hidden",
            transition: "max-height 1s ease-in-out",
          }}
        >
          {child.sort(sortObjectsBy(sortBy, asc)).map((cDocument) =>
            recursiveDocuments({
              document: cDocument,
              childNumber: childNumber + 1,
              isPlaceholder,
            })
          )}
        </div>
      </div>
    );
  }
  const cleanModal = () =>
    setAttentionModal({
      open: false,
      description: "",
      action: () => {},
      confirmationText: "",
      cancelText: "",
    });

  return (
    <ManagementDocumentNoteStyled className="managementDocumentContainer">
      {attentionModal.open && (
        <AttentionModal
          title="Attention"
          isOpen={attentionModal.open}
          description={attentionModal.description}
          onClick={(ev) => {
            attentionModal.action();
            cleanModal();
            ev.stopPropagation();
          }}
          onClose={(ev) => {
            cleanModal();
            ev.stopPropagation();
          }}
          cancellable={!!attentionModal.cancelText}
          confirmationText={attentionModal.confirmationText}
          cancelText={attentionModal.cancelText}
          styleModal={{
            maxWidth: "calc(100vw - 50%)",
          }}
        />
      )}
      <div
        className="stickyHeader"
      >
        <ManagementDocumentsHeader
          handleExpandOrCollapse={(value) => {
            openCloseAllFolders(value);
          }}
          isExpand={isAllFoldersOpen()}
          documentsTree={documentsTree}
          hiddenActionIcon
          searchLabelId="documents-search-box-modal"
          parentCollection={parentCollection}
          onQuerySearch={(value) => setSearch(value)}
          style={{ padding: 0 }}
          handleSorting={setSortingDocument}
          sortBy={sortingDocument.sortBy}
          type={SEARCH_BOX_TYPES.NOTE_DOCUMENTS}
        />
      </div>
      <div
        className={"documentsList"}
        id="document-note-list-container"
      >
        {documentsTree
          .sort(sortObjectsBy(sortingDocument.sortBy, sortingDocument.asc))
          .map((rootDocument) => {
            return recursiveDocuments({
              document: { ...rootDocument },
              childNumber: 0,
              isRoot: true,
            });
          })}
      </div>
    </ManagementDocumentNoteStyled>
  );
}

export default ManagementNoteDocumentsV2;
