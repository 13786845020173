import { now } from "moment";
import { getRandomId } from "../../helpers/helpers";

export default class Activity {
  constructor({
    id = getRandomId(),
    creationDate = now(),
    detail = "",
    scope = "",
    type = "",
    user = "",
    companyId = "",
    salesOrderId = "",
    purchaseOrderId = "",
    shipmentId = "",
    hiddenValueDetail = "",
    involvedFields = [],
  }) {
    this.id = id;
    this.creationDate = creationDate;
    this.detail = detail;
    this.scope = scope;
    this.type = type;
    this.user = user;
    this.companyId = companyId;
    this.salesOrderId = salesOrderId;
    this.shipmentId = shipmentId;
    this.purchaseOrderId = purchaseOrderId;
    this.hiddenValueDetail = hiddenValueDetail;
    this.involvedFields = involvedFields;
  }
}
