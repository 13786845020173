import React, { useState, useEffect } from "react";
import { FactoryViewStyled } from "./styles";
import LeftColumn from "../../components/ColumnLayout/LeftColumn";
import ColumnContainer from "../../components/ColumnLayout/ColumnContainer";
import RightColumn from "../../components/ColumnLayout/RightColumn";
import { useCompanyId, useModal, usePermissionGroups } from "../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { sortObjectsBy, isTradeDashEmployee } from "../../helpers/helpers";
import FactoryInfo from "../../components/FactoriesScreen/FactoryInfo";
import FactoryDocuments from "../../components/FactoriesScreen/FactoryDocuments";
import { useIsAllowedFunction } from "../../hooks/permissions";
import { useUser } from "../../hooks/user";
import ReadOnlyModal from "../../components/Modal/ReadOnlyModal";
import {
  useFactories,
  useCurrentFactory,
  useSummaryFactories,
} from "../../hooks/factories";
import FactoryItem from "../../api/model/factoryItem";
import { GENERAL_PERMISSION_VALUE } from "../../helpers/constants";
import { updateDoc } from "firebase/firestore";
import CustomModal from "../../components/Modal/Modal";
import CreateVendorCustomer from "../../components/General/CreateVendorCustomer";
import { createFactory } from "../../helpers/factories";
import AttentionModal from "../../components/Modal/AttentionModal";
import userTypes from "../../api/types/userTypes";
import IntlMessages from "../../util/IntlMessages";
import LoadingBackdrop from "../../components/WholeScreenFocusBackdrop/LoadingBackdrop";
import FactoryTemplateContainer from "../../components/Factories/FactoryTemplateContainer";
import TemplateChangeLog from "../../components/SalesOrders/TemplateChangeLog";
import { dbTables } from "../../api/types/dbTables";
import { Button } from "@mui/material";
import { colors } from "../../assets/jss/variables";

// import { trackEvent } from "../../helpers/analytics";

function FactoriesScreen() {
  const companyId = useCompanyId();
  const user = useUser();
  const navigate = useNavigate();
  const [showInactive, setShowInactive] = useState(false);
  const [settingsSnapshot, setSettingsSnapshot] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [attentionModalData, setAttentionModalData] = useState({});
  const isReadOnly = isTradeDashEmployee(user);
  const [openReadOnlyModal, setReadOnlyModal] = useState(false);
  const summaryFactories = useSummaryFactories();
  const allFactories = useFactories();
  const factories = allFactories.filter((factory) => {
    if (!showInactive) {
      return !factory.inactive;
    }
    return true;
  });
  const hasPermission = (currentFactoryId) =>
    isAllowed(currentFactoryId) ||
    isAllowed(GENERAL_PERMISSION_VALUE.ALL_VENDORS);
  const { factoryId } = useParams();
  const summaryFactory =
    factories.find((factory) => factory.id === factoryId) || {};
  const currentFactory = useCurrentFactory() || {};
  const isAllowed = useIsAllowedFunction();
  const factoriesWitPermission = factories.filter((factory) =>
    hasPermission(factory.id)
  );
  const allFactoryWithPermission = allFactories.filter(
    (factory) =>
      hasPermission(factory.id) ||
      isAllowed(GENERAL_PERMISSION_VALUE.ALL_VENDORS)
  );

  const [query, setQuery] = useState("");

  const {
    open: templateIsOpen,
    openModal: openTemplate,
    closeModal: closeTemplate,
  } = useModal();

  const resetTopScroll = () => {
    const element = document.getElementById("leftColumnScroller");
    if (element) {
      element.scrollTop = 0;
    }
  };

  const changeFactoryPath = () => {
    if (factoriesWitPermission.length > 0) {
      navigate(`/app/factories/${factoriesWitPermission[0].id}`, {
        replace: true,
      });
      resetTopScroll();
    } else if (allFactoryWithPermission.length === 0) {
      navigate(`/app/factories/empty`, { replace: true });
    }
  };

  useEffect(() => {
    if (
      (currentFactory.inactive && !showInactive) ||
      !hasPermission(factoryId)
    ) {
      changeFactoryPath();
    }
  }, [showInactive, user.permissions, currentFactory.inactive]);

  const permissionGroups = usePermissionGroups({
    companyId,
    filteringByInactive: true,
  });

  async function handleUpdateSummary(newFactory) {
    const findSummary = summaryFactories.find(
      (summary) => summary.id === newFactory.factoriesSummaryId
    );
    if (!findSummary) {
      return;
    }
    const list = findSummary.factories.map((item) => {
      if (item.id === newFactory.id) {
        let newItem = { ...new FactoryItem({ ...newFactory }) };
        if (item.isOutdated) {
          newItem = { ...newItem, isOutdated: item.isOutdated };
        }
        return newItem;
      } else {
        return item;
      }
    });
    updateDoc(findSummary.ref, {
      factories: list,
    });
  }
  function filterFactories() {
    const filterFactories = factoriesWitPermission
      .filter((factory) => {
        const name = (factory.name || "").toLowerCase();
        const parseQuery = query.toLocaleLowerCase();
        const number = (factory.number || "").toLowerCase();

        return name.includes(parseQuery) || number.includes(parseQuery);
      })
      .sort(sortObjectsBy("name"));
    return filterFactories || [];
  }

  const handleReadOnlyModal = () => {
    setReadOnlyModal(true);
  };
  const hasPermissionsTemplate = () => {
    return (
      user.role === userTypes.SUPER_ADMIN ||
      user.role === userTypes.TRADEDASH_EMPLOYEE ||
      isAllowed("modify_factory_template")
    );
  };

  const showInactiveFactory = currentFactory.inactive && !showInactive;

  return (
    <FactoryViewStyled className="factoryViewContainer">
      {attentionModalData.open && (
        <AttentionModal
          title="Attention"
          description={attentionModalData.text}
          isOpen={attentionModalData.open}
          confirmationText="Done"
          onClick={() => setAttentionModalData({})}
        />
      )}
      {openModal && (
        <CustomModal
          header={"New Vendor"}
          isOpen={openModal}
          onClose={() => {
            if (!isLoading) {
              setOpenModal(false);
            }
          }}
          modalStyle={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {isLoading && <LoadingBackdrop />}
          <CreateVendorCustomer
            onSubmit={async ({ item }) => {
              setIsloading(true);
              const result = await createFactory({
                factory: item,
                companyId,
                summary: summaryFactories,
                allFactories: factories,
                user: user,
              });
              if (result.success) {
                setOpenModal(false);
                navigate(`/app/factories/${result.id}`);
                setIsloading(false);
              } else {
                let warningText = "";
                if (result.errorNumber) {
                  warningText = "number";
                }
                setAttentionModalData({
                  open: true,
                  text: `That vendor ${warningText} is already in use`,
                });
                setIsloading(false);
              }
            }}
            handleClose={() => setOpenModal(false)}
            user={user}
            scope={"Vendor"}
          />
        </CustomModal>
      )}
      <ColumnContainer>
        <LeftColumn
          redirectPath={"/app/factories"}
          title={`Vendors (${factoriesWitPermission.length})`}
          onChangeQuery={setQuery}
          query={query}
          cookie={"filterFactories"}
          checkBoxLabel={"Show inactive vendors"}
          onChangeInactive={() => setShowInactive(!showInactive)}
          showInactive={showInactive}
          navList={filterFactories()}
          allowToCreate={!isAllowed("can_create_vendors")}
          onAddNewItem={() => {
            if (!isReadOnly) {
              setOpenModal(true);
            } else {
              handleReadOnlyModal();
            }
          }}
          isEmpty={allFactoryWithPermission.length === 0}
        />

        {factoryId !== "empty" &&
          hasPermission(factoryId) &&
          factoryId === currentFactory.id &&
          !showInactiveFactory && (
            <RightColumn
              key={currentFactory.id}
              title={factoryId === "factories" ? " " : currentFactory.name}
              button={
                hasPermissionsTemplate() && (
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 16 }}
                  >
                    <TemplateChangeLog
                      changeLogsPath={`${dbTables.COMPANIES}/${companyId}/${dbTables.FACTORIES}/${factoryId}/${dbTables.FACTORY_TEMPLATE_CHANGE_LOG}`}
                      entity={factories.find(
                        (factory) => factory.id === factoryId
                      )}
                      scope={dbTables.FACTORIES}
                    />

                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "white",
                        color: colors.primaryDark,
                        fontWeight: 500,
                        fontSize: 14,
                      }}
                      className="button-white-button"
                      onClick={openTemplate}
                      disabled={!isAllowed("modify_factory_template")}
                    >
                      <IntlMessages id="factories.screen.po.template" />
                    </Button>
                  </div>
                )
              }
            >
              <div className="contentContainer">
                {hasPermission(factoryId) && (
                  <FactoryInfo
                    factory={currentFactory}
                    factories={factoriesWitPermission}
                    isReadOnly={isReadOnly}
                    handleReadOnlyModal={handleReadOnlyModal}
                    handleUpdateSummary={handleUpdateSummary}
                    isAllowed={isAllowed}
                  />
                )}
                {factoriesWitPermission.length > 0 &&
                  currentFactory.id &&
                  hasPermission(factoryId) && (
                    <FactoryDocuments
                      factory={currentFactory}
                      permissionGroups={permissionGroups}
                      isReadOnly={isReadOnly}
                      handleReadOnlyModal={handleReadOnlyModal}
                      settingsSnapshot={settingsSnapshot}
                      setSettingsSnapshot={setSettingsSnapshot}
                      currentUser={user}
                    />
                  )}
              </div>
            </RightColumn>
          )}
      </ColumnContainer>
      {openReadOnlyModal && (
        <ReadOnlyModal
          isOpen={openReadOnlyModal}
          onClick={() => setReadOnlyModal(false)}
          onClose={() => setReadOnlyModal(false)}
        />
      )}

      {currentFactory.id && templateIsOpen && (
        <FactoryTemplateContainer
          factory={{ ...currentFactory, ...summaryFactory }}
          companyId={companyId}
          onClose={closeTemplate}
          open={templateIsOpen}
          isReadOnly={isReadOnly}
          summaryFactories={summaryFactories}
        />
      )}
    </FactoryViewStyled>
  );
}

export default FactoriesScreen;
