import React from "react";
import { getDateByTimezone } from "../../helpers/helpers";
import { CalendarIcon } from "../../helpers/iconSvgPath";

export const TaskDateField = ({
  task,
  dateRef,
  isTaskCompleted,
  setDatePickerData = () => {},
  fieldDate,
  serverTime = false,
}) => {
  return (
    <div
      className="date"
      ref={dateRef}
      onClick={() => {
        if (!isTaskCompleted) {
          setDatePickerData({
            open: true,
            date: fieldDate,
            ref: dateRef,
          });
        }
      }}
    >
      {getDateByTimezone({
        date: task[fieldDate],
        serverTime,
      })}
      {!isTaskCompleted && <CalendarIcon svgClass="calendarIcon" />}
    </div>
  );
};
