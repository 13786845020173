import { getRandomId } from "../../helpers/helpers";
import { now } from "moment";

export default class Item {
  constructor({
    id = getRandomId(),
    creationDate = now(),
    itemNumber = 0,
    image = [],
    description = "",
    salesOrderQuantity = {},
    salesOrderPrice = {},
    cbmMaster = "0.00",
    piecesPerMaster = 0,
    cartons = 0,
    purchaseOrderQuantity = 0,
    shipmentQuantity = {},
    unitCost = 0,
    salesOrderIds = [],
    salesOrders = [],
    shipmentIds = [],
    weight = "0",
    previousValue = {},
    vendorNumber = "",
    vendorName = "",
    purchaseOrderId = "",
    MOQ = 0,
    mismatchStatus = {
      purchaseOrder: false,
      salesOrder: false,
      shipments: true,
    },
  }) {
    this.id = id;
    this.creationDate = creationDate;
    this.itemNumber = itemNumber;
    this.image = image;
    this.description = description;
    this.salesOrderQuantity = salesOrderQuantity;
    this.salesOrderPrice = salesOrderPrice;
    this.cbmMaster = cbmMaster;
    this.piecesPerMaster = piecesPerMaster;
    this.cartons = cartons;
    this.purchaseOrderQuantity = parseInt(purchaseOrderQuantity);
    this.shipmentQuantity = shipmentQuantity;
    this.unitCost = parseFloat(unitCost);
    this.salesOrderIds = salesOrderIds;
    this.salesOrders = salesOrders;
    this.shipmentIds = shipmentIds;
    this.weight = weight;
    this.previousValue = previousValue;
    this.vendorNumber = vendorNumber;
    this.vendorName = vendorName;
    this.purchaseOrderId = purchaseOrderId;
    this.MOQ = MOQ;
    this.mismatchStatus = mismatchStatus;
  }
}
