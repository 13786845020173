import React from "react";
import IntlMessages from "../../util/IntlMessages";
import {
  LockNoteIconModal,
  ReportInfoIcon,
  SendIcon,
  TrashCanIcon,
} from "../../helpers/iconSvgPath";
import TooltipTD from "../Tooltip/TooltipTD";
import { cx } from "@emotion/css";

function FooterNoteSection({
  handleDelete,
  editingNote,
  hasMention,
  lockNote,
  handleModalAtention = () => {},
  isEmpty,
  OS,
  handleClick,
  typeNote,
}) {
  const getLockIcon = () => {
    return (
      <TooltipTD
        label={"Who can see this note"}
        showToolTip={hasMention}
        style={{ width: 32 }}
      >
        {hasMention && (
          <LockNoteIconModal
            onClick={handleModalAtention}
            style={{ cursor: "pointer" }}
            className={cx("LockNoteIcon", {
              unlock: !lockNote,
              locked: lockNote,
            })}
          />
        )}
      </TooltipTD>
    );
  };

  const noteScope = {
    PURCHASE_ORDER_NOTE: "PO",
    SALES_ORDER_NOTE: "Sales Order",
    SHIPMENT_NOTE: "Sales Order",
  };
  return (
    <div className="section-bottom">
      <div className="section-info-container">
        <div className="delete-note-section">
          {editingNote && (
            <>
              <TrashCanIcon
                color={"#FF9494"}
                svgClass="trashIcon"
                onClick={handleDelete}
              />
              <span className="deleteText">DELETE NOTE</span>
            </>
          )}
        </div>
        <div className="info">
          <ReportInfoIcon
            width={20}
            height={20}
            style={{ marginRight: 3, marginTop: -3 }}
            svgClass={"infoIcon"}
          />

          <div className="textContainer">
            <span>- This note is editable for 6 seconds after submitting</span>
            <br />
            <span>
              - Access Groups shown in grey have one or more users that do not
              have permission to see this {noteScope[typeNote]}; likewise for
              individual users
            </span>
          </div>
        </div>
      </div>
      <div className="section-actions-buttons">
        <div className="actionsContainer">
          {getLockIcon()}
          <button
            disabled={isEmpty()}
            onClick={handleClick}
            className={cx("buttonSubmit", { buttonDisabled: isEmpty() })}
            onKeyDown={(ev) => ev.preventDefault()}
            onKeyUp={(ev) => ev.preventDefault()}
          >
            <IntlMessages id="components.purchaseorderdashboard.notessection.send" />
            <SendIcon
              svgClass={"sendIcon"}
              color={"white"}
              style={{ marginLeft: 16 }}
            />
          </button>
        </div>
        <label className="label-description">
          <strong>{`${
            OS.includes("Windows") ? "Ctrl + Enter" : "⌘ + Return"
          }`}</strong>{" "}
          to send
        </label>
      </div>
    </div>
  );
}

export default FooterNoteSection;
