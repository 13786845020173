import moment from "moment";
import React, { useState, useEffect, useMemo } from "react";
import { dbTables } from "../../api/types/dbTables";
import { getDateByTimezone } from "../../helpers/helpers";
import { builderTasksInPhases } from "../../helpers/timelineModal";
import { useCustomers } from "../../hooks/customers";
import { useFactories } from "../../hooks/factories";
import { useCompanyUsers } from "../../hooks/user";
import Loader from "../General/Loader";
import { GanttChartStructureStyled } from "./styles";
import TimelineModalV2 from "./TimelineModal";
import { colorPickerArray } from "../../helpers/timelineCalendar";
import { getTasksSortedByListIndex } from "../../helpers/ganttChart";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../firebase";
import { GanttChartProvider } from "./GanttChartContext";

function TimelineStructure({
  salesOrder,
  purchaseOrders,
  shipments,
  onCancelTimeLineModal,
  onSubmitTimeLineModal,
  setSizeChange,
}) {
  const companyUsers = useCompanyUsers({});
  const factories = useFactories();
  const customers = useCustomers();
  const [loading, setLoading] = useState(true);
  const [tasksDB, setTasksDB] = useState([]);

  const memoizedToRender = useMemo(() => {
    return render({
      salesOrder,
      purchaseOrders,
      factories,
      customers,
      companyUsers,
      loading,
      tasksDB,
      shipments,
    });
  }, [tasksDB]);

  useEffect(() => {
    if (salesOrder && purchaseOrders && purchaseOrders.length > 0) {
      getTasks(salesOrder, purchaseOrders, shipments);
    } else if (salesOrder && purchaseOrders && purchaseOrders.length === 0) {
      getSoTask({ salesOrder, addToState: true });
      setLoading(false);
    }
    return;
  }, []);

  function render({
    loading,
    tasksDB,
    salesOrder,
    companyUsers,
    purchaseOrders,
    factories,
    customers,
    shipments,
  }) {
    const { sortedTasksByNumberAndPhases } = builderTasksInPhases({
      tasks: tasksDB,
    });

    if (!loading && sortedTasksByNumberAndPhases.length > 5) {
      return (
        <GanttChartProvider>
          <TimelineModalV2
            milestonesArray={getMilestones(salesOrder)}
            allTasks={sortedTasksByNumberAndPhases}
            companyUsers={companyUsers}
            salesOrder={{ ...salesOrder, color: "#D46BEE" }}
            purchaseOrders={purchaseOrders.map((po = {}, index) => {
              if (!po.color) {
                const colorIndex = index % colorPickerArray.length;
                return { ...po, color: colorPickerArray[colorIndex].color };
              }
              return po;
            })}
            shipments={shipments}
            factories={factories}
            customers={customers}
            onCancelTimeLineModal={onCancelTimeLineModal}
            onSubmitTimeLineModal={onSubmitTimeLineModal}
            setSizeChange={setSizeChange}
          />
        </GanttChartProvider>
      );
    } else {
      return (
        <Loader
          style={{
            justifyContent: "center",
            display: "flex",
            zIndex: 1000,
            alignItems: "center",
            top: 0,
            left: 0,
          }}
        />
      );
    }
  }

  const getSoTask = async ({ salesOrder, addToState }) => {
    const SOTasksSnap = await getDocs(
      collection(
        firestore,
        `${salesOrder.ref.path}/${dbTables.SALES_ORDER_TASKS}`
      )
    );

    const soTasks = SOTasksSnap.docs.map((doc) => ({
      ...doc.data(),
      ref: doc.ref,
    }));
    if (addToState) {
      setTasksDB(soTasks);
    }
    return soTasks;
  };

  async function getTasks(salesOrder, purchaseOrders, shipments) {
    let tasksDB = [];
    const SOTasks = await getSoTask({ salesOrder });
    tasksDB = [...tasksDB, ...SOTasks];
    const POPromises = [];
    for (let index = 0; index < purchaseOrders.length; index++) {
      const POTasks = getDocs(
        collection(
          firestore,
          `${purchaseOrders[index].ref.path}/${dbTables.PURCHASE_ORDER_TASKS}`
        )
      );
      POPromises.push(POTasks);
    }
    Promise.all(POPromises)
      .then((values) => {
        values.forEach((value) => {
          const POTasks = value.docs.map((doc) => ({
            ...doc.data(),
            ref: doc.ref,
          }));
          tasksDB = [...tasksDB, ...POTasks];
        });
      })
      .then(() => {
        const shipmentPromises = [];
        for (let index = 0; index < shipments.length; index++) {
          const shipmentTasks = getDocs(
            collection(
              firestore,
              `${shipments[index].ref.path}/${dbTables.SHIPMENT_TASKS}`
            )
          );
          shipmentPromises.push(shipmentTasks);
        }

        Promise.all(shipmentPromises).then((values) => {
          values.forEach((value) => {
            const shipmentTasks = value.docs.map((doc) => ({
              ...doc.data(),
              ref: doc.ref,
            }));
            tasksDB = [...tasksDB, ...shipmentTasks];
          });
          tasksDB = tasksDB.map((task) => ({
            ...task,
            startDate: getDateByTimezone({
              date: task.startDate,
              timestamp: true,
            }),
            finishDate: getDateByTimezone({
              date: task.finishDate,
              timestamp: true,
            }),
          }));
          setLoading(false);
          // tasksDB.forEach((task) => delete task.ref);
          // console.log("TASKS DB ::: ", JSON.stringify(tasksDB));

          tasksDB = getTasksSortedByListIndex({
            tasks: tasksDB,
            purchaseOrders: purchaseOrders,
          });
          setTasksDB(tasksDB);
        });
      });
  }

  function getMilestones(salesOrder) {
    if (!salesOrder.milestones) {
      return [
        {
          date: moment().valueOf(),
          color: "#FF0A0A",
          isToday: true,
          name: "Today",
        },
      ];
    }
    return [
      ...salesOrder.milestones,
      {
        date: moment().valueOf(),
        color: "#FF0A0A",
        isToday: true,
        name: "Today",
      },
    ];
  }
  return (
    <GanttChartStructureStyled
      id="timeline-modal-container"
      className={"ganttChartContainer"}
    >
      {memoizedToRender}
    </GanttChartStructureStyled>
  );
}

export default TimelineStructure;
