import * as FullStory from "@fullstory/browser";
import {
  globalEnvironment,
  FSOrgId,
  globalEnvironments,
} from "../constants/globalVariables";
import moment from "moment";
import { setUserId, setUserProperties } from "firebase/analytics";
import { analytics } from "../firebase";

// export function trackEvent(eventName, params = {}) {
//   pendo.track(eventName, params);
//   FS.event(eventName, params);
// }

export function identifyFullStory({ currentUser, company }) {
  FullStory.init({ orgId: FSOrgId });
  FullStory.identify(currentUser.id, {
    fullName: currentUser.firstName + " " + currentUser.lastName,
    email: currentUser.email || "",
    analytics_app_persona: currentUser.typeOfUser || "",
    company_name: company.name || "",
    environment: globalEnvironment,
  });
}

export function getVisitorIdByEmail({ email, environment }) {
  if (!email || !environment) {
    return "";
  }
  const productionEnvironments = [globalEnvironments.EXHART_PRODUCTION];
  if (productionEnvironments.includes(environment)) {
    return email;
  }
  return `exclude_${email}`;
}

export function identifyPendo({ currentUser, company }) {
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  const screenResolution = `${screenWidth}x${screenHeight}`;
  window.pendo.initialize({
    visitor: {
      id: getVisitorIdByEmail({
        email: currentUser.email,
        environment: globalEnvironment,
      }), // Recommended if using Pendo Feedback, or NPS Email
      full_name: `${currentUser.firstName} ${currentUser.lastName}`, // Recommended if using Pendo Feedback
      analytics_app_persona: currentUser.analyticsPersona || "", // Optional
      role: currentUser.role || "",
      environment: globalEnvironment,
      createdDate: currentUser.created || "",
      monitor_resolution: screenResolution,
      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
    },
    account: {
      id: company.name,
      activation_cohort: moment(company.created).format("YYYY-MM"),
    },
  });
  // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
  // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
  // Call this function in your authentication promise handler or callback when your visitor and account id values are available
  // Please use Strings, Numbers, or Bools for value types.
}

export function identifyGoogleAnalytics({ currentUser, company }) {
  setUserId(analytics, currentUser.id);
  setUserProperties(analytics, {
    email: currentUser.email,
    displayName: currentUser.displayName,
    role: currentUser.role,
    company: company.name,
    environment: globalEnvironment,
  });
}
