import { CheckCircle, FiberManualRecord } from "@mui/icons-material";
import React from "react";

function PickerIcon({ milestone, colorPicker, onChangeMilestone }) {
  return milestone.color === colorPicker.color ? (
    <CheckCircle
      key={colorPicker.color}
      style={{ color: colorPicker.color, cursor: "pointer" }}
    />
  ) : (
    <FiberManualRecord
      key={colorPicker.color}
      style={{ color: colorPicker.color, cursor: "pointer" }}
      onClick={() => {
        onChangeMilestone({
          ...milestone,
          color: colorPicker.color,
        });
      }}
    />
  );
}

export default PickerIcon;
