export default class AutoCompleteUser {
  constructor({
    value = "",
    label = "",
    avatar = "",
    firstName = "",
    lastName = "",
  }) {
    this.value = value;
    this.label = label;
    this.avatar = avatar;
    this.firstName = firstName;
    this.lastName = lastName;
  }
}
