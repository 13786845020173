import React, { useState } from "react";
import { Grow, Snackbar } from "@mui/material";
import { cx } from "@emotion/css";
import ViewerSKU from "../ViewerSKU";
import { SimpleSKUTableStyled } from "./styles";
import RowEmptySimple from "../../Tables/RowEmptySimple";
import LoadingBackdrop from "../../WholeScreenFocusBackdrop/LoadingBackdrop";
import { getSettingsTableHeader } from "../../CompanyTabs/settingsHelper";
import { useIsAllowedFunction } from "../../../hooks/permissions";
import { generalPermissions } from "../../../helpers/constants";
import CollapsePORow from "./CollapsePORow";

function SKUTableCollapse({
  prefix,
  actualRows,
  entity,
  onChangeData,
  isLoading,
  modalShowImage,
  imagesTableStorage,
  isModal,
  modalFilterText,
  purchaseOrders,
  tableHeader,
  openSnackBar,
  handleSnackBar,
  handleSorting,
  sorting,
  classTable = "",
  onOpenRow = () => {},
  rowsIsOpen = {},
  onOpenModal,
}) {
  const isAllowed = useIsAllowedFunction();
  const [photosViewer, setPhotosViewer] = useState({
    open: false,
    photos: [],
  });

  const [showImage, setShowImage] = useState(
    !imagesTableStorage[prefix] || !!modalShowImage
  );

  const hasPermission = isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT);
  const idExpandModal = isModal ? "ExpandModal" : "";

  function handlePhotosViewer({ open, photos = [] }) {
    if (photos.length > 0) {
      setPhotosViewer({ photos, open });
    } else {
      setPhotosViewer({ photos: [{ src: "" }, open] });
    }
  }

  return (
    <SimpleSKUTableStyled
      className={cx("tableSKUContainer", classTable, {
        modalSKUContainer: isModal,
        poExpandModal: isModal,
      })}
      id={`itemTable${prefix}${idExpandModal}`}
    >
      {showImage && photosViewer.open && (
        <ViewerSKU
          showImage={showImage}
          handlePhotosViewer={handlePhotosViewer}
          photosViewer={photosViewer.photos}
          openViewer={photosViewer.open}
        />
      )}
      {isLoading && <LoadingBackdrop size={60} withLogo={true} />}
      {openSnackBar && (
        <Snackbar
          className={"snackbar-table-link"}
          open={openSnackBar}
          onClose={() => handleSnackBar(false)}
          TransitionComponent={Grow}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          style={{ position: "absolute !important", top: 100 }}
          autoHideDuration={1500}
          message={
            <span style={{ margin: "auto" }} id="message-id">
              Copied to clipboard
            </span>
          }
        />
      )}
      <div className="headerSKURow">
        {getSettingsTableHeader({
          headers: tableHeader.filter(
            (column) => !column.permission || isAllowed(column.permission)
          ),
          filters: sorting,
          checked: showImage,
          onChangeCheckBox: () => setShowImage(!showImage),
          updateSorting: (sorting) => {
            handleSorting(sorting);
          },
          hadSpecialStyle: !hasPermission,
        })}
      </div>
      <div className="poSKUList" style={{ height: "100%" }}>
        {actualRows.length === 0 && (
          <RowEmptySimple
            searchText={modalFilterText}
            style={{ height: "100%", border: "unset" }}
          />
        )}
        {actualRows.map((row, index, list) => (
          <CollapsePORow
            index={index}
            key={index + row.id}
            itemSKU={row}
            showImage={showImage}
            handlePhotosViewer={handlePhotosViewer}
            entity={entity}
            prefix={prefix}
            purchaseOrders={purchaseOrders}
            onChangeData={onChangeData}
            onOpenRow={onOpenRow}
            open={rowsIsOpen[row.id]}
            onOpenModal={onOpenModal}
            lastItem={list.length - 1 === index}
          />
        ))}
        <div
          className="backgroundRow"
          style={{ height: actualRows.length === 0 ? 0 : "" }}
        >
          <div className="dataCell" />
          <div className="SOCell" />
          <div className="ShipmentCell" />
        </div>
      </div>
    </SimpleSKUTableStyled>
  );
}

export default SKUTableCollapse;
