import React, { useState } from "react";
import ReportPopover from "./ReportPopover";
import { useFavorites, useFavoritesList } from "../../hooks";
import { dbTables } from "../../api/types/dbTables";
import { useUser } from "../../hooks/user";
import { addFavorite, removeFavorite } from "../../helpers/favoritesHelper";
import TooltipTD from "../../components/Tooltip/TooltipTD";
import { Modal, Snackbar, Grow, Portal } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { REPORTS_TYPE } from "../../helpers/constants";
import { cx } from "@emotion/css";
import {
  PlayVideoIcon,
  ReportLinkIcon,
  StarIcon,
  YellowFolderIcon,
} from "../../helpers/iconSvgPath";
import copyLink from "../../assets/flag-icons/copy-link-icon.png";
import { colors } from "../../assets/jss/variables";

const ReportTreeList = ({
  handleOpenReport,
  report,
  isSelected,
  handleOpenFolder,
  levelMargin = 0,
  isOpen,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const favoritesDB = useFavorites({ dbTable: dbTables.REPORT_FAVORITES });
  const user = useUser();
  const favorites = useFavoritesList({ dbTable: dbTables.REPORT_FAVORITES });

  const getDisclouserArrow = () => {
    if (report.type !== "folder") {
      return <div style={{ width: 18, minWidth: 18, height: "100%" }} />;
    }
    return (
      <div
        onClick={(ev) => {
          handleOpenFolder(report.id);
          ev.stopPropagation();
        }}
        className="disclouserArrowStyle"
      >
        <KeyboardArrowDownIcon
          className="arrow"
          style={{
            transform: !isOpen ? "rotate(-90deg)" : "none",
            width: 24,
            height: 24,
          }}
        />
      </div>
    );
  };

  const getFavoriteStar = () => {
    if (report.type !== REPORTS_TYPE.FOLDER) {
      const activeStar = favorites.includes(report.id);
      return (
        <StarIcon
          height={14}
          width={14}
          color={activeStar ? "#F3CA3E" : "white"}
          style={{ stroke: activeStar ? "#F3CA3E" : "gray" }}
          svgClassname={cx("starIcon", { activeIcon: activeStar })}
          onClick={() => {
            if (activeStar) {
              removeFavorite({
                favoritesDB,
                id: report.id,
              });
            } else {
              addFavorite({
                dbTable: dbTables.REPORT_FAVORITES,
                id: report.id,
                user,
                favoritesDB,
              });
            }
          }}
        />
      );
    } else {
      return (
        <div
          style={{
            minWidth: 20,
            width: 20,
          }}
        />
      );
    }
  };

  function getReportLink({ reportId }) {
    const url = window.location.href;
    const urlSplit = url.split("/");
    if (urlSplit.length === 6) {
      urlSplit.pop();
    }
    return `${urlSplit.join("/")}/${reportId}`;
  }

  return (
    <div
      className={cx("reportListText", { blueBar: isSelected })}
      key={report.id}
      onClick={(ev) => {
        if (report.type === REPORTS_TYPE.FOLDER) {
          handleOpenFolder(report.id);
          ev.stopPropagation();
        }
      }}
    >
      <div
        style={{ width: levelMargin, minWidth: levelMargin, height: "100%" }}
      />
      {getDisclouserArrow()}
      <div
        className={cx("favorite-container", {
          folderIcon: report.type === REPORTS_TYPE.FOLDER,
        })}
      >
        {getFavoriteStar()}
      </div>
      <span className="report-icon">
        {report.type === REPORTS_TYPE.FOLDER ? <YellowFolderIcon /> : null}
      </span>
      <div
        className="report-name"
        style={{ cursor: report.type === REPORTS_TYPE.FOLDER ? "unset" : "" }}
        onClick={() => {
          if (report.type !== REPORTS_TYPE.FOLDER) {
            handleOpenReport(report);
          }
        }}
      >
        {report.name}
      </div>
      <div className="icons-container">
        {report.type !== REPORTS_TYPE.FOLDER && (
          <TooltipTD
            label="Copy link to this report"
            tooltipClick={(ev) => {
              navigator.clipboard.writeText(
                getReportLink({ reportId: report.id })
              );
              setOpenSnackBar(true);
              ev.stopPropagation();
            }}
            style={{ display: "flex" }}
            className="icon"
          >
            <ReportLinkIcon height={18} width={18} color={colors.primaryDark} />
          </TooltipTD>
        )}
        <ReportPopover report={report} />
        <TooltipTD
          label="Explanation video"
          showToolTip={!!report.loomEmbed}
          className="icon"
          tooltipClick={(ev) => {
            if (report.loomEmbed) {
              setOpenModal(report.id);
            }
            ev.stopPropagation();
          }}
        >
          {report.loomEmbed && (
            <PlayVideoIcon color={colors.primaryDark} width={18} height={18} />
          )}
        </TooltipTD>

        {openModal && openModal === report.id && (
          <Modal
            open={openModal}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "auto",
            }}
            onClose={() => setOpenModal(false)}
          >
            <iframe
              title={report.name}
              width="940"
              height="529"
              src={report.loomEmbed}
              frameborder="0"
              webkitallowfullscreen={true}
              mozallowfullscreen={true}
              allowFullScreen={true}
            />
          </Modal>
        )}
        {openSnackBar && (
          <Portal>
            <Snackbar
              className="snackbar-copy-link"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              open={openSnackBar}
              onClose={() => setOpenSnackBar(false)}
              TransitionComponent={Grow}
              autoHideDuration={1500}
              message={
                <div>
                  <img src={copyLink} alt="copy link" />
                  <span id="message-id">Link Copied</span>
                </div>
              }
            />
          </Portal>
        )}
      </div>
    </div>
  );
};

export default ReportTreeList;
