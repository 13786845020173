import React from "react";
import { PurchaseOrderBadgeStyled } from "./styles";
import { cx } from "@emotion/css";

function POBadge({
  number,
  id = "",
  className = "purchase-order-badge",
  isDisable,
  style = {},
}) {
  return (
    <PurchaseOrderBadgeStyled
      className={cx(className, { isDisable })}
      id={id}
      style={style}
    >
      <span className="numberText">{number}</span>
    </PurchaseOrderBadgeStyled>
  );
}

export default POBadge;
