import React from "react";
import TooltipTD from "../Tooltip/TooltipTD";
import { cx } from "@emotion/css";
import { styled } from "@mui/material";
import { colors } from "../../assets/jss/variables";
import { CloseIcon } from "../../helpers/iconSvgPath";

const StyledTooltip = styled(TooltipTD)(() => ({
  "&.TooltipBase": {
    cursor: "pointer",
    padding: "4px 5px",
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      "& path": {
        strokeWidth: 2.5,
      },
      background: colors.backgroundWhite,
    },
    "&:active": {
      background: colors.backgroundWhiteAction,
      "& path": {
        strokeWidth: 3,
      },
    },
  },
  "&.closeThreadIcon": {
    width: 20,
    height: 20,
    color: colors.primaryDark,
    cursor: "pointer",
    position: "absolute",
    top: 3,
    right: 3,
  },
}));

function CloseButton({ onClick, label, className, size }) {
  return (
    <StyledTooltip
      tooltipClick={onClick}
      label={label}
      showToolTip={!!label}
      className={cx("TooltipBase", className)}
      style={{ width: size, height: size }}
    >
      <CloseIcon style={{ minWidth: size, minHeight: size }} />
    </StyledTooltip>
  );
}

export default CloseButton;
