import React from "react";
import userTypes from "../../api/types/userTypes";

function RolePermissionMananger({
  category,
  list,
  permissions = [],
  isDisabled,
  role,
  onChangePermission,
}) {
  return (
    <React.Fragment>
      {category.categories.map((subCategory) => {
        const currentList = list.filter(
          (itemList) => itemList.subCategory === subCategory
        );
        return (
          <div className="user-manager-permissions" style={{ marginLeft: 20 }} key={subCategory}>
            <span className="subTitle">{subCategory}</span>
            <ul className="permissions-container">
              {currentList.map((permission, index) => {
                const currentPermission =
                  permissions.find((item) => item.id === permission.value) ||
                  {};
                return (
                  <li
                    key={permission.value + index}
                    style={{ padding: 3, width: "100%" }}
                  >
                    <label
                      className="switch"
                      style={{ margin: 0, minWidth: 77 }}
                    >
                      <input
                        disabled={isDisabled}
                        onChange={(ev) => {
                          onChangePermission({
                            value: ev.target.value,
                            permission,
                          });
                        }}
                        checked={
                          role && role.name === userTypes.SUPER_ADMIN
                            ? true
                            : !!currentPermission.enabled
                        }
                        value={permission.value}
                        type="checkbox"
                      />
                      <span
                        data-positive="YES"
                        data-negative="NO"
                        className="slider round"
                      />
                    </label>
                    <div
                      className="permission-label-container"
                      style={{ marginLeft: 8 }}
                    >
                      <span className="permission-label">
                        {permission.label}
                      </span>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </React.Fragment>
  );
}

export default RolePermissionMananger;
