import moment from "moment";
import { getRandomId } from "../../helpers/helpers";

export default class Milestone {
  constructor({
    id = getRandomId(),
    name = "default name",
    date = moment().valueOf(),
    color = "#000",
  }) {
    this.id = id;
    this.name = name;
    this.date = date;
    this.color = color;
  }
}
