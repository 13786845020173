import { getRandomId } from "../../helpers/helpers";
import { now } from "moment";

export default class SalesOrderTemplateObj {
  constructor({
    id = getRandomId(),
    version = 1,
    user = "",
    description = "",
  }) {
    this.id = id;
    this.version = version;
    this.creationDate = now();
    this.user = user;
    this.description = description;
  }
}
