import { styled } from "@mui/material";
import React, { useState } from "react";
import Viewer from "react-viewer";
import { colors } from "../../assets/jss/variables";

const keyToolbar = [
  "zoomIn",
  "zoomOut",
  "reset",
  "prev",
  "next",
  "flipHorizontal",
];

const singleKeyToolbar = ["zoomIn", "zoomOut", "reset", "flipHorizontal"];

const ViewerStyled = styled(Viewer)(() => ({
  "&.viewer": {
    "& .react-viewer-attribute": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: "1%",
      "& .image-text": {
        background: "white",
        marginBottom: 15,
        padding: 4,
        color: colors.primaryDark,
        borderRadius: 3,
      },
      "& .react-viewer-showTotal": {
        background: "white",
        visibility: "hidden",
        display: "none",
      },
    },
    "& .react-viewer-close": {
      background: "white",
      width: 60,
      height: 60,
      "& i": {
        top: 15,
        left: 30,
        transform: "scale(1.1, 1.1)",
        color: colors.primaryDark,
      },
    },
  },
}));

function ViewerSKU({
  showImage,
  handlePhotosViewer,
  photosViewer,
  openViewer,
}) {
  const [imageIndex, setImageIndex] = useState(0);

  return (
    <React.Fragment>
      {showImage && (
        <ViewerStyled
          downloadable={true}
          onChange={(props, index) => {
            setImageIndex(index);
          }}
          visible={openViewer}
          onClose={() => {
            handlePhotosViewer(false, []);
            setImageIndex(0);
          }}
          onMaskClick={() => {
            handlePhotosViewer(false, []);
            setImageIndex(0);
          }}
          images={photosViewer}
          className="viewer"
          zoomSpeed={0.3}
          minScale={0.2}
          zIndex={2000}
          customToolbar={(toolbar) => {
            const currentToolbar =
              photosViewer.length < 2 ? singleKeyToolbar : keyToolbar;
            const filterToolbar = toolbar.filter((tool) =>
              currentToolbar.includes(tool.key)
            );
            return filterToolbar;
          }}
          noImgDetails={
            <React.Fragment>
              <span className="image-text">
                {photosViewer[imageIndex].number}
              </span>
              <span className="image-text">
                {photosViewer[imageIndex].description}
              </span>
            </React.Fragment>
          }
        />
      )}
    </React.Fragment>
  );
}

export default ViewerSKU;
