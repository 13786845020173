import React, { useState, useRef, useEffect } from "react";

import { colors } from "../../assets/jss/variables";
import { Edit as EditIcon } from "@mui/icons-material";
import { ClickAwayListener } from "@mui/material";

function EditableLabel({
  inputStyle = {},
  defaultChildren = "--------------",
  label,
  onChange,
  children,
  onValid = () => {},
  autofocus = false,
  editable = false,
  onFocus,
  style,
  labelStyle = {},
  handleEnableContextMenu = () => {},
  isTemplateValue = false,
  onclose = () => {},
  disabled = false,
  isReadOnly = false,
  handleReadOnlyModal = () => {},
  handleOpenFolder = () => {},
  showEditIcon,
  hiddenText = false,
  currentDocument,
  onStartEditing = () => {},
  disabledClick = false,
  disabeldBlur,
  ...rest
}) {
  const [editing, setEditing] = useState(editable);
  const inputRef = useRef(null);
  function toggleEditing(ev) {
    if (
      currentDocument &&
      currentDocument.placeholder &&
      currentDocument.type === "file"
    ) {
      handleEnableContextMenu();
      ev.stopPropagation();
    }

    if (disabled) {
      handleOpenFolder(ev);
      ev.stopPropagation();
      return;
    }
    if (isReadOnly && !editing) {
      handleReadOnlyModal();
      ev.stopPropagation();
      return;
    }
    if (!editing) {
      onStartEditing();
      setTimeout(
        () => inputRef && inputRef.current && inputRef.current.select(),
        50
      );
      ev.stopPropagation();
    }
    setEditing(!editing);
  }
  useEffect(() => {
    setEditing(rest.editing);
    if (rest.editing) {
      setTimeout(
        () => inputRef && inputRef.current && inputRef.current.select(),
        50
      );
    }
  }, [rest.editing]);
  const labelValue = label || children;
  const labelValueShow = isTemplateValue
    ? labelValue
    : labelValue || defaultChildren;
  return (
    <div
      style={style}
      onContextMenu={(ev) => {
        handleEnableContextMenu();
        if (editing) toggleEditing(ev);
        ev.stopPropagation();
        ev.preventDefault();
      }}
      className={!children ? "editable-label see-able" : "editable-label"}
    >
      {!editing && (
        <span
          style={{ padding: 0, ...style, ...labelStyle }}
          onClick={(ev) => {
            if (disabledClick) {
              return;
            }
            const disabled = rest.disabled;
            if (disabled) handleEnableContextMenu();
            else if (!editing) toggleEditing(ev);
            ev.stopPropagation();
          }}
        >
          {hiddenText ? "" : labelValueShow}
          {showEditIcon && (
            <EditIcon
              className={"edit-icon"}
              style={{
                marginLeft: 4,
                paddingTop: 1,
                fontSize: 14,
                color: `${colors.primaryDark}`,
              }}
              onClick={(ev) => {
                if (!disabled) {
                  setEditing(true);
                }
                ev.stopPropagation();
              }}
            />
          )}
        </span>
      )}
      {editing && (
        <ClickAwayListener
          onClickAway={(ev) => {
            toggleEditing(ev);
            onclose();
            ev.stopPropagation();
          }}
        >
          <input
            {...rest}
            disabled={disabled}
            className="input-editable-label"
            onClick={(ev) => ev.stopPropagation()}
            onBlur={(ev) => {
              if (disabeldBlur) {
                return;
              }
              onValid(ev);
              toggleEditing(ev);
            }}
            editing={(rest.editing || "").toString()}
            style={{ ...style, ...inputStyle }}
            ref={inputRef}
            onKeyUp={(keyEvent) => {
              if (keyEvent.key === "Enter") {
                toggleEditing();
                onValid(keyEvent);
              } else if (keyEvent.key === "Escape") {
                toggleEditing();
                onclose();
              }
            }}
            value={children}
            type={rest.type || "text"}
            onChange={onChange}
            autoFocus={autofocus}
            onFocus={onFocus}
          />
        </ClickAwayListener>
      )}
    </div>
  );
}

export default EditableLabel;
