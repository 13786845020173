import React, { useState, useEffect, useCallback } from "react";
import IntlMessages from "../../util/IntlMessages";

import Avatar from "./AdminAvatar";
import { useDispatch } from "react-redux";
import { dbTables } from "../../api/types/dbTables";
import { addDataToFirestore } from "../../actions/DataActions";
import CustomSnackbar from "../../routes/components/snackbar/component/CustomSnackbar";
import userTypes from "../../api/types/userTypes";
import { useUser } from "../../hooks/user";
import { firestore } from "../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { useCompanies } from "../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { colors } from "../../assets/jss/variables";

function AdminHeader() {
  const companies = useCompanies();
  const navigate = useNavigate();
  const { companyId } = useParams();
  const currentUser = useUser();
  const [currentCompany, setCurrentCompany] = useState({});
  const [companyName, setCompanyName] = useState("");
  const [companyDomain, setCompanyDomain] = useState("");
  const [errorMessage, setErrorMessage] = useState({
    name: "",
    domain: "",
  });
  const [edited, setEdited] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");
  const isCompanyUser = currentUser.role !== userTypes.TRADEDASH_EMPLOYEE;
  //Redux Actions
  const dispatch = useDispatch();
  const createCompany = useCallback((company) =>
    addDataToFirestore(dbTables.COMPANIES, company)(dispatch)
  );

  useEffect(() => {
    let company = companies.find((company) => company.id === companyId);
    if (company) {
      setCurrentCompany(company);
      setCompanyName(company.name);
      setCompanyDomain(company.domain || "");
      setEdited(false);
    } else {
      setCurrentCompany({});
    }
  }, [companyId, companies]);

  //CRUD Methods
  function saveCompany() {
    const parseDomain = currentCompany.domain || "";
    setEdited(false);
    const company = { ...currentCompany, domain: parseDomain.toLowerCase() };
    delete company.new;
    Object.keys(company).forEach(
      (key) =>
        (company[key] = company[key].replace
          ? company[key].replace(/\s\s+/g, " ").trim()
          : company[key])
    );

    createCompany(company);
    setOpenSnackbar(true);
    setSnackbarMessage("admincontent.successfullcompanynameupdated");
    setSnackbarType("success");
  }

  function existCompany(name) {
    const company = companies.filter(
      (company) => company.name.toLowerCase() === name
    );
    if (company.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  function onChange(event, key) {
    setEdited(true);
    const value = event.target.value;
    if (key === "name") {
      setCompanyName(value);
      const companyNameValid = value.replace(/\s\s+/g, " ").trim();
      let nameError = "";
      if (companyNameValid.match(/[<>!@#$%^&*]+/i)) {
        nameError = "errormessage.specialcharacters";
      } else if (!companyNameValid) {
        nameError = "errormessage.nocompanyname";
      } else if (companyNameValid.length > 55) {
        nameError = "errormessage.companynametoolong";
      } else if (existCompany(companyNameValid)) {
        nameError = "errormessage.companyexists";
      }
      if (nameError) {
        setErrorMessage({ ...errorMessage, name: nameError });
      } else {
        setCurrentCompany({ ...currentCompany, [key]: companyNameValid });
        return setErrorMessage({ ...errorMessage, [key]: "" });
      }
    } else {
      setCompanyDomain(value);
      const companyDomainValid = value.replace(/\s\s+/g, " ").trim();
      let domainError = "";
      if (companyDomainValid.match(/[<>!@#$%^&*]+/i)) {
        domainError = "errormessage.specialcharacters";
      } else if (!companyDomainValid) {
        domainError = "errormessage.nocompanydomain";
      } else if (companyDomainValid.length > 30) {
        domainError = "errormessage.companydomaintoolong";
      } else if (!companyDomainValid.match(/\w+\.\w{2,10}/i)) {
        domainError = "errormessage.invaliddomain";
      }
      if (domainError) {
        setErrorMessage({ ...errorMessage, domain: domainError });
      } else {
        setCurrentCompany({ ...currentCompany, [key]: companyDomainValid });
        return setErrorMessage({ ...errorMessage, [key]: "" });
      }
    }
  }

  function cancelCompany() {
    setEdited(false);
    if (currentCompany.new) {
      navigate("/app/admin/" + companies[companies.length - 1].id);
      return;
    }
    let company = companies.find((company) => company.id === companyId);
    setCompanyName(company.name);
    setCompanyDomain(company.domain || "");
    setCurrentCompany(company);
    setErrorMessage({});
  }

  function handleUpload(url) {
    setCurrentCompany({ ...currentCompany, url_logo: url });
    setEdited(true);
  }
  function disableCompany() {
    updateDoc(doc(firestore, `${dbTables.COMPANIES}/${companyId}`), {
      enabled: !currentCompany.enabled,
    });
  }
  //TODO
  // function observeCompany(ev) {
  //   if (currentUser.observedCompany === currentCompany.id) {
  //     updateDoc(doc(firestore, `${dbTables.USERS}/${currentUser.id}`), {
  //       observedCompany: "",
  //     });
  //     return;
  //   }
  //   updateDoc(doc(firestore, `${dbTables.USERS}/${currentUser.id}`), {
  //     observedCompany: currentCompany.id,
  //     companyId: currentCompany.id,
  //   });

  //   localStorage.setItem("poListFilters", "");
  //   localStorage.setItem("weekFilters", "");
  //   localStorage.setItem("soListFilters", "");
  //   localStorage.setItem("shipmentListFilters", "");
  //   localStorage.setItem("taskScreenFilters", "");
  //   localStorage.setItem("logisticsFilter", "");
  //   localStorage.setItem("PuchaseOrderPhaseScreenFilters", "");
  // }

  function handleCloseSnackbar() {
    setOpenSnackbar(false);
  }

  return (
    <React.Fragment>
      {openSnackbar && (
        <CustomSnackbar
          open={openSnackbar}
          message={snackbarMessage}
          handleCloseSnackbar={handleCloseSnackbar}
          variant={snackbarType}
        />
      )}

      <Avatar
        disabled={isCompanyUser}
        onUpload={handleUpload}
        id={currentCompany.id}
        onChange={onChange}
        avatarUrl={currentCompany.url_logo}
        name={companyName}
        domain={companyDomain}
        errorMessage={errorMessage}
        onChangeCompany={disableCompany}
        isCompanyActive={currentCompany.enabled}
      />

      {edited && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button onClick={cancelCompany}>
            {<IntlMessages id="generic.text.cancel" />}{" "}
          </button>
          <button
            disabled={errorMessage.name || errorMessage.domain ? true : false}
            onClick={saveCompany}
            style={{ background: colors.primaryDark }}
          >
            {<IntlMessages id="generic.text.save" />}
          </button>
        </div>
      )}
    </React.Fragment>
  );
}

export default AdminHeader;
