import { styled } from "@mui/material";
import { colors } from "../../../assets/jss/variables";
import { itemTableRowHeight } from "../../../helpers/orderDashboard";
import TooltipTD from "../../Tooltip/TooltipTD";

const gridSOSKUTable =
  "130px 100px 1fr 140px 120px 160px 110px 110px 115px 115px 20px";

const gridShipmentSKUTable =
  "130px 100px 160px 1fr 95px 100px 160px 110px 115px 115px 115px 115px 20px";

const gridPurchaseOrderSKUTable =
  "35px 7% 6.5% calc(20.5% - 35px) 5.5% 5.5% 7% 6% 9.5% 9.5% 6% 117px calc(17% - 117px)";

const gridModalPurchaseOrderSKUTable =
  "35px 7% 6.5% calc(16.5% - 35px) 5.5% 5.5% 7% 6% 9.5% 9.5% 6% 117px calc(21% - 117px)";

export const SimpleSKUTableStyled = styled("div")(() => ({
  "&.tableSKUContainer": {
    alignContent: "start",
    color: colors.primaryDark,
    height: "calc(100vh - 500px)",
    width: "100%",
    fontSize: 14,
    minWidth: 1570,
    "& .poSKUList": {
      border: `2px solid ${colors.dividerColor}`,
      borderTop: "unset",
      background: "white",
      overflowY: "scroll !important",
      maxHeight: "calc(100vh - 575px)",
      display: "flex",
      flexDirection: "column",
      "& .backgroundRow": {
        gridTemplateColumns: gridPurchaseOrderSKUTable,
        position: "relative",
        top: 0,
        height: "100%",
        zIndex: 0,
        width: "100%",
        display: "grid",
        "& .dataCell": {
          gridColumn: "span 7",
        },
        "& .SOCell": {
          gridColumn: "span 3",
          background: colors.salesOrderBackgroundColor,
          borderInline: `2px solid ${colors.salesOrderBorderColor}`,
        },
        "& .ShipmentCell": {
          gridColumn: "span 3",

          background: colors.shipmentBadge,
          borderInline: `2px solid ${colors.shipmentBorderColor}`,
        },
      },
      "&::-webkit-scrollbar": {
        backgroundColor: "white",
        width: 18,
      },
      "&::-webkit-scrollbar-thumb": {
        background: "transparent",
        borderRadius: 8,
      },
      "&::-webkit-scrollbar-track": {
        border: " solid 1px transparent",
      },
      "&:hover": {
        "&::-webkit-scrollbar-thumb": {
          boxShadow: `inset 0 0 10px 10px ${colors.thumbScrollColor}`,
          borderBlock: "1px solid transparent",
          borderInline: "5px solid transparent",
        },
      },
    },
    "& .list": {
      border: `2px solid ${colors.dividerColor}`,
      borderTop: "unset",
      background: "white",
      overflowY: "scroll !important",
      "&::-webkit-scrollbar": {
        backgroundColor: "white",
        width: 18,
      },
      "&::-webkit-scrollbar-thumb": {
        background: "transparent",
        borderRadius: 8,
      },
      "&::-webkit-scrollbar-track": {
        border: " solid 1px transparent",
      },
      "&:hover": {
        "&::-webkit-scrollbar-thumb": {
          boxShadow: `inset 0 0 10px 10px ${colors.thumbScrollColor}`,
          borderBlock: "1px solid transparent",
          borderInline: "5px solid transparent",
        },
      },
    },
    "& .headerSKURow": {
      display: "grid",
      gridTemplateColumns: gridSOSKUTable,
      position: "relative",
      height: 51,
      background: colors.tableHeaderColor,
      fontWeight: 700,
      color: colors.primaryDark,
      alignItems: "center",
      border: `2px solid ${colors.dividerColor}`,
      overflowY: "scroll",
      overflowX: "hidden",
      "&::-webkit-scrollbar": {
        backgroundColor: "white",
        width: 18,
        background: colors.tableHeaderColor,
      },
      "&::-webkit-scrollbar-thumb": {
        background: "transparent",
        borderRadius: 8,
      },
      "&::-webkit-scrollbar-track": {
        border: " solid 1px transparent",
      },
    },
    "& .emptySKURow": {
      fontSize: 16,
      lineHeight: "16px",
      letterSpacing: "0.295em",
      textTransform: "uppercase",
      height: "100%",
      backgroundColor: "white",
      color: colors.primaryDark,
      borderColor: colors.dividerColor,
      padding: 15,
      display: "flex",
      justifyContent: "center",
      border: `2px solid ${colors.dividerColor}`,
      borderTop: "unset",
      alignItems: "center",
    },
    "& .itemSKURow": {
      display: "grid",
      gridTemplateColumns: gridSOSKUTable,
      position: "relative",
      gridColumn: "11 span",
      borderBottom: `1px solid ${colors.dividerColor}`,
      alignItems: "center",
      height: itemTableRowHeight.NORMAL,
      transition: "all 0.6s ease",
      "& .soRowContainer": {
        display: "contents",
        "&:hover": {
          "& .salesOrderCell": {
            background: "rgb(235,243,212, 0.55)",
          },
        },
      },
      "& .shipmentRowContainer": {
        display: "contents",
        "&:hover": {
          "& .shipmentCell": {
            background: "rgb(246,230,249, 0.55)",
          },
        },
      },

      "&:hover": {
        background: colors.yellowOnHover,
      },
    },
    "& .withImage": {
      height: itemTableRowHeight.WITH_IMAGE,
      "& .cellRow": {
        height: itemTableRowHeight.WITH_IMAGE,
      },
    },
    "& .cellBorderRight": {
      borderRight: `2px solid ${colors.salesOrderBorderColor}`,
    },
    "& .cellBorderLeft": {
      borderLeft: `2px solid ${colors.salesOrderBorderColor}`,
    },

    "& .cellRow": {
      height: itemTableRowHeight.NORMAL,
      display: "flex",
      alignItems: "center",
      paddingInline: 11,
      transition: "all 0.6s ease",
    },
    "& .borderBotom": {},
    "& .salesOrderCell": {
      background: colors.salesOrderBackgroundColor,
      height: "100%",
      display: "flex",
      alignItems: "center",
      paddingInline: 11,
      transition: "all 0.6s ease",
      borderColor: colors.salesOrderBorderColor,
    },
    "& .cellBorderBottom": {
      borderBottom: `1px solid ${colors.dividerColor}`,
    },
    "& .shipmentCell": {
      background: colors.shipmentBadge,
      height: "100%",
      display: "flex",
      alignItems: "center",
      paddingInline: 11,
      transition: "all 0.6s ease",
      borderColor: colors.shipmentBorderColor,
      borderBottom: `1px solid ${colors.dividerColor}`,
      "& .newShipmentBadge": {
        background: "white",
        fontWeight: 500,
        border: "1px solid #25486D",
        borderRadius: 5,
        display: "flex",
        alignItems: "center",
        height: 26,
        fontSize: 12,
        paddingInline: 5,
        gap: 3,
        "& .shipmentIcon": {
          marginBottom: 2,
          fill: colors.primaryDark,
        },
        "&:hover": {
          fontWeight: 500,
          cursor: "pointer",
          border: "2px solid #25486D",
        },
      },
    },
    "& .navLinkClass": {
      textDecoration: "underline",
      color: colors.primaryDark,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& .totalNumber": {
      background: "white",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      color: colors.darkGray,
      padding: "0px 0px 5px 225px",
      gap: 5,
      justifyContent: "center",
      "& .totalText": {
        fontSize: 14,
        fontWeight: 400,
      },
    },
    "& .blockRedirect": {
      textDecoration: "none",
      cursor: "unset",
    },
    "& .alignRight": {
      justifyContent: "flex-end",
    },
    "& .extendedCost": {
      paddingRight: 24,
    },
    "& .shipmentPrice": {
      paddingRight: 8,
    },
    "& .unitCost": {
      paddingRight: 28,
    },
    "& .cbmMaster": {
      paddingRight: 30,
    },
    "& .totalCBM": {
      paddingRight: 27,
    },
    "& .cartons": {
      paddingRight: 28,
    },
    "& .piecesPerMaster": {
      paddingRight: 27,
    },

    "& .centerAlign": {
      justifyContent: "center",
    },
    "& .dangerStyle": {
      color: "red",
    },
    "& .image": {
      width: 50,
      maxHeight: 50,
      cursor: "pointer",
    },
    "& .arrowContainer": {
      padding: 0,
      justifyContent: "center",
      transition: "transform 0.3s ease-in-out",
      "& .arrowHover": {
        borderRadius: 5,
        "&:hover": {
          background: "#E5E5E5",
        },
      },
      "& .arrow": {
        fontSize: "1.5rem",
        cursor: "pointer",
        transition: "transform 0.3s ease-in-out",
      },
    },
    "& .customized-tooltip": {
      display: "flex",
      overflow: "hidden",
      paddingInline: 11,
      transition: "all 0.6s ease",
      "& .tooltTipSpan": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        transition: "all 0.6s ease",
      },
    },
    "& .hiddenPrice": {
      gridColumn: "span 3",
    },
    "& .hiddenClass": {
      opacity: 0,
    },
    "& .expandIconContainer": {
      display: "flex",
      alignItems: "center",
      position: "absolute",
      width: 130,
      transition: "all 0.6s ease",
      cursor: "pointer",
      gap: 5,
      justifyContent: "flex-start",
      fontWeight: 700,
      "&:hover": {
        "& .expandRow": {
          "& path": {
            strokeWidth: 2.5,
          },
        },
      },
      "&:active": {
        "& .expandRow": {
          "& .item1": {
            transform: "translateY(1px)",
          },
          "& .item3": {
            transform: "translateY(-1px)",
          },
          "& path": {
            strokeWidth: 3,
          },
        },
      },
    },
    "& .editableLabelSKU": {
      transition: "all 0.6s ease",
    },
    "& .lastItem": { borderBottom: "none" },
  },

  "& .headerTable": {
    gap: 5,
    display: "flex",
    alignItems: "center",
    paddingInline: "11px 5px",

    "& .arrow": {
      opacity: 0,
    },
    "&:hover": {
      "& .arrow": {
        opacity: 1,
      },
    },
  },
  "&.tableShipmentSKUContainer": {
    width: "100%",
    height: "calc(100vh - 561px)",
    minWidth: 1528,
    "& .headerSKURow": {
      gridTemplateColumns: gridShipmentSKUTable,
    },
    "& .itemSKURow": {
      gridTemplateColumns: gridShipmentSKUTable,
    },
  },
  "&.tablePOSKUContainer": {
    width: "100%",
    height: "100%",
    position: "relative",
    minWidth: 1550,
    display: "flex",
    flexDirection: "column",
    maxHeight: "calc(100vh - 539px)",
    "& .headerSKURow": {
      gridTemplateColumns: gridPurchaseOrderSKUTable,
      gridTemplateRows: "25px 25px",
      minHeight: 55,
      overflowY: "scroll",
      position: "relative",
      zIndex: 1,
      "&::-webkit-scrollbar": {
        backgroundColor: colors.shipmentBadge,
        width: 18,
        boxShadow: `inset -2px 0px 0px 0px ${colors.shipmentBorderColor}`,
      },
      "& .fullTitle": {
        gridRow: "2 span",
      },
      "& .skuTitle": {
        gridColumn: "span 3",
        alignItems: "center",
        justifyContent: "center",
      },
      "& .salesOrderCell": {
        background: colors.salesOrderBackgroundColor,
      },
    },
    "& .itemSKURow": {
      gridTemplateColumns: gridPurchaseOrderSKUTable,
      background: "white",
      position: "relative",
      zIndex: 1,
    },
  },
  "&.modalSKUContainer": {
    height: "calc(100vh - 235px)",
    width: "calc(100vw - 160px)",
    minWidth: 1253,
    "& .poSKUList": {
      maxHeight: "calc(100vh - 254px)",
      height: "auto",
    },
    "&.poExpandModal": {
      "& .backgroundRow": {
        gridTemplateColumns: gridModalPurchaseOrderSKUTable,
      },
    },
  },
  "&.poExpandModal": {
    "& .headerSKURow": {
      gridTemplateColumns: gridModalPurchaseOrderSKUTable,
    },
    "& .itemSKURow": {
      gridTemplateColumns: gridModalPurchaseOrderSKUTable,
      background: "white",
    },
  },

}));

export const EditableSKULabel = styled(TooltipTD)(() => ({
  "&.editableLabelSKU": {
    display: "flex",
    width: "fit-content",
    alignItems: "center",
    justifyContent: "flex-end",
    "& .edit-icon": {
      opacity: 0,
    },
    "&:hover": {
      "& .edit-icon": {
        opacity: 1,
      },
    },

    "& .labelText": {
      width: "100%",
      display: "flex",
      alignItems: "center",
      gap: 5,
      justifyContent: "flex-end",
      "& .valueText": {
        textOverflow: "ellipsis",
        overflow: "hidden",
        fontWeight: 700,
        fontSize: 14,
      },
      "& .disabledText": {
        fontWeight: 400,
      },
      "& .previewColor": {
        color: colors.mediumGray,
      },
    },
  },
}));
