import React, { useState } from "react";
import AutocompleteSimpleSelect from "../../AutoCompleteSelect/AutoCompleteSimpleSelect";

let MAX_CHARACTER = 35;

function PaidToComponent({
  options,
  onChange,
  value,
  handleAddNewElement,
  onRemove,
  expense,
}) {
  const [textFieldValue, setTextFieldValue] = useState("");
  const [isListOpen, setIsListOpen] = useState(false);
  const valueList = options.find((option) => option === value);
  return (
    <AutocompleteSimpleSelect
      options={options}
      handleAddNewElement={handleAddNewElement}
      handleClose={() => setIsListOpen(false)}
      handleRemove={onRemove}
      value={valueList || value}
      maxCharacter={MAX_CHARACTER}
      handleChange={onChange}
      textValue={""}
      activeBlur
      cleanOnBlur
      mainClass={"autocomplete-select"}
      autoCompleteClasses={{
        option: "option",
        listbox: "listbox",
        // popper: popperClass,
      }}
      textFieldClasses={{
        adornedEnd: "adornedend",
        inputAdornedEnd: "inputAdornedEnd",
        notchedOutline: "notchedOutline",
      }}
      onTextFieldChange={(ev) => {
        const value = ev.target.value;
        setTextFieldValue(value.trim().replace(/\s+/g, " "));
      }}
      textFieldValue={textFieldValue || value}
      isOpenList={isListOpen}
      handleOpenList={(value) => {
        setIsListOpen(value);
      }}
      onClose={() => setIsListOpen(false)}
      forceInput
      tabSave={`GL-Account-${expense.id}`}
    />
  );
}

export default PaidToComponent;
