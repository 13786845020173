import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import GoogleIcon from "../../assets/flag-icons/google-png.png";

function GoogleSignInButton({ onSuccess, onError }) {
  const googleSignIn = useGoogleLogin({
    onSuccess: onSuccess,
    onError: onError,
    scope: "https://www.googleapis.com/auth/spreadsheets",
  });

  return (
    <button
      onClick={() => googleSignIn()}
      style={{
        background: "white",
        color: "#444",
        width: 190,
        borderRadius: 5,
        border: "thin solid #888",
        boxShadow: "1px 1px 1px grey",
        whiteSpace: "nowrap",
      }}
    >
      <img
        src={GoogleIcon}
        alt="google"
        style={{
          display: "inline-block",
          verticalAlign: "middle",
          width: 24,
          height: 24,
          margin: "0px 8px",
        }}
      />{" "}
      <span>Sign In with Google</span>{" "}
    </button>
  );
}

export default GoogleSignInButton;
