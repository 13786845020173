import React, { useState } from "react";
import {
  deletePlaceholderDocuments,
  getModalForDeleting,
} from "../../helpers/documents";
import {
  DeleteIconv2,
  EditFileIcon,
  FolderIconv2,
  MoveToRootIcon,
  PermissionIcon,
  ThreeDotMenuIcon,
} from "../../helpers/iconSvgPath";
import { Button } from "@mui/material";
import IntlMessages from "../../util/IntlMessages";
import { MenuOptionsPopoverStyled } from "./styles";
import {
  canCreateFolder,
  canUploadFile,
  getDocumentPathv2,
  getDuplicatedNamesInDestination,
} from "../../helpers/documentsv2.helper";
import AttentionModal from "../Modal/AttentionModal";
import { TYPE_OF_FILE } from "../../helpers/constants";
import { DOCUMENT_TRIGGER_TYPES } from "../../helpers/purchaseOrder";
import { updateDoc } from "firebase/firestore";
import newFile from "../../assets/flag-icons/new-file-placeholder.svg";

function MenuOptions({
  currentDocument = {},
  allDocuments = [],
  onRename = () => {},
  onUploadFiles = () => {},
  onAddNewFolder = () => {},
  onPermission = () => {},
  onCloseNewFile = () => {},
  onModalAttention = () => {},
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [attentionModal, setAttentionModal] = useState({
    open: false,
    description: "",
    action: () => {},
    confirmationText: "",
    cancelText: "",
  });

  const handleClose = (ev) => {
    setAnchorEl(null);
    ev.preventDefault();
    ev.stopPropagation();
  };
  const open = Boolean(anchorEl);

  function handleUploadFile(ev) {
    onUploadFiles();

    handleClose(ev);
  }

  function handleCreateFolder(ev) {
    onAddNewFolder();

    handleClose(ev);
  }

  function handleRename(ev) {
    onRename();

    handleClose(ev);
    ev.stopPropagation();
  }

  function handlePermissions(ev) {
    onPermission();

    handleClose(ev);
  }

  async function handleDelete() {
    deletePlaceholderDocuments({
      documents: [
        {
          ...currentDocument,
        },
      ],
    });
  }

  function verifyDelete(ev) {
    handleClose(ev);
    const { open, description, style } = getModalForDeleting({
      isMultiSelect: false,
      placeholderText: "placeholder",
      documents: [
        {
          ...currentDocument,
          currentPath: getDocumentPathv2({
            currentDocument,
            documents: allDocuments,
          }),
        },
      ],
    });
    setAttentionModal({
      open,
      description,
      action: handleDelete,
      cancelText: "Cancel",
      confirmationText: "Delete",
      style,
    });
  }

  function handleMoveToTopLevel(ev) {
    handleClose(ev);
    const { isDuplicatedName, attentionModalDescription } =
      getDuplicatedNamesInDestination({
        documentsInTheSameLevel: allDocuments.filter(
          (document) => document.parentId === ""
        ),
        documentsToMove: [currentDocument],
      });

    if (isDuplicatedName) {
      setAttentionModal({
        open: true,
        description: attentionModalDescription,
        action: () => {},
        confirmationText: "Ok",
        cancelText: "",
      });
    } else {
      setTimeout(() => {
        updateDoc(currentDocument.ref, {
          parentId: "",
          oldPath: getDocumentPathv2({
            currentDocument,
            documents: allDocuments,
          }),
          currentPath: "",
          triggerType: DOCUMENT_TRIGGER_TYPES.DISMISS_TRIGGER,
        })
          .then(() => {
            onModalAttention({
              open: true,
              description: (
                <React.Fragment>
                  Succesfully moved to <strong>top level</strong>
                </React.Fragment>
              ),
              action: () => {},
              confirmationText: "Ok",
              cancelText: "",
            });
          })
          .catch((error) => {
            console.log("Error updating documents", error);
          });
      }, 1000);
    }
  }
  return (
    <div
      className="document-options-container"
      onClick={(ev) => {
        ev.stopPropagation();
        ev.preventDefault();
      }}
    >
      {attentionModal.open && (
        <AttentionModal
          title="Attention"
          isOpen={attentionModal.open}
          description={attentionModal.description}
          onClick={(ev) => {
            attentionModal.action();
            setAttentionModal({
              open: false,
              description: "",
              action: () => {},
              confirmationText: "",
              cancelText: "",
            });
            ev.stopPropagation();
          }}
          onClose={(ev) => {
            setAttentionModal({
              open: false,
              description: "",
              action: () => {},
              confirmationText: "",
              cancelText: "",
            });
            ev.stopPropagation();
          }}
          cancellable={!!attentionModal.cancelText}
          confirmationText={attentionModal.confirmationText}
          cancelText={attentionModal.cancelText}
          styleModal={attentionModal.style || {}}
        />
      )}

      <Button
        id={"document-menu-icon-id" + currentDocument.id}
        onClick={(ev) => {
          setAnchorEl(ev.currentTarget);
          onCloseNewFile();
          ev.stopPropagation();
        }}
        style={{
          minWidth: 0,
          padding: 0,
          height: "auto",
        }}
      >
        <ThreeDotMenuIcon svgClass={`actionIcon_${currentDocument.scope}`} />
      </Button>
      {open && (
        <MenuOptionsPopoverStyled
          id={"document-menu-options-id"}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          onContextMenu={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          classes={{
            paper: "menuOptionsPopoverPaper",
            root: "menuOptionsPopoverRoot",
          }}
          className="menuOptionsPopoverContainer"
          onClick={(ev) => ev.stopPropagation()}
        >
          {canUploadFile({ currentDocument }) && (
            <div onClick={handleUploadFile} className="menu-options-items">
              <div className="container-img">
                <img
                  className={"img-new-folder-placeholder"}
                  src={newFile}
                  alt="file"
                />
              </div>
              <div className="container-text">
                <IntlMessages id="components.documents.menuoptions.newfileTemplate" />
              </div>
            </div>
          )}
          {canCreateFolder({ currentDocument }) && (
            <div onClick={handleCreateFolder} className="menu-options-items">
              <div className="container-img">
                <FolderIconv2 width={16} height={16} />
              </div>
              <div className="container-text">
                <IntlMessages id="components.documents.menuoptions.newfolderTemplate" />
              </div>
            </div>
          )}
          {currentDocument.parentId && (
            <div onClick={handleMoveToTopLevel} className="menu-options-items">
              <div className="container-img">
                <MoveToRootIcon svgClass="img-options-icons" />
              </div>
              <div className="container-text">
                <IntlMessages id="components.documents.menuoptions.moveToTopLevel" />
              </div>
            </div>
          )}

          <div onClick={handleRename} className="menu-options-items">
            <div className="container-img">
              <EditFileIcon width={16} height={16} />
            </div>
            <div className="container-text">
              {<IntlMessages id="components.documents.menuoptions.rename" />}
            </div>
          </div>
          {currentDocument.type === TYPE_OF_FILE.FOLDER && (
            <div onClick={handlePermissions} className="menu-options-items">
              <div className="container-img">
                <PermissionIcon />
              </div>
              <div className="container-text">
                {
                  <IntlMessages id="components.documents.menuoptions.permissions" />
                }
              </div>
            </div>
          )}
          <div onClick={verifyDelete} className="menu-options-items">
            <div className="container-img">
              <DeleteIconv2 svgClass="img-options-icons" />
            </div>
            <div className="container-text">
              {<IntlMessages id="generic.text.delete" />}
            </div>
          </div>
        </MenuOptionsPopoverStyled>
      )}
    </div>
  );
}

export default MenuOptions;
