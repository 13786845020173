import { cx } from "@emotion/css";
import { createContext, useReducer } from "react";
import { motion } from "framer-motion";
import { borderAnimation } from "../PurchaseOrderDashboard/ActivityStream/ActivityHelpers";
import { colors } from "../../assets/jss/variables";

export const ActivityStreamContext = createContext(null);
export const ACTIVITY_STREAM_ACTION = {
  COMMON: "COMMON",
  BRING_ACTIVITIES_FROM_THE_BEGINING: "BRING_ACTIVITIES_FROM_THE_BEGINING",
};

export function ActivityStreamProvider({ children }) {
  const [activityStreamState, dispatchActivityStream] = useReducer(
    activityStreamReducer,
    initialState
  );
  const { noteThread } = activityStreamState;

  return (
    <div>
      <motion.div
        initial="hidden"
        animate={!!noteThread ? "visible" : "reset"}
        variants={borderAnimation}
        backgroundColor={colors.shipmentBorderColor}
        className={cx("motionClass", `poligon_${noteThread?.scope}`)}
        key={!!noteThread}
      />
      <div
        id="dashboard-activities-animated"
        className={cx("dashboard-activities-animated", {
          activeThread: !!noteThread,
        })}
      >
        <ActivityStreamContext.Provider
          value={{
            activityStreamState,
            dispatchActivityStream,
          }}
        >
          {children}
        </ActivityStreamContext.Provider>
      </div>
    </div>
  );
}

const initialState = {
  querySearch: "",
  noteThread: false,
  replyActivity: {},
  activeTab: null,
};

function activityStreamReducer(state, action) {
  switch (action.type) {
    case ACTIVITY_STREAM_ACTION.COMMON:
      return {
        ...state,
        ...action.payload,
      };
    case ACTIVITY_STREAM_ACTION.BRING_ACTIVITIES_FROM_THE_BEGINING:
      return {
        ...state,
        ...action.payload,
      };

    default:
      console.log("Unknown action type: ", action.type);
      return state;
  }
}
