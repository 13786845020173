import React from "react";
import { dbTables } from "../../api/types/dbTables";
import { FILTER_NOTIFICATION } from "../../helpers/constants";
import { useUser } from "../../hooks/user";
import NotificationGeneralFilters from "../FilterTable/NotificationGeneralFilters";
import FilterTab from "../PurchaseOrderDashboard/FilterTab";
import TooltipTD from "../Tooltip/TooltipTD";
import { NotificationFiltersStyled } from "./styles";
import { FilterNotificationIcon, MarkAllIcon } from "../../helpers/iconSvgPath";
import { cx } from "@emotion/css";
import { colors } from "../../assets/jss/variables";

function NotificationsFilters({
  activeTab,
  changeFilterTab,
  filters,
  changeFilters,
  onResetFilters,
  onFilterClick,
  unReadNotifications,
  onMarkAll = () => {},
}) {
  const user = useUser();

  return (
    <NotificationFiltersStyled className="taskFilters">
      <div className="generalFilters">
        <div className="tabFilterContainer">
          <FilterTab
            onClick={changeFilterTab}
            activeTab={activeTab}
            value={FILTER_NOTIFICATION.ALL}
            label="notifications.filters.allNotifications"
          />
          <FilterTab
            onClick={changeFilterTab}
            activeTab={activeTab}
            value={FILTER_NOTIFICATION.DMs}
            label="notifications.filters.directMessage"
            noTransform={true}
            user={user}
            scope={dbTables.NOTIFICATIONS}
            avatarStyle={{
              width: 20,
              height: 20,
              marginBottom: "0.5rem",
            }}
          />
          <FilterTab
            onClick={changeFilterTab}
            activeTab={activeTab}
            value={FILTER_NOTIFICATION.AD_HOC_TASK}
            label="notifications.filters.adHocTask"
          />
          <FilterTab
            onClick={changeFilterTab}
            activeTab={activeTab}
            value={FILTER_NOTIFICATION.COMPLETE_EARLY}
            label="notifications.filters.completeEarly"
            noTransform
          />
          <FilterTab
            onClick={changeFilterTab}
            activeTab={activeTab}
            value={FILTER_NOTIFICATION.COMPLETE_LATE}
            label="notifications.filters.completeLate"
            noTransform
          />
          <div className="indicator" />
        </div>
        <div className="filterNotificaitionContainer">
          <TooltipTD
            label="Show unread notifications only"
            tooltipClick={onFilterClick}
            className={cx("iconClick", { activeUnread: unReadNotifications })}
          >
            <FilterNotificationIcon
              color={unReadNotifications ? "#3b89ee" : colors.primaryDark}
            />
          </TooltipTD>
          <TooltipTD
            className="iconClick"
            label="Mark all notifications as read"
            tooltipClick={onMarkAll}
          >
            <MarkAllIcon />
          </TooltipTD>
        </div>
      </div>

      <div className={"selectFilters"}>
        <NotificationGeneralFilters
          changeFilters={changeFilters}
          currentFilters={filters}
          enabledFields={{
            assignedTo: true,
            customer: true,
            factory: true,
            date: true,
            tag: false,
          }}
          activeTab={activeTab}
          onResetFilters={onResetFilters}
        />
      </div>
    </NotificationFiltersStyled>
  );
}

export default NotificationsFilters;
