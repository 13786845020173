import Tag from "../../api/model/tag";
import { AUTOMATIC_SYSTEM_LABEL, TYPE_OF_TAGS } from "../../helpers/constants";

export const COMPANY_INITIAL_TAGS = [
  {
    ...new Tag({
      name: AUTOMATIC_SYSTEM_LABEL.SALES_ORDER_MISMATCH,
      type: TYPE_OF_TAGS.MISMATCH_TAG,
    }),
  },
  {
    ...new Tag({
      name: AUTOMATIC_SYSTEM_LABEL.SHIPMENT_MISMATCH,
      type: TYPE_OF_TAGS.MISMATCH_TAG,
    }),
  },
  {
    ...new Tag({
      name: AUTOMATIC_SYSTEM_LABEL.SALES_ORDER_FILE,
      type: TYPE_OF_TAGS.FILE_TAG,
    }),
  },
  {
    ...new Tag({
      name: AUTOMATIC_SYSTEM_LABEL.PURCHASE_ORDER_FILE,
      type: TYPE_OF_TAGS.FILE_TAG,
    }),
  },
  {
    ...new Tag({
      name: AUTOMATIC_SYSTEM_LABEL.TASK_DEADLINE,
      type: TYPE_OF_TAGS.DEADLINE_TAG,
    }),
  }
];
