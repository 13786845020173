import React from "react";
import UserNotificationSetting from "./UserNotificationSetting";

const settingOptions = [
  {
    label:
      "Receive email notifications for notes where I am tagged (@{username})",
    property: "mentionNotifications",
    type: "switch",
    description:
      "When set to YES, an email will be sent as a notification when @{username} is tagged in a note. If set to NO, please remember to check TradeDash regularly for important communications. ",
  },
  {
    label: "Pending and Past Due Tasks Morning Email",
    type: "switch",
    property: "taskNotifications",
    description:
      "When set to YES, an email reminder will be sent each morning if there are any late Tasks or if any Tasks are due that day or the next.",
  },
  {
    label: "Receive email notifications for tasks completed late",
    type: "switch",
    property: "lateTaskEmail",
    description:
      "An email will be sent when a task is completed late and I am configured to receive notifications for that task (by your TradeDash administrator).",
  },
  {
    label: "Receive email notifications for tasks completed early",
    type: "switch",
    property: "earlyTaskEmail",
    description:
      "An email will be sent when a task is completed early and I am configured to receive notifications for that task (by your TradeDash administrator).",
  },
  {
    label: "Mark all unread notes as read",
    type: "button",
    description: "This will mark every single note where @{username} is tagged as read. There will no longer be any unread notes or notifications.",
  },
];

function UserProfileHeader({ currentUser, handleClearMentions }) {
  return (
    <div
      className={"user-profile-header"}
      style={{
        padding: 0,
        marginInline: 12,
      }}
    >
      {settingOptions.map((setting, index) => (
        <UserNotificationSetting
          key={setting + index}
          currentUser={currentUser}
          setting={{
            ...setting,
            label: setting.label.replace("{username}", currentUser.displayName),
          }}
          handleClearMentions={handleClearMentions}
        />
      ))}
    </div>
  );
}

export default UserProfileHeader;
