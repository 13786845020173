import { colors } from "../../assets/jss/variables";
import { styled } from "@mui/material";

export const FeatureFlagStyled = styled("div")(() => ({
  "&.FFContainer": {
    boxShadow: "0 2px 4px 2px #ccc",
    margin: "0px 4px 4px 4px",
    overflow: "scroll",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  "& .FFCompanyNotFound": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    fontSize: 36,
    fontWeight: 700,
  },
  "& .FFHeader": {
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#c0d5ee",
    fontSize: 18,
    fontWeight: 500,
    color: "#464D69",
  },
  "& .addNewBtn": {
    backgroundColor: "transparent",
    color: "#25486d",
    border: "none",
    fontSize: 15,
    height: 44,
    width: "auto",
    fontWeight: "bold !Important",
    padding: 0,
    textTransform: "uppercase !Important",
    "&:hover": {
      background: "transparent",
    },
  },

  "& .addFeatureFlagContainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

export const FeatureFlagItemStyled = styled("li")(() => ({
  "&.permissionGroup": {
    color: "#25486d",
    border: "1px solid white",
    "& .root-width": {
      minWidth: 300,
    },
    display: "flex",
    alignItems: "center",
    padding: 8,
    "& li": {
      display: "flex",
      alignItems: "center",
      color: "#25486d",
    },
    "& .type-label": {
      width: 345,
      minWidth: "fit-content",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
    "& .permission-group-content": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      "& .add-remove-all-options": {
        width: "100px",
        textAlign: "center",
        margin: "0px 8px",
        "& .option-button": {
          padding: "4px 8px",
          fontSize: 14,
          fontWeight: "bold",
          cursor: "pointer",
          borderRadius: 5,
          "&:hover": {
            outline: `1px solid ${colors.primaryDark}`,
          },
        },
      },
      "& .autocomplete-users-contenainer-options": {
        width: "88%",
      },
    },
  },
  "& .switchOffStyles": {
    "& .MuiIconButton-root:hover": {
      background: "transparent",
    },
    "& .MuiIconButton-label": {
      color: colors.diamondRed,
    },
    "& .MuiSwitch-track": {
      backgroundColor: `${colors.diamondRed} !important`,
    },
  },
  "& .switchOnStyles": {
    "& .MuiIconButton-root:hover": {
      background: "transparent",
    },
    "& .MuiIconButton-label": {
      color: colors.diamondGreen,
    },
    "& .MuiSwitch-track": {
      backgroundColor: `${colors.diamondGreen} !important`,
    },
  },
}));
