import React, { useCallback } from "react";
import Notification from "./Notification";
import { useUser } from "../../hooks/user";
import { useQueryParams } from "../../hooks";
import { usePurchaseOrders, useSalesOrders } from "../../hooks/salesOrders";
import { setGlobalBackdrop } from "../../actions/DataActions";
import { useDispatch } from "react-redux";
import { useIsAllowedFunction } from "../../hooks/permissions";
import {
  AutoSizer,
  List,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";

function NotificationsContent({
  closeDrawer = () => {},
  onToggle,
  isAbleToModifyTaskDueDate,
  history,
  handleOpenModal,
  handleLoading,
  filteredNotifications = [],
  searchText = "",
}) {
  const user = useUser();
  const dispatch = useDispatch();
  const isAllowed = useIsAllowedFunction();
  const params = useQueryParams();
  const purchaseOrders = usePurchaseOrders();
  const salesOrders = useSalesOrders();
  const salesOrderId = params.salesOrderId || "";
  const currentSalesOrder = salesOrders.find(
    (salesOrder) => salesOrder.id === salesOrderId
  );
  const setBackdrop = useCallback((type) =>
    setGlobalBackdrop({
      type: type,
      isOpen: true,
      id: type,
    })(dispatch)
  );

  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 50,
  });

  const rowRenderer = ({ index, parent, key, style }) => (
    <CellMeasurer
      key={key}
      cache={cache}
      parent={parent}
      columnIndex={0}
      rowIndex={index}
    >
      <div style={style}>
        <Notification
          key={filteredNotifications[index].id}
          closeDrawer={closeDrawer}
          notification={filteredNotifications[index]}
          onToggle={onToggle}
          isAbleToModifyTaskDueDate={isAbleToModifyTaskDueDate}
          history={history}
          params={params}
          purchaseOrders={purchaseOrders}
          salesOrder={currentSalesOrder}
          user={user}
          isAllowed={isAllowed}
          handleBackdrop={setBackdrop}
          handleOpenModal={handleOpenModal}
          handleLoading={handleLoading}
          searchText={searchText}
        />
      </div>
    </CellMeasurer>
  );

  return (
    <AutoSizer>
      {({ width, height }) => (
        <List
          width={width}
          height={height}
          rowCount={filteredNotifications.length}
          deferredMeasurementCache={cache}
          rowHeight={cache.rowHeight}
          rowRenderer={rowRenderer}
        />
      )}
    </AutoSizer>
  );
}

export default NotificationsContent;
