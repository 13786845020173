import React from "react";
import { dbTables } from "../../../api/types/dbTables";
import { useDataRefactored } from "../../../hooks";
import { BACKDROP_TYPE } from "../../../helpers/constants";
import { BackdropDashboard } from "../sections/BackdropDashboard";
import ProjectTaskPanel from "./ProjectTaskPanel";
import { TaskProvider } from "./TaskContext";

function ProjectTasksV2({
  companyId,
  currentSalesOrder,
  currentPurchaseOrder,
  currentShipment,
  purchaseOrders,
  panelWidth,
  handleActivePanel,
  isReadOnly,
  handleReadOnlyModal,
}) {
  const salesOrderTasks = useDataRefactored({
    path: [
      dbTables.COMPANIES,
      companyId,
      dbTables.SALES_ORDERS,
      currentSalesOrder.id,
      dbTables.SALES_ORDER_TASKS,
    ],
    changingParams: [companyId, currentSalesOrder.id],
    stateReference: dbTables.SALES_ORDER_TASKS,
  });

  const purchaseOrderTasks = useDataRefactored({
    path: [
      dbTables.COMPANIES,
      companyId,
      dbTables.PURCHASE_ORDERS,
      currentPurchaseOrder.id,
      dbTables.PURCHASE_ORDER_TASKS,
    ],
    changingParams: [companyId, currentSalesOrder.id, currentPurchaseOrder.id],
    stateReference: dbTables.PURCHASE_ORDER_TASKS,
  });

  const shipmentTasks = useDataRefactored({
    path: [
      dbTables.COMPANIES,
      companyId,
      dbTables.SHIPMENTS,
      currentShipment.id,
      dbTables.SHIPMENT_TASKS,
    ],
    changingParams: [companyId, currentShipment.id],
    stateReference: dbTables.SHIPMENT_TASKS,
  });

  return (
    <TaskProvider panelWidth={panelWidth}>
      <BackdropDashboard backdropType={BACKDROP_TYPE.TASK} />
      <ProjectTaskPanel
        handleActivePanel={handleActivePanel}
        panelWidth={panelWidth}
        purchaseOrders={purchaseOrders}
        purchaseOrderTasks={purchaseOrderTasks}
        salesOrderTasks={salesOrderTasks}
        shipmentTasks={shipmentTasks}
        salesOrder={currentSalesOrder}
        purchaseOrder={currentPurchaseOrder}
        currentShipment={currentShipment}
        isReadOnly={isReadOnly}
        handleReadOnlyModal={handleReadOnlyModal}
      />
    </TaskProvider>
  );
}

export default ProjectTasksV2;
