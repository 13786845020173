import { getRandomId } from "../../helpers/helpers";
import { now } from "moment";

export default class GLAccountModel {
  constructor({
    id = getRandomId(),
    creationDate = now(),
    accounts = [],
    size = 1,
  }) {
    this.id = id;
    this.creationDate = creationDate;
    this.accounts = accounts;
    this.size = size;
  }
}
