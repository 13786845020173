import React, { useState, useEffect, useRef } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  AddIcon,
  CancelIcon,
  DeleteIconv2,
  EditIcon,
  ExpenseFileIcon,
  RemoveIcon,
} from "../../../helpers/iconSvgPath";
import moment from "moment";
import numeral from "numeral";
import ExpenseItem from "../../../api/model/ExpenseItem";
import { getRandomId, sortObjectsBy } from "../../../helpers/helpers";
import TooltipTD from "../../Tooltip/TooltipTD";
import {
  Accordion,
  AccordionSummary,
  Autocomplete,
  TextField,
  Button,
  Popper,
} from "@mui/material";
import { EXPENSES_HEADER_LABELS } from "../../../helpers/constants";
import { EXPENSE_FIELDS } from "../../../helpers/salesOrder";
import PaidToComponent from "./PaidToComponent";
import {
  canDeleteExpense,
  canEditExpense,
  getGLAccount,
  getTotal,
  isEqualTotalAmount,
  verifyRequiredFields,
} from "../../../helpers/expenses";
import { updateDoc } from "firebase/firestore";
import DatePicker from "../../DatePicker/DatePicker";
import { getTotalFixed } from "./ExpensesHelper";
import { cx } from "@emotion/css";
import { colors } from "../../../assets/jss/variables";

const DISPLAY_OPTIONS_TYPE = {
  EDIT_DELETE: "EDIT_DELETE",
  ADD_SAVE: "ADD_SAVE",
  REMOVE: "REMOVE",
};

const PDF_POSITION = {
  LEFT: "LEFT",
  BOTTOM: "BOTTOM",
};

function ExpenseItemComponent({
  expensesHeader,
  expense,
  onDelete,
  onEdit,
  editing,
  onSave,
  GLAccountItems = [],
  paidToMiscellaneo = {},
  uniquePaidToMiscellaneo = {},
  expandConfig = {},
  onExpand,
  isAllowed,
  user = {},
  withRef,
  documentAI = {},
  currentTotalActual = 0,
  onlyRead = false,
}) {
  const [currentExpense, setCurrentExpense] = useState(expense);
  const [totalActual, setTotalActual] = useState(+currentTotalActual);
  const [pdfPosition, setPdfPosition] = useState(PDF_POSITION.BOTTOM);
  const [showPDF, setShowPDF] = useState(false);
  const dateRef = useRef(null);
  const [openPicker, setOpenPicker] = useState(false);
  const editingExpense = editing && editing.id === currentExpense.id;
  const isOneItem = currentExpense.items.length <= 1;
  const firstItem = currentExpense.items.sort(sortObjectsBy("index", false))[0];

  useEffect(() => {
    if (!editingExpense) {
      setCurrentExpense(expense);
    }
  }, [expense]);

  useEffect(() => {
    if (documentAI.totalActual) {
      setTotalActual(numeral(documentAI.totalActual).format("0.00"));
    }
  }, [documentAI]);

  function isAbleToDisplayOptions({ editing, currentExpense }) {
    if (!editing) {
      const isExpanded =
        expandConfig[currentExpense.id] && currentExpense.items.length > 1;
      if (isExpanded) {
        return false;
      }
      return true;
    }
    return false;
  }

  function getExpenseValue({ expense = {}, label = "", field }) {
    switch (label) {
      case EXPENSES_HEADER_LABELS.DATE:
        return (
          expense.index === 1 && (
            <span style={{ paddingRight: 27 }}>
              {moment(expense[field]).format("M/D/YY")}
            </span>
          )
        );
      case EXPENSES_HEADER_LABELS.PAID_TO:
        return (
          expense.index === 1 && (
            <TooltipTD
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                paddingLeft: 7,
              }}
              label={expense[field]}
            >
              {expense[field]}
            </TooltipTD>
          )
        );
      case EXPENSES_HEADER_LABELS.DESCRIPTION_OF_CHARGES:
      case EXPENSES_HEADER_LABELS.NOTES:
        return (
          (expandConfig[currentExpense.id] ||
            (isOneItem && expense.index === 1)) && (
            <TooltipTD
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                paddingLeft: 8,
              }}
              label={expense[field]}
            >
              {expense[field]}
            </TooltipTD>
          )
        );
      case EXPENSES_HEADER_LABELS.FORECAST:
      case EXPENSES_HEADER_LABELS.ACTUAL:
        return (
          <span style={{ paddingRight: 7 }}>
            {numeral(expense[field]).format("$0,0.00")}
          </span>
        );

      case EXPENSES_HEADER_LABELS.GL_ACCOUNT: {
        const { name, description } = getGLAccount({
          items: GLAccountItems,
          itemId: expense.GLAccountId,
        });
        return (
          <TooltipTD
            label={description}
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              paddingLeft: 14,
            }}
          >
            <span style={{ width: 85, display: "inline-block" }}>{name}</span>
            <span>{description}</span>
          </TooltipTD>
        );
      }
      case "":
        return (
          isAbleToDisplayOptions({ editing, currentExpense }) &&
          displayOptions({
            expense: currentExpense,
            type: DISPLAY_OPTIONS_TYPE.EDIT_DELETE,
          })
        );
      default:
        return (expandConfig[currentExpense.id] || isOneItem) && expense[field];
    }
  }

  function handleChangeItem({ expense, field = "", value }) {
    let itemsCpy = [...expense.items];
    itemsCpy = itemsCpy.map((item) => {
      if (item.id === expense.itemId) {
        return { ...item, [field]: value };
      }
      return item;
    });
    setCurrentExpense({ ...currentExpense, items: itemsCpy });
  }

  function handleChangeExpense({ expense, field = "", value }) {
    setCurrentExpense({ ...expense, [field]: value });
  }

  const handleAddItem = (ev) => {
    const itemsCpy = [...currentExpense.items];
    const id = getRandomId();
    itemsCpy.push({
      ...new ExpenseItem({
        index: itemsCpy.length + 1,
        id,
      }),
    });
    setCurrentExpense({
      ...currentExpense,
      items: itemsCpy,
    });
    setTimeout(() => {
      const element = document.getElementById("GL-Account-" + id);
      if (element) {
        element.focus();
      }
    }, 50);
    ev.preventDefault();
    ev.stopPropagation();
  };

  const AddButton = () => (
    <Button
      className="editing-expense-button-to-add"
      style={{ cursor: "pointer", margin: "0" }}
      onClick={handleAddItem}
    >
      <AddIcon />
    </Button>
  );

  const SaveButton = () => (
    <TooltipTD
      label={getButtonDisabledText({
        currentExpense,
        totalActual,
      })}
      showToolTip={getButtonDisabledText({
        currentExpense,
        totalActual,
      })}
    >
      <Button
        className="editing-expense-button-to-save"
        style={{ cursor: "pointer", margin: "0px 4px" }}
        onClick={() => {
          onSave({ expense: currentExpense });
          setTotalActual(0);
        }}
        disabled={
          getButtonDisabledText({
            currentExpense,
            totalActual,
          }) !== ""
        }
      >
        SAVE
      </Button>
    </TooltipTD>
  );

  function displayOptions({ expense, type = "" }) {
    if (type === DISPLAY_OPTIONS_TYPE.EDIT_DELETE) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          {currentExpense.pdfURL ? (
            <ExpenseFileIcon
              onClick={(ev) => {
                setShowPDF(true);
                ev.preventDefault();
                ev.stopPropagation();
              }}
              style={{ cursor: "pointer", margin: "0px 4px" }}
            />
          ) : (
            <div style={{ width: 27 }}></div>
          )}
          {canEditExpense({
            isAllowed: isAllowed,
            createdBy: currentExpense.createdBy,
            userId: user.id,
          }) && !onlyRead ? (
            <EditIcon
              style={{ cursor: "pointer", margin: "0px 4px" }}
              onClick={(ev) => {
                if (editing) return;
                onEdit({ expense });
                onExpand(true);
                const total = +getTotal({
                  list: expense.items,
                  field: EXPENSE_FIELDS.ACTUAL,
                  format: false,
                });
                setTotalActual(total);
                ev.preventDefault();
                ev.stopPropagation();
              }}
            />
          ) : (
            <div style={{ width: 29 }}></div>
          )}
          {canDeleteExpense({
            isAllowed: isAllowed,
            createdBy: currentExpense.createdBy,
            userId: user.id,
          }) &&
            !onlyRead && (
              <DeleteIconv2
                style={{ cursor: "pointer", margin: "0px 4px" }}
                onClick={() => onDelete({ expense: currentExpense })}
              />
            )}
        </div>
      );
    } else if (type === DISPLAY_OPTIONS_TYPE.ADD_SAVE) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            className="editing-expense-button-to-add"
            style={{ cursor: "pointer", margin: "0px 4px" }}
            onClick={(ev) => {
              const itemsCpy = [...expense.items];
              const newExpenses = {
                ...new ExpenseItem({ index: itemsCpy.length + 1 }),
              };
              itemsCpy.push(newExpenses);
              setCurrentExpense({ ...expense, items: itemsCpy });
              onExpand(true);
              setTimeout(() => {
                const element = document.getElementById(
                  "GL-Account-" + newExpenses.id
                );
                if (element) {
                  element.focus();
                }
              }, 100);
              ev.preventDefault();
              ev.stopPropagation();
            }}
          >
            <AddIcon />
          </Button>
          <TooltipTD
            label={
              "Required fields: " +
              verifyRequiredFields({ expense: currentExpense }).join(", ")
            }
            showToolTip={
              verifyRequiredFields({ expense: currentExpense }).length > 0
            }
          >
            <Button
              className="editing-expense-button-to-save"
              style={{ cursor: "pointer", margin: "0px 4px" }}
              onClick={() => {
                onSave({ expense: currentExpense });
              }}
              disabled={
                verifyRequiredFields({ expense: currentExpense }).length > 0
              }
            >
              SAVE
            </Button>
          </TooltipTD>
        </div>
      );
    } else if (type === DISPLAY_OPTIONS_TYPE.REMOVE) {
      return (
        <Button style={{ minWidth: 20, minHeight: 20, padding: 0 }}>
          <RemoveIcon
            style={{ margin: "0px 4px" }}
            onClick={(ev) => {
              let itemsCpy = [...expense.items];
              itemsCpy = itemsCpy.filter(
                (itemCpy) => itemCpy.id !== expense.itemId
              );
              itemsCpy = itemsCpy
                .sort(sortObjectsBy("index", false))
                .map((itemCpy, index) => ({ ...itemCpy, index: index + 1 }));
              setCurrentExpense({ ...expense, items: itemsCpy });
              ev.preventDefault();
              ev.stopPropagation();
            }}
          />
        </Button>
      );
    }
    return "";
  }

  function getExpenseEditingValue({
    expense = {},
    label = "",
    isEditing = false,
    field,
  }) {
    switch (label) {
      case EXPENSES_HEADER_LABELS.DATE:
        return (
          isEditing &&
          expense.index === 1 && (
            <>
              {openPicker && (
                <DatePicker
                  el={dateRef.current}
                  onChange={(value) => {
                    const StringDate = moment(value).format("YYYY-MM-DD");
                    handleChangeExpense({
                      expense,
                      value: StringDate,
                      field: EXPENSE_FIELDS.DATE,
                    });
                    setOpenPicker(false);
                  }}
                  open={openPicker}
                  onClose={() => setTimeout(() => setOpenPicker(false), 100)}
                  value={expense[field] || ""}
                  cancelIcon={true}
                  onKeyDown={(ev) => {
                    if (ev.key === "Escape") {
                      setOpenPicker(false);
                      ev.preventDefault();
                      ev.stopPropagation();
                    }
                  }}
                />
              )}
              <TextField
                ref={dateRef}
                id="expense_date_input"
                className="expense-date"
                type="date"
                inputProps={{ className: "expense-date-input" }}
                value={expense[field]}
                required
                onClick={(ev) => {
                  setOpenPicker(true);
                  ev.preventDefault();
                  ev.stopPropagation();
                }}
                onKeyDown={(ev) => {
                  if (ev.key === "Enter") {
                    setOpenPicker(true);
                    ev.preventDefault();
                    ev.stopPropagation();
                  }
                }}
                onChange={(ev) => {
                  const StringDate = moment(ev.target.value).format(
                    "YYYY-MM-DD"
                  );
                  moment(StringDate).isValid();
                  handleChangeExpense({
                    expense,
                    value: moment(StringDate).isValid() ? StringDate : "",
                    field: EXPENSE_FIELDS.DATE,
                  });
                }}
              />
            </>
          )
        );
      case EXPENSES_HEADER_LABELS.PAID_TO:
        return (
          isEditing &&
          expense.index === 1 && (
            <PaidToComponent
              onChange={(ev, value) => {
                handleChangeExpense({
                  expense,
                  value,
                  field: EXPENSE_FIELDS.PAID_TO,
                });
              }}
              options={paidToMiscellaneo.list}
              handleAddNewElement={(value = "") => {
                const listCpy = [...paidToMiscellaneo.list];
                const uniqueList = [...(uniquePaidToMiscellaneo.list || [])];
                const parseValue = value
                  .replace(/^\s+|\s+$/g, "")
                  .replace(/\s+/g, " ");
                if (listCpy.includes(parseValue)) {
                  return;
                }
                if (!uniqueList.includes(parseValue)) {
                  uniqueList.push(parseValue);
                  updateDoc(uniquePaidToMiscellaneo.ref, {
                    list: uniqueList,
                  });
                }

                listCpy.push(parseValue);
                updateDoc(paidToMiscellaneo.ref, {
                  list: listCpy,
                });

                handleChangeExpense({
                  expense,
                  value,
                  field: EXPENSE_FIELDS.PAID_TO,
                });
              }}
              value={currentExpense[EXPENSE_FIELDS.PAID_TO]}
              onRemove={(value) => {
                let listCpy = [...paidToMiscellaneo.list];
                listCpy = listCpy.filter((item) => item !== value);
                updateDoc(paidToMiscellaneo.ref, {
                  list: listCpy,
                });
              }}
              expense={currentExpense}
            />
          )
        );
      case EXPENSES_HEADER_LABELS.GL_ACCOUNT: {
        const { name, description } = getGLAccount({
          items: GLAccountItems,
          itemId: expense.GLAccountId,
        });
        return (
          <React.Fragment>
            <Autocomplete
              id={"GL-Account-" + expense.itemId}
              options={GLAccountItems.filter((item) => item.active)
                .sort(sortObjectsBy("name", false))
                .map((option) => ({
                  id: option.id,
                  name: option.name + "  -  " + option.description,
                }))}
              getOptionLabel={(option) => option.name}
              style={{ width: "30%" }}
              renderInput={(params) => (
                <TextField {...params} className="expense-gl-account" />
              )}
              onChange={(ev, value) => {
                handleChangeItem({
                  expense,
                  value: value.id,
                  field: EXPENSE_FIELDS.GL_ACCOUNT_ID,
                });
              }}
              value={{
                id: expense.GLAccountId,
                name,
              }}
              disableClearable={true}
              classes={{
                root: "expenseRootAutocomplete",
                endAdornment: "expense-end-adorment",
                inputRoot: "expense-input-root-autocomplete",
                option: "expense-option-autocomplete",
                popper: "expensePopperAutocomplate",
                paper: "expensePaperAutocomplate",
              }}
              className="GLAccountAutocompleteContainer"
              PopperComponent={(props) => (
                <Popper
                  {...props}
                  style={{
                    width: "auto",
                    minWidth: 120,
                  }}
                  placement="bottom-start"
                />
              )}
            />

            <TextField
              className="expense-gl-account"
              inputProps={{ className: "expense-date-input" }}
              onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
              }}
              value={description}
              disabled
              style={{ width: "70%", marginLeft: 4, background: "#D2DFEC" }}
            />
          </React.Fragment>
        );
      }

      case EXPENSES_HEADER_LABELS.DESCRIPTION_OF_CHARGES:
        return (
          <TextField
            className="expense-description-of-charges"
            inputProps={{ className: "expense-date-input" }}
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
            }}
            onChange={(ev) => {
              handleChangeItem({
                expense,
                value: ev.target.value,
                field: EXPENSE_FIELDS.DESCRIPTION_OF_CHARGES,
              });
            }}
            value={expense[field]}
            placeholder="* Description of charges"
          />
        );
      case EXPENSES_HEADER_LABELS.NOTES:
        return (
          <TextField
            className="expense-notes"
            inputProps={{ className: "expense-date-input" }}
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
            }}
            onChange={(ev) => {
              handleChangeItem({
                expense,
                value: ev.target.value,
                field: EXPENSE_FIELDS.NOTES,
              });
            }}
            value={expense[field]}
            placeholder="Notes"
          />
        );

      case EXPENSES_HEADER_LABELS.FORECAST:
        return (
          <TextField
            className="expense-forecast"
            inputProps={{ className: "expense-date-input" }}
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
            }}
            type="number"
            onChange={(ev) => {
              handleChangeItem({
                expense,
                value: ev.target.value,
                field: EXPENSE_FIELDS.FORECAST,
              });
            }}
            value={expense[field] || ""}
          />
        );
      case EXPENSES_HEADER_LABELS.ACTUAL:
        return (
          <TextField
            className="expense-actual"
            inputProps={{ className: "expense-date-input" }}
            style={{ textAlign: "right" }}
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
            }}
            type="number"
            onChange={(ev) => {
              handleChangeItem({
                expense,
                value: ev.target.value,
                field: EXPENSE_FIELDS.ACTUAL,
              });
            }}
            value={expense[field] || ""}
          />
        );
      case "":
        return displayOptions({
          expense,
          type:
            expense.items.length === 1
              ? DISPLAY_OPTIONS_TYPE.ADD_SAVE
              : DISPLAY_OPTIONS_TYPE.REMOVE,
        });

      default:
        return "";
    }
  }

  function getExpenseItemStyles({ editing, currentExpense }) {
    if (!editing) {
      return {};
    } else if (editing.id === currentExpense.id) {
      return {};
    } else {
      return { display: "none" };
    }
  }

  const getButtonDisabledText = ({ currentExpense, totalActual }) => {
    const requiredFields = verifyRequiredFields({ expense: currentExpense });
    if (requiredFields.length > 0) {
      return "Required fields: " + requiredFields.join(", ");
    }
    if (!isEqualTotalAmount({ items: currentExpense.items, totalActual })) {
      return "Cannot save expense until unallocated amount is $0.00";
    }
    return "";
  };

  return (
    <React.Fragment key={expense.id}>
      <Accordion
        className="expense-item"
        expanded={
          !!expandConfig[currentExpense.id] && currentExpense.items.length > 1
        }
        classes={{ disabled: "expense-item-disabled" }}
        onClick={() => {
          if (isOneItem || editingExpense) return;
          onExpand(!expandConfig[currentExpense.id]);
        }}
        key={expense.id}
        style={getExpenseItemStyles({ editing, currentExpense })}
      >
        <AccordionSummary
          className="accordion-summary-container"
          expandIcon={
            <div
              className={cx("disclouserArrowStyle", {
                "no-display-icon": isOneItem || editingExpense,
              })}
            >
              <KeyboardArrowDownIcon
                className="arrow"
                style={{
                  width: 24,
                  height: 24,
                  transform: !expandConfig[currentExpense.id]
                    ? "rotate(-90deg)"
                    : "none",
                }}
              />
            </div>
          }
          classes={{
            content: expandConfig[currentExpense.id]
              ? "accordion-content-expended"
              : "accordion-content",
            focusVisible: "accordion-focus-visible",
            expandIconWrapper: expandConfig[currentExpense.id]
              ? "accordion-expand-icon-wrapper-expended"
              : "accordion-expand-icon-wrapper",
          }}
        >
          {expandConfig[currentExpense.id] ? (
            currentExpense.items
              .sort(sortObjectsBy("index", false))
              .map((item) => {
                return (
                  <div className="expense-itemRow">
                    {expensesHeader.map((header) => {
                      return (
                        <div
                          key={header.id}
                          id={header.id}
                          className={"expenses-itemData"}
                          style={header.bodyStyles}
                        >
                          {editingExpense && currentExpense.id === editing.id
                            ? getExpenseEditingValue({
                                expense: {
                                  ...currentExpense,
                                  GLAccountId: item.GLAccountId,
                                  descriptionOfCharges:
                                    item.descriptionOfCharges,
                                  notes: item.notes,
                                  forecast: item.forecast,
                                  actual: item.actual,
                                  index: item.index,
                                  itemId: item.id,
                                },
                                label: header.label,
                                field: header.field,
                                isEditing: true,
                              })
                            : getExpenseValue({
                                expense: {
                                  ...currentExpense,
                                  GLAccountId: item.GLAccountId,
                                  descriptionOfCharges:
                                    item.descriptionOfCharges,
                                  notes: item.notes,
                                  forecast: item.forecast,
                                  actual: item.actual,
                                  index: item.index,
                                },
                                label: header.label,
                                field: header.field,
                              })}
                        </div>
                      );
                    })}
                  </div>
                );
              })
          ) : (
            <div className="expense-itemRow">
              {expensesHeader.map((header) => {
                return (
                  <div
                    key={header.id}
                    id={header.id}
                    className={"expenses-itemData"}
                    style={header.bodyStyles}
                  >
                    {editingExpense && currentExpense.id === editing.id
                      ? getExpenseEditingValue({
                          expense: {
                            ...currentExpense,
                            GLAccountId: currentExpense.items[0].GLAccountId,
                            descriptionOfCharges:
                              currentExpense.items[0].descriptionOfCharges,
                            notes: currentExpense.items[0].notes,
                            forecast: currentExpense.items[0].forecast,
                            actual: currentExpense.items[0].actual,
                            index: currentExpense.items[0].index,
                            itemId: currentExpense.items[0].id,
                          },
                          label: header.label,
                          field: header.field,
                          isEditing: true,
                        })
                      : getExpenseValue({
                          expense: {
                            ...currentExpense,
                            GLAccountId: isOneItem ? firstItem.GLAccountId : "",
                            descriptionOfCharges:
                              firstItem.descriptionOfCharges,
                            notes: firstItem.notes,
                            index: firstItem.index,
                          },
                          label: header.label,
                          field: header.field,
                        })}
                  </div>
                );
              })}
            </div>
          )}

          {expandConfig[currentExpense.id] && !isOneItem && (
            <div className="expense-itemRow" id="totals-content">
              <div style={{ gridColumn: "span 4" }} />
              <span
                id="totalFooterText"
                className="totals-footer"
                style={{ gridColumn: "span 2", gap: 15 }}
              >
                Totals:
                <span style={{ minWidth: 150, textAlign: "end" }}>
                  {getTotal({
                    list: currentExpense.items,
                    field: EXPENSE_FIELDS.FORECAST,
                    format: "$0,0.00",
                  })}
                </span>
              </span>
              <span
                id="totalFooterField"
                className="totals-footer"
                style={{ padding: editingExpense ? 0 : "" }}
              >
                {editingExpense ? (
                  <TextField
                    id="expense-total-actual"
                    className="expense-actual"
                    inputProps={{ className: "expense-date-input" }}
                    style={{ textAlign: "right" }}
                    onClick={(ev) => {
                      ev.preventDefault();
                      ev.stopPropagation();
                    }}
                    type="number"
                    onChange={(ev) => {
                      const value = ev.target.value || 0;
                      setTotalActual(value ? +value : value);
                    }}
                    value={totalActual}
                  />
                ) : (
                  getTotal({
                    list: currentExpense.items,
                    field: EXPENSE_FIELDS.ACTUAL,
                    format: "$0,0.00",
                  })
                )}
              </span>
              <span className="totals-footer-icons">
                {isOneItem ? null : editingExpense ? (
                  <>
                    <AddButton />
                    <SaveButton />
                  </>
                ) : (
                  !editing &&
                  displayOptions({
                    expense: currentExpense,
                    type: DISPLAY_OPTIONS_TYPE.EDIT_DELETE,
                  })
                )}
              </span>
            </div>
          )}
          {editingExpense &&
            !isOneItem &&
            getTotalFixed({
              field: EXPENSE_FIELDS.ACTUAL,
              list: currentExpense.items,
              value: totalActual,
            }) !== 0 && (
              <div className="expense-itemRow" id="totals-content">
                <div style={{ gridColumn: "span 5" }} />
                <span
                  id="totalFooterText"
                  className="totals-footer"
                  style={{
                    gridColumn: "span 2",
                    gap: 15,
                    color: colors.dangerRed,
                  }}
                >
                  Unallocated:
                  <span style={{ minWidth: 150, textAlign: "end" }}>
                    {numeral(
                      getTotalFixed({
                        field: EXPENSE_FIELDS.ACTUAL,
                        list: currentExpense.items,
                        value: totalActual,
                      })
                    ).format("$0,0.00")}
                  </span>
                </span>

                <span className="copy-total-container">
                  <Button
                    className="editing-expense-button-to-save"
                    style={{
                      cursor: "pointer",
                      margin: "0px 4px",
                      fontSize: 11,
                      height: "inherit",
                      padding: 2,
                      background: colors.dangerRed,
                    }}
                    onClick={async () => {
                      const total = +getTotal({
                        list: currentExpense.items,
                        field: EXPENSE_FIELDS.ACTUAL,
                        format: false,
                      }).toFixed(2);
                      setTotalActual(total);
                    }}
                  >
                    Set Total
                  </Button>
                </span>
              </div>
            )}
          {editingExpense &&
            currentExpense.id === editing.id &&
            currentExpense.pdfURL && (
              <div
                className={
                  pdfPosition === PDF_POSITION.LEFT ? "PDFViewer-empty" : ""
                }
              ></div>
            )}
        </AccordionSummary>
      </Accordion>
      {editingExpense &&
        currentExpense.id === editing.id &&
        currentExpense.pdfURL && (
          <div
            className={
              pdfPosition === PDF_POSITION.BOTTOM
                ? "PDFViewer-container-bottom"
                : "PDFViewer-container-left" + withRef
            }
          >
            <button
              className="button-to-change-position"
              onClick={() => {
                setPdfPosition(
                  pdfPosition === PDF_POSITION.BOTTOM
                    ? PDF_POSITION.LEFT
                    : PDF_POSITION.BOTTOM
                );
              }}
            >
              {pdfPosition === PDF_POSITION.BOTTOM
                ? "Move to left"
                : "Move to bottom"}
            </button>
            <iframe
              title="pdf"
              src={currentExpense.pdfURL}
              className={"expense-pdf"}
            />
          </div>
        )}

      {showPDF && (
        <div className="pdf-viewer-container">
          <CancelIcon
            svgClass="cancel-icon-pdf"
            onClick={() => setShowPDF(false)}
          />
          <iframe
            title="pdf"
            src={currentExpense.pdfURL}
            className={"expense-pdf"}
          />
        </div>
      )}
    </React.Fragment>
  );
}

export default ExpenseItemComponent;
