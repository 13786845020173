import React, { useState } from "react";
import AttentionModal from "./AttentionModal";
import {
  generateActivityEntryForNonMoveTasksDueDates,
  handleBadgeMoveDependencyTasks,
  onMovingTasks,
  updateTaskNotifications,
} from "../../container/TaskListScreen/TaskScreenHelper";
import { useCompanyUsers, useUser } from "../../hooks/user";
import { triggerTaskTypes } from "../../helpers/tasks";
import { updateDoc } from "firebase/firestore";
import { MOVE_TASK_TYPES } from "../../helpers/constants";

function RemainingBadgeModal({
  open = false,
  task = {},
  taskDayOffset = 0,
  actionType = "",
  onCloseModal = () => {},
  mainEntity = {},
  parentCollection = "",
}) {
  const [dayOffset, setDayOffset] = useState(taskDayOffset);
  const user = useUser();
  const companyUsers = useCompanyUsers({});
  const { companyId } = user;

  const onClick = () => {
    if (actionType === MOVE_TASK_TYPES.BADGE_REMAINIG_DAYS) {
      // CLICKING ON BADGE
      handleBadgeMoveDependencyTasks({
        companyId,
        companyUsers,
        currentUser: user,
        remainingOffset: dayOffset,
        dayOffset: taskDayOffset,
        task,
        mainEntity,
        parentCollection,
      });
    } else if (actionType === MOVE_TASK_TYPES.START_DUE_DATE_CHANGE) {
      // CLICKING ON THE CALENDAR
      onMovingTasks({
        accepted: true,
        companyId,
        companyUsers,
        currentUser: user,
        dayOffsetRemainigTasks: dayOffset,
        dayOffsetTask: taskDayOffset,
        mainEntity,
        task,
        parentCollection,
      });
    }
    onCloseModal();
  };

  const onClose = () => {
    if (actionType === MOVE_TASK_TYPES.BADGE_REMAINIG_DAYS) {
      generateActivityEntryForNonMoveTasksDueDates({
        task,
        companyId,
        currentOrder: mainEntity,
        orderDB: parentCollection,
        user,
      });
      updateDoc(task.ref, {
        moved: true,
        triggerType: triggerTaskTypes.MOVED_REMAINING_TASK,
      });
      updateTaskNotifications(task.notificationId);
    } else if (actionType === MOVE_TASK_TYPES.START_DUE_DATE_CHANGE) {
      onMovingTasks({
        accepted: false,
        companyId,
        companyUsers,
        currentUser: user,
        dayOffsetRemainigTasks: dayOffset,
        dayOffsetTask: taskDayOffset,
        mainEntity,
        task,
        parentCollection,
      });
    }
    onCloseModal();
  };

  const modalDescription = () => (
    <span className="task-sheet-modal-input">
      Move all dependant tasks
      <input
        style={{ width: 54, margin: "0 8px", textAlign: "center" }}
        value={Math.abs(dayOffset)}
        max="365"
        type="number"
        id="task-shit-modal-input"
        onChange={(ev) => {
          const value = ev.target.value;
          if (value > 0) {
            const parseValue = value > 364 ? dayOffset : value;
            setDayOffset(parseValue);
          } else {
            setDayOffset(1);
          }
        }}
      />
      {dayOffset > 1 ? "days " : "day "}
      <span
        style={{
          color: taskDayOffset < 0 ? "green" : "red",
        }}
      >
        {taskDayOffset < 0 ? "earlier?" : "later?"}
      </span>
    </span>
  );

  return (
    <AttentionModal
      isOpen={open}
      title="Attention"
      cancellable
      description={modalDescription()}
      onClick={onClick}
      onClose={onClose}
      confirmationText="Yes"
    />
  );
}

export default RemainingBadgeModal;
