import React from "react";
import { WeeklyViewContainerStyled } from "./styles";

function WeeklyContent({ children, isExpand }) {
  return (
    <WeeklyViewContainerStyled
      className={"weeklyViewContainer"}
      style={{
        flexDirection: "row",
        overflow: isExpand ? "unset" : "auto",
        height: isExpand ? "calc(90vh - 240px)" : "calc(90vh - 222px)",
        minHeight: isExpand ? "calc(90vh - 240px)" : "calc(90vh - 222px)",
        width: isExpand ? "calc(100vw - 144px)" : "",
        minWidth: isExpand ? "calc(100vw - 144px)" : "",
      }}
      id="weeklyContentId"
    >
      {children}
    </WeeklyViewContainerStyled>
  );
}

export default WeeklyContent;
