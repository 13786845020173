import React, { useCallback, useState } from "react";
import {
  Badge,
  Button,
  ClickAwayListener,
  List,
  ListItem,
  Radio,
  RadioGroup,
} from "@mui/material";
import { ListItemButtonStyle, SearchDrawerStyle } from "./styles";
import {
  CloseIcon,
  InfoIcon,
  SearchDrawerIcon,
} from "../../helpers/iconSvgPath";
import TooltipTD from "../Tooltip/TooltipTD";
import SearchBox from "../SearchBox/SearchBox";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import {
  getFunctionByName,
  globalEnvironment,
} from "../../constants/globalVariables";
import { useUser } from "../../hooks/user";
import LoadingBackdrop from "../WholeScreenFocusBackdrop/LoadingBackdrop";
import { getVerificationPath, optionsSearch } from "./sidebarHelper";
import MainTableRefactor from "../filterTableRefactor/MainTableRefactor";
import { sortObjectsBy } from "../../helpers/helpers";
import { SearchTableContainer } from "../../container/SalesOrdersScreen/styles";
import SearchTableRow from "./SearchTableRow";
import Mark from "mark.js";
import { useNavigate } from "react-router-dom";
import { cx } from "@emotion/css";
import { setGlobalBackdrop } from "../../actions/DataActions";
import { useDispatch } from "react-redux";
import { BACKDROP_TYPE } from "../../helpers/constants";
import { SALES_ORDER_FIELDS } from "../../helpers/salesOrder";
import { usePermissions } from "../../hooks/permissions";
import userTypes from "../../api/types/userTypes";
import { useFactories } from "../../hooks/factories";
import { useCustomers } from "../../hooks/customers";

function SearchButtonDrawer() {
  const dispatch = useDispatch();
  const [table, setTable] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [sortFilter, setSortFilter] = useState({});
  const factories = useFactories();
  const customers = useCustomers();
  const user = useUser();
  const permissions = usePermissions();
  const navigate = useNavigate();
  const setBackdrop = useCallback((id, type = BACKDROP_TYPE.METADATA) =>
    setGlobalBackdrop({
      type,
      isOpen: true,
      id,
    })(dispatch)
  );

  const getQueryData = async () => {
    if (search.length < 3 || !selectedValue.scope || loading) {
      return;
    }
    setLoading(true);
    try {
      const functions = getFunctions();
      const searchBigQuerEndpoint = httpsCallableFromURL(
        functions,
        getFunctionByName({
          name: "searchInBigQuery",
          env: globalEnvironment,
        })
      );
      const result = await searchBigQuerEndpoint({
        scope: selectedValue.scope,
        searchBy: selectedValue.value,
        searchTerm: search,
        userId: user.id,
      });
      const { rows = [] } = result.data;
      if (rows.length === 1) {
        const [item] = rows;
        navigate(
          getVerificationPath({
            id: item.id,
            scope: selectedValue.scope,
          })
        );
        setLoading(false);
        onClose({ value: selectedValue.value, scope: selectedValue.scope });
        return;
      }

      setShowTable(true);
      setTable(
        rows.map((item) => ({
          ...item,
          customerName: (
            customers.find((customer) => customer.id === item.customerId) || {}
          ).name,
          vendorName: (
            factories.find((factory) => factory.id === item.factoryId) || {}
          ).name,
        }))
      );
      setLoading(false);
      setTimeout(() => {
        performMark(search);
      }, 300);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const onClose = ({ value, scope }) => {
    if (!loading) {
      if (value) {
        const isFieldNumber = value === SALES_ORDER_FIELDS.NUMBER;
        setBackdrop(
          isFieldNumber ? scope : value,
          isFieldNumber ? BACKDROP_TYPE.NAV_NUMBER : BACKDROP_TYPE.METADATA
        );
      }
      setOpenDrawer(false);
      setTable([]);
      setSearch("");
      setSelectedValue({});
      setShowTable(false);
    }
  };
  const markInstance = new Mark(document.getElementById("tableList"));
  function performMark(keyword = "") {
    var options = {
      separateWordSearch: false,
      diacritics: false,
      debug: false,
      acrossElements: true,
      accuracy: "partially",
      exclude: [".headerLabel", ".headerLabel > *"],
    };
    markInstance.unmark({
      done: () => {
        markInstance.mark(keyword, options);
      },
    });
  }

  const optionsFilter = optionsSearch.filter((option) => {
    const role = user.role;
    if (
      role === userTypes.SUPER_ADMIN ||
      role === userTypes.TRADEDASH_EMPLOYEE
    ) {
      return true;
    }
    const permission =
      permissions.find((permission) => permission.id === option.permission) ||
      {};
    return permission.enabled;
  });

  return (
    <div style={{ marginTop: "auto" }}>
      <ClickAwayListener
        onClickAway={() => {
          if (openDrawer) {
            onClose({});
          }
        }}
      >
        <SearchDrawerStyle
          className="drawerContainer"
          open={openDrawer}
          onClose={onClose}
          transitionDuration={800}
          onKeyDown={(ev) => {
            if (ev.key === "Enter") {
              getQueryData();
            }
          }}
        >
          <TooltipTD className="closeModal" label={"Close"}>
            <CloseIcon onClick={onClose} />
          </TooltipTD>
          <div className="container">
            {loading && (
              <LoadingBackdrop withLogo styles={{ marginTop: 105 }} />
            )}
            <span className="title">I am looking for a:</span>
            <List>
              <RadioGroup value={selectedValue} className="radioGroup">
                {optionsFilter.map((option) => (
                  <ListItem
                    key={option.scope + option.id}
                    onClick={() => {
                      setSelectedValue(option);
                      setSortFilter({
                        orderBy: "ASC",
                        sortedColumn: option.referenceSorting,
                      });
                      setTable([]);
                    }}
                    className="item"
                  >
                    <Radio
                      checked={selectedValue.id === option.id}
                      value={option}
                      size="small"
                      className="radioIcon"
                      classes={{ checked: "checkedClass" }}
                    />
                    <span className="labelText">{option.label}</span>
                    {option.detail && (
                      <span className="detailText">({option.detail})</span>
                    )}
                    <TooltipTD className="infoIcon" label={option.tooltipText}>
                      <InfoIcon />
                    </TooltipTD>
                  </ListItem>
                ))}
              </RadioGroup>
            </List>
            <div className="searchContainer">
              <SearchBox
                placeholder={
                  selectedValue.searchText ||
                  "Make selection above for the type of search first"
                }
                value={search}
                hasBeenReseted={() => {
                  setSearch("");
                }}
                style={{ width: "100%", minWidth: 300 }}
                autoFocus
                onChange={(ev) => setSearch(ev.target.value)}
              />
              <Button
                className={"searchButton"}
                onClick={getQueryData}
                disabled={search.length < 3 || !selectedValue.scope}
              >
                Search
              </Button>
            </div>

            <SearchTableContainer className="contentList" id="tableList">
              {showTable && selectedValue.headers && (
                <MainTableRefactor
                  bodyTableId="seachTableBody"
                  headCells={selectedValue.headers.sort(
                    sortObjectsBy("index", false)
                  )}
                  bodyRows={table.sort(
                    sortObjectsBy(
                      sortFilter.sortedColumn,
                      sortFilter.orderBy === "ASC"
                    )
                  )}
                  emptyText={"No result found"}
                  nonLookUpfilters={sortFilter}
                  filters={sortFilter}
                  onUpdateFilters={(nonLookUpfilters) => {
                    setSortFilter({ ...nonLookUpfilters });
                    setTimeout(() => {
                      performMark(search);
                    }, 300);
                  }}
                  renderRow={(item, index) => {
                    return (
                      <SearchTableRow
                        key={item.id + index}
                        item={item}
                        user={user}
                        headers={selectedValue.headers.sort(
                          sortObjectsBy("index", false)
                        )}
                        scope={selectedValue.scope}
                        onClose={({ addBackdrop = true }) => {
                          onClose({
                            value: addBackdrop ? selectedValue.value : "",
                            scope: selectedValue.scope,
                          });
                        }}
                      />
                    );
                  }}
                />
              )}
            </SearchTableContainer>
          </div>
        </SearchDrawerStyle>
      </ClickAwayListener>
      <ListItemButtonStyle
        component="li"
        onClick={() => {
          if (optionsFilter.length === 0) {
            return;
          }
          if (openDrawer) {
            setOpenDrawer(false);
          } else {
            setTimeout(() => setOpenDrawer(true), 100);
          }
        }}
        className={cx("listButtonContainer", { openButton: openDrawer })}
        style={{
          display: optionsFilter.length === 0 ? "none" : "",
        }}
      >
        <Badge
          className="searchBadge"
          overlap="circular"
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <SearchDrawerIcon height={20} width={18} />
        </Badge>
        <span className="menu">Search</span>
        {openDrawer && <div className="blue-bar" />}
      </ListItemButtonStyle>
    </div>
  );
}

export default SearchButtonDrawer;
