import React, { useState, useEffect } from "react";
import CustomModal from "../Modal/Modal";
import CreateReportForm from "./CreateReportForm";
import IntlMessages from "../../util/IntlMessages";
import { REPORTS_TYPE } from "../../helpers/constants";

const typeReport = {
  [REPORTS_TYPE.REPORT]: "Report",
  [REPORTS_TYPE.FOLDER]: "Folder",
};

function CreateReport({
  openModal,
  handleClose,
  report,
  onSubmit,
  isUpdating,
  user = {},
  type = REPORTS_TYPE.REPORT,
  companyUsers,
  permissionGroups,
}) {
  const [currentReport, setCurrentReport] = useState(null);
  useEffect(() => {
    setCurrentReport(report);
  }, [report]);

  function onChange({ value }) {
    setCurrentReport({ ...value });
  }

  if (!currentReport) {
    return null;
  }

  return (
    <CustomModal
      header={
        isUpdating ? (
          <IntlMessages id={`modal.settings.update${typeReport[type]}`} />
        ) : (
          <IntlMessages id={`modal.settings.create${typeReport[type]}`} />
        )
      }
      isOpen={openModal}
      onClose={handleClose}
      modalStyles={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        padding: "32px 8px",
      }}
    >
      <CreateReportForm
        report={currentReport}
        onChange={onChange}
        onSubmit={({ currentReport }) => {
          onSubmit({ currentReport });
        }}
        handleClose={handleClose}
        companyUsers={companyUsers}
        permissionGroupsDB={permissionGroups}
        isUpdating={isUpdating}
        user={user}
        type={typeReport[type]}
        isRoot={report.isRoot}
        parentPermission={report.parentPermission}
      />
    </CustomModal>
  );
}

export default CreateReport;
