
const shippingTerms = {
  EXW: "Ex Works",
  FCA: "Free Carrier",
  CPT: "Carriage Paid To",
  CIP: "Carriage and Insurance Paid To",
  DAT: "Delivered at Terminal",
  DAP: "Delivered at Place",
  DDP: "Delivered Duty Paid",
  FAS: "Free Alongside Ship",
  FOB: "Free on Board",
  CFR: 'Cost and Freight',
  CIF: 'Cost,Insurance and Freight'
}

export default shippingTerms;