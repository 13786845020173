import React from "react";
import {
  HugeLetterIcon,
  LargeLetterIcon,
  MidLetterIcon,
  SmallLetterIcon,
} from "../ToolbarButtons";
import { FONT_STYLE_NAME, StyleButton } from "../ComponentHelper";
import HeadLine from "../../../assets/toolbarIcon/HeadLine.svg";
import ArrowDown from "../../../assets/toolbarIcon/ArrowDown.svg";
import { ClickAwayListener } from "@mui/material";

const HEAD_SIZE = [
  { label: "H4", style: FONT_STYLE_NAME.NORMAL, icon: <SmallLetterIcon /> },
  { label: "H3", style: FONT_STYLE_NAME.MEDIUM, icon: <MidLetterIcon /> },
  { label: "H2", style: FONT_STYLE_NAME.LARGE, icon: <LargeLetterIcon /> },
  { label: "H1", style: FONT_STYLE_NAME.HUGE, icon: <HugeLetterIcon /> },
];

export const FontStyleControls = (props) => {
  const { openList, handleOpenList, classes, editorRef } = props;
  const currentStyle = props.editorState.getCurrentInlineStyle();

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (openList) {
          handleOpenList(false);
        }
      }}
    >
      <div
        className={classes}
        onClick={(ev) => {
          editorRef.current.focus();
          handleOpenList(!openList);
          ev.stopPropagation();
        }}
        style={{ background: openList ? "#efefef" : "" }}
      >
        <img width={22} src={HeadLine} alt="hl" />
        <img src={ArrowDown} alt="ad" />
        {openList && (
          <div className={"containerHeadline"}>
            {HEAD_SIZE.map((type, i) => (
              <div
                onClick={(ev) => {
                  ev.preventDefault();
                  handleOpenList(false);
                  setTimeout(() => {
                    props.onToggle(type.style);
                  }, 50);
                  ev.stopPropagation();
                }}
                key={type.label}
              >
                <StyleButton
                  key={i}
                  active={currentStyle.has(type.style)}
                  label={type.label}
                  icon={type.icon}
                  onToggle={() => {}}
                  style={type.style}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};
