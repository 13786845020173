import React from "react";
import Bold from "../../assets/toolbarIcon/Bold.svg";
import Italic from "../../assets/toolbarIcon/Italic.svg";
import Underline from "../../assets/toolbarIcon/Underline.svg";
import BulletList from "../../assets/toolbarIcon/BulletList.svg";
import NumberList from "../../assets/toolbarIcon/NumberedList.svg";
import AlignLeft from "../../assets/toolbarIcon/AlignLeft.svg";
import AlignCenter from "../../assets/toolbarIcon/AlignCenter.svg";
import AlignRight from "../../assets/toolbarIcon/AlignRight.svg";
import { FONTSIZE_NOTES } from "../../helpers/constants";

export const BoldIcon = () => <img style={{ width: 17 }} src={Bold} alt="B" />;
export const ItalicIcon = () => (
  <img style={{ width: 17 }} src={Italic} alt="I" />
);
export const UnderlineIcon = () => (
  <img style={{ width: 17 }} src={Underline} alt="U" />
);
export const UnorderListIcon = () => (
  <img style={{ width: 17 }} src={BulletList} alt="BL" />
);
export const OrderListIcon = () => (
  <img style={{ width: 17 }} src={NumberList} alt="NL" />
);
export const HugeLetterIcon = () => (
  <span style={{ fontSize: FONTSIZE_NOTES.HUGE, fontWeight: 500 }}>Huge</span>
);
export const LargeLetterIcon = () => (
  <span style={{ fontSize: FONTSIZE_NOTES.LARGE, fontWeight: 500 }}>Large</span>
);
export const MidLetterIcon = () => (
  <span style={{ fontSize: FONTSIZE_NOTES.MEDIUM, fontWeight: 500 }}>Medium</span>
);
export const SmallLetterIcon = () => (
  <span style={{ fontSize: FONTSIZE_NOTES.NORMAL, fontWeight: 500 }}>Normal</span>
);
export const AlignLeftIcon = () => (
  <img style={{ width: 17, height: 16 }} src={AlignLeft} alt="AL" />
);
export const AlignCenterIcon = () => (
  <img style={{ width: 17, height: 16 }} src={AlignCenter} alt="AC" />
);
export const AlignRightIcon = () => (
  <img style={{ width: 17, height: 16 }} src={AlignRight} alt="AR" />
);
