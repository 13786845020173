import { screenHeight } from "../../helpers/constants";
import { colors } from "../../assets/jss/variables";
import { styled } from "@mui/material";

export const WeeklyViewStyled = styled("div")(() => ({
  "&.weeklyViewContainer": {
    minHeight: screenHeight,
    position: "relative",
    margin: 16,
    backgroundColor: "#F6FBFF",
  },
  "& .filtersContent": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "7px 0px 23px 0px",
  },
  "& .searchboxContainer": {
    height: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  "& .weekend": {
    height: "100%",
    backgroundColor: "white",
    width: 20,
    textOrientation: "sideways",
    writingMode: "vertical-rl",
    letterSpacing: 20,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingTop: 24,
  },
  "& .filtersContainer": {
    display: "flex",
    flex: "1 1 0%",
    alignItems: "center",
    justifyContent: "space-between",
    "& .resetButton": {
      display: "flex",
      fontFamily: "Roboto",
      fontSize: "12px",
      lineHeight: "13px",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      height: "40px",
      width: "120px",
      backgroundColor: "rgba(255, 51, 102, 0.07)",
      border: "1px solid #FF7495 !important",
      boxSizing: "border-box",
      borderRadius: "4px",
      textTransform: "uppercase",
      color: colors.primaryDark,
      position: "relative",
      fontWeight: 400,
      padding: "14px 16px",
      right: "0px",
      transform: "unset !important",
      "@media screen and (max-width: 1425px)": {
        minWidth: 50,
        width: 50,
        maxWidth: 50,
        right: -10,
      },
      "&:hover": {
        backgroundColor: "rgba(255,51,102,0.2)",
      },
      "& .MuiTouchRipple-child": {
        backgroundColor: "rgba(255,51,102,0.4)",
      },
    },
    "& .buttons": {
      display: "flex",
      alignItems: "center",
      position: "relative",
      left: "12px",
      gap:16
    },
    "& .boxed-filter-resized": {
      userSelect: "none",
      height: 40,
      minWidth: 53,
      // borderRadius: 5,
      border: "1px solid #d2dfec",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      "& span": {
        margin: "8px 16px",
        color: colors.primaryDark,
        fontWeight: 400,
      },
      "&.active": {
        backgroundColor: " #39F",
        color: "white",
        "& span": {
          color: "white",
        },
      },
    },
  },
  "& .actionContainer": {
    position: "absolute",
    zIndex: 200,
    right: 72,
    marginTop: 16,
  },
  "& .buttonNavigator": {
    marginRight: "auto",
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    fontSize: 11,

    "&.arrowIconPrev": {
      width: "14px !Important",
      height: "12px !Important",
      transform: "rotateY(180deg)",
    },
    "&.arrowIconNext": {
      width: "14px !Important",
      height: "12px !Important",
    },
  },
  "& .buttonNavigatorToday": {
    fontSize: 11,
  },
  "& .emptyMessage": {
    position: "absolute",
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 50,
  },
  "& .boxedFilter": {
    userSelect: "none",
    height: 40,
    minWidth: 53,
    borderRadius: 5,
    border: "1px solid #d2dfec",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    cursor: "pointer",
    marginRight: 16,
    padding: "0 8px",
    "& span": {
      margin: "8px 16px",
      color: colors.primaryDark,
      fontWeight: 400,
    },
    "&.active": {
      backgroundColor: colors.radioCheck,
      color: "white",
      "& span": {
        color: "white",
      },
    },
  },
  "& .weekPage": {
    display: "flex",
    minWidth: 364,
    minHeight: 40,
    justifyContent: "space-between",
    fontSize: 11,
    textTransform: "uppercase",
    backgroundColor: "#F6FAFF",
    "& > div": {
      flex: 1,
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      padding: 8,
      userSelect: "none",
      transition: "all 500ms",
      "& span": {
        margin: "8px 16px",
        color: colors.primaryDark,
        fontWeight: 400,
      },
      "&.active": {
        backgroundColor: colors.radioCheck,
        color: "white",
        fontWeight: "bold",
        "& span": {
          color: "white",
        },
      },
      "&.disabled": {
        opacity: 0.6,
      },

      "&:first-child": {
        border: "1px solid " + colors.primaryLight,
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        color: colors.primaryDark,
        "&:hover": {
          cursor: "pointer",
          color: "white",
          backgroundColor: colors.radioCheck,
        },
      },
      "&:nth-child(2)": {
        borderLeft: "transparent",
        borderRight: "transparent",
        borderTop: "1px solid " + colors.primaryLight,
        borderBottom: "1px solid " + colors.primaryLight,
        color: colors.primaryDark,
        "&:hover": {
          cursor: "pointer",
          color: "white",
          backgroundColor: colors.radioCheck,
        },
      },
      "&:last-child": {
        border: "1px solid " + colors.primaryLight,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        color: colors.primaryDark,
        "&:hover": {
          cursor: "pointer",
          color: "white",
          backgroundColor: colors.radioCheck,
        },
      },
    },
    "& img": {
      marginBottom: 2,
      width: 10,
      height: 10,
      marginLeft: 12,
      marginRight: 12,
    },
    borderRadius: 3,
    // border: "1px solid " + colors.primaryLight
  },
  "& .weekPageResized": {
    display: "flex",
    minWidth: 250,
    minHeight: 40,
    justifyContent: "space-between",
    fontSize: 11,
    textTransform: "uppercase",
    backgroundColor: "#F6FAFF",
    "& > div": {
      flex: 1,
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      padding: 8,
      userSelect: "none",
      transition: "all 500ms",
      "& span": {
        margin: "8px 16px",
        color: colors.primaryDark,
        fontWeight: 400,
      },
      "&.active": {
        backgroundColor: colors.radioCheck,
        color: "white",
        fontWeight: "bold",
        "& span": {
          color: "white",
        },
      },
      "&.disabled": {
        opacity: 0.6,
      },

      "&:first-child": {
        borderRight: "1px solid " + colors.primaryLight,
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        color: colors.primaryDark,
        "&:hover": {
          cursor: "pointer",
          color: "white",
          backgroundColor: colors.radioCheck,
          border: "1px solid #5D92F4",
        },
      },
      "&:nth-child(2)": {
        borderLeft: "transparent",
        borderRight: "transparent",
        color: colors.primaryDark,
        "&:hover": {
          cursor: "pointer",
          color: "white",
          backgroundColor: colors.radioCheck,
          border: "1px solid #5D92F4",
        },
      },
      "&:last-child": {
        borderLeft: "1px solid " + colors.primaryLight,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        color: colors.primaryDark,
        "&:hover": {
          cursor: "pointer",
          color: "white",
          backgroundColor: colors.radioCheck,
          border: "1px solid #5D92F4",
        },
      },
    },
    "& img": {
      marginBottom: 2,
      width: 10,
      height: 10,
      marginLeft: 12,
      marginRight: 12,
    },
    borderRadius: 3,
  },
  "& .contentContainer": {
    height: "calc(90vh - 78px)",
    width: "auto",
    backgroundColor: "white",
    boxShadow: "-1px 2px 4px 2px #ccc",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  },
  "& .contentContainerExpanded": {
    height: "calc(90vh - 78px)",
    marginTop: 16,
    width: "auto",
    backgroundColor: "white",
    boxShadow: "-1px 2px 4px 2px #ccc",
    display: "flex",
    flexDirection: "column",
  },
}));
