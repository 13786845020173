import { getRandomId } from "../../helpers/helpers";
import { now } from "moment";

export default class GLAccountItemModel {
  constructor({
    id = getRandomId(),
    creationDate = now(),
    name = "",
    description = "",
    active = true,
    summaryId = "",
  }) {
    this.id = id;
    this.creationDate = creationDate;
    this.name = name;
    this.description = description;
    this.active = active;
    this.summaryId = summaryId;
  }
}
