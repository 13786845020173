import { Popover, Snackbar, styled } from "@mui/material";
import AppConfig from "../../constants/AppConfig";
import { colors } from "../../assets/jss/variables";
import { documentScope } from "../../helpers/documents";

export const ManagementDocumentsStyled = styled("div")(() => ({
  "&.documents-panel-container": {
    overflow: "scroll",
    maxHeight: "100%",
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent",
      width: 16,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "transparent",
      borderRadius: 7,
    },
    "&::-webkit-scrollbar-track": {
      border: " solid 1px transparent",
    },
    "&:hover": {
      "&::-webkit-scrollbar-thumb": {
        boxShadow: `inset 0 0 10px 10px ${colors.thumbScrollColor}`,
        borderBlock: "1px solid transparent",
        borderInline: "5px solid transparent",
      },
    },
    "& .stickyHeader": {
      position: "absolute",
      top: 20,
      width: "100%",
    },
    "& .documentsList": {
      padding: "0px 0px 0px 36px",
      "& .dividerPlaceholderDocuments": {
        width: "calc(100% - 36px)",
        paddingBlock: 8,
        "& .container": {
          padding: 0,
        },
      },
    },
    "& .document-folder-container": {
      position: "relative",
      marginBottom: 16,
      // add before styles a div on left and on bottom
      "&:before": {
        content: "''",
        position: "absolute",
        top: 72,
        left: 33,
        width: 1,
        height: "calc(100% - 82px)",
        background: colors.dividerColor,
      },
      "&:after": {
        content: "''",
        position: "absolute",
        left: 34,
        width: "calc(100% - 68px)",
        height: 1,
        background: colors.dividerColor,
      },
    },
  },
}));

export const ListItemDocumentStyled = styled("div")(() => ({
  "&.listItemDocumentContainer": {
    position: "relative",
    display: "flex",
    "&:hover": {
      "& .document-options-container": {
        width: "auto",
        transition: "all 0.6s ease",
        opacity: 1,
      },
    },
    "& .checkbox-folder-root": {
      position: "absolute",
      left: 29.5,
      top: 38.5,
      zIndex: 11,
      padding: 2,
      "& svg": {
        width: 15,
        height: 15,
      },
    },

    "& .document-child-checkbox": {
      position: "absolute",
      left: -29,
      top: 38,
      padding: 2,
      "& svg": {
        width: 15,
        height: 15,
      },
    },
    "& .listDocumentsEffect": {
      "& .img-menu-options": {
        opacity: 0,
        cursor: "pointer",
      },
      "&:hover": {
        borderBottom: 0,
        "& .img-menu-options": {
          opacity: 1,
        },
        "& .shipmentBadgeDocument": {
          border: "2px solid #CB5EDE",
        },
        "& .fileinfo": {
          fontWeight: 500,
        },
        "& .filename": {
          fontWeight: 700,
        },
      },
    },

    "& .listemItemDocument": {
      height: 78,
      margin: "10px 0px",
      borderRadius: 4,
      // border: "2px solid #e5edf7",
      width: "calc(100% - 36px)",
      "&:hover": {
        borderRadius: 4,
        "& .document-name": {
          fontWeight: "700 !Important",
        },
        "& .document-creation-date-content": {
          fontWeight: 500,
        },
        "& .document-created-by-content": {
          fontWeight: 500,
        },
        "& .versionLabel": {
          fontWeight: 600,
        },
        [`& .badge${documentScope.SALES_ORDER}`]: {
          background: colors.salesOrderHoverBackgroundColor,
          outline: `3px solid ${colors.salesOrderBorderColor}`,
        },
        [`& .badge${documentScope.SALES_ORDER}_selected`]: {
          background: colors.salesOrderSelectedBackgroundColor,
          outline: `3px solid ${colors.salesOrderSelectedBorderColor}`,
        },
        [`& .badge${documentScope.PURCHASE_ORDER}`]: {
          background: colors.purchaseOrderHoverBackgroundColor,
          outline: `3px solid ${colors.purchaseOrderBorderColor}`,
        },
        [`& .badge${documentScope.PURCHASE_ORDER}_selected`]: {
          background: colors.purchaseOrderSelectedBackgroundColor,
          outline: `3px solid ${colors.purchaseOrderSelectedBorderColor}`,
        },
        [`& .badge${documentScope.SHIPMENT}`]: {
          background: colors.shipmentHoverBackgroundColor,
          outline: `3px solid ${colors.shipmentBorderColor}`,
        },
        [`& .badge${documentScope.SHIPMENT}_selected`]: {
          background: colors.shipmentSelectedBackgroundColor,
          outline: `3px solid ${colors.shipmentSelectedBorderColor}`,
        },
        [`& .badge${documentScope.CUSTOMER}`]: {
          background: colors.purchaseOrderHoverBackgroundColor,
          outline: `3px solid ${colors.purchaseOrderBorderColor}`,
        },
        [`& .badge${documentScope.CUSTOMER}_selected`]: {
          background: colors.purchaseOrderSelectedBackgroundColor,
          outline: `3px solid ${colors.purchaseOrderSelectedBorderColor}`,
        },
        [`& .badge${documentScope.FACTORY}`]: {
          background: colors.purchaseOrderHoverBackgroundColor,
          outline: `3px solid ${colors.purchaseOrderBorderColor}`,
        },
        [`& .badge${documentScope.FACTORY}_selected`]: {
          background: colors.purchaseOrderSelectedBackgroundColor,
          outline: `3px solid ${colors.purchaseOrderSelectedBorderColor}`,
        },
      },

      "& .listItemFolderClose": {
        border: "1px solid #e5edf7",
        borderRadius: 4,
        marginLeft: 0,
        paddingLeft: 0,
        paddingRight: 0,
        "&:hover": {
          outline: "4px solid #698EC5",
          outlineOffset: -2,
          borderRadius: 4,
        },
        height: 75,
        margin: "10px 0px",
      },

      "& .listItemFolderOpen": {
        marginTop: 10,
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 8,
        "&:hover": {
          outline: "4px solid #698EC5",
          outlineOffset: -2,
          borderRadius: 4,
        },
        height: 75,
      },
      "& .listItemFolderCloseMainFile": {
        borderBottom: `1px solid ${AppConfig.themeColors.dividerColor}`,
        marginLeft: 0,
        paddingLeft: 0,
        paddingRight: 0,
        "&:hover": {
          outline: "4px solid #698EC5",
          outlineOffset: -2,
          borderRadius: 4,
        },
        height: 75,
        margin: "10px 0px",
      },
    },
    "& .document-options-container": {
      transition: "all 0.3s ease",
      opacity: 0,
      margin: "auto",
      display: "grid",
      gap: 8,
      width: "auto",
      padding: "0px 6px",
      "& .previewIcon": {
        width: 20,
        height: 20,
        "&:hover": {
          cursor: "pointer",
        },
      },
      "& .actionIcon_sales_orders": {
        width: 20,
        height: 20,
        fill: colors.salesOrderBorderColor,
        "&:hover": {
          cursor: "pointer",
        },
      },
      "& .actionIcon_selected_sales_orders": {
        width: 20,
        height: 20,
        fill: colors.salesOrderSelectedBorderColor,
        "&:hover": {
          cursor: "pointer",
        },
      },
      "& .actionIcon_purchase_orders": {
        width: 20,
        height: 20,
        fill: colors.purchaseOrderBorderColor,
        "&:hover": {
          cursor: "pointer",
        },
      },
      "& .actionIcon_selected_purchase_orders": {
        width: 20,
        height: 20,
        fill: colors.purchaseOrderSelectedBorderColor,
        "&:hover": {
          cursor: "pointer",
        },
      },
      "& .actionIcon_shipments": {
        width: 20,
        height: 20,
        fill: colors.shipmentBorderColor,
        "&:hover": {
          cursor: "pointer",
        },
      },
      "& .actionIcon_selected_shipments": {
        width: 20,
        height: 20,
        fill: colors.shipmentSelectedBorderColor,
        "&:hover": {
          cursor: "pointer",
        },
      },
      "& .actionIcon_customers": {
        width: 20,
        height: 20,
        fill: colors.purchaseOrderBorderColor,
        "&:hover": {
          cursor: "pointer",
        },
      },
      "& .actionIcon_selected_customers": {
        width: 20,
        height: 20,
        fill: colors.purchaseOrderSelectedBorderColor,
        "&:hover": {
          cursor: "pointer",
        },
      },
      "& .actionIcon_factories": {
        width: 20,
        height: 20,
        fill: colors.purchaseOrderBorderColor,
        "&:hover": {
          cursor: "pointer",
        },
      },
      "& .actionIcon_selected_factories": {
        width: 20,
        height: 20,
        fill: colors.purchaseOrderSelectedBorderColor,
        "&:hover": {
          cursor: "pointer",
        },
      },

      "& .downloadIcon": {
        width: 20,
        height: 20,
        "&:hover": {
          cursor: "pointer",
        },
      },
      "& .three-dot-menu-icon": {
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    [`& .${documentScope.SALES_ORDER}`]: {
      outline: `2px solid ${colors.salesOrderBorderColor}`,
      background: colors.salesOrderDocBackgroundColor,
      "&:hover": {
        outline: `4px solid ${colors.salesOrderBorderColor}`,
        background: colors.salesOrderHoverBackgroundColor,
        [`& .badge${documentScope.SALES_ORDER}`]: {
          background: colors.salesOrderHoverBackgroundColor,
        },
        "& circle": {
          fill: colors.salesOrderBorderColor,
        },
      },
    },
    [`& .SELECTED_${documentScope.SALES_ORDER}`]: {
      outline: `2px solid ${colors.salesOrderSelectedBorderColor}`,
      background: colors.salesOrderSelectedBackgroundColor,
      "&:hover": {
        outline: `4px solid ${colors.salesOrderSelectedBorderColor}`,
        background: colors.salesOrderHoverBackgroundColor,
        [`& .badge${documentScope.SALES_ORDER}_selected`]: {
          background: colors.salesOrderHoverBackgroundColor,
        },
      },
    },
    [`& .${documentScope.PURCHASE_ORDER}`]: {
      outline: `2px solid ${colors.purchaseOrderBorderColor}`,
      background: colors.purchaseOrderDocBackgroundColor,
      "&:hover": {
        outline: `4px solid ${colors.purchaseOrderBorderColor}`,
        background: colors.purchaseOrderHoverBackgroundColor,
        [`& .badge${documentScope.PURCHASE_ORDER}_selected`]: {
          background: colors.purchaseOrderHoverBackgroundColor,
        },
      },
    },
    [`& .SELECTED_${documentScope.PURCHASE_ORDER}`]: {
      outline: `2px solid ${colors.purchaseOrderSelectedBorderColor}`,
      background: colors.purchaseOrderSelectedBackgroundColor,
      "&:hover": {
        outline: `4px solid ${colors.purchaseOrderSelectedBorderColor}`,
        background: colors.purchaseOrderHoverBackgroundColor,
        [`& .badge${documentScope.PURCHASE_ORDER}_selected`]: {
          background: colors.purchaseOrderHoverBackgroundColor,
        },
        "& .document-options-container": {
          "& circle": {
            fill: colors.purchaseOrderSelectedBorderColor,
          },
        },
      },
    },
    [`& .${documentScope.SHIPMENT}`]: {
      outline: `2px solid ${colors.shipmentBorderColor}`,
      background: colors.shipmentDocBackgroundColor,
      "&:hover": {
        outline: `4px solid ${colors.shipmentBorderColor}`,
        background: colors.shipmentHoverBackgroundColor,
        [`& .badge${documentScope.SHIPMENT}`]: {
          background: colors.shipmentHoverBackgroundColor,
        },
      },
    },
    [`& .SELECTED_${documentScope.SHIPMENT}`]: {
      outline: `2px solid ${colors.shipmentSelectedBorderColor}`,
      background: colors.shipmentSelectedBackgroundColor,
      "&:hover": {
        outline: `4px solid ${colors.shipmentSelectedBorderColor}`,
        background: colors.shipmentHoverBackgroundColor,
        [`& .badge${documentScope.SHIPMENT}_selected`]: {
          background: colors.shipmentHoverBackgroundColor,
        },
        "& .document-options-container": {
          "& circle": {
            fill: colors.shipmentSelectedBorderColor,
          },
        },
      },
    },
    [`& .${documentScope.CUSTOMER}`]: {
      outline: `2px solid ${colors.purchaseOrderBorderColor}`,
      background: colors.purchaseOrderDocBackgroundColor,
      "&:hover": {
        outline: `4px solid ${colors.purchaseOrderBorderColor}`,
        background: colors.purchaseOrderHoverBackgroundColor,
        [`& .badge${documentScope.CUSTOMER}_selected`]: {
          background: colors.purchaseOrderHoverBackgroundColor,
        },
      },
    },
    [`& .SELECTED_${documentScope.CUSTOMER}`]: {
      outline: `2px solid ${colors.purchaseOrderSelectedBorderColor}`,
      background: colors.purchaseOrderSelectedBackgroundColor,
      "&:hover": {
        outline: `4px solid ${colors.purchaseOrderSelectedBorderColor}`,
        background: colors.purchaseOrderHoverBackgroundColor,
        [`& .badge${documentScope.CUSTOMER}_selected`]: {
          background: colors.purchaseOrderHoverBackgroundColor,
        },
        "& .document-options-container": {
          "& circle": {
            fill: colors.purchaseOrderSelectedBorderColor,
          },
        },
      },
    },
    [`& .${documentScope.FACTORY}`]: {
      outline: `2px solid ${colors.purchaseOrderBorderColor}`,
      background: colors.purchaseOrderDocBackgroundColor,
      "&:hover": {
        outline: `4px solid ${colors.purchaseOrderBorderColor}`,
        background: colors.purchaseOrderHoverBackgroundColor,
        [`& .badge${documentScope.FACTORY}_selected`]: {
          background: colors.purchaseOrderHoverBackgroundColor,
        },
      },
    },
    [`& .SELECTED_${documentScope.FACTORY}`]: {
      outline: `2px solid ${colors.purchaseOrderSelectedBorderColor}`,
      background: colors.purchaseOrderSelectedBackgroundColor,
      "&:hover": {
        outline: `4px solid ${colors.purchaseOrderSelectedBorderColor}`,
        background: colors.purchaseOrderHoverBackgroundColor,
        [`& .badge${documentScope.FACTORY}_selected`]: {
          background: colors.purchaseOrderHoverBackgroundColor,
        },
        "& .document-options-container": {
          "& circle": {
            fill: colors.purchaseOrderSelectedBorderColor,
          },
        },
      },
    },
    [`& .folderDocument_${documentScope.SALES_ORDER}`]: {
      "&:hover": {
        outline: `4px solid ${colors.salesOrderBorderColor}`,
      },
    },
    [`& .folderDocument_${documentScope.PURCHASE_ORDER}`]: {
      "&:hover": {
        outline: `4px solid ${colors.purchaseOrderBorderColor}`,
      },
    },
    [`& .folderDocument_${documentScope.SHIPMENT}`]: {
      "&:hover": {
        outline: `4px solid ${colors.shipmentBorderColor}`,
      },
    },
    [`& .folderDocument_${documentScope.CUSTOMER}`]: {
      "&:hover": {
        outline: `4px solid ${colors.purchaseOrderBorderColor}`,
      },
    },
    [`& .folderDocument_${documentScope.FACTORY}`]: {
      "&:hover": {
        outline: `4px solid ${colors.purchaseOrderBorderColor}`,
      },
    },
    "& .versionLabel": {
      width: 25,
      height: 20,
      borderRadius: 3,
      textAlign: "center",
      fontSize: 11,
      paddingTop: 3,
      position: "absolute",
      left: -30,
      top: 18,
      margin: 0,
      lineHeight: "14px",
      background: "white",
      outline: `2px solid ${AppConfig.themeColors.primaryLight}`,
      fontWeight: 400,
      outlineOffset: -2,
      transition: "0.5s top",
    },

    [`& .badge${documentScope.SALES_ORDER}`]: {
      outline: `2px solid ${AppConfig.themeColors.salesOrderBorderColor}`,
      background: colors.salesOrderDocBackgroundColor,
      outlineOffset: -2,
    },
    [`& .badge${documentScope.SALES_ORDER}_selected`]: {
      outline: `2px solid ${colors.salesOrderSelectedBorderColor}`,
      background: colors.salesOrderSelectedBackgroundColor,
      outlineOffset: -2,
    },
    [`& .badge${documentScope.PURCHASE_ORDER}`]: {
      outline: `2px solid ${AppConfig.themeColors.purchaseOrderBorderColor}`,
      background: colors.purchaseOrderDocBackgroundColor,
      outlineOffset: -2,
    },
    [`& .badge${documentScope.PURCHASE_ORDER}_selected`]: {
      outline: `2px solid ${colors.purchaseOrderSelectedBorderColor}`,
      background: colors.purchaseOrderSelectedBackgroundColor,
      outlineOffset: -2,
    },
    [`& .badge${documentScope.SHIPMENT}`]: {
      outline: `2px solid ${colors.shipmentBorderColor}`,
      background: colors.shipmentDocBackgroundColor,
      outlineOffset: -2,
    },
    [`& .badge${documentScope.SHIPMENT}_selected`]: {
      outline: `2px solid ${colors.shipmentSelectedBorderColor}`,
      background: colors.shipmentSelectedBackgroundColor,
      outlineOffset: -2,
    },
    [`& .badge${documentScope.CUSTOMER}`]: {
      outline: `2px solid ${AppConfig.themeColors.purchaseOrderBorderColor}`,
      background: colors.purchaseOrderDocBackgroundColor,
      outlineOffset: -2,
    },
    [`& .badge${documentScope.CUSTOMER}_selected`]: {
      outline: `2px solid ${colors.purchaseOrderSelectedBorderColor}`,
      background: colors.purchaseOrderSelectedBackgroundColor,
      outlineOffset: -2,
    },
    [`& .badge${documentScope.FACTORY}`]: {
      outline: `2px solid ${AppConfig.themeColors.purchaseOrderBorderColor}`,
      background: colors.purchaseOrderDocBackgroundColor,
      outlineOffset: -2,
    },
    [`& .badge${documentScope.FACTORY}_selected`]: {
      outline: `2px solid ${colors.purchaseOrderSelectedBorderColor}`,
      background: colors.purchaseOrderSelectedBackgroundColor,
      outlineOffset: -2,
    },

    "& .dotsImage": {
      transform: "rotate(90deg)",
    },

    "& .listItemIcon": {
      minWidth: 0,
      height: "100%",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-start",
      position: "relative",
    },
    "& .imgListItemIcon": {
      marginLeft: 16,
    },
    "& .leftContainerLineDivider": {
      width: "2%",
      marginTop: -30,
    },

    "& .labelVersionBotton": {
      border: "2px solid #e5edf7",
      background: "white",
      width: 24,
      height: 16,
      borderRadius: 3,
      textAlign: "center",
      fontSize: 12,
      fontWeight: 400,
      position: "absolute",
      left: -16,
      top: 32,
      paddingLeft: 2,
    },
    "& .listItemDocumentCollapseItemsFolder": {
      display: "flex",
      "& .root-list-content": {
        marginLeft: 16,
      },
    },
    "& .listItemDocumentCollapseItems": {
      "& div": {
        "& ul": {
          marginLeft: 12,
        },
      },
    },
  },
  "&.noteButtonEffect": {
    "&:hover": {
      "& .permissionIcon": {
        opacity: 1,
      },
      "& .placeholderStyle": {
        opacity: 0.6,
      },
      "& .shipmentBadgeDocument": {
        border: "2px solid #CB5EDE",
      },
      "& .squareButton": {
        opacity: 1,
      },
      "& .fileinfo": {
        fontWeight: 500,
      },
      "& .filename": {
        fontWeight: 700,
      },
    },
    "& .permissionIcon": {
      opacity: 0,
      cursor: "pointer",
    },
    "& .placeholderStyle": {
      cursor: "unset",
    },
    "& .squareButton": {
      opacity: 0,
      cursor: "pointer",
      "&:hover": {
        fill: colors.primaryDark,
        "& .line": {
          stroke: "white",
        },
      },
      "&:active": {
        fill: colors.primaryDark,
        "& .line": {
          stroke: "white",
          transformBox: " fill-box",
          transformOrigin: "center",
          transform: "scale(1.2)",
        },
      },
    },
  },
  "&.hoverDrag": {
    "& .listemItemDocument": {
      outline: "4px solid #c0d5ee",
    },
  },
}));

export const DocumentInfoStyled = styled("div")(() => ({
  "&.document-info-container": {
    height: "100%",
    width: "100%",
    display: "flex",
    padding: "14px 12px 10px 19px",
    "& .document-icon-container": {
      width: "auto",
      height: "100%",
      minWidth: 38,
    },
    "& .document-detail-container": {
      width: "calc(100% - 30px)",
      height: "100%",
      padding: "0px 0px 0px 10px",
      overflow: "hidden",

      "& .document-name-content": {
        width: "100%",
        height: "40%",
        display: "flex",
        alignItems: "flex-start",
        "& .document-name": {
          fontWeight: 500,
          overflow: "hidden",
          textOverflow: "ellipsis",
          textWrap: "nowrap",
        },
        "& .editableLabel": {
          width: "100%",
          height: "100%",
          padding: "0px 8px",
        },
      },
      "& .document-creation-date-content": {
        width: "100%",
        height: "30%",
        display: "flex",
        alignItems: "center",
        "& span": {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      "& .document-created-by-content": {
        width: "100%",
        height: "30%",
        display: "flex",
        alignItems: "center",
        overflow: "hidden",
        justifyContent: "space-between",
        "& .created-by": {
          marginRight: 10,
          maxWidth: "100%",
          "& span": {
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "block",
            whiteSpace: "nowrap",
          },
        },
        "& .document-size": {
          height: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
    },
  },
  "&.document-info-upload": {
    alignItems: "center",
    padding: "6px 20px 6px 8px",
    "& .document-detail-container": {
      padding: 0,
      display: "flex",
      alignItems: "center",
    },
    "& .listItemIcon img": {
      margin: "5px !important",
      height: 25,
    },
    "& .document-name-content": {
      overflow: "hidden",
    },
  },
}));

export const FileSelectorStyled = styled("div")(() => ({
  "& .normalIcon": { width: 32, height: 32 },
  "& .smallIcon": {
    height: 30,
    marginRight: 5,
  },
}));

export const MenuOptionsPopoverStyled = styled(Popover)(() => ({
  "&.menuOptionsPopoverContainer": {
    "& .title-for-multiselect": {
      fontSize: 14,
      color: "#25486d",
      fontWeight: 700,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: 8,
      background: "#ccc",
    },
    "& .menuOptionsPopoverPaper": {
      overflow: "hidden",
      minWidth: 180,
    },
    "& .menu-options-items": {
      height: 30,
      borderBottom: "1px solid #f2f2f2",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      padding: "0px 6px",
      gap: 12,
      cursor: "pointer",
      "&:hover": {
        background: colors.primary,
        color: "#333",
      },
      "& .container-img": {
        minWidth: 25,
        maxWidth: 25,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      "& .container-text": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 12,
        color: "#25486d",
        lineHeight: 2,
        fontWeight: 400,
      },
    },
  },
}));

export const CopyLinkSnackbarStyled = styled(Snackbar)(() => ({
  "&.snackbarCopyLinkContainer": {
    right: 124,
    top: "auto",
    bottom: "auto",
    "& div": {
      width: 287,
      height: 76,
      background: "rgba(100, 126, 153, 0.5)",
      borderRadius: 10,
      padding: 0,
      textAlign: "center",
      "& .snackbar-copy-link-content": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 16,
        "& img": {
          position: "relative",
          width: 17,
          height: 17,
        },
        "& span": {
          color: "#fff",
          fontFamily: "Roboto",
          fontSize: 18,
        },
      },
    },
  },
}));
