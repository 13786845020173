import { colors } from "../../assets/jss/variables";
import { onHoverBackground } from "../../helpers/variableColors";
import { Popover, styled } from "@mui/material";

export const SOListContainer = styled("div")(({ theme }) => ({
  "&.root": {
    height: "100%",
    overflowY: "auto",
    position: "relative",
    padding: 16,
    backgroundColor: "#F6FBFF",
    zIndex: 0,
  },
  "& .contentContainer": {
    marginTop: 16,
    width: "100%",
    boxShadow: "0px 4px 10px rgb(0 0 0 / 25%)",
    backgroundColor: "white",
    position: "relative",
    height: "calc(100vh - 160px)",
    display: "flex",
    flexDirection: "column",
  },
  "& .buttonsFilter": {
    display: "grid",
    padding: "16px 16px 0px",
    gap: 16,
    "& .onlyAvatar": {
      minWidth: 80,
      maxWidth: 80,
      padding: 0,
      [theme.breakpoints.down("md")]: {
        minWidth: 53,
        "& .onlyText": {
          display: "none",
        },
      },
    },
  },
}));

export const SOTableContainer = styled("div")(() => ({
  "&.contentListSO": {
    "& .weekTableContainer": {
      marginBottom: 32,
    },
    "& .tableContainer": {
      height: "calc(99vh - 300px)",
      marginRight: 4,
      overflow: "auto",
      "&:hover": {
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: colors.thumbScrollColor,
        },
      },
      "&::-webkit-scrollbar": {
        width: 8,
        height: 8,
        marginRight: 3,
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar-track:vertical": {
        marginTop: 52,
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "transparent",
        borderRadius: 5,
        marginLeft: 3,
      },
    },
  },
}));

export const SearchTableContainer = styled("div")(() => ({
  "&.contentList": {
    overflow: "auto",
    zIndex: 1,
    "& .containerMessage": {
      width: "100%",
    },
    "&:hover": {
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: colors.thumbScrollColor,
      },
    },
    "&::-webkit-scrollbar": {
      width: 8,
      height: 8,
      marginRight: 3,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-track:vertical": {
      marginTop: 52,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent",
      borderRadius: 5,
      marginLeft: 3,
    },
  },
}));

export const MentionPopoverStyled = styled(Popover)(() => ({
  "& .popoverContainer": {
    paddingTop: 16,
    boxShadow: "0px 10px 50px 3px rgba(1,31,64,0.59)",
    background: "#F7F9FC",
    borderRadius: 10,
  },
  "& .mentionBodyContainer": {
    padding: "12px 0px",
    "& .sales-order-mention": {
      display: "flex",
      alignItems: "center",
      padding: "2px 18px",

      "& .quantity-mention": {
        width: 14,
        color: colors.primaryDark,
        fontSize: 12,
        textAlign: "center",
        "&:hover": {
          cursor: "pointer",
          fontWeight: 600,
        },
      },
      "& .number-mention-container": {
        width: "-webkit-fill-available",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      "& .mention-number-container": {
        width: "40px",
      },
    },
    "& .po-mention": {
      display: "flex",
      alignItems: "center",
      padding: "2px 18px",
      "&:hover": {
        background: onHoverBackground,
      },
      "& .quantity-mention": {
        width: 14,
        color: colors.primaryDark,
        fontSize: 12,
        textAlign: "center",
        "&:hover": {
          cursor: "pointer",
          fontWeight: 600,
        },
      },
      "& .number-mention-container": {
        width: "-webkit-fill-available",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      "& .mention-number-container": {
        width: "40px",
      },
    },
    "& .shipment-mention": {
      display: "flex",
      alignItems: "center",
      padding: "2px 18px",
      "&:hover": {
        background: onHoverBackground,
      },
      "& .quantity-mention": {
        width: 14,
        color: colors.primaryDark,
        fontSize: 12,
        textAlign: "center",
        "&:hover": {
          cursor: "pointer",
          fontWeight: 600,
        },
      },
      "& .number-mention-container": {
        width: "-webkit-fill-available",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      "& .mention-number-container": {
        width: "40px",
      },
    },
  },
  "& .mentionPopoverHeader": {
    display: "flex",
    alignItems: "center",
    padding: "0px 16px",
    "& .developeIconContainer": {
      width: 40,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    "& .orderIconsContainer": {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
    },
  },
}));
