import React, { useState, useEffect } from "react";
import { useRef } from "react";
import TooltipTD from "../Tooltip/TooltipTD";

function EditableLabelv2({
  text = "",
  editing = false,
  onSave = () => {},
  onCancel = () => {},
  className = "",
}) {
  const [value, setValue] = useState("");
  const [isActive, setIsActive] = useState(false);
  const inputRef = useRef(null);
  useEffect(() => {
    if (editing) {
      setValue(text);
      setTimeout(() => {
        inputRef.current.focus();
        inputRef.current.select();
        setIsActive(true);
      }, 50);
    }
  }, [editing]);

  function handleSave() {
    setIsActive(false);
    if (value === text || !value.trim()) {
      handleCancel();
    } else {
      onSave(value.trim());
    }
  }

  const handleCancel = () => {
    setIsActive(false);
    onCancel();
  };

  return (
    <div
      className={className}
      onClick={(ev) => (editing ? ev.stopPropagation() : null)}
    >
      {editing ? (
        <input
          id="document-editable-label"
          ref={inputRef}
          className={"editableLabel"}
          value={value}
          onChange={(e) => {
            const { value } = e.target;
            const cleanedValue = value.replace(/\s+/g, " ");
            const validCharacters = /^[^\t\\/\\]+$/;
            if (cleanedValue === "" || validCharacters.test(cleanedValue)) {
              setValue(cleanedValue);
            }
          }}
          autoFocus={true}
          onBlur={(ev) => {
            console.log("ON BLUR", value);
            if (isActive) {
              handleSave();
            }
            ev.stopPropagation();
          }}
          onKeyUp={(ev) => {
            if (ev.key === "Enter") {
              handleSave();
            } else if (ev.key === "Escape") {
              handleCancel();
            }
            ev.stopPropagation();
          }}
        />
      ) : (
        <TooltipTD label={text} className="document-name">
          {text}
        </TooltipTD>
      )}
    </div>
  );
}

export default EditableLabelv2;
