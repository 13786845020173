import React from "react";
import { CompanyPermissionsStyled } from "./styles";
import { CompanyPermissionsData } from "../../helpers/constants";
import { dbTables, USER_CUSTOM_SETTINGS } from "../../api/types/dbTables";
import CustomButton from "../Buttons/CustomButton";
import { useCompanyUsers } from "../../hooks/user";
import {
  orderByPhasePOSetup,
  POHeaderSetup,
  shipmentHeaderSetup,
  SOHeaderSetup,
  taskHeaderSetup,
  expensesHeaderSetup,
} from "../../helpers/screenHeaderSetup";
import { firestore } from "../../firebase";
import userTypes from "../../api/types/userTypes";
import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { Tooltip } from "@mui/material";

function CompanyPermissions({ companyId, permissions = [] }) {
  const companyUsers = useCompanyUsers({ id: companyId });
  const list = CompanyPermissionsData.map((currentPermission) => {
    const permission =
      permissions.find(
        (companyPermission) => currentPermission.value === companyPermission.id
      ) || {};
    return { ...currentPermission, enabled: !!permission.enabled };
  });
  async function changePermission(permission) {
    const value = permission.value;
    const checked = permission.enabled;
    try {
      setDoc(
        doc(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${dbTables.COMPANY_PERMISSION}/${value}`
        ),
        {
          enabled: !checked,
          permission: value,
          id: value,
        }
      );
    } catch (error) {
      console.log(error, "error");
    }
  }

  function changeAllPermissionFromCategory(list, enabled) {
    list.forEach((permission) => {
      const id = permission.value;
      setDoc(
        doc(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${dbTables.COMPANY_PERMISSION}/${id}`
        ),
        {
          enabled: enabled,
          permission: permission.value,
          id,
        }
      );
    });
  }

  return (
    <CompanyPermissionsStyled className="companyPermissionsContainer">
      <div className="permissions-header-tab">
        <h6 className="permission-header-text">Company permission</h6>
        <div className="selectAllNoneContainer">
          <span
            className="select-all-button"
            onClick={() => changeAllPermissionFromCategory(list, true)}
          >
            Select all
          </span>
          -
          <span
            className="select-none-button"
            onClick={() => changeAllPermissionFromCategory(list, false)}
          >
            Clear
          </span>
        </div>
      </div>
      <ul className="permission-list">
        {list.map((permission, index) => (
          <li
            className="permission-list-item"
            key={permission.value + index}
            style={{ padding: 3, width: "100%" }}
          >
            <label
              className="switch"
              style={{ margin: 0, minWidth: 77 }}
              onClick={(ev) => {
                changePermission(permission);
                ev.stopPropagation();
              }}
            >
              <input
                checked={permission.enabled}
                value={permission.value}
                type="checkbox"
              />
              <span
                data-positive="YES"
                data-negative="NO"
                className="slider round"
              />
            </label>
            <div
              className="permission-label-container"
              style={{ marginLeft: 8 }}
            >
              <strong>
                {permission.prefix ? (
                  <span className="permission-label-prefix">
                    {permission.prefix}
                  </span>
                ) : (
                  <span className="permission-label-prefix-empty"></span>
                )}
              </strong>
              <span className="permission-label">{permission.label}</span>
              <Tooltip placement="right-end" title={permission.tooltip}>
                <div
                  style={{
                    height: 10,
                    width: 10,
                    backgroundColor: "#ccc",
                    borderRadius: "50%",
                    display: "inline-block",
                  }}
                />
              </Tooltip>
            </div>
          </li>
        ))}
      </ul>
      <div className="permissions-header-tab" style={{ display: "block" }}>
        <h6 className="permission-header-text">Custom Settings</h6>
        <div>
          <CustomButton
            onClick={async () => {
              companyUsers.forEach((user) => {
                setDoc(
                  doc(
                    firestore,
                    `${dbTables.USERS}/${user.id}/${dbTables.CUSTOM_SETTINGS}/${USER_CUSTOM_SETTINGS.SCREEN_HEADERS}`
                  ),
                  {
                    id: USER_CUSTOM_SETTINGS.SCREEN_HEADERS,
                    POHeaderSetup,
                    SOHeaderSetup,
                    taskHeaderSetup,
                    orderByPhasePOSetup,
                    shipmentHeaderSetup,
                    expensesHeaderSetup,
                  }
                );
              });
              const tradedashEmployeesSnapDB = await getDocs(
                query(
                  collection(firestore, dbTables.USERS),
                  where("role", "==", userTypes.TRADEDASH_EMPLOYEE)
                )
              );
              const tradedashEmployeesDB = tradedashEmployeesSnapDB.docs.map(
                (doc) => ({ ...doc.data(), ref: doc.ref })
              );
              tradedashEmployeesDB.forEach((user) => {
                setDoc(
                  doc(
                    firestore,
                    `${dbTables.USERS}/${user.id}/${dbTables.CUSTOM_SETTINGS}/${USER_CUSTOM_SETTINGS.SCREEN_HEADERS}`
                  ),
                  {
                    id: USER_CUSTOM_SETTINGS.SCREEN_HEADERS,
                    POHeaderSetup,
                    SOHeaderSetup,
                    taskHeaderSetup,
                    orderByPhasePOSetup,
                    shipmentHeaderSetup,
                    expensesHeaderSetup,
                  }
                );
              });
            }}
          >
            update.column.headers.for.list.views
          </CustomButton>
        </div>
      </div>
    </CompanyPermissionsStyled>
  );
}

export default CompanyPermissions;
