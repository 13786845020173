import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { createActivityEntryFromMovedTask } from "../../../helpers/orderDashboard";
import {
  dependencyTypesOnMove,
  getAvailableTasksToMove,
  getDependencyTasks,
} from "../../../helpers/timelineModal";
import { dbTables, ORDER_TYPE, typeOfTask } from "../../../api/types/dbTables";
import {
  MOVE_TASK_TYPES,
  PO_STATUS,
  SALES_ORDER_DIAMOND_STATUS,
  taskStatus,
} from "../../../helpers/constants";
import { updateDoc } from "firebase/firestore";
import { salesOrderTriggerTypes } from "../../../helpers/salesOrder";
import { getStatusTask, TASK_FIELDS } from "../../../helpers/tasks";
import {
  callMoveDependencyTasks,
  getCurrentOrderTask,
  handleUpdateTotalTask,
} from "../../../container/TaskListScreen/TaskScreenHelper";
import RemainingBadgeModal from "../../Modal/RemainingBadgeModal";
import TaskOptions from "../TaskOptions";
import { useCompanyId } from "../../../hooks";
import { ENTITY_TASK_ACTION, EntityTaskContext } from "./TaskContext";
import TaskData from "./TaskData";

function TaskItemv2({
  task,
  onClickOffset = () => {},
  isSmallPanel,
  companyUsers,
  salesOrder,
  purchaseOrder,
  shipment,
  allTaskList,
  isReadOnly = false,
  handleReadOnlyModal = () => {},
  pendingToCompleted,
  onCleanPendingComplete,
  currentUser,
  onCompleteTask,
  firstItem,
  backdropId,
  panelWidth
}) {
  const { dispatchTaskcontext, TaskContextState } =
    useContext(EntityTaskContext);

  const { filterTabActive, showCompletedTask } = TaskContextState;

  const companyId = useCompanyId();
  const [userEditId, setUserEditId] = useState(task.enableToEdit);
  const [openModalToAdjustTasks, setOpenModalToAdjustTasks] = useState({
    open: false,
    daysOffset: 0,
  });
  const [timer, setTimer] = useState("");

  const [countingSeconds, setCountingSeconds] = useState(
    parseInt(
      (moment(task.creationDate).add(7, "seconds").valueOf() -
        moment().valueOf()) /
        1000
    )
  );

  const [classesTask, setClassesTask] = useState("");
  const [newClassToDessapear, setNewClassToDissapear] = useState(
    "option-to-edit-container"
  );
  const [openPicker, setOpenPicker] = useState(false);
  const [openUsers, setOpenUsers] = useState(false);
  const [editingAdHocTask, setEditingAdHocTask] = useState(false);
  const [currentTask, setCurrentTask] = useState(task);
  const [lastElementId, setLastElementId] = useState("");

  useEffect(() => {
    setCurrentTask({ ...task });
  }, [task]);

  useEffect(() => {
    if (userEditId) {
      if (!editingAdHocTask && !timer) {
        setTimer(
          setInterval(() => {
            setCountingSeconds((t) => (t === 6 ? 6 - 1 : t - 1));
          }, 1000)
        );
      } else if (editingAdHocTask && timer) {
        clearInterval(timer);
        setCountingSeconds(6);
        setTimer(false);
      }
    }
  }, [editingAdHocTask]);

  const createUpdateActivityDate = ({ currentDayOffset }) => {
    createActivityEntryFromMovedTask({
      task: task,
      dayOffsetTask: currentDayOffset,
      dayOffsetRemainigTasks: Math.abs(currentDayOffset),
      diffDaysTask: currentDayOffset,
      isMovingForward: currentDayOffset > 0,
      typeChange: dependencyTypesOnMove.START_DATE_AND_FINISH_DATE_CHANGED,
      user: currentUser,
      companyUsers,
      mainEntity: {
        ...mainEntity,
        parentCollection,
        taskScope: task.type || ORDER_TYPE.SHIPMENT,
      },
    });
  };

  useEffect(() => {
    if (userEditId && !editingAdHocTask && timer && countingSeconds <= -1) {
      setNewClassToDissapear("option-to-edit-container dissapear");
      clearInterval(timer);
      setTimeout(() => {
        setUserEditId(false);

        const { currentOrder, orderDB: parentCollection } = getCurrentOrderTask(
          {
            task: currentTask,
            salesOrder,
            purchaseOrder,
            currentShipment: shipment,
          }
        );
        if (currentOrder) {
          let diamondStatus;

          if (task.status === taskStatus.LATE) {
            diamondStatus =
              currentOrder.status !== SALES_ORDER_DIAMOND_STATUS.LATE
                ? SALES_ORDER_DIAMOND_STATUS.LATE
                : undefined;
          } else if (task.status === taskStatus.NEAR_DUE) {
            diamondStatus = !currentOrder.status
              ? SALES_ORDER_DIAMOND_STATUS.NEAR_DUE
              : undefined;
          }

          if (diamondStatus) {
            updateDoc(currentOrder.ref, {
              diamondStatus,
              triggerType: salesOrderTriggerTypes.DISMISS_TRIGGER,
            });

            if (
              parentCollection === dbTables.PURCHASE_ORDERS &&
              salesOrder.status !== diamondStatus
            ) {
              updateDoc(salesOrder.ref, {
                diamondStatus,
                triggerType: salesOrderTriggerTypes.DISMISS_TRIGGER,
              });
            }
          }
        }
        setClassesTask("");
        updateDoc(task.ref, { enableToEdit: false });
        clearInterval(timer);
      }, 2000);
    }
  }, [countingSeconds]);

  useEffect(() => {
    task.complete
      ? setClassesTask("complete")
      : userEditId && editingAdHocTask
      ? setClassesTask("to-edit")
      : setClassesTask("");
  }, [editingAdHocTask]);

  useEffect(() => {
    const isCompleted = task.complete && task.status === "complete";
    if (
      (classesTask.includes("pendingSize") ||
        classesTask.includes("pendingOpacity") ||
        classesTask.includes("completeOpacity")) &&
      isCompleted
    ) {
      if (showCompletedTask) {
        onCleanPendingComplete(task);
      } else {
        if (task.moved === true) {
          setClassesTask("completeSize");
          setTimeout(() => {
            if (pendingToCompleted[task.id]) {
              onCleanPendingComplete(task);
            }
          }, 2000);
        } else {
          setClassesTask("pendingOpacity");
        }
      }
    }
  }, [task.complete, task.status, task.moved]);

  useEffect(() => {
    if (
      classesTask.includes("completeSize") ||
      classesTask.includes("pendingOpacity")
    ) {
      setClassesTask("complete");
    }
  }, [showCompletedTask, filterTabActive]);

  useEffect(() => {
    if (classesTask !== "" && !task.complete) {
      setClassesTask("");
    }
  }, [task.complete]);

  function changeStyleTo(value) {
    setClassesTask(value);
  }

  async function changeField({ taskToUpdate, field, triggerType }) {
    if (field === TASK_FIELDS.ASSIGNED_TO) {
      // trackEvent("PO Dashboard - Tasks - Reassigned", {
      //   from: item.assignedTo,
      //   to: value
      // });
    }
    if (field === TASK_FIELDS.FINISH_DATE) {
      if (taskToUpdate.currentDiffDays === 0) {
        setOpenPicker(false);
        return;
      }
      updateDoc(task.ref, {
        updated_by: currentUser.id,
        status: getStatusTask(taskToUpdate),
        triggerType: triggerType,
        finishDate: taskToUpdate.finishDate,
        startDate: taskToUpdate.startDate,
      });
      setOpenPicker(false);
      const currentDayOffset = taskToUpdate.currentDiffDays;
      if (taskToUpdate.type === typeOfTask.SALES_ORDER) {
        dispatchTaskcontext({
          type: ENTITY_TASK_ACTION.COMMON,
          payload: {
            loading: true,
          },
        });
        const result = await callMoveDependencyTasks({
          companyId,
          salesOrder,
          task: taskToUpdate,
        });
        const { data } = result;
        if (data.length > 0) {
          setOpenModalToAdjustTasks({
            open: true,
            daysOffset: currentDayOffset,
          });
        } else {
          createUpdateActivityDate({ currentDayOffset });
        }
        dispatchTaskcontext({
          type: ENTITY_TASK_ACTION.COMMON,
          payload: {
            loading: false,
          },
        });
        // trackEvent("PO Dashboard - Tasks - Updated Due Date", {
        //   description: item.description,
        //   dayOffset: dayOffsetRemainigTasks
        // });
      } else {
        const availableTasksToMove = getAvailableTasksToMove({
          tasks: allTaskList,
        });
        let dependencyTasks = getDependencyTasks({
          task: taskToUpdate,
          tasks: availableTasksToMove,
          dependencyTasks: [],
          remainingDayOffset: 1,
        });
        if (dependencyTasks.length > 0) {
          setOpenModalToAdjustTasks({
            open: true,
            daysOffset: currentDayOffset,
          });
        } else {
          createUpdateActivityDate({ currentDayOffset });
        }
      }
    } else {
      updateDoc(task.ref, {
        ...taskToUpdate,
        updated_by: currentUser.id,
        triggerType: triggerType,
      });
    }
    setOpenPicker(false);
    setOpenUsers(false);
  }

  const isVoided = () => {
    const orderByType = {
      [typeOfTask.SALES_ORDER]: salesOrder,
      [typeOfTask.PURCHASE_ORDER]: purchaseOrder,
      [typeOfTask.SHIPMENT]: shipment,
    };
    const order = orderByType[task.type] || {};
    return (
      salesOrder.status === PO_STATUS.VOIDED ||
      order.status === PO_STATUS.VOIDED
    );
  };

  const isVoid = isVoided();

  function handleChange(field, value, triggerType) {
    setCurrentTask({
      ...currentTask,
      [field]: value,
      triggerType: triggerType,
    });
    setOpenPicker(false);
    setOpenUsers(false);
  }

  const handleIsEditingTask = (value) => {
    dispatchTaskcontext({
      type: ENTITY_TASK_ACTION.COMMON,
      payload: {
        isEditingTask: value,
      },
    });
  };

  const { currentOrder: mainEntity, orderDB: parentCollection } =
    getCurrentOrderTask({
      task: currentTask,
      salesOrder,
      purchaseOrder,
      currentShipment: shipment,
    });

  const permissionToVerify =
    task.type === typeOfTask.PURCHASE_ORDER ? task.factoryId : task.customerId;

  if (userEditId === currentUser.id) {
  }
  return (
    <>
      {openModalToAdjustTasks.open && (
        <RemainingBadgeModal
          open={openModalToAdjustTasks.open}
          onCloseModal={() => {
            console.log("onCloseModal");
            setOpenModalToAdjustTasks({ open: false });
          }}
          actionType={MOVE_TASK_TYPES.START_DUE_DATE_CHANGE}
          mainEntity={mainEntity}
          parentCollection={parentCollection}
          task={currentTask}
          taskDayOffset={openModalToAdjustTasks.daysOffset}
        />
      )}
      <TaskData
        item={currentTask}
        classesTask={classesTask}
        isVoid={isVoid}
        firstItem={firstItem}
        backdropId={backdropId}
        changeField={changeField}
        companyUsers={companyUsers}
        changeStyleTo={changeStyleTo}
        completeTask={onCompleteTask}
        onLastFinishDate={(value, enabled) => {
          if (enabled) {
            setEditingAdHocTask(true);
          }
        }}
        onOpenPicker={(value) => {
          if (isReadOnly) {
            handleReadOnlyModal();
          } else {
            setOpenPicker(value);
          }
        }}
        openPicker={openPicker}
        openUsers={openUsers}
        onOpenUsers={(value) => {
          if (isReadOnly) {
            handleReadOnlyModal();
          } else {
            setOpenUsers(value);
          }
        }}
        purchaseOrder={purchaseOrder}
        onClickOffset={onClickOffset}
        editingAdHocTask={editingAdHocTask}
        handleChange={handleChange}
        lastElementId={lastElementId}
        onLastElementId={(value) => setLastElementId(value)}
        permissionToVerify={permissionToVerify}
        isSmallPanel={isSmallPanel}
        panelWidth={panelWidth}
        user={companyUsers.find((user) => user.id === currentTask.assignedTo)}
        onCleanPendingTask={onCleanPendingComplete}
      />
      {userEditId === currentUser.id && (
        <TaskOptions
          item={currentTask}
          newClassToDessapear={newClassToDessapear}
          countingSeconds={countingSeconds}
          currentUser={currentUser}
          handleUpdateTotalTask={({ type, offset }) => {
            handleUpdateTotalTask({
              type,
              offset,
              mainEntity,
              salesOrder,
            });
          }}
          onEditTask={(value) => {
            clearInterval(timer);
            setEditingAdHocTask(value);
            handleIsEditingTask(value);
          }}
          editingAdHocTask={editingAdHocTask}
          recoverLastTask={() => setCurrentTask(task)}
          companyUsers={companyUsers}
          permissionToVerify={permissionToVerify}
          userEditId={userEditId}
          handleIsEditingTask={handleIsEditingTask}
        />
      )}
    </>
  );
}

export default TaskItemv2;
