import { colors } from "./variables";
import { styled } from "@mui/material";

export const ColumnContainerStyled = styled("div")(() => ({
  "&.columnContainer": {
    height: "100%",
    width: "100%",
    backgroundColor: "white",
    boxShadow: "0 2px 5px 1px #aaa",
    display: "flex",
    padding: "0px !Important",
    "& section": {
      justifyContent: "unset",
    },
    "& .leftColumn": {
      flex: 1,
      backgroundColor: colors.primaryLight,
      height: "100%",
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      minWidth: 350,
      maxWidth: 350,
      "& .title": {
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        color: colors.primaryDark,
        height: 34,
        "& .titleName": {
          fontSize: 16,
          fontWeight: 500,
          textAlign: "left",
        },
        "& .titleButtom": {
          fontSize: 12,
          fontWeight: 700,
          padding: "10px 20px 10px 20px",
          backgroundColor: "white",
          borderRadius: 4,
          cursor: "pointer",
          color: colors.primaryDark,
          margin: 0,
        },
      },
      "& .inactiveItems": {
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        marginBottom: 17,
        cursor: "pointer",
        marginTop: 5,
        "& .inactiveItemsText": {
          fontSize: 11,
          color: "#6F7E8E",
          fontWeight: 400,
          textTransform: "uppercase",
          marginLeft: 6,
          lineHeight: "13px",
        },
      },
      "& header": {
        backgroundColor: colors.primary,
        display: "flex",
        alignItems: "center",
        padding: "18px 18px 17px 20px",
        fontWeight: "500",
        justifyContent: "space-between",
        height: "auto",
        fontSize: 16,
        flexDirection: "column",
      },
      "& ul": {
        overflowY: "auto",
        margin: 0,
      },
      "& .container": {
        textDecoration: "none",
        padding: "23px 16px",
        height: 60,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        width: "100%",
        color: colors.primaryDark,
        "&:hover": {
          backgroundColor: "white",
          boxShadow: "0px 2px 5px #cccc",
        },
      },
      "& .active": {
        backgroundColor: "white",
        boxShadow: "0px 2px 5px #cccc",
      },
      "& .disabled": {
        boxShadow: "0px 2px 5px #cccc",
        cursor: "not-allowed",
        textDecoration: "none",
        padding: 16,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        width: "100%",
        color: colors.primaryDark,
        opacity: 0.5,
        height: 60,
      },
    },
    "& .rightColumn": {
      flex: 4,
      backgroundColor: "white",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      color: colors.primaryDark,
      "& header": {
        backgroundColor: colors.primaryLight,
        display: "flex",
        minHeight: 70,
        alignItems: "center",
        padding: "8px 57px 8px 60px",
        fontWeight: 500,
        width: "100%",
        justifyContent: "space-between",
      },
    },
    "& .inputSearch": {
      width: "80%",
      padding: "0px 16px",
      border: "none",
      lineHeight: 2,
      color: colors.primaryDark,
      "&::placeholder": {
        color: "#c0d5ee",
      },
    },
    "& .buttonX": {
      background: "#FFF",
      width: 15,
      height: 15,
      borderRadius: "50%",
      margin: "0px 8px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    "& .buttonSearch": {
      color: colors.primaryDark,
      width: 15,
      height: 15,
      margin: "0px 8px",
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
}));

export const AdminPageContent = styled("div")(() => ({
  "&.adminPageContainer": {
    padding: 16,
    width: "100%",
    overflow: "hidden",
    display: "flex",
    height: "100%",
  },
  "& .ButtonGroup": {
    display: "flex",
    flexDirection: "row",
    "& .rootButton": {
      overflow: "auto",
      minWidth: 180,
      maxWidth: 180,
      border: `2px solid ${colors.primaryLight}`,
      margin: "0 4px",
      "&::-webkit-scrollbar": {
        width: 6,
        background: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: colors.thumbScrollColor,
        borderRadius: 7,
      },
    },
    "& .buttonSidebar": {
      height: "10%",
      backgroundColor: colors.primary,
      color: colors.primaryDark,
      minHeight: 50,
      padding: 10,
      "&:active": {
        transform: "scale(1)",
      },
    },
  },
  "& .mainContainer": {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflow: " hidden",
    boxShadow: "0 2px 4px 2px #ccc",
  },
  "& .mainTableContainer": {
    height: "100%",
    overflow: "auto",
    "& .actionButtonContainer": {
      padding: 10,
      backgroundColor: "white",
      display: "flex",
      justifyContent: "flex-end",
      position: "sticky",
      top: 0,
      zIndex: 2,
      height: 50,
      "& .tabTitle": {
        fontSize: "1rem",
        fontWeight: 700,
        color: colors.primaryDark,
      },
    },
    "& .withoutPermissionTextContainer": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%",
    },
    "& .withoutPermissionText": {
      fontSize: 72,
      color: colors.primaryDark,
      fontWeight: 700,
      fontFamily: "Roboto",
    },
    "&::-webkit-scrollbar": {
      width: 6,
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent",
      borderRadius: 7,
    },
    "&:hover": {
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: colors.thumbScrollColor,
      },
    },
  },
}));
