import React, { useEffect, useState } from "react";
import { dbTables } from "../../api/types/dbTables";
import CustomButton from "../Buttons/CustomButton";
import DataTable from "../DataTable/DataTable";
import { sortObjectsBy, isTradeDashEmployee } from "../../helpers/helpers";
import IntlMessages from "../../util/IntlMessages";
import CustomSnackbar from "../../routes/components/snackbar/component/CustomSnackbar";
import { useUser } from "../../hooks/user";
import ActionsModal from "./ActionsModal";
import AttentionModal from "../Modal/AttentionModal";
import {
  GENERAL_PERMISSION_VALUE,
  SALES_ORDER_STATUS,
} from "../../helpers/constants";
import ReadOnlyModal from "../Modal/ReadOnlyModal";
import { useCustomers } from "../../hooks/customers";
import { firestore } from "../../firebase";
import { collection, onSnapshot } from "firebase/firestore";
import { useIsAllowedFunction } from "../../hooks/permissions";
import userTypes from "../../api/types/userTypes";
import { VoidLabel } from "../../helpers/iconSvgPath";
import { TableCell, TableRow } from "@mui/material";

const headerData = ["#", "Customer", "", "", "", "", "", "", "", "", "", ""];

function SalesOrdersManager({ companyId, salesOrderTemplateId }) {
  const [snackbarMessage] = useState("");
  const [snackbarType] = useState("success");
  const [openSnackbarSuccess, setOpenSnackbarSuccess] = useState(false);
  const [salesOrders, setSalesOrders] = useState([]);
  const user = useUser();
  const customers = useCustomers();
  const isAllowed = useIsAllowedFunction();

  const [actionModalType, setActionModalType] = useState("");
  const [openReadOnlyModal, setReadOnlyModal] = useState(false);

  const [selectedSalesOrder, setSelectedSalesOrder] = useState(null);
  const [openAttentionModal, setOpenAttentionModal] = useState(false);
  const [attentionModalDescription, setAttentionModalDescription] =
    useState("");

  useEffect(() => {
    if (!companyId) {
      return;
    }
    const unsubscribe = onSnapshot(
      collection(
        firestore,
        `${dbTables.COMPANIES}/${companyId}/${dbTables.SALES_ORDERS}`
      ),
      (snap) => {
        setSalesOrders(
          snap.docs.map((doc) => ({ ...doc.data(), ref: doc.ref }))
        );
      }
    );

    return () => {
      unsubscribe();
    };
  }, [companyId]);

  if (!companyId || !salesOrderTemplateId) {
    return null;
  }

  function openVoidPO(so, ev) {
    if (isTradeDashEmployee(user)) {
      setReadOnlyModal(true);
    } else if (user.role !== userTypes.SUPER_ADMIN) {
      const voidPOPermission = isAllowed(
        GENERAL_PERMISSION_VALUE.CAN_VOID_PURCHASE_ORDERS
      );
      const voidPQPermission = isAllowed(
        GENERAL_PERMISSION_VALUE.CAN_VOID_PURCHASE_QUOTES
      );
      if (voidPOPermission || voidPQPermission) {
        setSelectedSalesOrder(so);
        setActionModalType("voidPO");
      } else {
        setAttentionModalDescription("You do not have permission to void POs");
        setOpenAttentionModal(true);
      }
    } else {
      setSelectedSalesOrder(so);
      setActionModalType("voidPO");
    }
    ev.stopPropagation();
  }

  function getPOsString(purchaseOrders = []) {
    let poString = "";
    if (purchaseOrders.length === 1) {
      return purchaseOrders[0].number;
    }
    purchaseOrders.forEach((po, index) => {
      if (index === purchaseOrders.length - 1) {
        poString += ` and ${po.number}`;
      } else if (index === purchaseOrders.length - 2) {
        poString += `${po.number}`;
      } else {
        poString += `${po.number}, `;
      }
    });
    return poString;
  }

  return (
    <div className="sales-orders-projects" style={{ overflow: "auto" }}>
      {openReadOnlyModal && (
        <ReadOnlyModal
          isOpen={openReadOnlyModal}
          onClick={() => setReadOnlyModal(false)}
          onClose={() => setReadOnlyModal(false)}
        />
      )}
      {openAttentionModal && (
        <AttentionModal
          title="Attention"
          description={attentionModalDescription}
          isOpen={openAttentionModal}
          onClick={() => setOpenAttentionModal(false)}
          // onClose={() => setOpenAttentionModal(false)}
        />
      )}
      <CustomSnackbar
        open={openSnackbarSuccess}
        message={snackbarMessage}
        handleCloseSnackbar={() => setOpenSnackbarSuccess(false)}
        variant={snackbarType}
      />
      <div className="sales-order-title">
        <span style={{ flex: 1 }}>
          <IntlMessages id="salesorders.title" />
        </span>
      </div>
      <DataTable
        header={headerData.map((item, index) => (
          <TableCell
            key={item + index}
            style={{
              fontSize: "0.875rem",
            }}
          >
            {item}
          </TableCell>
        ))}
      >
        {salesOrders &&
          salesOrders
            .sort(sortObjectsBy("number", true))
            .map((salesOrder, index) => {
              return (
                <TableRow key={salesOrder.id + index}>
                  <TableCell
                    style={{
                      fontSize: "0.875rem",
                    }}
                  >
                    <span style={{ marginRight: 8 }}>{salesOrder.number}</span>
                    {salesOrder.status === SALES_ORDER_STATUS.VOIDED && (
                      <VoidLabel />
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "0.875rem",
                    }}
                  >
                    {customers.find(
                      (customer) => customer.id === salesOrder.customerId
                    )
                      ? customers.find(
                          (customer) => customer.id === salesOrder.customerId
                        ).name
                      : ""}
                  </TableCell>
                  <TableCell
                    onClick={(ev) => ev.stopPropagation()}
                    style={{
                      fontSize: "0.875rem",
                    }}
                  >
                    <CustomButton
                      onClick={(ev) => openVoidPO(salesOrder, ev)}
                      containerColor="#f7fcff"
                      disabled={salesOrder.status === SALES_ORDER_STATUS.VOIDED}
                    >
                      {"components.saleOrders.ActionsModal.voidPO"}
                    </CustomButton>
                  </TableCell>

                  <TableCell colSpan={10} />
                </TableRow>
              );
            })}
      </DataTable>
      <div
        style={{
          position: "sticky",
          bottom: 0,
          width: "100%",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
        }}
      />
      {selectedSalesOrder && !!actionModalType && (
        <ActionsModal
          open={!!actionModalType}
          isAllowed={isAllowed}
          onClose={({ purchaseOrders, salesOrder, hasError }) => {
            setSelectedSalesOrder(null);
            setActionModalType(null);
            if (hasError) {
              setAttentionModalDescription(
                <React.Fragment>
                  An error occurred while processing your request. Please try
                  again later or contact our support team for assistance
                </React.Fragment>
              );
              setOpenAttentionModal(true);
              return;
            }
            if (salesOrder) {
              const hasPOs = purchaseOrders?.length > 0;
              const SOText = hasPOs
                ? ` And Sales Order ${salesOrder.number} has been voided as well`
                : `Sales order ${salesOrder.number} has been successfully
              voided `;
              setAttentionModalDescription(
                <React.Fragment>
                  {hasPOs &&
                    `${getPOsString(purchaseOrders)} ${
                      purchaseOrders.length && purchaseOrders.length === 1
                        ? "has"
                        : "have"
                    } been successfully voided `}
                  <br />
                  <br />
                  {SOText}
                </React.Fragment>
              );
            } else if (purchaseOrders) {
              setAttentionModalDescription(
                <React.Fragment>
                  {`${
                    purchaseOrders.length && purchaseOrders.length === 1
                      ? "PO"
                      : "POs"
                  } 
                  ${getPOsString(purchaseOrders)} 
                  ${
                    purchaseOrders.length && purchaseOrders.length === 1
                      ? "has"
                      : "have"
                  } been successfully voided`}
                </React.Fragment>
              );
            } else if (!purchaseOrders) {
              return true;
            }
            setOpenAttentionModal(true);
          }}
          salesOrder={selectedSalesOrder}
        />
      )}
    </div>
  );
}

export default SalesOrdersManager;
