import React from "react";
import { Popover } from "@mui/material";
import { sortObjectsBy } from "../../helpers/helpers";
import { useFactories } from "../../hooks/factories";

function TemplatesPopover({ anchorEl, open, onClose, onItemClick }) {
  const factories = useFactories().filter((factory) => !factory.inactive);

  return (
    <Popover
      classes={{ paper: "templates-container" }}
      onClose={onClose}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <ul>
        {factories.sort(sortObjectsBy("name")).map((factory) => (
          <li
            key={factory.id}
            onClick={() => {
              onItemClick(factory);
              onClose();
            }}
          >
            {factory.name}
          </li>
        ))}
      </ul>
    </Popover>
  );
}

export default TemplatesPopover;
