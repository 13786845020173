import React from "react";
import CustomModal from "./Modal";
import TitleInformation from "../PurchaseOrderDashboard/TitleInformation";
import { useState } from "react";
import { useEffect } from "react";
import Mark from "mark.js";
import POTableSKU from "../PurchaseOrderDashboard/POTableSKU";

function ExpandItemTableModalV2({
  isOpen,
  setExpandModal,
  prefix,
  columnsTable,
  rowTable,
  multiHeaderId,
  currentPurchaseOrder,
  currentSalesOrder,
  currentShipment,
  onChangeShipment,
  purchaseOrders,
  handleShowImage,
  modalShowImage,
  history,
  hasMismatchTag,
  onClickRedirect,
}) {
  const [cleanSearchBox, setCleanSearchBox] = useState(false);
  const [search, setSearch] = useState("");
  const markInstance = new Mark(document.getElementById("modal-item-table"));

  function performMark(keyword = "") {
    var options = {
      separateWordSearch: false,
      diacritics: false,
      debug: false,
      acrossElements: false,
      accuracy: "partially",
      exclude: [
        ".headerTable, .headerTable *",
        ".alignRight, .alignRight *",
        ".totalNumber, .totalNumber *"
      ],
    };
    markInstance.unmark({
      done: () => {
        markInstance.mark(keyword, options);
      },
    });
  }

  useEffect(() => {
    setTimeout(() => performMark(search), 200);
  }, [search]);

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={() => {
        setExpandModal(false);
      }}
      className="expand-item-table"
    >
      <TitleInformation
        type={prefix}
        purchaseOrder={currentPurchaseOrder}
        shipment={currentShipment}
        items={rowTable}
        salesOrder={currentSalesOrder}
      />

      <div className="modal-item-table" id="modal-item-table">
        <POTableSKU
          prefix={prefix}
          columnsTable={columnsTable}
          dataTable={rowTable}
          allDataTable={rowTable}
          multiHeaderId={multiHeaderId}
          currentPurchaseOrder={currentPurchaseOrder}
          currentSalesOrder={currentSalesOrder}
          style={{ maxWidth: 1672 }}
          onChangeShipment={onChangeShipment}
          purchaseOrders={purchaseOrders}
          handleShowImage={handleShowImage}
          modalShowImage={modalShowImage}
          isModal
          history={history}
          cleanSearchBox={cleanSearchBox}
          onWriteSearch={setSearch}
          handleSearchBox={setCleanSearchBox}
          filterText={search}
          hasMismatchTag={hasMismatchTag}
          onClickRedirect={(data) => {
            const { editing } = data;
            onClickRedirect(data);
            if (!editing) {
              setExpandModal(false);
            }
          }}
        />
      </div>
    </CustomModal>
  );
}

export default ExpandItemTableModalV2;
