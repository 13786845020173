import React from "react";
import { useReducer } from "react";
import { CALENDAR_SETUP } from "../../helpers/ganttChart";
import moment from "moment";
import { labelTaskPhases } from "../../helpers/constants";
import { GANTTCHART_TABLE_SIZE } from "../Timeline/GanttChartContext";
import FactoryTemplateModal from "./FactoryTemplateModal";
import {
  GANTT_CHART_ACTION,
  GanttChartTemplateContext,
} from "../CompanyTabs/SalesOrderTemplateContainer";

function FactoryTemplateContainer({
  open,
  onClose,
  factory,
  isReadOnly = false,
  companyId,
  summaryFactories,
}) {
  if (!factory) {
    return null;
  }

  return (
    <GanttChartTemplateProvider>
      <FactoryTemplateModal
        factory={factory}
        companyId={companyId}
        onClose={onClose}
        open={open}
        isReadOnly={isReadOnly}
        summaryFactories={summaryFactories}
      />
    </GanttChartTemplateProvider>
  );
}

export default FactoryTemplateContainer;

const initialState = {
  loading: true,
  salesOrderTemplate: {},
  salesOrderTasks: [],
  taskPhases: {
    [labelTaskPhases.PROPOSAL_QUOTE]: true,
    [labelTaskPhases.PRE_PRODUCTION]: true,
    [labelTaskPhases.PRODUCTION]: true,
    [labelTaskPhases.BOOKING_TRANSIT]: true,
    [labelTaskPhases.PAYMENT_BILLING]: true,
  },
  calendarSetup: CALENDAR_SETUP.WEEKLY,
  startDate: moment().subtract(2, "days").startOf(),
  endDate: moment().add(1, "year").endOf("isoWeek"),
  highlightedTask: null,
  tableSize: GANTTCHART_TABLE_SIZE.LARGE,
  reRenderArrow: false,
  graphTableRef: null,
  tasks: [],
  initializedTasks: [],
  templateChanges: {},
  vendorClone: {},
  deletedTasks: [],
};
function GanttChartTemplateProvider({ children }) {
  const [ganttChartTemplateState, dispatchGanttChartTemplate] = useReducer(
    ganttChart,
    initialState
  );

  return (
    <div
      className={"ganttChartTemplateProvider"}
      style={{ display: "flex", height: "100%" }}
      id="dashboard-activities"
    >
      <GanttChartTemplateContext.Provider
        value={{
          ganttChartTemplateState,
          dispatchGanttChartTemplate,
        }}
      >
        {children}
      </GanttChartTemplateContext.Provider>
    </div>
  );
}

function ganttChart(state, action) {
  const typeChange = action.typeChange;
  switch (action.type) {
    case GANTT_CHART_ACTION.COMMON:
      const newTypeChange =
        typeChange === undefined
          ? state.templateChanges
          : {
              ...state.templateChanges,
              [typeChange]: true,
            };

      return {
        ...state,
        ...action.payload,
        templateChanges: typeChange === "reset" ? {} : newTypeChange,
      };

    case GANTT_CHART_ACTION.UPDATE_TASK:
      const updatedTask = action.payload;

      const updatedTasks = state.tasks.map((task) =>
        task.id === updatedTask.id ? { ...task, ...updatedTask } : task
      );
      const changeTypeChange =
        typeChange === undefined
          ? state.templateChanges
          : {
              ...state.templateChanges,
              [typeChange]: true,
            };

      return {
        ...state,
        tasks: updatedTasks,
        templateChanges: changeTypeChange,
      };

    default:
      console.log("Unknown action type: ", action.type);
      return state;
  }
}

//
