import React from "react";

import { GANTT_CHART_ACTION } from "../CompanyTabs/SalesOrderTemplateContainer";
import { cx } from "@emotion/css";
import { GANTTCHART_TABLE_SIZE } from "../Timeline/GanttChartContext";
import TooltipTD from "../Tooltip/TooltipTD";
import { ExpandCircleDown } from "@mui/icons-material";

import { getNextSize } from "./soTemplateHelper";

function CollapseExpandButtons({ tableSize, dispatchGanttChartTemplate }) {
  
  return (
    <div className={cx("expandCollapseContainer", tableSize)}>
      <TooltipTD
        label={"Collapse Table"}
        showToolTip={tableSize !== GANTTCHART_TABLE_SIZE.SMALL}
      >
        {tableSize !== GANTTCHART_TABLE_SIZE.SMALL && (
          <ExpandCircleDown
            onClick={() => {
              dispatchGanttChartTemplate({
                type: GANTT_CHART_ACTION.COMMON,
                payload: {
                  tableSize: getNextSize({ tableSize, reverse: false }),
                },
              });
            }}
            className={"expand-collapse-icon"}
          />
        )}
      </TooltipTD>
      <TooltipTD
        label={"Expand Table"}
        showToolTip={tableSize !== GANTTCHART_TABLE_SIZE.LARGE}
      >
        {tableSize !== GANTTCHART_TABLE_SIZE.LARGE && (
          <ExpandCircleDown
            onClick={() => {
              dispatchGanttChartTemplate({
                type: GANTT_CHART_ACTION.COMMON,
                payload: {
                  tableSize: getNextSize({ tableSize, reverse: true }),
                },
              });
            }}
            className={cx("expand-collapse-icon", "rotateIcon")}
          />
        )}
      </TooltipTD>
    </div>
  );
}

export default CollapseExpandButtons;
