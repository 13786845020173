import React, { useCallback, useEffect } from "react";
import { useCompanyId } from "../../hooks";
import { dbTables } from "../../api/types/dbTables";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { listenToDocument } from "../../helpers/helpers";
import { useIsAllowedFunction } from "../../hooks/permissions";
import { useFactories } from "../../hooks/factories";
import { useSelector, useDispatch } from "react-redux";
import { GENERAL_PERMISSION_VALUE } from "../../helpers/constants";
import { useUser } from "../../hooks/user";

// import { trackEvent } from "../../helpers/analytics";

function FactoryLayout() {
  const user = useUser();
  const navigate = useNavigate();
  const companyId = useCompanyId();
  const dispatch = useDispatch();
  const factories = useFactories();
  const { factoryId } = useParams();
  const isAllowed = useIsAllowedFunction();
  const factoriesWitPermission = factories.filter(
    (factory) =>
      isAllowed(factory.id) || isAllowed(GENERAL_PERMISSION_VALUE.ALL_VENDORS)
  );
  const unsubscribeCurrentFactory = useSelector(
    (state) => state.data.unsubscribeCurrentFactory
  );

  const listenFactory = useCallback((factoryId) =>
    listenToDocument({
      table: dbTables.CURRENT_FACTORY,
      path: [dbTables.COMPANIES, companyId, dbTables.FACTORIES, factoryId],
      keyName: "factory",
    })(dispatch)
  );

  useEffect(() => {
    if (!factoryId || factoryId === "empty") {
      return;
    }
    if (unsubscribeCurrentFactory) {
      unsubscribeCurrentFactory();
    }
    listenFactory(factoryId);
  }, [factoryId]);

  useEffect(() => {
    if (
      factoriesWitPermission.length > 0 &&
      (!factoryId || factoryId === "empty")
    ) {
      return navigate(`/app/factories/${factoriesWitPermission[0].id}`, {
        replace: true,
      });
    } else if (factoriesWitPermission.length === 0 && !factoryId) {
      return navigate("/app/factories/empty", {
        replace: true,
      });
    }
  }, [user.permissions, factoryId]);

  return <Outlet />;
}

export default FactoryLayout;
