import React, { useState, useEffect } from "react";
import { colors } from "../../assets/jss/variables";
import { NavLink } from "react-router-dom";
import {
  getCompanyUserDisplayName,
  getDateByTimezone,
  getTagsLabel,
  getTheDate,
  isTradeDashEmployee,
} from "../../helpers/helpers";
import TooltipTD from "../../components/Tooltip/TooltipTD";
import { useStorage } from "../../hooks";
import TagsModal from "../../components/Modal/TagsModal";
import {
  headerColumns,
  LOCAL_STORAGE_KEY,
  taskStatus,
} from "../../helpers/constants";
import { getUserAvatar } from "../../helpers/users";
import { getNumberOfLateDays } from "../../helpers/tasks";
import { dbTables } from "../../api/types/dbTables";
import CustomCheckbox from "../../components/Inputs/CustomCheckbox";
import { getShipmentBadge } from "../../helpers/shipments";

import moment from "moment";
import {
  getTagStatus,
  PO_TYPES,
  SALES_ORDER_TYPES,
} from "../../helpers/salesOrder";
import { getBackgroundColorByType } from "../../helpers/orderDashboard";
import { TableCell } from "@mui/material";
import { shipmentBadgeClass } from "../StyledComponent/BadgeStyled";
import {
  MainRowStyled,
  mainRowClasses,
} from "../../components/filterTableRefactor/MainRowStyled";
import { CheckBoxIcon, DiamondIcon } from "../../helpers/iconSvgPath";
import ThreePanelButton from "../../components/Buttons/ThreePanelButton";
import { cx } from "@emotion/css";
import SalesOrderBadge from "../../components/Badge/SalesOrderBadge";
import POBadge from "../../components/Badge/POBadge";
import { getVerificationPath } from "../../components/Sidebar/sidebarHelper";

function TaskTableRow({
  task: item,
  index,
  onComplete,
  companyUsers,
  filters,
  tags = [],
  onUpdateItem,
  user,
  isAllowed,
  headerCells,
  isThereColumnSetting = false,
}) {
  const [completing, setCompleting] = useState(false);
  const [completed, setCompleted] = useState(false);

  const filterTag = filters.tag || [];
  const purchaseOrder = item.purchaseOrder || {};
  let purchaseOrderId = purchaseOrder.id || item.purchaseOrderId;
  if (!purchaseOrderId) {
    const salesOrder = item.salesOrder || {};
    const purchaseOrderIds = salesOrder.purchaseOrderIds || [];
    purchaseOrderId = purchaseOrderIds[0];
  }
  const [, setStorage] = useStorage(LOCAL_STORAGE_KEY.projectTasks);

  useEffect(() => {
    if (completed) {
      onComplete(item, index);
      setCompleting(false);
    }
  }, [completed]);

  function taskTableRowSelector({ column, enabled, index }) {
    const {
      purchaseOrder: currentPO = {},
      shipment: currentShipment = {},
      salesOrder: currentSO = {},
    } = item;

    let entity = {};
    let scope = "";
    if (SALES_ORDER_TYPES.includes(item.type)) {
      entity = currentSO;
      scope = dbTables.SALES_ORDERS;
    } else if (PO_TYPES.includes(item.type)) {
      entity = currentPO;
      scope = dbTables.PURCHASE_ORDERS;
    } else {
      entity = currentShipment;
      scope = dbTables.SHIPMENTS;
    }

    switch (column) {
      case headerColumns.DIAMOND:
        return (
          <TableCell key={index} className="diamond">
            {item.status === "late" && (
              <TooltipTD label={getNumberOfLateDays({ task: item })}>
                <DiamondIcon color={colors.diamondRed} height={16} />
              </TooltipTD>
            )}
            {item.status === "nearDue" && (
              <TooltipTD label="Due Today or Tomorrow">
                <DiamondIcon color={colors.diamondOrange} height={16} />
              </TooltipTD>
            )}
            {item.status === "inProgress" && (
              <TooltipTD label="In Progress">
                <DiamondIcon color={colors.diamondGreen} height={16} />
              </TooltipTD>
            )}
            {item.status === "notStarted" && (
              <TooltipTD label="Not Started">
                <DiamondIcon
                  color={"white"}
                  strokeColor={colors.diamondWhiteBorder}
                  height={16}
                />
              </TooltipTD>
            )}
            {item.complete && (
              <TooltipTD label="Completed">
                <CheckBoxIcon width={15} height={15} color="#6B7A89" />
              </TooltipTD>
            )}
          </TableCell>
        );

      case headerColumns.TYPE: {
        let currentTags = [];
        let field = "";
        if (SALES_ORDER_TYPES.includes(item.type)) {
          currentTags = currentSO.tags;
          field = "salesOrderId";
        } else if (PO_TYPES.includes(item.type)) {
          currentTags = currentPO.tags;
          field = "purchaseOrderId";
        } else {
          currentTags = currentShipment.tags;
          field = "shipmentId";
        }
        const tagStatus = getTagStatus({
          tags: entity.tags,
          currentTags: filterTag,
        });
        return (
          enabled && (
            <TableCell key={index} className="SONumber">
              <div className="number-table-container">
                {isAllowed("see_tags") && (
                  <TagsModal
                    label={getTagsLabel(tags, currentTags).tagsString}
                    tags={getTagsLabel(tags, currentTags).tagsArray}
                    allTags={tags}
                    item={{
                      ...entity,
                      taskId: item.id,
                      [field]: entity.id,
                    }}
                    currentUser={user}
                    tagReference={scope}
                    onUpdateItem={onUpdateItem}
                    isThereItemRef={false}
                    isReadOnly={isTradeDashEmployee(user)}
                    status={tagStatus}
                  />
                )}

                <NavLink
                  to={`/app/${getVerificationPath({ id: entity.id, scope })}`}
                  className={"navLink"}
                  id="list-view-content-salesorder"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  {SALES_ORDER_TYPES.includes(item.type) ? (
                    <SalesOrderBadge
                      number={item.SONumber}
                      className="sales-order-badge-white"
                    />
                  ) : PO_TYPES.includes(item.type) ? (
                    <POBadge
                      number={item.PONumber}
                      className="purchase-order-badge-white"
                    />
                  ) : (
                    getShipmentBadge({
                      shipment: currentShipment,
                      classes: shipmentBadgeClass.mediumInverted,
                      id: "mediumInverted",
                    })
                  )}
                </NavLink>
                <ThreePanelButton
                  alternativePath={`/app/${getVerificationPath({
                    id: entity.id,
                    scope,
                    toThreePanel: true,
                  })}`}
                />
              </div>
            </TableCell>
          )
        );
      }

      case headerColumns.TASK:
        return (
          enabled && (
            <TableCell key={index} className="description">
              <div className="description-wrapper">
                <div className="task-description-container">
                  <TooltipTD label={item.description}>
                    <NavLink
                      className={"ellipsisText"}
                      to={`/app/${getVerificationPath({
                        id: entity.id,
                        scope,
                        toThreePanel: true,
                      })}&&taskId=${item.id}`}
                      onClick={() => {
                        setStorage("taskTab", 0);
                        setStorage("taskFilter", "ALL");
                        if (item.complete) {
                          setStorage("showCompleted", true);
                        }
                      }}
                      onContextMenu={() => {
                        setStorage("taskTab", 0);
                        setStorage("taskFilter", "ALL");
                        if (item.complete) {
                          setStorage("showCompleted", true);
                        }
                      }}
                      id="list-view-content-taskname"
                    >
                      <span
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          width: "100%",
                        }}
                      >
                        {item.description}
                      </span>
                    </NavLink>
                  </TooltipTD>
                </div>
              </div>
            </TableCell>
          )
        );

      case headerColumns.CUSTOMER:
        return (
          enabled && (
            <TableCell key={index} className={"customerName"}>
              <TooltipTD
                label={item.customerName}
                className="customized-tooltip"
              >
                <NavLink
                  to={`/app/customers/${item.customerId}`}
                  id="list-view-content-customer"
                  className={"navLink"}
                >
                  <span className="span-description">{item.customerName}</span>
                </NavLink>
              </TooltipTD>
            </TableCell>
          )
        );

      case headerColumns.VENDOR:
        return (
          enabled && (
            <TableCell key={index} className={"factoryName"}>
              <TooltipTD
                label={item.factoryName}
                className="customized-tooltip"
              >
                <NavLink
                  to={`/app/factories/${item.factoryId}`}
                  id="list-view-content-factory"
                  className={"navLink"}
                >
                  <span className="span-description">{item.factoryName}</span>
                </NavLink>
              </TooltipTD>
            </TableCell>
          )
        );

      case headerColumns.ASSIGNED_TO:
        return (
          enabled && (
            <TableCell key={index} className="assignedToName">
              {companyUsers.find((cu) => cu.id === item.assignedTo) ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {getUserAvatar({
                    user: companyUsers.find(
                      (companyUser) => companyUser.id === item.assignedTo
                    ),
                    styles: {
                      width: 24,
                      height: 24,
                      fontSize: 13,
                      outline: "2px solid #000",
                    },
                  })}
                  <TooltipTD
                    label={getCompanyUserDisplayName(
                      companyUsers,
                      item.assignedTo
                    )}
                    className="customized-tooltip"
                  >
                    <span
                      style={{
                        position: "relative",
                        paddingLeft: 5,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {getCompanyUserDisplayName(companyUsers, item.assignedTo)}
                    </span>
                  </TooltipTD>
                </div>
              ) : (
                ""
              )}
            </TableCell>
          )
        );

      case headerColumns.START_DATE:
        return (
          enabled && (
            <TableCell key={index} className="date" id="startDate">
              {getTheDate(
                moment(
                  getDateByTimezone({
                    date: item.startDate,
                  })
                ),
                "M/D/YY"
              )}
            </TableCell>
          )
        );

      case headerColumns.DUE_DATE:
        return (
          enabled && (
            <TableCell key={index} className="date" id="dueDate">
              {getTheDate(
                moment(
                  getDateByTimezone({
                    date: item.finishDate,
                  })
                ),
                "M/D/YY"
              )}
            </TableCell>
          )
        );

      case headerColumns.ACTION:
        return (
          enabled && (
            <TableCell key={index} className="checkBoxCell" id="actionButton">
              <div
                className={cx({ checkBox: !item.complete })}
                style={{ paddingInline: 12 }}
              >
                <CustomCheckbox
                  color={item.complete ? "#6B7A89" : ""}
                  checked={item.complete || false}
                  onClick={() => {
                    if (!item.complete) {
                      setCompleting(true);
                      setTimeout(() => setCompleted(true), 1000);
                    }
                  }}
                  iconSize={22}
                  styles={{ padding: 0 }}
                  disabled={item.complete || completing}
                />
                {!item.complete && (
                  <span className={"checkBoxMarkDone"}>Mark Done</span>
                )}
              </div>
              {completing && <div className={"completing-task"}></div>}
            </TableCell>
          )
        );

      case headerColumns.EXTRA:
        return enabled && <TableCell key={index} />;

      default:
        return <TableCell key={index} />;
    }
  }
  return (
    <MainRowStyled
      key={
        item.purchaseOrderId
          ? item.id + item.purchaseOrderId
          : item.id + item.salesOrderId
      }
      className={mainRowClasses.TaskTableRow}
      style={{
        background: getBackgroundColorByType({ type: item.type }),
        opacity: item.status === taskStatus.COMPLETE ? 0.5 : 1,
      }}
    >
      {headerCells.map((header) =>
        taskTableRowSelector({
          column: header.name,
          enabled: header.enabled,
          index: item.id + header.name,
        })
      )}
      {isThereColumnSetting && <TableCell />}
    </MainRowStyled>
  );
}

export default TaskTableRow;
