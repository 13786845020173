import { createStore, applyMiddleware, compose } from "redux";
import Thunk from "redux-thunk";
import reducers from "../reducers";

export function configureStore(initialState = {}) {
  const store = createStore(
    reducers,
    initialState,
    compose(applyMiddleware(Thunk))
  );

  return store;
}
