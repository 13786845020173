import React, { useState } from "react";
import addDocumentIcon from "../../assets/flag-icons/add-document-2.svg";
import uploadDocumentIcon from "../../assets/flag-icons/upload-document-2.svg";
import SearchLabel from "../Inputs/SearchLabel";
import { ManagementDocumentHeaderStyled, PopoverSortingStyled } from "./styles";
import { colors } from "../../assets/jss/variables";
import ModalCreateFolderDocument from "./ModalCreateFolderDocument";
import { dbTables } from "../../api/types/dbTables";
import { cx } from "@emotion/css";
import TooltipTD from "../Tooltip/TooltipTD";
import { HiddenDataIcon } from "../../helpers/iconSvgPath";
import { Button } from "@mui/material";
import { TYPE_OF_FILE } from "../../helpers/constants";

const sortByList = {
  byName: "name",
  byChange: "lastModifiedDate",
};

function ManagementDocumentsHeader({
  handleAddNewFile,
  disabled,
  handleExpandOrCollapse,
  isExpand,
  onQuerySearch = () => {},
  documentPath = "",
  hiddenActionIcon = false,
  searchLabelId = "management-documents-search-box",
  parentCollection,
  sortBy = sortByList.byName,
  handleSorting = () => {},
  style = {},
  searchStyle = {},
  type,
  allDocuments = [],
}) {
  const [cleanSearchBox, setCleanSearchBox] = useState(false);
  const [openNewFolder, setOpenNewFolder] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const DOCUMENTS_WITH_TITLE = [dbTables.FACTORIES, dbTables.CUSTOMERS];

  const handleClose = () => setAnchorEl(false);

  return (
    <ManagementDocumentHeaderStyled className="documentsHeader" style={style}>
      {openNewFolder && (
        <ModalCreateFolderDocument
          open={openNewFolder}
          title="Create New Folder"
          isNew
          orderPath={documentPath}
          documentTree={allDocuments.filter(
            (doc) =>
              doc.parentId === "" && doc && doc.type === TYPE_OF_FILE.FOLDER
          )}
          handleClose={() => setOpenNewFolder(false)}
        />
      )}
      {!!anchorEl && (
        <PopoverSortingStyled
          id="sortingPopoverDocument"
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={() => handleClose()}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          classes={{
            paper: "popoverPaper",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="list">
            <div
              className={cx("option", {
                selected: sortBy === sortByList.byName,
              })}
              onClick={() => {
                handleSorting({ sortBy: sortByList.byName, asc: false });
                handleClose();
              }}
            >
              Alphabetical
            </div>
            <div
              className={cx("option", {
                selected: sortBy === sortByList.byChange,
              })}
              onClick={() => {
                handleSorting({ sortBy: sortByList.byChange, asc: true });
                handleClose();
              }}
            >
              Most recently modified
            </div>
          </div>
        </PopoverSortingStyled>
      )}
      <Button
        className="sortOrderButton"
        onClick={(ev) => {
          setAnchorEl(ev.currentTarget);
          ev.stopPropagation();
        }}
      >
        Sort Order
      </Button>
      <div
        style={{
          width: 2,
          minWidth: 2,
          height: 20,
          backgroundColor: colors.primaryDark,
          marginLeft: 6,
        }}
      />
      <div className={cx(`${parentCollection}Button`, "iconContainer")}>
        <TooltipTD
          label={isExpand ? "Collapse all folders" : "Expand all folders"}
          style={{ display: "flex" }}
        >
          <HiddenDataIcon
            onClick={() => handleExpandOrCollapse(!isExpand)}
            svgClass="expandRow"
          />
        </TooltipTD>
      </div>
      {DOCUMENTS_WITH_TITLE.includes(parentCollection) && (
        <span
          style={{
            color: colors.primaryDark,
            fontSize: 17,
            fontWeight: "bold",
            margin: "0px 8px",
          }}
        >
          Documents
        </span>
      )}
      <div className="documentsHeaderButtonAddDocument">
        <SearchLabel
          cookie={parentCollection}
          returnQuery={(query) => {
            onQuerySearch(query);
          }}
          placeholderOnExpand="Search for document name"
          cleanSearchBox={cleanSearchBox}
          onDisableCleanSearchBox={() => setCleanSearchBox(false)}
          storageField="documentsearch"
          searchLabelId={searchLabelId}
          styles={searchStyle}
          type={type}
        />

        {!hiddenActionIcon && (
          <img
            className="documentsIconAddDocument"
            onClick={disabled ? null : () => setOpenNewFolder(true)}
            src={addDocumentIcon}
            alt="add-document"
            height={18}
            width={18}
            style={{ cursor: disabled ? "unset" : "" }}
          />
        )}
        {!hiddenActionIcon && (
          <img
            className="documentsIconAddDocument"
            onClick={disabled ? null : handleAddNewFile}
            src={uploadDocumentIcon}
            alt="add-document"
            width={21}
            style={{ cursor: disabled ? "unset" : "" }}
          />
        )}
      </div>
    </ManagementDocumentHeaderStyled>
  );
}

export default ManagementDocumentsHeader;
