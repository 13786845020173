import { Modal } from "@mui/material";
import React from "react";

function CustomizedModal({ open, onClose, body = null }) {
  return (
    <Modal open={open} onClose={onClose}>
      {body}
    </Modal>
  );
}

export default CustomizedModal;
