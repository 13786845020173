import React, { useState, useEffect } from "react";
import CustomModal from "../Modal/Modal";
import FormTextField from "../TextFields/FormTextField";
import CustomButton from "../Buttons/CustomButton";
import IntlMessages from "../../util/IntlMessages";

function FeatureFlagForm({ openModal, closeModal, featureFlag, onSubmit }) {
  const [currentFeatureFlag, setCurrentFeatureFlag] = useState({});

  useEffect(() => {
    setCurrentFeatureFlag({ ...featureFlag });
  }, [featureFlag]);

  function handleChange(value, field) {
    setCurrentFeatureFlag({ ...currentFeatureFlag, [field]: value });
  }

  function verifyCurrentFeatureName(featureFlag) {
    if (!featureFlag.name || !featureFlag.name.toString().trim()) {
      return true;
    }
    return false;
  }

  return (
    <CustomModal
      header={<IntlMessages id={"permissionGroup.create.custommodal"} />}
      isOpen={openModal}
      onClose={closeModal}
    >
      <FormTextField
        value={currentFeatureFlag.name}
        onChange={(ev) => handleChange(ev.target.value, "name")}
        label="generic.textForm.name"
      />
      <FormTextField
        value={currentFeatureFlag.description}
        onChange={(ev) => handleChange(ev.target.value, "description")}
        label="featureFlag.description.formtextfield"
      />
      <CustomButton onClick={closeModal} type="cancel">
        {"generic.text.cancel"}
      </CustomButton>
      <CustomButton
        onClick={() => onSubmit(currentFeatureFlag)}
        disabled={verifyCurrentFeatureName(currentFeatureFlag)}
        type="success"
      >
        {"generic.text.create"}
      </CustomButton>
    </CustomModal>
  );
}

export default FeatureFlagForm;
