/**
 * Maps Routes
 */
import React from "react";
import OrderDashboardView from "../../components/PurchaseOrderDashboard/OrderDashboardView";
import DashboardNavigationBar from "../../components/PurchaseOrderDashboard/DashboardNavigationBar";
import { useUser } from "../../hooks/user";

function PurchaseOrderDashboard() {
  const user = useUser();
  return (
    <div className="content-wrapper">
      <DashboardNavigationBar user={user} />
      <OrderDashboardView />
    </div>
  );
}

export default PurchaseOrderDashboard;
