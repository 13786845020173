import React from "react";
import AttentionModal from "./AttentionModal";

function ReadOnlyModal({ isOpen, onClose, isRegularUser }) {
  return (
    <AttentionModal
      isOpen={isOpen}
      onClose={onClose}
      onClick={onClose}
      description={
        <React.Fragment>
          {isRegularUser
            ? "This current order is currently in read-only mode."
            : "Your user has read-only access"}
        </React.Fragment>
      }
      title="Attention"
    />
  );
}

export default ReadOnlyModal;
