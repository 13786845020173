import { colors } from "../../assets/jss/variables";
import { styled } from "@mui/material";
import CustomModal from "../Modal/Modal";

export const UserAvatarModalStyled = styled(CustomModal)(() => ({
  "& .container": {
    width: 480,
    height: "100%",
    backgroundColor: "white",
    boxShadow: "11px 0px 21px 2px rgba(9,33,53,0.24)",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  "& .modal": {
    display: "flex !important",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    "& .modal-dialog": {
      color: "black",
      margin: 8,
      maxWidth: "unset",
      "& .modal-content": {
        width: 900,
        height: 400,
        "& .modal-body": {
          width: 900,
          padding: 0,
          height: 400,
        },
      },
    },
  },

  "& .modalContainer": {
    display: "flex",
    alignContent: "center",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "20px 0px 10px 0px",
    height: 340,
    "& .columnPreview": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& .cancelImage": {
        height: 20,
        position: "absolute",
        right: 10,
        top: 10,
        color: colors.diamondRed,
        background: "white",
        opacity: 0.9,
        border: " 1px solid white",
        borderRadius: 50,
        cursor: "pointer",
      },
      "& .dropzone": {
        textAlign: "center",
        padding: 20,
        border: "3px dashed #eeeeee",
        backgroundColor: "#fafafa",
        color: " #bdbdbd",
        width: 200,
        height: 200,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      },
    },
  },
  "& .modalBottons": {
    float: "right",
    padding: "0px 20px  10px 0px",
    display: "flex",
  },

  "& .slideRight": {
    animationName: "$slide-in-right",
    animationDuration: "0.2s",
  },
  "& .slideLeft": {
    animationName: "$slide-in-left",
    animationDuration: "0.2s",
  },
  "@keyframes slide-in-right": {
    from: {
      width: 0,
    },
    to: {
      width: 350,
    },
  },
  "@keyframes slide-in-left": {
    from: {
      width: 350,
    },
    to: {
      width: 0,
    },
  },
}));

export const UserSettingsDrawerStyled = styled("div")(() => ({
  "&.setting": {
    backgroundColor: "#F3F5F7",
    padding: "21px 0px 13px 0px",
    marginBottom: 10,
    fontSize: 13,
    width: "100%",
    "& .buttonSetting": {
      background: colors.proceedButtonColor,
      color: "white",
      fontSize: 12,
      padding: "0px 10px",
      width: 70,
      lineHeight: "15px",
    },
    "& .switchRow": {
      height: 35,
      display: "flex",
      alignItems: "center",
      marginBottom: 15,
      padding: "0px 19px 0px 14px",
      "& .settingLabel": {
        margin: 0,
        marginLeft: 9,
        fontSize: 14,
        fontWeight: 400,
        textAlign: "left",
      },
    },
    "& .settingDescription": {
      display: "flex",
      alignItems: "center",
      margin: "20px 19px 0px 14px",
      fontSize: 12,
      fontWeight: 400,
      textAlign: "left",
    },
  },
}));

export const UserDrawerContentStyled = styled("div")(() => ({
  "&.userDrawerContainer": {
    backgroundColor: "#F3F5F7",
    padding: "18px 20px 14px 16px",
    fontSize: 13,
    margin: "0px 12px",
    "& .phoneInputError": {
      "& .special-label": {
        color: colors.diamondRed,
      },

      "& .form-control": {
        borderColor: `${colors.diamondRed} !important`,
      },
      "& .flag-dropdown": {
        borderColor: `${colors.diamondRed} !important`,
      },
    },
    "& .phoneInput": {
      height: 35,
      width: "100%",
      border: "unset",
      boxShadow: "unset",
      "&:focus": {
        boxShadow: "unset",
      },
      "& .special-label": {
        fontSize: 10,
        background: "#F3F5F7",
        color: colors.borderGray,
        fontVariant: "small-caps",
        display: "block",
        marginTop: 2,
      },

      "& .form-control": {
        height: 35,
        width: "100%",
        fontSize: 14,
        borderColor: `${colors.borderGray} !important`,
        boxShadow: "unset",
        "&:focus": {
          boxShadow: "unset",
        },
      },
      "& .flag-dropdown": {
        borderColor: `${colors.borderGray} !important`,
        boxShadow: "unset",
        height: 38,
        border: 0,
        borderRight: "1px  solid",
        "&:focus": {
          boxShadow: "unset",
        },
      },
    },
    "& .timezoneAutocompleteError": {
      width: "100%",
      "& .MuiFormControl-root": {
        "& .MuiInputLabel-formControl": {
          color: colors.diamondRed,
        },
        "& .MuiInputBase-root": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.diamondRed,
          },
        },
      },
    },

    "& .timezoneAutocomplete": {
      width: "100%",
      "& .MuiFormControl-root": {
        "& fieldset": {
          "& legend": {
            maxWidth: 65,
            "& span": {
              padding: 0,
            },
          },
        },
        "& .MuiInputLabel-formControl": {
          fontVariant: "small-caps",
          fontSize: 13,
          color: colors.borderGray,
        },
        "& .MuiInputBase-root": {
          background: "white",
          fontSize: 14,
          color: colors.primaryDark,
          padding: 0,
          height: 35,

          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.borderGray,
            border: `1px solid`,
          },
        },
        "& .MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-clearIndicator":
          {
            visibility: "hidden",
          },
        "& .MuiAutocomplete-popupIndicator": {
          padding: 0,
          marginTop: -5,
          background: "unset",
        },
      },
    },

    "& .errorInformationField": {
      "& .MuiFormLabel-root": {
        color: colors.diamondRed,
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: colors.diamondRed,
        },
        "&:hover fieldset": {
          borderColor: colors.diamondRed,
        },
        "&.Mui-focused fieldset": {
          borderColor: colors.diamondRed,
        },
      },
      "& .MuiFormHelperText-root": {
        color: colors.diamondRed,
      },
    },
    "& .userInformationField": {
      border: 0,
      margin: 0,
      display: "inline-flex",
      padding: 0,
      position: "relative",
      minWidth: 0,
      flexDirection: "column",
      verticalAlign: "top",
      fontSize: 12,
      width: "100%",
      "& .MuiFormLabel-root": {
        fontWeight: 400,
        fontVariant: "small-caps",
        background: "transparent",
        fontSize: 13,
        color: colors.borderGray,
      },

      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderWidth: 1,
          borderColor: colors.borderGray,
          "&:hover fieldset": {
            borderColor: colors.borderGray,
          },
          "&.Mui-focused fieldset": {
            borderColor: colors.borderGray,
          },
          "& legend": {
            maxWidth: 65,
            "& span": {
              padding: 0,
            },
          },
        },
        "&:hover fieldset": {
          borderWidth: 1,
        },
        "&.Mui-focused fieldset": {
          borderWidth: 1,
        },
      },
      "& .MuiFormHelperText-root": {
        fontSize: 9,
        position: "absolute",
        marginTop: 35,
        marginLeft: 5,
        color: colors.borderGray,
      },
      "& .MuiOutlinedInput-adornedStart": {
        background: "white",
      },
    },
    "& .dataGrid": {
      display: "grid",
      height: "auto",
      gridTemplateColumns: "repeat(2, 1fr)",
      gridGap: "33px 20px",
      width: "100%",
      "& .dataColumn": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    "& .permissionList": {
      "& .permissionTitleList": {
        fontSize: 14,
        color: colors.borderGray,
        fontVariant: "small-caps",
      },
      "& .titleList": {
        color: colors.borderGray,
        "& .MuiListItemText-root": {
          margin: 0,
          "& span": {
            fontSize: 12,
          },
        },
      },
    },
  },

  "& .changePhoto": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: " 6px 10px",
    width: 54,
    height: 24,
    background: colors.borderGray,
    border: `0.75px solid ${colors.borderGray}`,
    borderRadius: 11,
    minWidth: 54,
    marginLeft: 18,
    fontSize: 10,
    color: "white",
    "&:hover": {
      background: colors.borderGray,
    },
  },
}));
