import React from "react";

import { Switch } from "@mui/material";
import { cx } from "@emotion/css";
import TooltipTD from "../../Tooltip/TooltipTD";
import { getUserAvatar } from "../../../helpers/users";
import userTypes from "../../../api/types/userTypes";
import { updateDoc } from "firebase/firestore";
import { colors } from "../../../assets/jss/variables";
import { EditIcon } from "../../../helpers/iconSvgPath";

function UserCompanyTableRow({
  users,
  userItem,
  namePermissionGroups,
  canChangeUserStatus,
  handleOpenModal = () => {},
  onUserUpdate = () => {},
  canEditUser,
  hiddenData,
}) {
  return (
    <div className="rowTable">
      <div className={cx("cellTable", "name")}>{userItem.firstName}</div>
      <div className={cx("cellTable", "name")}>{userItem.lastName}</div>
      <div className={cx("cellTable", "name")}>@{userItem.displayName}</div>
      <div className={cx("cellTable", "name")}>{userItem.cellPhone}</div>
      <TooltipTD label={userItem.email} className={cx("cellTable", "name")}>
        {userItem.email}
      </TooltipTD>
      <div className={cx("cellTable", "name")}>{userItem.role}</div>
      <TooltipTD
        label={namePermissionGroups.join(", ")}
        className={cx("cellTable", "name")}
      >
        {namePermissionGroups.join(", ")}
      </TooltipTD>

      {!hiddenData && (
        <div className={cx("cellTable", "name")}>{userItem.typeOfUser}</div>
      )}
      {!hiddenData && (
        <div className={cx("cellTable", "name")}>
          <Switch
            checked={userItem.automaticSystemUser}
            onChange={(ev) => {
              updateDoc(userItem.ref, {
                automaticSystemUser: !userItem.automaticSystemUser,
              });
              ev.stopPropagation();
            }}
            value={userItem.automaticSystemUser}
            color="primary"
            inputProps={{ "aria-label": "primary checkbox" }}
            size="small"
          />
        </div>
      )}
      <div className={cx("cellTable", "avatar")}>
        {getUserAvatar({
          user: userItem,
          styles: {
            outline: "2px solid #000",
            width: 35,
            height: 35,
          },
        })}
      </div>
      <div className={cx("cellTable", "actionButtonsContainer")}>
        <TooltipTD
          label={userItem.active ? "Deactivate" : "Activate"}
          style={{ display: "flex" }}
        >
          <Switch
            checked={userItem.active}
            value={userItem.active}
            color="primary"
            inputProps={{ "aria-label": "primary checkbox" }}
            disabled={!canChangeUserStatus}
            onChange={(e) => {
              const adminUsers = users.filter(
                (user) => user.role === userTypes.SUPER_ADMIN && user.active
              );
              if (
                userItem.role === userTypes.SUPER_ADMIN &&
                adminUsers.length <= 1 &&
                userItem.active
              ) {
                handleOpenModal(userItem);
              } else {
                updateDoc(userItem.ref, { active: !userItem.active });
              }
              e.stopPropagation();
            }}
            style={{
              color: colors.primaryDark,
            }}
            size="small"
          />
        </TooltipTD>
        {canEditUser && (
          <TooltipTD
            label="Edit"
            style={{ display: "flex", cursor: "pointer" }}
            tooltipClick={onUserUpdate}
          >
            <EditIcon />
          </TooltipTD>
        )}
      </div>
    </div>
  );
}

export default UserCompanyTableRow;
