import { now } from "moment";

export default class Notification {
  constructor({
    id = "",
    creationDate = now,
    customerId = "",
    customerName = "",
    factoryId = "",
    factoryName = "",
    companyId = "",
    salesOrderId = "",
    purchaseOrderId = "",
    PONumber = "",
    SONumber = "",
    shipmentNumber = "",
    shipmentId = "",
    type = "",
    read = false,
    newNotification = true,
    scope = "",
    assignedBy = "",
    assignedTo = "",
    complete = "",
    completedBy = "",
    dayOffset = 0,
    description = "",
    updatedBy = "",
    finishDate = "",
    confirmedTask = false,
    SOT = false,
    createdBy = "",
    mainDocumentId = "",
    shipment = {},
  }) {
    this.id = id;
    this.creationDate = creationDate;
    this.customerId = customerId;
    this.factoryId = factoryId;
    this.factoryName = factoryName;
    this.customerName = customerName;
    this.companyId = companyId;
    this.salesOrderId = salesOrderId;
    this.purchaseOrderId = purchaseOrderId;
    this.PONumber = PONumber;
    this.SONumber = SONumber;
    this.shipmentNumber = shipmentNumber;
    this.type = type;
    this.read = read;
    this.newNotification = newNotification;
    this.scope = scope;
    this.assignedBy = assignedBy;
    this.assignedTo = assignedTo;
    this.complete = complete;
    this.completedBy = completedBy;
    this.dayOffset = dayOffset;
    this.description = description;
    this.updatedBy = updatedBy;
    this.finishDate = finishDate;
    this.confirmedTask = confirmedTask;
    this.SOT = SOT;
    this.createdBy = createdBy;
    this.mainDocumentId = mainDocumentId;
    this.shipmentId = shipmentId;
    this.shipment = shipment;
  }
}
