import React from "react";
import SearchBox from "../SearchBox/SearchBox";
import { NotificationSearchboxStyled } from "./styles";

function NotificationsHeader(props) {
  return (
    <NotificationSearchboxStyled className="notificationHeaderSearchbox">
      <SearchBox
        placeholder="Search notifications"
        style={{ width: "100%", alignItems: "center", display: "flex" }}
        value={props.searchQuery}
        customStyle={"search"}
        hasBeenReseted={() => props.onChangeSearch("")}
        onDebounceValue={(filters, value) => {
          props.onChangeSearch(value);
        }}
      />
    </NotificationSearchboxStyled>
  );
}

export default NotificationsHeader;
