import { styled } from "@mui/material";
import { colors } from "../../assets/jss/variables";

export const ProgressStageStyled = styled("div")(() => ({
  "&.progressRoot": {
    display: "flex",
    alignItems: "left",
    width: "fit-content",
  },
  "& .ellipsisText": {
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "100%",
    color: colors.primaryDark,
    textDecoration: "underline",
    display: "flex",
    width: "100%",
  },
}));
