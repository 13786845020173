import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import searchIcon from "../../assets/flag-icons/search_activity.svg";
import { InputBase } from "@mui/material";

export const InputAutocomplete = ({
  inputValue,
  placeholder,
  setInputValue,
  params,
}) => {
  
  const iconEndAdornment = () =>
    inputValue ? (
      <CancelIcon
        className="buttonX"
        onClick={() => {
          setInputValue("");
        }}
      />
    ) : (
      <img
        src={searchIcon}
        alt=""
        width="14px"
        height="14px"
        style={{
          marginRight: 10,
          position: "absolute",
          right: 8,
        }}
      />
    );

  return (
    <InputBase
      ref={params.InputProps.ref}
      inputProps={{
        ...params.inputProps,
        value: inputValue,
      }}
      autoFocus
      className={"inputBase"}
      classes={{ input: "rootInput" }}
      value={inputValue}
      placeholder={placeholder}
      endAdornment={iconEndAdornment()}
    />
  );
};
