import React from "react";
import { Switch } from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import { colors } from "../../../assets/jss/variables";
import { cx } from "@emotion/css";
import { firestore } from "../../../firebase";
import { dbTables } from "../../../api/types/dbTables";
import TooltipTD from "../../Tooltip/TooltipTD";
import { EditIcon } from "../../../helpers/iconSvgPath";

function GLTableRow({
  GLAccountItem = {},
  GLAccounts,
  companyId,
  onEditAccountItem,
}) {
  return (
    <div className="rowTable" key={GLAccountItem.id}>
      <div className={cx("cellTable", "name")}>{GLAccountItem.name}</div>
      <div className={cx("cellTable", )}>
        <span className="description">{GLAccountItem.description}</span>
      </div>
      <div className={cx("cellTable", "actionButtonsContainer")}>
        <TooltipTD
          label={GLAccountItem.active.active ? "Deactivate" : "Activate"}
          style={{ display: "flex" }}
        >
          <Switch
            checked={GLAccountItem.active}
            value={GLAccountItem.active}
            color="primary"
            inputProps={{ "aria-label": "primary checkbox" }}
            onChange={(ev, checked) => {
              const GLAccount = GLAccounts.find(
                (account) => account.id === GLAccountItem.summaryId
              );
              let accountsCpy = [...GLAccount.accounts];
              accountsCpy = accountsCpy.map((accountCpy) => {
                if (accountCpy.id === GLAccountItem.id) {
                  return { ...accountCpy, active: checked };
                }
                return accountCpy;
              });
              updateDoc(
                doc(
                  firestore,
                  `${dbTables.COMPANIES}/${companyId}/${dbTables.GL_ACCOUNTS}/${GLAccount.id}`
                ),
                {
                  accounts: accountsCpy,
                }
              );
              ev.preventDefault();
              ev.stopPropagation();
            }}
            style={{
              color: colors.primaryDark,
            }}
            size="small"
          />
        </TooltipTD>
        <TooltipTD
          label="Edit"
          style={{ display: "flex", cursor: "pointer" }}
          tooltipClick={onEditAccountItem}
        >
          <EditIcon />
        </TooltipTD>
      </div>
    </div>
  );
}

export default GLTableRow;
