import React from "react";
import { MilestonePopoverStyled } from "./styles";
import { milestoneHeader } from "../../helpers/timelineCalendar";
import TooltipTD from "../Tooltip/TooltipTD";
import MilestoneItem from "./MilestoneItem";
import { Button } from "@mui/material";
import { ChevronIcon, MilestonesIcon } from "../../helpers/iconSvgPath";
import { cx } from "@emotion/css";

function Milestones({
  milestones,
  onChangeMilestone,
  onDeleteMilestone,
  onAddNewMilestone,
  onCloseMilestone,
  collapseListTask = false,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Button
        className={cx("buttonViewOptions", {
          openButton: open,
          smallButton: collapseListTask,
        })}
        aria-describedby="simple-popover"
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        <TooltipTD
          showToolTip={collapseListTask}
          label={"Milestones"}
          className="tooltipContainer"
        >
          <MilestonesIcon style={{ strokeWidth: 1.5 }} />
          <span className="labelOption">Milestones</span>
          <ChevronIcon svgClass={"chrevronClass"} />
        </TooltipTD>
      </Button>
      {open && (
        <MilestonePopoverStyled
          id="simple-popover"
          open={open}
          anchorEl={anchorEl}
          onClose={() => {
            onCloseMilestone(milestones);
            setAnchorEl(null);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          classes={{ paper: "milestonesPopover" }}
          className="milestonesPopoverContainer"
        >
          <div className={"milestoneHeader"}>
            {milestoneHeader.map((header) => (
              <div
                key={header.id}
                className="text-container"
                style={{ width: header.width }}
              >
                {header.label}
              </div>
            ))}
          </div>
          <div className={"milestoneBody"}>
            {milestones
              .filter((milestone) => !milestone.isToday)
              .map((milestone) => (
                <MilestoneItem
                  key={milestone.id}
                  milestone={milestone}
                  onChangeMilestone={onChangeMilestone}
                  onDeleteMilestone={onDeleteMilestone}
                />
              ))}
          </div>
          <div className={"milestoneFooter"}>
            <Button
              className="add-milestone"
              onClick={() => {
                if (milestones.length <= 5) {
                  onAddNewMilestone();
                }
              }}
              disabled={milestones.length > 5}
            >
              + ADD MILESTONE
            </Button>
          </div>
        </MilestonePopoverStyled>
      )}
    </>
  );
}

export default Milestones;
