import React, { useContext, useState } from "react";
import { useCompanyId } from "../../../hooks";
import { useCompanyUsers, useUser } from "../../../hooks/user";
import { PO_STATUS } from "../../../helpers/constants";
import { filterTaskList } from "../HelperProjectTasks";
import { EntityTaskContext } from "./TaskContext";
import { TaskPanelHeader } from "./TaskPanelHeader";
import AdHocTaskComponentV2 from "./AdHocTaskComponentV2";
import TaskStageList from "./TaskStageList";

function ProjectTaskPanel({
  purchaseOrders,
  panelWidth,
  handleActivePanel,
  purchaseOrderTasks,
  salesOrderTasks,
  shipmentTasks,
  salesOrder,
  purchaseOrder,
  currentShipment,
  isReadOnly,
  handleReadOnlyModal,
}) {
  const companyId = useCompanyId();
  const currentUser = useUser();
  const companyUsers = useCompanyUsers({
    id: companyId,
    showBotUser: false,
    showInactiveUsers: false,
  });
  const [pendingToCompleted, setPendingToComplete] = useState({});

  const { TaskContextState } = useContext(EntityTaskContext);

  const { filterTabActive, showCompletedTask } = TaskContextState;

  const { POTasks, SOTasks } = filterTaskList({
    purchaseOrderTasks,
    salesOrderTasks,
    shipmentTasks,
    showCompleted: showCompletedTask,
    taskFilter: filterTabActive,
    user: currentUser,
    pendingToCompleted,
  });

  return (
    <>
      <TaskPanelHeader
        panelWidth={panelWidth}
        activePanel={panelWidth === "50%"}
        onActivePanel={handleActivePanel}
        SOTasks={SOTasks}
        POTasks={POTasks}
        purchaseOrders={purchaseOrders.filter(
          (po) => po.status !== PO_STATUS.VOIDED
        )}
        salesOrder={salesOrder}
        purchaseOrder={purchaseOrder}
      />
      <div
        className="content-card-content"
        style={{ height: "100%", flexDirection: "column" }}
      >
        <div
          id="task-scroll-container"
          className={"taskScrollContainer"}
          style={{ top: 0 }}
        >
          <AdHocTaskComponentV2
            panelWidth={panelWidth}
            isReadOnly={isReadOnly}
            handleReadOnlyModal={handleReadOnlyModal}
            companyUsers={companyUsers}
            salesOrderTasks={salesOrderTasks}
            purchaseOrderTasks={purchaseOrderTasks}
          />
          <TaskStageList
            taskList={[...POTasks, ...SOTasks]}
            purchaseOrder={purchaseOrder}
            salesOrder={salesOrder}
            shipment={currentShipment}
            isSmallPanel={panelWidth === "25%"}
            companyUsers={companyUsers}
            handlePendingComplete={setPendingToComplete}
            pendingToCompleted={pendingToCompleted}
            panelWidth={panelWidth}
          />
        </div>
      </div>
    </>
  );
}

export default ProjectTaskPanel;
