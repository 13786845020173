import { colors } from "../../assets/jss/variables";
import { styled } from "@mui/material";

export const SalesOrderBadgeStyled = styled("div")({
  "&.sales-order-badge": {
    background: colors.salesOrderBackgroundColor,
    padding: "0px 6px",
    borderRadius: 12,
    border: `2px solid ${colors.salesOrderBorderColor}`,
    width: "fit-content",
    height: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      background: colors.salesOrderHoverColor,
      cursor: "pointer",
      textShadow: "0 0 1px #000",
    },
  },
  "&.sales-order-badge-white": {
    background: "#fff",
    padding: "0px 6px",
    borderRadius: 12,
    border: `2px solid ${colors.salesOrderBorderColor}`,
    width: "fit-content",
    height: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      // background: colors.salesOrderSelectedBorderColor,
      cursor: "pointer",
      textShadow: "0 0 1px #000",
    },
  },
  "&.sales-order-badge-white-700": {
    background: "#fff",
    padding: "0px 6px",
    borderRadius: 12,
    border: `2px solid ${colors.salesOrderBorderColor}`,
    width: "fit-content",
    height: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 700,
    "&:hover": {
      cursor: "pointer",
    },
  },
  "&.isDisable": {
    opacity: 0.55,
  },
  "& .numberText": {
    lineHeight: "20px",
    fontSize: 12,
    padding: 0,
    margin: 0,
  },
});

export const PurchaseOrderBadgeStyled = styled("div")({
  "&.purchase-order-badge": {
    background: colors.purchaseOrderBackgroundColor,
    padding: "0px 6px",
    fontSize: 12,
    borderRadius: 12,
    border: `2px solid ${colors.purchaseOrderBorderColor}`,
    width: "fit-content",
    height: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      background: colors.purchaseOrderHoverColor,
      cursor: "pointer",
      textShadow: "0 0 1px #000",
    },
  },
  "&.purchase-order-badge-white": {
    background: "#fff",
    padding: "0px 6px",
    fontSize: 12,
    borderRadius: 12,
    border: `2px solid ${colors.purchaseOrderBorderColor}`,
    width: "fit-content",
    height: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      textShadow: "0 0 1px #000",
      cursor: "pointer",
    },
  },
  "&.purchase-order-badge-white-700": {
    background: "#fff",
    padding: "0px 6px",
    fontSize: 12,
    borderRadius: 12,
    border: `2px solid ${colors.purchaseOrderBorderColor}`,
    width: "fit-content",
    height: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 700,
    "&:hover": {
      cursor: "pointer",
    },
  },
  "& .numberText": {
    lineHeight: "20px",
    fontSize: 12,
    padding: 0,
    margin: 0,
    display: "block",
  },
  "&.isDisable": {
    opacity: 0.55,
  },
});
