import { now } from "moment";
import { getRandomId } from "../../helpers/helpers";

export default class ChangeLog {
  constructor({
    id = getRandomId(),
    creationDate = now(),
    description = "",
    user = "",
    version = "",
    versionId = "",
    templateViewId = "",
    cloneFrom   = "",
    addedTasks = [],
    deletedTasks = [],
    updatedTasks = [],
  }) {
    this.id = id;
    this.creationDate = creationDate;
    this.description = description;
    this.user = user;
    this.version = version;
    this.versionId = versionId;
    this.templateViewId = templateViewId;
    this.addedTasks = addedTasks;
    this.deletedTasks = deletedTasks;
    this.updatedTasks = updatedTasks;
    this.cloneFrom =   cloneFrom;
    }
}
