import { getTotal } from "../../../helpers/expenses";
import { parseNumberValue } from "../../../helpers/orderDashboardRefactored";

export const getTotalFixed = ({ value, list, field }) => {
  const parseValue = parseNumberValue(value).toFixed(2);
  const total = parseNumberValue(
    getTotal({
      list,
      field,
      format: false,
    })
  ).toFixed(2);

  return Number(parseValue) - Number(total);
};
